<template>
  <narrow-form-container>
    <h2>User Profile</h2>
    <div class="ui divider"></div>
    <template v-if="loading">
      <bf-spinner class="spinner" text-pos="top">
        Getting your details.
      </bf-spinner>
    </template>
    <div v-else-if="loadingFailure">
      Failed to get user details.
    </div>
    <template v-else>
      <VeeForm
        :initial-values="initialFormValues"
        :on-submit="updateUser"
        class="ui form vee-form"
        validate-on-mount
      >
        <template #default="{ meta: { valid, pending }, isSubmitting }">
          <div class="form-body">
            <div class="field">
              <label>Email</label>
              <span class="uneditable">{{ user.email }}</span>
            </div>
            <Field
              v-slot="{ field, errorMessage }"
              as="div"
              class="field"
              rules="required"
              name="first_name"
              label="First Name"
              validate-on-input
            >
              <label>First Name</label>
              <input
                v-bind="field"
                type="text"
                placeholder="First Name"
                :class="{ error: !!errorMessage }"
              >
              <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                <div v-if="errorMessage" class="error-text">
                  {{ errorMessage }}
                </div>
              </transition>
            </Field>
            <Field
              v-slot="{ field, errorMessage }"
              as="div"
              class="field"
              rules="required"
              name="last_name"
              label="Last Name"
              validate-on-input
            >
              <label>Last Name</label>
              <input
                v-bind="field"
                type="text"
                placeholder="Last Name"
                :class="{ error: !!errorMessage }"
              >
              <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                <div v-if="errorMessage" class="error-text">
                  {{ errorMessage }}
                </div>
              </transition>
            </Field>
            <div class="field">
              <label>User Type</label>
              <span class="uneditable">{{ user.user_type || 'Unknown' }}</span>
              <span v-if="user.is_staff" class="uneditable"> — Kapiche Staff User</span>
            </div>
            <div class="field">
              <label>Connected Accounts</label>
              <div v-for="provider in user.providers" :key="provider.name" class="uneditable">
                {{ provider.name || 'Unknown' }}
                <button v-if="!provider.isSocial" type="button" @click="auth0ChangePassword">
                  Reset Password
                </button>
              </div>
            </div>
          </div>

          <div class="ui divider bottom"></div>

          <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div v-if="success === true" class="ui icon message bottom positive">
              <i class="icon checkmark"></i>
              <div class="content">
                User profile updated.
              </div>
            </div>
            <div v-else-if="success === false" class="ui icon message bottom negative">
              <i class="kapiche-icon-cross icon"></i>
              <div class="content">
                User profile not updated.
              </div>
            </div>
          </transition>

          <div class="actions">
            <button
              type="submit"
              class="ui button primary"
              :class="{ 'loading': isSubmitting }"
              :disabled="!valid || pending || isSubmitting"
            >
              Update Profile
            </button>
          </div>
          <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
            <div
              v-if="auth0Success !== undefined"
              class="ui icon message bottom"
              :class="{
                positive: !!auth0Success,
                negative: !auth0Success,
              }"
            >
              <i v-if="auth0Success" class="icon checkmark"></i>
              <i v-else class="kapiche-icon-cross icon"></i>
              <div class="content">
                {{ auth0Message }}
              </div>
            </div>
          </transition>
        </template>
      </VeeForm>
    </template>
  </narrow-form-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import { Form as VeeForm, Field } from 'vee-validate'

  import NarrowFormContainer from 'src/components/account/NarrowFormContainer.vue'
  import { UPDATE_USER } from 'src/store/types'
  import { Auth0 } from 'src/plugins/auth0'
  import { BfSpinner } from "components/Butterfly"
  import auth from 'src/api/auth'

  import { UserProfile } from 'src/types/UserTypes'

  interface FormValues {
    first_name: string
    last_name: string
  }

  export default defineComponent({
    components: { NarrowFormContainer, BfSpinner, VeeForm, Field },
    data () {
      return {
        loading: true,
        loadingFailure: false,
        success: undefined as boolean | undefined,
        auth0: true,
        auth0Success: undefined as boolean | undefined,
        auth0Message: '',
        user: {} as UserProfile,
      }
    },
    computed: {
      ...mapGetters([
        'currentSite', 'currentUser', 'featureFlags',
      ]),
      initialFormValues (): FormValues {
        return {
          first_name: this.currentUser?.first_name ?? '',
          last_name: this.currentUser?.last_name ?? '',
        }
      },
    },
    metaInfo () {
      return {
        title: this.currentSite ? `${this.currentSite.site_name} Profile - Kapiche` : null
      }
    },
    async mounted () {
      await this.loadUserProfile()
    },
    methods: {
      async loadUserProfile () {
        this.loadingFailure = false
        this.loading = true
        try {
          this.user = await auth.getUserProfile()
        } catch (e) {
          this.loadingFailure = true
        } finally {
          this.loading = false
        }
      },
      async auth0ChangePassword () {
        try {
          const res = await Auth0.requestPasswordChange(this.user.email)
          if (res.status === 200) {
            this.auth0Success = true
            this.auth0Message = 'Please check your email inbox.'
          } else {
            throw null
          }
        } catch (e) {
          this.auth0Success = false
          this.auth0Message = 'Something went wrong, please refresh and try again.'
          if (e) throw e
        }
      },
      async updateUser ({ first_name, last_name }: FormValues) {
        try {
          this.success = undefined
          await this.$store.dispatch({
            type: UPDATE_USER,
            first_name,
            last_name,
          })
          this.success = true
          setTimeout(() => { this.success = undefined }, 2500)
        } catch {
          this.success = false
          setTimeout(() => { this.success = undefined }, 2500)
        }
      }
    }
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .spinner
    width: 100%

  .uneditable
    font-size: 16px
    font-weight: bold
    margin-bottom: 5px
    button
      background: none
      border: none
      cursor: pointer
      color: $blue
      margin-left: 5px
      font-weight: bold

  .actions
    display: flex
    justify-content: left

  // The following override semantic styles
  .narrow-form-container .actions .ui.button
    margin-right: 30px
    font-size: 17px

  .ui.form .field
    margin-bottom: 1.5em
    label
      margin-bottom: 7px
</style>
