<template>
  <widget-frame
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    :side-panel-collapsable="true"
    class="pivot-table"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <download-export-button
        v-if="isZoomed && hasValidData"
        :name="`${exportName} - Pivot Table - ${rowFields.join(', ')} - ${colFields.join(', ')}`"
        short-name="Pivot Table"
        :get-csv-data="getCsvData"
        :is-loading="isLoading"
        :bot-share-enabled="false"
        :make-ppt-slide="makePptSlide"
        :get-el="getExportEl"
      ></download-export-button>
      <router-link
        v-if="!isZoomed && zoomToRoute"
        class="widget-action expand"
        :to="zoomToRoute"
      >
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a
        :href="CONST.widget_help_links.pivot_table"
        class="widget-action help"
        target="_blank"
      >
        <i class="kapiche-icon-info"></i>
      </a>
    </template>
    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="PivotTable Icon">
    </template>
    <!--======================== HEADING -->
    <template #header>
      Pivot Table
    </template>
    <!--======================== MENU -->
    <template v-if="isZoomed" #menu>
      <div class="menu-list">
        <div>
          <div>
            <p><strong>Note: Use 1 or more row and/or columns</strong></p>
          </div>
          <div class="settings-section">
            <h4>Rows</h4>
          </div>
          <div
            v-for="(row, i) in stagedRowFields"
            :key="'row_' + row + '_' + i"
            class="settings-row"
          >
            <div>
              <dropdown
                :class="{ 'unselected': row === noneSelectedLabel }"
                @input="updateField('stagedRowFields', i, $event)"
                @toggled="setMaxHeight"
              >
                <template #trigger>
                  {{ row }}
                  <i class="icon dropdown"></i>
                </template>
                <dropdown-item
                  v-for="option in fieldOptions"
                  :key="option.name + '_rows'"
                  :value="option.name"
                  :disabled="option.disabled"
                >
                  {{ option.name }} ({{ option.segments }} segments)
                </dropdown-item>
              </dropdown>
              <a
                v-if="i > 0"
                href=""
                @click.prevent="removeField('stagedRowFields', i)"
              >
                &times;
              </a>
            </div>
            <div
              v-if="row === stagedBucketDateField"
              class="bucket-date"
            >
              <span>
                Bucket dates:
              </span>
              <dropdown
                @input="stagedBucketDateOffset = $event"
                @toggled="setMaxHeight"
              >
                <template #trigger>
                  {{ stagedBucketDateOffset }}
                  <i class="icon dropdown"></i>
                </template>
                <dropdown-item
                  v-for="label in Object.keys(DATE_BUCKET_OPTIONS)"
                  :key="label"
                  :value="label"
                >
                  {{ label }}
                </dropdown-item>
              </dropdown>
            </div>
          </div>
          <div v-if="stagedRowFields.find((f) => f !== noneSelectedLabel)">
            <bf-button
              color="white"
              size="mini"
              @click="stagedRowFields.push(noneSelectedLabel)"
            >
              Add another row
            </bf-button>
          </div>
          <div class="settings-section">
            <h4>Columns</h4>
          </div>
          <div
            v-for="(col, i) in stagedColFields"
            :key="'col_' + col + '_' + i"
            class="settings-row"
          >
            <div>
              <dropdown
                :class="{ 'unselected': col === noneSelectedLabel }"
                @input="updateField('stagedColFields', i, $event)"
                @toggled="setMaxHeight"
              >
                <template #trigger>
                  {{ col }}
                  <i class="icon dropdown"></i>
                </template>
                <dropdown-item
                  v-for="option in fieldOptions"
                  :key="option.name + '_cols'"
                  :value="option.name"
                  :disabled="option.disabled"
                >
                  {{ option.name }} ({{ option.segments }} segments)
                </dropdown-item>
              </dropdown>
              <a
                v-if="i > 0"
                href=""
                @click.prevent="removeField('stagedColFields', i)"
              >
                &times;
              </a>
            </div>
            <div
              v-if="col === stagedBucketDateField"
              class="bucket-date"
            >
              <span>
                Bucket dates by:
              </span>
              <dropdown
                @input="stagedBucketDateOffset = $event"
                @toggled="setMaxHeight"
              >
                <template #trigger>
                  {{ stagedBucketDateOffset }}
                  <i class="icon dropdown"></i>
                </template>
                <dropdown-item
                  v-for="label in Object.keys(DATE_BUCKET_OPTIONS)"
                  :key="label"
                  :value="label"
                >
                  {{ label }}
                </dropdown-item>
              </dropdown>
            </div>
          </div>
          <div v-if="stagedColFields.find((f) => f !== noneSelectedLabel)">
            <bf-button
              color="white"
              size="mini"
              @click="stagedColFields.push(noneSelectedLabel)"
            >
              Add another column
            </bf-button>
          </div>
          <div class="settings-section">
            <h4>Values</h4>
          </div>
          <div class="settings-row">
            <dropdown
              @input="updateValueField"
              @toggled="setMaxHeight"
            >
              <template #trigger>
                {{ stagedValueField }}
                <i class="icon dropdown"></i>
              </template>
              <dropdown-item
                v-for="option in valueFieldOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </dropdown-item>
            </dropdown>
            <dropdown
              v-if="valueMethodOptions.length > 0"
              @input="stagedValueMethod = $event"
              @toggled="setMaxHeight"
            >
              <template #trigger>
                {{ stagedValueMethod }}
                <i class="icon dropdown"></i>
              </template>
              <dropdown-item
                v-for="option in valueMethodOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </dropdown-item>
            </dropdown>
            <label
              v-if="['TOP BOX', 'BOTTOM BOX'].includes(stagedValueMethod)"
              class="box-input"
            >
              Number of boxes:
              <el-input-number
                :model-value="stagedBoxValue"
                :controls="true"
                :min="1"
                size="small"
                @update:model-value="stagedBoxValue = $event"
              />
            </label>
            <div class="settings-row checkbox">
              <el-switch
                :model-value="stagedHideTotals"
                :disabled="stagedValueMethod === 'PERCENT OF ROW'"
                :title="switchTitle"
                @change="(val) => stagedHideTotals = val"
              ></el-switch>
              <span class="checkbox-text">Hide Totals</span>
            </div>
          </div>
          <div class="settings-section">
            <h4>Colour palette</h4>
          </div>
          <div class="settings-row colors">
            <div>
              <el-radio
                :model-value="stagedColorSetMethod"
                label="none"
                :disabled="paletteDisabled"
                :title="paletteDisabled ? 'Colour palette not yet supported for PERCENT OF ROW' : ''"
                @update:model-value="stagedColorSetMethod = $event"
              >
                No colours
              </el-radio>
              <el-radio
                :model-value="stagedColorSetMethod"
                label="single"
                :disabled="paletteDisabled"
                @change="changeBinsSingleColor(stagedBinColors[stagedBinColors.length-1])"
                @update:model-value="stagedColorSetMethod = $event"
              >
                Pick one colour
              </el-radio>
              <el-radio
                :model-value="stagedColorSetMethod"
                label="preset"
                :disabled="paletteDisabled"
                @change="changePresetBins(stagedColorPreset)"
                @update:model-value="stagedColorSetMethod = $event"
              >
                Pick preset colours
              </el-radio>
              <el-radio
                :model-value="stagedColorSetMethod"
                label="multiple"
                :disabled="paletteDisabled"
                @update:model-value="stagedColorSetMethod = $event"
              >
                Pick multiple colours
              </el-radio>
            </div>
          </div>
          <div>
            <template v-if="stagedColorSetMethod==='single'">
              <el-color-picker
                class="single-color"
                :model-value="stagedBinColors[stagedBinColors.length-1]"
                color-format="rgb"
                @change="changeBinsSingleColor"
              />
              <br />
              <el-color-picker
                v-for="i in 5"
                :key="'single_' + i"
                :model-value="stagedBinColors[i-1]"
                show-alpha
                color-format="rgb"
                class="unclickable"
              />
            </template>
            <template v-if="stagedColorSetMethod==='multiple'">
              <el-color-picker
                v-for="i in 5"
                :key="'multiple_' + i"
                :model-value="stagedBinColors[i-1]"
                show-alpha
                color-format="rgb"
                @update:model-value="stagedBinColors[i-1] = $event"
              />
            </template>
            <template v-if="stagedColorSetMethod==='preset'">
              <div class="presets">
                <el-radio
                  :model-value="stagedColorPreset"
                  label="RED_GREEN"
                  @change="changePresetBins"
                  @update:model-value="stagedColorPreset = $event"
                >
                  <el-color-picker
                    v-for="i in 5"
                    :key="'preset_' + i"
                    :model-value="COLOR_PRESETS.RED_GREEN[i-1]"
                    show-alpha
                    color-format="rgb"
                    class="unclickable"
                  />
                </el-radio>
                <el-radio
                  :model-value="stagedColorPreset"
                  label="BLUE_ORANGE"
                  @change="changePresetBins"
                  @update:model-value="stagedColorPreset = $event"
                >
                  <el-color-picker
                    v-for="i in 5"
                    :key="'preset_' + i"
                    :model-value="COLOR_PRESETS.BLUE_ORANGE[i-1]"
                    show-alpha
                    color-format="rgb"
                    class="unclickable"
                  />
                </el-radio>
              </div>
            </template>
          </div>
        </div>
        <div class="button-wrapper">
          <bf-button
            color="blue"
            size="big"
            class="save-button"
            :disabled="!hasUnappliedSettings"
            @click="updateTableSettings"
          >
            Update
          </bf-button>
        </div>
      </div>
    </template>
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <h2>this.props</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button> or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button> if the problem persists.
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="isZoomed" #content>
      <div v-if="displayPercentOfRowWarning" class="percent-of-row-warning">
        PERCENT OF ROW can produce unexpected results when Themes or Top Concepts are selected for columns.
        <br />
        <a href="http://help.kapiche.com/en/articles/7051518-how-to-use-the-pivot-table-widget#h_55da0683a7" target="_blank">More info.</a>
      </div>
      <span v-if="hasValidData && table" class="table-wrapper" :style="cssBinVars">
        <div class="scroll" @scroll="onscroll()">
          <pivot-table
            ref="pivot"
            :data="dataRows"
            :rows="table.rowFields"
            :cols="table.colFields"
            :reducer="table.reducer"
            :summary-label="table.summaryLabel"
            :render-visible="renderVisible"
            :value-label="calculateValueLabel(table)"
            :value-method="table.valueMethod"
            :show-summary="false"
            :grand-total-label="grandTotalLabel"
            :date-fields="dateFields"
            date-format="YYYY-MM-DD"
            :col-label="colLabel"
            :row-label="rowLabel"
            :hide-totals="table.hideTotals"
            :bin-colors="stagedColorSetMethod !== 'none' ? table.binColors : undefined"
            wrapper-class=".scroll"
            @sort="trackSort"
          >
            <template #value="{ cell }">
              <div
                class="heatmap-cell"
                :class="[
                  !cell.isSummary && cell.data.length > 0 && table.valueMethod !== 'PERCENT OF ROW'
                    ? `bin-${cell.data[0].value_binned}`
                    : '',
                  !table.hideTotals? 'showTotals': ''
                ]"
              >
                {{ cell.value }}
              </div>
            </template>
            <template #header="{ label, sorted, visible }">
              <div class="header-content">
                <span>
                  {{ label }}
                </span>
                <span :class="{ sorted: sorted }">
                  <up-down
                    v-if="visible"
                    :up="sorted === 'asc'"
                    :down="sorted === 'desc'"
                  />
                </span>
              </div>
            </template>
          </pivot-table>
        </div>
      </span>
      <div v-else class="empty-message">
        Please configure the Pivot Table by selecting
        <br />
        fields to display as rows or columns.
      </div>
      <export-modal
        ref="exportModal"
      />
    </template>
    <template v-else #content>
      <div>
        This data can be pivoted.
        <router-link :to="zoomToRoute">
          Open pivot table
        </router-link>
      </div>
    </template>
  </widget-frame>
</template>

<script lang='ts'>
import { PropType, defineComponent, nextTick } from 'vue'
import PptxGenJS from 'pptxgenjs'
import _ from 'lodash'

import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-pivot-table.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { DashboardSavedQuery, QueryType, SavedQuery } from 'types/Query.types'
import { BfButton } from 'components/Butterfly'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import { WidgetConfig } from 'types/DashboardTypes'
import { SchemaColumn } from 'types/SchemaTypes'
import Table, { VectorGetter } from './Table.vue'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import ExportModal from './ExportModal.vue'
import { comma } from 'src/utils/formatters'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import { getBoxValues } from '../DataWidgetUtils'
import { calcWeightedAverage } from 'src/utils/math'

const COLOR_PRESETS = {
  RED_GREEN: [
    'rgb(230, 124, 115, 1)',
    'rgb(243, 190, 185, 1)',
    'rgb(255, 255, 255, 1)',
    'rgb(171, 221, 197, 1)',
    'rgb(87, 187, 138, 1)',
  ],
  BLUE_ORANGE: [
    'rgb(254, 156, 82, 1)',
    'rgb(255, 206, 170, 1)',
    'rgb(221, 221, 221, 1)',
    'rgb(158, 203, 236, 1)',
    'rgb(60, 151, 218, 1)',
  ],
} as const

const DEFAULT_BIN_COLORS = [
  'rgb(6, 140, 204, 0.05)',
  'rgb(6, 140, 204, 0.25)',
  'rgb(6, 140, 204, 0.5)',
  'rgb(6, 140, 204, 0.75)',
  'rgb(6, 140, 204, 1)',
]

const DATE_BUCKET_OPTIONS = {
  'None': null,
  'Daily': 'D',
  'Weekly': 'WS',
  'Fortnightly': '2W',
  'Monthly': 'MS',
  'Quarterly': 'QS',
  'Yearly': 'YS',
} as const

interface TableSettings {
  rowFields: VectorGetter[]
  colFields: VectorGetter[]
  reducer: (data: Record<string, number>[]) => number
  summaryLabel: string
  colorPreset: keyof typeof COLOR_PRESETS
  colorSetMethod: 'single' | 'multiple' | 'preset' | 'none'
  binColors: string[]
  valueField: string
  valueMethod: string
  bucketDateOffset: keyof typeof DATE_BUCKET_OPTIONS,
  hideTotals: boolean
  boxValue: number
}

interface FieldOption {
  name: string
  segments: number
  disabled?: boolean
}

const PivotTable = defineComponent({
  components: {
    DownloadExportButton,
    WidgetFrame,
    BfButton,
    Dropdown,
    DropdownItem,
    PivotTable: Table,
    UpDown,
    ExportModal,
  },
  inject: {
    featureFlags:{
      from: 'featureFlags',
      default: () => ({}),
    }
  },
  props: {
    exportName: { type: String, required: false, default: '' },
    devMode: { type: Boolean, required: false, default: false },
    zoomToRoute: { type: Object, required: false, default: null },
    isZoomed: { type: Boolean, required: false, default: false },
    banner: { type: Object, default: ()=>null, required: false },
    baseQuery: { type: Array as PropType<QueryType[]>, required: true },
    masked: { type: Boolean, required: false, default: false },
    data: { type: Object, required: false, default: null },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    config: { type: Object as PropType<WidgetConfig<'pivot-table'> | null>, required: false, default: null },
    status: { type: String, required: false, default:'' },
    savedQueries: { type: Array as PropType<SavedQuery[]>, required: false, default: () => [] },
    currentModel: { type: Object, required: true },
    concepts: { type: Array as PropType<DashboardSavedQuery[]>, required: false, default: () => [] },
    dashboardId: { type: Number, required: true },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasNps: { type: Boolean, required: false, default: false },
    weekStart: { type: String, required: false, default: null },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: () => [] },
  },
  data () {
    return {
      icon: icon as string,
      errorIcon: errorIcon as string,
      hasErrored: false,
      width: 0,
      height: 0,
      stagedHideTotals: false as boolean,
      stagedRowFields: [] as string[],
      stagedColFields: [] as string[],
      stagedValueField: 'Frequency',
      stagedValueMethod: 'COUNT',
      stagedColorSetMethod: 'single' as TableSettings['colorSetMethod'],
      stagedColorPreset: 'RED_GREEN' as TableSettings['colorPreset'],
      stagedBinColors: DEFAULT_BIN_COLORS,
      stagedBucketDateOffset: 'None' as keyof typeof DATE_BUCKET_OPTIONS,
      stagedBoxValue: 2,
      table: null as TableSettings | null,
      COLOR_PRESETS,
      renderVisible: true,
      noneSelectedLabel: 'Select...',
      DATE_BUCKET_OPTIONS,
    }
  },
  computed: {
    switchTitle (): string {
      return this.stagedValueMethod === 'PERCENT OF ROW'?
        "Total Counts are currently not supported for 'Percent of Row'":
        "Toggle the total counts in the table"
    },
    colLabel (): string {
      return this.table?.colFields.map(this.getVectorLabel).join(', ') ?? ''
    },
    rowLabel (): string {
      return this.table?.rowFields.map(this.getVectorLabel).join(', ') ?? ''
    },
    // The first date field selected will be used to bucket the data,
    // as we can only specify one date field to bucket by per request.
    bucketDateField (): string | undefined {
      const stagedFields = [...this.table.rowFields, ...this.table.colFields]
      const dateField = stagedFields.find((f) => this.dateFields.includes(f.label))
      return dateField?.label
    },
    stagedBucketDateField (): string | undefined {
      const stagedFields = [...this.stagedRowFields, ...this.stagedColFields]
      const dateField = stagedFields.find((f) => this.dateFields.includes(f))
      return dateField
    },
    onscroll () {
      return _.throttle(() => {
        this.renderVisible &&
          this.$refs.pivot.calculateVisible()
      }, 20)
    },
    hasUnappliedSettings () {
      if (!this.table) return false
      return (
        this.stagedBucketDateOffset !== this.table.bucketDateOffset ||
        this.stagedValueField !== this.table.valueField ||
        this.stagedValueMethod !== this.table.valueMethod ||
        this.stagedColorSetMethod !== this.table.colorSetMethod ||
        this.stagedColorPreset !== this.table.colorPreset ||
        this.stagedHideTotals !== this.table.hideTotals ||
        this.stagedBoxValue !== this.table.boxValue ||
        JSON.stringify(this.stagedBinColors) !== JSON.stringify(this.table.binColors) ||
        JSON.stringify(this.stagedRowFields.filter((f: string) => f !== this.noneSelectedLabel)) !== JSON.stringify(this.rowFields) ||
        JSON.stringify(this.stagedColFields.filter((f: string) => f !== this.noneSelectedLabel)) !== JSON.stringify(this.colFields)
      )
    },
    isLoading (): boolean {
      return this.status === 'fetching'
    },
    paletteDisabled (): boolean {
      return this.stagedValueMethod === 'PERCENT OF ROW'
    },
    displayPercentOfRowWarning (): boolean {
      return this.stagedValueMethod === 'PERCENT OF ROW' && (this.colFields.includes('Themes') || this.colFields.includes('Top Concepts') || this.colFields.includes('Theme Groups'))
    },
    dataRows (): Array<Record<string, string|number>> {
      let payload: Array<Record<string, string|number>> = this.data?.payload ?? []

      // From the selectedFields, we construct a list of fields that
      // do not contain (No Value) segment in them.
      let fields: string[] = this.rowFields.concat(this.colFields)
      let truncateNoValueFields = fields.filter((field: string) => {
        if (this.currentModel.metadata_info.hasOwnProperty(field)) {
          const frequencyInfo = this.currentModel.metadata_info[field].frequencies ?? []
          if (Object.keys(frequencyInfo).includes("(No Value)")) {
            return false
          } else {
            return true
          }
        }
      })

      // If there are no nonzero `(No Value)` counts in the data, it is better
      // to not show them at all. `(No Value)` is a special segment value
      // that we inject to represent null values in the data. These are
      // included in the backend during aggregation and pivot calculations.
      payload = payload.filter(
        (item: Record<string, number|string>) => {
          // Is this a (No Value) row or column? We have to check
          // all the row fields and all the column fields to see if
          // any of them is (No Value).
          let isNoValue = this.stagedRowFields.filter(
            (field: string) => item[field] === '(No Value)'
          ).length > 0
            || this.stagedColFields.filter(
              (field: string) => item[field] === '(No Value)'
            ).length > 0

          if (isNoValue) {
            // For fields in truncateNoValueFields, we drop the (No Value) data
            // if we want to calculate NPS or NPS Impact. This is because these fields
            // donot have any null values, so (No Value) will always be 0.
            // This will mess with the Average NPS/NPS Impact calculation if left as
            // part of the data.
            for (const field of truncateNoValueFields) {
              if (
                item.hasOwnProperty(field) &&
                 ["NPS", "NPS Impact"].includes(this.table?.valueMethod ?? "")
              ) {
                if (item[field] === "(No Value)") {
                  return false
                }
              }
            }
            // It is a (No Value) row or column. Is the value zero?
            // If so, we don't want to show it.
            if (item.hasOwnProperty('value')) {
              return item['value'] !== 0 && item['value'] !== null
            } else if (item.hasOwnProperty('frequency_cov')) {
              return item['frequency_cov'] !== 0
            } else {
              return true
            }
          } else {
            return true
          }
        }
      )

      return payload
    },
    hasValidData (): boolean {
      return this.data && (this.rowFields.length > 0 || this.colFields.length > 0)
    },
    fieldOptions (): FieldOption[] {
      const getSegmentCount = (field: string) => {
        const data = this.currentModel.metadata_info[field]
        return data?.values ?? 0
      }

      const options: FieldOption[] = (this.schema as any[])
        .filter((row) => row.typename !== 'TEXT')
        .map((row) => row.name)
        .map((name) => ({
          segments: getSegmentCount(name),
          name,
        }))

      if (this.savedQueries.length > 0) {
        options.push({
          segments: this.savedQueries.length,
          name: 'Themes',
        })
      }

      if (this.featureFlags?.theme_groups && this.themeGroups.length > 0) {
        options.push({
          segments: this.themeGroups.length,
          name: 'Theme Groups',
        })
      }

      if (this.concepts.length > 0) {
        options.push({
          segments: this.concepts.length,
          name: 'Top Concepts',
        })
      }

      if (this.hasSentiment) {
        options.push({
          segments: 4,
          name: 'Sentiment',
        })
      }

      // Disable options that are already selected
      options.forEach((option) => {
        let disabled =
          this.stagedRowFields.includes(option.name) ||
          this.stagedColFields.includes(option.name)

        // If Themes or Top Concepts is in fields, disable both from being selected.
        // See shortcut story [sc-44537] for details.
        if (['Themes', 'Theme Groups', 'Top Concepts'].includes(option.name)) {
          disabled =
            this.stagedColFields.concat(this.stagedRowFields).includes('Themes') ||
            this.stagedColFields.concat(this.stagedRowFields).includes('Top Concepts') ||
            this.stagedColFields.concat(this.stagedRowFields).includes('Theme Groups')
        }
        option.disabled = disabled
      })

      // Sort alphabetically
      options.sort((a, b) => a.name.localeCompare(b.name))

      return options
    },
    rowFields (): string[] {
      return (this.table?.rowFields as any[] ?? [])
        .map((r) => r.label)
        .filter((r) => r !== this.noneSelectedLabel)
    },
    colFields (): string[] {
      return (this.table?.colFields as any[] ?? [])
        .map((r) => r.label)
        .filter((r) => r !== this.noneSelectedLabel)
    },
    cssBinVars () {
      if (!this.table || this.table.colorSetMethod === 'none') return {}
      return {
        '--bg-color-0': this.table.binColors[0],
        '--bg-color-1': this.table.binColors[1],
        '--bg-color-2': this.table.binColors[2],
        '--bg-color-3': this.table.binColors[3],
        '--bg-color-4': this.table.binColors[4],
      }
    },
    valueFieldOptions () {
      const fieldOptions: string[] = (this.schema as any[])
        .filter((row) => ['NUMBER', 'SCORE'].includes(row.typename))
        .map((row) => row.name)

      fieldOptions.push('Frequency')

      if (this.hasSentiment) {
        fieldOptions.push('Sentiment',)
      }
      if (this.hasNps) {
        fieldOptions.push('NPS', 'NPS Impact',)
      }
      return fieldOptions
    },
    valueMethodOptions () {
      if (
        this.NPSFields.includes(this.stagedValueField) ||
        ['NPS', 'NPS Impact'].includes(this.stagedValueField)
      ) {
        return []
      }
      if (this.stagedValueField === 'Frequency') {
        return ['COUNT', 'PERCENT OF DATA', 'PERCENT OF ROW']
      }
      if (this.stagedValueField === 'Sentiment') {
        return ['Positive PERCENT', 'Negative PERCENT', 'Mixed PERCENT']
      }
      const defaultOptions = ['COUNT', 'SUM', 'MEAN', 'MEDIAN', 'MIN', 'MAX']

      const column = this.schema.find((row) => row.name === this.stagedValueField)
      if (column?.typename === 'SCORE') {
        return defaultOptions.concat(['TOP BOX', 'BOTTOM BOX'])
      }

      return defaultOptions
    },
    NPSFields () {
      return (this.schema as any[])
        .filter((row) => row.typename === 'NPS')
        .map((row) => row.name)
    },
    scoreFields () {
      return (this.schema as any[])
        .filter((row) => row.typename === 'SCORE')
        .map((row) => row.name)
    },
    dateFields () {
      return (this.schema as any[])
        .filter((row) => ['DATE', 'DATE_TIME'].includes(row.typename))
        .map((row) => row.name)
    },
    grandTotalLabel () {
      if (['COUNT', 'SUM'].includes(this.table.valueMethod)) {
        return 'Grand Total'
      }

      if (this.table.valueMethod === 'NPS') {
        return 'Average of NPS'
      } else if (this.table.valueMethod === 'NPS Impact') {
        return 'Average of NPS Impact'
      }

      const str = this.table.valueMethod.toLowerCase()
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    // Defines the key used to access the value in the data
    // from the pivot endpoint. In most cases we name this "value"
    // in the aggFuncs, but some value types have different names.
    valueKey () {
      if (this.table?.valueField === 'NPS') {
        return 'NPS Category|nps__'
      }

      if (this.table?.valueField === 'NPS Impact') {
        return 'NPS Category|npsi_rto__'
      }

      if (
        this.table?.valueMethod === 'TOP BOX' ||
        this.table?.valueMethod === 'BOTTOM BOX'
      ) {
        return `${this.table?.valueField}|box%__`
      }

      return 'value'
    },
  },
  watch: {
    stagedValueMethod: {
      handler (newVal) {
        if (newVal === 'PERCENT OF ROW') {
          this.stagedHideTotals = true
          this.stagedColorSetMethod = 'none'
        }
      },
      immediate: true
    },
    config: {
      deep: true,
      handler (newVal, oldVal) {
        if (!_.isEqual(oldVal, newVal)) {
          this.setOptionsFromConfig()
          this.updateTableSettings(false)
        }
      },
    },
  },
  mounted () {
    this.setOptionsFromConfig()
    this.updateTableSettings(false)
  },
  methods: {
    async getExportEl () {
      const rowMap = this.$refs.pivot.rowMap
      const colMap = this.$refs.pivot.colMap
      const fields = rowMap.concat(colMap)
      const res = await this.$refs.exportModal.open(fields)

      return new Promise(async (resolve) => {
        setTimeout(async () => {
          // We need to render the entire table
          const renderSetting = this.renderVisible
          this.renderVisible = false

          // Wait for the DOM to update
          await nextTick()

          const el = document.querySelector('.table-wrapper')?.cloneNode(true) as HTMLElement
          const wrapper = document.createElement('div')
          wrapper.style.position = 'absolute'
          wrapper.style.top = '100%'
          wrapper.style.left = '100%'
          document.body.appendChild(wrapper)
          wrapper.appendChild(el)

          // Hide left-side number cells
          el.querySelectorAll('.number-header').forEach((e) =>
            e.style.display = 'none'
          )

          ;(Array.from(el.querySelectorAll('td, th')) as HTMLElement[])
            .forEach((el: HTMLElement) => {
              const coords = el.dataset.coords?.split('|') ?? []
              coords.forEach((coord: string) => {
                if (!coord) return

                const [label, value] = coord.split('=')
                const show = res[label]?.find((v: string) => v == value)
                if (show == undefined) {
                  // Take cell out of table flow
                  el.style.position = 'absolute'
                  el.style.top = '-100%'
                  el.style.left = '-100%'
                }
              })
            })

          resolve(el)

          await nextTick()
          this.renderVisible = renderSetting

          await nextTick()
          el.remove()

          this.$refs.exportModal.close()
        }, 1000)
      })
    },
    getVectorLabel (v: VectorGetter) {
      let suffix = ''
      if (this.bucketDateField === v.label) {
        const offset = this.table.bucketDateOffset as keyof typeof DATE_BUCKET_OPTIONS
        const offsetLabel = DATE_BUCKET_OPTIONS[offset]
        if (offsetLabel) {
          suffix = `\u00a0(${DATE_BUCKET_OPTIONS[offset]})`
        }
      }
      return `${v.label.replace(' ', '\u00a0')}${suffix}`
    },
    updateConfig () {
      const updated: typeof this.config =
        Object.assign({}, this.config, {
          options: {
            rowFields: this.rowFields,
            colFields: this.colFields,
            binColors: this.table.binColors,
            colorSetMethod: this.table.colorSetMethod,
            colorPreset: this.table.colorPreset,
            valueField: this.table.valueField,
            valueMethod: this.table.valueMethod,
            bucketDateOffset: this.table.bucketDateOffset,
            hideTotals: this.table.hideTotals,
            boxValue: this.table.boxValue,
          }
        })
      this.$emit('config-changed', updated)
    },
    setOptionsFromConfig () {
      this.stagedRowFields = this.config?.options?.rowFields ?? []
      this.stagedColFields = this.config?.options?.colFields ?? []
      this.stagedColorSetMethod = this.config?.options?.colorSetMethod ?? 'single'
      this.stagedBinColors = this.config?.options?.binColors ?? DEFAULT_BIN_COLORS
      this.stagedColorPreset = this.config?.options?.colorPreset ?? 'RED_GREEN'
      this.stagedValueField = this.config?.options.valueField ?? 'Frequency'
      this.stagedValueMethod = this.config?.options.valueMethod ?? 'COUNT'
      this.stagedBucketDateOffset = this.config?.options.bucketDateOffset ?? 'None'
      this.stagedHideTotals = this.config?.options.hideTotals ?? false
      this.stagedBoxValue = this.config?.options.boxValue ?? 2

      if (!this.stagedRowFields?.length) {
        this.stagedRowFields = [this.noneSelectedLabel]
      }

      if (!this.stagedColFields?.length) {
        this.stagedColFields = [this.noneSelectedLabel]
      }
    },
    median (values: number[]) {
      values.sort((a, b) => a - b)
      const half = Math.floor(values.length / 2)
      if (values.length % 2) return values[half]
      return (values[half - 1] + values[half]) / 2
    },
    mean (values: number[]) {
      const average = values.reduce((a, b) => a+b) / values.length
      return average
    },
    updateTableSettings (updateConfig = true) {
      if (
        this.NPSFields.includes(this.stagedValueField) ||
        this.stagedValueField === 'NPS Impact'
      ) {
        this.stagedValueMethod = 'NPS Impact'
      } else if (
        this.NPSFields.includes(this.stagedValueField) ||
        this.stagedValueField === 'NPS'
      ) {
        this.stagedValueMethod = 'NPS'
      }
      const getSummaryLabel = (method: string) => {
        if (method === 'NPS') return 'Average NPS'
        if (method === 'COUNT') return 'Total'
        if (method === 'SUM') return 'Total'
        if (method === 'MEAN') return 'Mean'
        if (method === 'MEDIAN') return 'Median'
        if (method === 'MIN') return 'Min'
        if (method === 'MAX') return 'Max'
      }

      const rowFields = this.stagedRowFields
        .filter((f) => f !== this.noneSelectedLabel)
        .map((f) => this.segmentGetter(f))

      const colFields = this.stagedColFields
        .filter((f) => f !== this.noneSelectedLabel)
        .map(this.segmentGetter)

      this.table = {
        bucketDateOffset: this.stagedBucketDateOffset,
        colorSetMethod: this.stagedColorSetMethod,
        colorPreset: this.stagedColorPreset,
        binColors: this.stagedBinColors.slice(),
        summaryLabel: getSummaryLabel(this.stagedValueMethod),
        valueField: this.stagedValueField,
        valueMethod: this.stagedValueMethod,
        reducer: this.cellReducer,
        hideTotals: this.stagedHideTotals,
        boxValue: this.stagedBoxValue,
        rowFields,
        colFields,
      } as TableSettings

      updateConfig && this.updateConfig()
      this.isZoomed && this.fetchData()

      updateConfig &&
      this.$analytics.track.pivotTable.updateSettings(
        this.table.colorSetMethod,
        `${this.table.valueField}, ${this.table.valueMethod}`,
        this.table.rowFields.map((f: any) => f.name).join(', '),
        this.table.colFields.map((f: any) => f.name).join(', '),
      )
    },
    removeField (arrName: string, index: number) {
      this[arrName] =
        this[arrName].filter((_: any, i: number) => i !== index)
      if (!this[arrName].length) {
        this[arrName] = [this.noneSelectedLabel]
      }
    },
    updateField (arrName: string, index: number, value: string) {
      this[arrName] =
        this[arrName].map((field: string, i: number) => {
          return i === index ? value : field
        })
    },
    updateValueField (field: string) {
      this.stagedValueField = field
      if (!this.valueMethodOptions.includes(this.stagedValueMethod)) {
        this.stagedValueMethod = this.valueMethodOptions[0]
      }

    },
    changeBinsSingleColor (val: string | null) {
      if (!val) return
      const nums = val.match(/\d+/g)
      if (nums) this.stagedBinColors = [
        `rgb(${nums[0]}, ${nums[1]}, ${nums[2]}, 0.05)`,
        `rgb(${nums[0]}, ${nums[1]}, ${nums[2]}, 0.25)`,
        `rgb(${nums[0]}, ${nums[1]}, ${nums[2]}, 0.5)`,
        `rgb(${nums[0]}, ${nums[1]}, ${nums[2]}, 0.75)`,
        `rgb(${nums[0]}, ${nums[1]}, ${nums[2]}, 1)`,
      ]
    },
    changePresetBins (label: TableSettings['colorPreset']) {
      if (label === 'RED_GREEN') {
        this.stagedBinColors = COLOR_PRESETS.RED_GREEN
      } else if (label === 'BLUE_ORANGE') {
        this.stagedBinColors = COLOR_PRESETS.BLUE_ORANGE
      }
    },
    setChartDimensions (width: number, height: number): void {
      this.width = width
      this.height = height
    },
    async fetchData (force = false) {
      if (!this.table) return

      this.hasErrored = false

      let fields: string[] = this.rowFields.concat(this.colFields)
      fields = Array.from(new Set(fields))

      let themes = []
      let cutExclude = [] as {column: string, value: string}[]

      if (fields.includes('Themes')) {
        cutExclude = [ {column: 'group__', value: 'overall__'} ]
        fields = fields.filter((f) => f !== 'Themes')
        themes.push(
          ...this.savedQueries.map((q: SavedQuery) => ({
            value: q.query_value,
            name: `theme_${q.name}`,
          }))
        )
      }

      if (fields.includes('Theme Groups')) {
        cutExclude = [ {column: 'group__', value: 'overall__'} ]
        fields = fields.filter((f) => f !== 'Theme Groups')
        themes.push(
          ...this.themeGroups.map((q: SavedQuery) => ({
            value: q.query_value,
            name: q.name,
          }))
        )
      }

      if (fields.includes('Top Concepts')) {
        cutExclude = [ {column: 'group__', value: 'overall__'} ]
        fields = fields.filter((f) => f !== 'Top Concepts')
        themes.push(
          ...this.concepts.map((c: DashboardSavedQuery) => ({
            value: c.query_value,
            name: `concept_${c.name}`,
          }))
        )
      }

      const blocks = []
      if (
        this.table.valueField === 'Frequency' &&
        this.table.valueMethod === 'PERCENT OF DATA'
      ) {
        blocks.push({
          'aggfuncs': [
            {
              'new_column': 'frequency_cov',
              'aggfunc': 'count',
              'src_column': 'document_id'
            }
          ],
          'metric_calculator': 'coverage',
          'cutfuncs': [
            {
              'new_column': 'value_binned',
              'src_column': 'frequency_cov',
              'bins': '5',
              'exclude': cutExclude,
            }
          ],
        })
      }

      if (
        this.NPSFields.includes(this.table.valueField) ||
        this.table.valueField === 'NPS Impact' ||
        this.stagedValueField === 'NPS'
      ) {
        blocks.push({
          'aggfuncs': [
            {
              'src_column': 'document_id',
              'new_column': 'frequency',
              'aggfunc': 'count',
            },
          ],
          'cutfuncs': [
            {
              'new_column': 'value_binned',
              'src_column': this.valueKey,
              'exclude': cutExclude,
              'bins': '5',
            }
          ],
          'metric_calculator': 'nps',
          'pivot_field': 'NPS Category',
        })
      }

      if (
        this.table.valueField === 'Sentiment' ||
        fields.includes('Sentiment')
      ) {
        fields = fields.filter((f) => f !== 'Sentiment')
        blocks.push({
          'aggfuncs': [
            {
              'new_column': 'frequency',
              'src_column': 'document_id',
              'aggfunc': 'count',
            }
          ],
          'pivot_field': 'sentiment__',
          'metric_calculator': 'sentiment',
        })
      }

      const isBoxAgg = ['TOP BOX', 'BOTTOM BOX'].includes(this.table.valueMethod)
      const column = this.schema.find((row) => row.name === this.table?.valueField)

      // Default block
      if (blocks.length === 0) {
        let aggfunc = this.table.valueMethod.toLowerCase()
        if (aggfunc === 'percent of row') {
          aggfunc = 'count'
        }

        let metric_calculator = undefined

        if (isBoxAgg && column) {
          const box_values = getBoxValues(
            this.table.valueMethod.startsWith('TOP') ? 'top' : 'bottom',
            column.score_range!,
            this.table.boxValue,
          )
          metric_calculator = {
            type: 'box',
            field: this.table.valueField,
            impact: false,
            box_values,
          }
        }

        blocks.push({
          'aggfuncs': [
            {
              'new_column': isBoxAgg ? 'frequency' : 'value',
              'aggfunc': isBoxAgg ? 'count' : aggfunc,
              'src_column': ['Frequency'].includes(this.table.valueField)
                ? 'document_id'
                : isBoxAgg ? 'document_id' : this.table.valueField,
            },
          ],
          'cutfuncs': isBoxAgg ? [] : [
            {
              'new_column': 'value_binned',
              'src_column': 'value',
              'exclude': cutExclude,
              'bins': '5',
            }
          ],
          metric_calculator,
          "pivot_field": isBoxAgg ? this.table.valueField : undefined,
        })
      }

      let dateAgg = {}

      if (this.bucketDateField && this.table.bucketDateOffset !== 'None') {
        fields = fields.filter((f) => f !== this.bucketDateField)
        const bucketDateOffset = this.table.bucketDateOffset as keyof typeof DATE_BUCKET_OPTIONS
        dateAgg = {
          'date_fieldname': this.bucketDateField,
          'date_aggregation_offset': DATE_BUCKET_OPTIONS[bucketDateOffset],
          'week_start': this.weekStart,
        }
      }

      try {
        const requirements = {
          'blocks': blocks,
          'agg_fields': fields,
          'queries': themes,
          'filters': [],
          ...dateAgg,
        }
        this.$emit('requires',
          'pivot-table',
          requirements,
          true,
          true // ensure this request has filters applied
        )
      } catch (e) {
        console.error(e)
        this.hasErrored = true
      }
    },
    // Determines which group(s) (row or column) a datum belongs to
    segmentGetter (field: string): VectorGetter {
      return {
        label: field,
        getter: (item) => {
          if (field === 'Sentiment') {
            return ['Positive', 'Negative', 'Neutral', 'Mixed']
          }

          const isTheme = item['group__'].startsWith('theme_')
          if (field === 'Themes' && isTheme) {
            return [item['group__'].replace(/^theme_/, '')]
          }

          const isGroup = item['group__'].startsWith('group_')
          if (field === 'Theme Groups' && isGroup) {
            return [item['group__'].replace(/^group_/, '')]
          }

          const isConcept = item['group__'].startsWith('concept_')
          if (field === 'Top Concepts' && isConcept) {
            return [item['group__'].replace(/^concept_/, '')]
          }

          return [item[field as keyof typeof item]]
        },
      }
    },
    cellReducer (data: any[], keys: string[], formatValue = true) {
      const getValue = (): number => {
        if (data.length === 0 || !this.table) return 0

        const values: number[] = []

        // Get the coverage &, except in the case of sentiment, which
        // due to the way the pivot endpoint's metric calculation works
        // has its segments' % figures  all contained within a single
        // datapoint (in sentiment__|<segment>%__ attributes).
        if (
          this.table.valueField === 'Frequency' &&
          this.table.valueMethod === 'PERCENT OF DATA' &&
          !keys.find((k) => k.includes('Sentiment'))
        ) {
          const vals = data.map((i) => i.coverage_doc_rto__ ?? 0)
          values.push(_.sum(vals) * 100)
        }

        // We display % of positive sentiment when sentiment % is
        // selected as the value field & calculation.
        if (
          this.table.valueField === 'Sentiment' &&
          this.table.valueMethod === 'Positive PERCENT'
        ) {
          const vals = data.map((i) => i['sentiment__|positive%__'] ?? 0)
          values.push(_.mean(vals))
        } else if (
          this.table.valueField === 'Sentiment' &&
          this.table.valueMethod === 'Negative PERCENT'
        ) {
          const vals = data.map((i) => i['sentiment__|negative%__'] ?? 0)
          values.push(_.mean(vals))
        } else if (
          this.table.valueField === 'Sentiment' &&
          this.table.valueMethod === 'Mixed PERCENT'
        ) {
          const vals = data.map((i) => i['sentiment__|mixed%__'] ?? 0)
          values.push(_.mean(vals))
        }

        const getForKey = (key: string) => {
          values.push(...data.map((i) => i[key] ?? 0))
        }

        if (this.table.valueField === 'Frequency') {
          const suffix = this.table.valueMethod === 'PERCENT OF DATA'
            ? '%__'
            : ''

          // For GrandTotal cells relating to sentiment, we have to gather
          // the data from several attributes on the same datapoint. Normally
          // we'd expect a datapoint for each cell in the row/col to exist.
          // Get sentiment values for grandTotal if:
          // - Sentiment is part of the submitted fields
          // - The value method is 'percent of Row' and Sentiment is
          // part of the columns
          keys.forEach((k) => {
            if (k.includes('GrandTotal')) {
              const fields = k.split(':')[1].split('|')
              if (
                fields.includes('Sentiment') ||
                (this.table?.valueMethod === 'PERCENT OF ROW' && this.colFields.includes('Sentiment'))
              ) {
                getForKey(`sentiment__|positive${suffix}`)
                getForKey(`sentiment__|negative${suffix}`)
                getForKey(`sentiment__|neutral${suffix}`)
                getForKey(`sentiment__|mixed${suffix}`)
              }
            }
          })

          // Get individual sentiment values
          if (keys.includes('Sentiment:Positive')) {
            getForKey(`sentiment__|positive${suffix}`)
          }
          if (keys.includes('Sentiment:Negative')) {
            getForKey(`sentiment__|negative${suffix}`)
          }
          if (keys.includes('Sentiment:Neutral')) {
            getForKey(`sentiment__|neutral${suffix}`)
          }
          if (keys.includes('Sentiment:Mixed')) {
            getForKey(`sentiment__|mixed${suffix}`)
          }
        }

        // Gather data for all other value fields
        getForKey(this.valueKey)

        // Aggregate values
        if (this.table.valueMethod === 'MEAN') {
          return _.sum(values) / data.length
        }
        if (this.table.valueMethod === 'MEDIAN') {
          return this.median(values)
        }
        if (this.table.valueMethod === 'MIN') {
          return _.min(values) || 0
        }
        if (this.table.valueMethod === 'MAX') {
          return _.max(values) || 0
        }
        if (
          this.table.valueMethod === 'NPS' ||
          this.table.valueMethod === 'NPS Impact'
        ) {
          return this.mean(values)
        }

        if (
          this.table.valueMethod === 'TOP BOX' ||
          this.table.valueMethod === 'BOTTOM BOX'
        ) {
          // Calculate weighted average of multiple percentages
          return calcWeightedAverage(data.map((d) => ({
            value: d[this.valueKey] ?? 0,
            weight: d[`${this.table?.valueField}|total#__`]
          })))
        }

        // COUNT, SUM
        return _.sum(values)
      }

      // Round to 2 decimal places
      let val = Math.round(getValue() * 100) / 100
      return formatValue ? comma(val) : val
    },
    methodLabel (method: string) {
      if (method === 'PERCENT OF DATA') return 'PERCENT %'
      if (method === 'TOP BOX') {
        return `TOP ${this.table?.boxValue} BOX (%)`
      }
      if (method === 'BOTTOM BOX') {
        return `BOTTOM ${this.table?.boxValue} BOX (%)`
      }
      return method
    },
    calculateValueLabel (table): string {
      let label = this.methodLabel(table.valueMethod)
      let group = ""
      if (table.valueField === "NPS Impact" || table.valueField === "NPS") {
      } else {
        group = ` of ${table.valueField}`
      }
      return `${label}${group}`
    },
    getCsvData () {
      this.$analytics.track.pivotTable.exportCSV()
      return this.$refs.pivot.toCSV()
    },
    trackSort (type: string, vector: Record<string, string>) {
      this.$analytics.track.pivotTable.sortClick(
        `${type}: ${JSON.stringify(vector.item)}`,
        vector.direction,
      )
    },
    // error panel items
    refresh () {
      window.location.reload()
    },
    reload () {
      this.fetchData(true)
    },
    contact () {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    },
    makePptSlide (pptx: PptxGenJS) {
      // Pivot Table hasn't been rendered (e.g not zoomed)
      if (!this.$refs.pivot) return

      const slide = pptx.addSlide()

      const rows = this.$refs.pivot.generatePptTable(
        {
          fill: 'F7F9FA',
          color: '333333',
          align: 'center',
          bold: true,
        },
        {}
      )

      slide.addTable(rows, {
        x: 0.5,
        y: 0.5,
        border: {
          color: 'E7E8E8',
        },
        colW: [2, 0.5],
      })
    },
    setMaxHeight (open: boolean, e: Event) {
      if (open) {
        const el = e.target as HTMLElement
        const wrapperEl = el.closest('.dropdown') as HTMLElement
        const widgetEl = el.closest('.pivot-table') as HTMLElement
        const dropdownEl = wrapperEl?.querySelector('.dropdown-menu') as HTMLElement

        // Check to see if we got a DOM element to work with
        // getBoundingClientRect() on failure to fetch element has known issues
        if (dropdownEl == null) {
          return
        }

        const widgetBottom = widgetEl.getBoundingClientRect().bottom
        const dropdownTop = dropdownEl?.getBoundingClientRect().top
        const maxHeight = widgetBottom - dropdownTop - 20

        dropdownEl.style.maxHeight = `${maxHeight}px`
      }

    },
  },
})

export default PivotTable
</script>

<style lang="sass" scoped>
  @import '~assets/kapiche.sass'
  .error-panel
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding-bottom: 30px
  .message
    display: flex
    flex-direction: row
    justify-content: center
    background-color: rgba(255,0,0, 0.1)
    padding: 6px
    color: $text-black
    width: 100%
    max-height: 30px
    position: absolute
    bottom: 0
  .errorIcon
    position: relative
    height: 32px
    width: 32px
    display: inline-block
    top: 10px
  .action
    padding-top: 20px
  button
    background: none
    padding: 3px 4px
    border: 1px solid $blue-light
    &:hover
      background-color: $grey-light
    &:focus
      border: 1px solid $blue-light
      outline: none
  .concept-table
    th
      text-align: left

  .heatmap-cell
    height: 100%
    width: 100%
    position: absolute
    left: 0
    top: 0
    display: flex
    align-items: center
    justify-content: center
    &.bin-0
      background: var(--bg-color-0)
    &.bin-1
      background: var(--bg-color-1)
    &.bin-2
      background: var(--bg-color-2)
    &.bin-3
      background: var(--bg-color-3)
    &.bin-4
      background: var(--bg-color-4)

  ::v-deep
    .el-dialog__body
      padding-top: 0

    footer
      display: none !important

    .main.panel
      max-width: 100%
    .content
      max-width: 100%
      .table-wrapper
        position: relative
        width: 100%
        height: 100%
        padding: 20px
        .scroll
          width: 100%
          height: 100%
          overflow: auto


    .header
      text-align: center
      .header-content
        display: flex
        align-items: center
        > span:nth-child(1)
          flex: 1
          margin-left: 14px
        > span:nth-child(2)
          opacity: 0
          width: 14px
          transition: opacity 0.2s
          text-align: right
          &.sorted
            opacity: 1
        &:hover
          > span:nth-child(2)
            opacity: 1

    .settings-section
      display: flex
      margin-top: 1rem
      align-items: center
      margin-bottom: 10px
      button
        margin-left: auto
        &:focus
          border-width: 1px
      h4
        margin: 0
        font-weight: 700

    .settings-row
      margin-bottom: 20px
      > div
        display: flex
        align-items: center
        > a
          margin-left: 6px
          user-select: none
          color: $red
          &:hover
            text-decoration: none
        > :not(:last-child)
          margin-right: 6px
      &.colors > div
        align-items: flex-start
        flex-direction: column
        display: flex
    .checkbox
        padding: 0.5rem
        padding-top: 0.7rem
    .checkbox-text
          font-style: italic
          color: $grey-dark



    .unclickable
      pointer-events: none

    .single-color
      margin-bottom: 10px

    .presets
      display: flex
      flex-direction: column
      label
        display: flex
        align-items: center
      > :not(:last-child)
        margin-bottom: 3px

    .save-button
      width: 100%

  .content, .controls
    div.dropdown
      background: #fff
      padding: 8px 12px
      border: 1px solid $grey-light
      cursor: pointer
      width: 100%
      color: $text-black
      &.unselected
        color: $subdued
      i.dropdown
        margin-top: 1px
        margin-left: auto
        color: $text-black
        justify-content: end

  ::v-deep
    .dropdown-item.disabled
      opacity: 0.5
    .menu-list .dropdown-menu
      position: absolute !important
      width: 100%
    .dropdown-trigger
      display: flex
      width: 100%

    .button-wrapper
      background: #fff
      position: sticky
      bottom: -20px
      margin-top: 20px
      padding: 10px

  .empty-message
    display: flex
    flex: 1
    text-align: center
    align-items: center
    font-size: 16px
    color: $subdued

  .percent-of-row-warning
    font-weight: bold
    color: $orange-light
    a
      text-decoration: none !important

  .bucket-date
    margin-top: 10px
    white-space: nowrap
    > span
      font-style: italic

  .box-input
    display: flex
    align-items: center
    margin-top: 4px
    .el-input-number
      margin-left: auto
</style>
