import dayjs from 'dayjs'
import numeral from 'numeral'
import _ from 'lodash'
export * from './markdown'
export * from './noValue'

import util from '../general'

export function number (number: number|string, format?: string): string {
  let result = ''
  if (format !== undefined) {
    result = numeral(number).format(format)
  } else {
    result = numeral(number).format('0,0.[00]')
  }
  // Omit the sign for zero
  if (result === '+0') {
    return result.slice(1,)
  } else {
    return result
  }
}

export function decimalAsPercent (number: number|string): string {
  return numeral(number).format('0,0.[00]%')
}

/**
 * Capitalize the first character of a string.
 * @param {string} text
 * @returns {string}
 */
export function capitalize (text: string): string {
  if (!text) {
    return ''
  }
  return text[0].toUpperCase() + text.slice(1)
}

/**
 * Capitalize all of the words in a string. As in, capitalise the first letter of every word. e.g.:
 * 'hello my name is chris' > 'Hello My Name Is Chris'. Useful for capitalising labels which are stored in dom
 * attributes, which are normally lowercase. Handles the special case of NPS, which should always be capitalised.
 * @param {string} text
 * @param {Set} alwaysUp - A set of words to always uppercase
 * @returns {string}
 */
// export const cap = (text, alwaysUp = new Set(['NPS'])) => text === '' || alwaysUp.has(text.toUpperCase()) ? text.toUpperCase() :
// export const capAllTwo = (text, alwaysUp = new Set(['NPS'])) => text.split(' ').map(w=>capitalize(w, alwaysUp)).join(' ')

export function capitalizeAll (text: string, alwaysUp = new Set(['NPS'])): string {
  if (!text) {
    return ''
  }
  const words = text.split(' ')
  let result = ''
  for (let word of words) {
    if (result.length > 0) {
      result += ' '
    }
    // Handles special cases which should always be uppercase (e.g. NPS)
    const upperCaseWord = word.toUpperCase()
    if (alwaysUp.has(upperCaseWord)) {
      result += upperCaseWord
    } else {
      result += word[0].toUpperCase() + word.slice(1)
    }
  }
  return result
}

export function date (theDate: dayjs.ConfigType): string {
  return dayjs(theDate).fromNow()
}

export function formatNPS (score: number|string): string {
  score = Number(score)
  if (score===0) return '0'
  const formatted = numeral(score).format('0.00')
  return score > 0 ? `+${formatted}` : formatted
}

export function formatDataUnits (val: number|string): string {
  return numeral(val).format('0,0')
}

// Date format
const DATE_FORMAT = 'DD-MMM-YYYY'
const DATE_FORMAT_TIME = 'DD-MMM-YYYY hh:mma'

export function formatDate (sourceDate: dayjs.ConfigType): string {
  return dayjs(sourceDate).format(DATE_FORMAT)
}

export function formatDateTime (sourceDate: dayjs.ConfigType): string {
  return dayjs(sourceDate).format(DATE_FORMAT_TIME)
}

// Add 's' to end of descPhrase if dataset has more than one entry
export function pluraliseDesc (dataset: Array<unknown>, descPhrase: string): string {
  if (dataset.length > 1) {
    descPhrase += 's'
  }
  return `${dataset.length} ${descPhrase}`
}

// Truncate text, optionally specifying truncation length `len`
export function truncate (text: string, len?: number): string {
  if (len) {
    return util.truncateText(text, len)
  }
  return util.truncateText(text)
}

export function startCase (text?: string): string {
  if (text === undefined) {
    return ''
  }
  // Don't match numerals, only match letters and underscores. lodash separates
  // numbers i.e. "S3" becomes "S 3".
  return text.replace(/^([A-Z_]?[a-z]+)+/, _.startCase)
}

export function formatInterval (text: string): string {
  if (text.startsWith('1')) {
    // remove the trailing s, this isn't plural
    return text.split(' ')[1].slice(0, -1)
  }
  return text
}

export const comma = (num: number) => {
  return num.toLocaleString('en', { useGrouping: true })
}

// Calculate percent to 2 decimal places
export const percent = (num: number, total: number) => {
  const n = Math.round((num / total) * 10000) / 100
  return Number.isFinite(n) ? n : 0
}
