<template>
  <div id="wrapper" ref="wrapper">
    <!-- Automatically determines whether to display an error banner -->
    <error-banner :has-sidebar="hasSidebar" />
    <session-banner
      :has-sidebar="hasSidebar"
    />
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import { POLL_BACKEND } from 'src/store/types'
  import ErrorBanner from 'components/widgets/ErrorBanner.vue'
  import SessionBanner from 'components/widgets/SessionBanner.vue'

  /**
   * A class that wraps a worker and calls a callback on a given interval.
   * We use this instead of setInterval because JS timers become inaccurate
   * when the tab is not in focus. This is a problem for us because we poll
   * the backend for new data every 10 seconds, and we want to keep doing
   * that even if the user is not looking at the tab. The primary reason for
   * that is to prevent auth0 logging out the session. It calls "refresh"
   * inside the poll action, and the delay introduced by the inaccurate
   * timers, as well as other internal workings in auth0, can result in
   * the session being logged out prematurely.
   *
   * @param callback The callback to call on the given interval.
   * @param interval The interval to call the callback on.
   */
  class WorkerInterval {
    // From:
    // https://stackoverflow.com/a/75828547/170656
    worker: Worker|null = null

    constructor (callback: () => void, interval: number) {
      const blob = new Blob([`setInterval(() => postMessage(0), ${interval})`])
      const workerScript = URL.createObjectURL(blob)
      this.worker = new Worker(workerScript)
      this.worker.onmessage = callback
    }

    stop (): void {
      this.worker?.terminate()
    }
  }

  export default defineComponent({
    components: {
      ErrorBanner,
      SessionBanner,
    },
    data () {
      return {
        dataPoll: null as WorkerInterval | null,
        versionPoll: null as NodeJS.Timeout | null,
        outdated: false,
      }
    },
    computed: {
      ...mapGetters(['currentSite', 'loggedIn', 'appVersion', 'featureFlags']),
      hasSidebar () {
        return this.loggedIn && this.currentSite !== null
      },
    },
    beforeUnmount () {
      if (this.dataPoll !== null) {
        this.dataPoll.stop()
      }
      this.dataPoll = null
    },
    created () {
      this.startPolls()
    },
    methods: {
      ...mapActions({ POLL_BACKEND }),
      poll () {
        try {
          // Don't add poll steps here, add them into this action below.
          this.POLL_BACKEND()
        } catch (e) {
          console.error('Error during poll dispatch', e)
        }
      },
      startPolls () {
        if (this.dataPoll == null) {
          this.poll()
          this.dataPoll = new WorkerInterval(this.poll, 10000)
        }

        if (this.versionPoll == null) {
          this.checkAppVersion()
          // Check version once per hour
          const interval = 1000 * 60 * 60
          this.versionPoll = setInterval(this.checkAppVersion, interval)
        }
      },
      async checkAppVersion () {
          const headers = { "Content-Type": "application/json" }
          fetch('/version.json', { headers })
            .then(response => response.json())
            .then(data => {
              this.outdated = this.appVersion !== data.version
              if (this.outdated) {
                this.$analytics.track.newVersionDuringUserSession({
                  'appVersion': this.appVersion,
                  'fetchedVersion': data.version,
                }),
                this.$notify({
                  title: 'Update available',
                  message: `A new version of Kapiche has been released!
                    Reload the page to use the latest version. <br/ >
                    <a href="javascript:window.location.reload()"> Reload now </a>`,
                  dangerouslyUseHTMLString: true,
                  type: 'info',
                  duration: 0,
                  showClose: false,
                  onClick: () => this.$analytics.track.clickedReloadToast({
                    'appVersion': this.appVersion,
                    'fetchedVersion': data.version,
                  })
                })
              }
            }
          )
      },
      closeUpdateModal () {
        this.outdated = false
        if (this.versionPoll != null) {
          clearInterval(this.versionPoll)
          this.versionPoll = null
        }
      },
    },
  })
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  /* Make sure our wrapper is ALSO the full height of the body (child of div#app) */
  #wrapper
    background-color: $grey-light-background
    height: 100%
    overflow-y: auto

  /* Semantic UI overrides */
  .ui.steps .step.active, .ui.steps .step.active:after, .ui.steps .step.active:before, .ui.steps .step.active .title, .ui.steps .step.active .description
    background-color: #068ccc
    color: white
    font-weight: bold

  .update-modal
    width: 500px !important
    main > div
      padding: 2rem

</style>
