/**
 * All interactions with the /feature-flags/ endpoints are done here.
 */
import HTTPRetryUtil from 'src/utils/httpretry'

async function getAllFeatures (project_id = null, user_id = null, site_id = null) {
  let params: Record<string, number> = {}
  if (project_id) params.project_id = project_id
  if (user_id) params.user_id = user_id
  if (site_id) params.site_id = site_id

  const response = await HTTPRetryUtil.retry('feature-flags/all/', { params } as any)

  let result = {
    diagnostic_exports: false,
    honeycomb_hypanthium: false,
    sentiment_info: false,
    analysis_clone: false,
  }

  response.forEach((flag: keyof typeof result) => result[flag] = true)
  return result
}

export default {
  getAllFeatures,
}
