import dayjs from 'dayjs'
import { PivotData, TrendLine } from 'src/types/widgets.types'
import ChartUtils from 'src/utils/chart'

interface Menu {
  'Date Field': string;
  'Resolution': string;
}

type TimelineRecords = Record<string, Record<string, {countDocument: number}>>


const supportedSeries = ['nps', 'impact', 'promoters', 'passives', 'detractors'] as const
type SupportedSeries = typeof supportedSeries[number]


export const regroupData = (
  data: PivotData,
  dateField: string,
  group: string,
  series: SupportedSeries[],
  nameSuffix='',
): [ TimelineRecords, TrendLine[]] => {

  // Constants related to each series
  const lookup = {
    'nps': {
      color: '#068CCC',
      accessor: 'NPS Category|nps__',
      tooltipCalculator: (dp: Record<string, number>) => {
        return (dp['NPS Category|(No Value)'] ?? 0) +
        (dp['NPS Category|Detractor'] ?? 0) +
        (dp['NPS Category|Passive'] ?? 0) +
        (dp['NPS Category|Promoter'] ?? 0)
      }
    },
    'impact': {
      color: '#f89516',
      accessor: 'NPS Category|npsi_rto__',
       tooltipCalculator: (dp: Record<string, number>) => {
        return (dp['NPS Category|(No Value)'] ?? 0) +
        (dp['NPS Category|Detractor'] ?? 0) +
        (dp['NPS Category|Passive'] ?? 0) +
        (dp['NPS Category|Promoter'] ?? 0)
      }
    },
    'promoters': {
      color: '#21ba45',
      accessor: 'NPS Category|Promoter%__',
       tooltipCalculator: (dp: Record<string, number>) => {
        return (dp['NPS Category|Promoter'] ?? 0)
      }
    },
    'passives': {
      color: '#7f7f7f',
      accessor: 'NPS Category|Passive%__',
       tooltipCalculator: (dp: Record<string, number>) => {
        return (dp['NPS Category|Passive'] ?? 0)
      }
    },
    'detractors': {
      color: '#ee3824',
      accessor: 'NPS Category|Detractor%__',
       tooltipCalculator: (dp: Record<string, number>) => {
        return (dp['NPS Category|Detractor'] ?? 0)
      }
    },
  }

  const makeSeries = (name: TrendLine['name'], color: string): TrendLine => ({
    datetimes: [],
    lineStyle: 'solid-line',
    visible: true,
    counts: [],
    color,
    name,
  })

  const records = Object.fromEntries(series.map(s => [s + nameSuffix, {}])) as TimelineRecords
  const groupedSeries = Object.fromEntries(
    series.map(s => [s, makeSeries(s + nameSuffix, lookup[s].color)])
  ) as Record<SupportedSeries, TrendLine>

  data.payload.forEach((dataPoint) => {
    if (dataPoint.group__ !== group) return

    // dataPoint[dateField] is usually the date for any data point.
    // Resolution matters a lot here.
    // e.g. 2023-10-01T00:00:00.000Z
    // This will convert to epoc timestamp e.g. 1696118400000
    const timestamp = dayjs(dataPoint[dateField]).valueOf()
    for (const s of supportedSeries) {
      if (!series.includes(s)) continue
      groupedSeries[s].counts.push(+dataPoint[lookup[s].accessor])
      groupedSeries[s].datetimes.push(timestamp)
      // Construct Record objects for tooltip stats
      records[s + nameSuffix][dataPoint[dateField]] = {
        countDocument: lookup[s].tooltipCalculator(dataPoint as Record<string, number>)
      }
    }
  })
  return [ records, Object.values(groupedSeries) ]
}

export const getCsvData = (data: TrendLine[], menu: Menu) => {
  const dataByDate: {
    [date: number]: {
      [name: string]: string
    }
  } = {}

  data.forEach((s) => {
    s.datetimes.forEach((d: number, j: number) => {
      if (!dataByDate[d]) dataByDate[d] = {}
      dataByDate[d][s.name] = s.counts[j].toFixed(2)
    })
  })

  return Object.keys(dataByDate).sort().map((d) => {
    const dateString = ChartUtils.formatDate(d, menu['Resolution'].toLowerCase())
    const row = { [menu['Date Field']]: dateString }
    data.forEach(({ name }) => {
      row[name] = dataByDate[+d][name] ?? ''
    })
    return row
  })
}
