import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  approved_domains: {
    domains: null,
    loading: false,
    error: null
  }
}

export type SitesState = typeof state

export default {
  state,
  mutations,
  actions,
  getters
}
