<!--https://vuejs.org/v2/examples/tree-view.html-->
<template>
  <li>
    <div
      class="ui segment"
      :style="{paddingLeft: (depth * indent) + 'px'}"
      @click="toggle"
      @dblclick="makeFolder"
    >
      <toggle-checkbox
        :disabled="!item.selectable"
        :value="selectedKeys.hasOwnProperty(item.key)"
        :show-checkbox="item.selectable"
        :class="{ selectable: item.selectable }"
        @input="$emit('selectionChanged', item, selectedKeys.hasOwnProperty(item.key), !selectedKeys.hasOwnProperty(item.key))"
      >
        <div class="flex-container">
          <div class="flex-grow">
            <!-- icon -->
            <i :class="itemClassLookup(item)"></i>
            <!-- item name -->
            <span
              :class="{bold: itemIsSelected}"
            >
              {{ item.name }} <span v-if="isFolder">/</span>
            </span>
            <!--count of subitems selected-->
            <span v-if="isFolder" class="subitem-selection"> ({{ selChildCount() }} subitems selected)</span>
          </div>
          <div v-if="isFolder && !isOpen">
            <p>&#9660;</p>
          </div>
          <div v-if="isFolder && isOpen">
            <p>&#9650;</p>
          </div>
        </div>
      </toggle-checkbox>
    </div>
    <ul v-show="isOpen" v-if="isFolder">
      <tree-item
        v-for="(child, index) in item.children"
        :key="index"
        class="item"
        :item="child"
        :depth="depth + 1"
        :item-class-lookup="itemClassLookup"
        :selected-keys="selectedKeys"
        @change="change"
        @selectionChanged="selectionChanged"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
      ></tree-item>
      <li v-if="allowAdd" class="add" @click="$emit('add-item', item)">
        +
      </li>
    </ul>
  </li>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import ToggleCheckbox from 'src/components/widgets/ToggleCheckbox.vue'

  export default defineComponent({
    name: 'TreeItem',
    components: { ToggleCheckbox },
    props: {
      item: { type: Object, default: () => null },
      depth: { type: Number, default: 0 },
      showFiles: { type: Boolean, default: true },
      showFolders: { type: Boolean, default: true },
      allowSelectFile: { type: Boolean, default: true },
      allowSelectFolder: { type: Boolean, default: true },
      allowAdd: { type: Boolean, default: false },
      allowMakeFolder: { type: Boolean, default: false },
      indent: { type: Number, default: 30 },
      itemClassLookup: { type: Function, default: (item) => '' },
      selectedKeys: { type: Object, default: () => null },
      startOpen: { type: Boolean, default: false }
    },
    data () {
      return {
        isOpen: this.startOpen,
        itemSelected: false,
        logger: this.$logger.getLogger('TreeItem')
      }
    },
    computed: {
      isFolder: function () {
        return this.item.children && this.item.children.length
      },
      itemIsSelected: function () {
        return this.selectedKeys.hasOwnProperty(this.item.key)
      }
    },
    methods: {
      selChildCount () {
        this.logger.trace(`in selChildCount for item: ${JSON.stringify(this.item)}`)
        // Count how many nested keys appear in the selected Keys
        let nestedChildren = this.item.nestedChildren()
        let nestedKeys = nestedChildren.map((item) => item.key)
        let nestedKeysSet = new Set(nestedKeys)
        let count = 0
        for (const key of Object.keys(this.selectedKeys)) {
          if (nestedKeysSet.has(key)) {
            count = count + 1
          }
        }
        return count
      },
      change (item) {
        this.$emit('change', item)
      },
      selectionChanged (item, oldval, newval) {
        this.$emit('selectionChanged', item, oldval, newval)
      },
      toggle: function () {
        if (this.isFolder) {
          this.isOpen = !this.isOpen
        }
      },
      makeFolder: function () {
        if (!this.allowMakeFolder) {
          return
        }
        if (!this.isFolder) {
          this.$emit('make-folder', this.item)
          this.isOpen = true
        }
      }
    }
  })
</script>
<style lang="sass" scoped>
  @import '../../assets/kapiche.sass'
  @import '../../assets/mixins.sass'

  li
    list-style-type: none

  ul
    padding-left: 0

  i
    font-size: 24px
    color: #95a6ac

  $icon-color: #95a6ac
  $border-color: #d8d8d8

  .flex-container
    display: flex

  .flex-grow
    flex-grow: 1

  .bold
    font-weight: bold

  .subitem-selection
    color: #95a6ac
    font-size: 14px

  div.segments
    background: white
    box-shadow: $box-shadow
    max-height: calc(100vh - 31rem)
    overflow-y: auto
    position: relative
    width: rem(600px)
    /*margin: rem(40px) auto rem(20px) auto*/
    margin: 0 auto 0 auto

    div.segment
      border: 1px solid transparent
      /*Prevent text getting selected when the item is double-clicked*/
      user-select: none
      padding: rem(12px)
      text-align: left
      margin: 1px
      font-size: 16px

      i[class^='icon-']
        margin-right: rem(10px)
        color: $icon-color

      &.header
        background-color: #fafafa
        height: rem(55px)

      &:hover:not(.header):not(.nohover):not(.disabled)
        cursor: pointer
        background: #f4f6f7
        border: 1px solid $blue !important

      &:not(:last-of-type)
        border-bottom: 1px solid #e5e5e5

      .name
        font-size: rem(18px)

      .animated-background
        height: rem(20px)
        width: 100%

      .toggle-checkbox.selectable
        margin-left: 40px

      &::selection
        background: transparent
</style>
