<template>
  <div
    v-if="showErrors"
    class="error-banner"
    :class="{
      'with-sidebar': hasSidebar,
      'connection-error': hasConnectionErrors,
    }"
  >
    <span v-if="hasConnectionErrors">
      Failed to connect to the server. Please refresh the page and contact us if the problem persists.
    </span>
    <span v-else>
      An unexpected error has occured. Please refresh the page and contact us if the problem persists.
    </span>
    <i class="kapiche-icon-delete-thin" @click="clearErrors"></i>
  </div>
</template>
<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import { debounce } from 'lodash'
  import { mapGetters } from 'vuex'

  import { CLEAR_REQUEST_ERRORS } from 'src/store/types'

  export default defineComponent({
    props: {
      hasSidebar: { type: Boolean, default: false }
    },
    computed: {
      ...mapGetters(['currentSite', 'hasRequestErrors', 'serverErrors', 'hasConnectionErrors']),
      showErrors () {
        return this.hasRequestErrors && this.serverErrors.length > 0
      }
    },
    watch: {
      // We debounce the watcher to account for the fact that custom error
      // handling at the component level works by clearing the store errors;
      // when that happens this watcher will be triggered again and we won't
      // want to register an unhandled error (the second call will override
      // the first).
      showErrors: debounce(function (isShown) {
        if (isShown) {
          this.hasConnectionErrors
            ? this.$analytics.track.connectionError()
            : this.$analytics.track.errorUnhandled(
              this.serverErrors
            )
        }
      }, 100)
    },
    methods: {
      clearErrors () {
        this.$store.dispatch(CLEAR_REQUEST_ERRORS)
      }
    },
  })
</script>
<style lang="sass" scoped>
  @import '../../assets/kapiche.sass'
  @import '../../assets/mixins.sass'

  .error-banner
    background-color: $orange
    color: white
    font-size: rem(16px)
    font-weight: bold
    padding-top: rem(25px)
    position: fixed
    height: rem(75px)
    text-align: center
    top: 0
    width: 100%
    z-index: 50
    &.connection-error
      background-color: $subdued
    &.with-sidebar
      height: rem(70px)
      left: 17rem
      width: calc(100% - 17rem)

    .icon-delete-thin
      cursor: pointer
      font-size: rem(12px)
      margin-left: rem(10px)
</style>
