import Project, { COLUMN_LABELED_TYPES } from "src/api/project"
import {ExtraTypeOptionsMap, SchemaTypeIds, SchemaTypeNames} from "types/SchemaTypes"

const ColumnIndexedTypes: Map<SchemaTypeIds, SchemaTypeNames> = Project.COLUMN_INDEXED_TYPES as  Map<SchemaTypeIds, SchemaTypeNames>

export const DISABLED_TYPES: Array<SchemaTypeIds> = [
  COLUMN_LABELED_TYPES.get('IGNORE')
]

export const makeExtraTypeOptions = function (fields: Array<string>): ExtraTypeOptionsMap {
  const desc = 'This column is being used as the source for a remapped column, therefore it cannot have this data type'
  const options = new Map(
    DISABLED_TYPES.map(t => [t, { disabled: true, showTooltip: true, tooltipDescription: desc}])
  )
  return new Map(fields.map(f => [f, options]))
}
