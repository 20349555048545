// import defineRule from '../defineRule'

import { defineRule } from "vee-validate"

defineRule('min', (value: string, [min]: [string]): boolean | string => {
  return typeof value === 'string'
    ? value.length < parseInt(min, 10)
      ? `Your description is too short (${value.length}).   Minimum length is ${min}.`
      : true
    : false
})
