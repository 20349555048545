<template>
  <div>
    <p>
      Add IPv4 prefixes to improve security of enabled exports even further.
    </p>

    <el-button type="primary" @click="configurePrefix('Add')">
      Add IPv4 Address Prefix
    </el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="IPv4 Address Prefix" width="180">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.ipv4_prefix }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Actions">
        <template #default="scope">
          <el-button
            size="small"
            type="danger"
            @click="configurePrefix('Delete', scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { ElMessageBox } from 'element-plus'

  export default defineComponent({
    props: {
      tableData: { type: Array<Record<string, unknown>>, required: true },
    },
    methods: {
      async configurePrefix (action: string, item) {
        // Default to undefined instead of empty string because the input
        // validation doesn't mark the initial empty value as invalid.
        let inputValue = undefined
        if (item) {
          inputValue = item.ipv4_prefix
        }
        try {

          let value = undefined
          if (action !== 'Delete') {
            let result = await ElMessageBox.prompt('Enter prefix below:', `${action} IPv4 Prefix`, {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              inputPattern: /^(\d{1,3}\.?){1,4}$/,
              inputErrorMessage: 'Invalid IPv4 Prefix',
              inputValue: inputValue,
            })
            value = result.value
          }

          if (action === 'Add') {
            this.$emit('prefix-action', action, value)
          } else if (action === 'Delete') {
            this.$emit('prefix-action', action, item)
          }
        } catch (e) {
          // Dialog cancelled
        }
      }
    },
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .items, .el-table
    font-size: 16px
</style>
