<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    class="field-segmentation"
    @resize="width = $event"
  >
    <!--======================== ACTIONS -->
    <template v-if="hasFields" #actions>
      <div>
        <div class="default-actions">
          <download-export-button
            :name="`${exportName} - Commpare Segmentation - ${selectedField}`"
            short-name="Compare Segmentation"
            :is-loading="isLoading"
            :get-el="getChartEl"
            :get-csv-data="getCsvData"
            :get-svg-export-config="getSvgExportConfig"
            :make-ppt-slide="makePptSlide"
            :basic-svg-export="true"
          ></download-export-button>
          <router-link
            v-if="!isZoomed && zoomToRoute"
            class="widget-action expand"
            :to="zoomToRoute"
          >
            <i class="kapiche-icon-fullscreen"></i>
          </router-link>
          <a
            :href="CONST.widget_help_links.field_segmentation"
            class="widget-action help"
            target="_blank"
          >
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Field Segmentation Icon">
    </template>

    <!--======================== HEADING -->
    <template #header>
      Segmentation
    </template>

    <!--======================== MENU -->
    <template #menu>
      <div v-if="hasFields" class="menu-list">
        <widget-menu
          :menus="menus"
          :vertical="isZoomed"
          :bound="$el"
          @onSelect="setMenuSelection"
        />
      </div>
    </template>

    <template #panel>
      <div v-if="hasFields">
        <selector-controls
          :items="fieldNames"
          :quick-options="rowsForChart.length > 500 ? ['TOP 500' ,'TOP 20', 'TOP 10', 'NONE'] : ['ALL', 'TOP 20', 'TOP 10', 'TOP 5', 'NONE']"
          @selected="selection = $event"
        >
          Quick-Select
        </selector-controls>
      </div>
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <template v-if="dataSlice1 && dataSlice2">
          <h3>data (slice 1, slice 2)</h3>
          Start: {{ new Date(dataSlice1.startTime) }}, {{ new Date(dataSlice2.startTime) }}<br />
          Done: {{ new Date(dataSlice1.doneTime) }}, {{ new Date(dataSlice2.doneTime) }}<br />
          Elapsed: {{ (dataSlice1.doneTime - dataSlice1.startTime) / 1000 }} seconds, {{ (dataSlice2.doneTime - dataSlice2.startTime) / 1000 }} seconds<br />
          Status: {{ dataSlice1.status }}, {{ dataSlice2.status }}<br />
          Error: {{ dataSlice1.error }}, {{ dataSlice2.error }}
        </template>
        <hr />
        <ul>
          <li v-for="row in (rowsForChart)">
            {{ row }}
          </li>
        </ul>

        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button> or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button> if the problem persists.
        </div>
        <div v-if="dataSlice1 && dataSlice1.userError" class="message">
          {{ dataSlice1.userError }}
        </div>
        <div v-if="dataSlice2 && dataSlice2.userError" class="message">
          {{ dataSlice2.userError }}
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="hasFields" #content>
      <segmentation-chart
        :headings="headings"
        :legend="legend"
        :rows="visibleRows"
        :max="range['max']"
        :min="range['min']"
        :show-indicator-bar="false"
        :width="width"
        empty-segment-text="(No Value)"
        @hover-legend="legendToolTip = $event"
        @hover-row="updateRowToolTip"
      >
        <template #row-tool-tip>
          <data-tool-tip v-bind="toolTip" />
        </template>
        <template #interaction-menu="{ index }">
          <button @click="clickedSegment(index)">
            Add <b>{{ rowForSelectionIndex(index).segment }}</b> as a filter
          </button>
          <button v-if="featureFlags?.segment_drilldown" @click="drillDown(index)">
            Drill into <b>{{ rowForSelectionIndex(index).segment }}</b>
          </button>
        </template>
      </segmentation-chart>
    </template>
    <template v-else #content>
      <div class="empty-message">
        <p>
          You don't have any <a :href="CONST.intercom_links.FIELDS" target="_blank">fields</a> in
          your dataset.
        </p>
        <p>Fields can help you understand your customers more deeply.</p>
      </div>
    </template>

    <template v-if="showFooter" #footer>
      <div>
        <small>{{ footerText }}</small>
      </div>
      <div>
        <router-link v-if="numberHiddenSegments > 0" :to="zoomToRoute" class="footer-link">
          {{ numberHiddenSegments }}  more segments
        </router-link>
        <div v-else-if="areTruncatedBySelector">
          <b>{{ numberTruncatedSegments }} more segments not shown</b>
        </div>
      </div>
    </template>
  </widget-frame>
</template>

<script lang='ts'>
import PptxGenJS from 'pptxgenjs'
import { computed, defineComponent, inject, onMounted, PropType, ref, watch } from 'vue'
import SegmentationChart from 'components/charts/SegmentationChart/SegmentationChart.vue'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import DataToolTip from 'components/DataWidgets/DataToolTip/DataToolTip.vue'
import icon from 'assets/img/dashboards/dash-segmentation-new.svg'
import SelectorControls from 'components/widgets/SelectorControls/SelectorControls.vue'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { makeRequirements } from './FieldSegmentationWidget.requirements'
import { menu } from './FieldSegmentationWidget.menu'
import { rowsForGroup, sortRows } from 'components/DataWidgets/FieldSegmentationWidget/FieldSegmentationWidget.utils'
import { minMaxValues } from 'components/DataWidgets/ThemesWidget/ThemesWidget.utils'
import { FieldType, MenuOption } from 'types/components/WidgetMenu.types'
import { DataToolTipInterface } from 'types/components/DataToolTip.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { Analytics } from 'src/analytics'
import { TableChartHeading } from 'src/types/components/Charts.types'
import { makeBarChartSlide } from '../DataWidgetUtils'

const CompareSegmentationWidget = defineComponent({
  components: {
    SelectorControls,
    WidgetFrame,
    WidgetMenu,
    SegmentationChart,
    DataToolTip,
    DownloadExportButton,
  },
  props: {
    exportName: { type: String, required: false, default: '' },
    group: { type: String, required: false, default: 'overall__'},
    mode: { type: String, required: false, default: ''},
    sliceOneName: { type: String, required: true },
    sliceTwoName: { type: String, required: true },
    sliceOneQuery: { type: Object, required: false, default: null },
    sliceTwoQuery: { type: Object, required: false, default: null },
    devMode: { type: Boolean, required: false, default: false },
    isZoomed: { type: Boolean, required: false, default: false},
    /** maximum number of rows to show */
    maxRows: { type: Number, required: false, default: null },
    footerText: { type: String, required: false, default: ''},
    /** fields */
    segmentFields: { type: Array as PropType<FieldType[]>, required: false, default:()=>[] },
    /** data to render */
    dataSlice1: { type: Object, required: false, default: null },
    dataSlice2: { type: Object, required: false, default: null },
    hasNps: { type: Boolean, required: false, default: false},
    /** route object for zoom button */
    zoomToRoute: { type: Object, required: false, default: null },
    /** widget banner to display */
    banner: { type: Object, default: ()=>null, required: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'compare-segments'> | null>, required: false, default: null },
  },
  setup (props, { emit }) {
    const analytics = inject<Analytics>('analytics')
    const featureFlags = inject<Record<string, boolean>>('featureFlags')

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const allowShowExpected = ref(false)
    const selectedField = ref<string | null>(null)
    const selectedOrder = ref('Frequency')
    const selection = ref<number[] | null>(null)
    const hoveredRowForToolTip = ref<number | null>(null)
    const legendToolTip = ref(0)
    const width = ref(300)

    const expectedTooltipText = computed(() => {
      let label = ''
      switch (props.mode) {
        case 'overview': label = 'the filtered overall data'; break
        case 'query': label = 'theme'; break
        case 'concept': label = 'concept'; break
      }
      return `Expected frequency is based on the conditional probability of the segment given ${label}`
    })

    const isSubsetofOverall = computed(() => {
      return props.group && props.group !== 'overall__'
    })

    const menus = computed(() => {
      return menu(selectedField.value ?? '', props.segmentFields, selectedOrder.value, props.hasNps, false, true)
    })

    const hasErrored = computed(() => {
      return !!props.dataSlice1 && !!props.dataSlice1.error &&
             !!props.dataSlice2 && !!props.dataSlice2.error
    })

    const isLoading = computed(() => {
      return props.dataSlice1 && props.dataSlice1.status === 'fetching' &&
             props.dataSlice2 && props.dataSlice2.status === 'fetching'
    })

    /** the rows for the currently selected field */
    const groupRows = computed(() => {
      if (!props.group || !selectedField.value) return []

      const rows_slice1 = rowsForGroup(
        props.dataSlice1?.data?.payload,
        props.sliceOneQuery ? 'filter' : props.group,
        selectedField.value,
      )

      const rows_slice2 = rowsForGroup(
        props.dataSlice2?.data?.payload,
        props.sliceTwoQuery ? 'filter_compare' : props.group,
        selectedField.value,
      )

      // merge the two slices
      const rows = rows_slice1.map((row) => {
        const compareRow = rows_slice2.find((r) => r.segment === row.segment)
        return {
          ...row,
          frequency_compare: compareRow?.frequency ?? 0,
          freqPercent_compare: compareRow?.freqPercent ?? 0,
          diff: (compareRow?.freqPercent ?? 0) - row.freqPercent,
          nps_compare: compareRow?.['NPS Category|nps__'] ?? 0,
          nps_diff: (compareRow?.['NPS Category|nps__'] ?? 0) - row?.['NPS Category|nps__'],
          nps: row?.['NPS Category|nps__'] ?? 0
        }
      })

      return sortRows(rows, selectedOrder.value)
    })

    const numberHiddenSegments =  computed(() => {
      if (!groupRows.value || !props.maxRows || isLoading.value || hasErrored.value) return 0
      return groupRows.value?.length - props.maxRows
    })

    const rowsForChart = computed(() => {
      const rows = groupRows.value.slice(0, props.maxRows ?? Infinity)
      if (showingNPS.value) {
        return rows.map((data) => {
          return {
            label: data.segment,
            indicator: null,
            bars: [
              { percent: data.nps, color: '#11ACDF' },
              { percent: data.nps_compare, color: '#8064AA' },
            ],
            columns: [
              { value: data.nps, label: data.nps.toFixed(2) },
              { value: data.nps_compare, label: data.nps_compare.toFixed(2) },
              { value: data.nps_diff, label: data.nps_diff.toFixed(2) },
            ],
          }
        })
      } else {
        return rows.map((data) => {
          return {
            label: data.segment,
            indicator: null,
            bars: [
              { percent: data.freqPercent, color: '#11ACDF' },
              { percent: data.freqPercent_compare, color: '#8064AA' },
            ],
            columns: [
              { value: data.freqPercent, label: `${data.freqPercent.toFixed(2)}%` },
              { value: data.freqPercent_compare, label: `${data.freqPercent_compare.toFixed(2)}%` },
              { value: data.diff, label: `${data.diff.toFixed(2)}%` },
            ],
          }
        })
      }
    })

    const range = computed((): { min: number, max: number } => {
      // min/max values are rounded (down for min, up for max) to next integer)
      const minMax = minMaxValues(rowsForChart.value, selectedField.value, selectedOrder.value, false, 1)
      return minMax ?? { min: 0, max: 0 }
    })

    const visibleRows =  computed(() => {
      if (isLoading.value || !rowsForChart.value) return []
      if (!selection.value || selection.value.length > rowsForChart.value.length) {
        return rowsForChart.value.slice(0, 20)
      }
      return selection.value.map((s) => rowsForChart.value[s])
    })

    // The number of segments truncated by the selector controls
    const numberTruncatedSegments =  computed(() => {
      return rowsForChart.value.length - visibleRows.value.length
    })

    const legend = computed(() => {
      return [
        { label: props.sliceOneName, color: '#11ACDF' },
        { label: props.sliceTwoName, color: '#8064AA' },
      ]
    })

    const showingNPS = computed(() => {
      return selectedOrder.value === 'Highest NPS' || selectedOrder.value === 'Lowest NPS'
    })

    const hasFields = computed(() => {
      return !!(props.segmentFields && props.segmentFields.length)
    })

    /** Are the rows truncated based on the selector controls */
    const areTruncatedBySelector = computed(() => {
      return visibleRows.value.length < rowsForChart.value.length
    })

    const showFooter = computed(() => {
      return hasFields.value && (numberHiddenSegments.value > 0 || props.footerText !== '' || areTruncatedBySelector.value)
    })

    const fieldNames = computed(() => {
      return hasFields.value ? groupRows.value.map(r=>r.segment) : []
    })

    const headings = computed(() => {
      let headings: TableChartHeading[] = [{ label: selectedField.value! }]
      if (showingNPS.value) {
        headings.push({ label:'NPS', color: '#11ACDF' })
        headings.push({ label:'NPS', color: '#8064AA' })
        headings.push({ label:'Diff.', color: '#95A6AC' })
      } else {
        headings.push({ label:'Frequency', color: '#11ACDF' })
        headings.push({ label:'Frequency', color: '#8064AA' })
        headings.push({ label:'Diff.', color: '#95A6AC' })
      }
      return headings
    })

    const toolTip = computed(() => {
      if (
        hoveredRowForToolTip.value === null ||
        !visibleRows.value ||
        !(hoveredRowForToolTip.value < visibleRows.value.length)
      ) return null

      const row = rowForSelectionIndex(hoveredRowForToolTip.value)
      if (!row) return null

      let toolTip: {
        title: string
        action: string
        data: DataToolTipInterface[]
      } = {
        title: row.segment !== '' ? row.segment : '(No Value)',
        action: 'Click for explore options',
        data: [
          {
            label: `${props.sliceOneName} frequency (#/%)`,
            value: {
              text: `${row.frequency} / ${row.freqPercent?.toFixed(2)}%`,
              style: { 'font-weight': 'bold', color: '#11ACDF' },
            }
          },
          {
            label: `${props.sliceTwoName} frequency (#/%)`,
            value: {
              text: `${row.frequency_compare} / ${row.freqPercent_compare?.toFixed(2)}%`,
              style: { 'font-weight': 'bold', color: '#8064AA' },
            }
          },
        ]
      }

      if (props.hasNps && row['NPS Category|nps__']) {
        toolTip.data = toolTip.data.concat(
          {
            label: `${props.sliceOneName} NPS`,
            value: {
              text: row.nps,
              style: row['NPS Category|nps__'] === '' ? { 'color': '#95a6ac' } : { 'font-weight': 'bold' },
            }
          },
          {
            label: `${props.sliceTwoName} NPS`,
            value: {
              text: row.nps_compare,
              style: row['NPS Category|nps__'] === '' ? { 'color': '#8064AA' } : { 'font-weight': 'bold' },
            }
          },
        )
      }

      return toolTip
    })

    const rowForSelectionIndex = (selectionIndex?: number|null) => {
      if (
        selectionIndex === undefined ||
        selectionIndex === null ||
        !groupRows.value ||
        !groupRows.value?.length
      ) return null

      let indexOf = 0
      if (selection.value === null) {
        indexOf = selectionIndex
      } else {
        indexOf = selection.value[selectionIndex]
      }
      if (indexOf > groupRows.value.length) return {} as any
      return groupRows.value[indexOf]
    }

    const clickedSegment = (index: number) => {
      const row = rowForSelectionIndex(index)
      if (row) {
        emit('segment-clicked', selectedField.value, row.segment === '(No Value)' ? '' : row.segment)
      }
    }

    const drillDown = (index: number) => {
      const row = rowForSelectionIndex(index)
      if (row) {
        emit('go-to-segment', selectedField.value, row.segment)
      }
    }

    const fetchData = (force = false) => {
      const requirements_slice1 = makeRequirements(props.hasNps, selectedField.value!, props.sliceOneQuery)
      const requirements_slice2 = makeRequirements(props.hasNps, selectedField.value!, props.sliceTwoQuery)
      emit('requires', 'segmentation_slice1', { ...requirements_slice1 }, force, !props.sliceOneQuery)
      emit('requires', 'segmentation_slice2', { ...requirements_slice2 }, force, !props.sliceTwoQuery)
    }

    const refresh = () => window.location.reload()
    const reload = () => fetchData(true)
    const contact = () => {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    }

    const getChartEl = () => {
      return root.value?.$el.querySelector('.svg-container')
    }

    const getSvgExportConfig = () => {
      const svgEl = getChartEl()
      if (!svgEl) return null

      const bb = svgEl.getBoundingClientRect()
      return {
        dims: {
          height: bb.height,
          width: bb.width,
        },
        css: `
          svg {
            top: 0;
            left: 0;
          }
          .chart {
            background-color: #fff;
            cursor: default;
          }
          text.first {
            text-anchor: start;
          }
          text.end {
            text-anchor: end;
          }
          rect.bar-0 {
            fill: #11ACDF;
          }
          rect.bar-1 {
            fill: #8064AA;
          }
          rect.background {
            fill: #F1F1F1;
          }
          rect.indicator {
            fill: #8064AA;
          }
          text.sorted {
            fill: #068CCC;
            font-weight: bold;
          }
          .legend {
            cursor: default;
          }
        `
      }
    }

    const getCsvData = () => {
      if (selectedField.value === null) return []
      return groupRows.value.map((row) => {
        let out = {
          [selectedField.value ?? '']: row.segment,
          [`${props.sliceOneName} Frequency (#)`]: row.frequency,
          [`${props.sliceOneName} Frequency (%)`]: row.freqPercent,
          [`${props.sliceTwoName} Frequency (#)`]: row.frequency_compare,
          [`${props.sliceTwoName} Frequency (%)`]: row.freqPercent_compare,
        }
        if (props.hasNps && row['NPS Category|nps__']) {
          out['NPS'] = row['NPS Category|nps__']
        }
        return out
      })
    }

    const updateConfig = () => {
      const options: NonNullable<typeof props.config>['options'] = {
        order: selectedOrder.value,
        field: selectedField.value,
        allowShowExpected: allowShowExpected.value,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const setShowExpected = (showExpected: boolean, fromConfig: boolean) => {
      allowShowExpected.value = showExpected
      fetchData()

      // don't track programmatic setting of this option
      if (!fromConfig) {
        analytics?.track.segmentationChart.changeShowExpected(allowShowExpected.value)
        updateConfig()
      }
    }

    const setMenuSelection = (menu: string, [_, selection]:[unknown, string], fromConfig: boolean) => {
      switch (menu) {
        case 'Field': {
          // set field selection only if the selection exists on the menu, if it doesn't then
          // set it to the first item on the menu
          const fieldMenuOptions = menus.value[0].options?.[0]?.[0]?.options
          if (!fieldMenuOptions) return
          if (selection && fieldMenuOptions?.some(
              (option: MenuOption) => (typeof option === 'string' ? option : option.value) === selection
            )) {
            selectedField.value = selection
          } else {
            selectedField.value = typeof fieldMenuOptions[0] === 'string' ? fieldMenuOptions[0] : fieldMenuOptions[0]?.value ?? null
          }
          fetchData()

          // don't track programmatic setting of this option
          if (!fromConfig) {
            analytics?.track.segmentationChart.changeField(selection, props.group)
          }
          break
        }
        case 'Sort By':
          // when the widget switches from showing expected we might need
          // to reset the sort by selection as it might now be invalid
          const sortByMenuOptions = menus.value[1].options?.[0]?.[0]?.options
          if (sortByMenuOptions?.includes(selection)) {
            selectedOrder.value = selection
          } else {
            selectedOrder.value = 'Frequency'
          }
          if (!fromConfig) {  // don't track programmatic setting of this option
            analytics?.track.segmentationChart.changeOrder(selection, props.group)
          }
          break
      }
      if (!fromConfig) {
        updateConfig()
      }
    }

    const setOptionsFromConfig = () => {
      const sortBy = props.config?.options?.order ?? 'Frequency'
      const field = props.config?.options?.field ?? props.segmentFields?.[0]?.name
      const allowShowExpected = props.config?.options.allowShowExpected ?? true

      setMenuSelection('Sort By', [null, sortBy], true)
      setMenuSelection('Field', [null, field], true)
      setShowExpected(allowShowExpected, true)
    }

    const updateRowToolTip = (index: number) => {
      hoveredRowForToolTip.value = index
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      if (!rowsForChart.value.length) return

      const slide = pptx.addSlide()

      makeBarChartSlide(
        pptx,
        slide,
        [
          {
            name: props.sliceOneName,
            labels: rowsForChart.value.flatMap((r) => [r.label]),
            values: rowsForChart.value.flatMap((r) => [r.columns[0].value]),
          },
          {
            name: props.sliceTwoName,
            labels: rowsForChart.value.flatMap((r) => [r.label]),
            values: rowsForChart.value.flatMap((r) => [r.columns[1].value]),
          },
        ],
        `${props.exportName} - Compare Segmentation`,
        'Frequency (%)',
        selectedField.value ?? '',
        {
          chartColors: ['11ACDF', '8064AA'],
          valAxisMaxVal: 100,
          showLegend: true,
        }
      )
    }

    watch(() => props.config, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch(() => props.sliceOneQuery, () => {
      setMenuSelection ('Sort By', [null, selectedOrder.value], true)
      fetchData()
    }, {
      deep: true,
    })

    watch(() => props.sliceTwoQuery, () => {
      setMenuSelection ('Sort By', [null, selectedOrder.value], true)
      fetchData()
    }, {
      deep: true,
    })

    watch(() => props.group, () => {
      fetchData()
    })

    onMounted(() => {
      setOptionsFromConfig()
      if (!selectedField.value) {
        setMenuSelection ('Field', [null, props.segmentFields?.[0]?.name], true)
      }
      fetchData()
    })

    return {
      expectedTooltipText,
      isSubsetofOverall,
      menus,
      hasErrored,
      isLoading,
      numberHiddenSegments,
      rowsForChart,
      visibleRows,
      numberTruncatedSegments,
      legend,
      hasFields,
      areTruncatedBySelector,
      showFooter,
      fieldNames,
      headings,
      rowForSelectionIndex,
      toolTip,
      clickedSegment,
      icon,
      errorIcon,
      range,
      refresh,
      reload,
      contact,
      root,
      getSvgExportConfig,
      getChartEl,
      getCsvData,
      setShowExpected,
      setMenuSelection,
      updateRowToolTip,
      selectedField,
      allowShowExpected,
      width,
      legendToolTip,
      selectedOrder,
      makePptSlide,
      featureFlags,
      drillDown,
    }
  }
})

export default CompareSegmentationWidget
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  div.empty-message
    text-align: center
    font-size: 1.4rem
    color: rgba(149, 166, 172, 0.9)
    margin-top: 10%
    margin-bottom: 25%

    p
      margin-bottom: 0.5em

  .footer-link
    font-weight: bold

  .error-panel
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding-bottom: 30px

  .message
    display: flex
    flex-direction: row
    justify-content: center
    background-color: rgba(255,0,0, 0.1)
    padding: 6px
    color: $text-black
    width: 100%
    max-height: 30px
    position: absolute
    bottom: 0


  .errorIcon
    position: relative
    height: 32px
    width: 32px
    display: inline-block
    top: 10px

  .action
    padding-top: 20px

  button
    background: none
    border: none
    border-bottom: 2px solid $blue
    padding: 3px 4px
    &:hover
      background-color: $grey-light
    &:focus
      border: 2px solid $blue-light
      outline: none

  .default-actions
    display: flex
    flex-direction: row-reverse
    width: 100%
</style>
