<template>
  <div class="dialog">
    <header>
      <button class="close" @click="$emit('close')">
        <i class="close icon"></i>
      </button>
    </header>
    <main>
      <ul class="messages">
        <li v-for="message in messages" :key="message.id">
          <div class="date">
            <i class="bullhorn icon"></i> {{ dayjs(message.date_from).calendar() }}
          </div>
          <h1>{{ message.summary }}</h1>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="message" v-html="markdown(message.message)" />
        </li>
      </ul>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import dayjs from 'dayjs'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  props: {
    /** Array of message objects */
    messages: { type: Array, default: ()=>[]}
  },
  data () {
    return {
      dayjs
    }
  },
  methods: {
    markdown: FormatUtils.markdown,
  },
})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  div.dialog
    margin: 0 auto
    width: 700px
    background: #FFFFFF 0 0 no-repeat padding-box
    box-shadow: 0 10px 50px #00000040
    border-radius: 5px
    opacity: 1

  header
    padding: 10px
    display: flex
    flex-direction: row-reverse

  main
    max-height: calc(100vh - 200px)
    overflow-y: auto
    padding: 0 0 20px

  button
    font-family: $standard-font
    background: none
    border: none
    border-radius: 5px
    cursor: pointer
    padding: 0
    color: #E5E5E5
    font-size: rem(20px)
    &:hover
      color: rgba(0,0,0,0.7)
    &::v-deep i.icon.close
      margin: 0 2px 0 0
  ul
    margin: 0
    padding: 0
    list-style: none
    padding: 0 30px

  li
    border-bottom: 1px solid #E5E5E5
    padding: 13px 0
    &:first-child
      padding-top: 0
    &:last-child
      border-bottom: none

  div.date
    padding-top: 5px
    color: #95A6AC

  h1
    margin: 10px 0
    font-size: rem(20px)
    font-weight: bold
    color: #373737

  .message
    &::v-deep
      font-size: rem(16px)
      p
        margin: 3px

</style>
