<template>
  <div class="selector">
    <h2><slot name="default" /></h2>
    <div class="button-group">
      <bf-button
        v-for="(opt) in quickOptions" :key="opt" :class="{'active': opt === selectedOption}" size="tiny"
        color="blue" @click="setOption(opt)"
      >
        {{ opt }}
      </bf-button>
    </div>
    <div class="select-group">
      <ul>
        <li v-for="(item, index) in items" :key="item">
          <toggle-checkbox
            :value="selectedItems.includes(index)"
            @input="(checked)=>{ toggleSelection(index, checked) }"
          >
            <span v-truncate="25">{{ noValue(item) }}</span>
          </toggle-checkbox>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { range, differenceWith, isEqual } from 'lodash'
import { BfButton } from 'components/Butterfly'
import ToggleCheckbox from 'components/widgets/ToggleCheckbox.vue'
import FormatUtils from 'src/utils/formatters'

export default defineComponent({
  components: { BfButton, ToggleCheckbox },
  props: {
    quickOptions: { type: Array, required: false, default:()=>[] },
    defaultSelection: { type: String, default: 'TOP 20'},
    items: { type: Array, required: false, default:()=>[] }
  },
  data () {
    return {
      selectedOption: this.defaultSelection,
      selectedItems: range(this.labelToNItems(this.defaultSelection)),
    }
  },
  watch:{
    selectedItems (value) {
      this.$emit('selected', value)
    },
    items (value, oldValue) {
      const diff = differenceWith(value, oldValue, isEqual)
      if (diff.length > 0) {
        this.selectedOption = this.defaultSelection
        this.selectedItems = range(this.labelToNItems(this.defaultSelection))
      }
    }
  },
  methods: {
    noValue: FormatUtils.noValue,
     labelToNItems (label: string): number {
      switch (label) {
        case 'ALL':
          return this.items.length
        case 'None':
          return 0
        default:
          const num = parseInt(label.replace(/^TOP/, ''))
          if (!isNaN(num)) {
            return Math.min(num, this.items.length)
          }
          return 0
      }
    },
    setOption (value: string) {
      this.selectedOption = value
      this.selectedItems = range(this.labelToNItems(value))
    },
    toggleSelection (index: number, checked: boolean) {
      if (checked) {
        this.selectedItems = this.selectedItems.concat(index).sort((a, b)=>{
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })
      } else {
        this.selectedItems = this.selectedItems.filter(i => i !== index)
      }
    }
  }
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.selector
  display: flex
  flex-direction: column
  align-items: flex-start

h2
  font-size: 12px
  color: $text-grey
  font-weight: bold
  text-transform: uppercase
  margin-bottom: 10px
  align-self: flex-start

div.button-group
  display: flex
  flex-wrap: nowrap
  justify-content: center
  border-radius: 5px
  border: 1px solid $blue
  margin-bottom: 12px

  &::v-deep .bf-button
    border-radius: 0
    margin: 0
    background-color: rgba(0,0,0,0)
    color: $blue
    font-size: 11px
    font-weight: bold
    padding: 8px 8px
    border: none
    text-transform: uppercase
    border-right: 1px solid $blue
    border-left: none
    flex-shrink: 0

  &::v-deep .bf-button.active
    background-color: $blue
    color: $white
    cursor: default
    border: none

  &::v-deep .bf-button:first-child
    border-radius: 2px 0 0 2px
  &::v-deep .bf-button:last-child
    border-radius: 0 2px 2px 0
    border-right: none
div.select-group
  padding: 0 5px
  overflow-y: auto
  width: 100%

ul
  margin: 0
  padding: 0
  list-style: none
</style>
