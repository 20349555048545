export const MAX_PASSWORD_LENGTH = 128
export const MIN_PASSWORD_LENGTH = 15


export function isValidPassword (value: string): boolean|string {
  let message = "Passwords must be at least 15 characters long but not be longer than 128."
  try {
    let isValid = value.length >= MIN_PASSWORD_LENGTH && value.length <= MAX_PASSWORD_LENGTH
    return isValid || message
  } catch {
    return message
  }
}
