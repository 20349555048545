<template>
  <div>
    <template v-if="error">
      <div class="error-msg">
        {{ error }}
      </div>
    </template>
    <template v-else>
      <div
        v-loading="loading"
        class="invitation-container"
      >
        <el-table
          v-if="invitations.length > 0"
          :data="invitations"
          :show-header="false"
        >
          <!-- Placeholder so the table columns lines up -->
          <el-table-column width="50px">
          </el-table-column>
          <el-table-column prop="name">
            {{ "-" }}
          </el-table-column>
          <el-table-column
            prop="email"
            :min-width="150"
          />
          <el-table-column>
            <template #default="scope">
              {{ date(scope.row.date_sent) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="user_type"
            width="160px"
          >
            <template #default="scope">
              <user-type-select
                read-only
                :value="scope.row.user_type"
              />
            </template>
          </el-table-column>
          <el-table-column width="50px">
            <template #default="scope">
              <i
                class="red link large icon remove"
                @click="deleteInvitation(scope.row.uuid)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>

    <invite-user-modal
      :visible="showInviteModal"
      @user-invited="handleInvited"
      @close="handleModalClose"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { BfSpinner } from "components/Butterfly"
import UserTypeSelect from 'src/components/account/UserList/UserTypeSelect.vue'
import InviteUserModal from 'src/components/account/UserList/InviteUserModal.vue'
import Site from 'src/api/site'
import FormatUtils from 'src/utils/formatters'


interface Invitation {
  add_to: {
    id: number
    type: 'project' | 'dashboard'
  }[]
  auth0_connection_name: string
  auth0_signup_method?: string
  date_sent: string
  date_used: null
  email: string
  invited_by: number | null
  user_type: 'ADMIN' | 'ANALYST' | 'VIEWER'
  uuid: string
}


const SiteUsersList = defineComponent({
  name: 'SiteUsersList',
  components: {
    BfSpinner,
    UserTypeSelect,
    InviteUserModal,
  },
  props: {
    showInviteModal: { type: Boolean, default: false },
  },
  data () {
    return {
      loading: true as boolean,
      error: undefined as undefined | string,
      invitations: [] as Invitation[],
    }
  },
  mounted (): void {
    this.fetchInvitationData()
  },
  methods: {
    date (val: string) {
      return `Sent ${FormatUtils.date(val)}`
    },
    async fetchInvitationData (): Promise<void> {
      this.loading = true
      try {
        this.invitations = await this._fetch()
      } catch (e) {
        this.error = 'Unable to fetch invitation data'
      } finally {
        this.loading = false
      }
    },
    _fetch (): Promise<Invitation[]> {
      return Site.getMembershipInvitations()
    },
    async deleteInvitation (inviteId: number) {
      this.loading = true
      try {
        await Site.deleteInvitation(inviteId)
        this.$notify({
          title: 'Invitation Deleted',
          message: 'The invitation has been successfully deleted.',
          type: 'success',
        })
      } finally {
        try {
        this.fetchInvitationData()
        } finally {
          this.loading = false
        }
      }
    },
    handleModalClose ($event: Event) {
      this.$emit('close', $event)
      this.fetchInvitationData()
    },
    handleInvited (email: string) {
      this.$notify({
          title: 'Invited!',
          dangerouslyUseHTMLString: true,
          message: `We have sent the invitation to<br />${email}`,
          type: 'success',
          duration: 2000,
          zIndex: 100
        })
      this.fetchInvitationData()
    }
  },
})

export default SiteUsersList
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .spinner
    width: 100%
    min-height: 300px
    justify-content: center

  .access-list
    padding: 5px 55px
    h3
      font-size: 16px
      font-weight: bold

  ::v-deep
    .el-table__cell > .cell
      text-overflow: clip !important

</style>