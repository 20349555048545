import { defineComponent } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Meta from 'vue-meta'
import PivotToolPage from 'pages/experiments/PivotToolPage/PivotToolPage.vue'
import Login from 'pages/Login.vue'
import Logout from 'pages/Logout.vue'
import Authenticate from 'pages/Authenticate.vue'
import VerifyEmail from 'pages/VerifyEmail.vue'
import ResendConfirmationEmail from 'pages/ResendConfirmationEmail.vue'
import RequestPasswordReset from 'pages/RequestPasswordReset.vue'
import ConfigureTeamConnector from 'pages/ConfigureTeamsConnector.vue'
// integrations
import SurveyMonkeyConfiguration from 'pages/SurveyMonkeyConfiguration.vue'
import AddIntegration from 'pages/AddIntegration.vue'
// Site tools
import CopyQueries from 'pages/CopyQueries.vue'
// Staff tools
import CopyQueriesStaff from 'components/staff_tools/CopyQueries.vue'

import FrontContainer from 'components/Container.vue'
import AlertsPage from 'components/AlertsPage.vue'
import LabelsPage from 'pages/ProjectLabels/ProjectLabelsPage.vue'
import SiteSelect from 'components/SiteSelect.vue'
import UnsubscribedPage from 'components/UnsubscribedPage.vue'
import SiteWrapper from 'components/site/SiteWrapper.vue'
import SiteHome from 'components/site/SiteHome.vue'
import SiteHomeViewer from 'components/site/SiteHomeViewer.vue'
import AllMembers from 'components/Members.vue'
import AccountSettings from 'components/account/Settings.vue'
import UserProfile from 'components/account/UserProfile.vue'
import UsersAdmin from 'components/account/UsersAdmin.vue'
import ResetPassword from 'components/ResetPassword.vue'
import PasswordResetSuccessful from 'components/PasswordResetSuccessful.vue'
import Project from 'components/project/home/Details.vue'
import EditProject from 'pages/project/ProjectSettingsPage.vue'
import ProjectCreate from 'components/project/create/CreateProject.vue'
import AddData from 'components/project/AddData.vue'
import AddBucketData from 'components/project/AddBucketData.vue'
import CreateAnalysis from 'components/project/analysis/create/CreateAnalysis.vue'
import ProjectContainer from 'components/project/ProjectContainer.vue'
import AnalysisContainer from 'components/project/analysis/results/AnalysisContainer.vue'
import Summary from 'components/project/analysis/results/Summary.vue'
import DataExports from 'components/project/analysis/results/DataExports.vue'
import DiagnosticDataExports from 'components/project/analysis/results/DiagnosticDataExports.vue'
import Storyboard from 'components/project/analysis/results/Storyboard.vue'
import Settings from 'components/project/analysis/results/Settings.vue'
import UnmappedRecords from 'components/project/analysis/results/UnmappedRecords.vue'
import Radar from 'components/project/analysis/results/Radar.vue'
import ThemeBuilder from 'components/project/analysis/results/ThemeBuilder/ThemeBuilder.vue'
import Integrations from 'components/account/Integrations.vue'
import Subscription from 'components/account/Subscription.vue'
import Notifications from 'components/account/Notifications.vue'
import ExportAPI from 'components/account/ExportAPI/ExportAPI.vue'
import CreateSiteForm from 'pages/CreateSiteForm.vue'
import QuerySearch from 'components/project/analysis/results/query/QuerySearch.vue'
import CompareQuery from 'components/project/analysis/results/query/CompareQuery.vue'
import FuzzySearch from 'components/project/analysis/results/FuzzySearch.vue'

import { dashboardRoutes } from './dashboard-routes'
import { userSignupRoutes } from './user-signup-routes'

// This swallows errors thrown by router.push that occur when
// the navigation is cancelled (e.g by a second navigation).
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// const originalPush = Router.prototype.push
// Router.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return (originalPush.call(this, location) as any).catch((err: any) => {
//     if (Router.isNavigationFailure(err)) {
//       console.warn(err)
//       // resolve err
//       return err
//     }
//     // rethrow error
//     return Promise.reject(err)
//   })
// } as typeof Router.prototype.push

// Vue.use(Router)
// Vue.use(Meta)

const router = createRouter({
  // mode: 'history',
  history: createWebHistory(),
  scrollBehavior: () => ({ left: 0, top: 0 }), // Will only work with HTML5 history mode
  linkActiveClass: 'active',
  routes: [
    {
    path: '/',
    component: FrontContainer,
    children: [
      { path: '', component: SiteSelect, name: 'start', meta: { requiresAuth: true, title: 'Select site' }, props: true},
      { path: 'integration/:type', component: AddIntegration, name: 'add-integration', meta: { title: 'Add Integration', requiresAuth: true }},
      { path: 'unsubscribed', component: UnsubscribedPage, name: 'unsubscribed', meta: { title: 'Unsubscribed from Digest', requiresAuth: false }},
      { path: 'verified', component: SiteSelect, name: 'verified', meta: { requiresAuth: true, title: 'Select site' }, props: { verification: true }},
      { path: 'login', component: Login, name: 'login', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Login' }},
      { path: 'logout', component: Logout, name: 'logout', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Logout' }},
      { path: 'sso', component: Login, name: 'sso', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Login' }, props: { sso: true }},
      { path: 'authenticate', component: Authenticate, name: 'authenticate', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Authenticating...' }},
      { path: 'confirm/:key', component: VerifyEmail, name: 'confirm', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Confirm Email' } },
      { path: 're-confirm', component: ResendConfirmationEmail, name: 're-confirm', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Resend Confirmation Email' }},
      { path: 'request-reset', component: RequestPasswordReset, name: 'request-reset', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Reset Password' }},
      { path: 'request-successful', component: PasswordResetSuccessful, name: 'request-successful', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Reset Password' }},
      { path: 'reset-password/:uid/:token', component: ResetPassword, name: 'reset-password', meta: { requiresAuth: false, breadcrumbs: [{ name: 'home' }], title: 'Reset Password' }},
      // Create-site is currently not in the correct place. We will need to move it after we move to app.kapiche.com.
      { path: 'create-site', component: CreateSiteForm, name: 'create-site-form', meta: { requiresAuth: true, title: 'Create Site Form' }},
      { path: '/survey-monkey-config', component: SurveyMonkeyConfiguration, name: 'survey-monkey-config', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }], title: 'Survey Monkey Configuration' }},
      { path: '/configure-teams-connector', component: ConfigureTeamConnector, name: 'configure-teams-connector', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }], title: 'Configure Teams Connector' }},
      { path: ':site',
        component: SiteWrapper,
        meta: { requiresAuth: true },
        children: [
          { path: '', name: 'home', component: SiteHome, meta: { requiresAuth: true, title: 'Welcome' }},
          { path: 'dashboard-list', name: 'viewer-home', component: SiteHomeViewer, meta: { requiresAuth: true, title: 'Welcome' }},
          { path: 'manage-alerts', name: 'alerts', component: AlertsPage, meta: { requiresAuth: true, title: 'Alerts' }},
          { path: 'project-labels', name: 'project-labels', component: LabelsPage, meta: { requiresAuth: true, title: 'Labels' }},
          // Account screens
          {
            path: 'account',
            component: AccountSettings,
            name: 'account',
            redirect: '/account/profile',
            meta: { requiresAuth: true },
            children: [
              { path: 'profile', component: UserProfile, name: 'profile', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }], title: 'User Profile' }},
              { path: 'users', component: UsersAdmin, name: 'users', meta: { requiresAuth: true, requiredUserType: ['ADMIN'], breadcrumbs: [{ name: 'home' }], title: 'Members' }},
              { path: 'integrations', component: Integrations, name: 'integrations', meta: { requiresAuth: true, requiredUserType: ['ADMIN'], breadcrumbs: [{ name: 'home' }, { name: 'integrations' }], title: 'Integrations' }},
              { path: 'subscription', component: Subscription, name: 'subscription', meta: { requiresAuth: true, requiredUserType: ['ADMIN'], breadcrumbs: [{ name: 'home' }, { name: 'subscription' }], title: 'Subscription' }},
              { path: 'notifications', component: Notifications, name: 'notifications', meta: { requiresAuth: true, requiredUserType: ['ADMIN'], breadcrumbs: [{ name: 'home' }, { name: 'notifications' }], title: 'Notifications' }},
              { path: 'exportapi', component: ExportAPI, name: 'exportapi', meta: { requiresAuth: true, requiredUserType: ['ADMIN'], breadcrumbs: [{ name: 'home' }, { name: 'exportapi' }], title: 'Export API' }},
            ]
          },
          // Members screens
          { path: 'members', component: AllMembers, name: 'members', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }], title: 'Members' }},


          // Site tools
          { path: 'copy-queries', component: CopyQueries, name: 'copy-queries', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }], title: 'Copy Themes' }},

          // Staff tools
          { path: 'copy-queries-staff', component: CopyQueriesStaff, name: 'copy-queries-staff', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }], title: 'Copy Themes' }},

          // Project Screens
          {
            path: 'projects/create',
            component: ProjectCreate,
            name: 'project-create',
            meta: { requiresAuth: true, requiredUserType: ['ANALYST'], breadcrumbs: [{ name: 'home' }, { name: 'projects' }], title: 'New Project' }
          },
          { path: 'projects/:projectId',
            component: ProjectContainer,
            name: 'view-project',
            props: route => ({
              projectId: parseInt(route.params.projectId as string)
            }),
            redirect: { name: 'project-details' },
            meta: { requiresAuth: true },
            children: [
              { path: 'details', component: Project, name: 'project-details', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }, { name: 'projects' }], title: 'Project Details' }},
              { path: 'edit', component: EditProject, name: 'project-edit', meta: { requiresAuth: true, breadcrumbs: [{ name: 'home' }, { name: 'projects' }], title: 'Project Details' }},
              { path: 'add_data', component: AddData, name: 'add-data', meta: { requiresAuth: true }},
              { path: 'add_bucket_data', component: AddBucketData, name: 'add-bucket-data', meta: { requiresAuth: true }},
              { path: 'create_analysis', component: CreateAnalysis, name: 'analysis-create', meta: { requiresAuth: true }},
              // Analysis screens
              {
                path: 'analysis/:analysisId',
                component: AnalysisContainer,
                name: 'view-analysis',
                props: route => ({
                  projectId: parseInt(route.params.projectId as string),
                  analysisId: parseInt(route.params.analysisId as string),
                }),
                redirect: { name: 'summary' },
                meta: { requiresAuth: true },
                children: [
                  { path: 'browse', component: QuerySearch, name: 'browse-excerpts' },
                  { path: 'compare', component: CompareQuery, name: 'compare-query' },
                  { path: 'summary', component: Summary, name: 'summary' },
                  { path: 'exports', component: DataExports, name: 'data-exports' },
                  { path: 'diagnostic-exports', component: DiagnosticDataExports, name: 'diagnostic-data-exports' },
                  { path: 'storyboard', component: Storyboard, name: 'storyboard' },
                  { path: 'settings', component: Settings, name: 'settings' },
                  {
                    path: 'unmapped', component: UnmappedRecords, name: 'unmapped',
                    props: route => ({
                      projectId: parseInt(route.params.projectId as string),
                      analysisId: parseInt(route.params.analysisId as string),
                      navigatedConcept: route.params.navigatedConcept,
                    })
                  },
                  { path: 'pivot', component: PivotToolPage, name: 'pivot-tool',
                    props: route => ({
                      projectId: parseInt(route.params.projectId as string),
                      analysisId: parseInt(route.params.analysisId as string),
                    })
                  },
                  {
                    path: 'radar', component: Radar, name: 'radar',
                    props: route => ({
                      projectId: parseInt(route.params.projectId as string),
                      analysisId: parseInt(route.params.analysisId as string),
                    })
                  },
                  {
                    path: 'theme-builder',
                    component: ThemeBuilder,
                    name: 'theme-builder',
                    props: route => ({
                      projectId: parseInt(route.params.projectId as string),
                      analysisId: parseInt(route.params.analysisId as string),
                      state: route.query.state,
                    })
                  },
                  { path: 'fuzzy-search', component: FuzzySearch, name: 'fuzzy-search' }
                ]
              }
            ]
          }
        ]
      },
      ...(userSignupRoutes as RouteRecordRaw[]),
      ...(dashboardRoutes as RouteRecordRaw[]),
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }] as RouteRecordRaw[]
})

export const useRouter = () => router

export default router
