import * as Sentry from '@sentry/vue'
import { Extras } from '@sentry/types'
import { App } from 'vue'
import store from 'src/store'


const sentryIgnoreErrors = [
    'There is already a query with this name on this analysis. Try another name.',
    'Project with this name already exists on this site.',
    'We couldn\'t determine the Site for your request.',
    'You can\'t delete yourself.',
    'can\'t access dead object',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'Unable to log in with provided credentials',
    'Avoided redundant navigation to current location',
    'Navigation cancelled from',
    // See ch22505
    'Non-Error promise rejection captured with value: Object Not Found Matching',
    'This password is too short',
    'Cannot read property \'blur\' of undefined',
    'That email already has access to this Dashboard',
    'There is already an invitation for',
    'TypeError: undefined is not an object (evaluating \'p.manager\')',
    'Non-Error exception captured'
]

/*
   Segment adds a number of trackers which customers may block.
   So we ignore errors from these URLs since they do not affect
   the functioning of the app.
 */
const segmentThirdPartyTrackerUrls = [
  //MixPanel
  /mxpnl\.com/,
  // Facebook
  /facebook\.net/,
  /facebook\.com/,
  // LinkedIn
  /linkedin\.com/,
  /licdn\.com/,
  /adsymptotic\.com/,
  // Twitter
  /twitter\.com/,
  /ads-twitter\.com/,
  /t\.co/,
  // Google
  /accounts\.google\.com/,
]

const browserExtensionUrls = [
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Safari extensions
  /safari-extension:\/\//i,
]

export function throwToSentry (error: unknown, extras: Extras = {}): void {
    if (!(error instanceof Error)) {
        error = new Error(JSON.stringify(error))
    }

    // See https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-551756226
    Sentry.withScope((scope) => {
        scope.setExtras(extras)
        Sentry.captureException(error)
    })
}


export function configureSentry (app: App, environment: string): void {
    let dsn: string

    if (environment === 'production') {
       dsn = process.env.SENTRY_DSN ?? ''
        initSentry(app, dsn, environment)
    } else if (environment === 'staging') {
        dsn = process.env.SENTRY_DSN ?? ''
        initSentry(app, dsn, environment)
    }

    // Keep the current user registered in the sentry scope
    store.watch(() => store.getters.currentUser, (newUser, oldUser) => {
        if (oldUser && newUser && oldUser.id === newUser.id) {
            // The user object is continually overwritten via polling,
            // which then repeatedly triggers our watcher.
            // Here we bail out if the actual user didn't change.
            // Note that this ignores changes to particular fields on
            // the user object, otherwise we would have to perform a
            // deep compare between old and new user objects.
            return
        }
        Sentry.configureScope(function (scope) {
            scope.setUser(newUser)
        })
    })
}


export function initSentry (app: App, dsn: string, environment: string): void {
    Sentry.init({
        app,
        dsn: dsn,
        release: process.env.PACKAGE_VERSION,
        ignoreErrors: sentryIgnoreErrors,
        denyUrls: [
          ...segmentThirdPartyTrackerUrls,
          ...browserExtensionUrls,
        ],
        environment: environment,
        maxValueLength: 1024,
        beforeSend ( event, hint ) {
            let error: any = hint?.originalException
            if (!(error instanceof Error)) {
                error = new Error(JSON.stringify(error))
            }
            event.extra = event.extra || {}

            const _fs = window[window._fs_namespace as keyof Window]

            if (typeof _fs !== 'function') {
                event.extra.fullstory = 'FullStory is not installed'
                return event
            }

            // getCurrentSessionURL isn't available until after the FullStory script is fully bootstrapped.
            // If an error occurs before getCurrentSessionURL is ready, make a note in Sentry and move on.
            // More on getCurrentSessionURL here: https://help.fullstory.com/develop-js/getcurrentsessionurl
            event.extra.fullstory = typeof _fs.getCurrentSessionURL === 'function' ? _fs.getCurrentSessionURL(true) : 'current session URL API not ready'

            // FS.event is immediately ready even if FullStory isn't fully bootstrapped
            _fs.event('Application error', {
                name: error?.name,
                message: error?.message,
                sentryEventId: hint?.event_id,
                sentryUrl: `https://sentry.io/organizations/kapiche/issues/?project=129858&query=${hint.event_id}`
            })

            return event
        },
    })
}
