<!--
  A wrapper for the `BfModal` component that provides consistent styling
  for prompt-style dialogs.

  Content is provided via the `header` and `body` slots.
-->
<template>
  <bf-modal :visible="visible" @close="close">
    <div class="container">
      <div class="close-button" @click="close">
        <i class="kapiche-icon-cross"></i>
      </div>
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
    </div>
  </bf-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BfModal } from 'src/components/Butterfly'

export default defineComponent({
  components: { BfModal },
  props: {
    visible: { type: Boolean, default: false },
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
})
</script>

<style lang="sass" scoped>
  @import '../../../semantic/dist/semantic.css'
  @import 'assets/kapiche.sass'

  .container
    width: 600px
    margin: 0 auto
    padding: 50px
    position: relative
    background-color: #fff
    border-radius: 2px
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    transition: all .3s ease
    text-align: center
    font-size: 16px

    .close-button
      color: $grey
      cursor: pointer
      position: absolute
      right: 15px
      top: 15px
      &:hover
        color: $text-grey

    .header
      font-size: 30px
      margin-bottom: 25px
      color: $text-black

    .body
      line-height: 1.5
      margin-bottom: 25px
      color: $text-black

</style>
