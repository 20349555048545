/**
 * All interactions with the /announcement endpoints are done here.
 */
import Vue from 'vue'
import HTTPRetryUtil from 'src/utils/httpretry'

export const getAnnouncements = async () => {
    return await HTTPRetryUtil.retry('announcements/')
  }

export const dismissAnnouncement = async (msg: string) => {
  return await Vue.http.post(`announcements/${msg}/dismiss/`)
}

export const dismissAllAnnouncements = async () => {
  return await Vue.http.post('announcements/dismiss/')
}
