<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="status === 'fetching'"
    :dev-mode="devMode"
    :has-errored="error != null"
    :banner="banner"
    class="timeline"
    @resize="setChartDimensions"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon">
    </template>
    <template #header>
      Timeline
    </template>
    <template #actions>
      <download-export-button
        :name="exportName+'-Timeline'"
        :is-loading="status === 'fetching'"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="makePptSlide"
        :basic-svg-export="true"
        short-name="Timeline"
        @export-change="isExporting = $event"
      ></download-export-button>
      <router-link
        v-if="!isZoomed && zoomToRoute"
        class="widget-action expand"
        :to="zoomToRoute"
      >
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a
        :href="CONST.widget_help_links.timeline"
        class="widget-action help"
        target="_blank"
      >
        <i class="kapiche-icon-info"></i>
      </a>
    </template>
    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        Start: {{ new Date(startTime) }}<br />
        Done: {{ new Date(doneTime) }}<br />
        Elapsed: {{ (doneTime - startTime) / 1000 }} seconds<br />
        Status: {{ status }}<br />
        Error: {{ error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div v-if="error" class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button>
          or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>

    <template v-if="hasDataOptions" #menu>
      <widget-menu
        :menus="menus"
        :vertical="isZoomed"
        :bound="$el"
        @onSelect="setMenuSelection"
      />
    </template>

    <!--======================== CONTENT -->
    <template v-if="hasDataOptions && selectedDataInPlottableFormat.length > 0" #content>
      <insight-cues
        v-if="getTimelineCues"
        :cues-stale="areTimelineCuesStale"
        :loading="timelineCuesLoading"
        :content="formattedTimelineCues"
        @fetch="fetchTimelineCues"
      ></insight-cues>
      <!-- Render topic timeline -->
      <div class="row timeline-container" :class="{'tool-tip-padded': isZoomed}">
        <timeline
          :timeline-id="'timeline-trend'"
          :all-series="selectedDataInPlottableFormat"
          :y-label="selectedModeOption.yAxisLabel"
          :resolution="resolution.toLowerCase()"
          :y-range="yRange"
          :y-value-number-format="selectedModeOption.numberType"
          :x-label="selectedDatefield"
          :records="records"
          :chart-height="chartHeight"
          :series-labels="seriesLabels"
          :series-tags="themeToGroupNameMap"
          :is-exporting="isExporting"
          @series-visibility-changed="() => {
            calculateYRange()
            updateConfig()
          }"
        ></timeline>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, inject, onMounted, PropType, ref, watch } from "vue"
import PptxGenJS from 'pptxgenjs'

import { sanitize } from 'dompurify'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-timeline.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import Timeline from 'components/project/analysis/results/widgets/Timeline.vue'
import InsightCues from 'components/DataWidgets/InsightCues/InsightCues.vue'
import DrawUtils from 'src/utils/draw'
import ChartUtils from 'src/utils/chart'
import ProjectAPI from "src/api/project"
import { getAggregationOffset, getDataMax, getDataMin, makeTimelineSlide } from '../DataWidgetUtils'
import { SegmentField } from "types/AnalysisTypes"
import { MenuOption } from "types/components/WidgetMenu.types"
import { FetchState } from "src/store/modules/data/state"
import { WidgetConfig } from "types/DashboardTypes"
import { SavedQuery } from 'types/Query.types'
import { PivotData, Resolution, TrendLine } from 'types/widgets.types'
import { CurrentModelDateField } from 'types/ProjectTypes'
import { SchemaColumn } from 'types/SchemaTypes'
import { Analytics } from 'src/analytics'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { markdown, truncate } from 'src/utils/formatters'
import { ScoreColumn, schemaColToScoreCol, getScoreColumnRegroupMap, generateScoreRequirements } from "./TimelineScoreUtils"

export interface MenuItem {
  title: string
  type: 'menu' | 'radio'
  options: Array<MenuOption>
  showSelected?: boolean
  selected?: string | string[]
}

export interface Menu {
  name: string
  options: MenuItem[][]
  selection?: string
}

export interface DisplayModeOption {
  label: string
  numberType: string
  yAxisLabel: string
  yCapValue: number
  yMultipleOf: number
  allowEnhance?: boolean
}

type FieldFrequency = FetchState<PivotData>

const overallNPSColor = 'rgb(6, 140, 204)'
const impact_sentiment_extra = {
  'numberType': 'integer',
  'yCapValue': 200,
  'yMultipleOf': 10,
}
const metric_sentiment_extra = {
  'numberType': 'integer',
  'yCapValue': 100,
  'yMultipleOf': 10,
}

const RESOLUTIONS = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']

const TimelineWidget = defineComponent({
  components: {
    WidgetFrame,
    DownloadExportButton,
    Timeline,
    WidgetMenu,
    WidgetMessagePanel,
    InsightCues,
  },
  props: {
    /** data to render */
    data: {type: Object as PropType<PivotData>, required: false, default: null},
    exportName: {type: String, required: false, default: ''},
    /** `fetching`, `done` or `''` */
    status: {type: String, required: false, default: ''},
    /** error object for dev panel */
    error: {type: [Error, Object], required: false, default: null},
    /** nicer error message for user  */
    userError: {type: String, required: false, default: null},
    startTime: {type: Number, required: false, default: null},
    doneTime: {type: Number, required: false, default: null},
    devMode: {type: Boolean, required: false, default: false},

    baseQuery: {type: Object, default: () => ({})},
    conceptQuery: {type: Object, default: () => null},
    // TODO: these are similar to ThemesWidget.vue
    /** list of queries for the chart */
    queries: { type: Array as PropType<SavedQuery[]>, required: false, default: ()=>[] },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: ()=>[] },
    /** fields for menu */
    segmentFields: { type: Array as PropType<SegmentField[]>, required: false, default:()=>[] },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false},
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false},
    /** does this data contain numeric fields? */
    hasNumericFields: { type: Boolean, default: false, required: false },
    /** widget banner to display */
    banner: { type: Object, default: ()=>null, required: false },
    viewingQuery: { type: Boolean, required: false, default: false },
    dateFields: { type: Array as PropType<CurrentModelDateField[]>, required: true },
    defaultDateField: { type: String, required: true },
    weekStart: { type: String, required: false, default: null },
    sortedSegmentsForFieldsLimited: { type: Object, required: true },
    group: { type: String, required: false, default: 'overall__' },
    fieldFrequency: { type: Object as PropType<FieldFrequency | null>, required: false, default: null },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    /** is this DataWidget in zoomed mode?  */
    isZoomed: { type: Boolean, required: false, default: false},
    /** route object for zoom button */
    zoomToRoute: { type: Object, required: false, default: null },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'timeline'> | null>, required: false, default: null },
    dayFirstDates: { type: Boolean, required: false, default: false },
    getTimelineCues: { type: Boolean, required: false, default: false },
    timelineCues: { type: String, required: false, default: null },
    timelineCuesLoading: { type: Boolean, required: true, default: true },
  },
  setup (props, { emit }) {
    const analytics = inject<Analytics>('analytics')
    const featureFlags = inject<Record<string, boolean>>('featureFlags')
    const themeToGroupNameMap = inject<Record<string, string>>('themeToGroupNameMapById', {})
    const themeNameMap = inject<ComputedRef<Record<number, string>>>('themeNameMap', computed(() => ({})))

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const selectedDisplayMode = ref<[string, string]>(['Frequency', 'Frequency (%)'])
    const selectedDataDisplayMode = ref<[string, string]>(['Other', 'Themes'])
    const selectedDatefield = ref<string>(props.defaultDateField) // default to the first datefield
    const resolution = ref<Resolution>('Monthly')  // default to monthly
    const selectedDataInPlottableFormat = ref<TrendLine[]>([])
    const groupedData = ref<null | Map<string, TrendLine[]>>(null)
    const yRange = ref<[number, number]>([0, 1])
    const records = ref<Record<string, any>>({})
    const chartHeight = ref('320px')
    const lastEmittedReqs = ref<string>('')
    const lastCueReqs = ref<string>('')
    const isExporting = ref<boolean>(false)

    const seriesLabels = computed<Record<string, string>>(() => {
      // Theme Groups are prefixed with 'group_' to avoid conflicts with Themes
      const labels = props.themeGroups.map((g) => [g.name, g.name.replace(/^group_/, '')])
      return Object.fromEntries(labels)
    })

    const formattedTimelineCues = computed(() => {
      return props.timelineCues? markdown(props.timelineCues) : null
    })

    const hasDataOptions = computed(() => {
      return !props.viewingQuery
        ? true
        : Object.keys(props.sortedSegmentsForFieldsLimited).length > 0
    })

    const numericalFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list, field) => field.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NUMBER')
          ? list.concat(field.name)
          : list,
        [] as string[]
      )
    })

    const scoreColumns = computed((): ScoreColumn[] => {
      if (!featureFlags?.scoring_metrics) return []
      return props.schema.filter((col) => col.type === 8).map((col) => schemaColToScoreCol(col))
    })

    const dataOptions = computed((): MenuItem[][] => {
      const otherOptions = featureFlags?.['theme_groups']
        ? ['Themes', 'Theme Groups']
        : ['Themes']
      return [
        [
          {
            'title': 'Field',
            'type': 'menu',
            'options': Object.keys(props.sortedSegmentsForFieldsLimited)
          },
        ],
        [
          {
            'title': 'Other',
            'type': 'menu',
            'options': !props.viewingQuery ? otherOptions : ['All Data']
          },
        ],
      ]
    })

    const validOptions = computed((): Required<WidgetConfig<'timeline'>['options']> => {
      let dataDisplayMode = props.config?.options?.dataDisplayMode ?? [null, null]
      let displayMode = props.config?.options?.displayMode ?? [null, null]
      let dateField = props.config?.options?.dateField ?? ''
      let resolution: Resolution = props.config?.options?.resolution ?? 'Monthly'
      let hiddenLegendItems = props.config?.options?.hiddenLegendItems ?? []

      if (!dataDisplayMode[0] || (dataDisplayMode[0] === 'Field' && !Object.keys(props.sortedSegmentsForFieldsLimited).includes(dataDisplayMode[1]))) {
        if (props.viewingQuery) {
          dataDisplayMode = ['Other', 'All Data']
        } else if (props.queries.length > 0) {
          dataDisplayMode = ['Other', 'Themes']
        } else if (props.themeGroups.length > 0) {
          dataDisplayMode = ['Other', 'Theme Groups']
        } else {
          dataDisplayMode = [
            dataOptions.value[0][0].title,
            dataOptions.value[0][0].options[0] as string,
          ]
        }
      }

      // We don't store the score column display mode as is, but we strip the aggregation from the title,
      // In order to restore the title, we find the score column and set it as expected.
      let scoreColNames = scoreColumns.value.map((col) => col.name)
      // set selected display
      // noinspection FallThroughInSwitchStatementJS
      switch (displayMode[0]) {
        case 'Frequency': {
          if (['Frequency (%)', 'Frequency (#)'].includes(displayMode[1])) {
            break
          }
        }
        case 'Numerical Field': {
          break
        }
        case 'Score': {
          break
        }
        case 'Metric':
        case 'Impact On': {
          if (displayMode[1] === 'NPS' && props.hasNps) {
            break
          }
          if (['Positive Sentiment', 'Negative Sentiment',
               'Mixed Sentiment', 'Neutral Sentiment'].includes(displayMode[1])
            && props.hasSentiment) {
              break
          }
          if (numericalFields.value.includes(displayMode[1])) {
            break
          }
          if (scoreColumns.value &&
           scoreColNames.includes(displayMode[1])) {
            break
           }
        }
        default: {
          displayMode = ['Frequency', 'Frequency (%)']
        }
      }

      // set selected date
      if (!props.dateFields.map((d) => d.name).includes(dateField)) {
        dateField = props.defaultDateField
      }

      // set selected resolution
      if (!RESOLUTIONS.includes(resolution)) {
        resolution = 'Monthly'
      }

      return {
        hiddenLegendItems,
        dataDisplayMode,
        displayMode,
        dateField,
        resolution,
      }
    })

    const hiddenLegendNames = computed((): string[] => {
      return selectedDataInPlottableFormat.value
        .filter((series) => !series.visible)
        .map((series) => `${selectedDataDisplayMode.value[1]}_${series.name}`)
    })

    const fetchData = (force=false) => {
      if (!hasDataOptions.value) return

      // TODO: a refactor could unify this code and the fetchData body in ThemesWidget.vue
      let blocks: any[] = [
        {
          aggfuncs: [
            {
              new_column: 'frequency_cov',
              src_column: 'document_id',
              aggfunc: 'count',
            },
          ],
          metric_calculator: 'coverage',
        }]

      // get NPS
      if (props.hasNps) {
        blocks.push({
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            }
          ],
          pivot_field: 'NPS Category',
          metric_calculator: 'nps',
        })
      }

      // get sentiment
      if (props.hasSentiment) {
        blocks.push({
          aggfuncs: [
            {
              new_column: 'frequency',
              src_column: 'document_id',
              aggfunc: 'count',
            }
          ],
          pivot_field: 'sentiment__',
          metric_calculator: 'sentiment',
        })
      }

      const field = selectedDisplayMode.value[1]
      const isNumericalField = numericalFields.value.includes(field)

      if (isNumericalField) {
          blocks.push({
            aggfuncs: [{
              new_column: `${field}|count`,
              src_column: `${field}`,
              aggfunc: 'count',
            }, {
              new_column: `${field}|mean__`,
              src_column: `${field}`,
              aggfunc: 'mean',
            }],
            metric_calculator: 'mean_impact'
          })
      }

      // Add score blocks to the requirements if present.
      const scoreBlocks = generateScoreRequirements(scoreColumns.value, selectedDisplayMode.value[1])
      if (Object.keys(scoreBlocks).length > 0) blocks.push(scoreBlocks)

      // TODO: this may or may not be present, depending on whether segments or queries is selected.
      let extra: Record<string, unknown> = {}
      if (selectedDataDisplayMode.value.join(',') === 'Other,Themes') {
        extra['queries'] = props.queries.map(q => ({
          name: `q_${q.id}`,
          value: q.query_value
        }))
      } else if (selectedDataDisplayMode.value.join(',') === 'Other,Theme Groups') {
        extra['queries'] = props.themeGroups.map(g => ({
          name: g.name,
          value: g.query_value,
        }))
      } else if (selectedDataDisplayMode.value[0] === 'Field') {
        extra['agg_fields'] = selectedDataDisplayMode.value[1]
          ? [selectedDataDisplayMode.value[1]]
          : []
      }

      if (props.conceptQuery) {
        extra['queries'] = (extra['queries'] as any || []).concat({
          name: props.group,
          value: props.conceptQuery,
        })
      }

      const requirements = {
        blocks,
        ...extra,
        date_fieldname: selectedDatefield.value,
        date_aggregation_offset: getAggregationOffset(resolution.value),
        week_start: props.weekStart,
      }

      let field_frequency_requirements: Record<string, unknown> = {
        "blocks": [
          {
            "aggfuncs": [
              {
                "new_column": "frequency",
                "src_column": "document_id",
                "aggfunc": "count"
              }
            ],
            "pivot_field": selectedDataDisplayMode.value[1]
          }
        ],
        date_fieldname: selectedDatefield.value,
        date_aggregation_offset: getAggregationOffset(resolution.value),
      }

      if (props.conceptQuery) {
        field_frequency_requirements.queries = [{
          name: props.group,
          value: props.conceptQuery,
        }]
      }

      // Fetch frequency totals if we're looking at a field
      if (selectedDataDisplayMode.value[0] === 'Field') {
        emit('requires',
          'field_frequency',
          field_frequency_requirements,
          force,
          true,
        )
      }

      /** request for segmentation chart overall data
       * @event requires
       * @property {string} id identifies this component request uniquely
       * @property {object} request chrysalis request
       * @property {boolean} force override cache
       * @property {boolean} multi use v2 api
       */
      emit('requires',
        'timeline' + (props.viewingQuery ? '_query' : ''),
        requirements,
        force,
        true,
      )
      lastEmittedReqs.value = JSON.stringify(requirements)
    }

    const fetchTimelineCues = async () => {

      const data = getCsvData()
      emit(
        'timeline-cues',
        'timeline',
        data,
        selectedDatefield.value,
        'timeline_widget_cues',
        {
          category: selectedDataDisplayMode.value[1],
          data_type: selectedDisplayMode.value[1]
        }
      )
    }

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      fetchData(true)
    }

    const setOptionsFromConfig = () => {
      selectedDataDisplayMode.value = validOptions.value.dataDisplayMode
      selectedDisplayMode.value = validOptions.value.displayMode
      selectedDatefield.value = validOptions.value.dateField
      resolution.value = validOptions.value.resolution ?? 'Monthly'
      selectedDataInPlottableFormat.value.forEach((s) => {
        s.visible = !validOptions.value.hiddenLegendItems?.includes(
          `${selectedDataDisplayMode.value[1]}_${s.name}`
        )
      })
    }

    /**
     * Quick accessor to get the selected display mode option without having to call into the dict
     * @return option - A dict of all the relevant options for a given mode (label, percentSign, accessor)
     */
    const selectedModeOption = computed((): DisplayModeOption => {
      if (selectedDisplayMode.value[0] === 'Numerical Field') {
        return {
          'label': selectedDisplayMode.value[1],
          'numberType': 'signAwareRoundedFloat',
          'yAxisLabel': `${selectedDisplayMode.value[1]} (avg)`,
          'yCapValue': 1e6,
          'yMultipleOf': 0.1,
        }
      }

      if (selectedDisplayMode.value[0] === 'Impact On' &&
          numericalFields.value.includes(selectedDisplayMode.value[1])
      ) {
        return {
          'label': `${selectedDisplayMode.value[1]} Impact`,
          'numberType': 'signAwareRoundedFloat',
          'yAxisLabel': `Impact on ${selectedDisplayMode.value[1]}`,
          'yCapValue': 1e6,
          'yMultipleOf': 0.1,
        }
      }

      // Construct an array for the score displayMode options.
      const scoreNames = scoreColumns.value.map((col) => col.name)

      if (selectedDisplayMode.value[0] === "Score" &&
       scoreNames.includes(selectedDisplayMode.value[1])) {
        const selectedScoreColumn = scoreColumns.value.find(
          (col) => col.name === selectedDisplayMode.value[1]
        )
        const isBox = ['top box', 'bot box'].includes(selectedScoreColumn?.aggregation.type ?? 'average')
        return {
          'label': `${selectedScoreColumn?.name} (${selectedScoreColumn?.aggregation.title})`,
          'numberType': isBox? 'percentage': 'signAwareRoundedFloat',
          'yAxisLabel': `${selectedScoreColumn?.name} (${selectedScoreColumn?.aggregation.title})`,
          'yCapValue': isBox? 100: 1e6,
          'yMultipleOf': isBox? 1: 0.1,
        }
      }

      if (selectedDisplayMode.value[0] === 'Impact On' &&
       scoreNames.includes(selectedDisplayMode.value[1])
      ) {
        const selectedScoreColumn = scoreColumns.value.find(
          (col) => col.name === selectedDisplayMode.value[1]
        )
        return {
          'label': `${selectedScoreColumn?.name} (${selectedScoreColumn?.aggregation.title}) Impact`,
          'numberType': 'signAwareRoundedFloat',
          'yAxisLabel': `Impact on ${selectedDisplayMode.value[1]} (${selectedScoreColumn?.aggregation.title})`,
          'yCapValue': 1e6,
          'yMultipleOf': 0.1,
        }

      }

      const displayModeOptions = {
        'Frequency Frequency (%)': {
          'label': 'Frequency (%)',
          'numberType': 'percentage',
          'yAxisLabel': '% of Records',
          'yCapValue': 1,
          'yMultipleOf': 0.1,
          'allowEnhance': true,
        },
        'Frequency Frequency (#)': {
          'label': 'Frequency (#)',
          'numberType': 'integer',
          'yAxisLabel': 'Records',
          'yCapValue': 1e6,
          'yMultipleOf': 5,
        },
        'Impact On Positive Sentiment': {
          'label': 'Positive Sentiment Impact',
          'queryLabel': 'positive',
          'yAxisLabel': 'Impact on pos. sentiment (%)',
          ...impact_sentiment_extra
        },
        'Impact On Negative Sentiment': {
          'label': 'Negative Sentiment Impact',
          'queryLabel': 'negative',
          'yAxisLabel': 'Impact on neg. sentiment (%)',
          ...impact_sentiment_extra
        },
        'Impact On Mixed Sentiment': {
          'label': 'Mixed Sentiment Impact',
          'queryLabel': 'mixed',
          'yAxisLabel': 'Impact on mixed sentiment (%)',
          ...impact_sentiment_extra
        },
        'Impact On NPS': {
          'label': 'NPS Impact',
          'numberType': 'signAwareInteger',
          'yAxisLabel': 'Impact on NPS',
          'yCapValue': 200,
          'yMultipleOf': 5,
        },
        'Metric Positive Sentiment': {
          'label': 'Positive Sentiment',
          'queryLabel': 'positive',
          'yAxisLabel': 'Positive sentiment (%)',
          ...metric_sentiment_extra
        },
        'Metric Negative Sentiment': {
          'label': 'Negative Sentiment',
          'queryLabel': 'negative',
          'yAxisLabel': 'Negative sentiment (%)',
          ...metric_sentiment_extra
        },
        'Metric Mixed Sentiment': {
          'label': 'Mixed Sentiment',
          'queryLabel': 'mixed',
          'yAxisLabel': 'Mixed sentiment (%)',
          ...metric_sentiment_extra
        },
        'Metric NPS': {
          'label': 'NPS',
          'numberType': 'signAwareInteger',
          'yAxisLabel': 'NPS',
          'yCapValue': 200,
          'yMultipleOf': 5,
        }
      } as Record<string, DisplayModeOption>

      return displayModeOptions[selectedDisplayMode.value.join(' ')]
    })

    const optionsForDataMenu = computed((): MenuItem[][] => {
      return dataOptions.value.map((t) =>
        t.map((o) => ({
          title: o.title,
          type: 'menu',
          options: o.options,
          showSelected: true,
          selected: selectedDataDisplayMode.value[0] === o.title
            ? selectedDataDisplayMode.value[1]
            : [],
        }))
      )
    })

    const displayOptions = computed((): MenuItem[][] => {
      const standardOptions = [
        'NPS',
        'Positive Sentiment',
        'Negative Sentiment',
        'Mixed Sentiment',
      ]
      const scoreColNames = scoreColumns.value.map((col) => `${col.name} (${col.aggregation.title})`)

      const options: Array<MenuItem[] | boolean> = [
        [
          {'title': 'Frequency', 'type': 'menu', 'options': ['Frequency (%)', 'Frequency (#)']},
          {'title': 'Date Field', 'type': 'radio', 'options': []}
        ],
        props.hasNumericFields && [
          {
            'title': 'Numerical Field',
            'type': 'menu',
            'options': numericalFields.value
          }
        ],
        scoreColumns.value.length > 0 && [
          {
            'title': 'Score',
            'type': 'menu',
            'options': scoreColNames,
          }
        ],
        [
          {
            'title': 'Metric',
            'type': 'menu',
            'options': standardOptions
          }
        ],
        [
          {
            'title': 'Impact On',
            'type': 'menu',
            'options': standardOptions.concat(numericalFields.value).concat(scoreColNames)
          }
        ]
      ]

      return options.filter((i): i is MenuItem[] => !!i)
    })

    const selectedForDisplayOptions = (option: MenuItem) => {
      switch (option.title) {
        case 'Date Field' :
          return [selectedDatefield.value]
        default:
          return option.title === selectedDisplayMode.value[0]
            ? [selectedDisplayMode.value[1]]
            : []
      }
    }

    const optionsForDisplayMenu = computed((): MenuItem[][] => {
      return displayOptions.value.map((t) =>
        t.map((o) => ({
          title: o.title,
          type: o.type,
          options: (
            o.title !== 'Date Field'
              ? o.options as string[]
              : props.dateFields.map(d => d.name)
          ).filter((opt) => (
            (opt !== 'NPS' && !opt.includes('Sentiment') ) ||
            (opt === 'NPS' && props.hasNps ) ||
            (opt.includes('Sentiment') && props.hasSentiment )
          )),
          showSelected: true,
          selected: selectedForDisplayOptions(o),
        }))
      )
    })

    const menus = computed((): Menu[] => {
      return [{
        name: 'DATA',
        selection: selectedDataDisplayMode.value[1],
        options: optionsForDataMenu.value.map(t => t.map((menu) => {
          return {
            ...menu,
            options: menu.options.map((option) => {
              if (option === 'Themes' && props.queries.length < 1) {
                return {
                  label: 'Themes',
                  value: 'Themes',
                  disabled: true,
                  tooltip: 'There are no Themes in this Analysis',
                }
              }

              if (option === 'Theme Groups' && props.themeGroups.length < 1) {
                return {
                  label: 'Theme Groups',
                  value: 'Theme Groups',
                  disabled: true,
                  tooltip: 'There are no Theme Groups in this Analysis',
                }
              }

              return option
            })
          }
        })),
      }, {
        name: 'DISPLAY',
        selection: selectedModeOption.value?.label,
        options: optionsForDisplayMenu.value,
      }, {
        name: 'RESOLUTION',
        selection: resolution.value,
        options: [
          [{
            title: 'Resolution',
            type: 'menu',
            showSelected: true,
            selected: resolution.value,
            options: RESOLUTIONS
          }]
        ],
      }]
    })

    const areTimelineCuesStale = computed((): boolean => {
      return lastEmittedReqs.value !== lastCueReqs.value
    })

    const calculateYRange = () => {
      const displayOption = selectedModeOption.value
      const visibleDataset = selectedDataInPlottableFormat.value.filter((series) => series.visible)
      const dataMax = getDataMax(
        visibleDataset,
        displayOption.yCapValue,
        displayOption.yMultipleOf,
        displayOption.allowEnhance,
      )
      const dataMin = getDataMin(
        visibleDataset,
        dataMax,
        (-1 * displayOption.yCapValue),
        displayOption.yMultipleOf,
      )
      yRange.value = [dataMin, dataMax]
    }

    const updatePlot = () => {
      // we check if the selectedDisplayMode has a score field in it,
      // if it does, we convert the second string of the array to the
      // menu name so it can match the data map.
      let scoreNamesMap: Record<string, string> = {}
      scoreColumns.value.forEach((col) => scoreNamesMap[col.name] = `${col.aggregation.title} ${col.name}`)
      let displayModeKey = [
        selectedDisplayMode.value[0],
        Object.keys(scoreNamesMap).includes(selectedDisplayMode.value[1])?
          scoreNamesMap[selectedDisplayMode.value[1]]:
          selectedDisplayMode.value[1]
      ].join(" ")

      const groupData = groupedData.value?.get(displayModeKey)
      if (!groupData) return

      selectedDataInPlottableFormat.value =
        groupData.filter((d) => d.name !== '(No Value)')

      setOptionsFromConfig()
      calculateYRange()
    }

    /**
     * If fieldName is not defined then we group by the `group__` field,
     * otherwise by the given fieldName.
     */
    const regroupData = (data: PivotData, fieldName?: string): void => {
      selectedDataInPlottableFormat.value = []
      records.value = {}

      if (fieldName === undefined && selectedDataDisplayMode.value[0] === 'Field') {
        fieldName = selectedDataDisplayMode.value[1]
      }

      // exit early if the current this.fieldFrequency isn't the field frequency
      // related to the current fieldName and data (this should eventually align
      // as we watch both incoming this.data and this.fieldFrequency)
      if (fieldName) {
        const uniqueGroups = Array.from(new Set(props.data.payload.map(v => v.group__)))
        if (!uniqueGroups.every(group => props.fieldFrequency?.data?.payload.find(v => v.group__ === group))) {
          return
        }
      }

      // Match payload column names to selectable dropdown keys
      let impactGrouping: string
      if (fieldName) {
        impactGrouping = 'rto'
      } else {
        impactGrouping = 'rto'
      }

      const scoreColumnsMap = scoreColumns.value.length > 0?
        getScoreColumnRegroupMap(scoreColumns.value): {}

      let metrics: Record<string, string> = {
        'frequency_cov': 'Frequency Frequency (#)',
        'coverage_doc_rto__': 'Frequency Frequency (%)',
        'NPS Category|nps__': 'Metric NPS',
        'sentiment__|positive%__': 'Metric Positive Sentiment',
        'sentiment__|negative%__': 'Metric Negative Sentiment',
        'sentiment__|mixed%__': 'Metric Mixed Sentiment',
        [`NPS Category|npsi_${impactGrouping}__`]: 'Impact On NPS',
        [`sentiment__|positive%i_${impactGrouping}__`]: 'Impact On Positive Sentiment',
        [`sentiment__|negative%i_${impactGrouping}__`]: 'Impact On Negative Sentiment',
        [`sentiment__|mixed%i_${impactGrouping}__`]: 'Impact On Mixed Sentiment',
        ...numericalFields.value.reduce((obj, field) => ({
          [`${field}|mean__`]: `Numerical Field ${field}`,
          [`${field}|mean__i_${impactGrouping}__`]: `Impact On ${field}`,
          ...obj,
        }), {})
      }

      let skipOverall = [
        'coverage_doc_rto__',
        `NPS Category|npsi_${impactGrouping}__`,
        `sentiment__|positive%i_${impactGrouping}__`,
        `sentiment__|negative%i_${impactGrouping}__`,
        `sentiment__|mixed%i_${impactGrouping}__`,
        ...numericalFields.value.map((field) =>
          `${field}|mean__i_${impactGrouping}__`,
        ),
      ]

      if (Object.keys(scoreColumnsMap).length > 0) {
        metrics = {
          ...metrics,
          ...scoreColumnsMap['standard'],
          ...scoreColumnsMap['impact'],
        }
        skipOverall = [
          ...skipOverall,
          ...Object.keys(scoreColumnsMap['impact']),
        ]
      }

      const groups = new Map<string, TrendLine[]>()

      for (let m of Object.values(metrics)) {
        // These are arrays only because currently the data structure expected
        // by the inner timeline widget, `selectedDataInPlottableFormat`, is
        // an array. This is awkward because we do lookups on it below.
        groups.set(m, [])
      }
      // for each individual record in the payload array
      for (let v of data.payload) {
        // When viewing themes or theme groups on the overall dashboard, we need all groups
        if (props.viewingQuery || !['Themes', 'Theme Groups'].includes(selectedDataDisplayMode.value[1])) {
          if (v.group__ !== props.group) {
            continue
          }
        }

        for (let k in v) {
          let metric_key = metrics[k]
          let g = groups.get(metric_key)
          if (g === undefined) continue

          if (fieldName === undefined && v.group__ === 'overall__' && skipOverall.includes(k)) {
            continue
          }

          // Now that we have our "plottable group", we need to find the
          // trend entry that matches.
          let trend_id: TrendLine['id']
          let name: TrendLine['name']
          let lineStyle: TrendLine['lineStyle']

          if (fieldName) {
            trend_id = `${metric_key}|${v[fieldName]}`
            name = `${v[fieldName]}`
            lineStyle = 'solid-line'
          } else {
            trend_id = `${metric_key}|${v.group__}`
            name = v.group__ === 'overall__' ? 'Overall' : v.group__
            lineStyle = v.group__ === 'overall__' ? 'dashed-line' : 'solid-line'
          }

          let queryId = undefined

          if (selectedDataDisplayMode.value[1] === 'Themes' && v.group__ !== 'overall__') {
            queryId = Number(name.replace('q_', ''))
            name = themeNameMap.value[queryId]
          }

          let trendLine = g.find(item => item.id === trend_id)
          if (trendLine === undefined) {
            trendLine = {
              color: '',
              id: trend_id,
              name: name,
              query_id: queryId,
              lineStyle: lineStyle,
              visible: true,
              counts: [],
              datetimes: []
            }
            g.push(trendLine)
          }

          // We don't get coverage relative to the group from the pivot endpoint, it has to be calculated manually
          if (fieldName && props.group !== 'overall__' && k === 'coverage_doc_rto__' && props.fieldFrequency?.data) {
            const dataDisplay = selectedDataDisplayMode.value[1]

            // Find corresponding data in fieldFrequency
            const datum = props.fieldFrequency?.data?.payload.find((d) =>
              d[selectedDatefield.value] === v[selectedDatefield.value] &&
              d.group__ === v.group__
            )

            if (!datum) continue

            // Sum frequencies to get a total for the field
            const sum = Object.entries(datum).reduce(
              (sum, [ key, value ]) =>
                key.startsWith(dataDisplay + '|') ? sum + (value as number) : sum,
                0
              )

            // Numerical types are formatted as a float, e.g 1 is "category|1.0"
            const schemaRow = props.schema.find(({ name }) => name === dataDisplay)
            if (['NUMBER', 'NPS'].includes(schemaRow?.typename ?? '')) name += '.0'

            // Calculate fraction of the total
            const fraction = (datum[`${dataDisplay}|${name}`] as number) / sum || 0
            trendLine.counts.push(fraction)
          } else if (k.includes('box%__')) {
            // We need to do this since the box%__ are already multiplied by 100,
            // unlike other percentages, and the value we need here should be in '0.2%' format.
            trendLine.counts.push(Number(v[k])/100 as number)
          } else {
            trendLine.counts.push(v[k] as number)
          }

          let d = Date.parse(v[selectedDatefield.value] as string)
          trendLine.datetimes.push(d)
        }

        // Gather frequency stats for tooltips

        const key = ['Themes', 'Theme Groups'].includes(selectedDataDisplayMode.value[1])
          ? v.group__
          : v[selectedDataDisplayMode.value[1]] as string

        const dateField = selectedDatefield.value

        const overallTotal = data.payload
          .filter((d) => d.group__ === 'overall__' && d[dateField] === v[dateField])
          .reduce((total, d) => total + (d.frequency_cov as number), 0)

        if (!records.value[key]) records.value[key] = {}

        records.value[key][v[dateField] as string] = {
          countDocumentFraction: (v['frequency_cov'] as number) / overallTotal,
          countDocument: v['frequency_cov'],
        }
      }

      for (let series of groups.values()) {
        let i = 0
        for (const v of series) {
          if (v.name === 'Overall') {
            v.color = overallNPSColor
          } else {
            v.color = DrawUtils.dashboardColourPalette[i % DrawUtils.dashboardColourPalette.length]
            i += 1
          }
        }
      }

      groupedData.value = groups
      updatePlot()
    }

    const updateConfig = () => {
      // Keep legend settings for other Data selections, overwrite the rest
      const hiddenLegendItems =
        (props.config?.options?.hiddenLegendItems ?? [])
          .filter((name) => !name.startsWith(`${selectedDataDisplayMode.value[1]}_`))
          .concat(hiddenLegendNames.value)

      const options: NonNullable<typeof props.config>['options'] = {
        dataDisplayMode: selectedDataDisplayMode.value,
        displayMode: selectedDisplayMode.value,
        dateField: selectedDatefield.value,
        resolution: resolution.value,
        hiddenLegendItems,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const getTrendEl = () => {
      const svg = root.value?.$el.querySelector('#timeline-trend svg') as SVGElement
      const legend = root.value?.$el.querySelector('.timeline-legend-container') as HTMLElement
      if (!svg || !legend) return null

      const svgClone = svg.cloneNode(true) as SVGElement
      const legendClone = legend.cloneNode(true) as HTMLElement

      const className = `legend-${Date.now()}`
      legendClone.classList.add(className)

      svgClone.style.width = `${svg.clientWidth}px`
      svgClone.style.visibility = 'hidden'
      svgClone.style.opacity = '0'
      let svgForeignObject = `
        <foreignObject
          height="${legend.clientHeight}"
          width="${legend.clientWidth}"
          y="${svg.clientHeight}"
          x="0"
        >
          <body xmlns="http://www.w3.org/1999/xhtml">
            <style>
              .${className} {
                list-style: none;
                column-count: 3;
              }
              .${className} svg {
                width: 12px;
                height: 12px;
                flex-shrink: 0;
                margin-right: 4px;
                margin-left: 0;
                margin-top : 3px;
              }
              .${className} .clickable-legend {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
              }
              .${className} li {
                margin-bottom: 2px;
                break-inside: avoid;
                font-size: 13px;
                font-family: 'Lato', Arial, Helvetica, sans-serif;
              }
            </style>
            ${new XMLSerializer().serializeToString(legendClone)}
          </body>
        </foreignObject>
      `
      svgClone.innerHTML += sanitize(svgForeignObject)
      const totalHeight = svg.clientHeight + legend.clientHeight
      svgClone.setAttribute('height', totalHeight.toString())

      document.body.appendChild(svgClone)
      setTimeout(() => svgClone.remove(), 0)

      return svgClone
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .dashed-line {
            stroke-dasharray: 3px 3px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
          .legend-entry.hidden {
            display: none;
          }
          .group-tag {
            color: #AAA
          }
        `
      }
    }

    const getCsvData = () => {
      //  Marshall the series data into a date-based structure:
      //    { 'Thu Jan 01 2015 00:00:00' : { 'customer service ': 1, 'issues': 4 }, ...}
      const isThemes = selectedDataDisplayMode.value[1] === 'Themes'
      const dataByDate: { [key: number]: any} = {}
      const dates: number[] = []
      selectedDataInPlottableFormat.value.forEach((s) => {
        s.datetimes.forEach((d: number, j: number) => {
          if (!dataByDate[d]) {
            dataByDate[d] = {}
            dates.push(d)
          }
          const id = s.query_id ?? s.name
          dataByDate[d][id] = s.counts[j]
        })
      })

      // Generate exhaustive lists of dates & series names
      dates.sort((a, b) => a - b)
      const seriesNames = selectedDataInPlottableFormat.value.map(series => series.query_id ?? series.name)
      seriesNames.sort()

      return dates.map(d => {
        // Ugly way to print out the right date but without any timezone info
        // (JS thinks every date created is relative to local timezone).
        const dateString = ChartUtils.formatDate(d, resolution.value.toLowerCase())
        const row = {
          [selectedDatefield.value]: dateString
        }
        seriesNames.forEach((id) => {
          let name = isThemes ? themeNameMap.value[+id] : id
          if (isThemes && themeToGroupNameMap.value[+id]) {
            name += ` [${themeToGroupNameMap.value[+id]}]`
          }
          row[name] = dataByDate[d][id] ?? null
        })
        return row
      })
    }

    const setChartDimensions = (_: unknown, height: number): void => {
      if (props.isZoomed) {
        const height_num = height - 200
        chartHeight.value = `${height_num < 320 ? 320 : height_num}px`
      } else {
        chartHeight.value = '320px'
      }
    }

    const setDate = ([title, value]: [string, string]) => {
      // Only trigger redraw for an actual change
      if (selectedDatefield.value !== value) {
        selectedDatefield.value = value
      }
    }

    const setMenuSelection = (menu: string, value: [string, string]) => {
      if (menu === 'DATA') {
        selectedDataDisplayMode.value = value
        analytics?.track.timeline.changeData(value[0], value[1])
      } else if (menu === 'DISPLAY') {
        if (value[0] === 'Date Field') {
          setDate(value)
        } else {
          // If the selected col is part of score columns, remove the aggregation title from displayMode[1],
          // This is done to separate agg info from score column name which will be saved in the dashboard config.
          let scoreColLabelsMap: Record<string, string> = {}
          scoreColumns.value.forEach((col) => scoreColLabelsMap[`${col.name} (${col.aggregation.title})`] = col.name)
          value[1] = Object.keys(scoreColLabelsMap).includes(value[1])? scoreColLabelsMap[value[1]]: value[1]
          selectedDisplayMode.value = value
        }
        analytics?.track.timeline.changeDisplay(value[0], value[1])
      } else if (menu === 'RESOLUTION') {
        resolution.value = value[1] as Resolution
        analytics?.track.timeline.changeResolution(value[1])
      }
      updateConfig()
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      const isThemes = selectedDataDisplayMode.value[1] === 'Themes'
      const slide = pptx.addSlide()
      makeTimelineSlide(
        pptx,
        slide,
        selectedDataInPlottableFormat.value.map((line) => {
          let name = line.name
          if (isThemes && themeToGroupNameMap.value[line.query_id!]) {
            let groupName = themeToGroupNameMap.value[line.query_id!]
            groupName = truncate(groupName, 20)
            name += ` [${groupName}]`
          }
          return {
            ...line,
            name,
          }
        }),
        props.exportName + ' Timeline',
        selectedModeOption.value.yAxisLabel,
        props.dayFirstDates,
      )
    }

    watch(() => props.config, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch(selectedDatefield, () => {
      fetchData()
    })

    watch(selectedDisplayMode, () => {
      fetchData()
      updatePlot()
    })

    watch(scoreColumns, () => {
      fetchData()
      updatePlot()
    })

    watch(() => validOptions, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch(resolution, () => {
      fetchData()
    })

    watch(() => props.viewingQuery, () => {
      fetchData()
      setOptionsFromConfig()
    })

    watch(() => props.conceptQuery, () => {
      fetchData()
      setOptionsFromConfig()
    })

    watch(() => props.queries, () => {
      fetchData()
    }, {
      deep: true,
    })

    watch(() => props.timelineCues, () => {
      lastCueReqs.value = lastEmittedReqs.value
    })

    /**
     * When this data prop or loaded data (fieldFrequency) is changed,
     * we recalculate all the plottable series
     * that will be available to select in the UI. When those options are selected,
     * that code will simply assign the plottable data series to the inner timeline
     * widget.
     */
    watch(() => props.data, (data) => {
      const fieldFrequency = props.fieldFrequency
      // only handle if required data is available
      if (data === null) return
      if (selectedDataDisplayMode.value[0] === 'Other') {
        regroupData(data)
      } else if (selectedDataDisplayMode.value[0] === 'Field') {
        if (fieldFrequency?.status !== 'done') return
        regroupData(data, selectedDataDisplayMode.value[1])
      } else {
        throw new Error('Unhandled type of data')
      }
    }, {
      deep: true,
    })

    watch(() => props.fieldFrequency, (fieldFrequency) => {
      const data = props.data
      // only handle if required data is available
      if (data === null) return
      if (selectedDataDisplayMode.value[0] === 'Other') {
        regroupData(data)
      } else if (selectedDataDisplayMode.value[0] === 'Field') {
        if (fieldFrequency?.status !== 'done') return
        regroupData(data, selectedDataDisplayMode.value[1])
      } else {
        throw new Error('Unhandled type of data')
      }
    }, {
      deep: true,
    })

    onMounted(() => {
      setOptionsFromConfig()

      // This watch is set programmatically because we don't want the
      // modifications above to trigger `fetchData`.
      watch(selectedDataDisplayMode, () => fetchData())

      // This widget is remounted when entering the zoomed view.
      // this.data will be cached and unchanged, which means its watcher
      // won't be called, so we have to call regroupData manually instead
      // to populate selectedDataInPlottableFormat.
      if (props.data) {
        regroupData(props.data)
      } else {
        fetchData()
      }
    })

    return {
      refresh,
      contact,
      reload,
      icon,
      errorIcon,
      selectedModeOption,
      resolution,
      calculateYRange,
      updateConfig,
      root,
      getExportConfig,
      getCsvData,
      menus,
      setChartDimensions,
      setMenuSelection,
      hasDataOptions,
      getTrendEl,
      yRange,
      selectedDataInPlottableFormat,
      selectedDatefield,
      records,
      chartHeight,
      selectedDataDisplayMode,
      selectedDisplayMode,
      makePptSlide,
      seriesLabels,
      formattedTimelineCues,
      fetchTimelineCues,
      areTimelineCuesStale,
      themeToGroupNameMap,
      isExporting,
    }
  }
})

export default TimelineWidget
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.header-icon
  height: 32px
  width: 32px

.row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.stats
  display: flex
  flex-direction: row
  justify-content: space-evenly
  align-items: center

.stat
  font-size: 26px
  min-width: 5em
  font-weight: bold
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-grow: 1

.standard
  color: #068CCC

.positive
  color: rgb(33, 186, 69)

.negative
  color: rgb(238, 56, 36)

.neutral
  color: rgb(127, 127, 127)

.label
  margin: 10px
  font-size: 16px
  font-weight: normal

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255, 0, 0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0


.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px

  &:hover
    background-color: $grey-light

  &:focus
    border: 2px solid $blue-light
    outline: none

.ui.dimmer
  z-index: 5

/* Local styles */
.column.mask
  background-color: white
  opacity: 0.3
  pointer-events: none

// These settings are required to allow the chart to resize correctly. If you comment these out,
// weird things happen with the sizing of the timeline.
.timeline-container
  width: inherit
  align-items: unset

</style>
