<template>
  <bf-modal :visible="visible" :click-to-close="true" class="modal-confirm-prompt" @close="close">
    <bf-dialog @close="close">
      <div class="panel">
        <h2 v-if="title">
          {{ title }}
        </h2>
        <div class="main">
          <slot name="default" />
        </div>
        <footer>
          <div class="actions">
            <bf-button size="huge" color="grey" @click="close">
              {{ cancelText }}
            </bf-button>
            <div class="spacer" />
            <bf-button size="huge" color="blue" @click="confirm">
              {{ confirmText }}
            </bf-button>
          </div>
        </footer>
      </div>
    </bf-dialog>
  </bf-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BfModal, BfButton, BfDialog } from "components/Butterfly"

const ModalConfirmPrompt = defineComponent({
  components: { BfDialog, BfModal, BfButton },
  props: {
    visible: { type: Boolean, required: true },
    confirmText: { type: String, required: false, default: 'Confirm' },
    cancelText: { type: String, required: false, default: 'Cancel' },
    title: { type: String, required: false, default: '' },
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
})
export default ModalConfirmPrompt
</script>

<style lang="sass" scoped>
@import 'assets/kapiche'
div.main
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  p
    margin-bottom: 5px
h2
  height: 64px
  font-weight: 400
  font-size: 30px
  text-align: center
  padding: 10px 0 20px
  margin: 0

footer
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  min-height: 100px

div.actions
  display: flex
  padding: 0
  justify-content: space-around

  .spacer
    width: 30px

</style>
