<template>
  <div id="unmapped-container">
    <!-- Header -->
    <h1 class="title">
      Unmapped Verbatims
    </h1>
    <div class="subtitle">
      <p>
        Unmapped verbatims are those not captured by any existing Themes.
        <br>This page is designed to help you find themes that your framework may be missing.
      </p>
    </div>

    <!-- Empty state -->
    <template v-if="!isLoading && totalHits === 0">
      <div class="empty-state">
        <div class="ui divider"></div>
        <p class="caption">
          Nothing to show here!
        </p>
        <div class="no-data">
          <div>All verbatims have been included in a theme.</div>
        </div>
      </div>
    </template>

    <!-- Loading mask -->
    <template v-else-if="isLoading || (!isNetworkDrawn && model && !isInsufficientData)">
      <transition name="fade" leave-active-class="animated fadeOut">
        <page-loader :opacity="1">
          <slot>
            <template v-if="isLoading">
              Modelling unmapped verbatims...
            </template>
            <template v-else>
              Generating network layout...
            </template>
          </slot>
        </page-loader>
      </transition>
    </template>

    <template v-if="!isLoading && totalHits > 0 && model">
      <!-- Network layout -->
      <div class="network widget" style="width: calc(100% - 60px)">
        <div class="widget-header">
          <img class="header-icon" src="../../../../assets/img/dashboards/dash-context-network.svg" alt="Network icon">
          <h2 class="widget-title">
            Context Network
          </h2>
        </div>
        <div class="content">
          <template v-if="!isInsufficientData">
            <network-layout ref="networkLayout" :model="model" :height="600" node-tooltip-click-text="Click to query for concept:" node-tooltip-verbatim-text="Unmapped verbatims:" @loaded="isNetworkDrawn = true" @concept-selected="concept => queryConcept(concept, true)"></network-layout>
          </template>
          <!-- No data -->
          <template v-else>
            <div class="no-data network">
              <div>Not enough data for network</div>
            </div>
          </template>
        </div>
      </div>

      <div class="widget-columns">
        <div>
          <!-- Top terms -->
          <div class="widget">
            <span style="float:right; font-size:21px;">
              <download-export-button
                :name="currentAnalysis.name+'-Unmapped Terms'"
                :is-loading="isLoading"
                :get-csv-data="getUnmappedTermsCsvData"
                :get-el="getChartEl"
                :get-svg-export-config="getExportConfig"
                short-name="Unmapped Terms"
              ></download-export-button>
            </span>
            <div class="widget-header">
              <img class="header-icon" src="../../../../assets/img/dashboards/dash-queries.svg" alt="Queries icon">
              <h2 class="widget-title">
                Most Frequent Unmapped Terms
              </h2>
            </div>
            <div class="ui divider"></div>
            <!--
              It's important to note that the network model doesn't return all concepts in the unmapped verbatims, simply the ones that are included in the network. If we don't have sufficient data to generate a network structure, we won't see any concepts either.
            -->
            <div v-if="sortedConcepts.length === 0" class="no-data">
              <div>Not enough data to show frequent terms</div>
            </div>
            <template v-if="sortedConcepts.length > 0">
              <div class="content concepts-chart">
                <div class="chart-title">
                  <div class="column">
                    Term
                  </div>
                  <div class="column">
                    # Verbatims
                  </div>
                </div>
                <div
                  v-for="(n, i) in Math.min(numConceptsShown, sortedConcepts.length)"
                  :key="sortedConcepts[i][0]"
                  class="concept-entry"
                  @click="queryConcept(sortedConcepts[i][0])"
                >
                  <div class="label">
                    <div class="concept-name">
                      {{ sortedConcepts[i][0] }}
                    </div>
                    <div class="concept-freq">
                      {{ sortedConcepts[i][1] }}
                    </div>
                  </div>
                  <div class="bar-background">
                    <div class="bar-fill" :style="{ width: 100 * sortedConcepts[i][1] / maxConceptFrequency + '%' }"></div>
                  </div>
                </div>
                <a v-if="numConceptsShown < sortedConcepts.length" href="javascript:void(0)" @click="numConceptsShown += 10">Show More</a>
              </div>
            </template>
          </div>
          <!-- Emergent Concepts -->
          <template v-if="hasDate && !isLoading && totalHits > 0 && model && currentSite">
            <div class="emergent-concepts" style="width: calc(100%)">
              <emergent-concepts
                :base-query="[unmappedBody['base_query']]"
                :exclude-queries="unmappedBody['exclude_queries_list'].map(q => q.value)"
                :topic-id="currentAnalysis.topic_framework_id"
                :project-id="currentProject.id"
                :analysis-id="currentAnalysis.id"
                :chrysalis-ref="currentProject.chrysalis_ref"
                :chrysalis-url="currentSite?.chrysalis_url"
                :saved-queries="savedQueries"
                :export-name="currentAnalysis.name"
                :date-fields="currentModel.dateFields"
                :default-date-field="defaultDateField"
                :show-drilldown="true"
                :banner="widgetBanners['emergent-concepts']"
                @concept-clicked="queryConcept"
              />
            </div>
          </template>
        </div>
        <div>
          <!-- Excerpts -->
          <div ref="verbatims" class="widget excerpts">
            <excerpts-unmapped
              :query="baseQuery"
              :unmapped-body="unmappedBody"
              :exclude-empty="true"
              :preview="false"
              :allow-highlight-toggle="false"
              :selected-concept="selectedConcept"
            >
              <!-- Selected concept dropdown -->
              <template #subheader>
                <div class="excerpts-subheader">
                  <div style="float:left">
                    Showing unmapped verbatims for:
                    <dropdown :value="selectedConcept" @input="v => selectedConcept = v">
                      <!-- display currently selected concept -->
                      <template #trigger>
                        <div style="cursor: pointer">
                          {{ selectedConcept || 'All terms' }}
                          <i class="icon dropdown"></i>
                        </div>
                      </template>
                      <!-- concept selections -->
                      <dropdown-item :key="-1" :value="null">
                        All terms
                      </dropdown-item>
                      <dropdown-item v-for="concept in sortedConceptsAlpha" :key="concept" :value="concept">
                        {{ concept }}
                      </dropdown-item>
                    </dropdown>
                  </div>
                  <div v-if="selectedConcept" class="concept-query-link" style="float: right">
                    <a :href="generateResolvedQueryConceptLink(selectedConcept)">Query for "{{ selectedConcept }}"</a>
                  </div>
                  <div style="clear:both"></div>
                </div>
              </template>
            </excerpts-unmapped>
          </div>
          <!-- Data statistics -->
          <div class="widget">
            <div class="widget-header">
              <img class="header-icon" src="../../../../assets/img/dashboards/dash-data-statistics.svg" alt="Statistics icon">
              <h2 class="widget-title">
                Unmapped Data Statistics
              </h2>
            </div>
            <div class="ui divider"></div>
            <div class="stats">
              <div><div>{{ number(model.n_frames) }}</div><div>Verbatims</div></div>
              <div><div>{{ decimalAsPercent(fractionUnmapped) }}</div><div>% of all verbatims</div></div>
              <div><div>{{ number(model.avg_frame_terms, ',.00') }}</div><div>Avg words per verbatim</div></div>
              <div v-if="model.n_non_english_frames !== undefined">
                <div>{{ number(model.n_non_english_frames) }}</div><div>Non-english verbatims</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="!isLoading && totalHits > 0 && !model">
      <div class="no-data">
        <div>
          Sorry! The Unmapped Verbatims screen is temporarily out of action.
          <br>Your site will be upgraded in the coming weeks, improving the query screen and making this screen much faster to load.
          <br>We appreciate your patience! Please don't hestiate to reach out!
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import NetworkLayout from 'src/components/project/analysis/results/widgets/NetworkLayout.vue'
  import { mapGetters, mapActions } from 'vuex'
  import { LOAD_SAVED_QUERIES } from 'src/store/types'
  import DownloadExportButton from './widgets/DownloadExportButton.vue'
  import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
  import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
  import Excerpts from './widgets/Excerpts.vue'
  import ExcerptsUnmapped from './widgets/ExcerptsUnmapped.vue'
  import PageLoader from 'src/components/widgets/PageLoader.vue'
  import Utils from 'src/utils/general'
  import { hasUnstructured, expandQuery } from 'src/utils/query'
  import Query, { expandSavedQueryIfPossible } from 'src/api/query'
  import { EmergentConcepts } from 'src/components/DataWidgets'
  import type { SavedQuery, ChrysalisQueryType } from 'src/types/Query.types'
  import FormatUtils from 'src/utils/formatters'

  const MIN_CONCEPTS = 3  // minimum number of concepts to render the network
  const NUM_NETWORK_CONCEPTS = 50  // number of concepts to show

  export default defineComponent({
    components: {
      DownloadExportButton,
      Dropdown,
      DropdownItem,
      Excerpts,
      ExcerptsUnmapped,
      NetworkLayout,
      PageLoader,
      EmergentConcepts
    },
    props: {
      analysisId: { type: Number, required: true},
      projectId: { type: Number, required: true},
      navigatedConcept: { type: String, required: false, default: null },
    },
    data () {
      return {
        isLoading: true,
        isNetworkDrawn: false,
        model: undefined,
        numConceptsShown: 10,
        totalHits: undefined,
        selectedConcept: undefined,
        hits: {},
      }
    },
    computed: {
      ...mapGetters([
        'currentProject', 'currentAnalysis', 'currentModel', 'currentSite',
        'savedQueries', 'featureFlags', 'defaultDateField', 'dateFields',
        'widgetBanners'
      ]),
      hasDate () {
        return this.dateFields.length > 0
      },
      fractionUnmapped () {
        if (
          this.isLoading
          || this.isInsufficientData
          || this.model?.n_frames === undefined
          || this.currentModel?.stats?.non_empty_frames === undefined
        ) {
          return NaN
        }
        return this.model.n_frames / this.currentModel.stats.non_empty_frames
      },
      isInsufficientData () {
        return this.model && this.model.concepts.length < MIN_CONCEPTS
      },
      maxConceptFrequency () {
        return this.sortedConcepts[0][1]
      },
      savedQueriesWithText () {
        return this.savedQueries.filter(
          ({ name, query_value }:SavedQuery) => hasUnstructured([expandQuery(name, query_value, this.savedQueries)])
        )
      },
      // Other (unmapped verbatims)
      otherQuery () {
        const q = {
          type: 'match_all',
          includes: [{ type: 'all_data' }],
          excludes: this.savedQueriesWithText.map(query => query.query_value)
        }
        if (this.selectedConcept) {
          q.includes = [{ type: 'text', value: this.selectedConcept }]
        }
        return q
      },
      excludeQueriesList () {
        const list = this.savedQueriesWithText
          .map((q: ChrysalisQueryType) => {
            return {
              name: q.name,
              value: expandSavedQueryIfPossible(q.name, q.query_value, this.savedQueries)
            }
          })
        return list
      },
      baseQuery () {
        // This query is the base query that provides the result for all non-empty
        // verbatims. In case a concept is selected, this query morphs to search for
        // all instances of that particular concept.
        const q = {
          type: 'match_all',
          includes: [
            {
              type: 'nonempty_data'
            }
          ]
        }
        if (this.selectedConcept) {
          q.includes = [{type: 'text', value: this.selectedConcept}]
        }
        return q
      },
      unmappedBody () {
        // The excludeQueriesList and BaseQuery combined to create the request body for
        // unmapped screen. Mainly used for the UnmappedExcerpts widget
        return {exclude_queries_list: this.excludeQueriesList, base_query: this.baseQuery}
      },
      emergentConceptQuery () {
        if (this.savedQueriesWithText.length == 0) {
          return [{
            type: 'match_all',
            includes: this.selectedConcept
              ?[{ type: 'text', value: this.selectedConcept }]
              :[{ type: 'all_data' }],
            excludes:[]
            }]
        }
        else {
          return this.savedQueriesWithText.map(query=>{
          return {
            type: 'match_all',
            includes: this.selectedConcept
              ?[{ type: 'text', value: this.selectedConcept }]
              :[{ type: 'all_data' }],
            excludes:[query.query_value]
          }
        })
        }
      },
      // Concepts sorted by frequency decreasing
      sortedConcepts () {
        const concepts = []
        for (const concept of this.model.concepts) {
          concepts.push([concept.name, concept.frequency])
        }
        concepts.sort((v1, v2) => v2[1] - v1[1])
        return concepts
      },
      // Concepts sorted alphanumerically
      sortedConceptsAlpha () {
        const concepts = []
        for (const concept of this.model.concepts) {
          concepts.push(concept.name)
        }
        concepts.sort(Utils.naturalSort({caseSensitive: false}))
        return concepts
      }
    },
    watch: {
      savedQueriesWithText () {
        this.generateModel()
      },
      isLoading (newVal) {
        if (!newVal) {
          setTimeout(() => {
            if (this.navigatedConcept) {
              this.queryConcept(this.navigatedConcept, true)
            }
          }, 1000)
        }
      },
    },
    async mounted () {
      await this.LOAD_SAVED_QUERIES({projectId: this.projectId, analysisId: this.analysisId})
    },
    methods: {
      ...mapActions({ LOAD_SAVED_QUERIES }),
      number: FormatUtils.number,
      decimalAsPercent: FormatUtils.decimalAsPercent,
      // Make API call to generate the model
      generateModel () {
        this.generateModelQeiii()
        // Track usage of the page
        this.$analytics.track.analysis.viewUnmapped(
          this.savedQueriesWithText.length, this.totalHits, this.fractionUnmapped
        )
      },
      generateModelQeiii () {
        Query.runUnmapped(
          this.projectId,
          this.unmappedBody,
          this.currentAnalysis.topic_framework_id,
          this.currentSite.chrysalis_url,
          this.currentProject.chrysalis_ref,
          {
            networkModel: true,
            numNetworkConcepts: NUM_NETWORK_CONCEPTS,
          }
        ).then(result => {
          this.model = result.model
          this.hits = result.hits
          if (this.model && !this.model.concepts) {
            // Legacy network model support
            this.model.concepts = this.model.terms
            this.model.links = this.model.npmi
            this.model.driving_concepts = []
          }
          this.totalHits = result.total_hits
          this.isLoading = false
        })
      },
      // Generate query link for concept
      generateQueryConceptLink (concept) {
        const query = [{
          type: 'text',
          values: [concept],
          operator: 'includes'
        }]
        return Utils.generateQueryLink(query)
      },
      // Returns a fully resolved query link for concept, rather than a route object
      generateResolvedQueryConceptLink (concept) {
        const route = this.generateQueryConceptLink(concept)
        route.params = {
          projectId: this.projectId,
          analysisId: this.analysisId
        }
        return this.$router.resolve(route).href
      },
      getUnmappedTermsCsvData () {
        return this.sortedConcepts.map((concept) => {
          return {
            name: concept[0],
            frequency: concept[1],
            coverage: concept[1] / this.model.n_frames
          }
        })
      },
      getChartEl (): SVGElement | null {
        return this.$el.querySelector('div.concepts-chart')
      },
      getExportConfig () {
        return {
          dims: this.getChartEl().getBoundingClientRect(),
          css: `
            text {
              color: #383838;
              font-size: 14px;
              stroke: none;
            }
            .line {
              stroke-width: 2px;
            }
            .axis path, .axis line {
              shape-rendering: crispEdges;
              stroke: #ebebeb;
              stroke-width: 2px;
              opacity: 0.5;
            }
          `
        }
      },
      // Query a concept; called based on click-interaction.
      queryConcept (concept, scrollToVerbatims=false) {
        if (scrollToVerbatims) {
          this.$refs.verbatims.scrollIntoView()
        }
        this.selectedConcept = concept
      }
    }
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  #unmapped-container
    height: 100%
    padding: 50px 30px 30px 30px
    h1.title
      font-size: 30px
      text-align: center
    div.subtitle
      font-size: 16px
      line-height: 26px
      margin-bottom: 50px
      text-align: center
    div.empty-state
      text-align: center
      .divider
        margin: 0 auto 40px auto
        width: 400px
      .caption
        margin-bottom: 15px

    .no-data
      height: auto
      line-height: 36px

    .masonry-grid
      padding: 0 30px 30px 30px
      .widget
        margin-top: 30px

    .excerpts-subheader
      font-weight: normal
      margin-bottom: 10px
      .concept-query-link
        a
          text-decoration: none
      .dropdown-trigger
        color: $blue
        font-weight: bold
        padding-left: 5px
      .dropdown-menu
        border: 1px solid $grey
        max-height: 300px
        overflow-y: auto

    /* Widget general class used to contain visualization elements */
    .widget
      background: white
      box-shadow: $box-shadow
      padding: 30px
      .widget-header
        text-align: center
        .header-icon
          height: 32px
        .widget-title
          font-size: 20px
          font-weight: bold
          margin-top: 15px
      .divider
        margin: 25px auto
        width: 100px
      .content
        margin-top: 25px
      &.excerpts
        padding: 0
        .segments
          border: 0
          box-shadow: none
        .header
          border-top: 0
      &.network
        border: 0
        margin-left: 30px
        padding: 30px 0 0 0
        .content
          border-top: 1px solid $grey

    .emergent-concepts
      .widget
        margin-top: 30px !important
        margin-bottom: 30px !important
        padding: 0
        border: 0

    .concepts-chart
      height: 480px
      overflow-y: auto
      padding: 0 25px
      text-align: left
      .chart-title
        color: $text-grey
        display: flex
        font-size: 12px
        font-weight: bold
        letter-spacing: 0.6px
        text-transform: uppercase
        .column
          padding: 0
          &:nth-child(1)
            text-align: left
          &:nth-child(2)
            flex-grow: 1
            text-align: right
      .concept-entry
        cursor: pointer
        margin: 15px 0
        .label
          display: flex
          line-height: 30px
          div
            width: 50%
            &.concept-name
              font-size: 16px
            &.concept-freq
              font-size: 14px
              text-align: right
        .bar-background
          background-color: $grey
          border-radius: 3px
          height: 20px
        .bar-fill
          background-color: $blue
          border-radius: 3px
          height: 20px
        &:hover
          color: $blue-light
          .bar-fill
            background-color: $blue-light
      a
        display: inline-block
        font-weight: bold
        text-decoration: none !important

    .stats
      font-size: 16px
      line-height: 34px
      > div
        display: flex
        width: 80%
        div
          margin: 0 auto
          width: 200px
          &:nth-child(1)
            font-weight: bold
            margin-right: 10px
            text-align: right
          &:nth-child(2)
            text-align: left

    .no-data.network
      height: 100px

    .widget-columns
      display: flex
      padding: 0 30px 30px 30px
      .widget
        margin-top: 30px
      > div
        flex: 1
        margin-right: 30px
        &:last-child
          margin-right: 0

      @media screen and (max-width: 1024px)
        flex-direction: column
        > div
          margin-right: 0
</style>
