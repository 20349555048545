<template>
  <button
    v-if="!routeTo"
    v-bind="$attrs"
    class="bf-button"
    :class="[ disabled ? 'disabled':'', sharp ? 'sharp':'', round ? 'round':'', compact ? 'compact':'', color, size]"
    :type="type"
    @click="!$attrs.onClick && $emit('click', $event)"
  >
    <slot />
  </button>
  <router-link
    v-else
    class="bf-button"
    :to="routeTo"
    :class="[ disabled ? 'disabled':'', sharp ? 'sharp':'', round ? 'round':'', compact ? 'compact':'', color, size]"
  >
    <slot />
  </router-link>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
export default defineComponent({
  inheritAttrs: false,
  props: {
    /** color of button: blue, green, orange, red, white, transparent.  Defaults to grey. */
    color: { type: String, required: false, default: null },
    /** size of button: mini, tiny, small, large, big, huge, massive: default is between small & large */
    size: { type: String, required: false, default: null },
    /** button type attribute, e.g "submit" */
    type: { type: String as PropType<"button" | "submit" | "reset" | undefined>, required: false, default: 'button' },
    /** reduces button padding */
    compact: { type: Boolean, required: false, default: false },
    /** makes ends of button rounded */
    round: { type: Boolean, required: false, default: false },
    /** removes smoothed corners,  making the corners exact points */
    sharp: { type: Boolean, required: false, default: false },
    routeTo: { type: Object, required: false, default: null }
    // future additions ?
    // border
    // dark
    // inverse
    // outline ?
  },
  computed: {
    disabled () {
      if (this.$attrs) {
        if (this.$attrs.disabled === undefined) return false
        if (this.$attrs.disabled === false) return false
        if (this.$attrs.disabled === true) return true
     }
     return false
    }
  }
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.bf-button
  cursor: pointer
  min-height: 1em
  border: 1px solid $grey
  vertical-align: baseline
  background-color: $grey
  color: $text-black
  font-family: $standard-font
  margin: 0 .25em 0 0
  padding: .78571429em 1.5em .78571429em
  text-transform: none
  text-shadow: none
  font-weight: 700
  line-height: 1em
  font-style: normal
  text-align: center
  text-decoration: none
  border-radius: 3px
  user-select: none
  font-size: 1rem
  display: inline-flex
  align-items: center
  justify-content: center

  &:hover, &:focus
    background-color: $grey-light
    outline: none
  &:active
    background-color: $grey
  &.disabled, &:disabled
    color: darkgrey
    background-color: $grey
    opacity: 0.45
    &:hover
      cursor: not-allowed

.blue
  border-color: $blue
  background-color: $blue
  color: white
  &:hover, &:focus
    background-color: $blue-light
  &:active
    background-color: $blue
  &.disabled, &:disabled
    color: white
    background-color: $blue

.green
  border-color: $green
  background-color: $green
  color: white
  &:hover, &:focus
    background-color: $green-light
  &:active
    background-color: $green
  &.disabled, &:disabled
    color: white
    background-color: $green

.red
  background-color: $red
  color: white
  &:hover, &:focus
    background-color: $red-light
  &:active
    background-color: $red
  &.disabled, &:disabled
    color: white
    background-color: $red-light

.orange
  background-color: $orange
  border-color: $orange
  color: white
  &:hover, &:focus
    background-color: $orange-light
  &:active
    background-color: $orange
  &.disabled, &:disabled
    color: white
    background-color: $orange

.white
  border-color: $blue
  background-color: $white
  color: $blue
  &:hover, &:focus
    background-color: $white
  &:active
    background-color: $white
  &.disabled, &:disabled
    color: $blue
    background-color: $white

.transparent
  border-color: transparent
  background-color: transparent
  color: $blue
  &:hover, &:focus
    background-color: transparent
  &:active
    background-color: transparent
  &.disabled, &:disabled
    color: $blue
    background-color: transparent

.sharp
  border-radius: 0

.compact
  padding: .58928571em 1.125em .58928571em

.round
  border-radius: 60px

.mini
  font-size: 0.78571429rem
.tiny
  font-size: 0.85714286rem
.small
  font-size: 0.92857143rem
.medium
  font-size: rem(16px)
.large
  border: none
  font-size: rem(18px)
.big
  font-size: 1.28571429rem
.huge
  font-size: 1.42857143rem
.massive
  font-size: 1.71428571rem
</style>
