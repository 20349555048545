import * as d3 from 'd3'
import { TimeInterval } from 'd3'
import dayjs from 'dayjs'

export type Resolution = 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'yearly'

export const xTickFormatter = (
  resolution: Resolution,
  dataLength: number,
  widgetWidth = 1000,
  allowedIndices: number[] | null = null) => {
  let lastYear = new Date(0)

  return (date: Date, i: number): string => {
    if (allowedIndices && !allowedIndices.includes(i)) return ''

    const formatMonth = d3.timeFormat('%b')
    const formatMonthYear = d3.timeFormat('%b \'%y')
    const formatQuarter = d3.timeFormat('Q%q \'%y')
    const formatYear = d3.timeFormat('%Y')

    // target minimum width of 80px per label
    const maxLabelWidth = 80
    const maxLabelCount = widgetWidth / maxLabelWidth
    const spacingFactor = Math.floor(dataLength / maxLabelCount)
    if (!allowedIndices && i % spacingFactor) return ''

    switch (resolution) {
      case 'daily':
      case 'weekly':
      case 'monthly': {
        // Calculate whether we need to indicate that
        // the x-axis labels have entered a new year
        const thisYear = d3.timeYear(date)
        if (thisYear > lastYear) {
          lastYear = thisYear
          return formatMonthYear(date)
        }

        return formatMonth(date)
      }
      case 'quarterly': return formatQuarter(date)
      case 'yearly': return formatYear(date)
    }
  }
}

export const getTickInterval = (resolution: string): TimeInterval | null => {
  switch (resolution) {
    default:
    case 'daily':
    case 'weekly':
    case 'monthly': return d3.timeMonth.every(1)
    case 'quarterly': return d3.timeMonth.every(3)
    case 'yearly': return d3.timeYear.every(1)
  }
}

export const formatTooltipDate = (date: Date, resolution: Resolution, short = false): string => {
  switch (resolution) {
    default: return dayjs(date).format(short ? 'MMM YYYY' : 'MMMM YYYY')
    case 'daily': return dayjs(date).format(short ? 'DD MMM YYYY' : 'dddd, DD MMMM YYYY')
    case 'weekly': return `Week beginning ${dayjs(date).format(short ? 'DD MMM YYYY' : 'DD MMMM YYYY')}`
  }
}
