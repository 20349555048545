<template>
  <div id="header" class="ui top fixed huge menu" :class="{collapse: !expandBar}">
    <template v-if="loggedIn">
      <div class="left menu">
        <!-- Project selector dropdown-->
        <div v-if="!currentUser.viewer" class="item project-selector">
          <div class="content">
            <div class="description">
              <p class="label">
                PROJECT
              </p>
            </div>
            <multiselect
              id="ajax"
              :model-value="currentProject"
              :options="projectsList"
              :loading="isLoading"
              :show-labels="false"
              :max-height="700"
              :custom-label="truncProjectName"
              placeholder="Select a project..."
              label="name"
              class="project-selector"
              @update:model-value="goToProject"
              @search-change="searchForProjectsHandler"
            >
              <template #project>
                <span :class="project-name">
                  {{ currentProject.name }}
                </span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>

      <div class="right menu">
        <a class="item" target="_blank" :href="CONST.intercom_links.HELP">
          <i class="large life ring icon"></i> Support
        </a>
        <div id="user-menu" class="ui dropdown item">
          <i class="large user icon"></i> {{ userName }} <i class="dropdown icon"></i>
          <div id="profile-menu" class="menu">
            <div class="heading">
              ACCOUNT
            </div>
            <router-link :to="{ name: 'profile' }" active-class="never-active" class="item">
              Profile
            </router-link>
            <a v-if="messages.length" active-class="never-active" class="item" @click.stop="showAnnouncements">
              Announcements ({{ messages.length }})
            </a>
            <div class="item" @click="doLogout">
              Log out
            </div>
            <template v-if="isAdmin">
              <div class="ui divider inset"></div>
              <div class="heading">
                SITE
              </div>
              <router-link :to="{ name: 'users' }" active-class="never-active" class="item">
                Users
              </router-link>
              <router-link :to="{ name: 'integrations' }" active-class="never-active" class="item">
                Integrations
              </router-link>
              <router-link :to="{ name: 'subscription' }" active-class="never-active" class="item">
                Subscription
              </router-link>
              <router-link :to="{ name: 'notifications' }" active-class="never-active" class="item">
                Notifications
              </router-link>
              <router-link v-if="subscription.exportAPIEnabled" :to="{ name: 'exportapi' }" active-class="never-active" class="item">
                Export API
              </router-link>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import _ from 'lodash'
  import $ from 'jquery'
  import { mapGetters, mapActions } from 'vuex'
  import Multiselect from 'vue-multiselect'

  import Util from 'src/utils/general'
  import Project from 'src/api/project'
  import { LOGOUT, FETCH_PROJECTS, TOGGLE_SHOW_ANNOUNCEMENTS } from 'src/store/types'

  export default defineComponent({
    components: { Multiselect },
    props: {
      expandBar: {type: Boolean, default: false}
    },
    data () {
      return {
        projectsList: [], // Set initial value to the store projects, otherwise set search value
        search_string: '', // Hold the current search string so we can be sure we're displaying correct values
        isLoading: false
      }
    },
    computed: {
      ...mapGetters([
        'loggedIn', 'isSubscribed', 'isAdmin', 'userName', 'projects',
        'currentProject', 'currentUser', 'featureFlags', 'messages',
        'subscription'
      ]),
    },
    watch: {
      loggedIn () {
        if (this.loggedIn === true) {
          this.initDropDown()
        }
      },
      projects: {
        handler () {
          this.isLoading = false
          this.$nextTick(() => {
            if (!this._evaluateSearch()) {
              this.projectsList = this.projects
            }
          })
        },
        deep: true
      },
    },
    created () {
      this.isLoading = true
      this.FETCH_PROJECTS().finally(()=>{ this.isLoading = false })
    },
    mounted () {
      if (this.loggedIn === true) {
        this.initDropDown()
      }
    },
    methods: {
      ...mapActions({FETCH_PROJECTS, TOGGLE_SHOW_ANNOUNCEMENTS}),
      // We need to pass vue multiselect a reference to a function. This is how we do that.
      // https://github.com/shentao/vue-multiselect/issues/127
      searchForProjectsHandler: _.debounce(function (search_string) {
        this.search_string = search_string
        // Evaluate whether or not to display search results at dispatch and property set
        if (this._evaluateSearch()) {
          this.isLoading = true
          Project.searchProjects(this.search_string)
            .then((response) => {
              if (this._evaluateSearch()) {
                this.projectsList = response
              } else {
                this.projectsList = this.projects
              }
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
            })
        } else {
          // If we don't send a search, the dropdown should be populated with initial project data
          this.projectsList = this.projects
        }
      }, 300),
      truncProjectName (project) {
        return Util.truncateText(project.name)
      },
      goToProject (project) {
        if (
          (this.$route.name === 'project-details' && this.$route.params.projectId !== project.id) ||
          (this.$route.name !== 'project-details')
        ) {
          this.$router.push({ name: 'project-details', params: { projectId: project.id }})
        }
      },
      doLogout () {
        $('#user-menu').dropdown('toggle')
        this.$store.dispatch(LOGOUT)
      },
      initDropDown () {
        this.$nextTick(() => {
          $('#user-menu').dropdown({
            action: 'hide',
            transition: 'drop'
          })
          $('#project-select').dropdown()
        })
      },
      _evaluateSearch () {
        return this.search_string !== '' && this.search_string !== null && this.search_string !== undefined
      },
      showAnnouncements () {
        $('#user-menu').dropdown('toggle')
        this.TOGGLE_SHOW_ANNOUNCEMENTS()
      }
    },
  })
</script>

<style lang="sass">
  @import 'assets/kapiche.sass'

  .ui.top.fixed.huge.menu
    .project-selector.item:before
      width: 0 !important
  /* Vue multiselect styling */
  .project-selector
    min-height: 0 !important
    .multiselect__option--highlight
      background-color: #f4f6f7 !important
      color: black
      &::after
        background-color: #f4f6f7 !important
        color: black
    .multiselect__spinner:after, .multiselect__spinner:before
        border-color: #068ccc transparent transparent
    .multiselect__tags
      font-size: 16px
    .multiselect__input, .multiselect__select, .multiselect__tags
      background: transparent
      border: none
      padding-left: 0
      /*prevent dropdown resizing on item select*/
      input#ajax
        width: 8.5rem
      span.multiselect__single, span.multiselect__placeholder
        padding-left: 0
        color: #068ccc
        font-weight: bold
        width: 100%
    .multiselect__select:before
      border-top-color: #068ccc


    .multiselect__content-wrapper
      overflow-x: hidden
      width: auto

  .project-selector
    p.label
      font-size: 0.85rem
      color: #95a6ac
      font-weight: bold

  @keyframes expand-heading-bar
    0%
      margin-left: 17rem
      width: calc(100% - 17rem)
    100%
      margin-left: 5rem
      width: calc(100% - 5rem)

  @keyframes collapse-heading-bar
    0%
      margin-left: 5rem
      width: calc(100% - 5rem)
    100%
      margin-left: 17rem
      width: calc(100% - 17rem)

  #header
    height: 5rem
    &:not(.collapse)
        animation: expand-heading-bar 0.2s ease-in-out forwards
    &.collapse
        animation: collapse-heading-bar 0.2s ease-in-out forwards

  #project-select
    span.text
      font-weight: bold
      font-size: 1.25rem
    i.icon.chevron
      padding-left: 1rem

  .heading
    font-family: $standard-font
    padding: 8px 12px 4px 19px
    text-transform: uppercase
    font-size: 13px
    font-weight: bold
    font-style: normal
    font-stretch: normal
    line-height: 1.33
    letter-spacing: 0.6px
    text-align: left
    color: #95a6ac


  #profile-menu
    width: 292px
    right: 18px !important
    top: 67px !important
    position: absolute !important
    border-radius: 5px !important
    box-shadow: 0 1px 5px 0 rgba(0, 1, 1, 0.15)
    background-color: #fff !important
    padding: 8px 0 20px

  #profile-menu > .item
    padding: 12px 20px !important
    color: #383838
    font-size: 16px
    font-family: $standard-font
    font-weight: normal
    font-style: normal
    font-stretch: normal

  div.ui.divider.inset
    margin: 10px 20px 10px 20px !important

  #profile-menu > a.item.never-active
    font-weight: normal !important
    background-color: #fff !important

    &:hover
      background-color: rgba(0, 0, 0, 0.05) !important
</style>
