<template>
  <div class="ui segments">
    <div class="ui segment sort-choice">
      Sort by
      <div class="ui inline dropdown">
        <div class="text">
          {{ capitalizeAll(sorting) }}
        </div>
        <i class="dropdown icon"></i>
        <div class="menu">
          <div class="item" :class="[sorting === 'colour groups' ? 'active' : '']">
            Color Groups
          </div>
          <div class="item" :class="[sorting === 'frequency' ? 'active' : '']">
            Frequency
          </div>
        </div>
      </div>
    </div>
    <div v-for="concept in sortedConcepts" :key="concept.name" class="ui segment concept-info" :class="{ hidden: isHidden(concept) }" @mouseover="hoverConcept(concept)" @mouseleave="hoverConcept(null)" @click="selectConcept(concept)">
      <div class="label">
        {{ concept.name }}
      </div>
      <div class="bar" :style="'background-color:' + colours[concept.name] + '; width:' + computeBarWidth(concept) + '%'">
        &nbsp;
      </div>
      <div class="value">
        {{ number(concept.nonEmptyCoverage * 100, '0.[0]') }}%
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import $ from 'jquery'
  import { mapGetters } from 'vuex'

  import Utils from 'src/utils/general'
  import ChartUtils from 'src/utils/chart'
  import FormatUtils from 'src/utils/formatters'

  export default defineComponent({
    props: {
      concepts: { type: Array, default: () => { [] } }
    },
    data () {
      return {
        sorting: 'frequency'
      }
    },
    computed: {
      ...mapGetters(['currentModel']),
      colours () {
        return this.currentModel.conceptColours
      },
      sortedConcepts () {
        if (this.sorting === 'color groups') {
          // Sort concepts by color groups in order of their most frequent concept,
          // followed by frequency within each group
          return this.concepts.concat().sort((t1, t2) => {
            let v = this.currentModel.conceptClusterOrder[t1.name] - this.currentModel.conceptClusterOrder[t2.name]
            if (v === 0) {
              v = t2.frequency - t1.frequency
            }
            return v
          })
        } else {
          // Simply sort concepts by frequency
          return this.concepts.concat().sort((t1, t2) => t2.frequency - t1.frequency)
        }
      },
      maxFrequency () {
        return Math.max(...this.concepts.map((t) => t.frequency))
      }
    },
    mounted () {
      this.$nextTick (() => {
        $(this.$el).find('.dropdown').dropdown({
          onChange: (value) => {
            this.sorting = value
          }
        })
      })
    },
    methods: {
      number: FormatUtils.number,
      capitalizeAll: FormatUtils.capitalizeAll,
      // Compute bar width using normalised frequency
      computeBarWidth (concept, maxWidth = 80) {
        return concept.frequency / this.maxFrequency * maxWidth
      },
      // Emit event for concept hover
      hoverConcept (concept) {
        this.$emit('concept-hovered', concept)
      },
      // Go to Query page for clicked concept
      selectConcept (concept) {
        let queryLink = Utils.generateQueryLink([{
          type: 'text',
          values: [concept.name],
          operator: 'includes'
        }])
        this.$router.push(queryLink)
      },
      // Is this concept hidden in the storyboard?
      isHidden (concept) {
        return concept.frequencyRank > this.currentModel.numConceptsDisplayed
      },
      getCsvData () {
        return this.sortedConcepts.map((concept) => {
          let d = {
            name: concept.name,
            frequency: concept.frequency,
            coverage: ChartUtils.formatPercent(concept.nonEmptyCoverage * 100),
          }
          return d
        })
      }
    }
  })
</script>

<style lang="sass" scoped>
  div.ui.segments
    border-left: 0
    border-right: 0
    border-bottom: 0

    div.segment
      border: 0
      padding-left: 0.75rem
      padding-right: 0.75rem
      padding-bottom: 0.1rem
      &.sort-choice
        font-weight: bold
        padding-bottom: 0.8rem
        .dropdown
          color: #068ccc

    div.concept-info
      cursor: pointer
      padding-top: 0
      transition: opacity .5s
      &.hidden
        opacity: 0.5
      div.bar, div.value
        display: inline-block
      div.bar
        height: 1rem
        margin-top: 0.2rem
      div.label
        color: #383838
        font-size: 1rem
      div.value
        color: #95a6ac
        font-size: 0.9rem
        line-height: 1rem
        padding-left: 0.4rem
      &:hover
        div.label, div.value
          color: #383838
          font-weight: bold
</style>
