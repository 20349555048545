<template>
  <!--
    This is not a redundant span. The WidgetFrame applies v-deep styles that
    require the css classes to be applied in a particular hierarchy. The dialog
    cannot be under the widget-action class or it will inadvertently get styles
    applied to it.
  -->
  <span>
    <span class="widget-action download">
      <dropdown
        v-if="getSvgExportConfig || getCsvData"
        class="download-export-button"
        position="is-bottom-right"
        @input="exportData(...$event)"
      >
        <template #trigger>
          <div style="cursor: pointer">
            <img class="icon-svg" :src="icon" alt="export">
          </div>
        </template>
        <template v-if="isLoading">
          <dropdown-item class="disabled">
            <bf-spinner size="tiny" text-pos="right">
              <span class="loading">Loading export data...</span>
            </bf-spinner>
          </dropdown-item>
        </template>
        <template v-else>
          <span v-if="showAlerts && featureFlags.cx_metrics" class="section">
            <div class="section-heading">
              ALERTS
            </div>
            <dropdown-item prevent-active :value="['createAlert']">
              Create alert
            </dropdown-item>
            <dropdown-item prevent-active :value="['manageAlerts']">
              Manage all alerts <icon class="share-icon" name="share" :size="12" color="#333" />
            </dropdown-item>
          </span>
          <span class="section">
            <div class="section-heading">
              DOWNLOAD&nbsp;AS...
            </div>
            <dropdown-item v-if="getCsvData" prevent-active :value="['downloadCsv']">
              Spreadsheet (CSV)
            </dropdown-item>
            <dropdown-item v-if="getEl && !isIE" prevent-active :value="['exportImage', 'download']">
              Image (PNG)
            </dropdown-item>
            <dropdown-item v-if="supportsClipboard && getEl && !isIE" prevent-active :value="['exportImage', 'clipboard']">
              Copy to clipboard (PNG)
            </dropdown-item>
            <dropdown-item v-if="getSvgExportConfig" prevent-active :value="['downloadSvg']">
              Vector (SVG)
            </dropdown-item>
            <dropdown-item v-if="makePptSlide" prevent-active :value="['downloadPPT']">
              PowerPoint (PPT)
            </dropdown-item>
          </span>
          <span v-if="botShareEnabled && (integrations.slack || (featureFlags.teams_bot && integrations.teams))" class="section">
            <div class="section-heading">
              SHARE&nbsp;VIA ...
            </div>
            <dropdown-item v-if="integrations.slack" prevent-active class="social" :value="['setDialogVisible', 'slackShareConfig']">
              <img :src="slackIcon" alt="export"> Slack
            </dropdown-item>
            <dropdown-item v-if="featureFlags.teams_bot && integrations.teams" prevent-active class="social" :value="['setDialogVisible', 'microsoftTeamsShareConfig']">
              <img :src="microsoftIcon" alt="export"> Microsoft Teams
            </dropdown-item>
          </span>
        </template>
      </dropdown>
    </span>

    <modal
      center
      :show-close="false"
      :visible="slackShareConfig.dialogVisible"
      @close="slackShareConfig.dialogVisible = false"
    >
      <template #header>
        <div class="share-title">
          <img :src="slackIcon" alt="export">
          Share to Slack
        </div>
      </template>
      <div v-if="slackShareConfig.error" class="share-error">
        {{ slackShareConfig.error }}
      </div>
      <VeeForm
        v-slot="{ meta: { valid, pending } }"
        :initial-values="initialFormValues"
        :on-submit="(v) => exportImage('slack', {
          ...v,
          channel: selectedChannel,
        })"
        validate-on-mount
      >
        <div class="row">
          <label>
            Channel
            <br />
            <el-select
              :model-value="selectedChannel"
              :multiple="false"
              placeholder="Select a channel..."
              :filterable="true"
              :loading="loading"
              @update:model-value="selectedChannel = $event"
            >
              <el-option
                v-for="channel in slackChannels"
                :key="channel.id"
                :label="channel.name"
                :value="channel.id"
              />
            </el-select>
          </label>
        </div>
        <Field
          v-slot="{ field, errors }"
          name="message"
          rules="max:200"
          validate-on-input
        >
          <label>
            <span>Message</span>
            <div v-if="errors" class="field-error">
              {{ errors[0] }}
            </div>
            <textarea
              v-bind="field"
              placeholder="Enter message"
              class="user-input"
            />
          </label>
        </Field>
        <div class="buttons">
          <bf-button
            size="large"
            :disabled="loading"
            @click="resetShare('slackShareConfig')"
          >
            Cancel
          </bf-button>
          <bf-button
            :disabled="!selectedChannel || !valid || pending || loading"
            type="submit"
            color="blue"
            size="large"
          >
            {{ loading ? 'Sending...' : 'Confirm' }}
          </bf-button>

        </div>
      </VeeForm>
    </modal>

    <modal
      center
      :show-close="false"
      :visible="microsoftTeamsShareConfig.dialogVisible"
      @close="microsoftTeamsShareConfig.dialogVisible = false"
    >
      <template #header>
        <div class="share-title">
          <img :src="microsoftIcon" alt="export">
          Share to Microsoft Teams
        </div>
      </template>
      <div v-if="microsoftTeamsShareConfig.error" class="share-error">
        {{ microsoftTeamsShareConfig.error }}
      </div>
      <VeeForm
        v-slot="{ meta: { valid, pending } }"
        :initial-values="initialFormValues"
        :on-submit="(v) => exportImage('microsoftTeams', {
          ...v,
          channel: selectedChannel,
        })"
        validate-on-mount
      >
        <div class="row">
          <label>
            Channel
            <br />
            <el-select
              :model-value="selectedChannel"
              :multiple="false"
              placeholder="Select a channel..."
              :filterable="true"
              @update:model-value="selectedChannel = $event"
            >
              <el-option
                v-for="channel in teamsChannels"
                :key="channel.id"
                :label="teamsDetailsToDisplayName(channel.details)"
                :value="channel.id"
              />
            </el-select>
          </label>
        </div>
        <Field
          v-slot="{ field, errors }"
          name="message"
          rules="max:200"
          validate-on-input
        >
          <label>
            <span>Message</span>
            <div v-if="errors" class="field-error">
              {{ errors[0] }}
            </div>
            <textarea
              v-bind="field"
              placeholder="Enter message"
              class="user-input"
            />
          </label>
        </Field>
        <div class="buttons">
          <bf-button
            size="large"
            :disabled="loading"
            @click="resetShare('microsoftTeamsShareConfig')"
          >
            Cancel
          </bf-button>
          <bf-button
            :disabled="!selectedChannel || !valid || pending || loading"
            type="submit"
            color="blue"
            size="large"
          >
            Confirm
          </bf-button>
        </div>
      </VeeForm>
    </modal>
    <alerts-modal ref="alertsModal" :dashboard-id="1" />
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import * as d3 from 'd3'
  import download from 'downloadjs'
  import html2canvas from 'html2canvas'
  import PptxGenJS from 'pptxgenjs'
  import { Form as VeeForm, Field, } from 'vee-validate'

  import BotAPI from 'src/api/chatbots'
  import DrawUtils from 'src/utils/draw'
  import Utils from 'src/utils/general'
  import { BfSpinner, BfButton, BfTextInput } from 'components/Butterfly'
  import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
  import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
  import { elementToSVG } from 'dom-to-svg'

  import icon from 'assets/img/download.svg'
  import slackIcon from 'assets/img/slack.svg'
  import microsoftIcon from 'assets/img/microsoft-teams.svg'

  import Icon from 'components/Icon.vue'
  import Modal from 'src/components/project/analysis/results/Modal.vue'
  import AlertsModal from 'src/components/AlertsModal.vue'
  import { WebhookConfig } from 'src/types/Chatbots.types'

  type ExportMethod = 'download' | 'slack' | 'microsoftTeams' | 'clipboard'
  type ImageType = 'image/png' | 'image/jpeg' | 'image/webp'

  interface FormValues {
    channel: null | string
    message: string
  }

  // While the teams message limit says in this documentation that the limit is
  // 28kB, through experimentation I have found that it can fail intermittently
  // above 21kB
  // https://learn.microsoft.com/en-us/microsoftteams/limits-specifications-teams
  const TEAMS_MESSAGE_LIMIT = 21_000


  const DownloadExportButton = defineComponent({
    components: {
      Dropdown,
      DropdownItem,
      BfSpinner,
      BfButton,
      BfTextInput,
      VeeForm,
      Field,
      Icon,
      Modal,
      AlertsModal,
    },
    props: {
      /*
      * name: String - A string representation of the title
      * shortName: Name of the export only (used for analytics)
      * getEl: A vanillaJs selector of the SVG element to be exported
      * getCsvData:
      * getSvgExportConfig: {
      *   dims: The dimensions of the svg
      *   css: The overwrite styles applied to the export as a string
      *   }
      */
      name: { type: String, default: '' },
      shortName: { type: String, default: '' },
      isLoading: { type: Boolean, default: false },
      getEl: { type: Function, default: null },
      getCsvData: { type: Function, default: null },
      getSvgExportConfig: { type: Function, default: null },
      botShareEnabled: { type: Boolean, default: true },
      showAlerts: { type: Boolean, default: false },
      makePptSlide: { type: Function, default: null },
      basicSvgExport: { type: Boolean, default: false },
    },
    data () {
      return {
        icon,
        slackIcon,
        microsoftIcon,
        slackShareConfig: {
          dialogVisible: false,
          channel: '',
          message: '',
          error: null as string | null,
        },
        microsoftTeamsShareConfig: {
          dialogVisible: false,
          channel: '',
          message: '',
          error: null as string | null,
        },
        loading: false,
        slackChannels: [] as { id: string, name: string }[],
        teamsChannels: [] as WebhookConfig[],
        selectedChannel: null as string | null,
      }
    },
    computed: {
      ...mapGetters([
        'featureFlags',
        'currentSite',
      ]),
      supportsClipboard (): boolean {
        return typeof ClipboardItem !== 'undefined'
      },
      // Detect IE because our PNG export method is not compatible with pre-Edge Microsoft.
      isIE (): boolean {
        return Utils.isIE()
      },
      initialFormValues (): FormValues {
        return {
          channel: null,
          message: '',
        }
      },
      integrations (): Record<string, boolean> {
        return {
          slack: this.currentSite?.chatbot_integrations?.slack,
          teams: this.currentSite?.chatbot_integrations?.teams,
        }
      },
    },
    methods: {
      async setDialogVisible (shareObjName: 'slackShareConfig' | 'microsoftTeamsShareConfig') {
        this[shareObjName].dialogVisible = true
        this.selectedChannel = null

        if (shareObjName === 'slackShareConfig') {
          this.getSlackChannels()
        } else if (shareObjName === 'microsoftTeamsShareConfig') {
          this.getTeamsChannels()
        }
      },
      resetShare (shareObjName: 'slackShareConfig' | 'microsoftTeamsShareConfig') {
        this[shareObjName] = {
          dialogVisible: false,
          channel: '',
          message: '',
          error: null,
        }
      },
      scaleToMinimum (width: number, height: number, min: number): number {
        const scale = Math.max(min / width, min / height)
        return Math.max(1, scale)
      },
      async exportData (functionName: string, ...args: unknown[]): void {
        this.$emit('export-change', true)
        await this.$nextTick()
        this[functionName]?.(...args)
        this.$emit('export-change', false)
      },
      serializeSvg (el: HTMLElement, exportConfig: object, scale = 1.5): string {
        const svgDocument = elementToSVG(el)
        // activeElement for Firefox, firstChild for Chrome
        const svg = svgDocument.activeElement ?? svgDocument.firstChild
        if (svg === null) {
          throw new Error("Unexpected missing svg element")
        }
        // Rescale.
        const currentWidth = parseFloat(svg.getAttribute('width') as string)
        const currentHeight = parseFloat(svg.getAttribute('height') as string)
        svg.setAttribute('width', `${currentWidth * scale}`)
        svg.setAttribute('height', `${currentHeight * scale}`)

        // Create a <style> element and add it to <defs> if it doesn't exist.
        const svgDefs = svg.querySelector('defs') || svgDocument.createElementNS("http://www.w3.org/2000/svg", "defs")
        const svgStyle = svgDocument.createElementNS("http://www.w3.org/2000/svg", "style")
        let dims = exportConfig.dims
        let css = exportConfig.css || ''
        css += `
          @font-face {
            font-family: Lato;
            src: local('Lato'), url(data:application/font-ttf;base64,${DrawUtils.getLatoFontBase64()}) format('truetype');
          }
          text {
            font-family: Lato;
          }
        `
        svgStyle.textContent = `
          <defs>
                <style>/* <![CDATA[ */ ${css} /* ]]> */</style>
          </defs>
        `
        svgDefs.appendChild(svgStyle)
        svg.appendChild(svgDefs)
        const svgContent = new XMLSerializer().serializeToString(svgDocument)
        return encodeURIComponent(svgContent)
      },
      serializeSvgBasic (el, exportConfig, scale = 1) {
        let dims = exportConfig.dims
        let css = exportConfig.css || ''
        css += `
          @font-face {
            font-family: Lato;
            src: local('Lato'), url(data:application/font-ttf;base64,${DrawUtils.getLatoFontBase64()}) format('truetype');
          }
          text {
            font-family: Lato;
          }
        `
        let serializer = new XMLSerializer()
        let svgContent =
          `<svg width="${dims.width * scale}" height="${dims.height * scale}"
                viewBox="0 0 ${dims.width} ${dims.height}" style="background:white"
                version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <style>/* <![CDATA[ */ ${css} /* ]]> */</style>
                </defs>
                ${Array.from(el.children).map((c) => serializer.serializeToString(c)).join('')}
          </svg>`
        return encodeURIComponent(svgContent)
      },
      downloadCsv (): void {
        let csvData = d3.csvFormat(this.getCsvData())
        Utils.downloadCsv(csvData, this.name)
        this.$analytics.track.analysis.downloadExport(
          this.shortName, 'CSV', { fullName: this.name }
        )
      },
      async downloadPPT () {
        const pptx = new PptxGenJS()
        await this.makePptSlide?.(pptx)
        pptx.writeFile({ fileName: `${this.name}.pptx` })
        this.$analytics.track.analysis.downloadExport(
          this.shortName, 'PPT', { fullName: this.name }
        )
      },
      async exportImage (method: ExportMethod, values: FormValues): Promise<void> {
        const maxBytes = method === 'microsoftTeams' ?
          (TEAMS_MESSAGE_LIMIT - this.microsoftTeamsShareConfig.message.length) :
          undefined
        const imageType = method === 'microsoftTeams' ? 'image/webp' : 'image/png'
        const imageData = await this.generateImageData(imageType, maxBytes)
        switch (method) {
          case 'slack':
            this.shareToSlack(imageData, values)
            break
          case 'microsoftTeams':
            this.shareToMicrosoftTeams(imageData, values)
            break
          case 'download':
            download(imageData, `${this.name}.png`, 'image/png')
            break
          case 'clipboard':
            // Convert the data url to a blob and copy to clipboard
            fetch(imageData)
              .then((res) => res.blob())
              .then((blob) => {
                navigator.clipboard.write([
                    new ClipboardItem({
                        'image/png': blob
                    })
                ])
              })
            break
        }
        this.$analytics.track.analysis.downloadExport(
          this.shortName, 'PNG', { fullName: this.name, exportMethod: method }
        )
      },
      async shareToSlack (image: string, values: FormValues): Promise<void> {
        this.loading = true
        this.slackShareConfig.error = null

        if (!values.channel) {
          return
        }

        try {
          await BotAPI.shareToSlack(
            image,
            values.channel,
            values.message,
          )
          this.resetShare('slackShareConfig')
        } catch (e: any) {
          if (e.status === 500) {
            this.slackShareConfig.error = 'There was an error sharing to Slack. Please try again.'
          } else {
            this.slackShareConfig.error = e.bodyText
          }
        } finally {
          this.loading = false
        }
      },
      async shareToMicrosoftTeams (image: string, values: FormValues): Promise<void> {
        this.loading = true
        this.microsoftTeamsShareConfig.error = null
        if (!values.channel) {
          return
        }
        try {
          await BotAPI.shareToTeams(
            image,
            values.channel,
            values.message,
          )
          this.resetShare('microsoftTeamsShareConfig')
        } catch (e: any) {
          if (e.status === 500) {
            this.microsoftTeamsShareConfig.error = 'There was an error sharing to Teams. Please try again.'
          } else {
            this.microsoftTeamsShareConfig.error = e.bodyText
          }
        } finally {
          this.loading = false
        }

      },
      async generateImageData (
        imageType: ImageType,
        maxBytes: number | undefined = undefined,
      ): Promise<string> {
        let el = await this.getEl()
        let tagName = el?.tagName.toLowerCase()
        let promise
        if (tagName === 'svg') {
          promise = this.svgToDataUrl(imageType, maxBytes)
        } else if (tagName === 'canvas') {
          promise = this.canvasToDataUrl(imageType, maxBytes)
        } else if (tagName === 'div' || tagName === 'span') {
          promise = this.elToDataUrl(el, imageType, maxBytes)
        }
        return promise
      },
      svgToDataUrl (imageType: ImageType = 'image/png', maxBytes = undefined, viewableOnly = false): Promise<string> {
        return new Promise (async (resolve) => {
          let exportConfig = this.getSvgExportConfig(viewableOnly)
          let dims = exportConfig.dims
          let padding = exportConfig.padding || 0
          let scale = 1
          if (maxBytes === undefined) {
            scale = this.scaleToMinimum(dims.width, dims.height, 1400)
          }

          // Use canvas and image to convert svg to png and download it
          let container = document.createElement('div')
          container.style.display = 'none'
          document.body.appendChild(container)
          let canvas = document.createElement('canvas')
          canvas.style.display = 'none'
          container.appendChild(canvas)
          let context = canvas.getContext('2d')
          let img = new Image()
          img.width = canvas.width = dims.width * scale
          img.height = canvas.height = dims.height * scale
          img.addEventListener('load', () => {
            // This setTimeout fixes a WebKit bug causing invisible text
            // due to the image being rendered before the @font-face has loaded
            setTimeout(() => {
              if (context === null) return
              context.fillStyle = '#fff'
              context.fillRect(0, 0, canvas.width, canvas.height)
              context.drawImage(img, padding, padding, canvas.width - padding * 2, canvas.height - padding * 2)
              const dataUrl = canvas.toDataURL(imageType)
              if (maxBytes && dataUrl.length > maxBytes) {
                scale = maxBytes / dataUrl.length
                img.width = canvas.width = dims.width * scale
                img.height = canvas.height = dims.height * scale
                context.fillRect(0, 0, canvas.width, canvas.height)
                context.drawImage(img, padding, padding, canvas.width - padding * 2, canvas.height - padding * 2)
              }
              resolve(canvas.toDataURL(imageType))
              // Cleanup
              container.remove()
            }, 0)
          })
          const el = await this.getEl()
          const serialize = this.basicSvgExport ? this.serializeSvgBasic : this.serializeSvg
          img.src = 'data:image/svg+xml; charset=utf8,' + serialize(el, exportConfig, scale)
          return img
        })
      },
      canvasToDataUrl (imageType: ImageType = 'image/png', maxBytes = undefined): Promise<string> {
        return new Promise (async (resolve) => {
          let el = await this.getEl()
          // Temporarily fill canvas with white background and copy image data for export
          let canvas = el
          let context = canvas.getContext('2d')
          let w = canvas.width
          let h = canvas.height
          let data = context.getImageData(0, 0, w, h)
          let compositeOperation = context.globalCompositeOperation
          context.globalCompositeOperation = 'destination-over'
          context.fillStyle = 'white'
          context.fillRect(0, 0, w, h)
          context.clearRect(0, 0, w, h)
          context.putImageData(data, 0, 0)

          const dataUrl = canvas.toDataURL(imageType)
          if (maxBytes && dataUrl.length > maxBytes) {
            const scale = maxBytes / dataUrl.length
            canvas.width = canvas.width * scale
            canvas.height = canvas.height * scale
          }
          context.globalCompositeOperation = compositeOperation
          resolve(canvas.toDataURL(imageType))
        })
      },
      elToDataUrl (el: any, imageType: ImageType = 'image/png', maxBytes = undefined): Promise<string> {
        return new Promise (async (resolve) => {
          // If it's just a html image, we're going to use domtoimage
          const isIE11 = (!(window.ActiveXObject) && 'ActiveXObject' in window)
          const options = {
            logging: process.env.APP_ENV !== 'production',
            removeContainer: true,
            scrollX: 0,
            scrollY: isIE11 ? -window.pageYOffset : -window.scrollY,
          }
          html2canvas(el, options)
            .then(canvasel => {
              const dataUrl = canvasel.toDataURL(imageType)
              if (maxBytes && dataUrl.length > maxBytes) {
                const scale = maxBytes / dataUrl.length
                canvasel.width = canvasel.width * scale
                canvasel.height = canvasel.height * scale
              }
              resolve(canvasel.toDataURL(imageType))
            }
          )
        })
      },
      downloadSvg () {
        let exportConfig = this.getSvgExportConfig()
        const serialize = this.basicSvgExport ? this.serializeSvgBasic : this.serializeSvg
        download('data:image/svg+xml; charset=utf8,' + serialize(this.getEl(), exportConfig), `${this.name}.svg`, 'image/svg+xml')
        this.$analytics.track.analysis.downloadExport(
          this.shortName, 'SVG', { fullName: this.name }
        )
      },
      async getSlackChannels () {
        this.loading = true
        try {
          const channels = await BotAPI.getPermittedSlackChannels()
          this.slackChannels = channels
          this.slackShareConfig.error = null
        } catch (e: any) {
          this.slackShareConfig.error = 'Failed to fetch Slack channels.'
        } finally {
          this.loading = false
        }
      },
      async getTeamsChannels () {
        try {
          const channels = await BotAPI.listWebhooks('teams')
          this.teamsChannels = channels
        } catch (e: any) {
          console.error(e)
          this.microsoftTeamsShareConfig.error = 'Failed to fetch Teams channels.'
        }
      },
      teamsDetailsToDisplayName (details: WebhookConfig['details']): string {
        return `${details?.team?.displayName} - ${details?.channel?.displayName}`
      },
      createAlert () {
        this.$refs.alertsModal.open()
      },
      manageAlerts () {
        this.$router.push({ name: 'alerts' })
      },
    }
  })
  export default DownloadExportButton
</script>

<style lang="sass" scoped>
  @import '~assets/kapiche.sass'
  /* tweaking the dropdown component styling */
  .download-export-button
    color: $grey
    &::v-deep
      &.active
        .widget-action::after
          display: none
      .dropdown-menu
        min-width: 0
        padding: 0
        margin-top: 0
        .dropdown-content
          border: 1px solid rgba(0, 0, 0, 0.13)
          padding: 20px
          border-radius: 0
          a
            padding: 6px
            color: rgba(0, 0, 0, 0.87)
            font-size: 1rem
            &.disabled
              color: rgba(0, 0, 0, 0.25)
              cursor: not-allowed

  .section:not(:last-child)
    margin-bottom: 15px

  .section-heading
    color: $blue
    font-weight: 700
    padding: 0px 6px 3px
    font-size: 1rem
  .social
    display: flex
    align-items: center
    img
      height: 25px
      width: 25px
      margin-right: 5px

  i.kapiche-icon-download
    color: $grey-dark

  .share-title
    display: flex
    align-items: center
    justify-content: center
    img
      margin-right: 10px
      width: 30px
    h2
      margin: 0

  .user-input
    width: 100%
  textarea
    height: 150px
    border: 1px solid #E5E5E5
    font-size: 16px
    padding: 17px  15px 13px
    outline: none
    color: $text-black
    font-family: $standard-font
    &:hover
      border-color: #95A6AC
    &:focus
      border-color: $blue
      box-shadow: 0 1px 5px -2px $blue
      &::placeholder
        color: transparent
      &.error
        border-color: $red
        color: $red
        box-shadow: 0 1px 5px -2px $red !important
    .error
      border-color: $red
      color: $red
  .buttons
    padding-top: 25px
    text-align: center
    > button:not(:last-child)
      margin-right: 20px

  .share-error
    color: $red
    text-align: center
    margin-bottom: 20px


  .el-dialog__body
    form > *:not(.buttons)
      margin-bottom: 10px
    div.dropdown
      background: #fff
      padding: 8px 12px
      border: 1px solid $grey-light
      cursor: pointer
      color: $text-black
      &.unselected
        color: $subdued
      i.dropdown
        margin-top: 1px
        margin-left: auto
        color: $text-black
        justify-content: end

  .field-error
    color: $red
    margin-bottom: 4px

  .share-icon
    margin-left: 3px
</style>
