import Vue from 'vue'
import * as microsoftTeams from "@microsoft/teams-js"
import { BotType, WebhookConfig } from 'src/types/Chatbots.types'

export interface ChatBotIntegration {
  id: number
  bot_type: BotType
  channels: { id: string, name: string }[]
  created_at: string
  expires: string
  site: number
}

const BotAPI = {
  // Send OAuth code to Botanic for verification
  verifyCode (code: string, type: BotType, url: string, site: string): PromiseLike<unknown> {
    const data = { code, type, url }
    const headers = { 'Site-Name': site }
    return Vue.http.post('chatbots/add_integration/', data, { headers })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  // Send base64 image, channel and message to share to Slack
  shareToSlack (image: string, channel: string, message: string): PromiseLike<unknown> {
    return Vue.http.post('chatbots/share/slack/', {
      image,
      channel,
      message,
    })
      .then(
        (response) => Promise.resolve(),
        (error) => Promise.reject(error)
      )
  },

  // Fetch a list of available Slack channels
  getSlackChannels (): PromiseLike<{ id: string, name: string }[]> {
    return Vue.http.get('chatbots/slack_channels/')
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  getPermittedSlackChannels (): PromiseLike<unknown> {
    return Vue.http.get('chatbots/permitted_slack_channels/')
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  // Remove a chat bot integration
  removeIntegration (type: BotType): PromiseLike<unknown> {
    return Vue.http.post('chatbots/remove_integration/', { type })
      .then(
        (response) => Promise.resolve(),
        (error) => Promise.reject(error)
      )
  },

  // List integrations for a site
  listIntegrations (): PromiseLike<ChatBotIntegration[]> {
    return Vue.http.get('chatbots/')
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  // List integrations for a site
  updateIntegration (id: number, data: Partial<ChatBotIntegration>): PromiseLike<void> {
    return Vue.http.patch(`chatbots/${id}/`, data)
      .then(
        (response) => Promise.resolve(),
        (error) => Promise.reject(error)
      )
  },

  addWebhook (
    type: BotType,
    webhook: string,
    channel: microsoftTeams.app.ChannelInfo | undefined,
    team: microsoftTeams.app.TeamInfo | undefined,
    site: string,
  ): PromiseLike<{'id': number}> {
    const headers = { 'Site-Name': site }
    const data = { type, webhook, channel, team }
    return Vue.http.post('chatbots/webhook/', data, { headers })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  listWebhooks (
    type: BotType,
    site?: string | undefined,
  ): PromiseLike<WebhookConfig[]> {
    let headers = {}
    if (site) {
      headers = { 'Site-Name': site }
    }
    return Vue.http.get(`chatbots/webhook/?type=${type}`, { headers })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  removeWebhook (id: string, site: string): PromiseLike<void> {
    const headers = { 'Site-Name': site }
    return Vue.http.delete(`chatbots/webhook/${id}/`, { headers })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  shareToTeams (imageData: string, webhookId: string, msg: string): PromiseLike<unknown> {
    return Vue.http.post(`chatbots/webhook/${webhookId}/`, {
      text: `${msg}<br /> ![](${imageData})`,
    }).then(
      (response) => Promise.resolve(),
      (error) => Promise.reject(error)
    )
  }
}

export default BotAPI
