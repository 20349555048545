/**
 * Access parts of our application state using pure functions.
 */

import Utils from 'src/utils/general'
import { COLUMN_LABELED_TYPES } from 'src/api/project'
import { State } from '.'
import { expandBotanicQueries } from 'src/utils/query'
import { ExpandedGroup, Group, expandThemeGroup } from 'src/pages/dashboard/Dashboard.utils'
import { SavedQuery } from 'src/types/Query.types'

interface storeGetters {
  [key: string]: any
}

// Auth
export const loggedIn = (state: State) => !!state.auth.token
export const userName = (state: State) => {
  if (state.auth.user) {
    return state.auth.user.first_name + ' ' + state.auth.user.last_name
  }
  return ''
}
export const currentUser = (state: State) => state.auth.user
export const isAdmin = (state: State) => {
  if (state.auth.user) {
    return state.auth.user.admin
  }
  return false
}
export const isSubscribed = (state: State) => {
  if (!state.auth.user) {
    return null
  }
  return state.subscription.allowsAccess
}
export const isTrialing = (state: State) => {
  if (!state.auth.user) {
    return false
  }
  return state.subscription.trialing
}

export const subscribedUntil = (state: State) => {
  return state.subscription.termEnd
}
export const dataUnits = (state: State) => {
  return state.subscription.dataUnitsBalance
}
export const domain = (state: State) => {
  return state.subscription.domain
}
export const plan = (state: State) => {
  return state.subscription.planId
}

// Project
export const integrations = (state: State) => state.project.integrations
export const notifications = (state: State) => state.project.notifications
export const projects = (state: State) => state.project.projects
export const currentProject = (state: State) => state.project.project
export const currentAnalysis = (state: State) => state.project.analysis
export const currentModel = (state: State) => state.project.model
export const currentDashboard = (state: State) => state.project.dashboard
export const dashboardWidgetConfig = (state: State) => state.project.dashboardWidgetConfig
export const dashboardDateRange = (state: State) => state.project.dashboardDateRange
export const dashboardQueries = (state: State) => state.project.dashboardQueries
export const themeGroups = (state: State) => state.project.themeGroups
export const dashboardAwaiting = (state: State) => state.project.dashboardAwaiting
export const projectMessage = (state: State) => state.project.message
export const analysisTimeline = (state: State) => state.project.analysisTimeline
export const dashboardTimeseries = (state: State) => state.project.dashboardTimeseries
export const nps = (state: State) => state.project.nps

export const sentiment = (state: State) => {
  let percentages = {}
  let frequencies = {}
  if (!state || !state.project || !state.project.sentiment) return { percentages, frequencies }

  if (state.project.sentiment.percentages) {
    let { mixed, neutral, positive, negative } = state.project.sentiment.percentages
    percentages = {
      mixed: !Number.isNaN(mixed) ? mixed : 0,
      neutral: !Number.isNaN(neutral) ? neutral : 0,
      positive: !Number.isNaN(positive) ? positive : 0,
      negative: !Number.isNaN(negative) ? negative : 0
    }
  }
  if (state.project.sentiment.frequencies) {
    const { mixed, neutral, positive, negative } = state.project.sentiment.frequencies
    frequencies = {
      mixed: !Number.isNaN(mixed) ? mixed : 0,
      neutral: !Number.isNaN(neutral) ? neutral : 0,
      positive: !Number.isNaN(positive) ? positive : 0,
      negative: !Number.isNaN(negative) ? negative : 0
    }
  }
  return { frequencies, percentages }
}

export const queriesMetadata = (state: State) => state.project.queriesMetadata
export const savedQueries = (state: State) => state.project.savedQueries

export const expandedSavedQueries = (state: State, getters: storeGetters) => {
  return expandBotanicQueries(getters.savedQueries, getters.savedQueries)
}
export const expandedDashboardQueries = (state: State, getters: storeGetters) => {
  return expandBotanicQueries(getters.dashboardQueries, getters.savedQueries)
}
export const expandedThemeGroups = (state: State, getters: storeGetters) => {
  const allGroups = getters.themeGroups as Group[]

  let themeGroups = allGroups.reduce((arr, g) => {
    // Only include group if it has children
    if (g.children.length > 0) {
      return arr.concat(expandThemeGroup(g, getters.savedQueries))
    }
    return arr
  }, [] as ExpandedGroup[])

  // Find ungrouped themes and append the "Ungrouped themes" group
  const savedQueries = getters.expandedSavedQueries as SavedQuery[]
  const unGroupedQueries = savedQueries.filter((q) => q.theme_group === null)

  // Append "Ungrouped themes" if there are themes not in a group
  if (unGroupedQueries.length > 0) {
    const ungroupedGroup = expandThemeGroup({
      id: -1,
      type: 'group',
      name: 'Ungrouped themes',
      children: unGroupedQueries.map((q) => ({
        id: q.id,
        name: q.name,
        type: 'theme',
      }))
    }, getters.savedQueries)
    themeGroups = themeGroups.concat([ungroupedGroup])
  }

  return themeGroups
}

// Results

export const hasNumericFields = (state: State, getters: any) => {
  return !!getters.sortedFieldsUnlimited.find((f: any) => f.type === 2)
}

export const hasNPS = (state: State) => {
  let schema = state.project?.project?.schema ?? state.project?.dashboard?.project?.schema
  if (schema) {
    return Utils.checkNpsExists(schema)
  }
  return false
}
export const npsFields = (state: State) => {
  let schema = state.project?.project?.schema ?? state.project?.dashboard?.project?.schema
  if (schema) {
    return Utils.getNpsFields(schema)
  }
  return []
}
export const hasDate = (state: State) => {
  let schema = state.project?.project?.schema ?? state.project?.dashboard?.project?.schema
  if (schema) {
    return Utils.checkSchemaHasDate(schema)
  }
  return false
}
export const dateFields = (state: State) => {
  let schema = state.project?.project?.schema ?? state.project?.dashboard?.project?.schema
  if (schema) {
    return Utils.getDateFields(schema)
  }
  return []
}
export const defaultDateField = (state: State, getters: any) => {
  if (state.project?.analysis?.default_date_field) {
    return state.project.analysis.default_date_field
  }
  return null
}
/**
 * Returns a function that will return an array of schema fields in the
 * project that match a particular type. The possible types are defined in
 * `api/project` and are TEXT, NUMBER, DATE, DATE_TIME, LABEL,
 * BOOLEAN, NPS and IGNORE.
 *
 * The returned function will return a result that looks like this array:
 *
 *   [
 *     {
 *
 *       index: boolean,
 *       name: string,
 *       type: integer
 *     },
 *     ...
 *   ]
 *
 * @param state
 * @returns {function(string): ([Object]|undefined)}
 */
// https://vuex.vuejs.org/guide/getters.html#method-style-access
export const getFieldsForType = (state: State) => (type: string) => {
  let schema = state.project?.project?.schema ?? state.project?.dashboard?.project?.schema
  if (!(schema)) {
    return
  }
  const column_index = COLUMN_LABELED_TYPES.get(type)
  const fields = schema.filter(
    (field) => field.type === column_index
  )
  return fields
}
export const categoricalFields = (state: State) => {
  return getFieldsForType(state)('LABEL')
}
/**
 * Return the name of the first field in the project schema that matches
 * the given "type". The possible types are defined in
 * `api/project` and are TEXT, NUMBER, DATE, DATE_TIME, LABEL,
 * BOOLEAN, NPS and IGNORE.
 *
 * If there are no fields in the schema matching the given type, null
 * is returned.
 *
 * @param state
 * @returns {null|String}
 */
export const getFirstSchemaFieldNameWithType = (state: State) => (type: string) => {
  const matchingSchemaFields = getFieldsForType(state)(type)
  if (!matchingSchemaFields) {
    return null
  }
  const first_match = matchingSchemaFields[0]
  if (first_match) {
    return first_match.name
  }
  return null
}
export const hasSentiment = (state: State) => {
  if (state.project.analysis && state.project.model) {
    return state.project.model.attribute_info.sentiment != null
  }
  return false
}

// Route
export const currentRoute = (state: State) => (state as any).route

// Flags
export const featureFlags = (state: State) => state.featureflags.features
export const lastFFlagFetchWhen = (state: State) => state.featureflags.lastFetchWhen
