import Vue from 'vue'
import { Alert } from 'src/components/AlertsPage.util'
import { Results } from './api.types'

interface AlertPayload extends Omit<Alert, 'id' | 'project' | 'dashboard' | 'added_by' | 'created_at'> {
  dashboard: number
}

const AlertsAPI = {
  // Fetch all alerts
  getAlerts (): PromiseLike<Results<Alert>> {
    return Vue.http.get('alerts/')
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  // Update an alert
  updateAlert (alert: Partial<Alert>): PromiseLike<Alert> {
    return Vue.http.patch(`alerts/${alert.id}/`, alert)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  // Create an alert
  createAlert (alert: AlertPayload): PromiseLike<Alert> {
    return Vue.http.post('alerts/', alert)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  // Delete an alert
  deleteAlert (id: number): PromiseLike<void> {
    return Vue.http.delete(`alerts/${id}/`)
      .then(
        (response) => Promise.resolve(),
        (error) => Promise.reject(error)
      )
  }
}

export default AlertsAPI
