<template>
  <bf-modal :visible="visible" @close="close">
    <bf-dialog @close="close">
      <div class="content">
        <h2 v-truncate="40">
          {{ header }}
        </h2>
        <!-- Page 1 - Add new column/field -->
        <div v-show="page === 1" class="field-container">
          <div class="field-row source-field-selection">
            <label>Source column:</label>
            <el-select
              :model-value="referenceField"
              :popper-append-to-body="false"
              @update:model-value="referenceField = $event"
            >
              <el-option v-for="field in fields" :key="field.index" :value="field.index" :label="field.name" :disabled="field.type === -1">
                {{ field.name + (field.type === -1 ? ' (IGNORE cannot be selected.)' : '') }}
              </el-option>
            </el-select>
          </div>
          <div class="field-row field-type-selection">
            <label>New column type:</label>
            <el-select
              :model-value="newType"
              placeholder=""
              :popper-append-to-body="false"
              @update:model-value="newType = $event"
            >
              <el-option :value="1" label="Text/Verbatim">
                <a class="ui red empty circular label"></a>
                Text/Verbatim
              </el-option>
              <el-option :value="2" label="Numerical">
                <a class="ui yellow empty circular label"></a>
                Numerical
              </el-option>
              <el-option :value="3" label="Date">
                <a class="ui olive empty circular label"></a>
                Date
              </el-option>
              <el-option :value="4" label="Date time">
                <a class="ui green empty circular label"></a>
                Date time
              </el-option>
              <el-option :value="5" label="Category">
                <a class="ui blue empty circular label"></a>
                Category
              </el-option>
              <el-option :value="7" label="NPS (0-10)">
                <a class="ui pink empty circular label"></a>
                NPS (0-10)
              </el-option>
              <el-option :value="-1" label="Ignore" :disabled="true" title="Ignore cannot be selected">
                <i class="ban icon red"></i>
                Ignore
              </el-option>
              <template #prefix>
                <span v-if="newType === 1">
                  <a class="ui red empty circular label"></a>
                </span>
                <div v-if="newType === 2">
                  <a class="ui yellow empty circular label"></a>
                </div>
                <div v-if="newType === 3">
                  <a class="ui olive empty circular label"></a>
                </div>
                <div v-if="newType === 4">
                  <a class="ui green empty circular label"></a>
                </div>
                <div v-if="newType === 5">
                  <a class="ui blue empty circular label"></a>
                </div>
                <div v-if="newType === 7">
                  <a class="ui pink empty circular label"></a>
                </div>
                <div v-if="newType === -1">
                  <i class="ban icon red"></i>
                </div>
              </template>
            </el-select>
          </div>
          <div class="field-row">
            <label for="new-name">New column header:</label>
            <bf-text-input
              id="new-name"
              v-model="newName"
              placeholder="Enter a name for this field"
            />
          </div>
        </div>
        <!-- Page 2 - Define transformations -->
        <div v-show="page === 2" class="transformations">
          <div class="actions">
            <a @click="uploadTransformationsFile">
              <input id="transformations-upload" ref="upload" type="file" accept=".csv" @change="processTransformationsFile" />
              <i class="el-icon-upload2"></i> Upload Mappings
            </a>
            <a @click="clearTransformations">
              <i class="el-icon-delete"></i> Reset mappings
            </a>
          </div>
          <div class="map-row">
            <template v-if="transformations.length > 0">
              <label class="map-header">Value to remap:</label>
              <div class="spacer" />
              <label class="map-header">Remapped value:</label>
            </template>
            <div v-else class="copy-warning">
              No transformations specified. Will be a copy of source column.
            </div>
          </div>
          <div v-for="item in transformations" :key="item.id" class="map-row">
            <bf-text-input
              v-model="item.from"
              placeholder="Source value"
            />
            <div class="arrow">
              &#8594;  <!--Right arrow-->
            </div>
            <bf-text-input
              v-model="item.to"
              placeholder="New value"
            />
            <i class="kapiche-icon-delete-thin" @click="removeTransformation(item.id)"></i>
          </div>
          <a class="add-row" @click="addRow">
            &#43; Add new value to remap
          </a>
        </div>
        <!-- Page 3 - Edit custom columns -->
        <div v-show="page === 3" class="current-columns field-container">
          <div v-for="item in customColumns" :key="item.id">
            <div class="item" @click="editCustomColumn(item)">
              {{ item.reference_field }} → {{ item.name }}
            </div>
            <i class="kapiche-icon-delete-thin item-delete" @click="removeCustomColumn(item.id)"></i>
          </div>
          <div v-if="customColumns.length === 0">
            No custom columns configured
          </div>
        </div>
        <div class="footer">
          <div class="errors">
            <div v-for="(e, idx) of errors" :key="`${idx}${e}`">
              {{ e }}
            </div>
          </div>
          <div class="buttons">
            <bf-button v-if="page !== 2" color="grey" size="large" @click="editCustomColumns">
              <span v-show="!isLoading">
                {{ page === 3 ? "Add Custom Column" : "Edit Custom Columns" }}
              </span>
            </bf-button>
            <bf-button v-if="page === 2" color="grey" size="large" @click="back">
              Back
            </bf-button>
            <bf-button v-if="page != 3" color="blue" size="large" @click="next">
              <span v-show="!isLoading">
                {{ buttonText }}
              </span>
              <span :class="{'active': isLoading}" class="ui inline inverted loader" />
            </bf-button>
          </div>
        </div>
        <p style="margin-top: 15px">
          <i class="star icon yellow"></i>This is an early access feature.
          <a href="javascript:window.Intercom('show');">
            Tell us what you think.
          </a>
        </p>
      </div>
    </bf-dialog>
  </bf-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BfModal, BfDialog, BfButton, BfTextInput } from 'src/components/Butterfly'
import { FIELD_TYPES_INDEX } from "components/project/create/SchemaUtils"
import Utils from 'src/utils/general'
import _ from 'lodash'
import Papa from 'papaparse'

// maximum number of rows in transformations file upload
const FILE_UPLOAD_LIMIT = 1000

export default defineComponent({
  components: { BfModal, BfDialog, BfButton, BfTextInput },
  props: {
    visible: { type: Boolean, default: false, required: false },
    fields: { type: Array, default: () => {return []}, required: false},
    customColumns: { type: Array, required: true},
  },
  data () {
    return {
      referenceField: this.fields[0] && this.fields[0].index,
      newName: '',
      newType: null,
      transformations: [{id: Utils.uuid()}],
      errors: [],
      page: 1,
      isLoading: false,
      columnId: null,
    }
  },
  computed: {
    buttonText () {
      if (this.page === undefined) {
        return ''
      } else if (this.page === 1) {
        return 'Next'
      } else if (this.page === 2) {
        return 'Done'
      } else {
        throw new Error(`Invalid page: ${this.page}. Valid values are 1 and 2.`)
      }
    },
    header () {
      if (this.page === 3) {
        return 'Edit Custom Columns'
      }
      let h = `${this.columnId ? 'Update' : 'Add'} Custom Column`
      if (this.page === 2) {
        h = `${h} - ${this.newName}`
      }
      return h
    }
  },
  methods:{
    resetState () {
      this.transformations = [{id: Utils.uuid()}]
      this.errors = []
      this.referenceField = this.fields[0].index
      this.newName = ''
      this.page = 1
      this.newType = null
      this.columnId = null
    },
    close () {
      this.resetState()
      this.$emit('close')
    },
    addRow () {
      this.transformations.push({id: Utils.uuid()})
      this.errors = []
    },
    clearTransformations () {
      this.transformations = [{id: Utils.uuid()}]
      this.errors = []
    },
    uploadTransformationsFile () {
      this.$refs.upload.click()
      this.errors = []
    },
    processTransformationsFile (e) {
      const file = e.target.files[0]
      const reader = new window.FileReader()
      reader.onload = (readEvent) => {
        //let transformations = processStopwords(readEvent.target.result)
        const results = Papa.parse(readEvent.target.result, {skipEmptyLines: true})
        // Check number of transformations
        if (results.data.length > FILE_UPLOAD_LIMIT) {
          this.errors.push(`Uploaded file has ${results.data.length} rows which is more than the ${FILE_UPLOAD_LIMIT} limit`)
        }
        else {
          // Check shape of transformations
          for (var i = 0; i < results.data.length; i++) {
            let d = results.data[i]
            if (d.length !== 2) {
              this.errors.push(`Row ${i+1} has ${d.length} values, but exactly 2 are required`)
              break
            }
          }
        }
        if (this.errors.length === 0) {
          // Add the transformations
          results.data.forEach(d => {
            const firstRow = this.transformations[0]
            // Update empty first row
            if (this.transformations.length === 1 && !firstRow.from && !firstRow.to) {
              this.transformations[0].from = d[0]
              this.transformations[0].to = d[1]
            }
            else {
              this.transformations.push({
                id: Utils.uuid(),
                from: d[0],
                to: d[1]
              })
            }
          })
        }
        e.target.value = null
        this.$analytics.track.project.uploadColumnTransformations(
          this.fields[this.referenceField].name,
          this.newName,
          FIELD_TYPES_INDEX[this.newType].label,
          results.data.length
        )
      }
      reader.readAsText(file)
    },
    // Edit the transformations for an existing custom column
    editCustomColumn (item) {
      this.referenceField = this.fields.find(f => f.name === item.reference_field).index
      this.newName = item.name
      this.type = item.type
      this.transformations = item.transformations
      this.page = 2
      this.columnId = item.id
    },
    // Enter or leave the page to view/edit existing custom columns
    editCustomColumns () {
      if (this.page === 3) {
        this.resetState()
        this.page = 1
      } else {
        this.page = 3
      }
    },
    back () {
      this.errors = []
      if (this.columnId) {
        this.page = 3
      } else {
        this.page = 1
      }
    },
    next () {
      this.errors = []
      this.isLoading = true
      try {
        if (this.page === 1) {
          this.validateFieldValues()
          if (this.errors.length > 0) {
            return
          }
          this.page++
        } else if (this.page === 2) {
          this.validateTransformations()
          if (this.errors.length > 0) {
            return
          }
          // Semantic UI won't let me use the field object in the dropdown. So
          // we have to use the index of the selected field everywhere and then
          // emit the full schema object at this point.
          const schemaField = this.fields.find(x => x.index === this.referenceField)
          this.$emit('add-or-update', {
            id: this.columnId || Utils.uuid(),
            transformations: this.transformations,
            referenceField: schemaField,
            newName: this.newName,
            type: this.newType,
          })
          this.close()
        } else {
          throw new Error(`Invalid page ${this.page}. Valid values are 1 and 2.`)
        }
      } finally {
        this.isLoading = false
      }
    },
    validateFieldValues () {
      const fieldNames = this.fields.map(f => f.name)
      const fieldIdxs = this.fields.map(f => f.index)
      if (!this.newName) {
        this.errors.push(`New column header must not be empty`)
      } else if (this.newName.toUpperCase() === 'NPS CATEGORY') {
        this.errors.push("'NPS Category' is a reserved column header & cannot be used in imported data")
      } else if (fieldNames.indexOf(this.newName) !== -1) {
        this.errors.push(`New column header: ${this.newName} already exists in the dataset`)
      }

      if (!this.newType) {
        this.errors.push(`New column type must not be empty.`)
      }

      if (this.referenceField == null) {
        this.errors.push(`Source column must not be empty`)
      } else if (fieldIdxs.indexOf(this.referenceField) === -1) {
        this.errors.push(`Source column could not be found in the original dataset`)
      }
    },
    validateTransformations () {
      for (const item of this.transformations) {
        const from = item.from?.trim()
        if (from === undefined) {
          this.errors.push(`Missing source value. Make sure there are no empty rows.`)
        }
      }

      const allFroms = this.transformations.map(item => item.from)
      const uniqueFroms = _.uniq(allFroms)
      if (allFroms.length !== uniqueFroms.length) {
        const delta = allFroms.length - uniqueFroms.length
        this.errors.push(`${delta} duplicated source values`)
      }
    },
    removeTransformation (id) {
      this.transformations = this.transformations.filter(x => x.id !== id)
    },
    removeCustomColumn (id) {
      this.$emit('remove', id)
    }
  }
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.content
  display: flex
  flex-direction: column
  align-items: center
  margin: 20px 40px 40px

  h2
    margin-top: 13px
    text-align: center

  label
    text-align: left
    font-weight: bold
    margin-bottom: 5px

  .field-container
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center
    width: 100%
    height: 100%

    .field-row
      font-size: 16px

    .spacer
      min-width: 30px

  .field-row
    display: flex
    flex-direction: column
    width: 80%
    margin-bottom: 20px

  .transformations
    display: flex
    flex-direction: column
    width: 100%
    height: 100%

    .map-header
      width: 49%
      display: inline-block

    .map-row
      display: flex
      justify-content: space-evenly
      align-items: baseline
      width: 100%
      height: 100%
      .arrow
        font-size: 30px
        color: $text-grey
      .kapiche-icon-delete-thin
        color: $text-grey
        font-size: 12px
        margin-left: 11px
        margin-right: 7px
        cursor: pointer

  .add-row
    cursor: pointer
    margin-bottom: 10px
    text-align: center

  .actions
    display: flex
    flex-direction: row
    justify-content: left
    margin: 10px 0
    .spacer
      width: 100%
    a
      cursor: pointer
      float: right
      margin-right: 20px
      text-decoration: none
      &:hover
        color: $blue-light
  #transformations-upload
    display: none

  .current-columns
    display: flex
    width: 80%
    .item
      color: $blue
      cursor: pointer
      display: inline
      font-size: 18px
      font-weight: bold
      line-height: 30px
      text-align: center
      &:hover
        color: $blue-light
    .item-delete
      cursor: pointer
      font-size: 12px
      margin-left: 15px
      &:hover
        color: $red

  .copy-warning
    font-weight: bold
    font-size: 14px
    margin: 10px

  .footer
    display: flex
    flex-direction: column
    align-items: center
    width: 80%
    .errors
      border-color: $red
      color: $red
      margin-bottom: 10px
    .buttons
      display: flex
      flex-direction: row

  ::v-deep .el-input__prefix
    left: 15px
    display: flex
    align-items: center
  .field-type-selection ::v-deep input.el-input__inner
    padding-left: 35px
  .field-type-selection a.ui.label
    margin-right: 5px
</style>
