<template>
  <div v-if="showLoader" class="loader">
    <bf-spinner text-pos="top">
      Loading analysis...
    </bf-spinner>
  </div>
  <div v-else-if="analysisError" class="error-box">
    <template v-if="analysisError.status === 404">
      <h1>Analysis could not be found</h1>
      <p>This Analysis either does not exist, or you do not have permission to access it.</p>
    </template>
    <template v-else>
      <h2>Unexpected Error</h2>
      <p>An unexpected error occurred when accessing this analysis.</p>
    </template>
    <div class="text-box">
      <p>Try the following:</p>
      <ul>
        <li>If you arrived at this page by typing the in URL, double-check that the URL is correct</li>
        <li>Check with your site or project admin that you have been given permission to access this analysis</li>
        <li>Check your internet connection &amp; refresh the page</li>
      </ul>
      <p>
        Please contact support if you cannot resolve this.
      </p>
    </div>
  </div>
  <div v-else class="analysis-container">
    <router-view
      :current-site="currentSite"
      :current-model="currentModel"
      :current-project="currentProject"
      :current-analysis="currentAnalysis"
      :current-user="currentUser"
      :feature-flags="featureFlags"
      :has-nps="hasNPS"
      :has-sentiment="hasSentiment"
      :has-date="hasDate"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import { BfSpinner } from 'components/Butterfly'
  import { LOAD_ANALYSIS, LOAD_NPS, CLEAR_ANALYSIS_TIMELINE } from 'src/store/types'

  export default defineComponent({
    components: {BfSpinner},
    props: {
      projectId: {type: Number, required: true},
      analysisId: {type: Number, required: true}
    },
    data () {
      return {
        analysisError: null,
        showLoader: true,
        analysisLoadStartTime: null as Date | null
      }
    },
    computed: {
      ...mapGetters([
        'currentAnalysis',
        'hasNPS',
        'hasSentiment',
        'hasDate',
        'currentModel',
        'currentProject',
        'currentSite',
        'currentUser',
        'featureFlags',
      ])
    },
    mounted () {
      this.fetchAnalysis()
    },
    methods: {
      ...mapActions({LOAD_ANALYSIS, LOAD_NPS}),
      async fetchAnalysis () {
        // When the route is changed, e.g switching site, the URL params can lag
        // behind the actual location (when called from mounted) and error due to
        // the Site-Name header not matching the project/analysis ID.
        if (window.location.pathname !== this.$route.path) {
          return
        }

        // First, always clear timeline data
        this.$store.commit(CLEAR_ANALYSIS_TIMELINE)
        try {
          this.analysisLoadStartTime = new Date()

          // Load analysis
          await this.LOAD_ANALYSIS({
            projectId: this.projectId,
            analysisId: this.analysisId,
            rethrowErrors: true
          })

          if (this.hasNPS) {
            await this.LOAD_NPS({
              projectId: this.projectId,
              analysisId: this.analysisId,
              rethrowErrors: true
            })
          }

          if (this.currentAnalysis && this.currentModel) {
            let analysis = this.currentAnalysis
            let model = this.currentModel
            this.$analytics.track.analysis.home({
              analysisId: analysis.id,
              createdAt: analysis.created,
              modifiedAt: analysis.modified,
              numConcepts: model.hierarchy_concepts.length,
              numTerms: model.stats.model_term_count,
              coveredFrames: model.stats.covered_frames,
              nonEmptyFrames: model.stats.non_empty_frames,
              coverage: model.stats.covered_frames / model.stats.non_empty_frames
            })
          }
        } catch (error) {
          this.analysisError = error
        } finally {
          this.delayedLoaderHide()
        }
      },
      delayedLoaderHide () {
        // ensure that loader is shown for at least one second
        const diff = (new Date()).valueOf() - (this.analysisLoadStartTime as Date).valueOf()
        const delay = diff > 1000 ? 0 : 1000 - diff
        setTimeout(() => {
          this.showLoader = false
        }, delay)
      },
    },
    metaInfo () {
      return {
        title: this.currentAnalysis?.name ? `${this.currentAnalysis.name} Analysis - Kapiche` : null
      }
    },
    watch: {
      '$route': function () {
        this.$el.scrollTop = 0
      },
      analysisId: function (newId: number, oldId: number) {
        if (newId !== oldId) {
          this.showLoader = true
          this.fetchAnalysis()
        }
      }
    },
  })
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  div.analysis-container
    padding: 30px
    background-color: $grey-light-background
    display: flex
    flex-direction: column
    flex: 1
    height: 100%

  div.loader
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 50px
    font-size: 16px

  div.error-box
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 50px
    font-family: $standard-font
    p
      font-size: 16px
    .text-box
      font-size: 16px


</style>


<style lang="sass">

  .header.segment
    padding: 0 !important
    font-size: 1.25rem !important
    .left.floated
      float: left
    .right.floated
      float: right
    .title
      padding: 1em
    .icons
      border-left: 1px solid #e6e6e6
      padding: 1em

  canvas.highlight
    position: absolute
    display: block
    top: 0
    left: 0
    opacity: 0.5
    z-index: 8
    -webkit-transition: opacity 250ms ease
    transition: opacity 250ms ease
    -webkit-animation: canvasFade 250ms ease
    animation: canvasFade 250ms ease
  @-webkit-keyframes canvasFade
    0%
      opacity: 0
    100%
      opacity: 0.5
  @keyframes canvasFade
    0%
      opacity: 0
    100%
      opacity: 0.5
</style>
