<template>
  <div ref="segmentchart-container" class="svg-container segmentation">
    <interaction-menu
      v-for="(row,r_index) in rows"
      ref="menus"
      :key="`im_${r_index}_${row.label}`"
      :get-parent-element="rowEls[r_index]"
      :has-content="hasMenu"
      @self-close="$emit('menu-closed')"
      @closed="menuClosed"
    >
      <slot
        name="interaction-menu"
        :index="r_index"
        :label="row.label"
        :row="row"
      />
    </interaction-menu>
    <!-- ============== LEGEND ============== -->
    <div v-if="showLegend" class="legend-container">
      <div
        v-for="(legendItem, index) in legend"
        :key="`l_${index}_${legendItem.label}`"
        class="legend"
        @mouseenter="(event) => legendHover(event, index)"
        @mouseleave="legendHover"
        @mousemove="updateCoordinates"
      >
        <div
          class="legend-icon-one"
          :style="{ backgroundColor: legendItem.color }"
        />
        {{ legendItem.label }}
      </div>
    </div>
    <svg class="chart" :width="`${width}px`" :height="chartHeight">
      <!-- ============== column headers ============== -->
      <g class="header" transform="translate(0)">
        <g
          v-for="(heading,index) in headings"
          :key="heading.label + '_' + index"
          :class="{'clickable': heading.sortable}"
          :transform="`translate(${index===0?0:(index+1)*colWidth})`"
          @click="heading.sortable && clickedOnHeading(index)"
        >

          <text
            v-truncate.svg
            :y="headerYPosition"
            :x="0"
            :class="{sorted: sortedColumnIndex === index, first: index===0, end: index>0}"
            :transform="`translate(${index>0 && heading.sortable?-11:0})`"
            :fill="headings[index].color || '#95A6AC'"
          >
            {{ truncate(heading.label, 24) }}
          </text>
          <title>{{ heading.label }}</title>
          <g v-if="heading.sortable" :transform="`translate(${index > 0 ? -7 : (heading.label.length * 7.5 + 8)})`">
            <up-down :x="0" :y="headerYPosition-10.5" :up="heading.sortAsc === true" :down="heading.sortAsc === false" />
          </g>
        </g>
      </g>

      <!-- ============== ROWS OF DATA ============== -->
      <g :transform="`translate(0,${rowsYPosition})`">
        <g
          v-for="(row,r_index) in rows"
          :key="`g_${r_index}_${row.label}`"
          :transform="`translate(0,+${r_index * heightForRow(row)})`"
          class="row"
        >
          <!-- hover area -->
          <rect
            :ref="`row_${r_index}`"
            :class="{ 'hover': hoverRowPointer }"
            width="100%"
            :y="-22"
            :height="heightForRow(row)"
            fill="white"
            @mouseenter="(event)=>toolTip(event, r_index)"
            @mouseleave="toolTip"
            @mousemove="updateCoordinates"
            @click="clickedOnRow(r_index)"
          > </rect>

          <g
            v-for="(bar, i) in row.bars || []"
            :key="`g_${r_index}_${row.label}_${i}`"
          >
            <!-- bar: grey background -->
            <rect
              :y="i * (lineHeight + 3)"
              class="background"
              width="100%"
              :height="lineHeight"
              pointer-events="none"
            ></rect>
            <!--  bar: value blue  -->
            <rect
              :class="`bar bar-${i}`"
              :x="xScale(bar.percent >= 0 ? 0 : bar.percent)"
              :y="i * (lineHeight + 3)"
              :width="rowWidth(bar.percent)"
              :height="lineHeight"
              pointer-events="none"
              :fill="bar.color || '#11acdf'"
            ></rect>
          </g>

          <!-- vertical indicator if being shown -->
          <rect v-if="showIndicatorBar" pointer-events="none" class="indicator" :x="xScale(row.indicator)-(row.indicator === max?2:0)" :y="-4" :width="markerWidth" :height="markerHeight"></rect>

          <!-- COLUMNS -->
          <g v-if="row.label">
            <text
              ref="labelRef"
              pointer-events="none"
              class="first"
              transform="translate(0, -6)"
              :class="{sorted: sortedColumnIndex === 0, hover: item===r_index}"
            >
              <tspan v-truncate.svg="seriesTags[row.id] ? 30 : 60">
                {{ seriesLabels[row.label] || row.label }}
              </tspan>
              &nbsp;
              <tspan v-if="seriesTags[row.id]" fill="#AAAAAA">
                [<tspan v-truncate.svg="30">{{ seriesTags[row.id] }}</tspan>]
              </tspan>
            </text>
          </g>
          <foreignObject
            v-else
            pointer-events="none"
            x="0" y="-28" width="100%" height="24"
            class="first html-label"
            :class="{sorted: sortedColumnIndex === 0, hover: item===r_index}"
          >
            <slot name="html-label" :row="row"></slot>
          </foreignObject>
          <text
            v-for="(col,c_index) in row.columns"
            :key="`g_${c_index}_${r_index}_${col.label}`"
            :transform="`translate(${(c_index+2)*colWidth}, -6)`"
            class="end"
            :class="{
              sorted: c_index+1 === sortedColumnIndex,
              hover: item===r_index,
            }"
            pointer-events="none"
            :fill="getHeadingColor(c_index + 1)"
          >
            {{ col.label }}
          </text>
        </g>
      </g>
    </svg>

    <!-- ============== TOOLTIPS ============== -->
    <floating-panel :visible="showLegendToolTip" :y="hoverY" :x="hoverX" :bound="$refs['segmentchart-container']">
      <slot name="legend-tool-tip"></slot>
    </floating-panel>
    <floating-panel :visible="showRowToolTip" :y="hoverY" :x="hoverX" :bound="$refs['segmentchart-container']">
      <slot name="row-tool-tip"></slot>
    </floating-panel>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import UpDown from 'components/widgets/UpDown/UpDown.vue'
import { ScaleLinear, scaleLinear } from 'd3'
import FormatUtils from 'src/utils/formatters'
import { SegmentationLabel, TableChartHeading, TableChartRowType } from 'types/components/Charts.types'
import InteractionMenu from 'src/components/widgets/InteractionMenu.vue'


export default defineComponent({
  components: { FloatingPanel, UpDown, InteractionMenu },
  props: {
    /** dataset to show: array of objects */
    rows: { type: Array as PropType<TableChartRowType[]>, required: true, default: ()=>[] },
    width: { type: Number, required: false, default:0 },
    max: { type: Number, required: false, default:0 },
    min: { type: Number, required: false, default:0 },
    /** Array of strings for column headings*/
    headings: { type: Array as PropType<TableChartHeading[]>, required: true, default: ()=>[] },
    /** Array of upto two strings for legend labels */
    legend: { type: Array as PropType<SegmentationLabel[]>, required: true, default: ()=>[] },
    /** what to show if an item has an empty label */
    emptySegmentText: { type: String, required: false, default: '(No Value)'},
    /** should the vertical indicator bar be shown */
    showIndicatorBar: { type: Boolean, required: false, default: false },
    hoverRowPointer: { type: Boolean, required: false, default: true },
    lineHeight: { type: Number, required: false, default: 6 },
    seriesLabels: { type: Object as PropType<Record<string, string>>, required: false, default: () => ({}) },
    seriesTags: { type: Object as PropType<Record<number, string>>, required: false, default: () => ({}) },
  },
  data () {
    return {
      showRowToolTip: false,
      showLegendToolTip: false,
      rowHeight: 48,
      markerHeight: 14,
      markerWidth: 2,
      headerTextHeight: 16,
      legendHeight: 40,
      item: null as null | number,
      hoverX: 0,
      hoverY: 0,
      interactionX: 0,
      interactionY: 0,
    }
  },
  computed: {
    hasMenu () {
      return !!this.$scopedSlots?.['interaction-menu']
    },
    colWidth (): number {
      return this.width / (this.headings?.length ?? 1)
    },
    chartHeight (): number {
      return this.rows.reduce((sum, row) => sum + this.heightForRow(row), 0) + this.headerHeight + this.legendHeight
    },
    xScale (): ScaleLinear<number, number> {
      return scaleLinear()
        .domain([this.min, this.max])
        .range([0, this.width])
    },
    showLegend (): boolean {
      return this.legend.length > 0
    },
    headerHeight (): number {
        return this.headerTextHeight
    },
    headerYPosition (): number {
      return this.headerHeight+(this.legendHeight*(this.legend.length === 0 ? 0 : 1))
    },
    rowsYPosition (): number {
      return this.headerYPosition + this.headerTextHeight*2.5
    },
    sortedColumnIndex () : number {
      return this.headings.findIndex((heading:TableChartHeading)=>{
        return heading.sortable && (heading.sortAsc === true || heading.sortAsc === false)
      })
    },
    rowEls (): (() => SVGElement)[] {
      return this.rows.map((_, index) => () => this.$refs[`row_${index}`]?.[0])
    },
  },
  watch: {
    rows: {
      handler () {
        this.$nextTick(() => {
          const labels = this.$refs.labelRef ?? []
          for (const label of labels) {
            const tag = label.parentNode.querySelector('.label-tag')
            if (tag) {
              const { width } = label.getBBox()
              tag.setAttribute('x', `${width + 5}`)
            }
          }
        })
      },
      deep: true,
      immediate: true,
    }
  },
  mounted () {

  },
  methods: {
    menuClosed () {
      if (!this.hasOpenMenu()) this.$emit('menu-closed')
    },
    hasOpenMenu () {
      return this.$refs.menus.some((menu: any) => menu.visible)
    },
    truncate: FormatUtils.truncate,
    noValue: FormatUtils.noValue,
    rowWidth (x: number): string {
      const start = this.xScale(x)
      const zero = this.xScale(0)
      return `${Math.abs(start - zero).toFixed(2)}px`
    },
    updateCoordinates (event: MouseEvent) {
        this.hoverX = event.clientX
        this.hoverY = event.clientY
    },
    /** when you click on a heading */
    clickedOnHeading (index: number) {
      this.$emit('clicked-heading', index)
    },
    /** when hovered on legend */
    legendHover (event: MouseEvent, index=null) {
      if (event && event.type)
        switch (event.type) {
          case 'mouseleave':
            this.showLegendToolTip = false
            break
          case 'mouseenter':
            this.showLegendToolTip = true
            this.updateCoordinates(event)
            this.$emit('hover-legend', index)
            break
        }

    },
    /** when you hover on a row */
    toolTip (event: MouseEvent, index: number | null =null) {
      if (event && event.type)
        switch (event.type) {
          case 'mouseleave':
            this.showRowToolTip = false
            this.item = null
            break
          case 'mouseenter':
            this.showRowToolTip = true
            this.updateCoordinates(event)
            this.item = index
            this.$emit('hover-row', index)
            break
        }
    },
    /** when you click on a row */
    clickedOnRow (index: number) {
      this.showLegendToolTip = false
      this.showRowToolTip = false
      this.$emit('row-clicked', index)
    },
    heightForRow (row: TableChartRowType) {
      return this.rowHeight + (((row.bars ? row.bars.length : 1) - 1) * (this.lineHeight + 3))
    },
    getHeadingColor (index) {
      return this.headings[index]?.color ?? '#383838'
    },
  }
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

div.svg-container
  width: 100%

svg
  top: 0
  left: 0

.chart
  background-color: #fff
  cursor: default

  text.first
    text-anchor: start

  text.end
    text-anchor: end

  g.row
    rect.background
      fill: #F1F1F1

    rect.indicator
      fill: #8064AA

    rect.hover
      cursor: pointer
      fill-opacity: 0

    text.hover
      fill: #068CCC

    text.sorted
      font-weight: bold

  g.header .clickable
    cursor: pointer
    user-select: none

  .header text
    font-size: 12px
    text-transform: uppercase
    font-weight: bold


  .html-label
    padding-top: 2px
.legend-container
  display: flex
  justify-content: center
  margin-top: 10px
  > *:not(:last-child)
    margin-right: 80px

.legend
  cursor: default
  display: flex
  align-items: center
  font-style: italic
  font-weight: bold
  font-size: 13px
  > div
    width: 10px
    height: 10px
    background: #333
    margin-right: 5px

.label-tag
  overflow: visible
</style>
