<template>
  <div class="ui segments terms">
    <div class="ui clearing segment header">
      <span class="left floated title">Context Chart</span>
      <span v-if="headerTools" class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button
          v-if="concepts.length"
          :name="`${currentAnalysis.name}-Context Chart`"
          :get-el="getContextChartEl"
          :get-csv-data="getCsvData"
          short-name="Context Chart"
        ></download-export-button>
      </span>
      <dropdown
        v-if="showInfluenceMode"
        class="display-dropdown"
        :value="sortMode"
        @input="v => sortMode = v"
      >
        <template #trigger>
          <div style="cursor: pointer">
            Display: <span>{{ sortMode }}</span>
            <i class="icon dropdown"></i>
          </div>
        </template>
        <dropdown-item
          v-for="option in ['frequency', 'influence']"
          :key="option"
          :value="option"
        >
          {{ option }}
        </dropdown-item>
      </dropdown>
    </div>
    <div class="ui segment body" :style="{'height': height + 'px'}">
      <div class="chart-container">
        <div v-show="!isInsufficientData">
          <canvas ref="contextChart"></canvas>
        </div>
        <div v-show="!loading && isInsufficientData" class="no-data">
          <div>Not enough data for chart</div>
        </div>
      </div>
    </div>

    <!-- Loading dimmer -->
    <div id="network-dimmer" class="ui dimmer inverted" :class="{'active': !model}">
      <div class="ui loader"></div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  import DownloadExportButton from './DownloadExportButton.vue'
  import HelpIcon from './HelpIcon.vue'
  import ChartUtils from 'src/utils/chart'
  import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
  import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'

  interface Concept {
    name: string,
    frequency: number,
    influence_strength?: number,
  }

  /**
   * Simple chart to render concepts from the context network.
   */
  export default defineComponent({
    components: {
      DownloadExportButton, DropdownItem, Dropdown,  HelpIcon,
    },
    props: {
      model: { type: Object, default: null, required: false },  // network model for a query
      loading: { type: Boolean, default: false},
      headerTools: { type: Boolean, default: true },
      height: { type: Number, default: 450 },
      limit: { type: Number, default: 25 },  // max number of terms to show
    },
    data () {
      return {
        contextConceptsChart: null as unknown,
        sortMode: 'frequency' as 'frequency' | 'influence',
      }
    },
    computed: {
      ...mapGetters(['currentAnalysis', 'currentModel', 'currentUser']),
      concepts (): Concept[] {
        return this?.model?.concepts || []
      },
      showInfluenceMode (): boolean {
        const hasInfluence = !!this.concepts.find((concept) =>
          !!concept.influence_strength
        )

        return hasInfluence
      },
      conceptValues (): number[] {
        return this.sortedConcepts.map((c) => this.sortMode === 'frequency'
          ? this.normaliseFreq(c.frequency)
          : c.influence_strength ?? 0
        )
      },
      help (): string {
        return `<p>A graph of the strongest context concepts/terms for this query.</p>`
          + `<p>If there are concepts or terms in your query, the items in this chart will be selected based on how much they are influenced by the concepts/terms in your query. Influence is a measure of the importance of a relationship between two concepts/terms (see the glossary in our Knowledge Base for more details).</p>`
          + `<p>If there is only structured data in your query, the items in this chart will be selected based on frequency.</p>`
      },
      isInsufficientData (): boolean {
        return (this?.model?.concepts?.length || 0) === 0
      },
      sortedConcepts (): Concept[] {
        return this.concepts.slice().sort((a, b) => this.sortMode === 'frequency'
          ? b.frequency - a.frequency
          : (b.influence_strength ?? 0) - (a.influence_strength ?? 0)
        )
      },
      xAxisLabel (): string {
        return this.sortMode === 'frequency' ? '% of Verbatims' : 'Influence'
      }
    },
    watch: {
      model () {
        this.draw()
      },
      sortMode () {
        this.draw()
      },
    },
    mounted () {
      this.draw()
    },
    methods: {
      normaliseFreq (freq: number): number {
        return freq / this.model.n_frames * 100
      },
      draw () {
        if (this.sortedConcepts.length <= 0) {
          return
        }

        if (this.contextConceptsChart) {
          this.contextConceptsChart.destroy()
          this.contextConceptsChart = null
        }

        // Assemble (truncated) chart data
        const conceptLabels = []
        const conceptColours = []
        const conceptValues = this.conceptValues.slice(0, this.limit)

        for (const concept of this.sortedConcepts.slice(0, this.limit)) {
          conceptLabels.push(concept.name)
          conceptColours.push(this.currentModel.conceptColours[concept.name] || '#cbcbcb')
        }

        const chartData = {
          labels: conceptLabels,
          datasets: [{
            label: this.xAxisLabel,
            borderColor: 'black',
            borderWidth: Array.apply(null, Array(conceptValues.length)).map(Number.prototype.valueOf, 0),
            data: conceptValues,
            maxBarThickness: 80,
            backgroundColor: conceptColours
          }]
        }

        // Render the chart
        const chartEl = this.$refs.contextChart
        const chartCtx = chartEl.getContext('2d')
        this.contextConceptsChart = new Chart(chartCtx, {
          type: 'horizontalBar',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            onClick: (e, el) => {
              if (!el || el.length === 0) {
                return
              }
              const index = el[0]._index
              this.$emit('concept-selected', this.sortedConcepts[index].name)
            },
            hover: {
              // Pointer cursor
              onHover: function (e) {
                 const point = this.getElementAtEvent(e)
                 e.target.style.cursor = point.length ? 'pointer' : 'default'
              }
            },
            legend: {
              display: false
            },
            scales: {
              yAxes: [{
                gridLines: {
                  display: false,
                  zeroLineWidth: 0,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.xAxisLabel,
                },
                ticks: {
                  beginAtZero: true
                },
                gridLines: {
                  display: true,
                  zeroLineWidth: 0,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR
                }
              }]
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem: unknown) => {
                  return this.sortMode === 'frequency'
                    ? ` Frequency: ${ChartUtils.percentLabel(tooltipItem)}`
                    : ` Influence: ${tooltipItem.xLabel.toFixed(2)}`
                },
                footer: (tooltipItems: unknown) => {
                  const concept = this.sortedConcepts[tooltipItems[0].index]
                  if (this.sortMode === 'frequency' && concept.influence_strength !== undefined) {
                    return `(Influence: ${concept.influence_strength.toFixed(2)})`
                  }
                  if (this.sortMode === 'influence') {
                    return `(Frequency: ${this.normaliseFreq(concept.frequency).toFixed(2)}%)`
                  }
                }
              }
            },
          }
        })
      },
      getContextChartEl () {
        return this.$refs.contextChart
      },
      getCsvData () {
        return this.sortedConcepts.map((d) => {
          const freq = this.normaliseFreq(d.frequency)
          const v: Record<string, string | number> = {
            'name': d.name,
            '% of Verbatims': ChartUtils.formatPercent(freq),
          }
          if (d.influence_strength !== undefined) {
            v['Influence'] = d.influence_strength
          }
          return v
        })
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import "assets/kapiche.sass"

  div.chart-container
    height: 100%
    div
      height: 100%

  div.no-data
    display: table
    width: 100%
    height: 100%
    > div
      display: table-cell
      text-align: center
      vertical-align: middle
      font-size: 24px
      color: #95a6ac

  .header
   .display-dropdown
    float: right
    padding: 1em
    font-size: 1.1rem
    .dropdown-trigger
      span, i
        text-transform: capitalize
        color: $blue
    .dropdown-item
      text-transform: capitalize
</style>
