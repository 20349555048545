<template>
  <div v-if="currentModel && currentModel.topics_list.length > 0" class="ui stackable grid project-summary">
    <div class="ui dimmer">
      <div class="ui text loader">
        Loading Data...
      </div>
    </div>

    <div class="one column row info">
      <div class="column">
        <h1 class="ui header center aligned">
          {{ currentAnalysis.name }}
        </h1>
        <div class="description">
          <strong>Created:</strong> {{ analysisDate }}<br>
          <template v-if="analysisDates">
            <strong>Data from:</strong> {{ analysisDates }}<br>
          </template>
          <span class="text-fields">
            <strong>Text Fields:</strong>&nbsp;
            <el-popover
              v-if="firstTextField.length > 50"
              effect="dark"
            >
              <template #default>
                <div>
                  <em>{{ firstTextField }}</em>
                </div>
              </template>
              <template #reference>
                <span>
                  {{ truncate(firstTextField, 50) }}
                </span>
              </template>
            </el-popover>
            <span v-else> {{ firstTextField }}</span>
            <span v-if="numTextFields > 1">
              and
              <el-popover effect="dark">
                <template #default>
                  <div>
                    <p v-for="field in textFields" :key="field">
                      {{ field }}
                    </p>
                  </div>
                </template>
                <template #reference>
                  <a> {{ numTextFields - 1 }} more</a>
                </template>
              </el-popover>
            </span>
          </span>
          <template v-if="includedSegments.length">
            <p>
              Including
              <el-popover effect="dark">
                <template #default>
                  <div>
                    <div class="seg-subtitle">
                      This analysis includes records with:
                    </div>
                    <div v-for="segment in includedSegments.slice(0, maxSegmentDisplay)" :key="segment.join(': ')">
                      {{ segment[0] }}: <strong>{{ segment[1] }}</strong>
                    </div>
                    <div v-if="includedSegments.length > maxSegmentDisplay" class="seg-subtitle">
                      and {{ includedSegments.length - maxSegmentDisplay }} more...
                    </div>
                  </div>
                </template>
                <template #reference>
                  <a> {{ includedSegments.length }} segments </a>
                </template>
              </el-popover>
            </p>
          </template>
          <template v-if="excludedSegments.length">
            <p>
              Excluding
              <el-popover effect="dark">
                <template #default>
                  <div>
                    <div class="seg-subtitle">
                      This analysis does not include records with:
                    </div>
                    <div v-for="segment in excludedSegments.slice(0, maxSegmentDisplay)" :key="segment.join(': ')">
                      {{ segment[0] }}: <strong>{{ segment[1] }}</strong>
                    </div>
                    <div v-if="excludedSegments.length > maxSegmentDisplay" class="seg-subtitle">
                      and {{ excludedSegments.length - maxSegmentDisplay }} more...
                    </div>
                  </div>
                </template>
                <template #reference>
                  <a> {{ excludedSegments.length }} segments </a>
                </template>
              </el-popover>
            </p>
          </template>
        </div>
      </div>
    </div>

    <!-- Sentiment Bar -->
    <div v-if="hasSentiment" class="one column row sentiment-bar">
      <div class="column">
        <sentiment-summary-segment :title="'Overall Sentiment'" :help="sentimentHelp" @sentiment-clicked="querySentiment"></sentiment-summary-segment>
      </div>
    </div>

    <!-- NPS Bar -->
    <div class="one column row nps-widget">
      <div class="column">
        <nps-summary :title="'Overall Net Promoter Score (NPS)'" @nps-clicked="queryNps"></nps-summary>
      </div>
    </div>

    <!-- Charts -->
    <div class="two column row charts">
      <!-- Concept Chart -->
      <div class="column">
        <concept-summary></concept-summary>
      </div>
      <!-- Field Breakdown Chart -->
      <div class="column">
        <field-breakdown></field-breakdown>
      </div>
    </div>

    <!-- Data Statistics -->
    <div class="one column row data-statistics">
      <div v-if="currentModel.stats" class="column">
        <div class="ui segments">
          <div class="ui clearing segment header">
            <span class="left floated title">Data &amp; Model Statistics</span>
            <!--<help-icon :content="dataHelp"></help-icon>-->
            <span class="right floated title">(hover each statistic for info)</span>
          </div>
          <div class="ui body segment">
            <div class="ui six column grid">
              <div class="row">
                <div class="column">
                  <div class="value blue">
                    {{ number(currentModel.stats.n_documents) }}
                  </div>
                </div>
                <div class="column">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>The number of records in this analysis.</p>
                        <p>A record can be thought of as a row in a spreadsheet or an entire survey response.</p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Records
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div class="column">
                  <div class="value blue">
                    {{ number(currentModel.stats.non_empty_frames) }}
                  </div>
                </div>
                <div class="column">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>The number of non-empty text fields are present in this analysis.</p>
                        <p>Keep in mind that a record can have more than 1 text field.</p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Verbatims
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div class="column">
                  <div class="value blue">
                    {{ number(currentModel.stats.n_topics) }}
                  </div>
                </div>
                <div class="column ">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>The number of concepts that have been identified in this model.</p>
                        <p>A concept is a frequent and influential term that Kapiche has identified in your data.</p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Concepts
                      </div>
                    </template>
                  </el-popover>
                </div>
              </div>
              <div class="row">
                <div class="column">
                  <div class="value blue">
                    {{ roundDecimals(currentModel.stats.covered_frames/(currentModel.stats.non_empty_frames) * 100) }}%
                  </div>
                </div>
                <div class="column">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>The percentage of Verbatims that contain at least 1 identified concept.</p>
                        <p>
                          If this number is below 75%, you might consider updating your model or investigating the
                          quality of your text exerpts. For example, in a survey context, you might have a lot of single
                          word answers like <em>n/a</em>, or <em>nothing</em>.
                        </p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Data Coverage
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div class="column">
                  <div class="value blue">
                    {{ roundDecimals(currentModel.stats.terms_per_frame) }}
                  </div>
                </div>
                <div class="column">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>The average number of terms each Verbatim contains.</p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Terms / Verbatim
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div class="column">
                  <div class="value">
                    <span class="blue">{{ currentModel.stats.variables }}</span> of {{ currentModel.stats.structured_fields }}
                  </div>
                </div>
                <div class="column ">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>The number of non-text fields (or columns) that have been used from your data.</p>
                        <p>Fields are where the list of usable segments are identified from.</p>
                        <p>A non-text field will be used in an analysis if it has less than 25 unique values.</p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Fields Used
                      </div>
                    </template>
                  </el-popover>
                </div>
              </div>
              <div class="row">
                <div class="column">
                  <div class="value blue">
                    {{ number(currentModel.stats.raw_term_count) }}
                  </div>
                </div>
                <div class="column">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>
                          The number of unique terms present in the data when this analysis was created.
                          Term variants (Dog, dog, dOg for example) are counted as 1).
                        </p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Unique Terms
                      </div>
                    </template>
                  </el-popover>
                </div>
                <div class="column">
                  <div class="value blue">
                    {{ number(currentModel.stats.n_phrases) }}
                  </div>
                </div>
                <div class="column ">
                  <el-popover effect="dark">
                    <template #default>
                      <div>
                        <p>
                          The number of phrases that have been identified from the text field{{ numTextFields.length> 1 ? 's':'' }} in this analysis.
                        </p>
                      </div>
                    </template>
                    <template #reference>
                      <div class="label">
                        Phrases
                      </div>
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Storyboard Entrance -->
    <div class="one column row">
      <div class="column">
        <div class="ui segment" style="text-align: center">
          <h2 class="ui header center aligned">
            {{ currentModel.stats.n_topics }} Concepts identified and semantically mapped
          </h2>
          <router-link :to="{name: 'storyboard'}" class="ui huge primary button">
            View Storyboard
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="one column row no-structured" style="margin: 1rem;">
    <div v-if="currentModel.stats.total_frame_count" class="ui warning message">
      <div class="header">
        No concepts identified.
      </div>
      <p>The file(s) analysed may not have adequate text data ({{ currentModel.stats.total_frame_count }} frames processed).</p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import dayjs from 'dayjs'

  import NpsSummary from './widgets/NpsSummary.vue'
  import FieldBreakdown from './widgets/FieldBreakdown.vue'
  import ConceptSummary from './widgets/ConceptSummary.vue'
  import SentimentSummarySegment from './widgets/SentimentSummarySegment.vue'
  import DataUtils from 'src/utils/data'
  import Utils from 'src/utils/general'
  import FormatUtils from 'src/utils/formatters'

  export default defineComponent({
    components: { SentimentSummarySegment, NpsSummary, FieldBreakdown, ConceptSummary },
    data () {
      let topic = this.$store.getters.currentModel.topics_list[0]
      let base = {
        sentimentHelp: '<p>A summary of the sentiment scores for the data in this analysis.</p>',
        dataHelp: `
        <p>High level information about the quality of your data.</p>
        <p>An "Unusable Verbatim" is a verbatim that is either blank, or only has low frequency terms or stopwords.</p>`,
        modelHelp: '<p>High level information about the model built for this analysis.</p>',
        maxSegmentDisplay: 46,
      }
      if (topic) {
        return Object.assign(base, {
          selectedTopic: topic
        })
      }
      // No topics
      return base
    },
    computed: {
      ...mapGetters(['currentModel', 'currentAnalysis', 'hasSentiment']),
      analysisDates () {
        return DataUtils.parseAnalysisDates(this.currentAnalysis)
      },
      analysisDate () {
        return dayjs(this.currentAnalysis.created).calendar()
      },
      firstTextField () {
        return this?.currentModel?.stats?.text_fields?.[0] || ""
      },
      numTextFields () {
        return this?.currentModel?.stats?.text_fields?.length || 0
      },
      textFields () {
        return this?.currentModel?.stats?.text_fields?.slice(1) || []
      },
      includedSegments () {
        return this?.currentAnalysis.included_segments || []
      },
      excludedSegments () {
        return this?.currentAnalysis.excluded_segments || []
      }
    },
    metaInfo () {
      return {
        title: this.currentAnalysis ? `${this.currentAnalysis.name} Summary - Kapiche` : null
      }
    },
    methods: {
      number: FormatUtils.number,
      truncate: FormatUtils.truncate,
      // Go to query results for `npsType` when nps widget is clicked.
      queryNps (npsType) {
        let queryLink = Utils.generateQueryLink([{
          type: 'segment',
          values: [npsType],
          field: 'NPS Category',
          operator: 'is'
        }])
        this.$router.push(queryLink)
      },
      // Go to query results for `sentimentType` when sentiment widget is clicked.
      querySentiment (sentimentType) {
        let queryLink = Utils.generateQueryLink([{
          type: 'attribute',
          values: [sentimentType],
          field: 'sentiment',
          operator: 'is'
        }])
        this.$router.push(queryLink)
      },
      roundDecimals (val) {
        return Math.round(val * 100) / 100
      }
    }
  })
</script>

<style lang="sass" scoped>

  div.info
    .description
      text-align: center
      font-size: 1.2rem
      line-height: 1.5
      .seg-subtitle
        color: rgba(149, 166, 172, 0.9)
        font-weight: bold
    .text-fields
      white-space: nowrap

  div.data-statistics
    div.header
      > span.right.floated
        font-size: 1rem
        color: rgba(149, 166, 172, 0.9)
    div.value
      text-align: right
      font-weight: bold
      font-size: 1.5rem
      > span.orange
        color: #F2711C
      > span.blue
        color: #068CCC
      &.blue
        color: #068CCC

    div.label
      font-size: 1.25rem

  #sentimentWidget
    height: 100px
    padding: 0
    *
      padding: 0

</style>
