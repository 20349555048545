export function formatDrfErrors (errors: undefined | Record<string, string[] | string>) {
  if (errors === undefined) return ''
  return Object.entries(errors)
    .map(([field, errContent]) => {
      if (!Array.isArray(errContent)) {
        errContent = [errContent]
      }
      return `${field.replaceAll('_', ' ')}: ${errContent.join(', ')}`
      })
    .join(', ')
}