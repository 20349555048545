<template>
  <div class="ui grid">
    <div id="storyboard-dimmer" class="ui dimmer inverted">
      <div class="ui text loader">
        Loading
      </div>
    </div>
    <div :class="showConceptList ? 'twelve wide column' : 'sixteen wide column'">
      <div class="storyboard-wrapper ui segments">
        <div class="ui clearing segment header">
          <div class="left floated title">
            Storyboard
          </div>
          <span class="icons right floated">
            <help-icon :content="storyboardHelp"></help-icon>
            <download-export-button
              ref="downloadImageBtn"
              :name="currentAnalysis.name+'-Storyboard'"
              :get-el="getEl"
              :get-svg-export-config="getExportConfig"
              :basic-svg-export="true"
              short-name="Storyboard"
            ></download-export-button>
          </span>
        </div>
        <div class="ui clearing segment slider-controls">
          <div class="ui grid">
            <div class="row sliders">
              <div class="eight wide column sliderbox">
                <label>Concept Groups</label>
                <bf-slider v-model="numClusters" :min="MIN_CLUSTERS" :max="MAX_CLUSTERS" />
                <label>{{ numClusters }}</label>
              </div>
              <div class="eight wide column sliderbox">
                <label>Concepts Displayed</label>
                <bf-slider v-model="numConcepts" :min="0" :max="maxConcepts" />
                <label>{{ numConcepts }}</label>
              </div>
            </div>
            <div class="row">
              <div class="sixteen wide column dimmer-box">
                <label>Dim concepts added to a Theme</label>
                <toggle-slider v-model="dimAddedConcepts" />
              </div>
            </div>
          </div>
        </div>
        <div class="ui segment storyboard-container">
          <storyboard
            ref="storyboard"
            :concepts-in-queries="conceptsToDim"
            :dim-concepts-in-queries="dimAddedConcepts"
            :defer-render="true"
            :active-concept="activeConcept"
            :on-concept-click="onConceptClicked ? emitConceptClick : undefined"
            @layout-complete="onLayoutComplete"
            @concept-clicked="conceptClicked"
          />
          <div class="slider-controls-note">
            Note: Editing colors/concepts shown is purely cosmetic and does not affect the analysis’ data.
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConceptList" class="four wide column">
      <div class="ui segments concept-list-wrapper">
        <div class="ui clearing segment header">
          <span class="left floated title">Concept List</span>
          <span class="icons right floated">
            <help-icon :content="conceptlistHelp"></help-icon>
            <download-export-button
              ref="downloadConceptListBtn"
              :name="currentAnalysis.name+'-Concepts'"
              :get-csv-data="getConceptListCsvData"
              short-name="Concepts"
            ></download-export-button>
          </span>
        </div>
        <div class="ui segment">
          <topic-info-list ref="conceptInfoList" :concepts="currentModel.topics_list" @concept-hovered="activateConcept"></topic-info-list>
        </div>
      </div>
    </div>
    <resize-listener @resize="setSizes" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  import Project from 'src/api/project'
  import DataUtils from 'src/utils/data'
  import DownloadExportButton from './widgets/DownloadExportButton.vue'
  import HelpIcon from './widgets/HelpIcon.vue'
  import Storyboard from './widgets/Storyboard.vue'
  import TopicInfoList from './widgets/TopicInfoList.vue'
  import { BfSlider } from 'components/Butterfly'
  import ToggleSlider from "components/widgets/ToggleSlider.vue"
  import QueryUtils from 'src/utils/query'
  import ResizeListener from "components/widgets/ResizeListener/ResizeListener.vue"
  import { isEqual } from 'lodash'

  const StoryboardPage = defineComponent({
    components: { DownloadExportButton, HelpIcon, Storyboard, TopicInfoList, BfSlider, ToggleSlider, ResizeListener },
    props: {
      showConceptList: { type: Boolean, required: false, default: true },
      onConceptClicked: { type: Function, required: false, default: null },
      defaultDimAddedConcepts: { type: Boolean, required: false, default: false },
      // Optional replacement for savedQueries
      queryList: { type: Array, required: false, default: () => [] },
      // Additional concepts to dim
      dimmedConcepts: { type: Array, required: false, default: () => [] },
    },
    data () {
      return {
        activeConcept: null,
        layoutComplete: false,
        numConcepts: 0,
        numClusters: 0,
        storyboardHelp: '<p>The storyboard is designed to help you understand the language model that has been built from your text data.</p>' +
          '<p>Concepts are represented by colored circles with a label. The size of the circle and label indicate how frequently it is mentioned.</p>' +
          '<p>Clicking a concept will navigate to the search page, which provides access to more information about that concept.</p>' +
          '<p>The colored hulls represent soft groupings of related concepts. The groupings can be made more or less specific using the "# of Colors" slider.</p>' +
          '<p>The "# of Concepts" slider allows concepts to be hidden based on their frequency. Starting with just a subset of the most frequent concepts can help in building an understanding of larger, more complex storyboards.</p>' +
          '<p>The lines between concepts represent the strongest relationships. The smallest possible number of links are drawn such that every concept is connected. Follow the links to help understand key narratives within the data.</p>',
        conceptlistHelp: '<p>This list shows all concepts present in the storyboard to the left. The number of verbatims each concept is mentioned in is represented via width of the colored bars and indicated as a percentage adjacent to each bar.</p>' +
          '<p>This list can be sorted by the colored groupings shown in the storyboard or by the frequency of the concepts themselves.</p>' +
          '<p>Hovering a concept or its frequency bar will highlight that concept on the storyboard. You can also click through to the search page for that concept.</p>',
        MIN_CLUSTERS: DataUtils.MIN_CLUSTERS,
        MAX_CLUSTERS: DataUtils.MAX_CLUSTERS,
        dimAddedConcepts: this.defaultDimAddedConcepts,
      }
    },
    computed: {
      ...mapGetters(['currentAnalysis', 'currentModel', 'savedQueries']),
      maxConcepts () {
        return !this.currentModel?.concept_layout ? 0 : Object.keys(this.currentModel.concept_layout).length
      },
      conceptsToDim (): string[] {
        let concepts: string[] = []

        const queries = this.queryList.length
          ? this.queryList
          : this.savedQueries

        queries.forEach(sq => {
          const nodes = QueryUtils.getQueryLeafNodesFromBotanicQuery(sq.query_value)
          concepts = concepts.concat(
            nodes?.filter(n => n.type === 'text' && n.value).map(n => n.value) ?? []
          )
        })

        return concepts.concat(this.dimmedConcepts)
      }
    },
    watch: {
      conceptsToDim (newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.$nextTick(() => {
            this.$refs.storyboard.draw()
          })
        }
      },
      numClusters (value) {
        this.$refs.storyboard.updateClusters(value)
        if (this.saveClustersTimer) {
          clearTimeout(this.saveClustersTimer)
        }
        this.saveClustersTimer = setTimeout(() => {
          Project.updateAnalysisDisplayAttributes(this.$store, { numClusters: value })
        }, 700)
      },
      numConcepts (value) {
        this.$refs.storyboard.updateNumConcepts(value)
        if (this.saveConceptsTimer) {
          clearTimeout(this.saveConceptsTimer)
        }
        this.saveConceptsTimer = setTimeout(() => {
          Project.updateAnalysisDisplayAttributes(this.$store, { numConcepts: value })
        }, 700)
      }
    },
    beforeUnmount () {
      clearTimeout(this.saveConceptsTimer)
      clearTimeout(this.saveClustersTimer)
    },
    mounted () {
      this.numConcepts = this.currentModel?.numConceptsDisplayed
      this.numClusters = this.currentModel?.clusters?.length
      this.setSizes()
      this.$nextTick(() => {
        this.$refs.storyboard.draw()
      })
    },
    metaInfo () {
      return {
        title: this.currentAnalysis ? `${this.currentAnalysis.name} Storyboard - Kapiche` : null
      }
    },
    methods: {
      emitConceptClick (concept: string, el: Element) {
        if (this.onConceptClicked) {
          const dimmed = this.conceptsToDim.includes(concept)
          this.onConceptClicked(concept, el, dimmed)
        }
      },
      setSizes () {
        this.$refs.storyboard.draw()
      },
      activateConcept (concept) {
        this.activeConcept = concept
      },
      getEl () {
        return this.$refs.storyboard.$el.querySelector('svg')
      },
      getExportConfig (viewableOnly = false) {
        return this.$refs.storyboard.getExportConfig(viewableOnly)
      },
      getConceptListCsvData () {
        return this.$refs.conceptInfoList.getCsvData()
      },
      onLayoutComplete () {
        document.getElementById('storyboard-dimmer').classList.remove('active')
        this.layoutComplete = true
      },
      conceptClicked (concept: string) {
        this.$analytics.track.analysis.conceptClickedOnStoryboard(concept)
      }
    }

  })
  export default StoryboardPage
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .storyboard-container
    padding: 0

  .analysis-container > .ui.grid
    flex: 1
    > .column
      display: flex
      flex-direction: column
      flex: 1
      &.four
        flex: 4
      &.twelve
        flex: 12

  .row
    &:first-child
      padding: 15px 0 7px 0
    &:last-child
      padding: 7px 0 15px 0

  .sliderbox
    display: flex !important
    justify-content: flex-start
    align-items: center

    .bf-slider
      width: 60%
      margin: 0 15px

  .dimmer-box
    display: flex !important
    align-items: center
    justify-content: flex-start
    label
      margin-right: 15px

  .sliders
    justify-content: space-between !important

  .icons
    color: $grey

  .storyboard-wrapper
    margin: 0 !important

    div.left.floated.title
      height: 61px

    .header.slider-controls
      height: 25px

    .storyboard-container
      position: relative

    .slider-controls-note
      position: absolute
      bottom: 0
      left: 0
      right: 75px // avoid storyboard controls in bottom-right
      color: rgba(149, 166, 172, 0.9)
      //background-color: rgba(255,255,255,0.7)
      padding: 0.5rem 1rem
      font-size: 1.2rem
    .cluster-slider, .concept-slider
      margin-left: 1.1rem
    .cluster-slider, .concept-slider, .slider-label, label
      font-size: 1.1rem
      padding-top: 0
    .slider-label
      text-align: right
    label
      vertical-align: top

    .num-clusters-slider, .num-concepts-slider
      padding-left: 1.1rem
      max-width: 350px
      width: 60%

  .storyboard-wrapper, .concept-list-wrapper
    display: flex
    flex-direction: column
    height: 100%

    .ui.segment:last-of-type
      display: flex
      flex-direction: column
      flex: 1 1 0
      padding: 0

      .segments
        border: none
        flex: 1 1 0
        overflow-y: auto

</style>
