import { App } from 'vue'

import {
  ElAlert,
  ElAutocomplete,
  ElButton,
  ElCheckbox,
  ElCheckboxGroup,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElColorPicker,
  ElContainer,
  ElDatePicker,
  ElDialog,
  ElDivider,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElForm,
  ElFormItem,
  ElHeader,
  ElInput,
  ElInputNumber,
  ElLoading,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElMenuItemGroup,
  ElNotification,
  ElMessageBox,
  ElOption,
  ElPagination,
  ElPopover,
  ElProgress,
  ElSelect,
  ElTooltip,
  ElSwitch,
  ElRadio,
  ElRow,
  ElTable,
  ElTableColumn,
  ElTabs,
  ElTabPane,
  ElTag,
  ElTree,
  ElRadioButton,
  ElRadioGroup,
} from 'element-plus'

export const components = [
  ElAlert,
  ElAutocomplete,
  ElButton,
  ElCheckbox,
  ElCheckboxGroup,
  ElCol,
  ElCollapse,
  ElCollapseItem,
  ElColorPicker,
  ElContainer,
  ElDatePicker,
  ElDialog,
  ElDivider,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElForm,
  ElFormItem,
  ElHeader,
  ElInput,
  ElInputNumber,
  ElMain,
  ElMenu,
  ElMenuItem,
  ElSubMenu,
  ElMenuItemGroup,
  ElMessageBox,
  ElOption,
  ElPagination,
  ElPopover,
  ElProgress,
  ElSelect,
  ElTooltip,
  ElSwitch,
  ElRadio,
  ElRow,
  ElTable,
  ElTableColumn,
  ElTabs,
  ElTabPane,
  ElTag,
  ElTree,
  ElRadioButton,
  ElRadioGroup,
]

export const setupElementPlus = (app: App) => {
  for (const comp of components) {
    app.component(comp.name!, comp as any)
  }
  app.directive('loading', ElLoading.directive)
  app.config.globalProperties.$notify = ElNotification
}
