<template>
  <bf-modal :visible="visible">
    <div v-if="bodyText" class="lock-out-modal">
      <div class="header">
        Oops, something went wrong.
      </div>
      <div class="content description">
        {{ bodyText }}
      </div>
      <a href="javascript:window.Intercom('show')" class="ui huge blue button">
        Contact Us
      </a>
    </div>

    <div v-else-if="exceededLimits" class="lock-out-modal">
      <div class="header">
        Your plan has exceeded limits
      </div>
      <div class="exceeded-limits-content">
        Your Site is currently over the user limit.
        <template v-if="isAdmin">
          Please update your Site settings.
          <br /><br />
          <router-link v-if="isAdmin" :to="{ name: 'profile' }" class="ui huge blue button">
            Settings
          </router-link>
        </template>
        <template v-else>
          Please notify your administrator.
        </template>
        <br /><br />
        or <a href="javascript:window.Intercom('show')">
          contact us for help
        </a>
      </div>
    </div>

    <div v-else-if="trialing" class="lock-out-modal">
      <div class="header">
        Your trial has ended
      </div>
      <div class="content description">
        Hey{{ space_first_name }}, your trial has ended. We'll be in
        touch soon to discuss your experience and steps moving forward, but
        feel free to
        <a href="javascript:window.Intercom('show')">
          contact us
        </a>
        if you have any questions.
        <br>
      </div>
      <a href="javascript:window.Intercom('show')" class="ui huge blue button">
        Contact Us
      </a>
    </div>

    <div v-else-if="!trialing && !isAdmin" class="lock-out-modal">
      <div class="header">
        Your plan has expired
      </div>
      <div class="content description">
        <b>This Site no longer has a valid subscription.</b>
        <br>
        Please contact your site administrator to renew your Kapiche plan
        and continue using Kapiche.
        <br>
        You still have access to your
        <router-link :to="{ name: 'profile' }">
          Settings
        </router-link>
      </div>
      <button class="ui huge blue button" @click="refresh">
        Refresh
      </button>
    </div>

    <div v-else-if="!trialing && isAdmin" class="lock-out-modal">
      <div class="header">
        Your plan has expired
      </div>
      <div class="content description">
        <b>This Site no longer has a valid subscription.</b>
        <br>
        Contact your account manager to renew your plan.
        <br>
        You still have access to your
        <router-link :to="{ name: 'profile' }">
          Settings
        </router-link>
      </div>
      <a href="javascript:window.Intercom('show')" class="ui huge blue button">
        Contact Us
      </a>
    </div>
  </bf-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BfModal } from 'components/Butterfly/'

export default defineComponent({
  components: { BfModal },
  props: {
    visible: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    trialing: { type: Boolean, default: false },
    exceededLimits: { type: Boolean, default: false },
    currentUser: { type: Object, required: false, default: null },
    bodyText: { type: String, required: false, default: null }
  },
  computed: {
    space_first_name () { return this.currentUser && ` ${this.currentUser.first_name}` }
  },

  methods: {
    refresh () {
      window.location.reload()
    },
  }
})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .lock-out-modal
    width: 500px
    margin: 0 auto
    padding: 50px
    background-color: #fff
    border-radius: 2px
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33)
    transition: all .3s ease
    text-align: center
    font-size: 16px

    .header
      font-size: 30px
      margin-bottom: 25px
      color: $text-grey

    .content
      line-height: 2
      margin-bottom: 25px

    .actions
      padding: 0 17.5px

    .exceeded-limits-content
      color: #383838
      font-size: 18px
      line-height: 1.5

</style>
