<template>
  <div class="expanding-button" :class="{expanded}" :style="cssVars">
    <resize-listener @resize="getDimensions" />

    <button type="button" :class="{ title: expanded, trigger: !expanded }" class="action-title" :disabled="expanded" @click="toggle">
      {{ label }}
    </button>

    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" :duration="{ enter: 500, leave: 0 }">
      <div v-if="expanded" class="actions">
        <div class="action-buttons-wrapper">
          <div v-for="action in actionsList" :key="action.value" class="action">
            <slot name="actions" :toggle="toggle" :item="action" :expanded="expanded">
              <el-button type="primary" plain class="action-button" @click="clickItem(action.value)">
                {{ action.label }}
              </el-button>
            </slot>
          </div>
        </div>
        <div class="cancel-wrapper">
          <el-button v-if="expanded" type="info" plain class="cancel-button" @click="cancel">
            Cancel
          </el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent} from 'vue'
import ResizeListener from 'components/widgets/ResizeListener/ResizeListener.vue'

export type actionType = {
  label: string;
  value: string | number
}

const BfExpandingButton = defineComponent({
  components: { ResizeListener },
  props: {
    actions: { type: Array as PropType<actionType[]>, required: true },
    trigger: { type: String, required: true },
    title: { type: String, required: false, default: null }
  },
  data () {
    return {
      expanded: false,
      width: 500
    }
  },
  computed: {
    label () {
      if (this.expanded) {
        return this.title ?? this.trigger
      } else {
        return this.trigger
      }
    },
    actionWidth () {
      return this.width / (this.actions.length + 2)
    },
    cssVars () {
      return {
        "--action-width": `${this.actionWidth}px`,
      }
      },
    actionsList () {
      return this.expanded ? this.actions : []
    }
  },
  mounted () {
    this.getDimensions()
  },
  methods: {
    getDimensions () {
      this.$nextTick(()=>{
        if (this.$el) {
          this.width = this.$el.getBoundingClientRect().width
        }
      })
    },
    cancel () {
      this.toggle()
      this.$emit('cancel')
    },
    toggle () {
      this.expanded = !this.expanded
    },
    clickItem (item: string) {
      this.$emit('click', item)
      this.toggle()
    }
  }
})

export default BfExpandingButton
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'
  div.expanding-button
    width: 100%
    padding: 16px
    border: 1px dashed $text-grey
    display: inline-flex
    flex-direction: column
    justify-content: space-around
    border-radius: 4px
    font-size: 100%
    line-height: 1.15
    font-weight: normal
    align-items: center

    &.expanded
      border: 1px solid $orange

  .action-title
    align-self: center
    border: none
    background-color: transparent
    color: $blue
    text-transform: uppercase
    font-weight: 800
    cursor: pointer

  .action-buttons-wrapper
    display: inline-flex
    flex-wrap: wrap

  .cancel-wrapper
    margin-top: 5px
    width: 100%
    display: inline-flex
    flex-direction: row-reverse

  .action-button
    align-self: center
    background-color: transparent
    color: $blue
    text-transform: uppercase
    font-weight: 800
    cursor: pointer
    margin: 5px

  .trigger
    position: relative
    width: auto

  .title
    width: 100%
    color: black
    cursor: default
    position: relative
    padding: 10px
    text-align: left

  .actions
    display: inline-flex
    width: 100%
    flex-direction: row
    flex-wrap: wrap
    position: relative

</style>
