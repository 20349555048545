// Calculate nps score using raw counts
// Returns percentages and final score
/**
 * This function calculates the NPS using raw counts.
 *
 * @param detractors {Number} - The number of records that are NPS 0-6
 * @param passives {Number} - The number of records that are NPS 7-8
 * @param promoters {Number} - The number of records that are NPS 9-10
 * @param forceReturnZero {Boolean} - Force returning nps of zero even if there are no records provided
 * @returns {{nps: (string|number), detractors: string, promoters: string, passives: string}}
 *      If the total sum of the inputs are zero, then the nps is returned as
 *      NaN, unless `forceReturnZero` is truthy. This is because we typically
        show breaks in the graph if no records are found, but want to plot zeroes
        if the NPS calculation results in a zero.
  */
export const calculateNPS = (detractors: number, passives: number, promoters: number, forceReturnZero = false) => {
    detractors = detractors ? detractors : 0
    passives = passives ? passives : 0
    promoters = promoters ? promoters : 0
    let totalValid = detractors + passives + promoters

    if (totalValid === 0) {
      return {
        'promoters': '0',
        'detractors': '0',
        'passives': '0',
        'nps': forceReturnZero ? 0 : NaN,
      }
    }
    let decimalPlaces = 2
    let detractorsPercent = Number(totalValid > 0 ? detractors / totalValid * 100 : 0).toFixed(decimalPlaces)
    let promotersPercent = Number(totalValid > 0 ? promoters / totalValid * 100 : 0).toFixed(decimalPlaces)
    let passivesPercent = Number(totalValid > 0 ? passives / totalValid * 100 : 0).toFixed(decimalPlaces)
    let npsScore = Number(totalValid > 0 ? +promotersPercent - +detractorsPercent : 0).toFixed(decimalPlaces)
    return {
      'promoters': promotersPercent,
      'detractors': detractorsPercent,
      'passives': passivesPercent,
      'nps': npsScore
    }
  }

/**
 * { detractors, passives, promoters, totalCount },
 * { detractors, passives, promoters, totalCount },
 * forceReturnZero
 *
 */
export const calculateOtherNPS = (overall: any, theme: any, forceReturnZero = false) => {
  return calculateNPS(
    overall.detractors - theme.detractors,
    overall.passives - theme.passives,
    overall.promoters - theme.promoters,
    forceReturnZero
  )
}

/**
 * { detractors, passives, promoters, totalCount },
 * { detractors, passives, promoters, totalCount },
 * forceReturnZero
 */
export const calculateNPSImpact = (overall: any, theme: any, forceReturnZero = false) => {
  const overallScores = calculateNPS(
    overall.detractors,
    overall.passives,
    overall.promoters,
    forceReturnZero)
  const otherScores = calculateOtherNPS(overall, theme, forceReturnZero)

  return Number(+overallScores.nps - +otherScores.nps).toFixed(2)
}
