<template>
  <div id="container">
    <div v-if="groupByField" class="group-by-field">
      {{ groupByField }}: <a @click="$emit('show-grouped-records', groupByField, groupByValue)">{{ groupByValue }}</a>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-show="expanded" class="text" v-html="highlightedText"></div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-show="!expanded" class="text" v-html="truncateText(highlightedText, TRUNCATE_AT)"></div>

    <!--  Actions -->
    <div id="actions">
      <span class="control rm" @click="copy(rawText)"><i class="el-icon-copy-document"></i> COPY TEXT</span>

      <span v-if="structuredData.length > 0" id="show-info" class="control rm" @click="toggleShowInfo">
        <i class="el-icon-postcard"></i>
        <span v-show="!showInfo">SHOW INFO</span>
        <span v-show="showInfo">HIDE INFO</span>
      </span>

      <span v-if="groupByField" class="control" @click="$emit('show-grouped-records', groupByField, groupByValue)"><i class="el-icon-chat-line-round"></i>  VIEW CONVERSATION</span>

      <span v-if="showAnnotations && sentiment">Sentiment: <span :class="sentimentStyle">{{ sentiment }}</span></span>

      <span class="spacer"></span>

      <span v-if="rawText.length > TRUNCATE_AT" id="show-more" class="control" @click="expanded = !expanded">
        <span v-show="!expanded" class="">SHOW MORE</span>
        <span v-show="expanded" class="">SHOW LESS</span>
      </span>
    </div>

    <div v-if="structuredData.length > 0" v-show="showInfo" id="metadata">
      <div v-for="d of structuredData" :key="`${d.field}${d.value}`" class="metadatum">
        <div class="field-label">
          {{ d.field }}
        </div>
        <div class="value-label" :class="{[d.tag]: d.tag !== undefined}">
          {{ d.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Util from 'src/utils/general'
import copy from 'copy-to-clipboard'

const SENTIMENT_STYLE = {
  positive: 'sentiment-positive',
  negative: 'sentiment-negative',
  mixed: 'sentiment-mixed'
}

export default defineComponent({
  name: 'Verbatim',
  props: {
    highlightedText: { type: String, required: true},
    rawText: { type: String, required: true},
    structuredData: { type: Array, required: false, default: () => ([])},
    showAnnotations: { type: Boolean, default: false },
    groupByField: { type: String, required: false, default: null }
  },
  data () {
    return {
      /** When expanded, the widget shows the full text instead of the truncated version. */
      expanded: false,
      /** When showing the info, the structured data is displayed in the widget */
      showInfo: false,
      TRUNCATE_AT: 1000,
    }
  },
  computed: {
    sentiment () {
      let hit = this.structuredData.find(x => x.field === 'Sentiment')
      return hit?.value
    },
    sentimentStyle () {
      return SENTIMENT_STYLE[this.sentiment]
    },
    groupByValue () {
      if (!this.groupByField) {
        return null
      }
      return this.structuredData.find(x => x.field === this.groupByField).value
    }
  },
  methods: {
    truncateText: Util.truncateText,
    copy: copy,
    toggleShowInfo () {
      this.showInfo = !this.showInfo
      if (this.showInfo) {
        this.$analytics.track.verbatimsWidget.showInfo(true)
      }
    },
  }
})
</script>

<style lang="sass" scoped>
  @import 'src/assets/colours.sass'

  #container
    padding: 0 30px
    flex-grow: 1
  .text
    font-size: 16px
    line-height: 1.7rem

  #actions
    display: flex
    margin: 15px 0
    font-size: 12px
    letter-spacing: 0.6px
    font-weight: bold
    color: #95a6ac
    .control
      cursor: pointer
      &:hover
        opacity: 0.75
        color: #068ccc
    .rm
      margin-right: 5%
    .spacer
      flex-grow: 1

  #metadata
    border: solid 1px rgb(216, 216, 216)
    background-color: rgb(251, 251, 251)
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    padding: 10px
    margin-top: 15px
    .metadatum
      flex: none
      flex-basis: 260px /* Max width of an element before we spin our another column */
      padding: 10px
      .field-label
        font-size: 12px
        font-weight: bold
        letter-spacing: 0.6px
        text-transform: uppercase
        color: rgb(149, 166, 172)
      .value-label
        font-size: 14px
        font-weight: normal
        color: rgb(56, 56, 56)
        &.positive, &.Promoter
          color: rgb(33, 186, 69)
          text-transform: capitalize
          font-weight: bold
        &.negative, &.Detractor
          color: rgb(238, 56, 36)
          text-transform: capitalize
          font-weight: bold
        &.mixed, &.Passive
          color: rgb(248, 149, 22)
          text-transform: capitalize
          font-weight: bold
        &.neutral
          color: rgb(127, 127, 127)
          text-transform: capitalize
          font-weight: bold

  .sentiment-positive
    color: $green
  .sentiment-negative
    color: $red
  .sentiment-mixed
    color: $orange

  .group-by-field
    color: $grey-dark
    margin-bottom: 5px
    a
      color: $blue
      font-weight: normal
      text-decoration: none
      cursor: pointer
      &:hover
        color: $blue-light
</style>
