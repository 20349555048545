<template>
  <modal
    class="delete-theme-modal"
    :visible="visible"
    @close="$emit('close')"
  >
    <template #header>
      Delete Group
    </template>
    <template #content>
      <p class="content">
        You are about to delete the theme group "{{ groupName }}".
        All child themes or groups will be moved up one level in the tree.
      </p>
    </template>
    <template #buttons>
      <div class="modal-actions">
        <bf-button
          size="large"
          color="grey"
          @click="$emit('close')"
        >
          Cancel
        </bf-button>
        <bf-button
          size="large"
          color="red"
          @click="$emit('submit')"
        >
          Delete
        </bf-button>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { BfButton } from 'components/Butterfly'
  import Modal from 'src/components/project/analysis/results/Modal.vue'

  const ThemeDeleteModal = defineComponent({
    components: {
      BfButton,
      Modal,
    },
    props: {
      visible: { type: Boolean, required: true },
      groupName: { type: String, required: true },
    },
  })

  export default ThemeDeleteModal
</script>
<style lang="sass" scoped>
  ::v-deep .el-dialog__body
    text-align: center !important

  .content
    white-space: pre-wrap

  .modal-actions
    display: flex
    justify-content: center
    margin-top: 30px
    > *:not(:last-child)
      margin-right: 20px
</style>
