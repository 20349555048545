<template>
  <input
    ref="bf-datepicker"
    class="bf-datepicker"
    :value="value"
    type="text"
    :placeholder="placeholder"
    autocomplete="off"
    readonly
  >
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import dayjs from 'dayjs'
  import Pikaday from 'pikaday'

  const BfDatePicker = defineComponent({
    props: {
      /** date string */
      value: {type: String, required: false, default: ''},
      /** format that selected date will be emitted in */
      format: { type: String, required: false, default: 'DD/MM/YYYY' },
      /** text to display when no date selected */
      placeholder: { type: String, required: false, default: '' },
      config: { type: Object, required: false, default: () => ({}) },
    },
    data () {
      return {
        picker: null
      }
    },
    mounted () {
      this.picker = new Pikaday({
        field: this.$refs['bf-datepicker'],
        format: this.format,
        onSelect: (date) => {
          this.$emit('input', dayjs(date).format(this.format))
        },
        ...this.config,
      })
    },
    beforeUnmount () {
      this.picker.destroy()
    }
  })

  export default BfDatePicker
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'
  @import '~pikaday/css/pikaday.css'

  .bf-datepicker
    border: 1px solid $grey-light
    padding: 14px
    cursor: pointer
</style>
