/**
 * This file provides exports API interactions.
 */
import Vue from 'vue'
import { ActionContext } from 'vuex'
import { DashboardSavedQuery } from 'src/types/Query.types'

export default {

  /**
   * Get data for document topics export
   *
   * @param store {Object} application store
   * @returns {Promise}
   */
  getDocumentTopics (store: ActionContext<any, any>) {
    let analysis = store.getters.currentAnalysis
    return Vue.http.get('projects/' + analysis.project + '/analysis/' + analysis.id + '/exports/document-topics/')
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get data for text excerpt topics export
   *
   * @param store {Object} application store
   * @returns {Promise}
   */
  getTextExcerptTopics (store: ActionContext<any, any>) {
    let analysis = store.getters.currentAnalysis
    return Vue.http.get('projects/' + analysis.project + '/analysis/' + analysis.id + '/exports/frame-topics/')
        .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get the nextdoor-specific export information for spreadsheet export
   * @param projectId
   * @param analysisId
   * @returns {Promise<*>}
   */
  async getExportConcepts (projectId: number, analysisId: number) {
    const res = await Vue.http.get(
      `projects/${projectId}/analysis/${analysisId}/exports/concepts/?`
    )
    return res.json()
  },

  /**
   * Get data for document level classification saved query export
   *
   * @param projectId {Number} the project ID
   * @param analysisId {Number} the analysis ID
   * @returns {Promise}
   */
  async getDocumentSavedQueries (
    projectId: number,
    analysisId: number,
    savedQueries: DashboardSavedQuery[],
  ) {
    const res = await Vue.http.post(
      `projects/${projectId}/analysis/${analysisId}/exports/document-queries/?`,
      savedQueries,
    )
    return await res.json()
  },

  /**
   * Get data for text excerpt classification saved query export
   *
   * @param store {Object} application store
   * @returns {Promise}
   */
  async getTextExcerptSavedQueries (
    projectId: number,
    analysisId: number,
    savedQueries: DashboardSavedQuery[],
  ) {
    const res = await Vue.http.post(`projects/${projectId}/analysis/${analysisId}/exports/frame-queries/?`, savedQueries)
    return await res.json()
  },

  /**
   * Get a list of saved queries with details
   *
   * @param store {Object} application store
   * @returns {Promise}
   */
  async getSavedQueriesList (projectId: number, analysisId: number) {
    const res = await Vue.http.get(`projects/${projectId}/analysis/${analysisId}/exports/saved-queries-list/`)
    return await res.json()
  },
}
