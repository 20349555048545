<template>
  <div>
    <p>
      The Export API must be enabled on a per-analysis basis. Once enabled,
      a table row can be expanded to see the instructions.
    </p>
    <ul>
      <li>The table below allows enabling the Export API.</li>
      <li>The Export API must be enabled on a per-analysis basis</li>
      <li>For an enabled analysis, </li>
    </ul>
    <div class="ui divider"></div>

    <div>
      <el-table
        :data="tableData"
        :default-sort="{prop: 'project_name', order: 'ascending'}"
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template #default="props">
            <template v-if="props.row.enabled">
              <h3>Export API Instructions</h3>
              <p>
                Different datasets can be exported. To export the
                <em>document-queries</em> dataset, append that to the
                base URL.
              </p>
              <p><strong>Endpoint</strong></p>
              <p>{{ props.row.export_url }}document-queries/</p>
              <p></p>
              <p><strong>Curl Example:</strong></p>
              <code>
                $ curl -X GET -H "Site-Name: {{ siteDomain }}" -H "Authorization: Site TOKEN" {{ props.row.export_url }}document-queries/
              </code>
              <p></p>
              <p>Substitute "TOKEN" in the above call with a valid API token.</p>
            </template>
            <template v-else>
              <p>Exports not enabled on this analysis.</p>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          label="Project"
          prop="project_name"
          :sortable="true"
        >
          <template #default="scope">
            <span style="margin-right: 10px">{{ scope.row.project_name }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="Analysis"
          prop="analysis_name"
          :sortable="true"
        >
          <template #default="scope">
            <span style="margin-right: 10px">{{ scope.row.analysis_name }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="API enabled"
          prop="enabled"
          :sortable="true"
          width="140"
        >
          <template #default="scope">
            <el-switch
              :model-value="scope.row.enabled"
              active-color="#38D35D"
              @update:model-value="scope.row.enabled = $event"
              @change="$emit('change-enabled', scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          label="Base URL"
          prop="export_url"
          :sortable="true"
        >
          <template #default="scope">
            <span v-if="scope.row.enabled">{{ scope.row.export_url }}</span>
            <span v-else></span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      siteDomain: { type: String, required: true },
      tableData: { type: Array<Record<string, unknown>>, required: true },
    },
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .items, .el-table
    font-size: 16px
</style>
