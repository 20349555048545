<template>
  <div class="panel">
    <header v-if="!!$slots.header">
      <slot name="header" />
    </header>
    <main v-if="!!$slots.main">
      <slot name="main" />
    </main>
  </div>
</template>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  div.panel
    margin: 0
    background: #FFFFFF 0 0 no-repeat padding-box
    box-shadow: 0 1px 5px #0001011A
    opacity: 1

  header
    padding: 14px 20px
    border-bottom: 1px solid #E5E5E5
    display: flex
    justify-content: space-between
    align-items: center

  main
    padding: 20px
    font-size: rem(16px)
</style>
