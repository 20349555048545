import dayjs from 'dayjs'
import _ from 'lodash'
import * as Sentry from "@sentry/browser"
import { ChrysalisFilter } from 'src/types/DashboardFilters.types'
import { QueryType, QueryElement, DashboardSavedQuery, QueryRow } from 'types/Query.types'
import { Nullable } from 'src/types/utils'

const ROW_OPERATOR_MAPPING = new Map([
  ['is', '='],
  ['is not', '='],
  ['is greater than', '>'],
  ['is greater than or equal to', '>='],
  ['is less than', '<'],
  ['is less than or equal to', '<='],
  ['includes', ''],
  ['does not includes', ''],
  ['is in the range', 'between'],
  ['is not in the range', 'not between'],
])
const ROW_OPERATOR_MAPPING_DATE = new Map([
  ['is', '='],
  ['is before', '<'],
  ['is after', '>'],
  ['is in the range', 'between'],
  ['is not in the range', 'not between']
])

const BOTANIC_OPERATOR_MAPPING = new Map([
  ['=', 'is'],
  ['>', 'is greater than'],
  ['>=', 'is greater than or equal to'],
  ['<', 'is less than'],
  ['<=', 'is less than or equal to'],
  ['between', 'is in the range'],
  ['not between', 'is not in the range'],
])
const BOTANIC_OPERATOR_MAPPING_DATE = new Map([
  ['=', 'is'],
  ['<', 'is before'],
  ['>', 'is after'],
  ['between', 'is in the range'],
  ['not between', 'is not in the range']
])

const getRowOperator = function (botanicOperator: string, isDate: boolean, excludes = false) {
  const op = isDate ? BOTANIC_OPERATOR_MAPPING_DATE.get(botanicOperator) : BOTANIC_OPERATOR_MAPPING.get(botanicOperator)
  // Corner case for negative excludes
  if (excludes === true && op === 'is') {
    return 'is not'
  }
  return op
}

const queryParser = function (query: QueryType, traverseExcludes = false) {
  if (['query', 'text', 'segment', 'attribute', 'nonempty_data', 'all_data', 'unmodelled_data'].indexOf(query.type) >= 0) {
    return query
  } else if (['match_all', 'match_any'].indexOf(query.type) >= 0) {
    let ret: any = []
    query.includes?.forEach((q) => {
      let nodes = queryParser(q)
      if (Array.isArray(nodes)) {
        ret = ret.concat(nodes)
      } else {
        ret.push(nodes)
      }
    })
    if (traverseExcludes === true && query.hasOwnProperty('excludes') && query.excludes!.length > 0) {
      query.excludes?.forEach(q => {
        let nodes = queryParser(q)
        if (Array.isArray(nodes)) {
          ret = ret.concat(nodes)
        } else {
          ret.push(nodes)
        }
      })
    }
    return ret
  }
}

/**
 * Convert dashboard filters from their app state format to a valid botanic query
 * @param {Array} filters
 * @returns {{type: string, includes: Array}}
 */
const convertDashboardFiltersToBotanicQueries = (
  filters: ChrysalisFilter[],
  matchAllFields = [] as string[],
) => {
  // Build list of values for each field
  let fieldValues: Record<string, any> = {}
  filters.forEach((f) => {
    const field = f.field === 'sentiment__'? 'sentiment' : f.field
    fieldValues[field] ??= {}
    fieldValues[field][f.op] ??= []
    if (f.op === 'between' || f.op === 'not between') {
      fieldValues[field][f.op].push(f.value)
    } else {
      fieldValues[field][f.op].push(...[].concat(f.value as any))
    }
  })

  const include_queries = []
  const exclude_queries = []

  for (let field in fieldValues) {
    for (let operator in fieldValues[field]) {
      let type = field === 'sentiment' ? 'attribute' : 'segment'

      let op = operator

      // Whether the filter belongs in includes or excludes
      let includes = true

      if (op === 'in') {
        op = '='
      }

      if (op === 'not in') {
        includes = false
        op = '='
      }

      const q = fieldValues[field][operator].map((val: number) => ({
        type: type,
        operator: op,
        field: field,
        value: val,
      }))

      const row = {
        type: matchAllFields.includes(field)
          ? 'match_all'
          : 'match_any',
        includes: q,
      }

      if (includes) {
        include_queries.push(row)
      } else {
        exclude_queries.push(row)
      }
    }
  }

  if (include_queries.length === 0) {
    include_queries.push({ type: 'all_data' })
  }

  return {
    type: 'match_all',
    includes: include_queries,
    excludes: exclude_queries,
  }
}

// Convert a botanic query to an array of Chrysalis filters
const convertBotanicQueriesToDashboardFilters = (queryRows: QueryRow[]): ChrysalisFilter[] => {
  let filters: ChrysalisFilter[] = []

  queryRows.forEach((item) => {
    let operator: string = item.operator

    if (item.is_date) {
      operator = ROW_OPERATOR_MAPPING_DATE.get(operator) ?? operator

      const field = item.field as string
      const value = item.values

      if (operator === '=') {
        filters.push({
          op: '>=',
          value: dayjs(value[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          field,
        }, {
          op: '<=',
          value: dayjs(value[0]).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          field,
        })
      } else if (operator === 'between') {
        filters.push({
          op: '>=',
          value: value[0],
          field,
        }, {
          op: '<=',
          value: value[1],
          field,
        })
      } else {
        filters.push({
          op: operator,
          value: value[0],
          field,
        })
      }
      return
    }

    if (operator === 'is') {
      operator = 'in'
    } else if (operator === 'is not') {
      operator = 'not in'
    } else {
      const mapping = ROW_OPERATOR_MAPPING
      operator = mapping.get(operator) ?? operator
    }

    const field = item.field === 'sentiment' ? 'sentiment__' : item.field as string
    let value = ([] as any).concat(item.values ?? (item as any).value)

    // Convert to number if using numerical operators
    if (['<', '>', '<=', '>='].includes(operator)) {
      value = +value[0]
    }

    if (operator === 'between') {
      filters.push({
        op: '>=',
        field: field,
        value: +value[0],
      }, {
        op: '<=',
        field: field,
        value: +value[1],
      })
    } else if (operator === 'not between') {
      // TODO: Can this be achieved via pyarrow filters?
    } else {
      filters.push({
        op: operator,
        field: field,
        value: value,
      })
    }
  })
  return filters
}

export default {
  /**
   * Convert an array of query row to a botanic query.
   *
   * The `level` parameter can be "frame" or "sentence". It controls
   * the match scope of the query.
   * @param {Array} queryRows
   * @param {String} level
   * @returns {Object}
   */
  queryRowsToBotanic (queryRows: QueryElement[], level: "sentence" | "frame"): QueryType {
    let includes: QueryType[] = []
    let excludes: QueryType[] = []
    for (let query of queryRows) {
      let botanicQueryNodes
      let excludesQuery
      if (query.type === 'query') {
        botanicQueryNodes = query.values?.map(value => {
          return { type: 'query', id: value }
        })
        excludesQuery = query.operator === 'does not include'
      } else if (query.type === 'text') {
        botanicQueryNodes = query.values?.map(value => {
          return { type: 'text', value: value }
        })
        excludesQuery = query.operator === 'does not include'
      } else if (query.type === 'segment' || query.type === 'attribute') {
        let mappings = query.is_date ? ROW_OPERATOR_MAPPING_DATE : ROW_OPERATOR_MAPPING
        let operator = mappings.get(query.operator!)!
        let q: QueryElement = {
          field: query.field,
          type: query.type,
          operator: operator
        }
        if (query.is_date) {
          q.is_date = true
        }
        if (operator === '=' && query.is_date) {
          q.value = [
            dayjs(query.values![0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
            dayjs(query.values![0]).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
          ]
          q.operator = 'between'
          botanicQueryNodes = [q]
        } else if (operator.endsWith('between')) {
          // Handle '(not) between' specially (multiple values)
          q.value = query.values
          botanicQueryNodes = [q]
        } else {
          botanicQueryNodes = query.values?.map(value => {
            let mappedQ = Object.assign({}, q)
            mappedQ.value = value
            return mappedQ
          })
        }
        excludesQuery = query.operator === 'is not'
      } else if (['unmodelled_data', 'non_empty_data', 'all_data'].indexOf(query.type) >= 0) {
        botanicQueryNodes = [{ type: query.type }]
        excludesQuery = false
      }
      const botanicQuery = {
        type: 'match_any',
        includes: botanicQueryNodes
      }
      if (excludesQuery === true) {
        excludes.push(botanicQuery as any)
      } else {
        includes.push(botanicQuery as any)
      }
    }
    if (includes.length === 0) {
      includes.push({ type: 'all_data' })
    }
    return { level, type: 'match_all', includes, excludes }
  },
  /**
   * Convert a botanic query into a query row that we can render.
   *
   * This is the function that is called when loading a saved query out of the
   * botanic database and convert it into the format we need in order for the
   * front end to render.
   *
   * @param {Object} query The saved query value from botanic
   * @returns {Array} {{type: string, operator: string, field: *, values: []}}]
   */
  botanicToQueryRows (query: QueryType): QueryElement[] {
    let rows: QueryElement[] = []
    for (let accessor of ['includes', 'excludes'] as const) {
      if (!query.hasOwnProperty(accessor) || query[accessor]!.length <= 0) continue
      for (const node of query[accessor]!) {
        if (accessor === 'includes' && query[accessor]!.length === 1 && _.isEqual(node, { type: 'all_data' })) {
          /**
           * We have do this because we inject "all_data" into queries that have an empty includes clause.
           * Here is an example:
           *
           * If you build a query in the query builder that has no inclusive constraints and only
           * exclusion constraints then the UI format might look like this.
           *    [
           *      {
           *        "type": "text",
           *        "values": [
           *          "Australian", "organic"
           *        ],
           *        "operator": "does not include"
           *      }
           *    ]
           *
           * Then once you save the query it gets converted to the botanic format. Because caterpillar requires
           * an "includes" clause then we insert an "all_data" node in `queryRowsToBotanic`. That would look like this.
           *
           *  {
           *    "type": "match_all",
           *    "includes": [
           *      {
           *        "type": "all_data"
           *      }
           *    ],
           *    "excludes": [
           *      {
           *        "type": "match_any",
           *        "includes": {
           *          {
           *            "type": "text",
           *            "value": "organic"
           *          }
           *       ]
           *     }
           *  ]
           *
           * We then need to convert it back to the UI format. It should match the query that we built in the
           * query builder BUT if we don't strip it out, then we would get a query that looks like this
           *    [
           *     {
           *         "type": "all_data",                          << This is the part we need to strip out
           *     },
           *     {
           *       "type": "text",
           *       "values": [
           *         "organic"
           *       ],
           *       "operator": "does not include"
           *     }
           *   ]
           *
           */
          continue
        } else if (['unmodelled_data', 'non_empty_data', 'all_data'].indexOf(node.type) >= 0) {
          rows.push({ type: node.type })
        } else if (node.type === 'match_any' && node.includes?.length) {
          let ret: QueryElement = {
            type: node.includes[0].type
          }
          if (node.includes![0].operator && node.includes![0].operator.endsWith('between')) {
            // Handle '(not) between' specially (multiple values)
            ret.values = node.includes![0].value!.slice() as any  // copy array
          } else {
            ret.values = node.includes!.map((q) => q.value!)
          }
          if (['segment', 'attribute'].indexOf(node.includes![0].type) >= 0) {
            let isDate = false || !!node.includes![0].is_date
            if (isDate) {
              ret.is_date = true
            }
            ret.operator = getRowOperator(node.includes![0].operator!, isDate, accessor === 'excludes')
            ret.field = node.includes![0].field

            if (ret.operator!.endsWith('in the range') && ret.is_date) {
                const start = dayjs(node.includes![0].value![0])
                const end = dayjs(node.includes![0].value![1])
                if (start.isSame(end, 'day')) {
                  ret.operator = 'is'
                  ret.values = [start.toString()]
                }
              }

          } else if (node.includes![0].type === 'text') {
            ret.operator = accessor === 'excludes' ? 'does not include' : 'includes'
          } else if (node.includes![0].type === 'query') {
            ret.operator = accessor === 'excludes' ? 'does not include' : 'includes'
            ret.values = node.includes!.map(({ id }: any) => id)
            ret.type = 'query'
          }
          rows.push(ret)
        }
      }
    }
    return rows
  },
  convertDashboardFiltersToBotanicQueries,
  convertBotanicQueriesToDashboardFilters,

  /**
   * Get an Array leaf *includes* query nodes from a botanic query.
   * WARNING: This ignores the excludes portion of the nodes
   *
   */
  getQueryLeafNodesFromBotanicQuery (botanicQuery: QueryType) {
    return queryParser(botanicQuery, false)
  },

  // Determine if two queries are equivalent
  areQueriesEquivalent (query1: any, query2: any) {
    let queryRep = (q: any) => {
      return JSON.stringify(q.map((qq: any) => {
        qq = Object.entries(qq).filter((ee) => ee[0] !== 'id')  // ignore surrogate IDs used by UI
        qq.sort((v1: any, v2: any) => v1[0].localeCompare(v2[0]))
        return qq
      }))
    }
    return queryRep(query1) === queryRep(query2)
  },

  // Automatically generate the appropriate date query given a date selected with a particular resolution.
  generateDateQuery (date: Date, field: string, resolution: string) {
    let q = {
      type: 'segment',
      field: field,
      is_date: true,
      operator: '',
      values: [] as any[],
    }
    if (resolution === 'daily') {
      q.operator = 'is'
      q.values = [date]
    } else {
      let values = []
      let dateOp: any = 'week'
      if (resolution === 'monthly') {
        dateOp = 'month'
      } else if (resolution === 'yearly') {
        dateOp = 'year'
      }
      values.push(dayjs(date).startOf(dateOp).format('YYYY-MM-DDT00:00:00'))
      values.push(dayjs(date).endOf(dateOp).format('YYYY-MM-DDT00:00:00'))
      q.operator = 'is in the range'
      q.values = values
    }
    return q
  },
  // Extracts _top-level_ structured filters from a query
  // (This means it ignores saved queries!)
  extractStructuredFiltersFromQuery (botanicQuery: QueryType) {
    const isTextOrQuery = function (item: QueryType) {
      return item['includes']![0]['type'] === 'text' || item['includes']![0]['type'] === 'query'
    }
    const isGroup = function (item: QueryType) {
      return ['match_all', 'match_any'].indexOf(item['includes']![0]['type']) >= 0
    }
    const structuredFilter = function (item: QueryType) {
      if (!item.includes || !item.includes.length) return false
      // Dashboard queries will have an extra level of nesting
      // that we need to go beyond
      if (isGroup(item)) {
        return !isTextOrQuery(item['includes']![0])
      }
      // All items in a query row are necessarily the same type,
      // so we can just check the type of the first item
      return !isTextOrQuery(item)
    }
    return {
      type: 'match_all',
      includes: botanicQuery['includes'] ? botanicQuery['includes'].filter(structuredFilter) : [],
      excludes: botanicQuery['excludes'] ? botanicQuery['excludes'].filter(structuredFilter) : []
    }
  },
}
 /**
* returns a botanic query but merging an existing query with dashboard filters
* param {Object} query
* param {Array} filters
*/
export const mergeDashboardFiltersWithBotanicQuery = (
  query: any,
  filters: Nullable<any[]>,
  matchAllFields: string[] = [],
) => {
 return filters && filters.length > 0 ? {
   type: 'match_all',
   includes: [
     query,
     convertDashboardFiltersToBotanicQueries(filters, matchAllFields)
     ]
 } : query
}

/**
 * Expands any { type: "query" } nodes with their child's content
 *
 * @param {string} queryName Used for error messages
 * @param {Object} queryValue The query_value to be expanded
 * @param {Array} savedQueries The full list of saved queries
 * @param {Array} visitedQueries Set to track visited queries (optional)
 * @returns {Object} expanded query object
 */
export const expandQuery = (
  queryName: string,
  queryValue: any,
  savedQueries: any[],
  queryPath: number[] = [],
): any => {

  let value = { ...queryValue }

  if (value.type === 'query') {
    const savedQuery = savedQueries.find(({ id }: any) => id.toString() === value.id)

    if (!savedQuery) {
      throw new Error(`Failed to find child query (id: ${value.id}) belonging to "${queryName}".`)
    }

    const queryId = parseInt(value.id, 10)
    if (queryPath.includes(queryId)) {
      console.warn(`Circular reference detected for query (id: ${queryId}) in "${queryName}".`)
      return null
    }

    // Mark the query as visited by adding it to the query path
    const updatedQueryPath = [...queryPath, queryId]

    return { ...expandQuery(queryName, savedQuery.query_value, savedQueries, updatedQueryPath) }
  }

  if (value.includes)
    value.includes = value.includes?.map((n: any) => expandQuery(queryName, n, savedQueries, queryPath))
  if (value.excludes)
    value.excludes = value.excludes?.map((n: any) => expandQuery(queryName, n, savedQueries, queryPath))

  return value
}

// expands botanic sub-queries while maintaining botantic syntax
export const expandBotanicQueries = (queries: DashboardSavedQuery[], savedQueries: DashboardSavedQuery[]) => {
  if (!Array.isArray(queries) || !Array.isArray(savedQueries)) return queries

  return queries.map((query) => {
    try {
      const expanded = expandQuery(query.name, query.query_value, savedQueries)
      return {
        ...query,
        query_value: expanded
      }
    } catch (error) {
      Sentry.captureException(error)
      return {
        ...query,
        query_value: {}
      }
    }
  })
}

/**
 * Recursive function that indicates whether the specified
 * botanic query contains any entries that match the passed test
 */
type testType = (q: QueryType) => boolean
const testQuery = (botanicQuery: QueryType | QueryType[] | void, test: testType): boolean => {
  if (!botanicQuery || !test) {
    return false
  }
  if (!Array.isArray(botanicQuery)) {
    // External callers invoke this function with a top level query node
    botanicQuery = [botanicQuery]
  }
  return botanicQuery.some(q => {
    if (test(q)) return true
    if (q.includes || q.excludes) {
      return testQuery(q.includes, test) || testQuery(q.excludes, test)
    }
  })
}


// botanic query contains an unstructured query entity
const hasText: testType = (botanicQuery) => {
  let isLiteralText = botanicQuery.type === 'text'
  let isAITopicAttribute = botanicQuery.type === 'attribute' && botanicQuery.field === 'aitopic'
  return isLiteralText || isAITopicAttribute
}

// botanic query contains an nested query
const hasSubQuery: testType = (botanicQuery) => botanicQuery.type === 'query'

// does this query have any text entries (ie unstructured)
export const hasUnstructured = (botanicQuery: QueryType | QueryType[]): boolean => testQuery(botanicQuery, hasText)

// does this query have any nest query entries
export const hasSubQueries = (botanicQuery: QueryType | QueryType[]): boolean => testQuery(botanicQuery, hasSubQuery)


 export const countTextRows = (rows: QueryElement[]): number => {
  return rows.filter(r => r.type === 'text').length
}
