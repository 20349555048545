<!--https://vuejs.org/v2/examples/tree-view.html-->
<template>
  <div class="ui segments">
    <template v-if="!isLoading">
      <ul>
        <tree-item
          class="item"
          :item="item"
          :selected-keys="selectedKeys"
          :start-open="rootFolderOpen"
          :indent="indent"
          :item-class-lookup="itemClassLookup"
          @make-folder="makeFolder"
          @add-item="addItem"
          @change="onchange"
          @selectionChanged="selectionChanged"
        ></tree-item>
      </ul>
    </template>
    <template v-else>
      <div v-for="i in 4" :key="i" class="ui segment nohover">
        <div class="animated-background"></div>
      </div>
      <!--        <div class="animated-background"></div>-->
    </template>
  </div>
</template>
<script lang="ts">
  // This is how the data needs to look:
  //
  // treeData: {
  //   name: 'My Tree',
  //     children: [
  //     { name: 'hello' },
  //     { name: 'wat' },
  //     {
  //       name: 'child folder',
  //       children: [
  //         {
  //           name: 'child folder',
  //           children: [
  //             { name: 'hello' },
  //             { name: 'wat' }
  //           ]
  //         },
  //         { name: 'hello' },
  //         { name: 'wat' },
  //         {
  //           name: 'child folder',
  //           children: [
  //             { name: 'hello' },
  //             { name: 'wat' }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // }

  import TreeItem from 'src/components/widgets/TreeItem.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: { TreeItem },
    props: {
      item: { type: Object, default: () => null },
      delimiter: { type: String, default: '/' },
      showFiles: { type: Boolean, default: true },
      showFolders: { type: Boolean, default: true },
      allowSelectFile: { type: Boolean, default: true },
      allowSelectFolder: { type: Boolean, default: true },
      itemClassLookup: { type: Function, default: (item) => '' },
      allowSelectMany: { type: Boolean, default: true },
      selectedKeys: { type: Object, default: () => {} },
      isLoading: { type: Boolean, default: false },
      rootFolderOpen: { type: Boolean, default: false },
      indent: { type: Number, default: 30 }
    },
    data: function () {
      return {
        logger: this.$logger.getLogger('Tree')
      }
    },
    methods: {
      selectionChanged (item, oldval, newval) {
        this.logger.debug('Tree.selectionChanged: ' + JSON.stringify(item) + ' ' + oldval + ' ' + newval)
        this.logger.trace(this.selectedKeys)
        if (newval === true && !this.allowSelectMany) {
          // Can't select multiple items, so we'll have to unselect
          // any existing selected ones.
          const existingItems = Object.keys(this.selectedKeys)  // Convert set->array
          this.logger.trace(`existingItems: ${existingItems}`)
          existingItems.forEach((key) => {
            this.logger.trace(`Sending deselect message for ${key}`)
            this.$emit('deselectItemKey', key)
          })
        }

        let msg = newval === true ? 'selectItemKey' : 'deselectItemKey'
        this.logger.trace(`sending ${msg} event for ${item.key}`)
        this.$emit(msg, item.key)
      },
      onchange (item) {
        this.logger.trace('Tree.onchange: ' + JSON.stringify(item))
        this.$emit('change', item)
      },
      makeFolder: function (item) {
        // Vue.set(item, 'children', [])
        item.children = []
        this.addItem(item)
      },
      addItem: function (item) {
        item.children.push({
          name: 'new stuff'
        })
      }
    }
  })
</script>
<style lang="sass" scoped>
  @import '../../assets/kapiche.sass'
  @import '../../assets/mixins.sass'

  ul
    padding: 0
    margin: 0

  div.segments
    background: white
    box-shadow: $box-shadow
    max-height: calc(100vh - 10rem)
    overflow-y: auto
    position: relative
    width: rem(600px)
    margin: rem(40px) auto rem(20px) auto

    div.segment
      border: 1px solid transparent
      /*Prevent text getting selected when the item is double-clicked*/
      user-select: none
      padding: rem(12px)
      text-align: left
      font-size: 16px

      i[class^='icon-']
        margin-right: rem(10px)
        color: $blue

      &.header
        background-color: #fafafa
        height: rem(55px)

      &:hover:not(.header):not(.nohover):not(.disabled)
        cursor: pointer
        background: #f4f6f7
        border: 1px solid $blue !important

      &:not(:last-of-type)
        border-bottom: 1px solid #e5e5e5

      .name
        font-size: rem(18px)

      .animated-background
        height: rem(20px)
        width: 100%

      &::selection
        background: transparent
</style>
