<template>
  <div class="ui segment narrow-form-container">
    <slot></slot>
  </div>
</template>
<style lang="sass" scoped>
.narrow-form-container
  padding: 30px !important
  width: 600px
  h2
    font-size: 24px

.narrow-form-container .vee-form::v-deep,
.narrow-form-container
  &.form,
  form.form
    font-size: 18px
    label
      font-weight: normal
      margin-bottom: 15px
      margin-top: 5px

  .divider
    margin-bottom: 20px
  .divider.bottom
    margin-bottom: 30px
    margin-top: 15px

  .actions
    text-align: right
    .button
      font-size: 20px
      font-weight: bold
      margin-bottom: 15px
      margin-right: 0
      &.cancel
        margin-right: 15px
</style>
