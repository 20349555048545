<template>
  <div
    class="segment"
    :class="{
      enabled: segment.enabled,
      invalid: segment.enabled && !isSegmentValid(segment),
    }"
  >
    <toggle-checkbox
      :value="segment.enabled"
      @input="updateSegment({ enabled: $event })"
    />
    <div>
      <div class="segment-title">
        {{ segmentConfig[segment.type].label }}
      </div>
      <div class="segment-description">
        {{ segmentConfig[segment.type].description }}
      </div>
      <div v-if="segment.type === 'metric'" class="selection-row">
        <div class="pill">
          Show
        </div>
        <dropdown
          v-for="(metric, i) in segment.metrics"
          :key="`${metric}_${i}`"
          :disabled="!segment.enabled"
          :bounded="true"
          class="dropdown blue"
          @input="updateSegment({
            metrics: segment.metrics.map((s, idx) =>
              idx === i
                ? { name: $event, agg_method: getAggMethod($event) }
                : s
            )
          })"
        >
          <template #trigger>
            <div class="dropdown-trigger">
              {{ metric?.name || metric }}
              <i class="kapiche-icon-chevron-down"></i>
            </div>
          </template>
          <dropdown-item>
            <div @click.stop="updateSegment({ metrics: segment.metrics.filter((s, idx) => idx !== i) })">
              (Remove from line)
            </div>
          </dropdown-item>
          <dropdown-item
            v-for="field in availableFields(segment)"
            :key="field"
            :value="field"
          >
            {{ field }}
          </dropdown-item>
        </dropdown>
        <dropdown
          v-if="availableFields(segment).length > 0"
          class="dropdown"
          :class="{
            'white': !segment.metric && isSegmentValid(segment),
            'orange': !segment.metric && !isSegmentValid(segment),
            'blue': !!segment.metric,
          }"
          :bounded="true"
          :disabled="!segment.enabled"
          @input="updateSegment({
            metrics: segment.metrics.concat({
              name: $event,
              agg_method: getAggMethod($event),
            })
          })"
        >
          <template #trigger>
            <div v-if="!isSegmentValid(segment)" class="dropdown-trigger">
              Add metric
              <i class="kapiche-icon-chevron-down"></i>
            </div>
            <div v-else-if="isSegmentValid(segment)" class="dropdown-trigger">
              Add another metric
              <i class="kapiche-icon-chevron-down"></i>
            </div>
          </template>
          <dropdown-item
            v-for="field in availableFields(segment)"
            :key="field"
            :value="field"
          >
            {{ field }}
          </dropdown-item>
        </dropdown>
      </div>
      <div
        v-if="segment.type === 'metric' && segment.enabled && !isSegmentValid(segment)"
        class="error-text"
      >
        Complete or deselect this section
      </div>
      <div v-if="segment.type === 'theme'">
        <div class="selection-row">
          <div class="pill">
            Show
          </div>
          <dropdown
            :disabled="!segment.enabled"
            :bounded="true"
            class="dropdown"
            :class="{
              'orange': !segment.metric,
              'blue': !!segment.metric,
            }"
            @input="updateSegment({
              metric: {
                name: $event,
                agg_method: getAggMethod($event),
              }
            })"
          >
            <template #trigger>
              <div class="dropdown-trigger">
                {{ segment.metric?.name || segment.metric || 'Choose metric' }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
            </template>
            <dropdown-item
              v-for="field in availableFields(segment)"
              :key="field"
              :value="field"
            >
              {{ field }}
            </dropdown-item>
          </dropdown>
          <div class="pill">
            of
          </div>
        </div>
        <div class="multi-select-row">
          <el-switch
            :model-value="segment.highlightOutliers"
            :disabled="!segment.enabled"
            @change="(val) => {
              updateSegment({ highlightOutliers: val })
              $emit('track:outlier', val)
            }"
          ></el-switch>
          <span class="switch-text">Highlight Outliers</span>
        </div>
        <div class="multi-select-row">
          <el-select
            :model-value="selectedQueries(segment)"
            value-key="name"
            :disabled="!segment.enabled"
            :multiple="true"
            placeholder="Search themes..."
            :filterable="true"
            :popper-append-to-body="false"
            @change="updateSegment({ queries: $event.map(q => q.id) })"
          >
            <div class="select-all-buttons">
              <button
                @click.prevent="updateSegment({ queries: savedQueries.map(q => q.id) })"
              >
                Select all
              </button>
              <button
                @click.prevent="updateSegment({ queries: [] })"
              >
                Deselect all
              </button>
            </div>
            <hr />
            <el-option
              v-for="q in sortedThemes"
              :key="q.id"
              :label="getThemeLabel(q)"
              :value="q"
            />
          </el-select>
        </div>
      </div>
      <div v-if="segment.type === 'themeGroup'">
        <div class="selection-row">
          <div class="pill">
            Show
          </div>
          <dropdown
            :disabled="!segment.enabled"
            :bounded="true"
            class="dropdown"
            :class="{
              'orange': !segment.metric,
              'blue': !!segment.metric,
            }"
            @input="updateSegment({
              metric: {
                name: $event,
                agg_method: getAggMethod($event),
              }
            })"
          >
            <template #trigger>
              <div class="dropdown-trigger">
                {{ segment.metric?.name || segment.metric || 'Choose metric' }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
            </template>
            <dropdown-item
              v-for="field in availableFields(segment)"
              :key="field"
              :value="field"
            >
              {{ field }}
            </dropdown-item>
          </dropdown>
          <div class="pill">
            of
          </div>
        </div>
        <div class="multi-select-row">
          <el-switch
            :model-value="segment.highlightOutliers"
            :disabled="!segment.enabled"
            @change="(val) => {
              updateSegment({ highlightOutliers: val })
              $emit('track:outlier', val)
            }"
          ></el-switch>
          <span class="switch-text">Highlight Outliers</span>
        </div>
        <div class="multi-select-row">
          <el-select
            :model-value="selectedGroups(segment)"
            value-key="groupName"
            :disabled="!segment.enabled"
            :multiple="true"
            placeholder="Search theme groups..."
            :filterable="true"
            :popper-append-to-body="false"
            @change="updateSegment({ groupIds: $event.map(g => g.id) })"
          >
            <div class="select-all-buttons">
              <button
                @click.prevent="updateSegment({ groupIds: sortedThemeGroups.map(g => Number(g.id)) })"
              >
                Select all
              </button>
              <button
                @click.prevent="updateSegment({ groupIds: [] })"
              >
                Deselect all
              </button>
            </div>
            <hr />
            <el-option
              v-for="g in sortedThemeGroups"
              :key="g.id"
              :label="g.groupName"
              :value="g"
            />
          </el-select>
        </div>
      </div>
      <div
        v-if="segment.type === 'themeGroup' && segment.enabled && !isSegmentValid(segment)"
        class="error-text"
      >
        Complete or deselect this section
      </div>
      <div
        v-if="segment.type === 'mostChangedThemes' && segment.enabled && !isSegmentValid(segment)"
        class="error-text"
      >
        Complete or deselect this section
      </div>
      <div v-if="segment.type === 'mostChangedThemes'">
        <div class="selection-row">
          <div class="pill">
            Show
          </div>
          <div class="pill">
            Theme outliers by
          </div>
          <dropdown
            :disabled="!segment.enabled"
            :bounded="true"
            class="dropdown"
            :class="{
              'orange': !segment.metric,
              'blue': !!segment.metric,
            }"
            @input="updateSegment({
              metric: {
                name: $event,
                agg_method: getAggMethod($event),
              }
            })"
          >
            <template #trigger>
              <div class="dropdown-trigger">
                {{ segment.metric?.name || segment.metric || 'Choose metric' }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
            </template>
            <dropdown-item
              v-for="field in availableFields(segment)"
              :key="field"
              :value="field"
            >
              {{ field }}
            </dropdown-item>
          </dropdown>
        </div>
        <div class="multi-select-row">
          <el-switch
            :model-value="segment.onlyShowOutliers"
            :disabled="!segment.enabled"
            @change="(val) => {
              updateSegment({ onlyShowOutliers: val})
              $emit('track:onlyShowOutliers', val)
            }"
          ></el-switch>
          <span class="switch-text">Only include significant changes</span>
          <el-switch
            :model-value="segment.returnSummary"
            :disabled="!segment.enabled"
            @change="(val) => {
              updateSegment({ returnSummary: val})
              $emit('track:returnSummary', val)
            }"
          ></el-switch>
          <span class="switch-text">Include Summary - AI Generated</span>
          <el-switch
            :model-value="segment.showSampleVerbatims"
            :disabled="!segment.enabled"
            @change="(val) => {
              updateSegment({ showSampleVerbatims: val})
              $emit('track:sampleVerbatims', val)
            }"
          ></el-switch>
          <span class="switch-text">Include Sample Verbatims</span>
        </div>
      </div>
      <div
        v-if="segment.type === 'verbatim' && segment.enabled && !isSegmentValid(segment)"
        class="error-text"
      >
        Complete or deselect this section
      </div>
      <div v-if="segment.type === 'verbatim'">
        <div class="selection-row">
          <div class="pill">
            Show
          </div>
          <dropdown
            :disabled="!segment.enabled"
            :bounded="true"
            class="dropdown"
            :class="{
              'orange': !segment.numberVerbatims,
              'blue': !!segment.numberVerbatims,
            }"
            @input="updateSegment({ numberVerbatims: $event })"
          >
            <template #trigger>
              <div class="dropdown-trigger">
                {{ segment.numberVerbatims || 'Choose Number of Verbatims' }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
            </template>
            <dropdown-item
              v-for="n in [1, 2, 3, 4, 5]"
              :key="n"
              :value="n"
            >
              {{ n }}
            </dropdown-item>
          </dropdown>
          <div class="pill">
            of
          </div>
        </div>
        <div class="multi-select-row">
          <el-select
            :model-value="selectedQueries(segment)"
            value-key="name"
            :disabled="!segment.enabled"
            :multiple="true"
            placeholder="Search themes..."
            :filterable="true"
            :popper-append-to-body="false"
            @change="updateSegment({ queries: $event.map(q => q.id) })"
          >
            <div class="select-all-buttons">
              <button
                @click.prevent="updateSegment({ queries: savedQueries.map(q => q.id) })"
              >
                Select all
              </button>
              <button
                @click.prevent="updateSegment({ queries: [] })"
              >
                Deselect all
              </button>
            </div>
            <hr />
            <el-option
              v-for="q in savedQueries"
              :key="q.id"
              :label="q.name"
              :value="q"
            />
          </el-select>
        </div>
        <div class="multi-select-row">
          <el-switch
            :model-value="segment.includeSummary"
            :disabled="!segment.enabled"
            @change="(val) => {
              updateSegment({ includeSummary: val})
              $emit('track:summary', val)
            }"
          ></el-switch>
          <span class="switch-text">Include Summary - AI Generated</span>
        </div>
      </div>
      <div
        v-if="segment.type === 'theme' && segment.enabled && !isSegmentValid(segment)"
        class="error-text"
      >
        Complete or deselect this section
      </div>
      <div v-if="segment.type === 'custom'">
        <div class="selection-row">
          <div class="pill">
            Show
          </div>
          <dropdown
            :bounded="true"
            :disabled="!segment.enabled"
            class="dropdown"
            :class="{
              'orange': !segment.metric,
              'blue': !!segment.metric,
            }"
            @input="updateSegment({
              metric: {
                name: $event,
                agg_method: getAggMethod($event),
              }
            })"
          >
            <template #trigger>
              <div class="dropdown-trigger">
                {{ segment.metric?.name || segment.metric || 'Choose metric' }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
            </template>
            <dropdown-item
              v-for="field in availableFields(segment)"
              :key="field"
              :value="field"
            >
              {{ field }}
            </dropdown-item>
          </dropdown>
          <div class="pill">
            of
          </div>
          <dropdown
            :bounded="true"
            :disabled="!segment.enabled"
            :highlight-active="false"
            class="dropdown"
            :class="{
              'orange': !segment.field,
              'blue': !!segment.field,
            }"
            @input="updateSegment({ field: $event, segments: [] })"
          >
            <template #trigger>
              <div class="dropdown-trigger">
                {{ segment.field || 'Choose field' }}
                <i class="kapiche-icon-chevron-down"></i>
              </div>
            </template>
            <dropdown-item
              v-for="field in Object.keys(sortedSegmentsForFieldsLimited)"
              :key="field"
              :value="field"
            >
              {{ field }}
            </dropdown-item>
          </dropdown>
        </div>
        <div class="multi-select-row">
          <el-select
            :model-value="segment.segments"
            :disabled="!segment.enabled || !segment.field"
            :multiple="true"
            :placeholder="!segment.field? 'First select a field': 'Search segments...'"
            :filterable="true"
            :popper-append-to-body="false"
            @update:model-value="updateSegment({ segments: $event })"
          >
            <div class="select-all-buttons">
              <button
                @click.prevent="updateSegment({ segments: sortedSegmentsForFieldsLimited[segment.field] })"
              >
                Select all
              </button>
              <button
                @click.prevent="updateSegment({ segments: [] })"
              >
                Deselect all
              </button>
            </div>
            <hr />
            <el-option
              v-for="s in sortedSegmentsForFieldsLimited[segment.field]"
              :key="s"
              :label="s || '(No Value)'"
              :value="s"
            />
          </el-select>
        </div>
      </div>
      <div
        v-if="segment.type === 'custom' && segment.enabled && !isSegmentValid(segment)"
        class="error-text"
      >
        Complete or deselect this section
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComputedRef, PropType, computed, defineComponent, inject } from 'vue'

import ToggleCheckbox from 'components/widgets/ToggleCheckbox.vue'
import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { DefaultSegmentConfig, isSegmentValid } from './EmailDigestModal.utils'
import { CustomSegment, MetricSegment, Segment, ThemeSegment, MostChangedThemesSegment, VerbatimSegment, ThemeGroupSegment } from 'types/components/EmailDigest.types'
import { SavedQuery } from 'src/types/Query.types'
import FormatUtils from 'src/utils/formatters'
import { flattenThemeGroups, themeSorterWithGroups, Group } from 'src/pages/dashboard/Dashboard.utils'
import { useStore } from 'src/store'

const EmailDigestModalSection = defineComponent({
  components: {
    ToggleCheckbox,
    Dropdown,
    DropdownItem,
  },
  props: {
    segment: { type: Object as PropType<Segment>, required: true },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    savedQueries: { type: Array as PropType<SavedQuery[]>, required: true },
    sortedSegmentsForFieldsLimited: { type: Object, required: true },
    hasNps: { type: Boolean, default: false, required: false },
    hasSentiment: { type: Boolean, default: false, required: false },
  },
  setup (props) {
    const store = useStore()
    const themeToGroupNameMap = inject<ComputedRef<Record<number, string>>>('themeToGroupNameMapById', computed(() => ({})))
    const themeGroups = computed<Group[]>(() => store.getters['themeGroups'])

    const flattenedThemeGroups = computed(() => {
      // create a flat list of group with Object with ids as keys and group names as values.
      const themeGroupMap: Record<number, string> = {}
      const groupList = flattenThemeGroups(themeGroups.value ?? [])
      groupList.forEach((g) => {
        themeGroupMap[g.id] = g.name
      })
      return themeGroupMap
    })

    const getThemeLabel = (query: SavedQuery): string => {
      if (themeToGroupNameMap.value[query.id]) {
        return `${FormatUtils.truncate(query.name, 30)} [${FormatUtils.truncate(themeToGroupNameMap.value[query.id], 30)}]`
      }
      return FormatUtils.truncate(query.name, 60)
    }

    const sortedThemes = computed(() => {
      return themeSorterWithGroups(props.savedQueries, themeToGroupNameMap.value)
    })

    const sortedThemeGroups = computed(() => {
      return Object.entries(flattenedThemeGroups.value)
        .map(g => {return {id: g[0], groupName: g[1]}})
        .sort((g1, g2) =>  g1.groupName.localeCompare(g2.groupName))
    })

    return {
      themeToGroupNameMap,
      getThemeLabel,
      sortedThemes,
      flattenedThemeGroups,
      sortedThemeGroups,
    }
  },
  data () {
    return {
      segmentConfig: DefaultSegmentConfig,
    }
  },
  methods:{
    isSegmentValid: isSegmentValid,
    updateSegment (attrs: Partial<Segment>): void {
      this.$emit('update:segment', {
        ...this.segment,
        ...attrs,
      })
    },
    availableFields (segment: MetricSegment|ThemeSegment|MostChangedThemesSegment|VerbatimSegment|CustomSegment): string[] {
      let options = this.schema
        .filter((field) => {
          if (!['NUMBER', 'SCORE'].includes(field.typename)) {
            return false
          }

          if (segment.type === 'metric') {
            return !segment.metrics.find((s) => s.name === field.name)
          }
          return true
        })
        .map((field) => field.name)

      const extraOptions = []

      if (this.hasNps) {
        if (segment.type === 'metric') {
          extraOptions.push(
            'NPS',
          )
        } else {
          extraOptions.push(
            'NPS',
            'NPS Impact'
          )
        }
      }

      if (this.hasSentiment) {
        extraOptions.push(
          'Positive Sentiment',
          'Negative Sentiment',
          'Neutral Sentiment',
          'Mixed Sentiment',
        )
      }

      if (['theme', 'themeGroup', 'mostChangedThemes', 'custom'].includes(segment.type)) {
        extraOptions.push(
          'Frequency (#)',
          'Frequency (%)',
        )
      }

      options.push(
        ...extraOptions.filter((name) => {
          if (segment.type === 'metric') {
            return !segment.metrics.find((s) => s.name === name)
          }
          return segment.metric !== name
        })
      )

      // Adding a divider between extraOptions and options
      options = Array.from(new Set(extraOptions.concat(options)))

      return options
    },
    selectedQueries (segment: ThemeSegment|VerbatimSegment): SavedQuery[] {
      return this.savedQueries.filter(sq => {
        return segment.queries.includes(sq.id)
      })
    },
    selectedGroups (segment: ThemeGroupSegment): Record<number, string>[] {
      return  this.sortedThemeGroups.filter(g => {
        return segment.groupIds.includes(g.id)
      })
    },
    getAggMethod (fieldName: string) {
      const column = this.schema.find((col) => col.name === fieldName)
      return column?.typename === 'SCORE' ? 'score' : 'average'
    },
  }
})

export default EmailDigestModalSection
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.segment
  display: flex
  background: #fff
  border: 1px solid $grey
  padding: 16px
  border-radius: 4px
  margin-bottom: 16px
  align-items: center

  &.enabled
    border-color: $blue

  &.invalid
    border-color: $red
    border-width: 2px

  > div:nth-child(1)
    flex: 0

  .toggle-checkbox
    padding: 15px

.selection-row
  display: flex
  flex-wrap: wrap
  align-items: baseline

.multi-select-row
  width: 100%
  margin-top: 10px

  ::v-deep .el-select
    width: 100%
  .switch-text
    color: $grey-dark
    font-style: italic
    margin-left: 0.5rem
    margin-right: 0.5rem
  ::v-deep .el-tag.el-tag--info
    background-color: $green
    color: $white
  ::v-deep .el-tag__close.el-icon-close
    background-color: $green
    color: $white

.pill
  padding: 2px 8px
  border-radius: 3px
  color: $text-grey
  font-size: 15px
  margin-right: 6px
  border: 1px solid $grey
  user-select: none
  background: $grey-light

.dropdown
  padding: 2px 8px
  border-radius: 3px
  color: $text-grey
  font-size: 15px
  cursor: pointer
  margin-right: 6px
  border: 1px solid $text-grey
  user-select: none
  line-height: 1.5

  &.disabled
    opacity: 0.6
    cursor: default

  &.blue
    background: $blue
    border-color: $blue
    color: #fff

  &.orange
    background: $orange
    border-color: $orange
    color: #fff

  &.white
    border: 1px solid #95a6ac
    color: #95a6ac

  .dropdown-trigger
    i
      font-size: 8px
      margin-left: 4px

.segment-title
  font-size: 16px
  font-weight: 600

.segment-description
  margin: 5px 0

.error-text
  margin-top: 5px

.select-all-buttons
  display: flex
  justify-content: space-evenly

  button
    text-transform: uppercase
    margin: 1px
    border: solid 1px transparent
    padding: 0.1em 0.7em 0.2em
    color: #a8a8a8
    font-size: 12px
    font-weight: bold
    color: $text-grey
    &:hover
      cursor: pointer
      color: #068ccc
</style>
