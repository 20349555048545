<template>
  <div v-if="loading" class="wrapper">
    <bf-spinner />
  </div>
  <div v-else class="v-flex-container">
    <TopBar :show-log-out="true" />
    <div class="v-flex-container-content">
      <div v-if="badDomain" class="redirect-message">
        <h3>
          Could not access site:<br />{{ badDomain }}
        </h3>
      </div>
      <div v-if="membershipDisabled" class="redirect-message">
        <h3>
          Your membership to:<br />{{ membershipDisabled }}<br />has been disabled.
          Please contact your site admin to restore your access.
        </h3>
      </div>
      <div class="ui text container narrow">
        <h1 class="ui header centered" style="font-weight: bolder">
          Welcome, {{ userName }}
        </h1>
        <div v-if="currentUser && currentUser.is_staff">
          <h3 style="text-align: center">
            Staff Actions
          </h3>
          <p class="log-out-button staff-only" @click="createSite">
            [+] Create a new site
          </p>
        </div>
        <h3 v-if="!availableSites.length" style="text-align: center">
          You have not been added to any sites yet. Please contact Kapiche
          for more information.
        </h3>
        <h3 v-else style="text-align: center">
          Your account can access the sites below:
        </h3>
        <div
          v-for="site in availableSites"
          :key="site.domain"
          class="ui clearing padded segment"
          :class="{'disabled': site.disabled}"
        >
          <span class="left floated">{{ site.site_name }}</span>
          <router-link v-if="!site.disabled" :to="{ name: 'home', params: { site: site.domain }}" class="right floated">
            Launch
          </router-link>
          <span v-else class="right floated">
            (Disabled)
          </span>
          <a
            v-if="currentUser && currentUser.is_staff"
            class="right floated negative"
            style="margin-right: 1rem;"
            href=""
            @click.prevent="deleteSiteModal(site)"
          >
            Delete
          </a>
        </div>
      </div>
      <bf-modal :visible="showDeleteModal" @close="closeDeleteModal">
        <bf-dialog @close="closeDeleteModal">
          <div class="delete_dialog">
            <h2>
              Do you want to delete the "{{ siteToDelete && siteToDelete.site_name }}" site?
            </h2>
            <p>
              This action cannot be reversed. All data for this site will be lost.
              It may be a good idea to have a second person sanity check whether this is the intended site to delete.
            </p>
            <div class="buttons">
              <bf-button size="big" @click="closeDeleteModal">
                No
              </bf-button>
              <bf-button color="red" size="big" @click="deleteSite(siteToDelete)">
                Yes, delete the site
              </bf-button>
            </div>
          </div>
        </bf-dialog>
      </bf-modal>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import { BfModal, BfDialog, BfButton, BfSpinner } from 'components/Butterfly'
  import { FETCH_SITES } from 'src/store/types'
  import Site from 'src/api/site'
  import TopBar from './TopBar.vue'
  import Project from 'src/api/project'

  export default defineComponent({
    components: { TopBar, BfModal, BfDialog, BfButton, BfSpinner },
    props: {
      verification: { type: Boolean, default: false },
      badDomain: { type: String, default: '' },
      membershipDisabled: { type: String, default: '' },
    },
    data () {
      return {
        siteToDelete: null,
        showDeleteModal: false,
        loading: true,
        checkedRedirect: false,
      }
    },
    computed: {
      ...mapGetters([
        'availableSites', 'userName', 'currentUser',
      ]),
    },
    watch: {
      availableSites (v) {
        if (v.length > 0) {
          this.checkRedirect()
        } else if (v !== undefined) {
          this.loading = false
        }
      },
    },
    metaInfo () {
      return {
        title: 'Sites - Kapiche'
      }
    },
    methods: {
      ...mapActions({
        FETCH_SITES,
      }),
      async checkRedirect () {
        if (this.loading && !this.checkedRedirect) {
          this.checkedRedirect = true
          // Check whether a newly signed up viewer user should be
          // redirected straight to their dashboard. The user must
          // be on the /verified route after verifying their email.
          // User has access to 1 site as a viewer
          if (this.verification &&
              this.availableSites?.length === 1 &&
              this.availableSites?.[0].disabled == false &&
              this.availableSites?.[0].user_type === 'VIEWER'
          ) {
            const [ site ] = this.availableSites
            const { data } = await Project.getAllDashboardsForSite(site.domain)

            // User has access to 1 dashboard as a viewer
            if (data.length === 1) {
              const dashboardURL = `/${site.domain}/dashboards/${data[0].url_code}`
              this.$router.push(dashboardURL)
            } else {
              this.loading = false
            }
          // We don't want to force the user to click on their site if there's
          // only one option. We know which site, so go straight there.
          } else if (
              this.availableSites?.length === 1 &&
              this.availableSites?.[0].disabled == false
            ) {
              const [ site ] = this.availableSites
              this.$router.push({ name: 'home', params: { site: site.domain }})
          } else {
              this.loading = false
          }
        }
      },
      createSite () {
        this.$router.push({ name: 'create-site-form' })
      },
      deleteSiteModal (site) {
        this.siteToDelete = site
        this.showDeleteModal = true
      },
      closeDeleteModal () {
        this.showDeleteModal = false
        this.siteToDelete = null
      },
      async deleteSite (site) {
        this.closeDeleteModal()
        await Site.deleteSite(site.domain)
        this.FETCH_SITES()
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import "assets/kapiche.sass"

  .v-flex-container
    height: 100%
    display: flex
    flex-direction: column
    overflow-y: hidden

    .v-flex-container-content
      flex: 1 1 0
      overflow-y: auto
      padding-top: 8rem

  .negative
    color: $red

  .ui.top.menu.fixed
    z-index: 10 !important
    height: 5rem

    .item.borderless.logo
      width: 150px

  .floated
    &.left
      float: left
    &.right
      float: right

  .right-header
    display: flex
    margin-left: auto
    padding: 10px
    align-items: center

  .log-out-button
    margin: 20px
    color: grey
    // Button elements do not automatically inherit font-family
    // from the `body` styles.
    /*font-family: 'Lato', Arial, Helvetica, sans-serif*/
    /*font-size: 16px*/
    cursor: pointer
    &:focus
      outline: none
    &:hover
      color: $blue
  .ui.text.container.narrow
    max-width: 500px !important
    padding-bottom: 50px

  .delete_dialog
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding: 25px
    h2
      font-size: 30px
    p
      text-align: center
    .buttons
      padding: 10px 0 10px
      button
        &:first-child
          margin-right: 20px

  .wrapper
    display: flex
    justify-content: center
    align-items: center
    height: 100%

  .redirect-message
    text-align: center
    position: relative
    top: -4rem
    width: 933px
    margin-left: auto
    margin-right: auto

</style>
