import md5 from 'md5'
import { FETCH_DATA, SET_FETCH_START, SET_FETCH_DONE, SET_FETCH_ERRORED, FETCH_DATA_QUERY, FETCH_DATA_CONTEXT_NETWORK } from './types'
import { fetch_pivot_data } from './api'
import { FAILURE } from 'src/store/types'
import Query from 'src/api/query'
import { Store } from 'vuex'
import { DataState } from './state'
import { hasSubQueries } from 'src/utils/query'
import { Requirements } from 'src/types/PivotData.types'
import { DashboardFilterType } from 'src/types/DashboardFilters.types'

export const makeCacheKey = (values: any[]) => md5(values.map(v=>JSON.stringify(v)).join())
const isInCache = (state: DataState, key: keyof DataState) => state[key] !== undefined

/**
 * Determines whether we should use the cache for this key/state
 * true if
 *  - the key is in the cache
 *  - AND not forcing OR expired OR in error*
 */
export const useCache = (state: DataState, key: keyof DataState, now: number, force: boolean, timeout=300000) => {
  const inCache = isInCache(state, key)
  const expired = inCache && now - (state[key].startTime ?? 0) > timeout
  const inerror = inCache && state[key].error
  return inCache && !(force || expired || inerror)
}

interface FetchDataParamsType {
  projectId: number
  analysisId?: number
  dashboardId?: number
  topicId: number
  chrysalisRef: string
  chrysalis_url: string
}

type KeyExtrasType = Array<unknown>

interface FetchDataParamType {
  requirements: Requirements
  params: FetchDataParamsType
  filters?: DashboardFilterType[]
  force?: boolean
  keyExtras?: KeyExtrasType
  fetch_method?: typeof fetch_pivot_data
}

interface FetchDataContextNetworkParamsType extends FetchDataParamsType {
  analysisId: number
}

interface FetchDataContextNetworkParams {
  params: FetchDataContextNetworkParamsType
  analysisModified: number
  query: any
  options: any
  savedQueries: any[]
  force: boolean
}

const actions: Record<string, (store: Store<DataState>, ...args: any[]) => any> = {
  async [FETCH_DATA] (
    { commit, state },
    { requirements, filters = [], force = false, params, keyExtras = [], fetch_method = fetch_pivot_data }: FetchDataParamType
  ) {
    const key = makeCacheKey([
      params,
      requirements,
      filters,
      ...keyExtras
    ])
    const timenow = Date.now()
    // should we use the cache? if so just return key
    if (useCache(state, key, timenow, force)) return Promise.resolve(key)
    // otherwise we have work to do
    commit(SET_FETCH_START, {key, time: timenow})

    // check that required params have been provided or return error
    const { projectId, analysisId, topicId, chrysalisRef, chrysalis_url } = params
    if (!projectId || !analysisId || !topicId || !chrysalisRef || !chrysalis_url) {
      const userError = 'Unexpected error: Failed to fetch data due to missing parameters' // njsscan-ignore: node_username
      const error = new Error(`${userError}: ${JSON.stringify(params)}`)
      commit(SET_FETCH_ERRORED, { key, error, userError })
      return Promise.resolve(key)
    }

    // check that queries have been expanded or return error
    if (requirements?.queries && hasSubQueries(requirements.queries.map(q => q.value))) {
      const userError = 'Unexpected error: Queries not expanded' // njsscan-ignore: node_username
      const error = new Error(userError)
      commit(SET_FETCH_ERRORED, { key, error, userError })
      return Promise.resolve(key)
    }

    fetch_method(params, requirements, filters)
      .then((result) => {
        commit(SET_FETCH_DONE, { key, result })
      }).catch((error: any) => {
        let userError
        if (error.status && error.statusText) {
          userError = `Network Error: ${error.status} ${error.statusText}`
        } else {
          userError = error instanceof Error ? (error as any).msg : 'unknown error'
        }
        commit(SET_FETCH_ERRORED, { key, error, userError })
        commit(FAILURE, error)
      })

    return Promise.resolve(key)
  },
  async [FETCH_DATA_QUERY] (
    { commit, state, getters },
    { projectId, analysisId, analysisModified, query, options, force }
  ) {
    const key = makeCacheKey([
      projectId,
      analysisId,
      analysisModified,
      query,
      'run_query',
      options,
    ])
    const timenow = Date.now()
    // should we use the cache? if so just return key
    if (useCache(state, key, timenow, force)) return Promise.resolve(key)
    commit(SET_FETCH_START, {key, time: timenow})

    // sub-query expansion is handled by the endpoint called by runQuery
    Query.runQuery(projectId, analysisId, query, getters.savedQueries, {
      ...options
    })
    .then((result) => {
      commit(SET_FETCH_DONE, {key, result})
    }, (error) => {
      commit(SET_FETCH_ERRORED, { key, error, userError: error.toString() })
    })
    return Promise.resolve(key)
  },
  async [FETCH_DATA_CONTEXT_NETWORK] (
    { commit, state },
    { params, analysisModified, query, options, savedQueries, force = false }: FetchDataContextNetworkParams
  ) {
    const { projectId, analysisId, topicId, chrysalisRef, chrysalis_url } = params

    const key = makeCacheKey([
      projectId,
      analysisId,
      analysisModified,
      query,
      'generate_context_network',
      options,
    ])
    const timenow = Date.now()
    // should we use the cache? if so just return key
    if (useCache(state, key, timenow, force)) return Promise.resolve(key)
    commit(SET_FETCH_START, {key, time: timenow})

    // this function handles sub-query expansion itself
    Query.generateContextNetwork(projectId, analysisId, chrysalis_url, chrysalisRef, topicId, query, savedQueries, options)
    .then((result: any) => {
      commit(SET_FETCH_DONE, {key, result})
    }).catch ((error: any) => {
      commit(SET_FETCH_ERRORED, { key, error, userError: error.toString() })
    })
    return Promise.resolve(key)
  },
}

export default actions
