import Vue from 'vue'
import HTTPRetryUtil from 'src/utils/httpretry'
import { Label, LabelParams } from 'pages/ProjectLabels/ProjectLabels.util'
import { Results } from './api.types'


const LabelsAPI = {
  getLabels (
    pageN: number | undefined = undefined,
    searchByName = '',
    pageSize: number | undefined = undefined,
  ): PromiseLike<Results<Label>> {
    const params: LabelParams = {}

    if (pageN !== undefined)
      params.page = `${pageN}`

    if (searchByName !== '') {
      params.name = searchByName
    }

    if (pageSize !== undefined) {
      params.page_size = `${pageSize}`
    }

    const searchParams = new URLSearchParams(params)
    return HTTPRetryUtil.retry(`labels/?${searchParams.toString()}`)
  },
  updateLabel (id: number, attrs: Partial<Label>): PromiseLike<Label> {
    return HTTPRetryUtil.post(`labels/${id}/`, { body: attrs})
  },
  deleteLabel (id: number): PromiseLike<void> {
    return Vue.http.delete(`labels/${id}/`).then(
      () => Promise.resolve(),
      (error) => Promise.reject(error),
    )
  },
  createLabel (attrs: Omit<Label, 'id'>): PromiseLike<Label> {
    return Vue.http.post('labels/', attrs).then(
      (response) => Promise.resolve(response.json()),
      (error) => Promise.reject(error),
    )
  }
}

export default LabelsAPI
