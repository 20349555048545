<template>
  <div class="dynamic-highlight">
    <span v-for="(part, index) in processedText" :key="index">
      <span v-if="part.isHighlighted" class="highlight">{{ part.text }}</span>
      <span v-else>{{ part.text }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'DynamicHighlight',
  props: {
    text: { type: String, required: true },
    start: {type: Number, required: true },
    end: {type: Number, required: true },
  },
  computed: {
    processedText () {
      const beforeHighlight = this.text.slice(0, this.start)
      const highlighted = this.text.slice(this.start, this.end)
      const afterHighlight = this.text.slice(this.end)

      const parts = [
        { text: beforeHighlight, isHighlighted: false },
        { text: highlighted, isHighlighted: true },
        { text: afterHighlight, isHighlighted: false },
      ]

      return parts.filter(part => part.text.length > 0)
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.highlight
  background-color: rgba(255, 255, 0, 0.7)

</style>
