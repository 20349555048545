export interface QueryElement {
  field?: string
  type: string
  operator?: string
  value?: string | string[]
  is_date?: boolean
  values?: string[]
  id?: string
}

export interface QueryType {
  includes?: QueryType[]
  excludes?: QueryType[]
  type: string
  value?: string
  operator?: string
  is_date?: boolean
  field?: string
  level?: "frame" | "sentence"
  id?: string
}

// SavedQuery type returned from `GET/projects/[id]/analysis/[id]/saved-query/`
export interface SavedQuery {
  id: number
  name: string
  description: string
  created: string // ISO timestamp
  modified: string // ISO timestamp
  project: number
  analysis: number
  query_value: QueryType
  is_new?: boolean
  dashboard_ids?: number[]
  exclude_mapped: boolean
  theme_group: number | null
}

// SavedQuery type returned inside { queries } body from `GET/projects/dashboards/11/`
export interface DashboardSavedQuery {
  id: number | string
  name: string
  description?: string
  query_value: QueryType
}

export interface ChrysalisQueryType {
  name: string
  value: QueryType
}

type Operator = 'is'
              | 'is not'
              | 'is greater than'
              | 'is greater than or equal to'
              | 'is less than'
              | 'is less than or equal to'
              | 'includes'
              | 'does not includes'
              | 'is in the range'
              | 'is not in the range'

export interface QueryRow {
  operator: Operator
  values: Array<string | number>
  field?: string
  type: string
  is_date?: boolean
}

export type QueryBuilderQuery = QueryRow[]

export interface SynonymType {
  name: string,
  frequency: number,
  similarity: number
}


export enum QueryLocation {
  ThemeBuilder = 'Theme Builder',
  Dashboard = 'Dashboard',
  QueryPage = 'Query',
}
