/**
 * All interactions with the /auth endpoints are done here.
 */
import Vue from 'vue'
import HTTPRetryUtil from 'src/utils/httpretry'
import { UserProfile } from 'src/types/UserTypes'

export default {
  /**
   * Login to the server.
   * @param {string} email required.
   * @param {string} password required.
   * @returns {Promise}
   */
  login (email: string, password: string): PromiseLike<unknown> {
    return Vue.http.post('auth/login/', { email, password })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Fetch the information for the currently logged in user.
   * @returns {Promise}
   */
  async getUser (): Promise<unknown> {
    // This count is slightly longer than the default because there are
    // harsh consequences for failing: the user will get logged out.
    // See in modules/auth: [types.FETCH_USER] ({ commit })
    return await HTTPRetryUtil.retry('auth/user/', { count: 30 } as any)
  },

    /**
   * Fetch the profile information for the currently logged in user.
   * @returns {Promise}
   */
     async getUserProfile (): Promise<UserProfile> {
      // This count is slightly longer than the default because there are
      // harsh consequences for failing: the user will get logged out.
      // See in modules/auth: [types.FETCH_USER] ({ commit })
      return await HTTPRetryUtil.retry('auth/user/profile', { count: 30 } as any)
    },

  /**
   * Fetch a paginated list list of users for this site
   *
   * @param {string} pageNumber
   * @param {string} query
   * @returns {Promise}
   */
  getUserListPaginate (pageNumber: string, query: string): PromiseLike<unknown> {
    return Vue.http.get('auth/users/', { params: { page: pageNumber, q: query }})
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Fetch a list of users for this site
   *
   * @param {string} query
   * @returns {Promise}
   */
  getUserList (query: string): PromiseLike<{ results: UserProfile[]}> {
    return Vue.http.get('auth/users/', { params: { q: query }})
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Update the current user
   * @param {Object} user - The user object required
   * @returns {Promise}
   */
  updateUser (user: Record<string, unknown>): PromiseLike<unknown> {
    return Vue.http.patch('auth/user/', user)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Change the password for a user
   * @param {string} old_password required.
   * @param {string} new_password1 required.
   * @param {string} new_password2 required.
   * @returns {Promise}
   */
  changePassword (old_password: string, new_password1: string, new_password2: string): PromiseLike<unknown> {
    return Vue.http.post('auth/password/change/', { old_password, new_password1, new_password2 })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Logout.
   * @returns {Promise}
   */
  logout (): PromiseLike<unknown> {
    return Vue.http.post('auth/logout/')
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Register user.
   *
   * @typedef {Object} data
   * @param {string} [email]
   * @param {string} [first_name]
   * @param {string} [last_name]
   * @param {string} [password1]
   * @param {string} [password2]
   * @param {number} [dashboard_id]
   * @param {Object} [auth0User] auth0 user object
   * @param data {Object} The data to send with the request.
    */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  register (data: Record<string, unknown>, use_v2=false): PromiseLike<unknown> {
    const url = `auth/registration/${use_v2?'v2/':''}`
    return Vue.http.post(url, data, {
        // sc-26446: we remove the authorization headers here because it's a non-authenicated
        // request and if an auth0 social signup is being done this will confuse botanic as it
        // will try to find the user matching the token and it doesn't exist yet
        before:  (request) => {
          request.headers.delete('Authorization')
        }
      })
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Confirm a users email address.
   * @param data {Object} the data to send with the request.
   * @param {string} data.email The key used for the confirmation. Required.
   */
  confirmEmail (data: Record<string, unknown>): PromiseLike<unknown> {
    return Vue.http.post('auth/confirm/', data)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Request a new confirmation email for a users email address.
   * @param email {String} the email address we wish to confirm
   */
  async reconfirmEmail (email: string): Promise<unknown> {
    return await Vue.http.post('auth/re-confirm/', { email })
  },

  /**
   * Request a password reset.
   * @param data {Object} The data to send with the request.
   * @param {string} data.email The email address for the account.
   */
  requestPasswordReset (data: Record<string, unknown>): PromiseLike<unknown> {
    return Vue.http.post('auth/password/reset/', data)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  },

  /**
   * Reset password.
   * @param data {Object} the data to send with the request.
   * @param {string} data.new_password1 The new password.
   * @param {string} data.new_password2 The new password.
   * @param {string} data.uid uid from the reset email.
   * @param {string} data.token token from the email.
   */
  resetPassword (data: Record<string, unknown>): PromiseLike<unknown> {
    return Vue.http.post('auth/password/reset/confirm/', data)
      .then(
        (response) => Promise.resolve(response.json()),
        (error) => Promise.reject(error)
      )
  }
}
