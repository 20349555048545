<!--
  This component represents the top-level query box
  where a user can type anything to start searching.
-->
<template>
  <div>
    <div class="search-bar">
      <!-- Search input and item selector -->
      <div class="search-input-container" @click="focusSearch">
        <!-- Search input with tag list -->
        <div class="search-input" :class="focused ? 'focused' : ''">
          <i class="icon search"></i>
          <input
            ref="searchInput"
            v-model="searchText"
            type="search"
            class="search-text"
            :placeholder="placeholderText"
            @keyup.enter="doManualSearch()"
            @keyup.esc="searchText = ''"
            @input="onInput"
          >
        </div>

        <!-- Query popover that allows free text search/matching and item selection -->
        <div class="query-popover" :class="focused ? 'active' : ''" :style="{ left: popoverLeft() + 'px' }">
          <!-- Free text search -->
          <div v-if="searchTextClean.length > 0">
            <!-- Explicit search -->
            <div v-if="searchTextClean.startsWith('&quot;')">
              Press enter to query for exact matches of <strong><i>{{ searchTextClean }}</i></strong>
            </div>

            <!-- Conceptual search (variant matching) -->
            <div v-else>
              Press enter to query for {{ wordOrPhrase }} <strong>{{ searchTextClean }}</strong>
            </div>

            <!-- Automatic search suggestions based on typed text -->
            <div v-if="hasSuggestions" class="search-suggestions">
              <ul v-if="searchSuggestions.fields.shortlist.length > 0">
                <li class="fields-header">
                  Fields
                </li>
                <li
                  v-for="suggestion in searchSuggestions.fields.shortlist" :key="`${suggestion.field}-${suggestion.segment}`" v-truncate="80"
                  @click.stop="selectSegmentWithField(suggestion.field, suggestion.segment)"
                >
                  {{ Object.values(suggestion).join(': ') }}
                </li>
                <div v-if="searchSuggestions.fields.remaining" class="grey">
                  and {{ number(searchSuggestions.fields.remaining) }} more fields...
                </div>
              </ul>
              <ul v-if="searchSuggestions.concepts.shortlist.length > 0">
                <li class="concepts-header">
                  Concepts
                </li>
                <li v-for="concept in searchSuggestions.concepts.shortlist" :key="concept" @click.stop="selectConcept(concept)">
                  <span v-if="concept" :style="'color:' + currentModel.conceptColours[concept]" class="concept-dot">●</span>{{ concept }}
                </li>
                <div v-if="searchSuggestions.concepts.remaining" class="grey">
                  and {{ number(searchSuggestions.concepts.remaining) }} more concepts...
                </div>
              </ul>
              <ul v-if="searchSuggestions.queries.shortlist.length > 0">
                <li class="concepts-header">
                  Themes
                </li>
                <li v-for="query in searchSuggestions.queries.shortlist" :key="query.id" @click.stop="selectQuery(query)">
                  {{ query.name }}
                </li>
                <div v-if="searchSuggestions.queries.remaining" class="grey">
                  and {{ number(searchSuggestions.queries.remaining) }} more queries...
                </div>
              </ul>
            </div>
          </div>

          <!-- Item selection -->
          <template v-else>
            <ul>
              <li v-if="hasStructured" :class="state === 'fields' ? 'active' : ''" @click="state = 'fields'">
                Fields
              </li>
              <li v-else class="no-fields disabled">
                Fields (No Data)
              </li>
              <li :class="state === 'concepts' ? 'active' : ''" @click="state = 'concepts'">
                Concepts
              </li>
              <li :class="state === 'queries' ? 'active' : ''" @click="state = 'queries'">
                Themes
              </li>
              <li :class="state === 'operators' ? 'active' : ''" @click="state = 'operators'">
                Operators
              </li>
            </ul>

            <!-- Query items -->
            <div class="query-items" :class="selectedField ? 'field-values' : ''">
              <ul v-if="state === 'fields'" class="query-fields">
                <li v-for="field in allFields" :key="field.name" @click.stop="selectField(field)">
                  <span :title="field.name">{{ field.name }}</span>
                </li>
              </ul>
              <ul v-else-if="state === 'concepts'" class="query-concepts">
                <li
                  v-for="concept in sortedConcepts"
                  :key="concept"
                  @click.stop="selectConcept(concept)"
                >
                  <span :style="'color:' + currentModel.conceptColours[concept]" class="concept-dot">●</span>
                  <span v-truncate="40">{{ concept }}</span>
                </li>
              </ul>
              <template v-else-if="state === 'queries'">
                <ul v-if="savedQueries.length > 0" class="query-queries">
                  <li
                    v-for="query in savedQueries"
                    :key="query.id"
                    @click.stop="selectQuery(query)"
                  >
                    <span :title="query.name">{{ query.name }}</span>
                  </li>
                </ul>
                <div v-else class="no-queries">
                  <h2>No themes to show</h2>
                  <a :href="CONST.intercom_links.SAVE_A_QUERY" target="_blank">
                    Click here to learn about themes.
                  </a>
                </div>
              </template>
              <template v-else-if="state === 'operators'">
                <ul class="query-queries">
                  <li
                    v-for="operator in operators"
                    :key="operator.id"
                    @click.stop="selectOperator(operator)"
                  >
                    <span :title="operator.name">{{ operator.name }}</span>
                  </li>
                </ul>
              </template>
            </div>

            <!-- Help concent specific to currently viewed items -->
            <div v-if="!selectedField" class="query-item-help">
              <template v-if="state === 'fields'">
                Fields are the structured data assigned to each record (row), which identifies the columns in your dataset. Examples of fields: name, location, review_score.
                <br>NPS Category is automatically added as a field to your analysis, if appropriate data is detected. <a target="_blank" :href="CONST.intercom_links.FIELDS">Learn more about Fields.<img class="ui image logo" src="../../../../../assets/img/new-tab.png"></a>
              </template>
              <template v-else-if="state === 'concepts'">
                Concepts are terms found in your data that display characteristics and patterns that identify it as important within your data. <a target="_blank" :href="CONST.intercom_links.CONCEPTS">Learn more about Concepts.<img class="ui image logo" src="../../../../../assets/img/new-tab.png"></a>
                <br>Concepts are coloured by semantic groups (Concepts related to one another).
              </template>
              <template v-else-if="state === 'queries'">
                Themes should encapsulate a topic your customers are talking about, and include relates words, e.g a theme called "Entertainment" might include the terms <em>entertainment, movies, music</em>.
              </template>
              <template v-else-if="state === 'operators'">
                Operators can add custom filter operations that allow you to fine tune the results for a particular theme.
                <br><a target="_blank" :href="CONST.intercom_links.WORD_COUNT_OPERATOR">Learn more about available operators.<img class="ui image logo" src="../../../../../assets/img/new-tab.png"></a>
              </template>
            </div>
          </template>
        </div>
      </div>

      <!-- Saved queries -->
      <div v-if="!compare" class="saved-queries-container">
        <saved-queries ref="savedQueries" @query-deleted="bubbleQueryDeleted" @query-renamed="bubbleQueryRenamed" @query-saved="bubbleQuerySaved" @query-selected="bubbleQuerySelected"></saved-queries>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import Tooltip from 'tooltip.js'
  import { mapGetters } from 'vuex'

  import SavedQueries from './SavedQueries.vue'
  import Utils from 'src/utils/general'
  import FormatUtils from 'src/utils/formatters'
  import { debounce, isEmpty } from 'lodash'
  import log from 'loglevel'
  import escapeRegExp from 'escape-string-regexp'
  let logger = log.getLogger('QuerySelector')

  export default defineComponent({
    components: { SavedQueries },
    props: {
      hasQuery: { type: Boolean, default: false },
      compare: { type: Boolean, default: false }
    },
    data () {
      return {
        focused: false,  // search is focused/active flag
        state: null,  // set on 'created'
        selectedField: null,  // selected field to drill into
        searchText: '',  // current free text search value
        searchSuggestions: {
          fields: {
            shortlist: [],
            remaining: 0,
          },
          concepts: {
            shortlist: [],
            remaining: 0,
          },
          queries: {
            shortlist: [],
            remaining: 0,
          }
        },
        operators: [
          {"id": 1, "name": "Word Count"}
        ],
        field_lookups: {},
        searchSuggestionsDebounced: debounce(function () {
          if (isEmpty(this.field_lookups)) {
            // `field_lookups` hasn't yet been populated. Do nothing.
            return
          }
          this.searchSuggestions = this.calculateSearchSuggestions(
            this.field_lookups
          )
        }, 250),
        sentimentSegments: ['mixed', 'negative', 'neutral', 'positive']
      }
    },
    computed: {
      ...mapGetters([
        'currentModel', 'sortedSegmentsForFieldsUnlimited', 'sortedFieldsUnlimited', 'savedQueries',
        'featureFlags'
      ]),
      hasSuggestions () {
        return this.searchSuggestions.fields.shortlist.length +
               this.searchSuggestions.concepts.shortlist.length +
               this.searchSuggestions.queries.shortlist.length > 0
      },
      hasStructured () {
        return this.allFields.length > 0
      },
      // Alphabetically sorted view of concepts
      sortedConcepts () {
        return this.currentModel.hierarchy_concepts.slice(0).sort((c1, c2) => c1.localeCompare(c2))
      },
      // Alphabetically sorted view of all fields
      allFields () {
        return this.sortedFieldsUnlimited.concat(this.currentModel.dateFields || []).sort()
      },
      // Sanitised version of `searchText`
      searchTextClean () {
        return Utils.sanitisePhraseQuery(this.searchText)
      },
      // Returns label indicating if current values of `searchText` is a word or phrase.
      wordOrPhrase () {
        return this.searchTextClean.split(' ').length > 1 ? 'phrase' : 'word'
      },
      placeholderText () {
        if (this.focused) {
          return 'Start typing or select from below...'
        }
        return this.hasQuery ? 'Add to your query' : 'Click here to begin your query'
      }
    },
    created () {
      this.state = this.hasStructured ? 'fields' : 'concepts'

      // Construct a data structure for search suggestions
      //
      // When the user types into the search box, we offer a short list
      // of suggestions for items to add to the query. The data structure
      // built below aims to make it efficient to come up with that
      // shortlist of suggestions. Most of the time, the number of unique
      // segments per field is low. But sometimes in some large datasets
      // we can have several hundred thousand unique segment values per
      // field, and in this scenario it becomes costly to filter. Thus,
      // we build the data structure below to make those lookups a bit
      // faster.
      //
      // The final data structure created here (assigned later to
      // `this.field_lookups`) is a mapping of FIELD_NAME: SET OF BIGRAMS.
      let t0 = Date.now()
      const result = {}
      let count = 0
      for (const f of this.sortedFieldsUnlimited) {
        let segments
        if (f.name === 'sentiment') {
          segments = this.sentimentSegments
        } else {
          segments = this.sortedSegmentsForFieldsUnlimited[f.name]
        }

        let bigramsInThisField = new Set()
        const max_segments_per_field = 50000
        let max_bigrams = 20
        // Fields with high cardinality will be restricted to searching for
        // substrings only within the first few bigrams of each segment
        // value. This is an optimization to reduce the time cost of
        // building this data structure.
        if (segments.length > 1000) max_bigrams = 5
        for (const [idx, s] of segments.entries()) {
          count += 1
          if (idx >= max_segments_per_field) break
          // The segment value `s` is broken up into a sequence of bigrams.
          // For example, the segment value "happy" will be turned into the
          // sequence of bigrams ['ha', 'ap', 'pp', 'py'].
          for (const bg of this.bigramSequence(s, max_bigrams)) {
            bigramsInThisField.add(bg)
          }
        }
        // Each field has its own lookup Set of bigrams. Later, given a
        // searchText, we can break up the search text into bigrams and
        // efficiently check whether every bigram given in the search text
        // appears in the lookup set. If yes, we will proceed to search
        // the sequence of segment values in that field for actual hits.
        // If no, we will skip that field entirely. Check the code for
        // calculateSearchSuggestions().
        result[f.name] = bigramsInThisField
      }
      this.field_lookups = result
      logger.debug(`create datastructure took ${Date.now() - t0} ms for ${count} segments`)
    },
    mounted () {
      this.$nextTick(() => {
        if (!this.hasStructured) {
          new Tooltip(this.$el.querySelector('.no-fields'), {
            title: '<p>No structured data fields found in this Analysis.</p>',
            html: true
          })
        }
      })
      // Setup click handler on document in order to hide popover.
      document.addEventListener('click', this.onDocumentClick.bind(this))
    },
    beforeUnmount: function () {
      // Cleanup click handler for popover behaviour
      document.removeEventListener('click', this.onDocumentClick)
    },
    methods: {
      number: FormatUtils.number,
      // Compute the left offset for the popover -- to make sure we don't overflow screen right. The offset will be negative.
      popoverLeft () {
        if (this.compare && this.$el) {
          let padding = -20
          let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
          let popover = this.$el.querySelector('.query-popover')
          let rect = popover ? popover.getBoundingClientRect() : null
          // If the right side of the popover overflows the screen, adjust the left position
          if (rect && rect.right > width) {
            return width - rect.right + padding
          }
          return 0
        }
      },
      // Bubble up query deleted event
      bubbleQueryDeleted (queryId, updatedQueries) {
        this.$emit('query-deleted', queryId, updatedQueries)
      },
      // Bubble up query renamed event
      bubbleQueryRenamed (query) {
        this.$emit('query-renamed', query)
      },
      // Bubble up query saved event
      bubbleQuerySaved (query) {
        this.$emit('query-saved', query)
      },
      // Bubble up query selected event
      bubbleQuerySelected (query) {
        this.$emit('query-selected', query)
      },
      // When clicking the broader search input box,
      // set `focused` flag and shift UI focus to the actual search input.
      focusSearch () {
        this.focused = true
        this.$refs.searchInput.focus()
        this.preventBlur = true
      },
      // Hide and reset the query selector
      hide () {
        this.focused = false
        this.selectedField = null
        this.searchText = ''
      },
      // Infer concept from variant
      inferVariant (value) {
        if (this.currentModel.variantsMap[value]) {
          return this.currentModel.variantsMap[value].name
        }
        return value
      },
      // Search based on `searchText` value.
      doManualSearch (textValue) {
        if (this.searchTextClean.length > 0) {
          let value = this.inferVariant(this.searchTextClean)
          this.$emit('text-added', value)
        }
        this.hide()
      },
      // Hide/reset popover when the document background is clicked.
      onDocumentClick () {
        if (this.preventBlur) {
          this.preventBlur = false
          return
        }
        this.hide()
      },
      // Handle change in search input by focusing if applicable
      onInput () {
        if (!this.focused && this.$refs.searchInput.value.length > 0) {
          this.focusSearch()
        }
        this.searchSuggestionsDebounced()
      },
      // Add concept to query
      selectConcept (conceptName) {
        this.$emit('text-added', conceptName)
        this.hide()
      },
      // Clicking on a field..
      selectField (field, event) {
        this.selectedField = field
        this.$emit('field-added', field.name)
        this.hide()
      },
      // Clicking on an operator..
      selectOperator (operator) {
        this.selectedOperator = operator
        this.$emit('operator-added', operator)
        this.hide()
      },
      selectQuery (query) {
        this.$emit('query-added', query)
        this.hide()
      },
      // Add segment to query based on search suggestions.
      selectSegmentWithField (field, segment) {
        if (segment === undefined) {
          // Date fields handled specially (no segment)
          this.$emit('field-added', field)
        } else {
          this.$emit('segment-added', field, segment)
        }
        this.hide()
      },
      /**
       * Produce a sequence of character bigrams from a string.
       *
       * Example:
       *
       *    "happy" -> ["ha", "ap", "pp", "py"]
       *
       * Note that there is a bailout parameter to be able to control the
       * number of bigrams produced by very long strings. In the case that
       * the max limit is reached, the bigram will represent the start of
       * the string, up to the limit.
       *
       * @param {string} str
       * @param {number} max_bigrams
       * @returns {Array<string>}
       */
      bigramSequence (str, max_bigrams=10) {
        let s = str.toLowerCase()
        const result = []
        for (let i=0; i<s.length-1; i++) {
          result.push(s.slice(i, i + 2))
          if (i >= max_bigrams) break
        }
        return result
      },
      /**
       * Returns search suggestions (fields & concepts) based on current
       * `searchText` value.
       * @param {Object<string, Set<string>>} field_lookups
       * @returns {{concepts: {shortlist: *, remaining: number}, fields: {shortlist: [], remaining: number}}}
       */
      calculateSearchSuggestions (field_lookups) {
        let t0 = Date.now()
        let searchText = new RegExp(
          escapeRegExp(this.searchText.trim()), 'i'
        )
        let potentialFields = []
        // We want to do an early bail out when matching the first 10 of each
        // group of data. That means we have to keep track of how many matches
        // we have made so far.
        let matches = 0
        const maxMatches = 10
        // This is a helper function so we don't have to keep pushing and
        // updating the counter
        function addToMatches (field, segment, group) {
          const match = {field: field}
          // More special handling for dates...
          if (segment) {
            match.segment = segment
          }
          group.push(match)
          ++matches
        }

        let searchTextBigrams = [...this.bigramSequence(
          this.searchText.trim(),
          // The search text bigrams should always include everything.
          100
        )]

        for (const f of this.sortedFieldsUnlimited) {
          if (matches >= maxMatches) {
            break
          }

          // This block does optimized bigram-matching to speed up search
          // to offer suggestions for queries based on text the user types
          // in. The work is focused on segment values, and is described
          // in more detail in the comments below. However, we just need to
          // point out here that IF the field name itself matches the
          // search text then we don't need to do any optimized bigram-
          // matching stuff, since we already know we're going to get some
          // hits based on the field name alone.
          if (!f.name.match(searchText)) {
            // Make use of our custom data structure. We break up the search
            // text into bigrams, and then we verify that this field contains
            // every single bigram in the search text. If it doesn't, we don't
            // bother searching the searchText against the actual segment
            // values at all.
            let lookup = field_lookups[f.name]
            let thisFieldContainsAllSearchTextBigrams = true
            for (const bigram of searchTextBigrams) {
              if (!lookup.has(bigram)) {
                thisFieldContainsAllSearchTextBigrams = false
                break
              }
            }

            if (!thisFieldContainsAllSearchTextBigrams) {
              // There's no point searching the segments in this field, since we
              // know it lacks one or more bigrams in the search text. Just
              // skip this field and move onto the next one.
              continue
            }
          }

          // Unfortunately sortedFields treats sentiment as a special case and
          // does not include the segment values, so we have to handle that
          let segments
          if (f.name === 'sentiment') {
            segments = this.sentimentSegments
          } else {
            segments = this.sortedSegmentsForFieldsUnlimited[f.name]
          }

          for (const s of segments) {
            if (matches >= maxMatches) {
              break
            }
            // We have to check the field name while looping over the segments,
            // because we need both the field name and the segments values
            // when adding to the list of potential matches
            if (f.name.match(searchText)) {
              addToMatches(f.name, s, potentialFields)
              continue
            }
            if (s.match(searchText)) {
              addToMatches(f.name, s, potentialFields)
            }
          }
        }

        if (this.currentModel.dateFields) {
          for (const f of this.currentModel.dateFields) {
            if (matches >= maxMatches) {
              break
            }
            if (f.name.match(searchText)) {
              addToMatches(f.name, null, potentialFields)
            }
          }
        }
        const nFieldsLeft = (
          Object.values(this.sortedSegmentsForFieldsUnlimited).reduce((a, s) => a + s.length, 0)
          + this.currentModel.dateFields.length
          - 10
        )

        let suggestedConcepts = this.sortedConcepts.filter((c) => c.match(searchText))
        let suggestedQueries = this.savedQueries.filter((c) => c.name.match(searchText))
        logger.debug(`search suggestions took ${Date.now() - t0} ms`)
        return {
          fields: {
            shortlist: potentialFields,
            remaining: Math.max(nFieldsLeft, 0)
          },
          concepts: {
            shortlist: suggestedConcepts.slice(0, 10),
            remaining: Math.max(suggestedConcepts.length - 10, 0)
          },
          queries: {
            shortlist: suggestedQueries.slice(0, 10),
            remaining: Math.max(suggestedQueries.length - 10, 0)
          }
        }
      },
    }
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  /* Variables */
  $box-shadow-blue: 0px 1px 5px 0 $blue
  $popover-width: 700px
  $saved-queries-width: 220px
  $header-letter-spacing: 0.7px
  $query-items-max-height: 390px
  $field-values-max-height: 450px
  $search-suggestions-max-height: 490px

  /* Mixins */
  =heading()
    font-size: 1rem
    font-weight: bold
    letter-spacing: $header-letter-spacing
    text-transform: uppercase

  /* Reusable classes */
  .concept-dot
    font-size: 1.5rem
    padding-right: rem(5px)

  .no-queries
    text-align: center
    margin: 40px 0 60px

    h2
      color: $text-grey
      font-size: 30px
      margin-bottom: 8px

    a
      color: $blue
      font-size: 16px

  div.search-bar
    display: flex

    /* Search input */
    div.search-input-container
      flex: 1

      div.search-input
        background-color: white
        box-shadow: $box-shadow
        color: $text-grey
        cursor: text
        padding: rem(20px) rem(15px)
        font-size: rem(16px)
        border: 1px solid white
        &.focused
          border-color: $blue
          .icon.search
            color: $blue
        .icon.search
          font-size: rem(20px)
        .search-text
          background-color: white
          border: none
          outline: none
          width: calc(100% - 50px)
          color: $text-black
          +placeholder-color($text-grey)

    div.saved-queries-container
      display: flex
      align-items: center
      justify-content: center
      min-width: $saved-queries-width

  /* Popover */
  div.query-popover
    background-color: white
    border-radius: 5px
    box-shadow: $box-shadow
    font-size: rem(16px)
    margin-top: rem(15px)
    padding: rem(30px)
    opacity: 0
    visibility: hidden
    transition: opacity 0.3s, visibility 0.3s
    position: absolute
    width: $popover-width
    z-index: 10
    &.active
      opacity: 1
      visibility: visible
    .grey
      color: $text-grey

    /* Search suggestions */
    div.search-suggestions
      border-top: 1px solid $grey
      margin-top: rem(15px)
      max-height: $search-suggestions-max-height
      overflow-y: auto
      > ul
        line-height: rem(30px)
        list-style: none
        padding-left: 0
        li.fields-header, li.concepts-header
          +heading()
          color: $orange
          font-size: rem(14px)
        li:not(.fields-header):not(.concepts-header)
          cursor: pointer
          &:hover
            color: $blue

    /* Header for when a field is selected */
    div.selected-field-header
      +heading()
      div.go-back
        color: $text-grey
        border-bottom: 1px solid $grey
        padding-bottom: rem(20px)
        cursor: pointer
        &:hover
          opacity: $text-hover-opacity
      div.selected-field
        color: $orange
        padding-top: rem(30px)


    /* Top-level header menu */
    > ul
      border-bottom: 1px solid $grey
      list-style: none
      margin: 0
      padding: 0
      > li
        color: $text-grey
        display: inline-block
        +heading()
        padding-bottom: rem(20px)
        cursor: pointer
        &:nth-child(1), &:nth-child(2), &:nth-child(3)
          margin-right: rem(50px)
        &:hover:not(.disabled):not(.active)
          opacity: $text-hover-opacity
        &.active
          box-shadow: 0 1px 0 0 $blue
          color: $blue
          cursor: default
        &.disabled
          cursor: default

    /* Panel for displaying and selecting query items (fields, segments, concepts) */
    .query-items
      border-bottom: 1px solid $grey
      padding: rem(15px) 0 0 0
      max-height: $query-items-max-height
      overflow-y: auto
      &.field-values
        border-bottom: none
        height: auto
        max-height: $field-values-max-height
        padding-top: rem(10px)
      .query-fields, .query-concepts, .query-queries
        column-count: 2
        column-size: auto
      .field-values, .query-fields, .query-concepts, .query-queries
        margin: 0
        padding-left: 0
        > li
          color: $text-black
          cursor: pointer
          line-height: rem(40px)
          list-style: none
          padding-right: rem(10px)
          overflow: hidden
          text-overflow: ellipsis
          &:hover
            color: $blue
      .query-fields, .query-queries
        padding-bottom: rem(15px)
      .field-values
        padding: 0

    /* Help specific to the current query item (field or concept) */
    .query-item-help
      color: $text-grey
      line-height: rem(26px)
      padding-top: rem(20px)
      a
        color: $blue
        cursor: pointer
        text-decoration: none
        img
          display: inline
          padding-left: 3px
          padding-bottom: 3px
        &:hover
          opacity: $text-hover-opacity
</style>
