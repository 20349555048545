<template>
  <div id="app" :class="{ 'scroll-padding': isHomeRoute }">
    <!--
      basing positioning on route name like this is fragile, but I'd
      rather do it here once, than have multiple containers with their
      own announcements-banners each setting a specific position
    -->
    <announcements-banner
      :messages="messages"
      @dismiss="DISMISS_ANNOUNCEMENT"
      @show-messages="showMessagesModal"
    />
    <bf-modal :visible="showAllMessages" :click-to-close="true" @close="DISMISS_ALL_ANNOUNCEMENTS">
      <announcements-list :messages="messages" @close="DISMISS_ALL_ANNOUNCEMENTS" />
    </bf-modal>

    <vue3-progress />
    <router-view></router-view>

    <lock-out-modal
      :visible="loggedIn && isLocked"
      :is-admin="isAdmin"
      :feature-flags="featureFlags"
      :trialing="isTrialing"
      :exceeded-limits="exceededLimits"
      :current-user="currentUser"
      :body-text="subscriptionErrorMessage"
    />
    <div class="version">
      v{{ appVersion }} - {{ commitVersion.substring(0, 8) }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import LockOutModal from 'src/components/widgets/LockOutModal.vue'

  import AnnouncementsBanner from 'components/widgets/Announcements/AnnouncementsBanner.vue'
  import AnnouncementsList from 'components/widgets/Announcements/AnnouncementsList.vue'
  import { BfModal } from 'components/Butterfly'
  import { DISMISS_ANNOUNCEMENT, DISMISS_ALL_ANNOUNCEMENTS, TOGGLE_SHOW_ANNOUNCEMENTS } from 'src/store/types'
  import { analytics } from 'src/analytics'

  export default defineComponent({
    components: {
      LockOutModal,
      AnnouncementsBanner,
      AnnouncementsList,
      BfModal,
    },
    provide: {
      analytics,
    },
    computed: {
      ...mapGetters([
        'isAdmin', 'isSubscribed', 'isTrialing', 'currentUser', 'currentSite',
          'featureFlags', 'exceededLimits', 'subscriptionErrorMessage',
          'messages', 'showAllMessages',
          'loggedIn', 'appVersion', 'commitVersion'
      ]),
      lockedRoute () {
        let needsAuth = this.$route.matched.some(route=>route.meta.requiresAuth)
        let notAccountRoute = !this.$route.path.includes('/account/')
        let notMembersRoute = (this.$route.name !== 'members')
        return needsAuth && notAccountRoute && notMembersRoute && this.currentSite
      },
      isLocked () {
        let siteAccessBlocked = (
          // It is necessary to check explicitly for `true` and `false` here.
          // These store parameters are initialized to `undefined`, and we
          // want to avoid showing the lockout modal immediately after
          // after site selection, before the subscription data has been
          // fetched from the backend for the first time.
          this.currentSite && (
            this.isSubscribed === false || this.exceededLimits === true
          )
        )
        return this.lockedRoute && siteAccessBlocked
      },
      isHomeRoute () {
        return this.$route.name === 'home'
      },
    },

    // This is the default title (in the tab). If no other title is specified in a child, this will display
    metaInfo () {
      return {
        title: this.currentSite ? `${this.currentSite.site_name} - Kapiche` : 'Kapiche'
      }
    },
    mounted () {
      /* We must disable scroll bars if we are in an iframe. The resizing is handled by
       * a listener in the Dashboard widget. */
      if (top !== self) {
        (this.$el as HTMLElement).style.overflowY = 'hidden'
      }

      this.$router.afterEach(() => {
        this.$nextTick(() => {
          window.scrollTo(0, 0)  // Scroll to top
        })
      })

      this.$progress.finish()
    },
    created () {
      this.$progress.start()

      this.$router.beforeEach((to, from, next) => {
        this.$progress.start()
        next()
      })

      this.$router.afterEach(() => {
        this.$progress.finish()
      })
    },
    methods: {
      ...mapActions({
        DISMISS_ANNOUNCEMENT,
        DISMISS_ALL_ANNOUNCEMENTS,
        TOGGLE_SHOW_ANNOUNCEMENTS,
      }),
      showMessagesModal () {
        this.DISMISS_ALL_ANNOUNCEMENTS()
        this.TOGGLE_SHOW_ANNOUNCEMENTS()
      },
    },
  })
</script>

<style lang="sass">
  @import '~vue2-animate/src/sass/vue2-animate.scss'
  @import '../../semantic/dist/semantic.min.css'
  @import '~animate.css/animate.css'
  @import '../../node_modules/vue-multiselect/dist/vue-multiselect.css'
  @import '../assets/kapiche-icons.css'
  @import '../assets/kapiche.sass'
  @import '../assets/global-styles.sass'
  @import 'assets/element-ui/element-variables.sass'

  div#app
    color: $text-black
    height: 100% !important
    &.scroll-padding
      background: $grey-light-background

  input, textarea, select, button
    color: $text-black
    font-family: $standard-font
    // This is necessary to fix system dark themes from affecting the background
    // color in these widgets.
    background-color: white

  div.version
    font-family: $standard-font
    // Stick this in the bottom left of the screen
    position: fixed
    bottom: 5px
    left: 5px
    // Make sure it always shows.
    z-index: 9999
    // By using `invert` we can make the version number "barely visible" on
    // both light and dark backgrounds. The color is also set to 50% grey,
    // which makes it invert evenly from a dark or light background.
    filter: invert(1)
    color: hsla(0, 0%, 50%, 0.2)

  .button
    border-radius: 3px !important

  .button
    border-radius: 3px !important

  .events-disabled
    pointer-events: none !important
    cursor: not-allowed

  /* Super cool content placeholder animations */
  .animated-background
    animation-duration: 2s
    animation-fill-mode: forwards
    animation-iteration-count: infinite
    animation-name: placeHolderShimmer
    animation-timing-function: linear
    background: #f6f7f8
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)
    background-size: 800px 104px
    height: 0.7em
    position: relative

  @keyframes placeHolderShimmer
    0%
      background-position: -468px 0
    100%
      background-position: 468px 0


  .ui.dimmer
    background-color: hsla(199, 65%, 20%, 0.75)

</style>
