<template>
  <modal
    :visible="visible"
    @close="cancel"
  >
    <template #header>
      {{ header }}
    </template>
    <template #content>
      <VeeForm ref="form" v-slot="{ errors }">
        <div class="content">
          <p><strong>{{ caption }}</strong></p>
          <Field
            v-slot="{ field }"
            rules="required:max:200"
            name="newText"
            label="New name"
            validate-on-input
          >
            <bf-text-input
              v-bind="field"
              ref="newTextInput"
              :value="proposedNewText"
              :placeholder="placeholder"
              :class="{ 'error': errors['newText'] }"
              @input="proposedNewText = $event"
              @keyup.enter="save"
            />
          </Field>
          <div
            v-if="errors['newText']"
            class="error-text"
          >
            {{ errors['newText'] }}
          </div>
        </div>
        <div class="actions">
          <button
            class="ui cancel button"
            @click.prevent="cancel"
          >
            {{ cancelButtonText }}
          </button>
          <button
            class="ui button blue"
            :class="{ disabled: errors['newText'] }"
            @click.prevent="save"
          >
            {{ saveButtonText }}
          </button>
        </div>
      </VeeForm>
    </template>
  </modal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import Modal from 'components/project/analysis/results/Modal.vue'
  import { Form as VeeForm, Field } from 'vee-validate'
  import { BfTextInput } from 'components/Butterfly'

  export default defineComponent({
    name: 'ModalEditText',
    components: {
      Modal,
      VeeForm,
      Field,
      BfTextInput,
    },
    props: {
      header: { type: String, default: '' },
      caption: { type: String, default: '' },
      placeholder: { type: String, default: '' },
      cancelButtonText: { type: String, default: 'Cancel' },
      saveButtonText: { type: String, default: 'Save' },
    },
    data () {
      return {
        proposedNewText: '',
        context: undefined,
        visible: false,
      }
    },
    methods: {
      show (proposed = '', context = undefined) {
        this.proposedNewText = proposed
        this.context = context
        this.visible = true
        setTimeout(() => {
          this.$refs.form.setFieldValue('newText', proposed)
          this.$refs.newTextInput.focusInput()
          this.$refs.newTextInput.selectText()
        }, 100)
      },
      save () {
        this.$emit('save', this.proposedNewText, this.context)
        this.visible = false
        this.proposedNewText = ''
        this.context = undefined
      },
      cancel () {
        this.visible = false
        this.proposedNewText = ''
        this.context = undefined
        this.$emit('cancel')
      }
    },
  })
</script>

<style lang="sass" scoped>
  @import '../../assets/kapiche.sass'

  .actions
    margin-top: 20px
    text-align: center
    button:first-child
      margin-right: 20px
</style>
