<template>
  <bf-modal :visible="visible" :click-to-close="true" class="dashboard-widgets-modal" @close="close">
    <bf-dialog @close="close">
      <VeeForm
        v-slot="{ meta: { dirty }, setFieldValue }"
        :initial-values="tableList"
        :on-submit="apply"
      >
        <div class="panel">
          <h2>Show / Hide widgets</h2>
          <div class="main">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Overview</th>
                  <th>Drilldown</th>
                  <th v-if="allowReset" class="staff-only">
                    Config
                  </th>
                </tr>
              </thead>
              <tbody>
                <Field
                  v-for="key in tableKeys"
                  v-slot="{ field }"
                  :key="key"
                  :name="key"
                  as="tr"
                >
                  <td :class="{'invalid': !isValid(key)}">
                    {{ key }}
                  </td>
                  <td>
                    <template v-if="!isValid(key)">
                      <el-popover
                        :hide-after="0"
                        effect="dark"
                        placement="right-start"
                      >
                        <template #default>
                          This dashboard doesn't have the necessary data types to display this widget.
                        </template>
                        <template #reference>
                          <toggle-checkbox disabled />
                        </template>
                      </el-popover>
                    </template>
                    <template v-else>
                      <toggle-checkbox
                        v-if="field && field.value && field.value.overviewVisible !== undefined"
                        :value="field.value.overviewVisible"
                        :checked="field.value.overviewVisible"
                        @input="(value)=>toggleValue(value, field, 'overview', setFieldValue)"
                      />
                      <span
                        v-else
                        class="no-config"
                      >
                        -
                      </span>
                    </template>
                  </td>
                  <td>
                    <template v-if="!isValid(key)">
                      <el-popover
                        :hide-after="0"
                        effect="dark"
                        placement="right-start"
                      >
                        <template #default>
                          This dashboard doesn't have the necessary data types to display this widget.
                        </template>
                        <template #reference>
                          <toggle-checkbox disabled />
                        </template>
                      </el-popover>
                    </template>
                    <template v-else>
                      <toggle-checkbox
                        v-if="field && field.value && field.value.drilldownVisible !== undefined"
                        :value="field.value.drilldownVisible"
                        :checked="field.value.drilldownVisible"
                        @input="(value)=>toggleValue(value, field, 'drilldown', setFieldValue)"
                      />
                      <span v-else class="no-config">-</span>
                    </template>
                  </td>
                  <td v-if="allowReset">
                    <a href="" @click.prevent="clearConfig(field.value)">
                      reset
                    </a>
                  </td>
                </Field>
              </tbody>
            </table>
          </div>
          <footer>
            <div class="actions">
              <bf-button size="huge" color="grey" @click="close">
                Cancel
              </bf-button>
              <div class="spacer" />
              <bf-button type="submit" size="huge" color="blue" :disabled="!dirty">
                Apply
              </bf-button>
            </div>
          </footer>
        </div>
      </VeeForm>
    </bf-dialog>
  </bf-modal>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { Form as VeeForm, Field } from "vee-validate"
import { BfModal, BfButton, BfDialog } from "components/Butterfly"
import ToggleCheckbox from "components/widgets/ToggleCheckbox.vue"
import { WidgetName, DashboardConfig, AnyWidgetConfig } from 'types/DashboardTypes'
import { widgetNameToLabel, defaultConfig } from 'pages/dashboard/Dashboard.utils'
import { cloneDeep } from 'lodash'

interface TableListRowType {
    overviewKey?: WidgetName
    overviewVisible?: boolean
    drilldownKey?: WidgetName
    drilldownVisible?: boolean
}
interface TableListType {
  [key: string]: TableListRowType
}

const DashboardWidgetsModal = defineComponent({
  components: { BfDialog, ToggleCheckbox, VeeForm, BfModal, BfButton, Field },
  props: {
    visible: { type: Boolean, required: false, default: false },
    widgetConfig: { type: Object as PropType<DashboardConfig['widgets']>, required: false, default: ()=>({ overview:[], drilldown:[]}) },
    hasNps: { type: Boolean, required: false, default: false },
    hasSentiment: { type: Boolean, required: false, default: false },
    hasNumericFields: { type: Boolean, required: false, default: false },
    hasDate: { type: Boolean, required: false, default: false },
    allowReset: { type: Boolean, required: false, default: false },
  },
  computed: {
    /** labels to iterate over for table rows */
    tableKeys (): string[] {
      return Object.keys(this.tableList)
    },
    /**
    This flattens the passed widgetConfig down to a structure which
    can be used more easily by our <Form> & <Field> components
    */
    tableList (): TableListType {
      let keys: WidgetName[] = Array.from(new Set([
        ...this.widgetConfig.overview.map((w) => w.name),
        ...this.widgetConfig.drilldown.map((w) => w.name)
      ]))

      // Don't show the compare widgets in the table, their visibility is
      // based on the visibility of the non-compare widget they're based on.
      // e.g compare-nps-summary is shown if nps-summary is enabled
      keys = keys.filter((k) => !k.startsWith('compare-'))

      const overviewTable = keys.reduce((rows, key)=>{
        const tableKey = this.widgetKeyToTableKey(key)
        const overviewIndex = this.widgetConfig.overview?.findIndex(w=>w.name==key) ?? -1
        const drilldownIndex = this.widgetConfig.drilldown?.findIndex(w=>w.name==key) ?? -1

        if (overviewIndex !== -1 || drilldownIndex !== -1) {
          rows[tableKey] = rows[tableKey] ?? {}
        }

        if (overviewIndex !== -1) {
          rows[tableKey].overviewKey = key
          rows[tableKey].overviewVisible = this.widgetConfig.overview[overviewIndex]?.visible
          }
        if (drilldownIndex !== -1) {
          rows[tableKey].drilldownKey = key
          rows[tableKey].drilldownVisible = this.widgetConfig.drilldown[drilldownIndex]?.visible
        }
        return rows
      }, {} as TableListType)
      return overviewTable
    },
  },
  methods: {
    isValid (key: string): boolean {
      const requiredField: Record<string, boolean> = {
        'NPS': this.hasNps,
        'Sentiment': this.hasSentiment,
        'Quadrant': this.hasNps || this.hasSentiment || this.hasNumericFields,
        'Timeline': this.hasDate,
        'Emergent Concepts': this.hasDate,
      }
      return requiredField[key] ?? true
    },
    // this is required due to lack of support for nested objects in
    // our VeeValidate v4 shims.  When we upgrade to Vue3 & VeeValidate v4
    // we shouldn't need this
    // https://vee-validate.logaretm.com/v4/guide/components/nested-objects-and-arrays/
    toggleValue (value: boolean, sourceField: { value: TableListRowType, name: string}, view: string, setFieldValue: (arg0: string, arg1: TableListRowType) => void ) {
      let newValue = { ...sourceField.value }
      if (view === 'overview') {
        newValue.overviewVisible = value
      }
      if (view === 'drilldown') {
        newValue.drilldownVisible = value
      }
      setFieldValue(sourceField.name, newValue)
    },
    // returns an appropriate label for the rows for each widget
    // if the config key for the widget is the same as the label
    // then it isn't listed here & just returns the key.  Also
    // some widgets are grouped under the same label even though
    // they are technically different widgets on each view.
    // eg. nps-summary & nps-timeline are both 'NPS'
    widgetKeyToTableKey (key: WidgetName): string {
      return widgetNameToLabel(key)
    },
    apply (formValues:TableListType) {
      const config = cloneDeep(this.widgetConfig) as DashboardConfig['widgets']

      Object.values(formValues).forEach((tableRow) => {
        const overviewItem = config.overview.find(({ name }) => name === tableRow.overviewKey)
        const drilldownItem = config.drilldown.find(({ name }) => name === tableRow.drilldownKey)
        if (overviewItem) overviewItem.visible = !!tableRow.overviewVisible
        if (drilldownItem) drilldownItem.visible = !!tableRow.drilldownVisible
      })

      this.$emit('widgets-updated', config)
    },
    close () {
      this.$emit('close')
    },
    clearConfig (row: TableListRowType) {
      const config = cloneDeep(this.widgetConfig) as DashboardConfig['widgets']

      const replaceConfig = (key: keyof DashboardConfig['widgets'], widgetName: WidgetName) => {
        const blankConfig = defaultConfig().widgets[key] as AnyWidgetConfig[]
        const blankWidget = blankConfig.find(({ name }) => name === widgetName)

        if (blankWidget) {
          config[key] = config[key].map((item) =>
            item.name === widgetName
              ? blankWidget as any
              : item
          )
        }
      }

      row.overviewKey &&
        replaceConfig('overview', row.overviewKey)
      row.drilldownKey &&
        replaceConfig('drilldown', row.drilldownKey)

      this.$emit('widgets-updated', config)
    },
  }
})

export default DashboardWidgetsModal
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche'
  div.main
    display: flex
    flex-direction: column
    align-items: center
    background-color: $grey-light-background

  ul.widget-list
    list-style: none
    font-size: 16px
    text-transform: capitalize
    max-height: 360px
    overflow-y: auto
    padding: 0
    margin: 0

  table
    margin: 15px 145px
    border: 1px solid #E5E5E5
    background-color: #FFFFFF
    padding: 15px 30px
    font-size: 16px

  thead
    th
      padding: 0 10px
      padding-bottom: 10px
      white-space: nowrap

  tbody
    tr
      td:first-child
        text-transform: capitalize
      td:not(:first-child)
        text-align: center

  h2
    height: 64px
    font-weight: 400
    font-size: 30px
    text-align: center
    padding: 10px 0 20px
    margin: 0

  footer
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    min-height: 130px

  div.actions
    display: flex
    padding: 0
    justify-content: space-around

    .spacer
      width: 30px

  span.no-config
    display: inline-block
    font-size: 30px
    width: 25px
    color: #D8D8D8
    margin-right: 5px

  .invalid
    color: $subdued

</style>
