<template>
  <div class="wrapper">
    <bf-spinner text-pos="top">
      Authenticating...
    </bf-spinner>
  </div>
</template>

<script lang="ts">
  import * as Sentry from '@sentry/browser'
  import { BfSpinner } from 'components/Butterfly'
  import {
    setAuthHeader,
    testCookieStorageAvailability,
    testSessionStorageAvailability,
  } from 'src/utils/auth'
  import * as types from 'src/store/types'
  import { mapActions } from 'vuex'
  import auth from 'src/api/auth'
  import { defineComponent } from 'vue'
  import { Auth0 } from 'src/plugins/auth0'

  export default defineComponent({
    components: { BfSpinner },
    computed: {
      $auth () {
        return Auth0
      },
      nextLocation () {
        const { next } = Auth0.state.appState.query
        // pick last occurrence of next parameter. consistent with:
        //   new URLSearchParams(location.search).get('next')
        return Array.isArray(next) ? next[next.length - 1] : next
      },
    },
    watch: {
      async '$auth.isAuthenticated' (isAuthenticated) {
        if (isAuthenticated) {
          const jwt = await this.getJWT()
          this.signInWithToken(jwt)
        } else {
          this.$store.commit(types.FAILURE, 'Failed to sign in')
        }
      },
      async '$auth.error' (error) {
        // the Auth0 client threw an error, probably to do with code<->token exchange
        error && this.setError()
      }
    },
    async mounted () {
      await new Promise(resolve => setTimeout(resolve, 1000))
      const { dev_token } = this.$route.params
      const { error, error_description } = this.$route.query

      if (dev_token) {
        this.signInWithToken(dev_token)
      } else if (Auth0.state.isAuthenticated) {
        const jwt = await this.getJWT()
        this.signInWithToken(jwt)
      }
      else if (Auth0.state.error) {
        // the Auth0 client threw an error, probably to do with code<->token exchange
        this.setError()
      }
      else if (error) {
        // if there was login prompt error, such as "The transaction has expired"
        // display that to the user in the same way token errors are shown
        // otherwise let the user know that the authentication has failed
        this.setError(error_description)
      }
    },
    methods: {
      ...mapActions({
        CLEAR_ERRORS: types.CLEAR_ERRORS,
        LOGOUT: types.LOGOUT,
      }),
      /**
       * @param message optional error message to display to users and not log to Sentry,
       *                these should be helpful and not critical (eg. "The transaction has expired")
       */
      setError (message?: string) {
        const referrer = Auth0.state.appState.referrer === '/sso'
          ? 'sso'
          : 'login'

        this.$router.push({
          name: referrer,
          params: {
            auth0Error: [
              'Failed to authenticate',
              // display given message or generate a new one if possible
              message || this.generateCustomErrorMessage(),
            ].filter(Boolean).join(': '),
          },
          query: Auth0.state.appState.query,
        })
      },
      generateCustomErrorMessage (): string {
        const txnTimeout = 1000 * 60 * 10 // 10 minutes
        if (Auth0.getLastTransactionAge() > txnTimeout) {
          // The last transaction was started a while ago and has expired.
          // In this case, we don't need to send the error to Sentry.
          return 'This link has expired. Please click the login button to sign in again.'
        }

        // investigate current state for things to report on
        const transactionManager = Auth0.state.auth0Client?.transactionManager
        const transaction = Auth0.state.auth0Client?.transactionManager?.transaction
        // only generate a custom Auth0 error message if a message was not already received
        const customAuth0ErrorMessage = [
          Auth0.state.error?.message as string,
          !transactionManager && 'no transaction manager',
          !!transactionManager && !transaction && 'no transaction found'
        ].filter(Boolean).join(', ')

        // add extra context to pass on to Sentry
        Sentry.setExtras({
          'session storage availability': testSessionStorageAvailability(),
          'cookie storage availability': testCookieStorageAvailability(),
        })
        // pass possible error to sentry without raising the error locally
        Sentry.captureException(
          new Error([
            'Failed to authenticate',
            // show generated message or indicate that the error was unknown
            customAuth0ErrorMessage || 'unknown reason (not shown to user)',
          ].join(': '))
        )

        return customAuth0ErrorMessage
      },
      async getJWT (): Promise<string> {
        // ensure Auth0 is ready before checking token claims
        await Auth0.auth0.value.checkSession()
        await Auth0.refreshTokensEarly()
        const claims = await Auth0.getIdTokenClaims()
        return claims?.__raw ?? ''
      },
      async signInWithToken (token: string) {
        setAuthHeader({
          auth0Token: token,
          claims: await Auth0.getIdTokenClaims(),
        })

        try {
          const user = await auth.getUser()
          this.$store.commit(types.SET_USER, { token, user })
          this.$router.push(this.nextLocation || { name: 'start' })
        } catch (e) {
          // This has been added to give us more instrumentation to diagnose the
          // occurrences of double log ins. See sc-44829
          console.warn(e)
          this.CLEAR_ERRORS()
          const msg = e?.body?.messages?.map((m: any) => m.message).join(', ') || 'Login failed. Please contact your admin.'
          const referrer = Auth0.state.appState.referrer === '/sso' ? 'sso' : 'login'
          this.LOGOUT({
            returnTo: `${location.origin}/${referrer}?${
              new URLSearchParams({
                next: this.nextLocation || '',
                auth0Error: msg,
              }).toString()
            }`,
            use_auth0: true,
          })
        }
      }
    }
  })
</script>

<style lang="sass" scoped="true">
  .wrapper
    display: flex
    justify-content: center
    align-items: center
    height: 100%
</style>
