<template>
  <el-collapse-item title="Fields used as the unique row identifier" :name="settingIndex">
    <div>
      <template v-if="uniqueFields.length > 0">
        <ul>
          <li v-for="f in uniqueFields" :key="f">
            {{ f }}
          </li>
        </ul>
      </template>
      <p v-else>
        There are no fields being used as the unique row identifier. Document updates are disabled on this project.
      </p>
    </div>
  </el-collapse-item>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: "UniqueFields",
  props: {
    settingIndex: { type: String, required: true },
    uniqueFields: { type: Array as PropType<string[]>, default: () => []},
  },
})
</script>

<style lang="sass" scoped>
  .field-type
    border: 1px solid whitesmoke
    background: whitesmoke
    padding: 2px
    .bold
      font-weight: bold
    .field-type-name
      text-transform: uppercase

</style>
