<template>
  <widget-frame
    :zoomed="isZoomed"
    :masked="masked"
    :is-loading="isLoading"
    :dev-mode="devMode"
    :has-errored="hasErrored"
    :banner="banner"
    class="key-phrases"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <download-export-button
        :name="`${exportName} - Key Phrases - ${displaySelection}`"
        short-name="Key Phrases"
        :is-loading="isLoading"
        :get-el="getChartEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getSvgExportConfig"
        :make-ppt-slide="makePptSlide"
      ></download-export-button>
      <router-link
        v-if="!isZoomed && zoomToRoute"
        class="widget-action expand"
        :to="zoomToRoute"
      >
        <i class="kapiche-icon-fullscreen"></i>
      </router-link>
      <a
        :href="CONST.widget_help_links.key_phrases"
        class="widget-action help"
        target="_blank"
      >
        <i class="kapiche-icon-info"></i>
      </a>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img class="header-icon" :src="icon" alt="Key Phrases Icon" />
    </template>

    <!--======================== HEADING -->
    <template #header>
      Key Phrases
      <button v-if="devMode" @click="fetchData">
        Fetch Data
      </button>
    </template>

    <!--======================== MENU -->
    <template #menu>
      <div class="menu-list">
        <widget-menu
          :menus="menus"
          :vertical="isZoomed"
          :bound="bindElement"
          @onSelect="setMenuSelection"
        />
      </div>
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        <h2>this.props</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div v-if="data && data.error" class="error-panel">
        <h3>
          <img class="errorIcon" :src="errorIcon" alt="widget error icon" />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button> or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button> if the problem persists.
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="groupbyNotSupported" #content>
      <div class="warning">
        Group by is not yet supported for this widget.
      </div>
    </template>
    <template v-else-if="hasValidData" #content>
      <segmentation-chart
        v-if="!isLoading"
        :rows="dataRows"
        :headings="headings"
        :legend="[]"
        :max="1"
        :min="minValue"
        :height="height"
        :width="width"
        :show-indicator-bar="false"
        empty-segment-text="(No Value)"
        :hover-row-pointer="showDrilldown"
        @clicked-heading="onHeadingClick"
        @row-clicked="rowClicked"
        @hover-row="setTooltipStats"
      >
        <template #row-tool-tip>
          <tooltip v-if="tooltipStats" :data-item="tooltipStats" :show-drilldown="showDrilldown" />
        </template>

        <template v-if="showDrilldown" #interaction-menu="interactionMenuProps">
          <slot name="interaction-menu" :label="interactionMenuProps.label">
            <button @click="$emit('phrase-clicked', interactionMenuProps.label)">
              Drill into <b>{{ interactionMenuProps.label }}</b>
            </button>
            <button @click="$emit('add-concept', interactionMenuProps.label)">
              Drill into the intersection of
              <b>{{ viewTitle }}</b>
              and
              <b>{{ interactionMenuProps.label }}</b>
            </button>
          </slot>
        </template>
      </segmentation-chart>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import PptxGenJS from 'pptxgenjs'
import { PropType, defineComponent } from 'vue'
import { APIResponseDataItem, getDataRows, TooltipStats, generateCSV } from './KeyPhrases.utils'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import SegmentationChart from 'components/charts/SegmentationChart/SegmentationChart.vue'
import Tooltip from 'components/DataWidgets/KeyPhrases/Tooltip.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import { WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-keyphrases.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import { KeysMatching } from 'src/types/utils'
import { debounce, isEmpty, isEqual } from 'lodash'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { TableChartHeading, TableChartRowType } from 'types/components/Charts.types'
import { fetch_keyphrases_data } from 'src/store/modules/data/api'
import { makeBarChartSlide } from '../DataWidgetUtils'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'

/** Interface extended here because we want to add a key property to the
 * heading. */
interface KeyPhrasesChartHeading extends TableChartHeading {
  key?: KeysMatching<APIResponseDataItem, number>
}

export default defineComponent({
  components: {
    WidgetMessagePanel,
    DownloadExportButton,
    SegmentationChart,
    WidgetFrame,
    WidgetMenu,
    Tooltip,
  },
  props: {
    devMode: { type: Boolean, required: false, default: false },
    data: { type: Object, required: false, default: null },
    queries: { type: Array, required: false, default: () => [] },
    /** This group is either a query name, or a concept name, or `overall__`.
     * At the time of writing, it isn't currently used directly, but it is
     * required to allow drilldown->drilldown route changes to correctly
     * update this widget. For drilldown->drilldown, only route query params
     * are changed, and there is no other conduit beside `group` to observe
     * that reactive change. Here this is managed through a watch on `group`.*/
    group: { type: String, required: false, default: '' },
    exportName: { type: String, required: false, default: '' },
    zoomToRoute: { type: Object, required: false, default: null },
    isZoomed: { type: Boolean, required: false, default: false },

    /** widget banner to display */
    banner: { type: Object, default: () => null, required: false },
    showDrilldown: { type: Boolean, required: false, default: false },

    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'key-phrases'> | null>, required: false, default: null },
    /** The title of the current view so that we can use it in the
     * interaction menu. Should be something like "store & prices" */
    viewTitle: { type: String, required: false, default: '' },
    groupbyNotSupported: { type: Boolean, default: false},
  },
  data () {
    return {
      icon,
      errorIcon,
      hasErrored: false,
      width: 0,
      height: 0,
      tooltipStats: null as TooltipStats | null,
      headings: [] as KeyPhrasesChartHeading[],
      sortSelection: 'npmi' as KeysMatching<APIResponseDataItem, number>,
      sortAsc: false,
      customDateRange: [undefined, undefined] as [string | undefined, string | undefined],
      displaySelection: 'Correlation',
      minConceptFreq: 0,
    }
  },
  computed: {
    /** This is only to change the type to the correct type to make
     * TypeScript happy. It would be great if we could just say
     * `$el as HTMLElement` up there in the template, but that seems
     * to not be allowed in Vue 2.*/
    bindElement (): HTMLElement {
      return this.$el
    },
    isLoading () {
      return this.data === null && !this.hasValidData
    },
    conceptList () {
      if (!isEmpty(this.data))
        return this.data['payload']
      else
        return []
    },
    limit (): number {
      if (this.isZoomed) {
        return 100
      } else {
        return 10
      }
    },
    minValue () {
      if (this.displaySelection === 'Frequency') {
        return 0
      } else {
        if (isEmpty(this.conceptList)) {
          return 0
        }

        // Dynamically change the lower bound of the npmi
        // axis based on whether there are negative values
        // or not. There is an API parameter to the key phrases
        // endpoint called `positive_only` that controls whether
        // negative values are returned or not. The dynamic axis
        // bound below will work regardless of whether the
        // `positive_only` parameter is set to true or false.
        let minNPMI = Math.min(...this.conceptList.map(
          (item: APIResponseDataItem) => item.npmi
        ))
        if (minNPMI < 0) {
          return -1
        } else {
          return 0
        }
      }
    },
    hasValidData (): boolean {
      return this.dataRows.length > 0
    },
    dataRows (): TableChartRowType[] {
      return getDataRows(
        this.conceptList,
        this.sortSelection,
        this.displaySelection === 'Frequency' ? 'coverage_pair_group' : 'npmi',
      )
    },
    menus (): WidgetMenuOptions[] {
      return [
        {
          name: 'Display',
          selection: this.displaySelection,
          options: [
            [
              {
                type: 'menu',
                options: ['Frequency', 'Correlation'],
                showSelected: true,
                selected: this.displaySelection,
              },
            ],
          ],
        },
        {
          name: 'Min Frequency',
          selection: this.minConceptFreq,
          options: [
            [
              {
                type: 'inputNumber',
                // Without having something in the options list,
                // the dropdown shows "No Data"
                options: ['inputNumber'],
                settings: {
                  min: 0,
                  step: 10,
                  max: 100,
                },
                showSelected: true,
                selected: this.minConceptFreq,
              },
            ],
          ],
        },
      ]
    },
  },
  watch: {
    config: {
      deep: true,
      handler () {
        this.setOptionsFromConfig()
      },
    },
    sortSelection (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData()
      }
    },
    sortAsc (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData()
      }
    },
    isZoomed (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData()
      }
    },
    displaySelection (display: string) {
      this.setHeadings(display)
    },
    minConceptFreq: debounce(function () {
      this.fetchData()
    }, 500),
    group (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData()
      }
    },
    queries (newVal, oldVal) {
      if (!isEqual(newVal, oldVal)) {
        this.fetchData()
      }
    },
  },
  mounted () {
    this.setOptionsFromConfig()
    this.setHeadings(this.displaySelection)
    this.fetchData()
  },
  methods: {
    updateConfig () {
      const updated: typeof this.config = Object.assign({}, this.config, {
        options: {
          display: this.displaySelection,
          minfreq: this.minConceptFreq,
        },
      })
      this.$emit('config-changed', updated)
    },
    setOptionsFromConfig () {
      this.displaySelection = this.config?.options?.display ?? 'Correlation'
      this.minConceptFreq = this.config?.options?.minfreq ?? 5
    },
    setDateRange (dates: [string, string]) {
      this.customDateRange = dates
      this.updateConfig()
    },
    setHeadings (display: string) {
      const headings: KeyPhrasesChartHeading[] = [
        { label: 'Phrase', sortable: false },
        { label: 'Freq (#)', sortable: true, sortAsc: undefined, key: 'count_pair' },
      ]

      display === 'Correlation' && headings.push({ label: 'Correlation', sortable: true, sortAsc: false, key: 'npmi' })

      display === 'Frequency' &&
        headings.push({ label: 'Freq (%)', sortable: true, sortAsc: false, key: 'coverage_pair_group' })

      this.headings = headings
      this.sortSelection = headings.slice(-1)[0].key || 'npmi'
      this.sortAsc = headings.slice(-1)[0].sortAsc || false
    },
    onHeadingClick (headingIndex: number) {
      const heading = this.headings[headingIndex]
      const { sortAsc } = heading

      // Reset and toggle sort mode
      this.headings.slice(1).forEach((h: KeyPhrasesChartHeading) => (h.sortAsc = undefined))
      this.sortAsc = heading.sortAsc = !sortAsc

      if (heading.key) {
        this.sortSelection = heading.key
      }
      this.$analytics.track.keyphrases.changeSort(
        this.sortSelection,
        this.sortAsc ? 'asc' : 'desc',
      )
    },
    setTooltipStats (index: number) {
      this.tooltipStats = this.conceptList[index]
    },
    setChartDimensions (width: number, height: number): void {
      this.width = width
      this.height = height
    },
    async fetchData (force = false) {
      this.hasErrored = false
      try {
        // Analytics first, so that we track all attempts regardless of success.
        this.$analytics.track.keyphrases.load(this.conceptList.length)
        this.$emit(
          'requires',
          'key-phrases',
          {
            // NOTE: filters get added by machinery in the parent Overview.vue.
            queries: this.queries.map((q: any) => ({
              name: q.name,
              value: q.query_value,
            })),
            min_concept_freq: this.minConceptFreq,
            sort_field: this.sortSelection,
            sort_asc: this.sortAsc,
            limit: this.limit,
          },
          force,
          true,
          fetch_keyphrases_data,
        )
      } catch (e) {
        this.hasErrored = true
        return []
      }
    },
    setMenuSelection (name: string, [title, val]: [string, string]) {
      if (name === 'Display') {
        this.displaySelection = val
        this.$analytics.track.keyphrases.changeDisplay(this.displaySelection)
      }
      if (name === 'Min Frequency') {
        this.minConceptFreq = val
      }
      this.updateConfig()
    },
    getCsvData () {
      return generateCSV(this.conceptList, this.displaySelection)
    },
    getChartEl () {
      return this.$el.querySelector('div.content')
    },
    getSvgExportConfig () {
      const el = this.getChartEl()
      if (!el) return

      const bb = el.getBoundingClientRect()
      // noinspection SpellCheckingInspection
      return { dims: { height: bb.height, width: bb.width }, css: `
        svg {
          top: 0;
          left: 0;
        }
        .chart {
          background-color: #fff;
          cursor: default;
        }
        text.first {
          text-anchor: start;
        }
        text.end{
          text-anchor: end;
        }
        rect.bar{
            fill: #11ACDF;
        }
        rect.background {
          fill: #F1F1F1;
        }
        rect.indicator {
          fill: #8064AA;
        }
        text.sorted {
          fill: #068CCC;
          font-weight: bold;
        }
        rect.legend-icon-one {
          fill: #11ACDF;
        }
        rect.legend-icon-two {
          fill: #8064AA;
        }
        .legend {
          cursor: default;
        }`,
      }
    },
    rowClicked (rowIndex: number) {
      if (!this.showDrilldown) return
      const selectedPhrase = this.dataRows[rowIndex]?.label
      this.$analytics.track.keyphrases.drillIntoPhrase(selectedPhrase)
    },
    // error panel items
    refresh () {
      window.location.reload()
    },
    reload () {
      this.fetchData()
    },
    contact () {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    },
    makePptSlide (pptx: PptxGenJS) {
      if (!this.dataRows.length) return

      const slide = pptx.addSlide()

      makeBarChartSlide(
        pptx,
        slide,
        [{
          name: 'Key Phrases',
          labels: this.dataRows.map((r) => r.label),
          values: this.dataRows.map((r) => r.columns[0].value),
        }],
        `${this.exportName} - Key Phrases`,
        'Frequency',
        'Phrase',
      )
    }
  },
})
</script>

<style lang="sass" scoped>
  @import '~assets/kapiche.sass'

  .error-panel
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding-bottom: 30px

  .message
    display: flex
    flex-direction: row
    justify-content: center
    background-color: rgba(255,0,0, 0.1)
    padding: 6px
    color: $text-black
    width: 100%
    max-height: 30px
    position: absolute
    bottom: 0


  .errorIcon
    position: relative
    height: 32px
    width: 32px
    display: inline-block
    top: 10px

  .action
    padding-top: 20px

  .error-panel
    button
      background: none
      border: none
      border-bottom: 2px solid $blue
      padding: 3px 4px
      &:hover
        background-color: $grey-light
      &:focus
        border: 2px solid $blue-light
        outline: none

  .concept-table
    th
      text-align: left

  ::v-deep footer
    display: none !important
</style>
