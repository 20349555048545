<template>
  <div>
    <query-selector
      v-show="!minimal" ref="querySelector" :has-query="query.length > 0"
      :compare="compare"
      @text-added="addText"
      @field-added="addField"
      @query-added="addQuery"
      @operator-added="addOperator"
      @segment-added="addSegment"
      @query-deleted="onSavedQueryDeleted"
      @query-saved="setSavedQuery"
      @query-renamed="onSavedQueryRenamed"
      @query-selected="loadSavedQuery"
    >
    </query-selector>

    <div v-if="query.length > 0 || compare" class="query-name-row">
      <div>
        <template v-if="savedQuery">
          {{ savedQuery.name }} <span class="clear" @click="clearQuery">(Clear query)</span>
        </template>
        <template v-else>
          Unsaved Query <span class="clear" @click="clearQuery">(Clear query)</span>
        </template>
      </div>

      <div>
        <div v-if="currentProject.sentiment_classifier.includes('plumeria_')" style="padding-right: 10px; display: inline-block">
          <help-icon
            :content="sentenceSearchHelpText"
            :link="CONST.intercom_links.SENTENCE_SEARCH_INFO"
          >
          </help-icon>
          <el-select
            :model-value="mutableQueryScope"
            :style="{ width: '160px' }"
            placeholder="Select"
            size="mini"
            @change="changeQueryScope"
            @update:model-value="mutableQueryScope = $event"
          >
            <el-option
              v-for="item in queryScopeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <transition leave-active-class="animated fadeOut">
          <span v-if="updateSuccess" class="left aligned updated-label updated"><i class="check icon"></i>&nbsp;Saved!&nbsp;</span>
        </transition>
        <button v-if="isQueryValid && canUpdateSavedQuery && !compare" class="ui green button update-query" :disabled="disableSaving" @click="updateSavedQuery">
          Save
        </button>
        <button v-if="isQueryValid && !compare" class="ui primary button" :disabled="disableSaving" @click="saveQuery">
          Save As...
        </button>
        <router-link v-if="!compare" class="ui button compare-query" :to="{ name: 'compare-query', query: generateCompareQueryParams(query) }">
          Compare Queries
        </router-link>

        <!-- Saved queries -->
        <div v-if="compare" class="saved-queries-container">
          <saved-queries
            ref="savedQueries"
            :compare="compare"
            @query-selected="loadSavedQuery"
          >
          </saved-queries>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  import QuerySelector from './QuerySelector.vue'
  import HelpIcon from '../widgets/HelpIcon.vue'
  import SavedQueries from './SavedQueries.vue'
  import Query from 'src/api/query'
  import QueryUtils from 'src/utils/query'
  import Utils from 'src/utils/general'
  import { cloneDeep } from 'lodash'
  import { isQueryValid as isValid } from './utils'

  export default defineComponent({
    components: { QuerySelector, SavedQueries, HelpIcon },
    props: {
      disableSaving: { type: Boolean, default: false },
      minimal: { type: Boolean, default: false },  // minimal view
      query: {
        type: Array,
        default () {
          return []
        }
      },
      queryScope: { type: String, default: "frame" },
      compare: { type: Boolean, default: false }
    },
    data () {
      return {
        mutableQuery: cloneDeep(this.query),  // a version of the query we can modify
        mutableQueryScope: this.queryScope,
        savedQuery: null,  // currently loaded saved query
        updateSuccess: false,
        sentenceSearchHelpText: '<p>Switch Between Verbatim Search and Sentence Search.</p>' +
          '<p>Click on the icon to see how these options affect theme results.</p>',
        queryScopeOptions: [{
          value: 'frame',
          label: 'match on verbatim'
        }, {
          value: 'sentence',
          label: 'match on sentence'
        }],
      }
    },
    computed: {
      ...mapGetters([
        'currentProject', 'currentAnalysis', 'savedQueries', 'featureFlags',
      ]),
      // we have to put mutableQuery in the same order as what botanicToQueryRows will produce
      orderedQuery () {
        let result = []
        let includes = []
        let excludes = []
        this.mutableQuery.forEach(mq=>{
          if (mq.operator === 'is not' || mq.operator === 'does not include') {
            excludes.push(mq)
          } else {
            includes.push(mq)
          }
        })
        result = result.concat(includes, excludes)
        return result
      },
      // Returns true if we have a saved query loaded and the query builder has been changed
      canUpdateSavedQuery () {
        if (!this.savedQuery) {
          return false
        }

        let queryRows = QueryUtils.botanicToQueryRows(this.savedQuery.query_value)
        let queryRowsEquivalent = QueryUtils.areQueriesEquivalent(
          queryRows,
          this.orderedQuery
        )
        let queryScopeEquivalent = this.savedQuery.query_value.level === this.queryScope
        return !(queryRowsEquivalent && queryScopeEquivalent)
      },
      // Returns false if query has no rows or isQueryValid fails.
      // isQueryValid ensures that query rows with a range type have 2
      // values and all other query row types have at least 1 value.
      isQueryValid () {
        return (
          this.query.length > 0
          && isValid(this.query)
        )
      }
    },
    watch: {
      // Update the `mutableQuery` if it differs from `newQuery`
      query: {
        handler (newQuery) {
          if (!QueryUtils.areQueriesEquivalent(newQuery, this.mutableQuery)) {
            this.mutableQuery = cloneDeep(newQuery)
          }
          if (this.mutableQuery.length <= 0) {
            this.savedQuery = null
          }
        },
        deep: true
      },
      queryScope: {
        handler (newQueryScope) {
          this.mutableQueryScope = newQueryScope
        },
        deep: true
      }
    },
    methods: {
      generateCompareQueryParams (q) {
        let savedQueryParam = {}
        // Only send the query through AS a saved query if it
        // hasn't been modified from the saved value.
        if (this.savedQuery && !this.canUpdateSavedQuery) {
          savedQueryParam = { savedQuery_0: this.savedQuery.id }
        }
        return {
          'q_0': Utils.generateEncodedQuery(q),
          'queryScope_0': this.queryScope,
          ...savedQueryParam
        }
      },
      changeQueryScope (newQueryScope: string) {
        this.$emit('change-query-scope', newQueryScope)
      },
      // Add specified `value` (query row) to the `mutableQuery`
      addToQuery (value) {
        this.mutableQuery.push(value)
        this.$emit('query-updated', cloneDeep(this.mutableQuery))
      },
      // Add query to mutableQuery
      addQuery (query) {
        this.addToQuery({
          type: 'query',
          operator: 'includes',
          values: [query.id.toString()]
        })
      },
      // Add text search to mutableQuery
      addText (textValue) {
        if (Array.isArray(textValue)) {
          this.addToQuery({
            type: 'text',
            operator: 'includes',
            values: textValue
          })
        } else {
          this.addToQuery({
            type: 'text',
            operator: 'includes',
            values: [textValue]
          })
        }
      },
      // Add segment to mutableQuery
      addField (field) {
        let v = {
          type: field === 'sentiment' ? 'attribute' : 'segment',
          field: field,
          operator: 'is',
          values: []
        }
        this.addToQuery(v)
      },
      // Add Operator to mutableQuery
      addOperator (operator) {
        if (operator.name === "Word Count") {
          let v = {
            type: 'segment',
            field: "Token Count",
            operator: 'is greater than',
            values: []
          }
          this.addToQuery(v)
        }
      },
      addSegment (field, segment) {
        let v = {
          type: field === 'sentiment' ? 'attribute' : 'segment',
          field: field,
          operator: 'is',
          values: [segment]
        }
        this.addToQuery(v)
      },
      // Deselect the saved query
      deselectSavedQuery () {
        if (!this.compare) {
          this.$refs.querySelector.$refs.savedQueries.clearSelection()
          this.savedQuery = null
        }
      },
      // Load the specified saved query
      loadSavedQuery (query) {
        this.savedQuery = query
        this.mutableQuery = QueryUtils.botanicToQueryRows(query.query_value)
        this.$emit('query-selected', query.id, cloneDeep(query.query_value))
      },
      // Clear our currently selected query, if it was just deleted
      onSavedQueryDeleted (queryId, updatedQueries) {
        this.$emit('query-deleted', queryId)

        if (!this.savedQuery) return

        if (this.savedQuery.id === queryId) {
          this.savedQuery = null
        } else {
          // Sync savedQuery with the updated version
          const updatedQuery = updatedQueries.find(({ id }) => id === this.savedQuery.id)
          if (updatedQuery) this.savedQuery = updatedQuery
        }
      },
      // Update our currently selected query, if it was just renamed
      onSavedQueryRenamed (query) {
        if (this.savedQuery && this.savedQuery.id === query.id) {
          this.savedQuery = query
        }
      },
      // Call down into the saved queries widget to instigate the save
      saveQuery () {
        this.$refs.querySelector.$refs.savedQueries.promptSaveQuery(
          this.mutableQuery,
          this.queryScope,
        )
      },
      // Clear the query
      clearQuery () {
        this.mutableQuery = []
        this.savedQuery = null
        this.$emit('query-updated', cloneDeep(this.mutableQuery))
      },
      // Handle manual selection of saved query (used on page load)
      selectQuery (id: number) {
        // This state only lives in queryselector in the single query screen, NOT compare
        if (this.$refs.querySelector.compare === false) {
          let loadQuery = () => {
            setTimeout(() => {
              this.savedQuery = this.$refs.querySelector.$refs.savedQueries.savedQueries.find((q) => q.id === parseInt(id, 10))
            }, 100)
          }
          this.$nextTick(() => {
            // Handle both when saved queries have and haven't already been fetched
            if (this.$refs.querySelector.$refs.savedQueries.savedQueries) {
              this.$refs.querySelector.$refs.savedQueries.selectQuery(id)
              loadQuery()
            } else {
              this.$refs.querySelector.$refs.savedQueries.defaultQueryId = id
              this.$refs.querySelector.$refs.savedQueries.$on('loaded', () => {
                loadQuery()
              })
            }
          })
        } else if (this.$refs.querySelector.compare === true) {
          let loadQuery = () => {
            this.savedQuery = this.$refs.savedQueries.savedQueries.find((q) => q.id === parseInt(id, 10))
          }
          if (this.$refs.savedQueries.savedQueries) {
            this.$refs.savedQueries.selectQuery(id)
            loadQuery()
          } else {
            this.$refs.savedQueries.defaultQueryId = id
            this.$refs.savedQueries.$on('loaded', () => {
              loadQuery()
            })
          }
        }
      },
      // Set the current saved query
      setSavedQuery (query) {
        this.savedQuery = Object.assign({}, this.savedQuery, query)
        this.savedQuery = { ...this.savedQuery, ...query }
        this.$emit('query-saved', query.id)
      },
      async updateSavedQuery () {
        let buttonEl = this.$el.querySelector('.button.update-query')
        buttonEl.classList.add('loading')
        await Query.updateSavedQuery(this.currentProject.id, this.currentAnalysis.id, {
          id: this.savedQuery.id,
          name: this.savedQuery.name,
          query_value: QueryUtils.queryRowsToBotanic(this.query, this.queryScope)
        })
          .then((response) => {
            if (this._isDestroyed) {
              return
            }
            buttonEl.classList.remove('error')
            this.savedQuery = response
            this.updateSuccess = true
            this.$refs.querySelector.$refs.savedQueries.refresh()
          })
          .finally(() => {
            buttonEl.classList.remove('loading')
            this.updateSuccess = false
          })
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .saved-queries-container
    display: inline-block

  div.query-name-row
    flex-grow: 1
    font-size: rem(20px)
    line-height: rem(30px)
    padding-top: rem(20px)
    display: flex
    span.clear
      cursor: pointer
      color: #95a6ac
      font-size: rem(16px)
    > div
      flex-grow: 1
      &:nth-child(2)
        text-align: right
        .updated-label
          color: rgb(33, 186, 69)
          padding-right: 10px
          font-size: 16px
        .button, .updated
          margin-left: 5px
          border-radius: 3px
          font-size: rem(12px)
          font-weight: bold
          text-transform: uppercase
          padding-top: rem(10px)
          padding-bottom: rem(10px)
          &.compare-query
            border-radius: 3px
            border: solid 1px rgb(6, 140, 204)
            background-color: $grey-light-background
            color: rgb(6, 140, 204)
            &:hover
              background-color: rgb(6, 140, 204)
              color: white
          &.loading
            pointer-events: none
          &.error
            background-color: $red
</style>
