<template>
  <div class="menus" :class="{vertical}">
    <div
      v-for="(menu, i) in menus"
      :key="menu.name"
      :class="['menu', `menu-${i}`]"
    >
      <bf-button
        v-if="menu.onClick"
        size="tiny"
        color="blue"
        @click="menu.onClick"
      >
        {{ menu.name }}
      </bf-button>
      <template v-else>
        <div class="header">
          <span class="label">{{ menu.label || menu.name }}</span>
          <div class="select" @click.stop="togglePopover(menu.name, $event)">
            <span v-truncate class="selected-text">{{ findSelectionLabel(menu.selection, menu.options) }}</span>
            <i class="dropdown icon"></i>
          </div>
        </div>
        <display-popout-two
          :bound="bound"
          :visible="visiblePopover === menu.name"
          :options="menu.options"
          :left-options="menu.leftOptions"
          :max-label-length="maxLabelLength"
          :apply-button="menu.applyButton"
          :validate="menu.validate"
          :on-select="menu.onSelect"
          @changeOption="(selection)=>setSelection(menu.name, selection)"
          @hide="hidePopover()"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import DisplayPopoutTwo from 'components/project/analysis/results/dashboards/widgets/DisplayPopoutTwo.vue'
import { BfButton } from 'src/components/Butterfly'
import { PropType, defineComponent } from 'vue'
import { WidgetMenuOptions } from 'types/components/WidgetMenu.types'

export default defineComponent({
  components: { DisplayPopoutTwo, BfButton },
  props: {
    /** list of menus to show.  */
    menus: { type: Array as PropType<WidgetMenuOptions[]>, required: true, default: ()=>[] },
    /** vertical menu instead of horizontal default */
    vertical: {type: Boolean, default: false },
    bound: { type: HTMLElement, default: null, required: false },
    maxLabelLength: { type: Number, required: false, default: 40 }
  },
  data () {
    return {
      visiblePopover: null as string|null
    }
  },
  methods: {
    togglePopover (menu: string, e: Event): void {
      this.visiblePopover = this.visiblePopover === menu ? null : menu

      // If in vertical mode, we need to position the menu in its fixed
      // position so that it works with the overflow-y: scroll wrapper
      if (this.visiblePopover && this.vertical) {
        this.$nextTick(() => {
          const wrapperEl = (e.target as HTMLElement).closest('.menu') as HTMLElement
          const headerEl = wrapperEl.querySelector('.header') as HTMLElement
          const menuEl = wrapperEl.querySelector('.dropdown-menu') as HTMLElement

          const wrapperRect = headerEl.getBoundingClientRect()
          menuEl.style.marginTop = '0px'
          menuEl.style.right = `${window.innerWidth - wrapperRect.right}px`
          // Make sure the menu is not off the screen
          let top = wrapperRect.bottom
          if (top + menuEl.clientHeight > window.innerHeight) {
            top = window.innerHeight - menuEl.clientHeight - 20
          }
          menuEl.style.top = `${top}px`
        })
      }
    },
    setSelection (menu: string, item: string[]): void {
      /** inform parent of item selected & what menu it was
       * @event onSelect
       * @property {string} menu identifies the menu by name
       * @property {array} item identifies the selection [ title, selection]
      */
      this.$emit('onSelect', menu, item)
    },
    findSelectionLabel (selection, options) {
      if (options === undefined) return selection
      const flatOptions = options.flat().reduce((acc, menu) => {
        // This protects us from the case where a menu might have the same
        // value in different columns. If we check that this column has
        // a value selected, we know that it is the right one
        if (menu.selected !== null) {
          acc.push(...menu.options)
        }
        return acc
      }, [])
      const option = flatOptions?.find(el => el.value === selection)
      return option && option.label || selection
    },
    hidePopover (): void {
      this.visiblePopover = null
    }
  }
})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .menus
    display: flex
    font-size: 15px
    margin: 0
    justify-content: space-evenly

  .menu
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: relative
    margin: 0 20px

  .header
    display: flex
    flex-direction: column
    align-items: center

    .select
      cursor: pointer
      font-weight: bold
      color: #068ccc
      align-self: center
      margin-left: 15px

    .label
      font-family: $standard-font
      font-size: 12px
      font-weight: bold
      letter-spacing: 0.6px
      color: rgb(149, 166, 172)
      margin-bottom: 3px
      text-transform: uppercase
      align-self: center

  div.menus.vertical
    flex-direction: column
    justify-content: flex-start
    align-items: stretch
    div.menu
      align-items: flex-start
      position: relative
      margin: 0
      justify-content: flex-start
      align-content: flex-start
    div.header
      align-items: flex-start
      .select
        width: 100%
        margin-left: 0
        margin-bottom: 20px
      .label
        align-self: flex-start

  ::v-deep div.dropdown-menu
    margin: 50px 0 0 0
    transform: none
    top: 0

</style>
