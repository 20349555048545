<template>
  <div class="slice-name">
    <div v-if="editMode" class="slice-name-edit">
      <span
        class="slice-name-tick"
        @click="save()"
      >
        <icon
          :color="value ? '#068ccc' : '#cccccc'"
          name="tick"
          :size="15"
        />
      </span>
      <bf-text-input
        v-model="value"
        placeholder="Slice name"
        @keyup.enter="save()"
      />
    </div>
    <div
      v-else
      class="slice-name-display"
      @click="editMode = true"
    >
      {{ initialValue }}
      <span class="slice-name-edit">
        <icon
          color="#cccccc"
          name="edit"
          :size="15"
        />
      </span>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, watch } from 'vue'
  import Icon from 'src/components/Icon.vue'
  import { BfTextInput } from 'components/Butterfly'

  const SliceRename = defineComponent({
    components: {
      Icon,
      BfTextInput,
    },
    props: {
      initialValue: { type: String, required: true },
      submit: { type: Function, required: true },
    },
    setup (props) {
      const value = ref('')
      const editMode = ref(false)

      watch(() => props.initialValue, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          value.value = newVal
        }
      }, {
        immediate: true,
      })

      const save = () => {
        if (value.value) {
          props.submit(value.value)
          editMode.value = false
        }
      }

      return {
        value,
        editMode,
        save,
      }
    },
  })

  export default SliceRename
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .slice-name
    font-size: 18px
    font-weight: bold
    margin-bottom: 15px

    .slice-name-display
      cursor: pointer
      display: flex
      align-items: center

    .slice-name-edit
      display: flex
      align-items: center

    .slice-name-tick
      display: flex
      margin-right: 10px

    ::v-deep
      input
        padding: 10px
        height: 32px
        border-color: #eaeaea
        &:hover
          border-color: #eaeaea
        &:focus
          box-shadow: none

      .message
        display: none !important

    .icon-wrapper
      margin-left: 10px
      cursor: pointer
</style>
