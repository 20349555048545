import { AnnouncementsState } from "./announcements"

const getters: Record<string, (state: AnnouncementsState) => any> = {
  /** returns a list of all messages not assigned to widgets, ie general announcements */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  messages (state) { return state.messages.filter(m=>!m.widget) },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showAllMessages (state) { return state.show_all },
  /** returns all messages assigned to widgets, keyed by widget name */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  widgetBanners (state) {
    return state.messages.reduce((banners, message)=>{
      if (!message?.widget) return banners
      return {
        [message.widget]: message,
        ...banners
      }
    }, {})
  },
}

export default getters
