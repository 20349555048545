<template>
  <div class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">Segment Comparison</span>
      <div class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button v-if="hasData" :name="'Segment Comparison'" :get-el="getSegmentCorrelationsChartEl" :get-csv-data="getCsvData"></download-export-button>
      </div>
      <div class="header-buttons right floated">
        <div class="ui buttons">
          <button class="ui button" :class="mode === 'correlation' ? 'active' : ''" @click="setMode('correlation')">
            Correlation
          </button>
          <button class="ui button" :class="mode === 'frequency' ? 'active' : ''" @click="setMode('frequency')">
            Frequency
          </button>
        </div>
      </div>
    </div>
    <div class="ui segment body" :style="{ height: height + 'px' }">
      <!-- Top overall dropdown -->
      <div v-show="hasData" class="variable-select">
        <div class="ui scrolling dropdown">
          <div class="text">
            Top Overall
          </div>
          <i class="dropdown icon"></i>
          <div class="menu">
            <div
              v-for="variableName in variableNames.concat('Top Overall')"
              :key="variableName"
              v-truncate="20"
              class="item"
              :data-value="variableName"
            >
              {{ variableName }}
            </div>
          </div>
        </div>
      </div>

      <div class="chart-container" :style="{ height: height - 80 + 'px' }">
        <canvas v-show="hasData" ref="segmentCorrelations"></canvas>
        <div v-show="!hasData" class="no-data">
          <div>No matches found for this query</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import Chart from 'chart.js'
  import $ from 'jquery'
  import { mapGetters } from 'vuex'

  import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
  import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'
  import MathUtils from 'src/utils/math'
  import Util from 'src/utils/general'
  import ChartUtils from 'src/utils/chart'

  export default defineComponent({
    components: { DownloadExportButton, HelpIcon },
    props: {
      'segments': { type: Array, default: () => []},
      'segmentsData': { type: Object, default: () => {}},
      'colours': { type: Array, default: () => [] },
      'numTopSegments': { type: Number, default: 10 },
      'height': { type: Number, default: 450 }
    },
    data () {
      return {
        segmentNames: [],
        mode: 'frequency',
        segmentLabels: [],
        variable: 'Top Overall',
        dataset: {},
        help: '<p>This graph shows the strength of relationships with segments from your data. By default, it shows the top relationships across all segments but can be restricted to show correlations for a specific variables.</p>' +
              '<p>The frequency mode shows a relative frequency for each segment for your query. The correlations mode shows how this query correlates with the rest of the segments in your data.</p>'
      }
    },
    computed: {
      ...mapGetters([
        'currentAnalysis', 'currentModel', 'sortedSegmentsForFieldsLimited', 'sortedFieldsLimited',
      ]),
      topOverall () {
        return !this.variable || this.variable.toLowerCase() === 'top overall'
      },
      variableNames () {
        return this.sortedFieldsLimited.map(v => v.name).filter(v => v !== 'sentiment')
      },
      hasData () {
        return this.segmentNames.length > 0
      }
    },
    watch: {
      segmentsData: {
        deep: true,
        immediate: true,
        handler (d) {
          if (d) {
            this.$nextTick(() => {
              this.drawSegmentCorrelationsChart()
            })
          } else {
            if (this.segmentCorrelationsChart) {
              this.segmentCorrelationsChart.destroy()
            }
          }
          // The mounted hook doesn't always work -- Watch segmentsData in case it changes and re-set the dropdown
          // Covers the case for when segment data changes but mounted() is not called
          this.$nextTick(() => {
            $('.variable-select .ui.dropdown').dropdown({
              onChange: (value) => {
                if (this.variable !== value) { // Only trigger redraw for an actual change
                  this.variable = value
                  this.drawSegmentCorrelationsChart()
                }
              }
            })
          })
        }
      },
      variable (val) {
        this.$analytics.track.correlationWidget.changeDisplay('segment', val)
      }
    },
    methods: {
      // Adjust the chart to use frequency or correlation.
      setMode (mode) {
        if (mode !== this.mode) {
          this.mode = mode
          this.marshallData()
          this.$nextTick(() => {
            this.drawSegmentCorrelationsChart()
          })
          this.$analytics.track.correlationWidget.changeMode('segment', this.mode)
        }
      },
      drawSegmentCorrelationsChart () {
        if (this.segmentCorrelationsChart) {
          this.segmentCorrelationsChart.destroy()
        }
        this.marshallData()
        if (!this.hasData) {
          return
        }

        this.$nextTick(() => {
          let chartEl = this.getSegmentCorrelationsChartEl()
          if (!chartEl) {
            return
          }
          const ctx = chartEl.getContext('2d')
          if (!ctx) {
            return
          }
          this.segmentCorrelationsChart = new Chart(ctx, {
            type: 'horizontalBar',
            data: {
              labels: this.segmentLabels,
              datasets: [{
                label: this.dataset.label,
                borderWidth: 0,
                borderSkipped: 'bottom',
                backgroundColor: '#5ac1df',
                barPercentage: 0.75,
                data: this.dataset.data
              }]

            },
            plugins: [ChartUtils.horizontalZeroCompensationPlugin],
            options: {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: false
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItem) => {
                    if (this.mode === 'frequency') {
                      return `${ChartUtils.percentLabel(tooltipItem)}`
                    }
                    return `${tooltipItem.xLabel}`
                  },
                  // Return full untrucated name for tooltip title
                  title: (tooltipItem) => {
                    return this.segmentNames[tooltipItem[0].index]
                  }
                }
              },
              // On click, emit event
              onClick: (event) => {
                let el = this.segmentCorrelationsChart.getElementAtEvent(event)
                if (el.length > 0) {
                  let segmentName = this.segmentNames[el[0]._index]
                  if (!this.isSegmentInQuery(segmentName)) {
                    this.$emit('segment-clicked', segmentName)
                  }
                }
              },
              hover: {
                onHover: (chart, el) => {
                  if (el.length > 0) {
                    let segmentName = this.segmentNames[el[0]._index]
                    if (!this.isSegmentInQuery(segmentName)) {
                      chartEl.style.cursor = 'pointer'
                      return
                    }
                  }
                  chartEl.style.cursor = 'default'
                }
              },
              scales: {
                yAxes: [{
                  gridLines: {
                    display: true,
                    zeroLineWidth: 0,
                    color: ChartUtils.AXIS_COLOUR,
                    zeroLineColor: ChartUtils.AXIS_COLOUR
                  }
                }],
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    fontStyle: 'bold',
                    labelString: this.mode === 'correlation' ? 'Correlation' : 'Relative Frequency'
                  },
                  type: this.mode === 'correlation' ? this.mode : 'linear',
                  gridLines: {
                    display: true,
                    zeroLineWidth: 1,
                    color: ChartUtils.AXIS_COLOUR,
                    zeroLineColor: ChartUtils.AXIS_COLOUR
                  },
                  ticks: this.mode === 'frequency' ? ChartUtils.percentTicks(this.scaledTopicFrequencies) : { min: -1, max: 1 }
                }]
              }
            }
          })
          $('.variable-select').css('padding-left', this.segmentCorrelationsChart.getDatasetMeta(0).controller._ruler.scale.width)
        })
      },
      // Gather correlations data for the currently `selected` segments and chart filter `variable`.
      marshallData () {
        this.dataset = {}
        if (this.topOverall) {
          // Top overall automatically determines the top correlations across all available variables.
          let segmentNames = []
          let correlations = []
          let frequencies = []
          if (this.segmentsData && this.segmentsData.numExcerpts > 0) {  // Check that query produces results
            for (let variable of Object.keys(this.segmentsData.correlations)) {
              const segments = this.sortedSegmentsForFieldsLimited
              if (!segments.hasOwnProperty(variable)) {
                continue
              }
              for (let segment of segments[variable]) {
                segmentNames.push(Util.generateSegmentName(variable, segment))
                correlations.push(MathUtils.roundCorrelation(this.segmentsData.correlations[variable][segment]))
                frequencies.push(this.segmentsData.counts[variable][segment] / this.segmentsData.numExcerpts * 100)
              }
            }
          }
          // Determine top segments for (potentially) multiple compare segments
          let cumulativeValues = {} // Can be correlations or frequencies

          segmentNames.forEach((segmentName, i) => {
            let val = this.mode === 'correlation' ? correlations[i] : frequencies[i]
            if (val > 0) {
              if (cumulativeValues[segmentName]) {
                cumulativeValues[segmentName] += val
              } else {
                cumulativeValues[segmentName] = val
              }
            }
          })
          cumulativeValues = Object.entries(cumulativeValues)
          cumulativeValues.sort((a, b) => {
            return b[1] - a[1]
          })
          let topSegmentNames = cumulativeValues.slice(0, this.numTopSegments).map((s) => s[0])
          // Grab the now sorted values
          let values = cumulativeValues.slice(0, this.numTopSegments).map((s) => s[1])

          if (this.segmentsData && this.segmentsData.numExcerpts > 0) {
            this.dataset.label = this.segmentsData.label
            this.dataset.name = this.segmentsData.name
            this.dataset.colour = this.colours ? this.colours : '#068CCC'
            this.dataset.data = values
            this.$set(this, 'segmentNames', topSegmentNames)
            this.segmentLabels = this.segmentNames.map((n) => {
              return n.length > 45
                ? Util.generateSegmentLabel(...Util.splitSegmentName(n)).substring(0, 45) + '...'
                : Util.generateSegmentLabel(...Util.splitSegmentName(n))
            })
          }
        } else {
          // Otherwise, show segment correlations by filtered variable
          let segmentNames = []
          let segmentLabels = []
          let segments = this.sortedSegmentsForFieldsLimited[this.variable].slice()
          segments.sort(Util.naturalSort({ caseSensitive: false }))
          for (let segment of segments) {
            let segmentName = Util.generateSegmentName(this.variable, segment)
            segmentNames.push(segmentName)
            segmentLabels.push(Util.labelOrNoValue(segment))
          }
          this.segmentNames = segmentNames
          this.segmentLabels = segmentLabels

          if (this.segmentsData && this.segmentsData.numExcerpts > 0) {
            let correlations = []
            let frequencies = []
            for (let segment of segments) {
              let corr = this.segmentsData.correlations[this.variable][segment]
              if (corr === undefined) {
                corr = 0
              }
              let freq = this.segmentsData.counts[this.variable][segment]
              if (freq === undefined) {
                freq = 0
              }
              correlations.push(MathUtils.roundCorrelation(corr))
              frequencies.push(freq / this.segmentsData.numExcerpts * 100)
            }
            this.dataset.label = this.segmentsData.label
            this.dataset.colour = this.colours ? this.colours : '#068CCC'
            this.dataset.data = this.mode === 'correlation' ? correlations : frequencies
          }
        }
      },
      // Determine if `segmentName` is part of the driving query of this widget.
      isSegmentInQuery (segmentName) {
        return this.segments.find((n) => n.node_type === 'segment' && n.value === segmentName) !== undefined
      },
      getSegmentCorrelationsChartEl () {
        return this.$refs.segmentCorrelations
      },
      getCsvData () {
        return this.segmentNames.map((segmentName, i) => {
          if (this.mode === 'correlation') {
            return {
              name: segmentName,
              'Correlation': this.dataset.data[i]
            }
          } else if (this.mode === 'frequency') {
            return {
              name: segmentName,
              'Frequency (%)': ChartUtils.formatPercent(this.dataset.data[i])
            }
          }
        })
      }
    },
  })
</script>

<style lang="sass" scoped>
  .header .left-floated
    float: left
  .variable-select
    background: white
    .ui.dropdown
      font-weight: bold
      color: #068ccc
      border-bottom: 4px solid #068ccc
      .icon
        margin-left: 0.5em
  .header
    padding: 0 !important
    .header-buttons
      padding: 10px 20px
      .button.active, .button:hover
        background: rgba(149, 166, 172, 1)
        color: white
      .button
        background: rgba(149, 166, 172, 0.15)
        border: 1px solid rgba(149, 166, 172, 0.4) !important
        color: rgb(149, 166, 172)
        font-weight: bold
        width: 115px
        &:first-child
          border-right: 0 !important
      .ui.buttons .button:first-child
        border-top-left-radius: .28571429rem
        border-bottom-left-radius: .28571429rem
      .ui.buttons .button:last-child
        border-top-right-radius: .28571429rem
        border-bottom-right-radius: .28571429rem

  div.no-data
    display: table
    height: 100%
    width: 100%
    > div
      display: table-cell
      text-align: center
      vertical-align: middle
      font-size: 24px
      color: #95a6ac
</style>
