<template>
  <div class="insufficient-data-panel">
    <div class="inner-panel">
      <div class="title">
        <slot name="title">
          Chart could not be created
        </slot>
      </div>
      <div class="message">
        <slot name="message">
          There is no data available to create this chart.
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .insufficient-data-panel
    width: 100%
    margin: 0
    padding: 0
    background-color: #fff
    line-height: 1.5

  .inner-panel
    margin-top: 90px
    height: 320px
    text-align: center

  .title
    margin: 1.5rem 0 0.75rem
    font-size: 2.1rem
    color: rgba(149, 166, 172, 0.9)

  .message
    font-size: 1.28rem
    color: rgba(55, 55, 55, 0.9)
    margin: 0 0 1.5rem
    width: 100%

</style>
