<template>
  <div class="statistic row">
    <div class="statistic positive">
      <div class="numeral">
        {{ sentiment.positive }}%
      </div>
      <div class="label">
        Positive
      </div>
    </div>
    <div class="statistic negative">
      <div class="numeral">
        {{ sentiment.negative }}%
      </div>
      <div class="label">
        Negative
      </div>
    </div>
    <div class="statistic mixed">
      <div class="numeral">
        {{ sentiment.mixed }}%
      </div>
      <div class="label">
        Mixed
      </div>
    </div>
    <div class="statistic neutral">
      <div class="numeral">
        {{ sentiment.neutral }}%
      </div>
      <div class="label">
        Neutral
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  props: {
    sentiment: {
      type: Object as PropType<{
        positive: string
        negative: string
        mixed: string
        neutral: string
      }>,
      required: true,
    },
  },
})
</script>
<style lang="sass" scoped>
@import '~assets/kapiche.sass'

.statistic.row
  margin-top: 14px
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between
  .statistic
    font-size: 26px
    font-weight: bold
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    &.positive
      color: rgb(33, 186, 69)
    &.negative
      color: rgb(238, 56, 36)
    &.mixed
      color: rgb(248, 149, 22)
    &.neutral
      color: rgb(127, 127, 127)
    .label
      margin: 10px
    .label
      font-size: 16px
      font-weight: normal
</style>
