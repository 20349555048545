<template>
  <div class="widget" :class="{ zoomed, masked: masked && zoomed, unmasked: !masked && zoomed }">
    <resize-listener @resize="resize" />
    <div class="main panel" :class="{ 'banner-top': banner, masked: masked && !zoomed, unmasked: !masked && !zoomed }">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div
        v-if="banner"
        class="banner" :class="banner.style"
        v-html="bannerHTML"
      />
      <div class="header">
        <div class="actions">
          <span
            v-if="sidePanelCollapsable && zoomed"
            class="collapse-icon"
            title="Show / Hide Options"
            @click="() => collapsed = !collapsed"
          >
            <icon
              :style="{
                'transform': collapsed ? '' : 'rotate(180deg)'
              }"
              name="double-chevron-left"
              :size="16"
              color="#7F7F7F"
            />
          </span>
          <slot v-if="!hasErrored" name="actions" />
          <bf-button v-if="devMode" class="right floated staff-button" @click="showDevPanel=!showDevPanel">
            <i class="icon wrench" />{{ showDevPanel ? 'hide':'show' }} data
          </bf-button>
        </div>
        <slot name="icon" />
        <h1 class="header-text">
          <slot name="header" />
        </h1>
        <hr class="keyline" />
      </div>
      <div class="menu">
        <slot v-if="!zoomed" name="menu" />
      </div>
      <bf-panel v-if="devMode && showDevPanel" class="dev-panel">
        <template #main>
          <slot name="devPanel" />
        </template>
      </bf-panel>
      <div v-if="hasErrored" class="content">
        <slot v-if="hasErrored" name="error-panel">
          <error-panel />
        </slot>
      </div>
      <div v-else v-loading="isLoading" class="content" :class="{ hasFooter }">
        <slot name="content">
          <widget-message-panel>
            <template #title>
              <span>Loading Chart</span>
            </template>
            <template #message>
              <span>Fetching Data... Please wait.</span>
            </template>
          </widget-message-panel>
        </slot>
      </div>
      <footer v-if="hasFooter()">
        <slot name="footer" />
      </footer>
    </div>
    <!-- ============= SIDE PANEL ============= -->
    <div
      v-if="zoomed"
      :style="{width: collapsed? '0px': '315px'}"
      class="control-pane panel"
      :class="{ masked, unmasked: !masked }"
    >
      <div
        class="header-control-panel"
        :style="{borderBottom: collapsed? '0px': '1px solid #e5e5e5'}"
      >
        <h2 v-if="!collapsed">
          Chart Options
        </h2>
      </div>
      <div class="controls">
        <!-- SLOT -->
        <slot v-if="zoomed && hasMenu() && !collapsed" class="menu zoomed" name="menu" />
        <slot v-if="zoomed && hasPanel() && !collapsed" name="panel" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BfButton, BfPanel } from 'components/Butterfly'
import ErrorPanel from 'components/widgets/ErrorPanel/ErrorPanel.vue'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import ResizeListener from 'components/widgets/ResizeListener/ResizeListener.vue'
import { markdown } from 'src/utils/formatters'
import Icon from 'components/Icon.vue'

export default defineComponent({
  components: {
    ErrorPanel,
    ResizeListener,
    BfButton,
    BfPanel,
    WidgetMessagePanel,
    Icon,
  },
  props: {
    banner: { type: Object, default: ()=>null, required: false },
    isLoading: { type: Boolean, default: false },
    zoomed: { type: Boolean, default: false },
    hasErrored: { type: Boolean, default: false },
    devMode: { type: Boolean, default: false },
    masked: { type: Boolean, default: true },
    sidePanelCollapsable: { type: Boolean, default: false },
  },
  data () {
    return {
      showDevPanel: false,
      collapsed: false,
    }
  },
  computed: {
    bannerHTML () {
      if (!this.banner?.message) return ''
      return markdown(this.banner?.message, true)
    },
    showSidePanel () {
      return this.sidePanelCollapsable && !this.collapsed
    },
  },
  watch: {
    zoomed (old_value, new_value) {
      if (old_value !== new_value) this.resize()
    },
  },
  mounted () {
    setTimeout(()=>{
      this.resize()
    }, 0)
  },
  methods: {
    resize: function () {
      this.$nextTick(()=>{
        if (this.$el) {
          const width = this.$el.getBoundingClientRect().width - (this.zoomed ? 350 : 40)
          const height = this.zoomed ? this.$el.getBoundingClientRect().height - 160 : 600
          this.$emit('resize', width, height)
        }
      })
    },
    // this.$slots is not reactive so they cannot be computed properties
    hasFooter () {
      return !!this.$slots.footer
    },
    hasMenu () {
      return !!this.$slots.menu
    },
    hasPanel () {
      return !!this.$slots.panel
    },
  }
})
</script>
<style lang="sass" scoped>
  .dev-panel
    width: 100%
    max-height: 500px
    overflow: scroll
    position: relative

  div.overlay
    position: absolute
    margin: 20px auto
  div.hidden
    position: absolute
    visibility: hidden
</style>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'
  @import 'assets/masked.sass'

  ::v-deep
    .widget-action
      color: $grey-dark
      font-size: 1.5rem
      font-weight: normal
      display: inline-block
      margin-left: 18px
      margin-top: 9px
      position: relative

      &:hover
        color: $blue
        .icon-svg
          filter: brightness(0) saturate(100%) invert(55%) sepia(47%) saturate(7500%) hue-rotate(174deg) brightness(92%) contrast(95%)

        &::after
          visibility: visible
          opacity: 1

      &::after
        display: block
        background: $grey-extra-light
        color: $text-grey
        position: absolute
        top: 100%
        right: 0
        white-space: nowrap
        padding: 14px 18px
        font-size: 14px
        margin-top: 10px
        visibility: hidden
        opacity: 0
        transition: opacity 0.3s ease
        box-shadow: 0 0 3px 0px rgba(#000, 0.15)
        z-index: 1

      &.expand::after
        content: "Open this widget in expanded mode"
      &.help::after
        content: "Click to learn more about this widget"
      &.download::after
        content: "Download/share chart"

  div.widget
    position: relative
    margin: 0 !important
    display: flex
    flex-direction: row
    justify-content: flex-start

  .panel
    background-color: #FFFFFF
    border: 1px solid rgba(0, 1, 1, 0.1)

  // .banner-top is only applied when a banner is shown
  // because the banner top corners are rounded and so
  // we do not want the surrounding panel to be less rounded
  // and look like it is poking through or out from under it
  .banner-top
    border-radius: 15px 15px 0 0

  div.main
    display: flex
    flex-direction: column
    flex-grow: 1
    align-items: center
    width: 100%

  .content
    width: 100%
    padding: 20px
    display: flex
    flex-direction: column
    align-items: center
    min-height: 100px

  div.header
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    width: 100%
    height: 140px
    padding: 0 10px 0 0
    hr.keyline
      width: 100px
      border: none
      border-bottom: 1px solid #e5e5e5
      margin-left: auto
      margin-right: auto
      margin-bottom: 20px

    ::v-deep .header-icon
      margin-top: 28px
      max-height: 32px
      min-width: 32px
    h1
      margin: 20px 0
      font-weight: bold
      opacity: 0.9
      font-size: 20px

    .actions
      padding: 8px 5px
      position: absolute
      align-self: flex-end
      display: flex
      flex-direction: row-reverse
      align-items: baseline
    div.menu
      width: 100%
    .menu-list
      justify-content: center
      align-content: center

    &::v-deep div.menu-dropdown
      align-items: flex-start
      display: flex
      flex-direction: column
      justify-content: flex-start
      margin-left: 0 !important
      transform: translateX(0%) !important


  div.control-pane
    display: none

  div.widget.zoomed
    height: 100%

    ::v-deep img.header-icon
      margin-top: 0 !important

    div.panel
      min-width: 0

    div.content
      padding: 20px
      overflow-y: auto
      flex-grow: 1

    div.menu
      display: none
    div.header
      flex-direction: row
      align-items: center
      height: 70px
      margin: 0
      padding: 15px 10px 14px 20px
      border-bottom: 1px solid #e5e5e5

      h1
        margin: 0 20px
        font-weight: bold
        opacity: 0.9
        font-size: 20px
        flex-grow: 1
      .keyline
        display: none
      .actions
        order: 1
        align-self: center
        position: relative
        margin-right: 15px

    div.control-pane
      display: flex
      flex-direction: column
      margin: 0 0 0 0
      flex-shrink: 0
      padding: 0

      .header-control-panel
        display: flex
        height: 70px
        padding: 20px
        opacity: 0.9
        line-height: 1.4
        text-align: left
        color: #383838
        h2
          font-weight: bold
          font-size: 20px
          padding: 0
          margin: 0

      &::v-deep div.dropdown-menu
        margin-left: 0 !important
        position: fixed
        z-index: 999

    .content, .controls
      flex: 1 1 0
      overflow-y: auto

  .controls
    display: flex
    flex-direction: column
    padding: 20px 15px
    overflow-y: auto

    &::v-deep
      .menu-list
        justify-content: flex-start
        align-content: flex-start
        .menu-dropdown
          align-items: flex-start
          margin: 0

      div.dropdown-list
        margin-bottom: 15px !important
        display: block !important
        div.dropdown-wrapper
          margin: 0 0 30px 0 !important
          .dropdown-label
            align-content: flex-start
            margin: 0

    hr
      width: 100%
      border: none
      border-bottom: 1px solid #e5e5e5
      margin-bottom: 20px

    .panel
      overflow-y: scroll

  footer
    display: flex
    flex-direction: row-reverse
    justify-content: space-between
    padding: 15px 25px 20px 25px
    width: 100%
    max-height: 60px

    & small
      color: #95A6AC
      font-size: 1rem

  .widget.zoomed footer
    bottom: 30px
    padding: 20px

  .banner
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    font-family: $standard-font
    color: $white
    padding: 10px 20px
    border-radius: 3px 3px 0 0
    height: 45px
  .red
    background-color: $red
  .blue
    background-color: $blue
  .orange
    background-color: $orange
  .green
    background-color: $green

  div.banner
    &::v-deep *
      font-size: 16px
      font-weight: bold

    &::v-deep a
      cursor: pointer
      color: $white !important
      border-bottom: 1px solid $white
      padding-bottom: 1px

  .collapse-icon
    margin-left: 20px
    margin-bottom: 5px
    height: 32px
    display: flex
    align-items: flex-end
    cursor: pointer

</style>
