
<template>
  <el-select
    :style="{ width: '140px' }"
    :model-value="value"
    :class="{'read-only': readOnly}"
    @click.capture="clickHandler"
    @change="$emit('change', $event)"
  >
    <el-option label="ADMIN" value="ADMIN">
      <a class="ui red empty circular label"></a>
      ADMIN
    </el-option>
    <el-option label="CREATOR" value="ANALYST">
      <a class="ui blue empty circular label"></a>
      CREATOR
    </el-option>
    <el-option label="EXPLORER" value="VIEWER">
      <a class="ui yellow empty circular label"></a>
      EXPLORER
    </el-option>
    <template #prefix>
      <span v-if="value === 'ADMIN'">
        <a class="ui red empty circular label"></a>
      </span>
      <div v-if="value === 'ANALYST'">
        <a class="ui blue empty circular label"></a>
      </div>
      <div v-if="value === 'VIEWER'">
        <a class="ui yellow empty circular label"></a>
      </div>
    </template>
  </el-select>
</template>
<script lang="ts">
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'UserTypeSelect',
  props: {
    value: {
      type: String as PropType<'ADMIN' | 'ANALYST' | 'VIEWER'>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandler (event: Event) {
      if (this.readOnly) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  }
})
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  ::v-deep .el-input__prefix
    left: 15px
    display: flex
    align-items: center
  ::v-deep input.el-input__inner
    padding-left: 35px

  .read-only ::v-deep  .el-input__suffix
    display: none

  a.ui.label
    margin-right: 5px
</style>