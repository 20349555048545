<!--
  The component acts as the router viewport for all sites.
  It also handles the rending of the sidebar and header.
-->
<template>
  <div>
    <SiteSidebar :is-collapsed="sideBarCollapse" @toggle-collapse="toggleCollapse" />
    <HeadingBar :expand-bar="sideBarCollapse" />

    <div id="main" :class="{expand: sideBarCollapse, collapse: sideBarCollapse}">
      <div class="front-wrapper">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  import HeadingBar from './HeadingBar.vue'
  import SiteSidebar from './SiteSidebar.vue'

  export default defineComponent({
    components: { HeadingBar, SiteSidebar },
    metaInfo () {  // Declaring metaInfo as a function allows it to be reactive
      return {
        title: this.currentSite ? `${this.currentSite.site_name} - Kapiche` : 'Kapiche'
      }
    },
    data: () => {
      return {
        sideBarCollapse: false
      }
    },
    computed: {
      ...mapGetters(['currentSite'])
    },
    methods: {
      toggleCollapse (value: boolean) {
        this.sideBarCollapse = value
      }
    }
  })
</script>

<style lang="sass" scoped>
  #main
    display: flex
    flex-direction: column
    height: 100vh
    max-height: 100vh
    padding-top: 5em
    margin-left: 17rem
    overflow-y: hidden
    &.expand
      margin-left: 17rem
      width: calc(100% - 17rem)
    &.collapse
      margin-left: 5rem
      width: calc(100% - 5rem)
    .front-wrapper
      background-color: #f6f6f6
      display: flex
      flex-direction: column
      flex: 1 1 auto
      width: 100%
      overflow-y: auto
      > div
        flex: 1
</style>
