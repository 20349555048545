import { createStore, Store } from 'vuex'

import auth, { AuthState } from './modules/auth/auth'
import subscription, { SubscriptionState } from './modules/subscription'
import project, { ProjectState } from './modules/project'
import error from './modules/error/error'
import featureflags, { FeatureFlagsState } from "src/store/modules/featureflags"
import app, { AppState } from "src/store/modules/app"
import query from "src/store/modules/query/query"
import announcements from "src/store/modules/announcements/announcements"
import data from "src/store/modules/data/"
import appVersion from 'src/store/modules/version'

import * as getters from './getters'
import { updateSitesList, updateIntercomSubscription, updateIntercomUser, setIntercomVisibility } from './watchers'

export interface State {
  app: AppState
  auth: AuthState
  subscription: SubscriptionState
  project: ProjectState
  featureflags: FeatureFlagsState
}

const store = createStore({
  getters: getters,
  modules: {
    auth: auth as any,
    subscription: subscription as any,
    project: project as any,
    error,
    featureflags,
    app: app as any,
    query: query as any,
    announcements: announcements as any,
    data: data as any,
    appVersion,
  },
  plugins: [
    updateSitesList,
    updateIntercomSubscription,
    updateIntercomUser,
    setIntercomVisibility,
  ],
  strict: false
})

export const useStore = () => store

export type AppStore = Store<State>

export type Actions = Record<string, (store: AppStore, ...args: any[]) => any>

export default store
