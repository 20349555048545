<template>
  <div class="progress">
    <div class="labels">
      <div>{{ progress.toFixed(0) }}% verbatims mapped (goal: {{ goal }}%)</div>
      <div>{{ ignored.toFixed(0) }}% ignored</div>
    </div>
    <div class="bar">
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
      <div class="ignored-bar" :style="{ width: ignored + '%' }"></div>
      <div class="goal" :style="{ left: goalPercent + '%' }"></div>
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent } from 'vue'

  const ProgressBar = defineComponent({
    props: {
      progress: { type: Number, required: true },
      goal: { type: Number, required: true },
      ignored: { type: Number, required: true },
    },
    setup (props) {
      const goalPercent = computed(() => {
        return (props.goal / 100) * (100 - props.ignored)
      })
      return {
        goalPercent,
      }
    }
  })
  export default ProgressBar
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .progress
    padding: 20px 20px 10px
    .bar
      position: relative
      height: 4px
      background: $grey
      > div
        position: absolute
        height: 100%
        top: 0
      .progress-bar
        background: $green
        left: 0
        border-right: 2px solid $white
        z-index: 2
      .ignored-bar
        background: $text-grey
        right: 0
        border-left: 2px solid $white
        z-index: 1
      .goal
        top: -3px
        height: 10px
        width: 2px
        background: $green
        z-index: 3
    .labels
      text-transform: uppercase
      white-space: nowrap
      font-weight: bold
      font-size: 12.5px
      display: flex
      margin-bottom: 4px
      > div:nth-child(1)
        color: $green
      > div:nth-child(2)
        color: $text-grey
        text-align: right
        flex: 1
</style>
