<template>
  <div class="strength-checker-wrapper" :style="`font-family: ${font}`">
    <slot class="strength-input"></slot>
    <div class="strength-bar">
      <div class="bar" :style="`width: ${strength}%; background-color: ${color};`"></div>
    </div>
    <p class="strength-message">
      Password Strength:
      <span v-if="strength === -1" :style="{ color: color }">Invalid</span>
      <span v-else-if="strength === 0" :style="{ color: color }">Very Weak</span>
      <span v-else-if="strength === 25" :style="{ color: color }">Weak</span>
      <span v-else-if="strength === 50" :style="{ color: color }">Good</span>
      <span v-else-if="strength === 75" :style="{ color: color }">Strong</span>
      <span v-else-if="strength === 100" :style="{ color: color }">Very Strong</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "PasswordChecker",
  props: {
    font: {
      type: String,
      default: 'Lato, Helvetica, Arial, sans-serif',
    },
    colors: {
      type: Object,
      default () {
        return {
          invalid: '#ee3824',
          very_weak: '#ee3824',
          weak: '#ffac42',
          good: '#38d35d',
          strong: '#21ba45',
          very_strong: '#21ba45',
        }
      }
    },
    showInstructions: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 15
    },
    password: {
      type: String,
      required: true,
    }
  }
  ,
  computed: {
    strength () {
      let strength = 0
      if (this.password === '' || this.testSpaces()) {
        return -1
      }
      strength += this.testVeryWeak() ? 24 : 0
      strength += this.testWeak() ? 1 : 0
      strength += this.testGood() ? 25 : 0
      strength += this.testStrong() ? 25 : 0
      return strength
    }
    ,
    color () {
      switch (this.strength) {
        case -1:
          return this.colors.invalid
        case 0:
          return this.colors.very_weak
        case 25:
          return this.colors.weak
        case 50:
          return this.colors.good
        case 75:
          return this.colors.strong
        case 100:
          return this.colors.very_strong
        default:
          return '#ee3824'
      }
    }
  },
  methods: {
    testVeryWeak () {
      return this.password.length >= 5
    },
    testWeak () {
      return this.password.length > 8
    },
    testGood () {
      return this.password.length >= this.length
    },
    testStrong () {
      return this.password.length >= 20
    },
    testSpaces () {
      const spaces = /\s/
      return spaces.test(this.password)
    },
    getStrength () {
      return this.strength
    }
  }
}
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .strength-checker-wrapper
    width: 100%
    color: '#7a7a7a'

  .strength-checker-wrapper .strength-input
    width: 100%
    display: block

  .strength-checker-wrapper ul
    list-style: disc
    padding-inline-start: 0

  .strength-checker-wrapper .strength-bar
    width: 100%
    position: relative
    border-radius: 10px
    height: 3px

  .strength-checker-wrapper .strength-bar .bar
    position: absolute
    left: 0
    top: 0
    border-radius: 10px
    height: 100%
    transition: width 0.3s

</style>
