<template>
  <div id="analysis-list" ref="analysisList">
    <div v-if="isDataProcessing" class="loading-mask">
      <img class="ui centered image" src="../../../assets/img/logo-square-inverse-transparent.png">
      <div>
        Your data is processing.
        <br>Please wait.
      </div>
      <div class="ui small active inline inverted loader"></div>
    </div>
    <abstract-sortable-grid
      v-else
      ref="grid"
      :grid-data="data"
      :working="gettingAnalyses"
      :fetch-data-fn="getAnalyses"
      :sort-options="sortOptions"
      :page-number="pageNumber"
    >
      <!-- Header -->
      <template #header>
        <h3 class="ui left floated header">
          Analysis
        </h3>
        <!-- This outer div to absorb the "height" style on .ui.segment#gridwrapper .row:nth-child(1) -->
        <div>
          <bf-button
            v-show="hasAnalyses"
            color="blue"
            size="large"
            :route-to="{ name: 'analysis-create', params: { projectId: projectId } }"
          >
            <i class="kapiche-icon-microscope"></i><span class="button-label">New analysis</span>
          </bf-button>
        </div>
      </template>

      <!-- Rows -->
      <template #rows>
        <template v-if="hasAnalyses">
          <div
            v-for="analysis in analyses"
            :key="analysis.id"
            class="row"
            :class="{ clickable: isAnalysisViewable(analysis.id) }"
            @click="viewAnalysis(analysis.id)"
            @mouseleave="onMouseLeave($event)"
          >
            <div class="name">
              {{ analysis.name }}<br>
              <!-- Error info -->
              <div class="subtext">
                <div v-if="analysis.status.toUpperCase() === 'ERROR'" class="subtext-detail" :class="{'error-detail': errorDetail(analysis)}">
                  <span class="error">{{ displayError(analysis) }}
                    <span v-if="errorDetail(analysis)">
                      <div class="tooltip">
                        <p>
                          {{ errorDetail(analysis) }}
                        </p>
                      </div>
                    </span>
                  </span>
                  <span>
                    (<a target="_blank" :href="CONST.intercom_links.ANALYSIS_ERRORS">Click for info</a>)
                  </span>
                </div>
                <!-- General info -->
                <div v-else class="subtext-detail">
                  <!-- Date range -->
                  <span v-if="hasDate() && getDates(analysis)" class="date-period">
                    {{ getDates(analysis) }}
                  </span>
                  <!-- Last updated -->
                  <span v-else class="date-period">
                    Last Updated {{ formatDate(analysis.modified) }}
                  </span>
                  <!-- Warning info -->
                  <span v-if="hasLowRecordCount(analysis)" class="warning">
                    Low Record Count (?)
                    <div class="tooltip">
                      <p>
                        Low records may result in very small storyboards and number of concepts.
                      </p>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="status actions">
              <!-- Menu -->
              <div
                :class="{ hidden: !analysisSubmenuVisible(analysis) }"
                class="ui dropdown"
              >
                <div class="text" @click.stop="openDropdown($event)">
                  ...
                </div>
                <div class="menu">
                  <div
                    class="item"
                    @click.stop="showDeleteAnalysisModal(analysis)"
                  >
                    Delete Analysis
                  </div>
                  <div class="item" @click.stop="showEditAnalysisNameModal(analysis)">
                    Edit name
                  </div>
                  <div v-if="featureFlags.analysis_clone" class="item" @click.stop="cloneAnalysis(analysis)">
                    Clone
                  </div>
                  <div v-if="currentUser && currentUser.is_staff" class="item staff-only" @click.stop="toggleAnalysisStale(analysis)">
                    Toggle stale
                  </div>
                  <div v-if="currentUser && currentUser.is_staff" class="item staff-only" @click.stop="rerunAnalysis(analysis)">
                    Rerun Analysis
                  </div>
                </div>
              </div>
              <!-- Normal status flags -->
              <div v-if="analysis.status.toUpperCase() === 'PROCESSING' || analysis.status.toUpperCase() === 'UPDATING'" class="status-processing analysis">
                <div class="ui active tiny inline loader"></div>
                  &nbsp;
                {{ analysis.status.toUpperCase() === 'PROCESSING' ? 'Analyzing' : 'Updating' }}
              </div>
              <div v-else-if="analysis.status.toUpperCase() === 'CREATED'">
                Queued
              </div>
              <div v-else-if="analysis.status.toUpperCase() === 'PENDING'">
                Update Queued
              </div>
              <div v-else-if="analysis.status.toUpperCase() === 'ERROR'" class="status-error">
                Error
              </div>
              <!-- Update stale data -->
              <div v-else-if="analysis.stale && !isDataProcessing">
                <a href="javascript:void(0)" @click.stop="showUpdateAnalysisModal(analysis)">Update</a>
              </div>
              <!-- Empty (ready) -->
              <div v-else>
                &nbsp;
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="analyses" class="row empty" @click="navigateToCreateAnalysis">
          <i class="kapiche-icon-microscope"></i>
          <i class="kapiche-icon-plus"></i>
          <div>Your data is ready.<br>Click here to create an analysis!</div>
        </div>
      </template>
    </abstract-sortable-grid>

    <!-- Delete confirmation -->
    <modal ref="confirmDeleteModal">
      <div class="header">
        Do you want to delete the analysis '{{ analysisToDelete ? analysisToDelete.name : '' }}'?
      </div>
      <div class="image content">
        <div class="description">
          <p>
            This action cannot be reversed. All results for this analysis will be lost.
          </p>
        </div>
      </div>
      <div class="actions">
        <button class="ui cancel basic button">
          No
        </button>
        <button class="ui negative button" @click="deleteAnalysis()">
          Yes, delete the analysis
        </button>
      </div>
    </modal>
    <!-- Update confirmation -->
    <modal ref="confirmUpdateModal">
      <div class="header">
        Do you want to update the analysis '{{ analysisToUpdate ? analysisToUpdate.name : '' }}'?
      </div>
      <div class="content">
        <div class="description">
          Updating an analysis cannot be undone and causes all counts, correlations and queries to reflect the project's data state at the moment the update was performed.
        </div>
      </div>
      <div class="actions">
        <button class="ui cancel basic button">
          No
        </button>
        <button
          class="ui positive button"
          @click="rerunAnalysis(analysisToUpdate)"
        >
          Yes, update the analysis
        </button>
      </div>
    </modal>

    <modal-edit-text
      ref="AnalysisEditName"
      :header="'Edit Analysis Name'"
      :caption="'Change the analysis name to'"
      @save="changeAnalysisName"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import $ from 'jquery'
  import { mapGetters } from 'vuex'

  import AbstractSortableGrid from './AbstractSortableGrid.vue'
  import Project from 'src/api/project'
  import { LOAD_PROJECT } from 'src/store/types'
  import Modal from 'components/widgets/Modal.vue'
  import ModalEditText from 'components/widgets/ModalEditText.vue'
  import DataUtils from 'src/utils/data'
  import { debounce } from 'lodash'
  import { BfButton } from 'components/Butterfly'

  export default defineComponent({

    components: { AbstractSortableGrid, Modal, ModalEditText, BfButton },
    props: { },
    data () {
      return {
        pageNumber: 1,
        pageSize: 4,
        sortBy:'',
        gettingAnalyses: false,
        analysisToDelete: null,
        analysisToUpdate: null,
        data: {},
        sortOptions: [
          { key: '-modified', label: 'Date Modified' },
          { key: '-created', label: 'Date Created' },
          { key: 'name', label: 'Alphanumeric' }
        ],
        getAnalysesDebounced: debounce(
          (pageNumber, sortBy) => {
            if (!this.projectId) {
              // Route might have navigated away by the time the debounce
              // fires. In that case projectId will not be defined so don't
              // bother getting Analyses for it.
              // TODO: see the TODO in the projectId computed prop
              return
            }
            return Project.getAnalyses(pageNumber, this.projectId, sortBy)
                      .then((response) => {
                        if (this.pageNumber === pageNumber) {
                          this.data = response
                        }
                      }).catch(({ status }) => {
                        // We aren't concerned about 401s here because it is either:
                        // 1. The user does not have permission to view the analyses
                        // 2. The user's token has expired.
                        // In scenario 1 - this is handled at the project container level
                        // In scenario 2 - this is handled with a prompt for reauth
                        if (status !== 401) {
                          throw new Error(`Unhandled API response for Project.getAnalyses, status: ${status}`)
                        }
                      }).finally(() => {
                        this.gettingAnalyses = false
                      })
          }, 500 ),
        viewAnalysisAlreadyClicked: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentProject',
        'featureFlags',
        'currentUser',
      ]),
      analyses () {
        return this.data.results
      },
      hasAnalyses () {
        return this.analyses && this.analyses.length > 0
      },
      isDataProcessing () {
        return !!this.currentProject?.processing
      },
      projectId () {
        // TODO: This is messy. rather receive the projectId via prop
        return this.$route.params.projectId
      },
    },
    watch: {
      isDataProcessing (current, prev) {
        if (prev === true && current === false) {
          this.getAnalyses(this.pageNumber, this.sortBy, true, false)
        }
      },
    },
    mounted () {
      this.sortBy = this.sortOptions[0].key
      this.getAnalyses(1, this.sortBy, true)
      // Init analysis actions dropdowns
      this.$nextTick(() => {
        $(this.$el).find('.actions .dropdown').dropdown({
          action: 'hide',
          transition: 'drop'
        })
      })
    },
    methods: {
      async deleteAnalysis () {
        try {
          await Project.deleteAnalysis(this.projectId, this.analysisToDelete.id)
        } catch (e) {
          console.warn('Error while deleting analysis:', e)
        } finally {
          this.analysisToDelete = null
          this.getAnalyses(this.pageNumber, this.sortBy, true)
        }
      },
      async cloneAnalysis (analysis) {
        this.gettingAnalyses = true
        try {
          await Project.cloneAnalysis(this.projectId, analysis.id)
          await this.getAnalyses(this.pageNumber, this.sortBy, true)
        } finally {
          if (this.gettingAnalyses) {
            this.gettingAnalyses = false
          }
        }
      },
      async toggleAnalysisStale (analysis) {
        this.gettingAnalyses = true
        try {
          await Project.updateAnalysisDisplayAttributes(
            this.$store,
            {
              stale: analysis.stale ? 0 : 1,
            },
            analysis
          )
          await this.getAnalyses(this.pageNumber, this.sortBy, true)
        } finally {
          if (this.gettingAnalyses) {
            this.gettingAnalyses = false
          }
        }
      },
      async rerunAnalysis (analysis) {
        this.gettingAnalyses = true
        try {
          await Project.rerunAnalysis(analysis.project, analysis.id, {})
          await this.getAnalyses(this.pageNumber, this.sortBy, true)
        } finally {
          if (this.gettingAnalyses) {
            this.gettingAnalyses = false
          }
          this.$analytics.track.analysis.update(analysis.id)
        }
      },
      displayError (analysis) {
        if (analysis.hasOwnProperty('status_text') && analysis.status_text.toLowerCase().includes('too little data')) {
          return 'Insufficient Data'
        } else if (analysis.hasOwnProperty('status_text') && analysis.status_text.toLowerCase().includes('no words')) {
          return 'No Data Matches Analysis Filters'
        }
        return 'Unexpected Problem'
      },
      errorDetail (analysis): string | undefined {
        if (
            analysis.hasOwnProperty('status_text')
            && analysis.status_text.toLowerCase().includes('too little data to identify enough concepts')
        ) {
          return analysis.status_text
        }
      },
      hasLowRecordCount (analysis): boolean {
        return analysis.status === 'Finished' &&
               analysis.hasOwnProperty('data_units') &&
               analysis.data_units < 400
      },
      formatDate: DataUtils.formatDate,
      getAnalyses (pageNumber, sortBy, force=false, background=false) {
        if (force || (pageNumber !== this.pageNumber)) {
          this.gettingAnalyses = !background
          this.pageNumber = pageNumber
          // Refresh project
          this.$store.dispatch({
            type: LOAD_PROJECT,
            projectId: this.projectId
          })
          return this.getAnalysesDebounced(pageNumber, sortBy)
        }
      },
      getDates (analysis) {
        return DataUtils.parseAnalysisDates(analysis)
      },
      // Traverse project schema and check for date fields
      hasDate () {
        for (let val of this.currentProject?.schema) {
          if (val.type === Project.COLUMN_LABELED_TYPES.get('DATE') || val.type === Project.COLUMN_LABELED_TYPES.get('DATETIME')) {
            return true
          }
        }
        return false
      },
      isAnalysisViewable (id) {
        let analysis = this.data.results.find(a => a.id === id)
        return (
          !this.viewAnalysisAlreadyClicked
          && !analysis.old_topic_model
          && analysis.status.toUpperCase() === 'FINISHED'
        )
      },
      navigateToCreateAnalysis () {
        this.$router.push({ name: 'analysis-create', params: { 'id': this.projectId }})
      },
      // Handle mouse leaving a row -- close its dropdown
      onMouseLeave (event) {
        let rowEl = event.target
        $(rowEl).find('.dropdown').dropdown('hide')
      },
      openDropdown (event) {
        $(event.target.parentNode).dropdown('show')
      },
      showDeleteAnalysisModal (analysis) {
        this.analysisToDelete = analysis
        this.$refs.confirmDeleteModal.show()
      },
      showUpdateAnalysisModal (analysis) {
        this.analysisToUpdate = analysis
        this.$refs.confirmUpdateModal.show()
      },
      // Upgrade analysis with concept model
      async upgradeAnalysis (analysis) {
        this.analysisToUpgrade = analysis
        try {
          // We actually just hijack the update analysis endpoint, because the concept
          // model is regenerated when updating statistics.
          await Project.rerunAnalysis(this.projectId, this.analysisToUpgrade.id, {})
          this.$analytics.track.analysis.upgrade(this.analysisToUpgrade.id)
          this.getAnalyses(this.pageNumber, this.sortBy, true)
        } finally {
          this.analysisToUpgrade = null
        }
      },
      viewAnalysis (id) {
        if (this.viewAnalysisAlreadyClicked) {
          // It is possible that the user can click the analysis row twice
          // rapidly, like a double-click. This can happen fast enough that
          // that this click event handler gets called multiple times before
          // the $router.push actually changes the page. So we set a flag
          // to know if the analysis has already been clicked.
          return
        }

        // Look up the specific analysis by id. If that analysis is not
        // viewable, do nothing.
        let analysis = this.data.results.find(a => a.id === id)
        if (!this.isAnalysisViewable(id)) {
          return
        }

        this.$router.push({
          name: 'view-analysis',
          params: {
            projectId: analysis.project,
            analysisId: analysis.id,
          }
        }, () => undefined, () => this.viewAnalysisAlreadyClicked = false)
        this.viewAnalysisAlreadyClicked = true
      },
      showEditAnalysisNameModal (analysis) {
        this.activeAnalysis = analysis
        this.proposedAnalysisName = this.activeAnalysis.name
        this.$refs.AnalysisEditName.show(
          analysis.name,
          analysis,
        )
      },
      async changeAnalysisName (newName, analysis) {
        await Project.modifyAnalysis(analysis, {name: newName})
        await this.getAnalyses(this.pageNumber, this.sortBy, true)
      },
      analysisSubmenuVisible (analysis) {
        // Analysis submenu is only visible if the analysis is not currently
        // being run, updated, or otherwise busy - except is a user is staff
        // in which case the submenu is always visible. This allows staff
        // to change analysis status and/or rerun the analysis.
        const busyStates = ['PROCESSING', 'UPDATING']
        let busy = busyStates.includes(analysis.status.toUpperCase())
        let is_staff = this.currentUser && this.currentUser.is_staff
        return is_staff || !busy
      },
    }
  })
</script>

<style lang="sass">
  @import 'assets/kapiche.sass'

  #analysis-list
    /* Data processing mask */
    .loading-mask
      background-color: #123f54
      background-image: linear-gradient(#123f54, #012a38)
      color: white
      font-size: rem(20px)
      height: rem(550px)
      line-height: rem(30px)
      padding-top: rem(150px)
      text-align: center
      img
        margin-bottom: rem(15px)
      .loader
        margin-top: rem(15px)
    .row
      overflow: visible !important
    .row.empty
      border-bottom: 0 !important
      color: $blue
      font-size: rem(18px)
      display: block !important
      height: auto !important
      padding-top: rem(50px) !important
      padding-bottom: rem(20px) !important
      text-align: center
      &:hover
        color: $blue-light
        cursor: pointer
      i.kapiche-icon-microscope
        font-size: rem(50px)
      i.kapiche-icon-plus
        font-size: rem(30px)
        position: absolute
        top: rem(65px)
      div
        margin-top: rem(10px)
    .tooltip
      margin: rem(5px) rem(10px) rem(0) rem(0)
      word-break: break-word
      text-transform: initial
    .subtext-detail
      display: flex
      gap: 0 10px
      > *
        text-overflow: ellipsis
        overflow: hidden
        flex: 0 1 auto
      > * + *
        min-width: rem(60px)
        flex: 0 1000000 auto
    .warning
        color: $yellow
    .warning .tooltip
        display: none
    .warning:hover .tooltip
        display: block

    .error-detail
        cursor: pointer
    .error-detail .error::after
        content: "(?)"
    .error-detail .tooltip
        display: none
    .error-detail:hover .tooltip
        display: block

    .name:hover
      .subtext-detail
        flex-wrap: wrap

    .status
      .hidden
        visibility: hidden
      .inline.loader
        vertical-align: text-bottom

  .button-label
    margin-left: rem(10px)


</style>
