<template>
  <div class="ui segments">
    <div class="ui clearing segment header">
      <span class="left floated title">Concept Comparison</span>
      <div class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button
          :name="`${currentAnalysis.name}-Concept Comparison`"
          :get-el="getTopicCorrelationsChartEl"
          :get-csv-data="getCsvData"
          short-name="Concept Comparison"
        ></download-export-button>
      </div>
      <div class="header-buttons right floated">
        <div class="ui buttons">
          <button class="ui button" :class="mode === 'correlation' ? 'active' : ''" @click="setMode('correlation')">
            Correlation
          </button>
          <button class="ui button" :class="mode === 'frequency' ? 'active' : ''" @click="setMode('frequency')">
            Frequency
          </button>
        </div>
      </div>
    </div>

    <div class="ui segment body" :style="{ height: height + 'px' }">
      <div class="ui horizontal list">
        <div class="item">
          <!-- n Entries dropdown -->
          <div class="num-concepts-select">
            <div class="label">
              # OF CONCEPTS
            </div>
            <div class="ui scrolling dropdown">
              <div class="text">
                Top 10
              </div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" :data-value="5">
                  Top 5
                </div>
                <div class="item" :data-value="10">
                  Top 10
                </div>
                <div class="item" :data-value="20">
                  Top 20
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Abs/pos/negative correlation dropdown -->
        <div v-show="mode === 'correlation'" class="item">
          <div class="correlation-type-select">
            <div class="label">
              SORT BY
            </div>
            <div class="ui scrolling dropdown">
              <div class="text">
                Largest Difference
              </div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" data-value="difference">
                  Largest Difference
                </div>
                <div class="item" data-value="positive">
                  Positive
                </div>
                <div class="item" data-value="negative">
                  Negative
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="right aligned item">
          <div class="squares">
            <div class="query_1 square"></div>
            <span class="label">QUERY 1</span>
            <div class="query_2 square"></div>
            <span class="label">QUERY 2</span>
          </div>
        </div>
      </div>

      <div class="chart-container" :style="{ height: height - 80 + 'px' }">
        <!-- Chart -->
        <canvas ref="topicCorrelations"></canvas>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import Chart from 'chart.js'
  import { mapGetters } from 'vuex'
  import $ from 'jquery'

  import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
  import HelpIcon from 'components/project/analysis/results/widgets/HelpIcon.vue'
  import ChartUtils from 'src/utils/chart'
  import Utils from 'src/utils/general'

  export default defineComponent({
    components: { DownloadExportButton, HelpIcon },
    props: {
      'queries': { type: Array, default: () => [] },
      'topicsData': { type: Array, default: () => [] },
      'colours': { type: Array, default: () => [] },
      'height': { type: Number, default: 500 }
    },
    data () {
      return {
        mode: 'correlation',
        correlationType: 'difference',
        numConcepts: 10,
        labels: undefined,
        names: undefined,
        datasets: {},
        help: '<p>This graph shows the strength of the relationship with identified concepts.</p>' +
          '<p>The graph can be toggled between displaying relative frequency and correlation.</p>'
      }
    },
    computed: {
      ...mapGetters([
        'currentAnalysis', 'currentModel'
      ])
    },
    watch: {
      topicsData: {
        immediate: true,
        handler (d) {
          this.marshallData(d)
        }
      }
    },
    mounted () {
      // The mounted hook doesn't always work -- Watch topicsData in case it changes and re-set the dropdown
      // Covers the case for when segment data changes but mounted() is not called
      this.$nextTick(() => {
        $('.num-concepts-select .ui.dropdown').dropdown({
          onChange: (value) => {
            this.numConcepts = value
            this.drawTopicCorrelationsChart(this.labels.slice(0, this.numConcepts), this.datasets)
          }
        })
        // Set the variable for the correlations type slider
        $('.correlation-type-select .ui.dropdown').dropdown({
          onChange: (value) => {
            this.correlationType = value
            this.marshallData(this.topicsData)
            this.$nextTick(() => {
              this.drawTopicCorrelationsChart(this.labels.slice(0, this.numConcepts), this.datasets)
            })
          }
        })
      })
    },
    methods: {
      // This is a reusable method to help us marshall the data -- there are a couple of different sort order cases --
      // depending on settings (correlation, frequency), so we need to call this in a couple of different places.
      // This method takes an array of TWO sets of topic data: [[query_0], [query_1]]
      marshallData (d) {
        this.labels = []
        this.names = []
        if (this.mode === 'correlation') {
          // First, create our array of labels and values for sorting purposes [['label', value], ...]
          let queryCors_0 = d[0] ? Utils.objectToArrays(d[0].correlations) : null
          let queryCors_1 = d[1] ? Utils.objectToArrays(d[1].correlations) : null

          // If only one column has a query, only order that column
          if (!queryCors_0 || !queryCors_1) {
            let labelArray = queryCors_0 || queryCors_1

            if (this.correlationType === 'negative') {
              labelArray = labelArray.sort((a, b) => a[1] - b[1])
            } else { // sort difference as positive for single queries
              labelArray = labelArray.sort((a, b) => b[1] - a[1])
            }
            this.labels = labelArray.map(x => x[0])
          }

          // If we have both, there's a more complicated sorting procedure
          if (queryCors_0 && queryCors_1) {
            // Sort our arrays descending for positive, ascending for negative
            if (this.correlationType === 'positive') {
              queryCors_0 = queryCors_0.sort((a, b) => b[1] - a[1])
              queryCors_1 = queryCors_1.sort((a, b) => b[1] - a[1])
            } else if (this.correlationType === 'negative') {
              queryCors_0 = queryCors_0.sort((a, b) => a[1] - b[1])
              queryCors_1 = queryCors_1.sort((a, b) => a[1] - b[1])
            }
            // This is where we conduct the sort by comparing the two ordered arrays
            while ((queryCors_0[0] && queryCors_1[0]) && this.correlationType !== 'difference') {
              // Push the largest or smallest value, depending on positive or negative
              if (this.correlationType === 'positive') {
                if (queryCors_0[0][1] > queryCors_1[0][1]) {
                  this.labels.push(queryCors_0[0][0])
                } else {
                  this.labels.push(queryCors_1[0][0])
                }
              } else if (this.correlationType === 'negative') {
                if (queryCors_0[0][1] < queryCors_1[0][1]) {
                  this.labels.push(queryCors_0[0][0])
                } else {
                  this.labels.push(queryCors_1[0][0])
                }
              }
              // Remove values from their arrays if they already exist in the label array (whether positive or negative ordering)
              // We have to continue to do this until we have two values which do not exist in the list
              while (queryCors_0[0] && this.labels.indexOf(queryCors_0[0][0]) !== -1) {
                queryCors_0.splice(0, 1)
              }
              while (queryCors_1[0] && this.labels.indexOf(queryCors_1[0][0]) !== -1) {
                queryCors_1.splice(0, 1)
              }
            }

            // Completely different procedure for difference
            if (this.correlationType === 'difference') {
              // For difference, create a "difference" column, sort by it
              // Extract unique list of concepts common to both queries
              let uniqueLabels = Array.from(
                new Set(
                  Object.keys(this.topicsData[0].correlations)
                    .concat(Object.keys(this.topicsData[1].correlations))
                )
              )
              // Construct an ordered array of difference values
              let diffs = [] // [ [label, difference(int)], ... ]
              for (let label of uniqueLabels) {
                diffs.push([
                  label,
                  // Calculate the absolute difference
                  this.topicsData[0].correlations[label] > this.topicsData[1].correlations[label]
                    ? this.topicsData[0].correlations[label] - this.topicsData[1].correlations[label]
                    : this.topicsData[1].correlations[label] - this.topicsData[0].correlations[label]
                ])
              }
              this.labels = diffs.sort((a, b) => b[1] - a[1]).map(x => x[0])
            }
          }
          // End procedure for both queries present
        } else if (this.mode === 'frequency') {
          // Create frequency arrays to then sort
          let queryFreqs_0 = d[0] ? Utils.objectToArrays(d[0].counts) : null
          let queryFreqs_1 = d[1] ? Utils.objectToArrays(d[1].counts) : null
          if (!queryFreqs_0 || !queryFreqs_1) {
            let labelArray = queryFreqs_0 || queryFreqs_1
            labelArray = labelArray.sort((a, b) => b[1] - a[1])
            this.labels = labelArray.map(x => x[0])
          }
          if (queryFreqs_1 && queryFreqs_0) {
            // Convert to percentages and sort
            queryFreqs_0.forEach((x, idx) => { queryFreqs_0[idx][1] = x[1] / d[0].numExcerpts * 100 })
            queryFreqs_1.forEach((x, idx) => { queryFreqs_1[idx][1] = x[1] / d[1].numExcerpts * 100 })
            queryFreqs_0.sort((a, b) => b[1] - a[1])
            queryFreqs_1.sort((a, b) => b[1] - a[1])
            // Perform the sort by popping highest vals off the top and comparing them
            while (queryFreqs_0[0] && queryFreqs_1[0]) {
              if (queryFreqs_0[0][1] > queryFreqs_1[0][1] && queryFreqs_0[0][0]) {
                this.labels.push(queryFreqs_0[0][0])
              } else {
                this.labels.push(queryFreqs_1[0][0])
              }

              // Remove values from their arrays if they already exist in the label array (whether positive or negative ordering)
              while (queryFreqs_0[0] && this.labels.indexOf(queryFreqs_0[0][0]) !== -1) {
                queryFreqs_0.splice(0, 1)
              }
              while (queryFreqs_1[0] && this.labels.indexOf(queryFreqs_1[0][0]) !== -1) {
                queryFreqs_1.splice(0, 1)
              }
            }
          }
        }
        // Now we create the dataset (add data to ordered labels)
        this.datasets = [{
          data: [],
          colour: 'rgb(17, 172, 223)'
        }, {
          data: [],
          colour: 'rgb(248, 149, 22)'
        }]
        if (this.mode === 'correlation') {
          this.labels.forEach(label => {
            this.datasets[0].data.push(this.topicsData[0] ? this.topicsData[0].correlations[label] : 0)
            this.datasets[1].data.push(this.topicsData[1] ? this.topicsData[1].correlations[label] : 0)
          })
        } else if (this.mode === 'frequency') {
          this.labels.forEach(label => {
            this.datasets[0].data.push(this.topicsData[0] && this.topicsData[0].counts[label] ? this.topicsData[0].counts[label] / d[0].numExcerpts * 100 : 0)
            this.datasets[1].data.push(this.topicsData[1] && this.topicsData[1].counts[label] ? this.topicsData[1].counts[label] / d[1].numExcerpts * 100 : 0)
          })
        }
        this.$nextTick(() => {
          this.drawTopicCorrelationsChart(this.labels.slice(0, this.numConcepts), this.datasets)
        })
      },
      // Adjust the chart to use frequency or correlation.
      setMode (mode) {
        if (mode !== this.mode) {
          this.mode = mode
          this.marshallData(this.topicsData)
          this.$nextTick(() => {
            this.drawTopicCorrelationsChart(this.labels.slice(0, this.numConcepts), this.datasets)
          })
        }
      },
      drawTopicCorrelationsChart: function (topicLabels, inputDataset) {
        if (this.conceptCorrelationsChart) {
          this.conceptCorrelationsChart.destroy()
        }
        let chartEl = this.$refs.topicCorrelations
        this.conceptCorrelationsChart = new Chart(chartEl.getContext('2d'), {
          type: 'horizontalBar',
          plugins: [ChartUtils.horizontalZeroCompensationPlugin],
          data: {
            labels: topicLabels,
            datasets: [
              {
                label: 'Query 1',
                borderWidth: 0,
                borderSkipped: 'bottom',
                barPercentage: 0.75,
                data: inputDataset[0].data.slice(0, this.numConcepts), // We have to trunc these because chartjs is a nugget
                backgroundColor: inputDataset[0].colour
              }, {
                label: 'Query 2',
                borderWidth: 0,
                borderSkipped: 'bottom',
                barPercentage: 0.75,
                data: inputDataset[1].data.slice(0, this.numConcepts),
                backgroundColor: inputDataset[1].colour
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, _data) => {
                  if (this.mode === 'frequency') {
                    return `Query ${tooltipItem.datasetIndex + 1}: ${ChartUtils.percentLabel(tooltipItem)}`
                  }
                  const label = tooltipItem?.xLabel?.toFixed(2) ?? ""
                  return `Query ${tooltipItem.datasetIndex + 1}: ${label}`
                }
              }
            },
            // On click, emit event
            onClick: (event) => {
              let el = this.conceptCorrelationsChart.getElementAtEvent(event)
              if (el.length > 0) {
                let topicName = this.labels[el[0]._index]
                if (!this.isConceptInQuery(topicName)) {
                  this.$emit('concept-clicked', [el[0]._datasetIndex, topicName])
                }
              }
            },
            hover: {
              onHover: (chart, el) => {
                if (el.length > 0) {
                  let topicName = this.labels[el[0]._index]
                  if (!this.isConceptInQuery(topicName)) {
                    chartEl.style.cursor = 'pointer'
                    return
                  }
                }
                chartEl.style.cursor = 'default'
              }
            },
            scales: {
              yAxes: [{
                gridLines: {
                  display: true,
                  zeroLineWidth: 0,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR
                }
              }],
              xAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: this.mode === 'correlation' ? 'Correlation' : 'Relative Frequency',
                  fontStyle: 'bold'
                },
                barPercentage: 0.2,
                type: this.mode === 'correlation' ? this.mode : 'linear',
                position: 'bottom',
                gridLines: {
                  display: true,
                  zeroLineWidth: 1,
                  color: ChartUtils.AXIS_COLOUR,
                  zeroLineColor: ChartUtils.AXIS_COLOUR
                },
                ticks: this.mode === 'frequency' ? ChartUtils.percentTicks(this.scaledTopicFrequencies) : { min: -1, max: 1 }
              }]
            }
          }
        })
      },
      // Determine if `conceptName` is part of the driving query of this widget.
      isConceptInQuery (conceptName) {
        return this.queries.find((n) => n.node_type === 'topic' && n.value === conceptName) !== undefined
      },
      getTopicCorrelationsChartEl () {
        return this.$refs.topicCorrelations
      },
      getCsvData () {
        return this.labels.map((topicName, i) => {
          return {
            topic: topicName,
            label: this.currentModel.topics[topicName].labelFull,
            'Query 1 data': this.mode === 'frequency'
              ? ChartUtils.formatPercent(this.datasets[0].data[i])
              : this.datasets[0].data[i],
            'Query 2 data': this.mode === 'frequency'
              ? ChartUtils.formatPercent(this.datasets[1].data[i])
              : this.datasets[1].data[i]
          }
        })
      }
    },
  })
</script>

<style lang="sass" scoped>
  div.ui.horizontal.list
    width: 100%
    .right.aligned.item
      float: right
    .squares
      display: flex
      margin-right: 33px
      span.label
        color: rgba(56, 56, 56, 0.9)
        font-size: 11px
        font-weight: bold
        line-height: 20px
      .square
        border-radius: 3px
        margin-left: 20px
        margin-right: 10px
        width: 20px
        height: 20px
        &.query_1
          background-color: rgb(17, 172, 223) !important
        &.query_2
          background-color: rgb(248, 149, 22) !important

  .header .left.floated
    float: left
  .header
    padding: 0 !important
    .header-buttons
      padding: 10px 20px
      .button.active, .button:hover
        background: rgba(149, 166, 172, 1)
        color: white
      .button
        background: rgba(149, 166, 172, 0.15)
        border: 1px solid rgba(149, 166, 172, 0.4) !important
        color: rgb(149, 166, 172)
        font-weight: bold
        width: 115px
        &:first-child
          border-right: 0 !important
      .ui.buttons .button:first-child
        border-top-left-radius: .28571429rem
        border-bottom-left-radius: .28571429rem
      .ui.buttons .button:last-child
        border-top-right-radius: .28571429rem
        border-bottom-right-radius: .28571429rem
  .num-concepts-select
    background: white
    padding-left: 4rem
    .label
      color: #95a6ac
      font-weight: bold
      font-size: 12px
    .ui.dropdown
      font-weight: bold
      color: #068ccc
      border-bottom: 4px solid #068ccc
      .icon
        margin-left: 0.5em
  .correlation-type-select
    background: white
    padding-left: 4rem
    .label
      color: #95a6ac
      font-size: 12px
      font-weight: bold
    .ui.dropdown
      font-weight: bold
      color: #068ccc
      border-bottom: 4px solid #068ccc
      .icon
        margin-left: 0.5em

</style>
