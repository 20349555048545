<template v-slot:row-tool-tip>
  <tool-tip-panel class="themes-chart-tooltip">
    <chart-tool-tip-body>
      <template #body>
        <div v-show="showDrilldown" class="link">
          Click to drill down into this phrase
        </div>
        <p><strong>Phrase text</strong>: {{ dataItem.phrase_text }}</p>
        <p><strong>Freq of phrase drilldown</strong>: {{ dataItem.count_pair }}</p>
        <p><strong>Coverage relative to overall</strong>: {{ coverageOverall }}</p>
        <p><strong>Coverage relative to drilldown</strong>: {{ coverageDrilldown }}</p>
        <p v-show="dataItem.npmi !== 0">
          <strong>Correlation</strong>: {{ roundNpmi }}
        </p>
      </template>
    </chart-tool-tip-body>
  </tool-tip-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ToolTipPanel from 'components/widgets/ToolTipPanel/ToolTipPanel.vue'
import ChartToolTipBody from 'components/widgets/ChartToolTipBody/ChartToolTipBody.vue'
import dayjs from 'dayjs'
import { APIResponseDataItem, roundValue } from "components/DataWidgets/KeyPhrases/KeyPhrases.utils"
import ChartUtils from "src/utils/chart"

export default defineComponent({
  components: { ToolTipPanel, ChartToolTipBody },
  props: {
    showDrilldown: { type: Boolean, required: true },
    dataItem: { type: Object as unknown as APIResponseDataItem, required: true },
  },
  computed: {
    startDate () {
      return dayjs(this.customDates[0], 'DD/MM/YYYY').format('D MMM, YYYY')
    },
    endDate () {
      return dayjs(this.customDates[1], 'DD/MM/YYYY').format('D MMM, YYYY')
    },
    coverageDrilldown () {
      return ChartUtils.formatPercent((this.dataItem.coverage_pair_group ?? 0) * 100)
    },
    coverageOverall () {
      return ChartUtils.formatPercent((this.dataItem.coverage_pair ?? 0) * 100)
    },
    roundNpmi () {
      return roundValue(this.dataItem.npmi ?? 0)
    },
  },
})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .tooltip
    min-width: 480px

    p
      font-size: 12pt

    hr
      border: none

    h1
      font-weight: bold

    h2>label
      float: right
      font-weight: bold

    h2.nps > label
      color: #11ACDF

    h2.negative > label
      color: rgb(238, 56, 36)
      font-weight: bold
    h2.positive > label
      color: rgb(33, 186, 69)
      font-weight: bold
    h2.neutral > label
      color: #7f7f7f
      font-weight: bold
    h2.mixed > label
      color: #f89516
      font-weight: bold
</style>
