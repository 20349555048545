<template>
  <div class="cues-collapse">
    <el-collapse @change="cuesTrigger">
      <el-collapse-item>
        <template #title>
          <div class="cues-header">
            <span class="label">
              Insight Cues <span class="ai-label beta">AI GENERATED</span>
            </span>
          </div>
        </template>
        <el-container
          v-loading="loading"
          element-loading-text="Generating Cues ..."
          :style="{minHeight: '50px', margin: '5px'}"
        >
          <div v-if="!cuesStale" class="content" v-html="content" />
          <a v-else-if="!loading" class="refresh-link" @click="updateCue"><i class="icon refresh" />Update cues</a>
        </el-container>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

const InsightCues = defineComponent({
  components: {
  },
  props: {
    cuesStale: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    content: { type: String, required: false, default: '' },

  },
  setup (props, { emit }) {

    const cuesTrigger = (sections: Array<string>) => {
      const expanded = sections.length > 0
      if (expanded && props.cuesStale && !props.loading) {
        emit('fetch')
      }
    }

    const updateCue = () => {
      emit('fetch')
    }

    return {
      cuesTrigger,
      updateCue,
    }
  }
})

export default InsightCues
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.cues-collapse
  width: inherit

.cues-header
  display: flex
  justify-content: space-between
  width: inherit

::v-deep
  .content
    h1, h2, h3
      font-size: 15px
      font-weight: bold

.label
  font-size: 14px
  font-weight: bold
  letter-spacing: 0.6px
  color: $text-black
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.beta::after
  margin: 0rem 0.3rem !important
  content: 'BETA' !important
  background-color: $green-light !important
  padding: 0.2rem 0.4rem !important
  border-radius: 3px !important
  border: 1px solid lighten($green-light, 10%)
  color: white
  font-size: 0.7rem !important
  font-weight: bold
  letter-spacing: 2px
  height: 1rem !important
  position: relative !important
  top: -0.1rem !important

.ai-label
  font-size: 14px
  letter-spacing: 0.6px
  color: rgb(149, 166, 172)
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.refresh-link
  cursor: pointer
  text-decoration: none
  &:hover
    color: $blue-light
</style>


