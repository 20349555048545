<template>
  <div class="ui segments">
    <!-- dimmer -->
    <div v-if="isLoading" class="ui active inverted dimmer timeline-dimmer">
      <div class="ui loader"></div>
    </div>
    <div class="ui clearing segment header">
      <span class="left floated title">Timeline Trend</span>

      <!-- Help and download -->
      <span class="icons right floated">
        <help-icon :content="help"></help-icon>
        <download-export-button
          :name="`${currentAnalysis.name}-Timeline`"
          :is-loading="isLoading"
          :get-el="getTrendEl"
          :get-svg-export-config="getExportConfig"
          short-name="Timeline"
        >

        </download-export-button>
      </span>

      <!-- Normalisation options -->
      <div class="header-buttons right floated">
        <div class="ui buttons">
          <button class="ui button" :class="{ active : mode === 'normalised' }" @click="mode = 'normalised'">
            Normalized
          </button>
          <button class="ui button" :class="{ active : mode === 'raw' }" @click="mode = 'raw'">
            Raw Freq.
          </button>
        </div>
      </div>
    </div>

    <div class="ui segment">
      <div class="ui horizontal list dropdowns">
        <div class="item">
          <span id="field" class="variable-select-timeline">
            <div class="dropdown-label">
              Field:
            </div>
            <div class="ui scrolling dropdown">
              <div class="text">{{ field }}</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div v-for="(variableName,index) in currentModel.dateFields" :key="index" class="item" :data-value="variableName.name">
                  {{ variableName.name }}
                </div>
              </div>
            </div>
          </span>
        </div>

        <div class="item">
          <span id="resolution" class="variable-select-timeline">
            <div class="dropdown-label">
              Resolution:
            </div>
            <div class="ui scrolling dropdown">
              <div class="text">Monthly</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" data-value="daily">Daily</div>
                <div class="item" data-value="weekly">Weekly</div>
                <div class="item" data-value="monthly">Monthly</div>
                <div class="item" data-value="yearly">Yearly</div>
              </div>
            </div>
          </span>
        </div>

        <div class="right aligned item">
          <div class="squares">
            <div class="query_1 square"></div>
            <span class="label">QUERY 1</span>
            <div class="query_2 square"></div>
            <span class="label">QUERY 2</span>
          </div>
        </div>
      </div>

      <!-- Render topic timeline -->
      <div id="timeline-container">
        <template v-if="timelineData">
          <div ref="timeline-trend" class="timeline"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import HelpIcon from 'src/components/project/analysis/results/widgets/HelpIcon.vue'
import DownloadExportButton from 'src/components/project/analysis/results/widgets/DownloadExportButton.vue'
import TimelineTrendBase from 'src/components/project/analysis/results/widgets/TimelineTrendBase.vue'

export default defineComponent({
  components: { HelpIcon, DownloadExportButton },
  extends: TimelineTrendBase,
  computed: {
    ...mapGetters([
      'currentAnalysis', 'analysisTimeline', 'currentModel'
    ]),
    help () {
      return `<p>Graphs the trajectory of this query over time.</p>`
    }
  },
  methods: {
    // This method can be overwritten if the colour assignments need to change
    _assignColours (timelineData, sortedTimelineKeys) {
      // Assign colours to the objects based on frequency ordered list
      sortedTimelineKeys.forEach((key, idx) => {
        timelineData['Query 1'].colour = 'rgb(17, 172, 223)'
        timelineData['Query 2'].colour = 'rgb(248, 149, 22)'
      })
      return timelineData
    }
  }
})
</script>

<style lang="sass" scoped>
  /* There are some inheritence issues with the style sheets when using template components */
  .squares
    display: flex
    margin-right: 33px
    span.label
      color: rgba(56, 56, 56, 0.9)
      font-size: 11px
      font-weight: bold
      line-height: 20px
    .square
      border-radius: 3px
      margin-left: 20px
      margin-right: 10px
      width: 20px
      height: 20px
      &.query_1
        background-color: rgb(17, 172, 223) !important
      &.query_2
        background-color: rgb(248, 149, 22) !important
  .ui.horizontal.list.dropdowns
    width: 100%
    .right.aligned.item
      float: right

  #timeline-container
    height: 200px !important
    .timeline
      height: 200px !important

  .header-buttons
    padding: 10px 20px !important
    .button.active, .button:hover
      background: rgba(149, 166, 172, 1)
      color: white
    .button
      background: rgba(149, 166, 172, 0.15)
      border: 1px solid rgba(149, 166, 172, 0.4) !important
      color: rgb(149, 166, 172)
      font-weight: bold
      width: 115px
      &:first-child
        border-right: 0 !important
    .ui.buttons .button:first-child
      border-top-left-radius: .28571429rem
      border-bottom-left-radius: .28571429rem
    .ui.buttons .button:last-child
      border-top-right-radius: .28571429rem
      border-bottom-right-radius: .28571429rem

</style>
