<template>
  <div class="add-data-container wizard-layout">
    <progress-indicator :num-steps="2" :step="step"></progress-indicator>

    <!-- Header -->
    <h2 v-if="step === 1">
      Add Data to Project
    </h2>
    <h2 v-else-if="step === 2">
      Map Columns to Project Schema
    </h2>

    <!-- Step 1: Select data file -->
    <div v-show="step === 1" class="step-container">
      <div v-if="!isFileSelected">
        Data files in a project must follow the same column structure. <a :href="CONST.intercom_links.ADDING_DATA" target="_blank">Learn more</a>.
      </div>
      <data-upload
        ref="dataUpload"
        :add-data="true"
        :file-row-limit="fileRowLimit"
        :file-column-limit="fileColumnLimit"
        :project-row-limit="subscription.projectRowLimit"
        :project-column-limit="subscription.projectColumnLimit"
        @file-ready="onFileReady"
        @file-cleared="onFileCleared"
      />
    </div>

    <!-- Step 2: Schema mapping -->
    <div v-show="step === 2" class="step-container">
      <div>Match the columns of the new file with the columns of the existing Project data.</div>
      <div class="mapping-table">
        <div class="ui two column grid mapping-header">
          <div class="row">
            <div class="column">
              New Data File
            </div>
            <div class="column">
              Existing Schema
            </div>
          </div>
        </div>
        <div class="ui two column grid mapping-body">
          <div v-for="(col, index) of schema" :key="index" class="row">
            <div class="column">
              {{ col.name }}
            </div>
            <div class="column">
              <div class="ui selection dropdown fluid">
                <input type="hidden" :name="'col-' + index" value="-1" @change="checkUnmappedProjectFields">
                <i class="dropdown icon"></i>
                <div class="default text">
                  Select Column
                </div>
                <div class="menu">
                  <div v-for="i of mappableSchema" :key="i.index" class="item" :data-value="i.index">
                    {{ i.name.trim() }}
                  </div>
                  <div class="item" data-value="-1">
                    DO NOT IMPORT
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showDataUnits" class="data-units-text">
        <p>
          Adding this data will use:<br>
          <span class="data-units">
            {{ number(numResponses) }} Data Units
          </span>
        </p>
      </div>
      <el-alert
        v-if="unmappedProjectFields.length"
        title="Warning!"
        type="warning"
        show-icon
        :closable="false"
        class="warning"
        center
      >
        The following fields have not been mapped to a column in the new data file.
        <ul>
          <li v-for="field in unmappedProjectFields" :key="field">
            "{{ field }}"
          </li>
        </ul>
      </el-alert>
    </div>
    <!-- No text field error -->
    <div v-if="noTextFieldError" class="error-text">
      Must select at least one text field from ({{ projectTextFields.join(', ') }})
    </div>
    <div v-if="incompatibleSchemaError" class="error-text">
      Missing Unique ID field: {{ incompatibleSchemaError }}
    </div>

    <!-- Actions -->
    <div class="actions">
      <router-link v-if="!canGoBack" :to="{ name: 'project-details', params: { projectId: projectId }}" class="ui button cancel">
        Cancel
      </router-link>
      <button v-else class="ui button cancel" :class="{ 'loading': isLoading, 'events-disabled': isLoading }" @click="goBack">
        Back
      </button>
      <button v-if="canGoNext" class="ui button blue" :class="{ 'loading': isLoading, 'events-disabled': isLoading }" @click="goNext">
        {{ step === 2 ? 'Add Data to Project' : 'Proceed' }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import $ from 'jquery'
  import { mapGetters } from 'vuex'

  import DataUpload from 'src/components/project/create/DataUpload.vue'
  import ProgressIndicator from 'src/components/widgets/ProgressIndicator.vue'
  import Project from 'src/api/project'
  import FormatUtils from 'src/utils/formatters'
  import { CLEAR_REQUEST_ERRORS } from 'src/store/types'

  export default defineComponent({
    components: { DataUpload, ProgressIndicator },
    data () {
      return {
        dataFile: null,
        dropdowns: null,
        isLoading: false,
        isMounted: false,
        isFileSelected: false,
        noTextFieldError: false,
        incompatibleSchemaError: false as boolean|string,
        step: 1,
        unmappedProjectFields: [] as string[],
      }
    },
    computed: {
      ...mapGetters([
        'currentProject', 'plan', 'subscription'
      ]),
      canGoBack () {
        return this.step > 1 || this.isFileSelected
      },
      canGoNext () {
        return this.step > 1 || this.dataFile !== null
      },
      fileRowLimit () {
        return Math.min(500000, this.subscription.projectRowLimit ?? Infinity)
      },
      fileColumnLimit () {
        return Math.min(500, this.subscription.projectColumnLimit ?? Infinity)
      },
      numResponses () {
        if (this.dataFile) {
          return this.$refs.dataUpload.numResponses
        }
        return 0
      },
      projectId () {
        return this.$route.params.projectId
      },
      // List of project text fields by name
      projectTextFields () {
        let textFields = []
        this.currentProject.schema.forEach((f) => {
          if (f.type === 1) {
            textFields.push(f.name)
          }
        })
        return textFields
      },
      schema () {
        if (this.dataFile) {
          return this.dataFile.schema
        }
        return []
      },
      showDataUnits () {
          return this.plan === 'data-unit-plan'
      },
      mappableSchema () {
        return this.currentProject.schema.filter(s=>s.name !== 'NPS Category')
      },
    },
    watch: {
      dataFile () {
        this.$nextTick(() => {
          this.dropdowns = $(this.$el).find('.mapping-table .ui.dropdown').dropdown()
          let schema = this.schema
          const dropdownOptions = this.mappableSchema.map(col => col.name.trim())
          this.dropdowns.each(function (i) {
            const newIdx = dropdownOptions.indexOf(schema[i].name.trim())
            $(this).dropdown('set selected', newIdx.toString())
          })
          this.checkUnmappedProjectFields()
        })
      }
    },
    mounted () {
      this.isMounted = true  // refs available
    },
    methods: {
      number: FormatUtils.number,
      // Check current mappings to determine if a text column is selected
      checkMappings () {
        let mappings = this.getMappings()
        let hasMappedText = false
        for (let v of Object.values(mappings)) {
          if (v !== -1 && this.currentProject.schema[v].type === 1) {
            hasMappedText = true
            break
          }
        }
        return hasMappedText
      },
      // Return list of current mappings.
      getMappings () {
        let mappings = {}
        this.dropdowns.each((i, el) => {
          mappings[this.schema[i].name] = parseInt($(el).dropdown('get value'), 10)
        })
        return mappings
      },
      checkUnmappedProjectFields (): string[] {
        if (!this.dropdowns) return []
        const usedIdxes = Object.values(this.getMappings()).filter(val => val !== -1)
        this.unmappedProjectFields = this.mappableSchema.filter(s => !usedIdxes.includes(s.index)).map(s => s.name)
      },
      // Trigger the indexing of data by the server.
      indexData () {
        this.isLoading = true
        this.incompatibleSchemaError = false
        let dataFileId = this.dataFile.id
        let mappings = this.getMappings()
        // Start indexing
        Project.indexDataFiles(
          this.projectId, [dataFileId], mappings
        ).then(() => {
          this.dataAdded = true
          this.$analytics.track.project.addData(this.$refs.dataUpload.fileSize, this.numResponses)
          this.$router.push({ name: 'project-details', params: { projectId: this.projectId }})
        }).catch((error) => {
          this.$store.dispatch(CLEAR_REQUEST_ERRORS)  // use custom instead of global error handling
          if (error.body['non_field_errors']?.[0]?.toLowerCase().includes("incompatible schema")) {
            let nonFieldErrors = error.body['non_field_errors'][0]
            this.incompatibleSchemaError = nonFieldErrors
          }
        }).finally(() => {
          this.isLoading = false
        })
      },
      goBack () {
        this.noTextFieldError = false
        if (this.step === 1) {
          // Reset data upload state
          this.$refs.dataUpload.clearUpload()
        } else {
          this.step -= 1
        }
      },
      goNext () {
        if (this.step === 2) {
          if (!this.checkMappings()) {
            this.noTextFieldError = true
            return
          }
          this.indexData()
          return
        }
        this.noTextFieldError = false
        this.step += 1
      },
      onFileCleared () {
        this.dataFile = null
        this.isFileSelected = false
      },
      onFileReady (dataFile) {
        this.dataFile = dataFile
      },
      onObjectSelected () {
        this.isFileSelected = true
      }
    }
  })
</script>
<style lang="sass">
  @import '../../assets/kapiche.sass'
  @import '../../assets/mixins.sass'

  .add-data-container
    font-size: rem(16px)
    .button
      font-size: rem(18px) !important

    .step-container
      padding-top: rem(20px)

      /* Schmea mapping table */
      .mapping-table
        box-shadow: $box-shadow
        margin-top: rem(50px)
        text-align: left
        .grid
          margin: 0 !important
        .row, .mapping-header, .mapping-body
          padding: 0 !important
        .mapping-body .column
          border-top: 1px solid $grey
        .mapping-header, .mapping-body
          .column
            height: rem(70px)
            padding-left: rem(30px)
            &:nth-child(1)
              background: white
              border-right: 1px solid $grey
              padding-top: rem(25px)
            &:nth-child(2)
              background: #f7f9fa
              padding-top: rem(13px)
            div.ui.dropdown
              width: calc(100% - 20px)
        .mapping-header
          font-size: rem(12px)
          font-weight: bold
          letter-spacing: rem(0.6px)
          text-transform: uppercase
          .column
            padding-top: rem(25px) !important

      .data-units-text
        font-size: rem(20px)
        margin-top: rem(50px)
        .data-units
          font-size: rem(26px)
          font-weight: bold
          margin-top: rem(15px)

      .warning
        margin-top: 40px
        li
          text-align: left

    /* Error */
    .error-text
      margin-top: rem(20px)

    /* Actions */
    .actions
      margin-top: rem(50px)
      padding-bottom: rem(50px)
</style>
