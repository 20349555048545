<template>
  <div class="root">
    <el-container
      v-loading="isLoading"
      :element-loading-text="selectedProduct ? 'Fetching product info...' : 'Fetching product list...'"
      :style="{minHeight: '50px', width: '750px'}"
    >
      <div v-if="errorMsg" class="error-msg">
        {{ errorMsg }}
      </div>
      <div v-if="productRecordCount && !errorMsg" class="confirm-text">
        <div v-if="productRecordCount > maxRecords">
          {{ selectedProduct?.["Product Name"] }}  [{{ selectedProduct?.["Product Code"] }}] has <b class="record-limit-warning">{{ productRecordCount }}</b>  records, <br> but the current limit is <b>{{ maxRecords }}</b>.
          <br><br>Please contact us if you would like to load this product data.
        </div>
        <div>
          Import <b>{{ productRecordCount }}</b> records from {{ selectedProduct?.["Product Name"] }}  [{{ selectedProduct?.["Product Code"] }}]?
        </div>
      </div>
      <div v-else-if="!selectedProduct && products" class="advantage-products">
        <div>
          <el-input
            :model-value="productSearch"
            placeholder="Type to search product name/code"
            class="filter"
            @update:model-value="productSearch = $event"
          />
        </div>

        <div class="products">
          <div class="header">
            <div>Product</div>
            <div>Market</div>
            <div>Language</div>
            <div>Subject</div>
            <div>Period</div>
          </div>
          <virtual-list
            ref="scroller"
            style="height: 600px; width:750px; overflow-y: auto;   scrollbar-gutter: stable;"
            :data-key="'id'"
            :data-sources="filteredProducts"
            :data-component="itemComponent"
            :extra-props="{ onRowClick: selectAdvantageProduct }"
            @row-click="selectAdvantageProduct"
          />
        </div>
      </div>
    </el-container>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref, computed, onMounted } from 'vue'
  import Advantage from 'src/api/advantage'
  import AdvantageProductItem from './AdvantageProductItem.vue'
  import { Product, ProductInfo } from './types'
  import VirtualList from 'vue3-virtual-scroll-list'

  // There are a few huge product tables in the Advantage DB
  // We'll put a conservative upper limit on the largest table we
  // will import for the initial implementation.
  const MAX_RECORD_COUNT = 100000

  export default defineComponent({
    components: { VirtualList },
    setup () {
      const products = ref<Array<Product>|null>(null)
      const productSearch = ref<string>('')
      const isLoading = ref<boolean>(false)
      const selectedProduct = ref<Product|null>(null)
      const productRecordCount = ref<number|null>(null)
      const scroller = ref(null)
      const errorMsg = ref<string|null>(null)

      onMounted(() => {
        isLoading.value = true
        Advantage.getProducts()
          .then((response) => {
            products.value = response
            products.value.sort((a, b) => {
              return a["Product Name"].localeCompare(b["Product Name"])
                || a["Market Code"].localeCompare(b["Market Code"])
                || a["Language"].localeCompare(b["Language"])
                || a["Survey Subject"].localeCompare(b["Survey Subject"])
                || a["Reporting Period"].localeCompare(b["Reporting Period"])
            })
            products.value.forEach(p => p['Last Updated'] = new Date(p["Updated Timestamp"]).toLocaleString())
          })
          .catch(() => {
            errorMsg.value = "Unexpected error fetching product list. Please go back and try again and if the problem persists, contact support."
          })
          .finally(() => {
            isLoading.value = false
          })
      })

      const selectAdvantageProduct = (product: Product) => {
        isLoading.value = true
        selectedProduct.value = product
        Advantage.getProductRecordCount(product.id)
        .then((response) => {
          if (!selectedProduct.value || selectedProduct.value.id !== product.id) {
            // Back button has been pressed and product is no longer selected, bail out
            return
          }
          productRecordCount.value = response.num_records
        })
        .catch(() => {
          errorMsg.value = "Unexpected error fetching product information. Please go back and try again and if the problem persists, contact support."
        })
        .finally(() => {
          if (selectedProduct.value?.id !== product.id) {
            // Same product is no longer selected;
            // Can happen when pressing back and selecting
            // a different product, causing re-entrant promises.
            // Bail out
            return
          }
          isLoading.value = false
        })
      }
      const goBack = (): boolean => {
        errorMsg.value = null
        if (selectedProduct.value) {
          selectedProduct.value = null
          productRecordCount.value = null
          isLoading.value = false
          return true
        }
        return false
      }
      const runImport = async (): Promise<void> => {
        if (!selectedProduct.value) {
          return
        }
        return Advantage.importProductData(selectedProduct.value.id)
          .catch(() => {
            errorMsg.value = "Unexpected error importing product data. Please go back and try again and if the problem persists, contact support."
          })
      }
      const filteredProducts = computed((): Array<Product>|null => {
        if (products.value === null) {
            return null
        }
        return products.value.filter(p => {
          const s = productSearch.value.toLowerCase()
          return p["Product Name"].toLowerCase().includes(s)
            || p["Product Code"].toLowerCase().includes(s)
        })
      })
      const readyToConfirm = computed((): boolean => {
        return selectedProduct.value !== null && productRecordCount.value !== null && productRecordCount.value <= MAX_RECORD_COUNT
      })

      return {
        isLoading,
        products,
        productSearch,
        selectedProduct,
        productRecordCount,
        selectAdvantageProduct,
        goBack,
        filteredProducts,
        runImport,
        readyToConfirm,
        scroller,
        maxRecords: MAX_RECORD_COUNT,
        itemComponent: AdvantageProductItem,
        errorMsg,
      }
    }
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  :deep .el-loading-mask
    background: transparent
  :deep .el-loading-text
    padding-left: 0

  .filter
    margin-top: 15px
    width: 500px

  div.products
    margin-top: 20px

  div.header
    background: white
    width: 740px
    display: flex
    flex-direction: row
    div
      border-bottom: 1px solid #ddd
      border-right: 1px solid #ddd
      border-top: 1px solid #ddd
      font-weight: bold
      flex: 2 1 auto
      padding: 10px
      text-align: left
      width: 50px
      &:first-child
        border-left: 1px solid #ddd
        width: 200px

  div.confirm-text
    font-size: 18px
    line-height: 24px
    margin-top: 15px
    width: 100%

  .record-limit-warning
    color:$red

  div.error-msg
    color: red
    margin-top: 10px
</style>