<template>
  <div class="ui stackable grid">
    <!-- Rerun analysis warning modal -->
    <div class="ui tiny modal rerun-warning">
      <div class="header">
        Confirmation
      </div>
      <div class="content">
        <p>Note: The storyboard may change based on any settings that affect the language model.</p>
        <br>
        <div class="ui large button" @click="rerunModal('hide')">
          Cancel
        </div>
        <button class="green ui large button" :class="{ disabled: !settingsChanged }" @click="rerun">
          I understand, rerun analysis
        </button>
      </div>
    </div>

    <!-- Rerun prompt -->
    <div id="settingsHeader" class="one column row">
      <div class="column">
        <div class="ui blue segment">
          <div class="ui grid two column">
            <div class="column">
              <h3>Adjust Settings</h3>
              <p>You can adjust the settings below and rerun this analysis.</p>
            </div>
            <div class="column middle aligned" style="text-align: right">
              <button class="green ui button" :class="{ disabled: !settingsChanged }" @click="rerunModal('show')">
                <i class="repeat icon"></i>Rerun Analysis
              </button>
              <button class="red ui button" :class="{ disabled: !settingsChanged }" @click="resetSettings">
                <i class="delete icon"></i>Discard Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Default date field -->
    <div class="one column row">
      <div class="column">
        <div class="ui segment">
          <div class="ui grid">
            <div class="column ten wide">
              <h4 class="ui header">
                Default Date Field
              </h4>
              <p>
                This will be used as the default selected field for any widgets using dates.
              </p>
            </div>
            <div class="column six wide right aligned">
              <div v-if="dateFields.length === 0" style="padding-top: 20px; font-size: 16px">
                (No date field present)
              </div>
              <div v-else>
                <simple-dropdown
                  :value="defaultDateField"
                  :options="dateFields.map(f => [f.name, f.name])"
                  placeholder-text="No date field selected..."
                  style="text-align: left"
                  @update:value="defaultDateField = $event"
                ></simple-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Upgrade to use memory limit for segments over unique values -->
    <div v-if="currentModel.upgrade_to_segments_size_limit" class="one column row">
      <div class="column">
        <div class="ui segment">
          <div class="ui grid">
            <div class="column fourteen wide">
              <h4 class="ui header">
                Upgrade analysis to support increased number of segments
              </h4>
              <p>
                Check this setting and re-run the analysis to permanently upgrade it.
                The upgrade will allow this analysis to query for an increased number
                of segments using the Query tool. (New analyses you create will already
                have the upgrade)
              </p>
            </div>
            <div class="column two wide middle aligned">
              <div class="ui checkbox">
                <input id="segmentsUpgrade" v-model="runUpdate" type="checkbox">
                <label for="segmentsUpgrade"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Stopwords -->
    <div class="one column row">
      <div class="column">
        <div class="ui segment">
          <h4>Stopwords</h4>
          <stopwords-description></stopwords-description>
          <div class="ui divider"></div>
          <br>
          <stopwords ref="stopwords" :initial-stopwords="initialStopwords" @stopwordsUpdated="updateStopwords"></stopwords>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'
  import _ from 'lodash'
  import $ from 'jquery'

  import SimpleDropdown from 'components/widgets/SimpleDropdown.vue'
  import Stopwords from 'components/project/analysis/results/widgets/Stopwords.vue'
  import StopwordsDescription from 'components/project/analysis/create/widgets/StopwordsDescription.vue'
  import Project from 'src/api/project'
  import { SET_ANALYSIS, SET_MESSAGE } from 'src/store/types'
  import FormatUtils from 'src/utils/formatters'

  export default defineComponent({
    components: {
      SimpleDropdown, Stopwords, StopwordsDescription
    },
    data () {
      // Manually collect information about variants and phrases
      // Ideally this would be made available at the Chrysalis API level
      let numVariants = 0
      let numTermsWithVariants = 0
      let numPhrases = this.$store.getters.currentModel.stats.n_phrases
      Object.values(this.$store.getters.currentModel.terms).forEach((term) => {
        numVariants += term.variants.length
        if (term.variants.length > 1) {
          numTermsWithVariants += 1
        }
      })

      return {
        stats: {
          numPhrases: numPhrases,
          numVariants: numVariants,
          numTermsWithVariants: numTermsWithVariants
        },
        defaultDateField: this.$store.getters.defaultDateField,
        phraseDetection: this.$store.getters.currentAnalysis.phrase_detection,
        removeDuplicates: this.$store.getters.currentAnalysis.remove_duplicates,
        stemming: this.$store.getters.currentAnalysis.stemming,
        stopwords: JSON.parse(this.$store.getters.currentAnalysis.stopwords),
        initialStopwords: JSON.parse(this.$store.getters.currentAnalysis.stopwords),
        runUpdate: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentAnalysis', 'currentModel', 'dateFields',
      ]),
      settingsChanged: function () {
        return (
          this.defaultDateField !== this.$store.getters.defaultDateField ||
          this.phraseDetection !== this.currentAnalysis.phrase_detection ||
          this.removeDuplicates !== this.currentAnalysis.remove_duplicates ||
          this.stemming !== this.currentAnalysis.stemming ||
          !_.isEqual(this.stopwords, this.initialStopwords) ||
          this.runUpdate
        )
      }
    },
    metaInfo () {
      return {
        title: this.currentAnalysis ? `${this.currentAnalysis.name} Settings - Kapiche` : null
      }
    },

    beforeUnmount () {
      // Remove the modal from DOM  since SemanticUI/Jquery takes it out of the component scope.
      $(this.selector).remove()
      // Semantic UI Modal is officially a steaming turd.
      // It messes with the body height which we rely on in the layout of our app.
      // So, first, we make sure the dimmer is hidden;
      // We don't rely on the `hide` method of the modal, because,
      // it initiates a callback that  can override the body height again.
      $('div.dimmer').dimmer('hide')
      // Now  we fix the body height which Semantic UI Modal takes the liberty to #$@! with.
      document.body.style.height = this.bodyHeight
    },
    methods: {
      number: FormatUtils.number,
      rerunModal (action) {
        $('.ui.modal.rerun-warning')
          .modal(action)
      },
      rerun () {
        this.rerunModal('show')
        if (!this.currentAnalysis?.project || !this.currentAnalysis?.id) return
        Project.rerunAnalysis(this.currentAnalysis.project, this.currentAnalysis.id, {
          default_date_field: this.defaultDateField || '',
          phrase_detection: this.phraseDetection,
          remove_duplicates: this.removeDuplicates,
          stemming: this.stemming,
          stopwords: JSON.stringify(this.stopwords)

        }).then(() => {
          this.$analytics.track.analysis.rerun({
            analysisId: this.currentAnalysis.id,
            defaultDateField: this.defaultDateField || '',
            phraseDetection: this.phraseDetection,
            removeDuplicates: this.removeDuplicates,
            stemming: this.stemming,
            stopwords: this.stopwords
          })
          this.$router.push(
            { name: 'project-details', params: { projectId: this.currentAnalysis.project }},
            () => {
              this.$store.commit(SET_MESSAGE, `Analysis ${this.currentAnalysis.name} is being rerun`)
              this.$store.commit(SET_ANALYSIS, null)
            }
          )
        }).catch((errors) => {
          this.rerunModal('hide')
          document.querySelector('.project-wrapper').scrollTop = 0
        })
      },
      resetSettings () {
        this.defaultDateField = this.$store.getters.defaultDateField
        this.phraseDetection = this.$store.getters.currentAnalysis.phrase_detection
        this.removeDuplicates = this.$store.getters.currentAnalysis.remove_duplicates
        this.stemming = this.$store.getters.currentAnalysis.stemming
        this.$refs.stopwords.stopwordList = this.initialStopwords.slice()
      },
      // Invoked when the stopwords are modified
      updateStopwords (stopwords) {
        this.stopwords = stopwords
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .ui.modal
    font-family: $standard-font
    .content
      text-align: center
      font-size: 1.2rem
    .header
      text-align: center
      text-color: #95a6ac

  div.two.wide.middle.aligned
    text-align: right

  h4
    font-weight: bold !important
  span.stat
    color: #21BA45 !important
    font-weight: bold
  div.document-info div.statistic
      margin: 0 !important
      width: 25%
</style>
