<!--
  Basic tooltip using the `FloatingPanel component.

  Provides a 'content' slot for tooltip body content.

-->
<template>
  <floating-panel :visible="visible" :x="x" :y="y" :bound="containerEl">
    <tool-tip-panel class="tool-tip">
      <chart-tool-tip-body>
        <template #body>
          <!-- Content inserted here -->
          <slot name="content"></slot>
        </template>
      </chart-tool-tip-body>
    </tool-tip-panel>
  </floating-panel>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FloatingPanel from 'components/widgets/FloatingPanel/FloatingPanel.vue'
import ToolTipPanel from 'components/widgets/ToolTipPanel/ToolTipPanel.vue'
import ChartToolTipBody from 'components/widgets/ChartToolTipBody/ChartToolTipBody.vue'

export default defineComponent({
  components: { FloatingPanel, ToolTipPanel, ChartToolTipBody},
  props: {
    container: {type: Element, default: null },
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    visible: {type: Boolean, default: true }
  },
  computed: {
    // returns `container` if provided, otherwise the parentNode of this component
    containerEl () {
      if (this.container) {
        return this.container
      }
      return this.$el ? this.$el.parentNode : undefined
    }
  }
})
</script>

<style lang="sass" scoped>
  table
    width: 100%
</style>
