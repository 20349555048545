<template>
  <div>
    <TopBar :show-log-out="true" />
    <div class="container">
      <div class="title">
        <h1>Create a new site</h1>
        <p>
          Create a new Kapiche site.
        </p>
      </div>
      <VeeForm ref="form" v-slot="{ errors }">
        <div class="ui form" :class="{'success': hasSucceeded, 'error': hasSucceeded === false}">
          <div class="field">
            <label>
              Site Name
              <br />
              <span class="help-text">
                This will be displayed on the Kapiche site.
              </span>
              <Field
                v-slot="{ field }"
                name="siteName"
                rules="required"
              >
                <input
                  v-bind="field"
                  v-model="siteName"
                  name="siteName"
                  type="text"
                  placeholder="e.g. KapicheSales"
                />
              </Field>
            </label>
            <div class="error-text">
              {{ errors['siteName'] }}
            </div>
          </div>
          <div class="field">
            <label>
              Site URL Name
              <br />
              <span class="help-text">
                This will be used in the site's url. E.g, for "app.kapiche.com/kapichesales" then enter
                "kapichesales".
              </span>
              <br />
              <span class="inline field">app.kapiche.com/
                <Field
                  v-slot="{ field }"
                  name="siteURLName"
                  rules="required|alphanumeric|site_available"
                >
                  <input
                    v-bind="field"
                    v-model="siteURLName"
                    name="siteURLName"
                    type="text"
                    placeholder="e.g. kapichesales"
                  />
                </Field>
              </span>
            </label>
            <div class="error-text">
              {{ errors['siteURLName'] }}
            </div>
          </div>
          <div class="field">
            <label>
              Email (Optional)
              <br />
              <span class="help-text">
                Please enter an email. The first user is added to the site using this email.
              </span>
              <Field
                v-slot="{ field }"
                name="email"
                rules="email"
              >
                <input
                  v-bind="field"
                  v-model="email"
                  name="email"
                  type="email"
                  placeholder="client@company.com"
                  @keyup.enter="submit"
                />
              </Field>
            </label>
            <div class="error-text">
              {{ errors['email'] }}
            </div>
          </div>

          <div class="field">
            <label>
              Chrysalis instance
              <br />
              <span class="help-text">
                Please select which chrysalis instance the site should be connected to.
              </span>
            </label>
            <multiselect
              v-model="chosenChrysUrl"
              :options="chrysUrlChoices"
              :show-labels="false"
              :close-on-select="true"
              :allow-empty="true"
              :taggable="true"
              :multiselect="false"
              tag-placeholder="Use a new chrysalis"
              placeholder="Select a chrysalis"
              @tag="selectNewChrysalis"
            >
              <template #noResult></template>
            </multiselect>
          </div>

          <div class="group">
            <div class="field">
              <label>
                Project Column Limit
                <br />
                <span class="help-text">
                  The maximum number of columns a project can have.
                  (This is enforced and data uploads with too many columns will be prevented.)
                </span>
                <Field
                  v-slot="{ field }"
                  name="projectColumnLimit"
                  rules="required|integer"
                >
                  <input
                    v-bind="field"
                    v-model="projectColumnLimit"
                    name="projectColumnLimit"
                    type="text"
                    placeholder=""
                    @keyup.enter="submit"
                  />
                </Field>
              </label>
              <div class="error-text">
                {{ errors['projectColumnLimit'] }}
              </div>
            </div>
            <div class="field">
              <label>
                Project Row Limit
                <br />
                <span class="help-text">
                  The maximum number of rows a project can have.
                  (This is not enforced but an alert is triggered if a project
                  goes over the limit.)
                </span>
                <Field
                  v-slot="{ field }"
                  name="projectRowLimit"
                  rules="required|integer"
                >
                  <input
                    v-bind="field"
                    v-model="projectRowLimit"
                    name="projectRowLimit"
                    type="text"
                    placeholder=""
                    @keyup.enter="submit"
                  />
                </Field>
              </label>
              <div class="error-text">
                {{ errors['projectRowLimit'] }}
              </div>
            </div>
            <div class="field">
              <label>
                Enforce Row Limit
                <br />
                <span class="help-text">
                  Whether to enforce above row limit
                </span>
                <br>
                <label for="true">True</label>
                <input
                  v-model="enforceRowLimit"
                  type="radio"
                  name="enforceRowLimit"
                  placeholder="true"
                  :value="true"
                >
                <label for="false">False</label>
                <input
                  v-model="enforceRowLimit"
                  type="radio"
                  name="enforceRowLimit"
                  placeholder="false"
                  :value="false"
                >
              </label>
              <div class="error-text">
                {{ errors['enforceRowLimit'] }}
              </div>
            </div>
          </div>
          <div class="field">
            <label>
              Term End (Expiry)
              <br />
              <span class="help-text">
                Please Enter Date Site or PoC will End
              </span>
              &nbsp;
              <vue-date-picker
                v-model="termEnd"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                placeholder=""
              />
            </label>
            <div class="error-text">
              {{ errors['termEnd'] }}
            </div>
          </div>
          <p>
            Go to the
            <a class="django-admin-link" target="_blank" :href="adminUrl">
              Site Profile
            </a>
            .
          </p>
          <p>
            See
            <a target="_blank" href="https://www.notion.so/kapiche/Site-Subscriptions-Billing-c9c985322b34494782fb49565a1316c3">
              Subscription Management
            </a>
            documentation.
          </p>
          <button
            class="ui primary button"
            :class="{'loading': isLoading}"
            @click="submit"
          >
            Create Site
          </button>
          <div class="ui success message">
            <div class="header">
              Yay!
            </div>
            <p>The site was successfully created. Rerouting...</p>
          </div>
          <div class="ui error message">
            <div class="header">
              Site creation failed.
            </div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Form as VeeForm, Field, defineRule } from 'vee-validate'
import api from 'src/api/site'
import TopBar from "components/TopBar.vue"
import { Multiselect } from 'vue-multiselect'
import VueDatePicker from '@vuepic/vue-datepicker'

export default defineComponent({
  components: {
    TopBar,
    Multiselect,
    VueDatePicker,
    VeeForm,
    Field,
  },
  data () {
    return {
      email: '',
      siteName: '',
      siteURLName: '',
      isLoading: false,
      hasSucceeded: null,
      errorMessage: '',
      chrysUrlChoices: [],
      chosenChrysUrl: this.urlChoices? urlChoices[0] : '',
      projectColumnLimit: undefined,
      projectRowLimit: undefined,
      enforceRowLimit: false,
      termEnd: undefined
    }
  },
  computed: {
    adminUrl () {
      switch (process.env.APP_ENV) {
        case 'production': {
          return 'https://django-admin.kapiche.com/admin/revealed_sites/siteprofile/'
        }
        case 'staging': {
          return  'https://django-admin.kapiche-dev.com/admin/revealed_sites/siteprofile/'
        }
        case 'dev': {
          return 'http://localhost:8000/admin/revealed_sites/siteprofile/'
        }
        default: {
          return ''
        }
      }
    }
  },
  async created () {
    defineRule('site_available', async (value: string) => {
      if (!value) {
        return false
      }
      try {
        const response = await api.checkSiteExists(value)
        return response.valid
      } catch (e) {
        return false
      }
    })
    const response = await api.availableChrysUrls()
    this.chrysUrlChoices = response.options
    this.chosenChrysUrl = response.default
  },
  methods: {
    async submit () {
      this.hasSucceeded = null
      this.errorMessage = ''
      try {
        this.isLoading = true
        const { valid } = await this.$refs.form.validate()
        if (!valid) {
          return
        }
        await api.createSite(
          this.email,
          this.siteName,
          this.siteURLName,
          this.chosenChrysUrl,
          this.projectColumnLimit,
          this.projectRowLimit,
          this.enforceRowLimit,
          this.termEnd,
        )
        this.hasSucceeded = true
        setTimeout(() => this.$router.push({ name: 'start' }), 1500)
      } catch (e) {
        this.hasSucceeded = false
        this.errorMessage = `${ e.statusText }: ${ e.bodyText }`
      }
      finally {
        this.isLoading = false
      }
    },
    /**
     * We're using the tagging functionality in vue-multiselect so that we can
     * add chrysalises that aren't already in use by a site and therefore aren't
     * an option in the dropdown.
     * @param <String> text
     */
    selectNewChrysalis (text) {
      this.chosenChrysUrl = text
    }
  }
})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .help-text
    font-weight: normal

  .container
    max-width: 650px
    margin: 60px auto
    line-height: 1.6em

  .title
    padding-bottom: 40px
    text-align: center
    text-transform: none
    color: black
  p
    font-weight: normal
    font-size: 16px

  .ui.form .field
    margin-bottom: 30px
    font-size: 16px

  .ui.button
    display: block
    margin: 0 auto

  div.group
    display: flex
    > div.field
      flex: 1
      :not(:last-child)
        margin-right: 20px

  :deep(.dp__input)
    text-indent: 20px
  :deep(.dp__outer_menu_wrap)
    font-weight: normal
</style>
