/**
 * All interactions with the /auth endpoints are done here.
 */
import Vue from 'vue'
import HTTPRetryUtil from '../utils/httpretry'

export interface Acl {
  access_list: {
    projects: {id: number, name: string}[]
    dashboards: {
      id: number
      name: string
      project_id: number
      project_name: string
      analysis_id: number
      analysis_name: string
    }[]
  }
  admin: boolean
  date_joined: string
  disabled: boolean
  email: string
  id: number
  last_login: string
  name: string
  user_id: number
  user_type: 'ADMIN' | 'ANALYST' | 'VIEWER'
}

export default {
  async createSite (
      email: string,
      siteName: string,
      siteURLName: string,
      chrysalis_url: string,
      projectColumnLimit: string,
      projectRowLimit: string,
      enforceRowLimit: boolean,
      termEnd: string,
    ) {
    const response = await Vue.http.post('site/create/',
      {
        domain: siteURLName,
        name: siteName,
        email: email,
        chrysalis_url: chrysalis_url,
        project_column_limit: projectColumnLimit,
        project_row_limit: projectRowLimit,
        enforce_row_limit: enforceRowLimit,
        term_end: termEnd,
      }
    )
    return await response.json()
  },

  async availableChrysUrls () {
    return await HTTPRetryUtil.retry('site/available_chrys_urls/')
  },

  /**
   * Return a list of sites the current user has access to.
   */
  async getListOfSites () {
    return await Vue.http.get(`site/access/`)
  },

    /**
   * Check if a site is available by hitting an endpoint that doesn't require auth
   */
  checkSiteExists (domain: string) {
    return Vue.http.get(`site/available/?domain=${domain}`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete user membership.
   */
  deleteUserMembership (membershipId: number) {
    return Vue.http.delete(`site/users/${membershipId}/`)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Set the admin status of a user for this site.
   */
  setUserMembershipAdmin (membershipId: number, isAdmin: boolean) {
    return Vue.http.patch(`site/users/${membershipId}/`, { admin: isAdmin })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Set the admin status of a user for this site.
   */
  setUserType (membershipId: number, type: string) {
    return Vue.http.patch(`site/users/${membershipId}/`, { user_type: type })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Set the admin status of a user for this site.
   */
   setUserDisabled (membershipId: number, disabled: boolean) {
    return Vue.http.patch(`site/users/${membershipId}/`, { disabled })
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Get a list of invitations that have been sent
   */
  getMembershipInvitations () {
    return Vue.http.get('site/invitations/')
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete invitation
   */
  deleteInvitation (invitationId: number) {
    return Vue.http.delete(`site/invitations/${invitationId}/`)
      .then((response) => Promise.resolve(), (error) => Promise.reject(error))
  },

  /**
   * Fetch an inviation
   */
  getMembershipInvitation (invitationId: number) {
    return Vue.http.get(`site/invitations/${invitationId}/`)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Create a new membership invitation
   */
  createMembershipInvitation (email: string, type: string, projectIds=[], dashboardIds=[]) {
    const ps = projectIds.map(id => ({type: 'project', id: id}))
    const ds = dashboardIds.map(id => ({type: 'dashboard', id: id}))

    return Vue.http.post(
      'site/invitations/',
      {
        email: email,
        user_type: type,
        add_to: ps.concat(ds),
      }
    )
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Accept membership invitation and register a user
   */
  acceptMembershipInvitation (invitationId: number, data: { email: string, first_name: string, last_name: string, county: string }, use_v2=false) {
    const url = use_v2 ? `site/register/v2/${invitationId}/` : `site/register/${invitationId}/`
    return Vue.http.post(url, data)
      .then((response) => Promise.resolve(response.json()), (error) => Promise.reject(error))
  },

  /**
   * Delete site. Must have staff flag.
   */
  async deleteSite (domain: string) {
    return await Vue.http.delete(`site/${domain}`)
  },

  /**
   * Fetch the list of user memberships for the current site.
   * v2 includes the projects each analyst has access to and the
   * dashboards each viewer has access to.
   */
  async getUserMembershipsV2 (): Promise<Acl[]> {
    return await HTTPRetryUtil.retry('site/users/v2/')
  },
}
