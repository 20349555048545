<template v-slot:row-tool-tip>
  <tool-tip-panel class="themes-chart-tooltip">
    <chart-tool-tip-body>
      <template #body>
        <div v-show="showDrilldown" class="link">
          Click for explore options
        </div>
        <h1>{{ label }}</h1>
        <h2>
          Frequency from start of data to {{ daysAgo ? `${daysAgo} days ago` : startDate }} (#/%): <label>{{ freq_1 }} / {{ freq_percent_1 }}</label>
        </h2>
        <h2>
          Frequency change during period (#/%): <label>{{ freq_diff }} / {{ freq_diff_percent }}</label>
        </h2>
        <h2>
          Frequency from start of data to {{ daysAgo ? 'now' : endDate }} (#/%): <label>{{ freq_total }} / {{ freq_percent_total }}</label>
        </h2>
        <h2>
          Relative difference: <label>{{ reldiff }}</label>
        </h2>
      </template>
    </chart-tool-tip-body>
  </tool-tip-panel>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import ToolTipPanel from 'components/widgets/ToolTipPanel/ToolTipPanel.vue'
import ChartToolTipBody from 'components/widgets/ChartToolTipBody/ChartToolTipBody.vue'
import dayjs from 'dayjs'

export default defineComponent({
  components: { ToolTipPanel, ChartToolTipBody },
  props: {
    label: { type: String, required: true },
    daysAgo: { type: String, required: false, default: '' },
    freq_1: { type: Number, required: true },
    freq_2: { type: Number, required: true },
    freq_total: { type: Number, required: true },
    freq_percent_1: { type: String, required: true },
    freq_percent_2: { type: String, required: true },
    freq_percent_total: { type: String, required: true },
    freq_diff: { type: Number, required: true },
    freq_diff_percent: { type: String, required: true },
    reldiff: { type: String, required: true },
    customDates: { type: Array as PropType<Array<string>>, required: true },
    showDrilldown: { type: Boolean, required: false, default: false },
  },
  computed: {
    startDate () {
      return dayjs(this.customDates[0], 'DD/MM/YYYY').format('D MMM, YYYY')
    },
    endDate () {
      return dayjs(this.customDates[1], 'DD/MM/YYYY').format('D MMM, YYYY')
    },
  },
})
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .themes-chart-tooltip
    min-width: 480px

    *
      font-size: 16px

    hr
      border: none

    h1
      font-weight: bold

    h2
      margin: 0 0 10px

    h2>label
      float: right
      font-weight: bold

    h2.nps > label
      color: #11ACDF

    h2.negative > label
      color: rgb(238, 56, 36)
      font-weight: bold
    h2.positive > label
      color: rgb(33, 186, 69)
      font-weight: bold
    h2.neutral > label
      color: #7f7f7f
      font-weight: bold
    h2.mixed > label
      color: #f89516
      font-weight: bold
</style>
