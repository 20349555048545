<template>
  <widget-frame
    ref="root"
    :zoomed="isZoomed"
    :is-loading="isLoading"
    :masked="masked"
    :dev-mode="devMode"
    :has-errored="!!hasErrored"
    :banner="banner"
    class="themes"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <download-export-button
            :name="exportName+'-Themes'"
            :is-loading="isLoading"
            :get-el="getChartEl"
            :get-csv-data="getCsvData"
            :get-svg-export-config="getSvgExportConfig"
            :make-ppt-slide="makePptSlide"
            short-name="Themes"
          ></download-export-button>
          <router-link
            v-if="!isZoomed && zoomToRoute"
            class="widget-action expand"
            :to="zoomToRoute"
          >
            <i class="kapiche-icon-fullscreen"></i>
          </router-link>
          <a
            :href="CONST.widget_help_links.themes"
            class="widget-action help"
            target="_blank"
          >
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
        <div v-show="isFiltered" class="ove-switch">
          <span>
            Show Expected
          </span>
          <el-switch
            :model-value="allowShowExpected"
            @change="setShowExpected"
          />
        </div>
      </div>
    </template>

    <!--======================== ICON -->
    <template #icon>
      <img
        class="header-icon"
        :src="icon"
        alt="Themes Icon"
      >
    </template>

    <!--======================== HEADING -->
    <template #header>
      Themes, Concepts &amp; Phrases
    </template>

    <!--======================== MENU -->
    <template #menu>
      <widget-menu
        :menus="menus"
        :vertical="isZoomed"
        :bound="$el"
        :max-label-length="35"
        @onSelect="setSelection"
      />
    </template>

    <!--======================== DEV PANEL -->
    <template #devPanel>
      <div>
        segment fields: {{ segmentFields }} <br />
        selectedDisplay: {{ selectedDisplay }} <br />
        isLoading: {{ isLoading }} <br />
        NpsField: {{ NpsField }} <br />
        hasNps: {{ hasNps }} <br />
        hasSentiment: {{ hasSentiment }} <br />
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre"><!--
          -->{{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>
    <!--======================== ERROR PANEL -->
    <template #error-panel>
      <div class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button> or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button> if the problem persists.
        </div>
        <div v-if="userErrors" class="message">
          {{ userErrors }}
        </div>
      </div>
    </template>
    <!--======================== CONTENT -->
    <template v-if="rowsForChart.length > 0" #content>
      <div ref="wrapper">
        <segmentation-chart
          v-if="!isLoading"
          ref="chart"
          :rows="rowsForChart"
          :headings="headings"
          :legend="legend"
          :max="range['max']"
          :min="range['min']"
          :width="width"
          :show-indicator-bar="showingExpected"
          :series-labels="seriesLabels"
          :series-tags="groupTags"
          empty-segment-text="(No Value)"
          @menu-closed="handleRowClicked(null)"
          @clicked-heading="setSorting"
          @row-clicked="handleRowClicked"
          @hover-row="setHoverRowIndex"
        >
          <template #row-tool-tip>
            <data-tool-tip
              v-if="clickedRowIndex === null"
              v-bind="hoverTheme"
            />
          </template>
          <template
            #interaction-menu="{ index, row }"
          >
            <button @click="navigateTo(row)">
              Drill into <b>{{ interactionLabel[index].replace(/^group_/, '') }}</b>
              <span
                v-if="groupTags[row.id]"
                :style="{ fontSize: 'inherit', marginLeft: '5px' }"
                class="group-tag"
              >
                [{{ groupTags[row.id] }}]
              </span>
            </button>
          </template>
        </segmentation-chart>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
    <template #footer>
      <div><small>{{ footerText }} </small></div>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, inject, onMounted, PropType, ref, watch } from 'vue'
import { isEqual, startCase } from 'lodash'
import PptxGenJS from 'pptxgenjs'

import SegmentationChart from 'components/charts/SegmentationChart/SegmentationChart.vue'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import icon from 'assets/img/dashboards/dash-queries.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import ProjectAPI from 'src/api/project'
import {
  getHeader,
  minMaxValues,
  sortRows,
  payloadDataToRows,
  payloadDataToCSVExport,
  payloadToData, toolTipData
} from './ThemesWidget.utils'
import { makeMenu, formatScoreColumns } from './ThemesWidget.menu'
import { makeRequirements } from "components/DataWidgets/ThemesWidget/ThemesWidget.requirements"
import DataToolTip from '../DataToolTip/DataToolTip.vue'
import { PayloadItem } from 'types/PivotData.types'
import { TableChartRowType, SegmentationLabel } from "types/components/Charts.types"
import { WidgetMenuOptions } from "types/components/WidgetMenu.types"
import { WidgetConfig } from 'src/types/DashboardTypes'
import { APIResponseDataItem as PhraseData } from "components/DataWidgets/KeyPhrases/KeyPhrases.utils"
import { SavedQuery } from 'src/types/Query.types'
import { Analytics } from 'src/analytics'
import { makeBarChartSlide } from '../DataWidgetUtils'
import { ExpandedGroup } from 'src/pages/dashboard/Dashboard.utils'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { truncate } from 'src/utils/formatters'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { formatDisplayForConfig, getScoreOptionsForRequirements } from './ScoreUtils'


interface PayloadType {
  overall: PayloadItem[]
  filtered?: PayloadItem[]
  phrases?: PhraseData[]
}

interface PayloadDataType {
  [key: string]: number | string | { [key:string]: number | string }
}

const ThemesWidget = defineComponent({
  components: {
    WidgetFrame,
    WidgetMenu,
    SegmentationChart,
    DownloadExportButton,
    DataToolTip,
    WidgetMessagePanel,
  },
  props: {
    /** route to navigate to when row is clicked on */
    toQueryRoute: { type: Object, required: false, default: null },
    toConceptRoute: { type: Object, required: false, default: null },
    /** for export filename */
    exportName: { type: String, required: false, default: '' },
    /** is this DataWidget in zoomed mode?  */
    isZoomed: { type: Boolean, required: false, default: false},
    /** route object for zoom button */
    zoomToRoute: { type: Object, required: false, default: null },
    /** data to render */
    data: { type: Object, required: false, default: null },
    /** explanatory text to display at the bottom of the widget **/
    footerText: { type: String, required: false, default: ''},
    /** show developer tools in widget */
    devMode: { type: Boolean, required: false, default: false },
    /** list of queries for the chart */
    queries: { type: Array as PropType<SavedQuery[]>, required: false, default: ()=>[] },
    /** list of concepts queries for the chart */
    concepts: { type: Array as PropType<SavedQuery[]>, required: false, default: ()=>[] },
    /** if filters have been applied to the dashboard, then we can calculate
     * the expected values for the data */
    isFiltered: { type: Boolean, required: false, default: false},
    /** fields for menu */
    segmentFields: { type: Array as PropType<{ name: string, type: string}[]>, required: false, default:()=>[] },
    /** does this data contain NPS? */
    hasNps: { type: Boolean, required: false, default: false},
    /** does this data contain sentiment? */
    hasSentiment: { type: Boolean, required: false, default: false},
    /** does this data contain numeric fields? */
    hasNumericFields: { type: Boolean, default: false, required: false },
    /** widget banner to display */
    banner: { type: Object, default: ()=>null, required: false },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'themes-concepts'> | null>, required: false, default: null },
    themeGroups: { type: Array as PropType<ExpandedGroup[]>, required: false, default: () => [] },
    schema: { type: Array as PropType<SchemaColumn[]>, required: true },
    scoreEnabled: { type: Boolean, required: false, default: false },
  },
  setup (props, { emit }) {
    const analytics = inject<Analytics>('analytics')
    const featureFlags = inject<Record<string, boolean>>('featureFlags', {})
    const themeToGroupNameMap = inject<ComputedRef<Record<number, string>>>('themeToGroupNameMapById', computed(() => ({})))
    const groupToGroupNameMap = inject<ComputedRef<Record<number, string>>>('groupToGroupNameMapById', computed(() => ({})))
    const themeNameMap = inject<ComputedRef<Record<number, string>>>('themeNameMap', computed(() => ({})))
    const themeGroupNameMap = inject<ComputedRef<Record<number, string>>>('themeGroupNameMap', computed(() => ({})))

    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)
    const wrapper = ref<InstanceType<typeof HTMLDivElement> | null>(null)

    const chart = ref()
    const selectedData = ref('Themes')
    const allowShowExpected = ref(true)
    const selectedDisplay = ref('Frequency')
    const validOptions = ref(true)
    const sortBy = ref(2)
    const ascendingSort = ref(false)
    const clickedRowIndex = ref<number | null>(null)
    const hoveredRowIndex = ref<number | null>(null)
    const width = ref(300)

    const seriesLabels = computed<Record<string, string>>(() => {
      // Theme Groups are prefixed with 'group_' to avoid conflicts with Themes
      const labels = props.themeGroups.map((g) => [g.name, g.name.replace(/^group_/, '')])
      return Object.fromEntries(labels)
    })

    const payload = computed((): PayloadType => {
      return {
        overall: props.data?.overall?.data?.payload,
        filtered: props.data?.filtered?.data?.payload,
        phrases: props.data?.phrases?.data?.payload,
      }
    })

    const phrases = computed((): PhraseData[] => {
      return payload.value.phrases ?? []
    })

    const groupTags = computed(() => {
      if (selectedData.value === 'Themes') {
        return themeToGroupNameMap.value
      }
      if (selectedData.value === 'Theme Groups') {
        return groupToGroupNameMap.value
      }
      return {}
    })

    const topPhrasesAsQueries = computed(() => {
      // copy the concepts and sort them as expected here:
      // returning with the same Type as a regular query
      if (phrases.value === undefined) return []
      return phrases.value
        .slice()
        // sort by frequency (not by name)
        .sort((a, b) => a.count_phrase - b.count_phrase)
        // translate top concepts into "queries"
        .map((c: { phrase_text: string, })=> ({
          name: c.phrase_text,
          query_value: {
            type: "match_any",
            includes: [{
              type: 'text',
              value: c.phrase_text,
            }]
        }}) as SavedQuery)
    })

    const themesOrConceptsQueries = computed<SavedQuery[]>(() => {
      if (selectedData.value === 'Themes') {
        return props.queries.map((q) => ({
          ...q,
          name: `q_${q.id}`,
        }))
      } else if (selectedData.value === 'Theme Groups') {
        return props.themeGroups.map((q) => ({
          ...q,
          name: `group_${q.id}`,
        }))
      } else if (selectedData.value === 'Top Concepts') {
        return props.concepts
      } else if (selectedData.value === 'Top Phrases') {
        return topPhrasesAsQueries.value
      } else {
        throw new Error(`Unknown data type ${selectedData.value}`)
      }
    })

    /* To be able to show expected, the option must be turned on AND there
     * must be an overall and filtered data set to support the comparison of
     * overall vs expected
     * */
    const showingExpected = computed((): boolean => {
      return allowShowExpected.value && props.isFiltered
    })

    const payloadData = computed((): PayloadDataType => {
      return payloadToData(
        payload.value,
        themesOrConceptsQueries.value,
        showingExpected.value,
        selectedData.value,
      )
    })

    const selectedDataLabel = computed((): 'Theme' | 'Concept' | 'Phrase' | 'Theme Group' => {
      if (selectedData.value === 'Themes') {
        return 'Theme'
      } else if (selectedData.value === 'Theme Groups') {
        return 'Theme Group'
      } else if (selectedData.value === 'Top Concepts') {
        return 'Concept'
      } else if (selectedData.value === 'Top Phrases') {
        return 'Phrase'
      } else {
        throw new Error(`Unknown data type ${selectedData.value}`)
      }
    })

    const numericalFields = computed((): string[] => {
      return props.segmentFields.reduce(
        (list: string[], field: any) =>
          field.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NUMBER') ? list.concat(field.name) : list,
        []
      )
    })

    const isLoading = computed((): boolean => {
      if (!validOptions.value) return true
      return props.data?.filtered?.status === 'fetching' || props.data?.overall?.status === 'fetching'
    })

    const rowsForChart = computed((): TableChartRowType[] => {
      if (isLoading.value || !payload.value) return []

      let nameFormatter = (name: string) => name
      if (selectedData.value === 'Themes') {
        nameFormatter = (name: string) => {
          const queryId = Number(name.replace(/^q_/, ''))
          const query = props.queries.find((q) => q.id === queryId)
          const queryName = query?.name ?? name
          return seriesLabels.value[queryName] ?? queryName
        }
      }
      if (selectedData.value === 'Theme Groups') {
        nameFormatter = (name: string) => {
          const queryId = Number(name.replace(/^group_/, ''))
          const query = props.themeGroups.find((q) => q.id === queryId)
          const queryName = query?.name ?? name
          return seriesLabels.value[queryName] ?? queryName
        }
      }

      return sortRows(
        payloadDataToRows(
          payloadData.value,
          themesOrConceptsQueries.value,
          selectedDisplay.value,
          showingExpected.value,
          sortBy.value,
          nameFormatter,
        ),
        sortBy.value,
        ascendingSort.value,
      )
    })

    const range = computed((): { min: number, max: number } => {
      // min/max values are rounded (down for min, up for max) to next integer)
      const minMax = minMaxValues(rowsForChart.value, selectedDisplay.value, sortBy.value, showingExpected.value, 1)
      return minMax ?? { min: 0, max: 0 }
    })

    const hoverTheme = computed(() => {
      if (hoveredRowIndex.value == null) return null

      const chartRow = rowsForChart.value[hoveredRowIndex.value]
      if (!chartRow) return null

      let label = chartRow?.label ?? ''
      let lookup = label

      if (selectedData.value === 'Themes') {
        lookup = `q_${chartRow.id}`
      }

      if (selectedData.value === 'Theme Groups') {
        lookup = `group_${chartRow.id}`
      }

      const dataItem = payloadData.value[lookup] as any
      if (!dataItem) return null

      const name = seriesLabels.value[label] ?? label
      return toolTipData(
        name,
        dataItem,
        chartRow,
        selectedDisplay.value,
        selectedDataLabel.value,
        props.hasNps,
        props.hasSentiment,
        showingExpected.value,
        props.isFiltered,
      )
    })

    const interactionLabel = computed((): Record<number, string> => {
      return rowsForChart.value.map((r: TableChartRowType) => r.label ?? '')
    })

    const validatedOptions = computed((): Required<WidgetConfig<'themes-concepts'>['options']> => {
      let display = props.config?.options?.display
      let data = props.config?.options?.data
      let sortBy = props.config?.options?.sortBy ?? 'Frequency (%)'
      let ascendingSort = !!props.config?.options?.ascendingSort
      let allowShowExpected = !!(props.config?.options?.allowShowExpected ?? true)
      // if the setting is true and it's possible because both the overall
      // data set and the filtered data set exist
      let willShowExpected = allowShowExpected && props.isFiltered

      // validate display
      if (
        !display ||
        (display === `nps_` && !props.hasNps) ||
        (display?.endsWith('Sentiment') && !props.hasSentiment) ||
        (numericalFields.value.includes(display) && !props.hasNumericFields) ||
        (willShowExpected && display === 'Frequency') ||
        (!willShowExpected && ['Frequency (%)', 'Frequency (#)'].includes(display))
      ) {
        display = willShowExpected ? 'Frequency (%)' : 'Frequency'
      }

      if (!willShowExpected && display.startsWith('Impact on') && display.endsWith('Sentiment')) {
        display = display.slice(10)
      }

      if (display.startsWith("__score__")) {
        const scoreColName = display.startsWith("__score__impact")? display.slice(17): display.slice(9)
        const scoreCol = scoreColumns.value.find((col) => col.name === scoreColName)
        const formattedCol = formatScoreColumns(scoreCol, willShowExpected)
        display = display.startsWith("__score__impact") && willShowExpected? formattedCol[1].value: formattedCol[0].value
      }

      // validate data
      if (!data) {
        data = 'Themes'
      }
      if (data === 'Themes' && props.queries.length === 0) {
        data = 'Top Concepts'
      }
      if (data === 'Theme Groups' && props.themeGroups.length === 0) {
        data = 'Top Concepts'
      }

      // validate sortBy
      if (
        typeof sortBy !== 'number' ||
        sortBy < 0 ||
        (willShowExpected && sortBy > 3 ) ||
        (!willShowExpected && sortBy > 2 )
      ) {
        sortBy = 2
      }

      return { display, data, sortBy, ascendingSort, allowShowExpected }
    })

    const headings = computed(() => {
      return getHeader(
        selectedDataLabel.value,
        selectedDisplay.value,
        sortBy.value as any,
        ascendingSort.value,
        showingExpected.value,
        props.isFiltered,
      )
    })

    const hasErrored = computed((): boolean => {
      return !!props.data?.filtered?.error ?? !!props.data?.overall?.error
    })

    const hasQueries = computed(() => {
      return props.queries?.length > 0
    })

    const hasThemeGroups = computed(() => {
      return props.themeGroups?.length > 0
    })

    const NpsField = computed((): string | null => {
      if (!props.hasNps) return null
      type fieldType = { type: string, name: string }
      const field: fieldType | undefined = props.segmentFields.find((f) => f.type === ProjectAPI.COLUMN_LABELED_TYPES.get('NPS'))
      return field ? field.name : null
    })

    const legends = computed((): Record<string, string[]> => ({
      'Frequency': ['Frequency (% of records)', 'Frequency (# of records)', 'Frequency (% of records)'],
      'nps_': ['NPS', 'Impact on Overall NPS', 'NPS'],
      'Positive Sentiment': ['Positive Sentiment', 'Impact on Overall Positive Sentiment', 'Positive Sentiment'],
      'Negative Sentiment': ['Negative Sentiment', 'Impact on Overall Negative Sentiment', 'Negative Sentiment'],
      'Neutral Sentiment': ['Neutral Sentiment', 'Impact on Overall Neutral Sentiment', 'Neutral Sentiment'],
      'Mixed Sentiment': ['Mixed Sentiment', 'Impact on Overall Mixed Sentiment', 'Mixed Sentiment'],
      '_': [
        startCase(selectedDisplay.value),
        `Impact on Overall ${startCase(selectedDisplay.value)}`,
        startCase(selectedDisplay.value),
      ],
    }))

    const legend = computed((): SegmentationLabel[] => {
      if (showingExpected.value) return [
        { label: 'Observed Value', color: '#11ACDF' },
        { label: 'Expected Value', color: '#8064AA' },
      ]
      const legend = legends.value[selectedDisplay.value]
      return [
        {
          label: legend ? legend[sortBy.value] : legends.value['_'][sortBy.value],
          color: '#11ACDF',
        }
      ]
    })

    const scoreColumns = computed(() => {
      return props.schema.filter((col) => col.type === 8 && props.scoreEnabled)
    })

    const menus = computed((): WidgetMenuOptions[] => {
      return makeMenu(
        hasQueries.value,
        selectedData.value,
        props.hasNps,
        NpsField.value,
        selectedDisplay.value,
        props.hasSentiment,
        props.hasNumericFields,
        numericalFields.value,
        props.isZoomed,
        showingExpected.value,
        hasThemeGroups.value,
        featureFlags,
        scoreColumns.value.length > 0,
        scoreColumns.value,
      )
    })

    const getCsvData = () => {
      let nameMap: Record<number, string> = {}
      let groupNameMap: Record<number, string> = {}
      if (selectedDataLabel.value === 'Theme') {
        nameMap = themeNameMap.value
        groupNameMap = themeToGroupNameMap.value
      }
      if (selectedDataLabel.value === 'Theme Group') {
        nameMap = themeGroupNameMap.value
        groupNameMap = groupToGroupNameMap.value
      }

      return payloadDataToCSVExport(
        payloadData.value,
        selectedDisplay.value,
        selectedDataLabel.value,
        themesOrConceptsQueries.value,
        showingExpected.value,
        props.hasNps,
        props.hasSentiment,
        props.hasNumericFields,
        numericalFields.value,
        null,
        nameMap,
        groupNameMap,
      )
    }

    const getChartEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getSvgExportConfig = () => {
      const bb = getChartEl()?.getBoundingClientRect()
      if (!bb) return
      return {
        dims: { height: bb.height, width: bb.width },
        padding: 20,
        css: `
          svg {
            top: 0;
            left: 0;
          }
          .chart {
            background-color: #fff;
            cursor: default;
          }
          text.first {
            text-anchor: start;
          }
          text.end {
            text-anchor: end;
          }
          g.row rect.bar {
            fill: #11ACDF;
          }
          rect.background {
            fill: #F1F1F1;
          }
          rect.indicator {
            fill: #8064AA;
          }
          rect.hover  {
            cursor: pointer;
            fill-opacity: 0;
          }
          text.hover {
            fill: #068CCC;
          }
          text.sorted {
            fill: #068CCC;
            font-weight: bold;
          }
          g.header > g.clickable > text.sorted {
            fill: #383838;
          }
          g.header .clickable {
            cursor: pointer;
            user-select: none;
          }
          .header text {
            fill: #95A6AC;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
          }
          rect.legend-icon-one {
            fill: #11ACDF;
          }
          rect.legend-icon-two {
            fill: #8064AA;
          }
          .legend {
            cursor: default;
          }
          .up-down > path {
            fill: #95a6ac;
            fill-opacity: 1;
          }
          .up-down > path.active {
            fill: #068ccc;
          }
        `
      }
    }

    const fetchData = (force=false) => {
      if (!selectedData.value) return
      let numericSelected = null
      // Check for numerical fields
      if (selectedDisplay.value.startsWith('__avg__')) numericSelected = [selectedDisplay.value.slice(7)]
      if (selectedDisplay.value.startsWith('__impact_on_avg__')) numericSelected = [selectedDisplay.value.slice(17)]
      // Check for score fields
      const scoreOptions = getScoreOptionsForRequirements(selectedDisplay.value, scoreColumns.value)

      const requirements = makeRequirements(
        props.hasNps,
        props.hasSentiment,
        numericSelected,
        scoreOptions,
        themesOrConceptsQueries.value,
        selectedData.value === 'Top Concepts' ? 25 : false
      )

      emit('requires-phrases',
        'themes-concepts__phrases',
        {
          limit: props.isZoomed ? 100 : 25,
          min_concept_freq: 5,
          // If no queries are given, no NPMI will be calculated,
          // but we'll still get the top phrases by frequency. So the same
          // backend endpoint serves "Top Phrases" (on the Overview Dashboard)
          // as well as "Key Phrases" (on the Query Dashboard). In the former
          // case, as below, no queries are given. In the latter case, queries
          // are given, and thus the NPMI available in the output are with
          // respect to those queries.
          queries: [],
          sort_asc: false,
          sort_field: "count_pair",
        },
        force,
        true // ensure this request has filters applied
      )

      emit('requires',
        'themes-concepts__overall',
        requirements,
        force,
        false, // do not automatically apply filters
      )
      emit('requires',
        'themes-concepts__filtered',
        requirements,
        force,
        true // ensure this request has filters applied
      )
    }

    const handleRowClicked = (index: number | null) => {
      if (index === clickedRowIndex.value) {
        clickedRowIndex.value = null
      } else {
        clickedRowIndex.value = index
      }
    }

    const navigateTo = (row: TableChartRowType) => {
      if (!selectedData.value) return

      switch (selectedData.value) {
        case 'Themes': {
          const id = row.id
          emit('go-to-theme', id)
          break
        }
        case 'Theme Groups': {
          const id = row.id
          emit('go-to-theme-group', id)
          break
        }
        case 'Top Concepts':
        case 'Top Phrases': {
          const label = row.label
          emit('go-to-concept', label)
          break
        }
        default:
          return
      }
    }

    const setSelectedDisplay = (field: [string | null, string], fromConfig: boolean) => {
      let fieldname = field[1]
      selectedDisplay.value = fieldname
      if (!fromConfig) {  // don't track programmatic setting of this option
        analytics?.track.themesWidget.changeDisplay(field[0]!, fieldname)
      }
    }

    const updateConfig = () => {
      let display = formatDisplayForConfig(selectedDisplay.value)

      const options: NonNullable<typeof props.config>['options'] = {
        ascendingSort: ascendingSort.value,
        display: display,
        data: selectedData.value,
        sortBy: sortBy.value,
        allowShowExpected: allowShowExpected.value,
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const setSelectedData = (field: [string | null, string], fromConfig: boolean) => {
      let fieldname = field[1]
      selectedData.value = fieldname
      if (!fromConfig) {  // don't track programmatic setting of this option
        analytics?.track.themesWidget.changeData(field[0]!, fieldname)
      }
    }

    const setSelection = (menuName: 'Display' | 'Data', field: [string | null, string], fromConfig: boolean) => {
      if (menuName === 'Display') {
        setSelectedDisplay(field, fromConfig)
      }
      else if (menuName === 'Data') {
        setSelectedData(field, fromConfig)
      }
      if (!fromConfig) {
        updateConfig()
        fetchData()
      }
    }

    const setSorting = (column: number, ascending=null as boolean|null, fromConfig: boolean) => {
      if (ascending === null) {
        ascendingSort.value = (sortBy.value === column) ? !ascendingSort.value : ascendingSort.value
      } else {
        ascendingSort.value = ascending
      }
      sortBy.value = column
      if (!fromConfig) {  // don't track programmatic setting of this option
        updateConfig()
        analytics?.track.themesWidget.changeSort(headings.value[column].label)
      }
    }

    const setShowExpected = (showExpected: boolean, fromConfig: boolean) => {
      allowShowExpected.value = showExpected
      if (!fromConfig) {  // don't track programmatic setting of this option
        updateConfig()
        analytics?.track.themesWidget.changeShowExpected(showExpected)
      }
    }

    const setOptionsFromConfig = () => {
      setSelection('Display', [null, validatedOptions.value.display], true)
      setSelection('Data', [null, validatedOptions.value.data], true)
      setSorting(validatedOptions.value.sortBy, validatedOptions.value.ascendingSort, true)
      setShowExpected(validatedOptions.value.allowShowExpected, true)
    }

    const setHoverRowIndex = (index: number) => {
      hoveredRowIndex.value = index
    }

    const refresh = () => {
      window.location.reload()
    }

    const reload = () => {
      fetchData(true)
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch (e) {
        console.warn('intercom show failed')
      }
    }

    const setChartDimensions = (w: number): void => {
      width.value = w
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      const slide = pptx.addSlide()

      let column = sortBy.value - 1
      if (column < 0) column = 0

      const legend = legends.value[selectedDisplay.value] ?? legends.value['_']

      const getLabel = (childId: number, themeName: string) => {
        let groupNameMap

        if (selectedData.value === 'Themes') {
          groupNameMap = themeToGroupNameMap.value
        }
        if (selectedData.value === 'Theme Groups') {
          groupNameMap = groupToGroupNameMap.value
        }

        if (groupNameMap?.[childId]) {
          let groupName = groupNameMap[childId]
          groupName = truncate(groupName, 20)
          return `${themeName} [${groupName}]`
        }

        return themeName ?? ''
      }

      makeBarChartSlide(
        pptx,
        slide,
        [{
          name: 'Themes, Concepts & Phrases',
          labels: rowsForChart.value.map((r) => getLabel(r.id!, r.label!)),
          values: rowsForChart.value.map((r) => r.columns[column].value),
        }],
        `${props.exportName} - Themes, Concepts & Phrases`,
        legend.slice(1)[column],
        selectedData.value,
      )
    }

    watch(() => props.config, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch(() => props.queries, (new_queries) => {
      if (new_queries.length===0) selectedData.value = 'Top Concepts'
      setOptionsFromConfig()
      fetchData()
    }, {
      deep: true,
    })

    watch(phrases, () => {
      if (!hasErrored.value) {
        fetchData()
      }
    })

    watch(() => props.isFiltered, () => {
      setOptionsFromConfig()
      fetchData()
    }, {
      deep: true,
    })

    watch(() => props.data, (newVal, oldVal) => {
      if (!isEqual(oldVal, newVal)) {
          clickedRowIndex.value = null
        }
    }, {
      deep: true,
    })

    onMounted(() => {
      setOptionsFromConfig()
      fetchData()
    })

    return {
      getSvgExportConfig,
      getCsvData,
      getChartEl,
      root,
      icon,
      errorIcon,
      reload,
      refresh,
      contact,
      range,
      handleRowClicked,
      hoverTheme,
      navigateTo,
      interactionLabel,
      hasErrored,
      wrapper,
      isLoading,
      setChartDimensions,
      allowShowExpected,
      setShowExpected,
      menus,
      setSelection,
      rowsForChart,
      headings,
      legend,
      width,
      showingExpected,
      setSorting,
      setHoverRowIndex,
      clickedRowIndex,
      makePptSlide,
      seriesLabels,
      selectedData,
      userErrors: null,
      chart,
      selectedDisplay,
      NpsField,
      groupTags,
    }
  },
})

export default ThemesWidget
</script>
<style lang="sass" scoped>
  @import '../../../../semantic/dist/semantic.css'
  @import '~assets/kapiche.sass'

  .empty-message
    text-align: center
    color: $subdued
    font-size: 2rem
    margin: 40px 0

  .error-panel
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    padding-bottom: 30px

    button
      background: none
      border: none
      border-bottom: 2px solid $blue
      padding: 3px 4px
      &:hover
        background-color: $grey-light
      &:focus
        border: 2px solid $blue-light
        outline: none

  .message
    display: flex
    flex-direction: row
    justify-content: center
    background-color: rgba(255,0,0, 0.1)
    padding: 6px
    color: $text-black
    width: 100%
    max-height: 30px
    position: absolute
    bottom: 0


  .errorIcon
    position: relative
    height: 32px
    width: 32px
    display: inline-block
    top: 10px

  .action
    padding-top: 20px

  .default-actions
    display: flex
    flex-direction: row-reverse
    width: 100%
  .ove-switch
    margin-top: 10px
</style>
