<template>
  <div class="root">
    <div class="ui grid">
      <!--Query Builder Row-->
      <div class="two column row search-container">
        <div v-if="query_0" class="column">
          <query-builder
            ref="queryBuilder_0" :query="query_0" :minimal="!isQueryVisible"
            :compare="true"
            :query-scope="query_0_scope"
            @query-selected="onQuerySelected_0"
            @query-updated="onQueryUpdated(0, $event)"
            @change-query-scope="changeQueryScope(0, $event)"
          >
          </query-builder>
        </div>

        <div v-if="query_1" class="column">
          <query-builder
            ref="queryBuilder_1" :query="query_1" :minimal="!isQueryVisible"
            :compare="true"
            :query-scope="query_1_scope"
            @query-selected="onQuerySelected_1"
            @query-updated="onQueryUpdated(1, $event)"
            @change-query-scope="changeQueryScope(1, $event)"
          >
          </query-builder>
        </div>
      </div>

      <!--Query display row-->
      <div class="two column row query-container">
        <div class="column">
          <template v-if="isQueryVisible">
            <div v-if="query_0.length > 0" class="query-rows">
              <query-row
                v-for="(q, i) in query_0"
                :key="computeQueryId(i, q)"
                :query="q"
                :editable="true"
                :first-row="i == 0"
                @row-deleted="deleteRow(0, i)"
                @update-row="updateRow('query_0', i, $event)"
              ></query-row>
            </div>
            <div v-else-if="query_0.length <= 0 && query_1.length > 0" class="query-rows show-all">
              <span class="label">No query, showing all data</span>
            </div>
          </template>
          <div v-if="!queryLoading && validQuery['query_0']" class="record-count">
            <strong>{{ number(recordHits[0] || 0) }}</strong> records ({{ number(recordHits[0] / currentModel.stats.n_documents * 100, '0.00') }}%)
          </div>
        </div>

        <div class="column">
          <template v-if="isQueryVisible">
            <div v-if="query_1.length > 0" class="query-rows">
              <query-row
                v-for="(q, i) in query_1"
                :key="computeQueryId(q)"
                :query="q"
                :editable="true"
                :first-row="i == 0"
                @row-deleted="deleteRow(1, i)"
                @update-row="updateRow('query_1', i, $event)"
              ></query-row>
            </div>
            <div v-else-if="query_1.length <= 0 && query_0.length > 0" class="query-rows show-all">
              <span class="label">No query, showing all data</span>
            </div>
          </template>
          <div v-if="!queryLoading && validQuery['query_1']" class="record-count">
            <strong>{{ number(recordHits[1] || 0) }}</strong> records ({{ number(recordHits[1] / currentModel.stats.n_documents * 100, '0.00') }}%)
          </div>
        </div>
        <div v-show="query_0.length + query_1.length > 0 && !queryLoading" class="toggle-query-show" @click="isQueryVisible = !isQueryVisible">
          {{ isQueryVisible ? 'Hide Query' : 'Show Query' }}
        </div>
      </div>
      <!-- Back to query button -->
      <div class="one column row return-button-row">
        <div class="column">
          <router-link class="ui button return-button" :to="{ name: 'browse-excerpts', query: generateQuery(query_0)}">
            &lt; Back to Single Query
          </router-link>
        </div>
      </div>
      <template v-if="!queryLoading && !noResultQuery && validQuery['any']">
        <!-- Sentiment Summary -->
        <div v-if="hasSentiment" class="two column row">
          <div class="column">
            <sentiment-summary-segment
              v-if="validQuery['query_0']"
              :query="botanicQuery_0"
              @sentiment-clicked="addSentimentToQuery(0, $event)"
            />
          </div>

          <div class="column">
            <sentiment-summary-segment
              v-if="validQuery['query_1']"
              :query="botanicQuery_1"
              @sentiment-clicked="addSentimentToQuery(1, $event)"
            />
          </div>
        </div>

        <!--NPS summary-->
        <div v-if="hasNPS" class="one column row nps-summary">
          <div class="column">
            <nps-summary-compare
              v-if="validQuery['all']"
              :queries="both_queries"
              :query-result-counts="recordHits"
              @nps-clicked="addNpsToQuery"
            />
          </div>
        </div>

        <!-- Timeline Trend -->
        <div v-if="hasDate" class="one column row timeline-trend">
          <div class="column">
            <timeline-compare
              v-if="validQuery['all']"
              :queries="[{ 'query_value': botanicQuery_1, 'name': 'Query 2' }, { 'query_value': botanicQuery_0, 'name': 'Query 1' }]"
              :legend="false"
              :can-drilldown="true"
              @date-selected="addDateToQuery"
            />
          </div>
        </div>

        <!-- Concept Network -->
        <div class="two column row concept-network">
          <div class="column left">
            <context-network
              v-if="modelLoading || networkModel_0 && networkModel_0.concepts"
              :model="networkModel_0"
              :loading="modelLoading"
              :height="400"
              :no-data-msg="query_0.length <= 0 ? 'Context network not shown for all data' : undefined"
              @concept-selected="addTermToQuery(0, ...arguments)"
            >
            </context-network>
            <old-context-network
              v-else :terms="queryTerms_0"
              @term-selected="addTermToQuery(0, ...arguments)"
            >
            </old-context-network>
          </div>
          <div class="column right">
            <context-network
              v-if="modelLoading || networkModel_1 && networkModel_1.concepts"
              :model="networkModel_1"
              :height="400"
              :no-data-msg="query_1.length <= 0 ? 'Context network not shown for all data' : undefined"
              @concept-selected="addTermToQuery(1, ...arguments)"
            >
            </context-network>
            <old-context-network
              v-else :terms="queryTerms_1"
              @term-selected="addTermToQuery(1, ...arguments)"
            >
            </old-context-network>
          </div>
        </div>

        <!-- Excerpts -->
        <div class="two column row excerpts">
          <div class="column left">
            <excerpts v-if="validQuery['query_0']" :query="botanicQuery_0" :exclude-empty="true" :preview="false" :hide-excerpts-per-page="true" :subtext="false"></excerpts>
          </div>
          <div class="column right">
            <excerpts v-if="validQuery['query_1']" :query="botanicQuery_1" :exclude-empty="true" :preview="false" :hide-excerpts-per-page="true" :subtext="false"></excerpts>
          </div>
        </div>

        <!-- Concept correlations -->
        <div class="sixteen wide mobile sixteen wide large screen eight wide widescreen column">
          <topic-correlations
            v-if="topicsData_1 && topicsData_0" :queries="[botanicQuery_0, botanicQuery_1]" :topics-data="[topicsData_0, topicsData_1]"
            :height="450" @concept-clicked="addConceptToQuery"
          ></topic-correlations>
          <!-- Loading mask -->
          <div v-else style="height:450px;" class="ui segment segments-loader">
            <div class="ui active inverted dimmer">
              <div class="ui loader"></div>
            </div>
          </div>
        </div>

        <!-- Concept correlations -->
        <div :class="'sixteen wide mobile eight wide widescreen column'">
          <segment-correlations v-if="segmentsData_0 && segmentsData_1" :segments="[query_0, query_1]" :segments-data="[segmentsData_0, segmentsData_1]" :height="450" @segment-clicked="addSegmentToQuery"></segment-correlations>
          <!-- Loading mask -->
          <div v-else style="height:450px;" class="ui segment segments-loader">
            <div class="ui active inverted dimmer">
              <div class="ui loader"></div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="queryLoading">
        <div class="ui container center aligned">
          <div class="ui active loader"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters, mapActions } from 'vuex'

  import { LOAD_SAVED_QUERIES, SET_ERRORS, CLEAR_ERRORS } from 'src/store/types'
  import ContextNetwork from 'src/components/project/analysis/results/widgets/ContextNetwork.vue'
  import OldContextNetwork from 'src/components/project/analysis/results/widgets/ContextNetwork_old.vue'
  import Excerpts from 'src/components/project/analysis/results/widgets/Excerpts.vue'
  import NpsSummaryCompare from 'src/components/project/analysis/results/widgets/NpsSummaryCompare.vue'
  import QueryBuilder from 'src/components/project/analysis/results/query/QueryBuilder.vue'
  import QueryRow from 'src/components/project/analysis/results/query/QueryRow.vue'
  import SentimentSummarySegment from 'src/components/project/analysis/results/widgets/SentimentSummarySegment.vue'
  import TopicCorrelations from 'src/components/project/analysis/results/widgets/ConceptCorrelationCompare.vue'
  import SegmentCorrelations from 'src/components/project/analysis/results/widgets/SegmentCorrelationCompare.vue'
  import TimelineCompare from 'src/components/project/analysis/results/widgets/TimelineCompare.vue'

  import Query from 'src/api/query'
  import QueryUtils from 'src/utils/query'
  import Utils from 'src/utils/general'
  import FormatUtils from 'src/utils/formatters'
  import { cloneDeep } from 'lodash'
  import { isQueryValid as isValid } from './utils'
  import { QueryType } from "types/Query.types"

  const ALL_DATA_QUERY = []

  export default defineComponent({
    components: {
      QueryBuilder,
      QueryRow,
      Excerpts,
      TimelineCompare,
      NpsSummaryCompare,
      SentimentSummarySegment,
      TopicCorrelations,
      SegmentCorrelations,
      ContextNetwork,
      OldContextNetwork
    },
    beforeRouteEnter (to, from, next) {
      // Set the query(s) from the url
      next(vm => {
        vm.$store.dispatch({
          type: LOAD_SAVED_QUERIES,
          projectId: vm.$route.params.projectId,
          analysisId: vm.$route.params.analysisId,
        }).then(() => {
          let urlQuery = vm.$route.query
          if (urlQuery) {
            if (urlQuery.savedQuery_0) {
              vm.savedQueryId_0 = urlQuery.savedQuery_0
              // Make sure saved query is selected
              vm.$nextTick(() => {
                vm.$refs.queryBuilder_0.selectQuery(urlQuery.savedQuery_0)
              })
            }
            if (urlQuery.savedQuery_1) {
              vm.savedQueryId_1 = urlQuery.savedQuery_1
              // Make sure saved query is selected
              vm.$nextTick(() => {
                vm.$refs.queryBuilder_1.selectQuery(urlQuery.savedQuery_1)
              })
            }
            vm.query_0 = Utils.parseRouteQuery(urlQuery.q_0)
            vm.query_1 = Utils.parseRouteQuery(urlQuery.q_1)
            vm.query_0_scope = urlQuery.queryScope_0 ?? "frame"
            vm.query_1_scope = urlQuery.queryScope_1 ?? "frame"
          }
        })
      })
    },
    beforeRouteUpdate (to, from, next) {
      // This guard can be triggered both by a query builder driven query update,
      // or by back/forwards navigation; we only want to catch the latter.
      // To do this we can tentatively parse the query from the new url and see if it
      // matches the currently stored one, and if so suppress the event.
      let queries = [Utils.parseRouteQuery(to.query.q_0), Utils.parseRouteQuery(to.query.q_1)]
      queries.forEach((query, idx) => {
        if (QueryUtils.areQueriesEquivalent(query, this[`query_${idx}`]) && to.query[`savedQuery_${idx}`] === this[`savedQueryId_${idx}`]) {
          // Suppress the event from a regular query change
        } else {
          // Continue by setting query value which will trigger page update
          this[`query_${idx}`] = query
          if (to.query[`savedQuery_${idx}`]) {
            this[`savedQueryId_${idx}`] = to.query[`savedQuery_${idx}`]
            // Make sure saved query is selected
            this.$refs[`queryBuilder_${idx}`].selectQuery(to.query[`savedQuery_${idx}`])
          } else {
            this[`savedQueryId_${idx}`] = null
            this.$refs[`queryBuilder_${idx}`].deselectSavedQuery()
          }
        }
        next()
      })
    },
    data () {
      return {
        isQueryVisible: true,
        queryRef_0: null,  // record query refs to avoid handling outdated queries
        queryRef_1: null,
        query_0: [],
        query_1: [],
        query_0_scope: "frame",
        query_1_scope: "frame",
        networkModel_0: null,
        networkModel_1: null,
        recordHits: [null, null], // The number of records hits returned to pass to multiselect
        savedQueryId_0: undefined, // The selected saved query (undefined is equivalent to a missing value in the url)
        savedQueryId_1: undefined,
        queryLoading: false, // If we are waiting for a query check to return (for noresultquery)
        noResultQuery: false, // If a query has no results
        hasDate: Utils.checkSchemaHasDate(this.$store.getters.currentProject.schema),
        // Chart data for correlations charts
        topicsData_0: null,
        topicsData_1: null,
        segmentsData_0: null,
        segmentsData_1: null,
        sentimentData: null,
        modelLoading: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentModel', 'hasNPS', 'hasSentiment', 'currentProject',
        'currentAnalysis', 'currentSite', 'savedQueries', 'featureFlags',
      ]),
      both_queries () {
        return [this.botanicQuery_0, this.botanicQuery_1]
      },
      queryTerms_0 () {
        let values = []
        this.query_0.forEach((q) => {
          if (q.type === 'text' && q.operator === 'includes') {
            q.values.forEach((v) => {
              let term = this.currentModel.terms[v]
              if (term && values.indexOf(term) < 0) {
                values.push(term)
              }
            })
          }
        })
        return values
      },
      queryTerms_1 () {
        let values = []
        this.query_1.forEach((q) => {
          if (q.type === 'text' && q.operator === 'includes') {
            q.values.forEach((v) => {
              let term = this.currentModel.terms[v]
              if (term && values.indexOf(term) < 0) {
                values.push(term)
              }
            })
          }
        })
        return values
      },
      botanicQuery_0 () {
        return QueryUtils.queryRowsToBotanic(this.query_0, this.query_0_scope)
      },
      botanicQuery_1 () {
        return QueryUtils.queryRowsToBotanic(this.query_1, this.query_1_scope)
      },
      // isQueryValid ensures that query rows with a range type have 2
      // values and all other query row types have at least 1 value.
      // (No rows is a valid query -- it will be interpreted as "All Data")
      validQuery () {
        const query_0 = isValid(this.query_0)
        const query_1 = isValid(this.query_1)

        return {
          all: query_0 && query_1,
          any: query_0 || query_1,
          query_0,
          query_1,
        }
      }
    },
    watch: {
      // We need to watch query 1 and query 2. If either changes, we need to push to a new url.
      ...[
        'query_0',
        'query_1'
      ].reduce((watchers, queryKey) => ({
        ...watchers,
        [queryKey]: {
          handler (query) {
            const queryIdx = queryKey.split('_')[1]
            const queryKeyShort = `q_${queryIdx}`
            let urlQuery = {
              q_0: Utils.generateEncodedQuery(this.query_0),
              q_1: Utils.generateEncodedQuery(this.query_1)
            }
            if (this.savedQueryId_0 !== null) {
              urlQuery.savedQuery_0 = this.savedQueryId_0
            }
            if (this.savedQueryId_1 !== null) {
              urlQuery.savedQuery_1 = this.savedQueryId_1
            }
            urlQuery.queryScope_0 = this.query_0_scope
            urlQuery.queryScope_1 = this.query_1_scope
            if (
              query.length === 0 && (this.$route.query[queryKeyShort]||'').length === 0 ||
              urlQuery[queryKeyShort] === this.$route.query[queryKeyShort]
            ) {
              // Don't update the route if both the route and query are
              // functionally equivalent, otherwise we get duplicates
              // in our history stack which makes back/forward navigation janky.
            } else {
              this.$router.push({ name: 'compare-query', query: urlQuery })
            }
            // Now, do something with the query
            if (this.validQuery[queryKey]) {
              // Execute query
              this.executeQuery(queryIdx, query)
            } else if (query.length === 0) {
              // Execute query for All Data
              // (Empty query registers as invalid)
              this.executeQuery(queryIdx, ALL_DATA_QUERY)
            } else {
              // Invalid query
              this.recordHits[queryIdx] = null
            }
          },
          deep: true
        }
      }), {}),
    },
    beforeUnmount () {
      window.removeEventListener('resize', this.resizeHeader)
    },
    mounted () {
      window.addEventListener('resize', this.resizeHeader)
    },
    updated () {
      this.resizeHeader()
    },
    methods: {
      ...mapActions({SET_ERRORS, CLEAR_ERRORS}),
      number: FormatUtils.number,
      /**
       * Modify query row attributes
       * @param {String} query_label required.
       * @param {number} index required.
       * @param {Object} change required.
       */
      updateRow (query_label, index, change) {
        this[query_label] = this[query_label].map((v, i) => {
          return i === index
            ? { ...this[query_label][index], ...change }
            : v
        })
      },
      // Delete query row at specified `index`
      deleteRow (context, index) {
        this[`query_${context}`].splice(index, 1)
        if (this[`query_${context}`].length === 0) {
          this.recordHits[context] = null
          // Clear selected saved query if the entire query is cleared
          this.$refs[`queryBuilder_${context}`].$refs.savedQueries.clearSelection()
          this[`savedQueryId_${context}`] = null
        }
      },
      // Add a date (range) to query when clicked in the timline widget.
      addDateToQuery (date, field, resolution, queryName) {
        let context = parseInt(queryName.split(' ')[1], 10) - 1  // convert query label to index
        this[`query_${context}`] = this[`query_${context}`].concat(QueryUtils.generateDateQuery(date, field, resolution))
      },
      // Add an nps type to query when clicked in the nps widget.
      addNpsToQuery (npsType) {
        this.query = this.query.concat({
          type: 'segment',
          field: 'NPS Category',
          operator: 'is',
          values: [npsType]
        })
      },
      // Add a sentiment type to query when clicked in the sentiment widget.
      addSentimentToQuery (context, sentimentType) {
        this[`query_${context}`] = this[`query_${context}`].concat({
          type: 'attribute',
          field: 'sentiment',
          operator: 'is',
          values: [sentimentType]
        })
      },
      // Add a segment to query when clicked in the segment comparison widget.
      addSegmentToQuery (segment) {
        let segmentName = segment[1]
        let context = segment[0]
        let field, value
        [field, value] = segmentName.split('=')
        this[`query_${context}`] = this[`query_${context}`].concat({
          type: 'segment',
          field: field,
          operator: 'is',
          values: [value]
        })
      },
      // Add concept to query when selected in concept comparison.
      addConceptToQuery (concept) {
        let conceptName = concept[1]
        this[`query_${concept[0]}`] = this[`query_${concept[0]}`].concat({
          type: 'text',
          operator: 'includes',
          values: [conceptName]
        })
      },
      // Add term to query when selected in nested visualiation.
      addTermToQuery (queryIdx, termName) {
        this[`query_${queryIdx}`] = this[`query_${queryIdx}`].concat({
          type: 'text',
          operator: 'includes',
          values: [termName]
        })
      },
      // A unique query id that Vue uses to determine caching/updating of QueryRow components.
      // This is important because when we delete query rows, Vue will try to reuse components which can
      // lead to polluting our updated components with stale state.
      computeQueryId (i, queryRow) {
        return `${i}_${JSON.stringify(queryRow)}`
      },
      // Execute the queries
      executeQuery (context, query) {
        for (const q of query) {
          // Protect against not queries, which have an "all data" query which does not contain an operator
          if (q.hasOwnProperty('operator') && q.operator.endsWith('in the range') && q.values.length < 2) {
            // Don't execute range queries until we have both values set
            this.recordHits[context] = 0
            this.noResultQuery = true
            return
          }
        }
        this.queryLoading = true
        const queryRef = Utils.uuid()
        this[`queryRef_${context}`] = queryRef
        Query.runQuery(
          this.$route.params.projectId,
          this.$route.params.analysisId,
          this[`botanicQuery_${context}`],
          this.savedQueries,
          {
            limit: 0,
            documents: true,
            networkModel: false          }
        )
        .then((data) => {
          if (queryRef !== this[`queryRef_${context}`]) {

            // When our component-level queryRef doesn't match the method-scoped queryRef
            // it means that a re-entrant query has happened and we are executing the callback
            // for an old query. So we just bail out.
            return
          }
          this.recordHits[context] = data.count
          this.$analytics.track.query.compare(context, JSON.stringify(this[`botanicQuery_${context}`]), data.count)
          this.noResultQuery = data.count <= 0
        })
        .catch(errors => {
          this.SET_ERRORS({errors})
          // The query is not valid, we don't pass anything to the child components
          this.noResultQuery = true
        })
        .finally(()=>{
          // At this point the query is valid, so we set the query
          this.queryLoading = false
        })

        this.generateContextNetwork(context, queryRef)

        let promises = []
        let dataTypes = ['topics', 'segments', 'attributes']
        dataTypes.forEach((dataType) => {
          promises.push(
            Query.drilldown(
              this.currentProject.id,
              this.currentAnalysis.id,
              this[`botanicQuery_${context}`],
              dataType,
              0,
              this.savedQueries,
            )
              .then(data => {
                if (queryRef !== this[`queryRef_${context}`]) {
                  // Callback for outdated query, so just bail out here
                  return
                }
                if (dataType === 'attributes') {
                  // handle attributes specially - extract sentiment
                  if (data.counts.sentiment) {
                    this.sentimentData = {
                      counts: data.counts.sentiment,
                      numExcerpts: data.total_hits
                    }
                  } else {
                    this.sentimentData = null
                  }
                } else {
                  // segments or topics
                  this[`${dataType}Data_${context}`] = {
                    correlations: data.npmi,
                    counts: data.counts,
                    numExcerpts: data.total_hits
                  }
                }
              })
          )
        })
        Promise.all(promises)
          .catch(() => {
            // The query is not valid, we don't pass anything to the child components
            this.queryLoading = false
            this.noResultQuery = true
          })
      },
      async generateContextNetwork (context, queryRef: string) {
        this.modelLoading = true
        this.networkModel = null
        let networkConfig = {
          num_network_concepts: 25
        }
        // Provide a baseline for determining influence;
        // Useful for calculating expected frequencies when
        // structured data filters are applied.
        const baselineQuery = QueryUtils.extractStructuredFiltersFromQuery(this[`botanicQuery_${context}`])
        networkConfig.baseline_query = JSON.stringify(baselineQuery)
        Query.generateContextNetwork(
          this.currentProject.id,
          this.currentAnalysis.id,
          this.currentSite.chrysalis_url,
          this.currentProject.chrysalis_ref,
          this.currentAnalysis.topic_framework_id,
          this[`botanicQuery_${context}`],
          this.savedQueries,
          networkConfig
        )
          .then((data) => {
            if (queryRef !== this[`queryRef_${context}`]) {
              // When our component-level queryRef doesn't match the method-scoped queryRef
              // it means that a re-entrant query has happened and we are executing the callback
              // for an old query. So we just bail out.
              return
            }
            this[`networkModel_${context}`] = data.model
          })
          .catch(errors => {
            this.SET_ERRORS({errors})
          })
          .finally(() => {
            this.modelLoading = false
          })
      },
      // Handle saved query selection, bubbled up from QueryBuilder component
      onQuerySelected_0 (savedQueryId: number, queryJson: QueryType) {
        let queryRows = QueryUtils.botanicToQueryRows(queryJson)
        this.savedQueryId_0 = savedQueryId
        this.query_0 = cloneDeep(queryRows)
        this.query_0_scope = queryJson.level || "frame"
      },
      onQuerySelected_1 (savedQueryId: number, queryJson: QueryType) {
        let queryRows = QueryUtils.botanicToQueryRows(queryJson)
        this.savedQueryId_1 = savedQueryId
        this.query_1 = cloneDeep(queryRows)
        this.query_1_scope = queryJson.level || "frame"
      },
      // Handle updated `query` from the QueryBuilder component
      onQueryUpdated (context, query) {
        // Make a new object, so that we don't get the observable from
        // the child widget that emitted this event (otherwise the
        // reactivity becomes very hard to reason about).
        this[`query_${context}`] = cloneDeep(query)
        if (query.length === 0) {
          // Clear selected saved query if the entire query is cleared
          this[`savedQueryId_${context}`] = null
        }
      },
      changeQueryScope (context: number, newQueryScope: string) {
        this[`query_${context}_scope`] = newQueryScope
        this.executeQuery(context, this[`query_${context}`])
      },
      // Used to carry forward the query to the single query screen
      generateQuery (q) {
        return { 'q': Utils.generateEncodedQuery(q) }
      },
      // Update fixed header and position of body content whenever the
      // window resizes or the query changes.
      resizeHeader () {
        const searchContainer = this.$el.querySelector('.search-container')
        const queryContainer = this.$el.querySelector('.query-container')
        const negativeOffset = 50  // without this negative offset, the content is too far down
        this.$nextTick(() => {
          searchContainer.style.top = `0px`
          queryContainer.style.top = `${searchContainer.offsetHeight}px`
        })
      }
    }
  })
</script>

<!-- Unscoped styles required for nested components -->
<style lang="sass">
  .root
    .query-name-row
      padding-bottom: 20px
    .saved-queries-container .dropdown
      margin: 0
</style>

<style lang="sass" scoped>
  @import 'src/assets/kapiche.sass'
  @import 'src/assets/mixins.sass'

  .search-container, .return-button-row, .query-container
    background-color: $grey-light-background

  div.row.return-button-row
    position: sticky !important
    top: 0px
    z-index: 3
    height: 50px
    .button, .updated
      margin-left: 5px
      border-radius: 3px
      font-size: rem(12px)
      font-weight: bold
      text-transform: uppercase
      padding-top: rem(10px)
      padding-bottom: rem(10px)
      &.return-button
        background-color: rgb(248, 149, 22)
        color: white
      &.loading
        pointer-events: none
      &.error
        background-color: $red

  div.query-rows
    background: white
    box-shadow: $box-shadow
    margin-top: rem(20px)
    padding: rem(5px) rem(20px) rem(20px) rem(20px)
    &.show-all
      font-size: 16px
      font-weight: bold
    .label
      line-height: 45px !important

  div.results-statistics
    p
      font-size: 16px

  div.row.search-container
    padding-bottom: 0
    position: sticky !important
    z-index: 9

  div.row.query-container
    position: sticky !important
    z-index: 8
    padding-top: rem(10px)
    .record-count
      font-size: rem(16px)
      margin-left: rem(15px)
      margin-top: rem(16px)
    .toggle-query-show
      bottom: 15px
      color: $blue
      cursor: pointer
      display: inline-block
      position: absolute
      right: 20px
      &:hover
        color: $blue-light

  p.no-matches-title
    font-size: 30px
    color: #95a6ac

  p.no-matches-body
    font-size: 18px
    color: #95a6ac

  div.queries-help
    color: #95a6ac
    h4
      font-size: 1.4rem
      margin-bottom: 0.2rem
      margin-top: 1rem
      font-weight: bold
    p
      font-size: 1.4rem
      margin-bottom: 0.75rem

    ul
      padding-left: 0
      list-style-position: inside
      li
        font-size: 1.15rem
        margin-bottom: 0.4rem


  img.mark-faded
    padding: 4rem
</style>
