<template>
  <tr
    ref="row"
    class="row"
    :class="{ 'active': clicked }"
    @click="$emit('click')"
    @mouseenter="$emit('row-hover')"
    @mouseleave="$emit('end-hover')"
  >
    <td>
      <div class="freq-label">
        <div>{{ row.a_freq }}</div>
        <div>{{ row.b_freq }}</div>
      </div>
    </td>
    <td>
      <div class="menu-wrapper">
        <span class="pair-label">
          <span class="label">{{ row.a_label }}:</span>&nbsp;
          <span v-truncate="50">{{ row.a_name }}</span>
        </span>
        <br />
        <span class="pair-label">
          <span class="label">{{ row.b_label }}:</span>&nbsp;
          <span v-truncate="50">{{ row.b_name }}</span>
        </span>
      </div>
    </td>
    <td v-if="hasNps">
      <div style="width: 120px; text-align: center">
        {{ row.pair_impact }}
      </div>
    </td>
    <td>
      <div
        class="pmi-label"
        :class="getClass(row)"
      >
        <div>{{ getLabel(row) }}</div>
        {{ getScore(row) }}
      </div>
    </td>
    <interaction-menu :get-parent-element="getEl" @self-close="$emit('menu-closed')">
      <button
        v-if="['numeric', 'categorical'].includes(row.a_type)"
        @click="$emit('toggle-filters', [[row.a_label, row.a_name]])"
      >
        Add "<b>{{ row.a_label }}: {{ row.a_name }}</b>" as a filter
      </button>
      <button
        v-if="['numeric', 'categorical'].includes(row.b_type)"
        @click="$emit('toggle-filters', [[row.b_label, row.b_name]])"
      >
        Add "<b>{{ row.b_label }}: {{ row.b_name }}</b>" as a filter
      </button>
      <button
        v-if="['numeric', 'categorical'].includes(row.a_type) && ['numeric', 'categorical'].includes(row.b_type)"
        @click="$emit('toggle-filters', [[row.a_label, row.a_name], [row.b_label, row.b_name]])"
      >
        Add both "<b>{{ row.a_label }}: {{ row.a_name }}</b>" and "<b>{{ row.b_label }}: {{ row.b_name }}</b>" as filters
      </button>
      <button
        v-if="row.a_type === 'theme'"
        @click="$emit('drilldown', row.a_name)"
      >
        Drill down into "<b>Theme: {{ row.a_name }}</b>"
      </button>
      <button
        v-if="row.b_type === 'theme'"
        @click="$emit('drilldown', row.b_name)"
      >
        Drill down into "<b>Theme: {{ row.b_name }}</b>" theme
      </button>
      <button
        v-if="row.a_type === 'theme' && ['numeric', 'categorical'].includes(row.b_type)"
        @click="$emit('drilldown', row.a_name) && $emit('toggle-filters', [[row.b_label, row.b_name]])"
      >
        Drill down into "<b>Theme: {{ row.a_name }}</b>" and add "<b>{{ row.b_label }}: {{ row.b_name }}</b>" as a filter
      </button>
      <button
        v-if="row.b_type === 'theme' && ['numeric', 'categorical'].includes(row.a_type)"
        @click="$emit('drilldown', row.b_name) && $emit('toggle-filters', [[row.a_label, row.a_name]])"
      >
        Drill down into "<b>Theme: {{ row.b_name }}</b>" and add "<b>{{ row.a_label }}: {{ row.a_name }}</b>" as a filter
      </button>
      <button
        v-if="row.a_type !== 'theme' || row.b_type !== 'theme'"
        @click="$emit('open-pivot-table', row.a_label, row.b_label)"
      >
        Create a <b>Pivot Table</b> using this pair
      </button>
      <hr
        v-if="['numeric', 'categorical'].includes(row.a_type) || ['numeric', 'categorical'].includes(row.b_type)"
      />
      <button
        v-if="['numeric', 'categorical'].includes(row.a_type)"
        @click="$emit('exclude-field', row.a_label)"
      >
        Exclude "<b>{{ row.a_label }}</b>" from widget
      </button>
      <button
        v-if="['numeric', 'categorical'].includes(row.b_type)"
        @click="$emit('exclude-field', row.b_label)"
      >
        Exclude "<b>{{ row.b_label }}</b>" from widget
      </button>
    </interaction-menu>
  </tr>
</template>

<script lang='ts'>
import { PropType, defineComponent } from 'vue'
import { Row, getNPMILabel, getOddsRatioLabel } from './SegmentCorrelation.vue'
import InteractionMenu from 'src/components/widgets/InteractionMenu.vue'

export default defineComponent({
  components: { InteractionMenu },
  props: {
    row: { type: Object as PropType<Row>, required: true },
    clicked: { type: Boolean, required: true },
    hasNps: { type: Boolean, required: false, default: false },
  },
  data () {
    return {
      getNPMILabel,
      getOddsRatioLabel,
    }
  },
  methods: {
    getEl (): HTMLElement | undefined {
      return this.$refs.row
    },
    getLabel (row): string {
      return this.getOddsRatioLabel(row.odds_ratio)
    },
    getScore (row): string {
      return `${row.odds_ratio}x more likely`
    },
    getClass (row): string {
      if (row.odds_ratio >= 2) return 'high-positive'
      if (row.odds_ratio >= 1.2) return 'positive'
      if (row.odds_ratio >= 1.0) return 'neutral'
      return 'neutral'
    }
  }
})

</script>

<style lang="sass" scoped>
  @import '~assets/kapiche.sass'

  .pair-label
    background: $grey-light-background
    border-radius: 3px
    font-size: 14px
    line-height: 20px
    color: $text-black
    height: 30px
    display: inline-block
    padding: 5px 10px
    > .label
      color: $grey-dark
    &:first-of-type
      margin-bottom: 5px

  .pmi-label
    border-radius: 3px
    padding: 10px 0
    text-align: center
    width: 170px
    font-size: 14px
    line-height: 18px
    div
      font-weight: 700
    &.high-positive
      background: #F8FFF5
      border: 1px solid #E9FAE2
      color: $green
    &.positive
      background: #F5FDFF
      border: 1px solid #E2F4FA
      color: $blue-light
    &.neutral
      background: #F9F9F9
      border: 1px solid #E7E7E7
      color: $grey-dark
    &.negative
      background: #FFFBF5
      border: 1px solid #FAF0E2
      color: $orange
    &.high-negative
      background: #FFFBFA
      border: 1px solid #FAEDED
      color: $red

  .freq-label
    text-align: right
    > div:first-child
      margin-bottom: 15px

  .row
    cursor: pointer
    position: relative
    transition: box-shadow 0.2s
    &:hover
      box-shadow: 0 0 5px 1px rgba(0, 1, 1, 0.2)

    &.active
      box-shadow: 0 0 5px 1px $blue
    td:nth-child(1)
      padding-right: 20px
      > div
        margin: 10px 0 10px 10px
    td:nth-child(2)
      padding-right: 20px
    td:nth-child(3)
      > div
        margin: 10px 10px 10px auto


  .menu-wrapper
    position: relative

</style>
