// import defineRule from '../defineRule'

import { defineRule } from "vee-validate"

defineRule('alphanumeric', (value: string) => {
  if (typeof value !== 'string') {
    return false
  }

  return /^[a-zA-Z0-9]*$/.test(value) || 'Must be alphanumeric.'
})
