<template>
  <div>
    <ul>
      <li
        v-for="item in items"
        :key="item.value"
        :class="{ disabled: item.disabled }"
        @click="change(item, $event)"
      >
        <input
          :id="item.value"
          class="radio"
          :aria-labelledby="`label_${item.value}`"
          :checked="item.value === selected"
          :value="item.value"
          type="radio"
        >
        <div class="check"></div>
        <label :id="`label_${item.value}`" class="radio">
          <span v-if="item.input === 'daterange'">
            {{ item.label }}
            <div v-if="item.value === selected" class="daterange-input">
              <vue-date-picker
                :teleport="true"
                :model-value="item.inputValue[0]"
                :placeholder="dateFormat"
                :format="dateFormat"
                :max-date="dateRangeConfig(item).maxDate"
                :enable-time-picker="false"
                @update:model-value="updateInput(item, [$event, item.inputValue[1]], 0)"
              />
              <span>to</span>
              <vue-date-picker
                :teleport="true"
                :model-value="item.inputValue[1]"
                :placeholder="dateFormat"
                :format="dateFormat"
                :min-date="dateRangeConfig(item).minDate"
                :enable-time-picker="false"
                @update:model-value="updateInput(item, [item.inputValue[0], $event], 1)"
              />
            </div>
          </span>
          <span v-else>
            {{ item.label }}
          </span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { MenuOptionLabelled } from 'src/types/components/WidgetMenu.types'
import dayjs from 'dayjs'
import VueDatePicker from '@vuepic/vue-datepicker'

export default defineComponent({
  components: {
    VueDatePicker,
  },
  props: {
    /** which item is selected  */
    selected: {
      type: [Number, String, Date],
      required: false,
      default: null,
    },
    /** list of items, { value: Number|String|Date, label: String, disabled: boolean}  */
    items: {
      type: Array as PropType<MenuOptionLabelled[]>,
      required: true
    }
  },
  data () {
    return {
      dateFormat: 'dd/MM/yyyy',
    }
  },
  methods: {
    dateRangeConfig (item: MenuOptionLabelled) {
      return {
        minDate: dayjs(item.inputValue[0], this.dateFormat).toDate(),
        maxDate: dayjs(item.inputValue[1], this.dateFormat).toDate(),
      }
    },
    updateInput (item: MenuOptionLabelled, val: any, index?: number) {
      if (item.input === 'daterange') {
        item.onChange?.(val)

        // Auto-select next input
        if (index === 0 && !item.inputValue[1]) {
          this.$nextTick(() => {
            const input = document.querySelector<HTMLInputElement>('.daterange-input input:nth-of-type(2)')
            input && input.click()
          })
        }
      }
    },
    change ({ value, disabled, input, inputValue }: MenuOptionLabelled, e: Event) {
      e.preventDefault()
      e.stopPropagation()

      if (!disabled && value !== this.selected) {
        /**  value property of item clicked on
          * @event change
          * @type {Number, String, Date}
        */
        const dismiss = input !== 'daterange'
        this.$emit('change', value, dismiss)

        // Auto-select first input
        if (input === 'daterange' && !inputValue[0]) {
          this.$nextTick(() => {
            const input = document.querySelector<HTMLInputElement>('.daterange-input input')
            input && input.click()
          })
        }
      }
    }
  }
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

ul
  list-style: none
  margin: 0
  padding: 0
  overflow: auto

  li
    cursor: pointer
    font-family: $standard-font
    padding: 5px 10px //20px 10px 10px
    color: $text-black
    display: flex
    min-height: 27px
    margin: 0
    label
      cursor: pointer

    &:hover
      background-color: #f4f6f7
    & .disabled
      opacity: 0.5
      cursor: not-allowed
    input[type=radio]
      position: absolute
      // needs to be opacity 0 instead of visibility hidden in order to get
      // keyboard focus to work, but I'm putting that off for the moment
      visibility: hidden
      // opacity: 0

      &:checked ~ .check
        border: 1px solid $blue

        &::before
          background: $blue

    label
      display: block
      position: relative
      padding-left: 10px
      font-weight: normal
      font-size: $base-font-size
      transition: all 0.25s linear

      span
        cursor: pointer

    &:not(.disabled):hover .check
       border: 1px solid $blue

    .check
      box-sizing: content-box
      display: block
      position: relative
      border: 1px solid #d8d8d8
      width: 20px
      height: 20px
      border-radius: 100%
      transition: border .25s linear
      cursor: pointer
      background: $white


      &::before
        display: block
        content: ''
        border-radius: 100%
        height: 12px
        width: 12px
        margin: 4px
        cursor: pointer

  .daterange-input
    white-space: nowrap
    margin-top: 6px
    > span
      color: $text-grey
      margin: 0 6px
    input
      color: $text-black
      border: solid 1px $grey
      padding: 10px
      border-radius: 3px
      width: 110px

</style>
