<template>
  <div class="container">
    <div class="header">
      <h1>
        Manage Labels
      </h1>
    </div>
    <project-label
      color="#068ccc"
      class="create-button"
      name="Create new label"
      icon="el-icon-plus"
      :invert="true"
      @click="createLabel('Unnamed label', '#6B6B6D')"
    />
    <project-label
      name="Back to projects"
      class="back-button"
      color="#068ccc"
      :invert="true"
      @click="$router.push({name: 'home'})"
    />
    <div v-loading="isLoading" class="data-table">
      <el-table
        :data="labels"
      >
        <el-table-column
          prop="color"
          label="Color"
          width="75px"
        >
          <template #default="scope">
            <el-color-picker
              :model-value="scope.row.color || '#068ccc'"
              :predefine="predefineColors"
              color-format="hex"
              size="default"
              @update:model-value="updateLabel(scope.row.id, { color: $event })"
            >
              {{ scope.row.name }}
            </el-color-picker>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="Label"
        >
          <template #default="scope">
            <el-input
              v-show="nameUnderEdit === scope.$index"
              :id="`name_input_${scope.$index}`"
              :model-value="scope.row.name"
              size="small"
              :maxlength="50"
              @update:model-value="scope.row.name = $event"
              @keyup.enter="handleInputConfirm(scope.row.id, 'name', $event)"
              @blur="handleInputConfirm(scope.row.id, 'name', $event)"
            />
            <span v-if="scope.row.name.length == 0" class="input-error">Label name cannot be empty</span>
            <project-label
              v-show="nameUnderEdit !== scope.$index"
              :color="scope.row.color"
              size="medium"
              class="name"
              :name="scope.row.name"
              @click="handleNameClick(scope.$index, `name_input_${scope.$index}`)"
            >
            </project-label>
          </template>
        </el-table-column>
        <el-table-column
          width="200px"
        >
          <template #default="scope">
            <el-button
              text
              @click="handleNameClick(scope.$index, `name_input_${scope.$index}`)"
            >
              Rename
            </el-button>
            <el-button
              text
              class="delete"
              @click="handleDeleteButtonClick(scope.row)"
            >
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span class="footer">
      <el-pagination
        :current-page="pageN"
        layout="prev, pager, next"
        :page-size="PAGE_SIZE"
        :total="response.count || 0"
        hide-on-single-page
        @update:current-page="pageN = $event"
      />
    </span>

    <modal
      :visible="showDeleteModal"
      class="delete-modal"
      @close="showDeleteModal = false"
    >
      <template #header>
        Confirm Delete
      </template>
      <template #content>
        <div class="warning-text">
          Are you sure you want to delete "{{ labelToDelete.name }}"?<br />
          It will be removed from all associated projects.
        </div>
      </template>
      <template #buttons>
        <div class="dialog-footer">
          <el-button @click="cancelDelete">
            Cancel
          </el-button>
          <el-button type="danger" @click="deleteLabel(labelToDelete.id)">
            Delete
          </el-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script lang="ts">
  import { defineComponent, onMounted, ref, nextTick, watch, inject } from 'vue'
  import { ElForm } from 'element-plus'
  import LabelAPI from 'src/api/labels'
  import { Label } from 'src/pages/ProjectLabels/ProjectLabels.util'
  import ProjectLabel from 'src/components/site/ProjectLabels/ProjectLabel.vue'
  import { Analytics } from 'src/analytics'
  import Modal from 'src/components/project/analysis/results/Modal.vue'


  export default defineComponent({
    components : {
      ProjectLabel,
      Modal,
    },
    setup () {
      const analytics = inject<Analytics>('analytics')

      const emptyFormValues = {
        'name': '',
        'description': '',
        'color': '',
      }



      const predefineColors = ref([
        '#9F5151', '#CF5A5A', '#DD6D3E', '#D9AC0D', '#72BE4E', '#619572', '#36C09F', '#3E9FA5', '#44A0C8', '#0F7DBA',
        '#3F5A84', '#464ABC', '#656C7E', '#6751A6', '#707070', '#955454', '#A03E79', '#A5A5A5', '#AB69C2', '#D76293'
      ])

      const isLoading = ref(true)
      const labels = ref<Label[]>([])
      const response = ref({})
      const pageN = ref(1)
      const PAGE_SIZE = 15

      const nameUnderEdit = ref<number|undefined>(undefined)
      const descriptionUnderEdit = ref<number|undefined>(undefined)
      const showDeleteModal = ref<boolean>(false)
      const showCreateModal = ref<boolean>(false)
      const labelToDelete = ref<Label| Record<string, never>>({})
      const createFormValues = ref<Omit<Label, 'id'>>(emptyFormValues)
      const formRules = {
        name: [{ required: true, message: 'Please give this label a name', trigger: 'blur' }]
      }

      // the form ref, would have been this.$refs.createForm
      const createFormRef = ref(null)

      const fetchLabels = async () => {
        isLoading.value = true
        try {
          const res = await LabelAPI.getLabels(pageN.value, undefined, PAGE_SIZE)
          labels.value = res?.results ?? []
          response.value = res
        } finally {
          isLoading.value = false
        }
      }

      watch(pageN, fetchLabels)

      const updateLabel = async (id: number, attrs: Partial<Label>) => {
        analytics?.track.labels.updateLabel(Object.keys(attrs))
        await LabelAPI.updateLabel(id, attrs)
        fetchLabels()
      }

      const deleteLabel = async (id: number) => {
        analytics?.track.labels.deleteLabel()
        await LabelAPI.deleteLabel(id)
        showDeleteModal.value = false
        // Next tick so that the model text doesn't update during close
        // animation
        nextTick( () => labelToDelete.value = {} )
        fetchLabels()
      }

      const createLabel = async (name: string, color: string) => {
        const label = await LabelAPI.createLabel({name, color})
        await fetchLabels()
        const idx = labels.value.findIndex(l => l.id === label.id)
        handleNameClick(idx, `name_input_${idx}`)
        analytics?.track.labels.createdNewLabel()
      }

      const clearCreateForm = () => {
        createFormValues.value = emptyFormValues
        if (createFormRef.value !== null) (createFormRef.value as typeof ElForm).resetFields()
      }

      const cancelCreate = () => {
        showCreateModal.value = false
      }

      const handleDeleteButtonClick = (label: Label) => {
        showDeleteModal.value = true
        labelToDelete.value = label
      }

      const cancelDelete = () => {
        showDeleteModal.value = false
        labelToDelete.value = {}
      }

      const handleInputConfirm = async (
        id: number,
        key: 'name' | 'description',
        $event: Event
      ) => {
        if ($event.target !== null) {
          const value = ($event.target as HTMLInputElement).value
          if (value.length === 0) return
          await updateLabel(id, { [key]: value })
        }
        nameUnderEdit.value = undefined
        descriptionUnderEdit.value = undefined
      }

      const handleNameClick = (tagId: number, inputRef: string) => {
        nameUnderEdit.value = tagId
        const el = document.getElementById(inputRef)
        nextTick(() => el?.focus())
      }

      const handleDescriptionClick = (tagId: number, inputRef: string) => {
        descriptionUnderEdit.value = tagId
        const el = document.getElementById(inputRef)
        nextTick(() => el?.focus())
      }

      onMounted(async () => {
        fetchLabels()
      })

      return {
        PAGE_SIZE,
        isLoading,
        labels,
        predefineColors,
        nameUnderEdit,
        descriptionUnderEdit,
        showDeleteModal,
        showCreateModal,
        labelToDelete,
        createFormValues,
        formRules,
        createFormRef,
        response,
        pageN,
        updateLabel,
        handleNameClick,
        handleDescriptionClick,
        handleInputConfirm,
        handleDeleteButtonClick,
        deleteLabel,
        createLabel,
        cancelDelete,
        cancelCreate,
        clearCreateForm,
      }
    }
  })

</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .container
    padding: 40px 100px
    margin: 0 auto
    width: 900px

    .el-table
      margin: 0 auto
      width: 700px

    ::v-deep .el-table__header .cell
      color: $black
      text-transform: uppercase

    .header
      margin-bottom: 40px
      text-align: center

    .back-button
      margin-left: 20px

    .item
      margin-top: 15px

    .delete
      color: $red

    .create-button
      margin: 10px 0

    .input-error
      color: $red
      font-size: 10px

    .footer
      display: flex
      justify-content: center
      margin-top: 15px

  .dialog-footer
    text-align: center
    margin-top: 20px

  .warning-text
    text-align: center

</style>
