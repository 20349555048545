<template>
  <div>
    <!-- Export limit prompt -->
    <modal-prompt :visible="showDataExportLimitModal" @close="showDataExportLimitModal = false">
      <template #header>
        <div>
          Sorry! This export is unavailable..
        </div>
      </template>
      <template #body>
        <div>
          We currently only support this export when you have {{ number(exportLimit) }} records or less, but this analysis has {{ number(currentModel.stats.n_documents) }}.
          <br /><br />
          Please <a href="javascript:window.Intercom('show');">contact us</a> if you would like data exports for this analysis.
        </div>
      </template>
    </modal-prompt>

    <div class="ui cards">
      <div class="card">
        <div class="content">
          <div class="header">
            Concepts for Documents<i class="file excel outline icon"></i>
          </div>
          <div class="meta">
            CSV
          </div>
          <div class="description">
            Contains a row for every document indicating which concepts it is associated with.
          </div>
        </div>
        <div class="ui bottom attached green button" :class="{loading: loading.documentConcepts}" @click="downloadDocumentTopics">
          <i class="kapiche-icon-download"></i>Download
        </div>
      </div>
      <div class="card">
        <div class="content">
          <div class="header">
            Concepts for Verbatims<i class="file excel outline icon"></i>
          </div>
          <div class="meta">
            CSV
          </div>
          <div class="description">
            Contains a row for every verbatim indicating which concepts it is associated with and its sentiment.
          </div>
        </div>
        <div class="ui bottom attached green button" :class="{loading: loading.frameConcepts}" @click="downloadTextExcerptTopics">
          <i class="kapiche-icon-download"></i>Download
        </div>
      </div>
      <div class="card">
        <div class="content">
          <div class="header">
            Themes for Documents<i class="file excel outline icon"></i>
          </div>
          <div class="meta">
            CSV
          </div>
          <div class="description">
            Contains a row for every document and a 0 or 1 indicating which Theme it is associated with.
          </div>
        </div>
        <div class="ui bottom attached green button" :class="{loading: loading.documentQueries}" @click="downloadDocumentQueries">
          <i class="kapiche-icon-download"></i>Download
        </div>
      </div>
      <div class="card">
        <div class="content">
          <div class="header">
            Themes for Verbatims<i class="file excel outline icon"></i>
          </div>
          <div class="meta">
            CSV
          </div>
          <div class="description">
            Contains a row for every verbatim indicating which Theme it is associated with and its sentiment.
          </div>
        </div>
        <div class="ui bottom attached green button" :class="{loading: loading.frameQueries}" @click="downloadTextExcerptQueries">
          <i class="kapiche-icon-download"></i>Download
        </div>
      </div>
      <div v-if="featureFlags.concept_export" class="card">
        <div class="content">
          <div class="header">
            Export Concepts (incl. Metrics)<i class="file excel outline icon"></i>
          </div>
          <div class="meta">
            CSV
          </div>
          <div class="description">
            Contains concept information and metadata including NPS, sentiment, and influencing terms, at the document level.
          </div>
        </div>
        <div class="ui bottom attached green button" :class="{loading: loading.fullConcepts}" @click="downloadFullExport">
          <i class="kapiche-icon-download"></i>
          Download
        </div>
      </div>
      <div class="card">
        <div class="content">
          <div class="header">
            Theme List<i class="file text outline icon"></i>
          </div>
          <div class="meta">
            TXT
          </div>
          <div class="description">
            Contains a list of themes and their details
          </div>
        </div>
        <div class="ui bottom attached green button" :class="{loading: loading.savedQueriesList}" @click="downloadSavedQueriesList">
          <i class="kapiche-icon-download"></i>
          Download
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { stringify } from 'csv-stringify'

  import ModalPrompt from 'components/widgets/ModalPrompt.vue'
  import Utils from 'src/utils/general'
  import Exports from 'src/api/exports'
  import { mapGetters } from 'vuex'
  import FormatUtils from 'src/utils/formatters'

  export default defineComponent({
    components: { ModalPrompt },
    data () {
      return {
        exportLimit: 100000,
        loading: {
          fullConcepts: false,
          documentConcepts: false,
          frameConcepts: false,
          documentQueries: false,
          frameQueries: false,
          savedQueriesList: false,
        },
        showDataExportLimitModal: false
      }
    },
    computed: {
      ...mapGetters(
        ['currentModel', 'currentAnalysis', 'currentProject', 'currentSite', 'featureFlags', 'expandedSavedQueries']
      ),
    },
    methods: {
      number: FormatUtils.number,
      checkDataExportLimit () {
        this.exportLimit = this.currentSite?.export_limit
        if (this.currentModel.stats.n_documents > this.exportLimit) {
          this.showDataExportLimitModal = true
          return false
        } else {
          return true
        }
      },
      downloadDocumentTopics (event) {
        if (!this.checkDataExportLimit()) {
          return
        }
        this.loading.documentConcepts = true
        Exports.getDocumentTopics(this.$store).then((response) => {
          stringify([response.headers].concat(response.rows), (_, csvString) => {
            Utils.downloadCsv(csvString, 'document-topics')
          })
          this.$analytics.track.analysis.downloadExport('Topics for Documents', 'CSV')
        }).finally(() => {
          this.loading.documentConcepts = false
        })
      },
      downloadTextExcerptTopics (event) {
        if (!this.checkDataExportLimit()) {
          return
        }
        this.loading.frameConcepts = true
        Exports.getTextExcerptTopics(this.$store).then((response) => {
          stringify([response.headers].concat(response.rows), (_, csvString) => {
            Utils.downloadCsv(csvString, 'verbatim-topics')
          })
          this.$analytics.track.analysis.downloadExport('Topics for Verbatims', 'CSV')
        })
        .finally(() => {
          this.loading.frameConcepts = false
        })
      },
      async downloadDocumentQueries (event) {
        if (!this.checkDataExportLimit()) {
          return
        }
        this.loading.documentQueries = true
        try {
          let data = await Exports.getDocumentSavedQueries(
            this.currentProject.id,
            this.currentAnalysis.id,
            this.expandedSavedQueries,
          )
          // If they navigate away from this page, the download will still happen. We've thought about this
          // and made peace with it 🧘
          stringify([data.headers].concat(data.rows), (_, csvString) => {
            Utils.downloadCsv(csvString, 'document-themes')
          })
          this.$analytics.track.analysis.downloadExport('Themes for Documents', 'CSV')
        } finally {
          this.loading.documentQueries = false
        }
      },
      async downloadTextExcerptQueries (event) {
        if (!this.checkDataExportLimit()) {
          return
        }
        this.loading.frameQueries = true
        try {
          let data = await Exports.getTextExcerptSavedQueries(
            this.currentProject.id,
            this.currentAnalysis.id,
            this.expandedSavedQueries,
          )
          // If they navigate away from this page, the download will still happen. We've thought about this
          // and made peace with it 🧘
          stringify([data.headers].concat(data.rows), (_, csvString) => {
            Utils.downloadCsv(csvString, 'verbatim-themes')
          })
          this.$analytics.track.analysis.downloadExport('Themes for Verbatims', 'CSV')
        } finally {
          this.loading.frameQueries = false
        }
      },
      async downloadFullExport () {
        this.loading.fullConcepts = true
        try {
          const res = await Exports.getExportConcepts(
            this.currentAnalysis.project, this.currentAnalysis.id
          )
          stringify([res.headers].concat(res.rows), (_, csvString) => {
            Utils.downloadCsv(csvString, 'concepts')
          })
          this.$analytics.track.analysis.downloadExport('Detailed Concepts', 'CSV')
        } finally {
          this.loading.fullConcepts = false
        }
      },
      async downloadSavedQueriesList () {
        this.loading.savedQueriesList = true
        try {
          const res = await Exports.getSavedQueriesList(this.currentAnalysis.project, this.currentAnalysis.id)
          Utils.downloadTxt(res.text, 'themes')
          this.$analytics.track.analysis.downloadExport('Themes List', 'TXT')
        } finally {
          this.loading.savedQueriesList = false
        }
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import '../../../../assets/kapiche.sass'

  div.ui.cards .card
    width: 360px
    .header .icon
      float: right

  i.kapiche-icon-download
    position: relative
    top: 2px
    right: 5px
    color: $grey-dark

</style>
