<template>
  <div v-if="isLoading" class="loading">
    <bf-spinner />
  </div>
  <div v-else>
    <div class="toolbar">
      <div v-if="!savedQueries.length">
        Create a&nbsp;
        <router-link :to="{ name: 'browse-excerpts' }" class="">
          Theme
        </router-link>
        &nbsp;to use the Radar feature.
      </div>
      <dropdown
        v-else
        :value="null"
        :close-on-click="false"
      >
        <template #trigger>
          <div style="cursor: pointer">
            Select Themes to monitor
            <span v-if="selectedQueries.length" class="selected-text">
              ({{ selectedQueries.length }} selected)
            </span>
            <i class="icon dropdown"></i>
          </div>
        </template>
        <dropdown-item
          v-for="q in savedQueries"
          :key="q.id"
          :value="q.id"
          :focusable="false"
          prevent-active
        >
          <toggle-checkbox
            :value="selectedQueries.includes(q.id)"
            :label="q.name"
            @input="() => toggleQuerySelect(q.id)"
          >
            {{ q.name }}
          </toggle-checkbox>
        </dropdown-item>
      </dropdown>
      <bf-button :color="'green'" size="small" :disabled="!canSave || !canScan" @click="saveSelection">
        Save
      </bf-button>
      <bf-button color="blue" size="small" :disabled="!canScan" @click="refreshRadar">
        Refresh
      </bf-button>
      <bf-button color="blue" size="small" :disabled="!canScan" @click="clearRadar">
        Clear Radar
      </bf-button>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div v-if="!results.length" class="verbatim">
      No Verbatims in Radar
    </div>
    <div
      v-for="result in results"
      :key="result.record._doc_id"
      class="verbatim"
    >
      <div class="query-name">
        {{ getQuery(result.query_id).name }}
      </div>
      <div class="query-date">
        {{ formatDate(result.record[defaultDateField]) }}
      </div>
      <div class="query-content" v-html="extractText(getQuery(result.query_id), result.record[result.record._field], result.record._topics)"></div>
    </div>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import { LOAD_SAVED_QUERIES } from 'src/store/types'
  import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
  import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
  import { BfButton, BfSpinner } from 'components/Butterfly'
  import ToggleCheckbox from 'components/widgets/ToggleCheckbox.vue'
  import QueryAPI from 'src/api/query'
  import { extractText } from 'src/components/DataWidgets/VerbatimsWidget/VerbatimsWidget.utils'
  import QueryUtils from "src/utils/query"
  import DataUtils from "src/utils/data"

  const Radar = defineComponent({
    components: {
      Dropdown,
      DropdownItem,
      ToggleCheckbox,
      BfButton,
      BfSpinner,
    },
    props: {
      analysisId: { type: Number, required: true },
      projectId: { type: Number, required: true },
    },
    data () {
      return {
        isLoading: true,
        selectedQueries: [] as number[],
        results: [],
        canSave: false,
      }
    },
    computed: {
      ...mapGetters([
        'currentAnalysis',
        'currentModel',
        'savedQueries',
        'defaultDateField',
      ]),
      radarQueries () {
        return (this.savedQueries || []).filter((q) => this.selectedQueries.includes(q.id))
      },
      canScan () {
        return this.savedQueries?.length && this.selectedQueries?.length
      },
    },
    async mounted () {
      this.isLoading = true
      await this.LOAD_SAVED_QUERIES({ projectId: this.projectId, analysisId: this.analysisId })
      this.selectedQueries = this.savedQueries.filter(({ radar }) => radar).map(({ id }) => id)
      await this.refreshRadar()
      this.isLoading = false
    },
    methods: {
      ...mapActions({
        LOAD_SAVED_QUERIES,
      }),
      formatDate: DataUtils.formatDate,
      toggleQuerySelect (queryID: number) {
        this.selectedQueries = this.selectedQueries.includes(queryID)
          ? this.selectedQueries.filter((q) => q !== queryID)
          : this.selectedQueries.concat(queryID)
        this.canSave = true
      },
      saveSelection () {
        this.isLoading = true
        this.savedQueries.forEach((query) => {
          const isSelected = this.selectedQueries.includes(query.id)
          const needsUpdate = query.radar !== isSelected
          if (needsUpdate) {
            QueryAPI.updateSavedQuery(
              this.projectId,
              this.analysisId, {
                id: query.id,
                radar: isSelected,
              }
            )
          }
        })
        this.canSave = false
        this.isLoading = false
      },
      async refreshRadar () {
        this.isLoading = true
        const results = await QueryAPI.scanRadar(this.projectId, this.analysisId)
        this.results = results
        this.isLoading = false
      },
      async clearRadar () {
        this.isLoading = true
        await QueryAPI.clearRadar(this.projectId, this.analysisId)
        await this.refreshRadar()
        this.isLoading = false
      },
      textValues (query) {
        let nodes = QueryUtils.getQueryLeafNodesFromBotanicQuery(query.query_value)
        return nodes.filter((n) => n.type === 'text').map((n) => n.value)
      },
      extractText (query, value, topics) {
        return extractText(value, topics, this.currentModel.topics, this.currentModel.terms, this.currentModel.conceptColours, this.textValues(query))
      },
      getQuery (id: number) {
        return this.savedQueries.find((q) => q.id === id)
      },
    }
  })

  export default Radar
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  #unmapped-container
    height: 100%
    padding: 50px 30px 30px 30px

  .verbatim
    background: #fff
    margin-top: 20px
    padding: 20px
    position: relative

    .query-content
      font-size: 15px
      margin-top: 4px

    .query-name
      position: absolute
      background: $blue
      color: #fff
      left: -12px
      top: -12px
      padding: 3px 6px
      border-radius: 3px
      font-size: 12px

    .query-date
      opacity: 0.5

  .loading
    display: flex
    align-items: center
    justify-content: center
    height: 100%

  .selected-text
    color: $text-grey
    font-size: 14px
    margin-top: 2px
    margin-left: 4px

  .toolbar
    display: flex
    margin-bottom: 40px

    > div:first-child
      flex: 1
      font-size: 18px
      display: flex
      align-items: center
      .dropdown-trigger > div
        display: flex
        align-items: center
        .icon
          font-size: 14px
          margin-left: 6px
          margin-top: -2px
          color: $text-black
</style>
