<template>
  <hr v-if="separator" class="dropdown-divider">
  <div v-else-if="title" class="dropdown-title">
    <slot />
  </div>
  <a
    v-else-if="!custom && !hasLink"
    v-truncate="40"
    class="dropdown-item"
    :class="itemClasses"
    :role="ariaRoleItem"
    :tabindex="focusable ? 0 : null"
    @click.stop="selectItem"
  >
    <slot />
  </a>
  <div
    v-else
    v-truncate="40"
    class="dropdown-item"
    :class="itemClasses"
    :role="ariaRoleItem"
    :tabindex="focusable ? 0 : null"
    @click.stop="selectItem"
  >
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      value: {
        type: [String, Number, Boolean, Object, Array, Function],
        default: null
      },
      separator: { type: Boolean, required: false, default: false },
      title: { type: Boolean, required: false, default: false },
      disabled: { type: Boolean, required: false, default: false },
      custom: { type: Boolean, required: false, default: false },
      focusable: {
        type: Boolean,
        required: false,
        default: true
      },
      paddingless: { type: Boolean, required: false, default: false },
      hasLink: { type: Boolean, required: false, default: false },
      preventActive: { type: Boolean, required: false, default: false },
      active: { type: Boolean, required: false, default: false },

      ariaRole: {
        type: String,
        required: false,
        default: ''
      }
    },
    computed: {
      itemClasses () {
        return {
          'dropdown-item': !this.hasLink,
          'disabled': this.disabled,
          'paddingless': this.paddingless,
          'active': (this.$parent.highlightActive && this.isActive && !this.preventActive) || (this.active && !this.preventActive),
          'has-link': this.hasLink
        }
      },
      ariaRoleItem () {
        return this.ariaRole === 'menuitem' || this.ariaRole === 'listitem' ? this.ariaRole : null
      },
      /**
       * Check if item can be clickable.
       */
      isClickable () {
        return !this.$parent.disabled && !this.separator && !this.disabled && !this.custom
      },
      isActive () {
        if (this.$parent.selected === null) return false
        return this.value === this.$parent.selected
      }
    },
    methods: {
      /**
       * Click listener, select the item.
       */
      selectItem () {
        if (!this.isClickable) return

        this.$parent.$parent.selectItem(this.value)
        this.$emit('click')
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import "assets/kapiche.sass"

  $dropdown-item-color: $text-black !default
  $dropdown-item-hover-color: $text-black !default
  $dropdown-item-hover-background-color: $grey-light !default
  $dropdown-item-active-color: $white !default
  $dropdown-item-active-background-color: $blue !default

  $dropdown-divider-background-color: $grey !default

  .dropdown-item, .dropdown-title
    color: $dropdown-item-color
    display: block
    font-size: rem(16px)
    padding: rem(8px) rem(20px)
    position: relative
    cursor: default

  .dropdown-title
    font-weight: bold
    font-size: rem(13px)
    letter-spacing: 0.6px
    text-transform: uppercase
    color: $subdued

  a.dropdown-item,
  button.dropdown-item
    padding-right: 3rem
    text-align: left
    text-decoration: none
    white-space: nowrap
    width: 100%
    &:hover:not(.disabled)
      background-color: $dropdown-item-hover-background-color
      color: $dropdown-item-hover-color
      cursor: pointer
    &.active
      background-color: $dropdown-item-active-background-color
      color: $dropdown-item-active-color

  .dropdown-divider
    background-color: $dropdown-divider-background-color
    border: none
    display: block
    height: 1px
    margin: rem(8px) rem(20px)
</style>
