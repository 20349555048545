<template>
  <div id="page-loader">
    <div class="content">
      <img src="../../assets/logo-inverted.svg">
      <slot>Loading your content...</slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    opacity: { type: Number, default: 1 } // The opacity of the loading mask (0 to 1)
  },
  mounted () {
    // Set loader opacity based on the prop (default)
    document
      .getElementById('page-loader')
      .setAttribute('style', `opacity: ${this.opacity};`)
  }
})
</script>

<style lang="sass" scoped>
  #page-loader
    background-image: linear-gradient(to top, #112126, #15313e 100%)
    z-index: 99999
    position: fixed
    top: 0
    left: 0
    overflow: hidden
    width: 100vw
    height: 100vh
    .content
      margin: auto
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      text-transform: uppercase
      font-weight: bold
      font-size: 0.8rem
      color: #068ccc
      text-align: center
      width: 350px
      height: 100px
      letter-spacing: 0.025rem
      img
        opacity: 1
        animation: flicker 3s infinite
        width: 250px
        display: block
        margin: 0 auto 1.5em auto

  @keyframes flicker
    0%
      opacity: 1
    50%
      opacity: 0.1
    100%
      opacity: 1
</style>
