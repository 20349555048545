<template>
  <div class="tool-tip">
    <slot name="body"></slot>
  </div>
</template>
<script lang='ts'>
  const ChartToolTipBody = {
    name: 'ChartToolTipBody',
    props: {}
  }

  export default ChartToolTipBody
</script>
<style lang="sass" scoped>
    .tool-tip
      ::v-deep
        *
          font-size: 14px
          margin: 0
          color: black
        div.link
          color: #95A6AC
          padding: 0 0 10px 0
          font-size: 15px
        h1
          font-size: 16px
          font-weight: bold
          padding: 5px 0 10px
        h2
          font-size: 16px
          padding: 5px 0
        h2>label
          font-size: 16px
          float: right
</style>
