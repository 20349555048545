import { MenuEntry, WidgetMenuOptions } from "types/components/WidgetMenu.types"
import { SchemaColumn } from 'src/types/SchemaTypes'

// These regex match the `top/bottom x` agg method saved on the project schema.
const topBoxRegexLower = /top (\d+) box/
const botBoxRegexLower = /bot (\d+) box/

export const formatScoreColumns = (column: SchemaColumn, showingExpected: boolean) => {
  let topBoxVal = "2"
  let botBoxVal = "2"
  let match
  if (match = column.score_aggregation?.match(topBoxRegexLower)) topBoxVal = match[1]
  if (match = column.score_aggregation?.match(botBoxRegexLower)) botBoxVal = match[1]
  let aggMap: {
    [key: string]: {
      label: string,
      value: string,
    }
  } = {
    "average": {
      label: "Avgerage",
      value: "average"
    },
    "median": {
      label: "Median",
      value: "median"
    },
    "sum": {
      label: "Sum",
      value: "sum"
    },
  }
  aggMap[`top ${topBoxVal} box`] = {
    label: `Top ${topBoxVal} Box`,
    value: `top_box_${topBoxVal}`

  }
  aggMap[`bot ${botBoxVal} box`] = {
    label: `Bottom ${botBoxVal} Box`,
    value: `bot_box_${botBoxVal}`
  }
  let agg = aggMap[column.score_aggregation ?? "average"]
  let options = [{
    label: `${agg.label} ${column.name}`,
    value: `__score__${agg.value}__${column.name}`,
  }]
  if (showingExpected) {
    options.push({
      label: `Impact on ${agg.label} ${column.name}`,
      value: `__score__impact__${agg.value}__${column.name}`
    })
  }
  return options
}

export const makeMenu = (
  hasQueries = false,
  selectedData = null as string | null,
  hasNps= false,
  npsField = null as string | null,
  selectedDisplay = null as string | null,
  hasSentiment= false,
  hasNumericFields= false,
  numericalFields = [] as string[],
  isZoomed= false,
  showingExpected = false,
  hasThemeGroups = false,
  featureFlags: Record<string, boolean> = {},
  hasScore = false,
  scoreColumns: Array<SchemaColumn> = [],
): WidgetMenuOptions[] => {

  let options = featureFlags.theme_groups
    ? ['Themes', 'Theme Groups', 'Top Concepts', 'Top Phrases']
    : ['Themes', 'Top Concepts', 'Top Phrases']

  const data_menu: MenuEntry = {
    type: 'menu',
    title: 'Source',
    options: options.map(option => {
      if (option === 'Themes' && !hasQueries) {
        return {
          label: 'Themes',
          value: 'Themes',
          disabled: true,
          tooltip: 'There are no Themes added to this Dashboard',
        }
      }

      if (option === 'Theme Groups' && !hasThemeGroups) {
        return {
          label: 'Theme Groups',
          value: 'Theme Groups',
          disabled: true,
          tooltip: 'There are no Theme Groups in this Analysis',
        }
      }

      return option
    }),
    showSelected: true,
    selected: selectedData,
  }
  let nps_menu: MenuEntry | false = false
  if (hasNps && npsField) {
    nps_menu = {
      type: 'menu',
      title: 'NPS',
      options: [
        {value: 'nps_', label: npsField}
      ],
      showSelected: true,
      selected: selectedDisplay
    }
  }

  if (showingExpected && nps_menu) {
    nps_menu.options.push({ value: 'npsi_', label: `Impact on ${npsField}`})
  }

  let sentiment_menu: MenuEntry | false = false
  if (hasSentiment) {
    sentiment_menu = {
      type: 'menu',
      title: 'Sentiment',
      options: [
        'Positive Sentiment', 'Negative Sentiment', 'Mixed Sentiment', 'Neutral Sentiment'
      ],
      showSelected: true,
      selected: selectedDisplay
    }
  }

  if (sentiment_menu && showingExpected) {
    sentiment_menu.options.push('',
      'Impact on Positive Sentiment', 'Impact on Negative Sentiment',
      'Impact on Mixed Sentiment', 'Impact on Neutral Sentiment')
  }
  const freq_menu: MenuEntry = {
    type: 'menu',
    title: 'Frequency',
    options: showingExpected ? ['Frequency (%)', 'Frequency (#)'] : ['Frequency'],
    showSelected: true,
    selected: selectedDisplay
  }

  let numerics_menu: MenuEntry|false = false
  if (hasNumericFields) {
    numerics_menu = {
      type: 'menu',
      title:'Numerical Field',
      options: [...numericalFields.map(nf=>({
        label: `Avg. ${nf}`,
        value: `__avg__${nf}`
      }))
      ],
      showSelected: true,
      selected: selectedDisplay
    }
  }

  if (numerics_menu && showingExpected) {
    numerics_menu.options.push('',
      ...numericalFields.map(nf=>({
        label: `Impact on Avg. ${nf}`,
        value: `__impact_on_avg__${nf}`
      })))
  }

  let score_menu: MenuEntry | false = false
  if (hasScore) {
    let options = []
    for (const col of scoreColumns) {
      let foramatted = formatScoreColumns(col, showingExpected)
      options.push(...foramatted)
    }
    score_menu = {
      type: 'menu',
      title: 'Score',
      // options: scoreColumns.map((col) => formatScoreColumns(col, false)),
      options: options,
      showSelected: true,
      selected: selectedDisplay
    }
  }
  return [
    {
      name: 'Data',
      selection: selectedData,
      options: [[data_menu]],
    },
    {
      name: 'Display',
      selection: selectedDisplay,
      options: (isZoomed ? [
        // as one column
        [freq_menu, nps_menu, sentiment_menu, numerics_menu, score_menu].filter(Boolean),
      ] : [
        // as three columns
        [freq_menu, nps_menu].filter(Boolean),
        [sentiment_menu].filter(Boolean),
        [numerics_menu, score_menu].filter(Boolean),
      ])
        // remove empty sections
        .filter(o => o.length > 0)  as MenuEntry[][],
    },
  ]
}

export const makeMenuCompare = (
  hasQueries = false,
  selectedData = null as string | null,
  hasNps = false,
  npsField = null as string | null,
  selectedDisplay = null as string | null,
  hasSentiment = false,
  hasNumericFields = false,
  numericalFields = [] as string[],
  isZoomed= false,
  hasThemeGroups = false,
  featureFlags: Record<string, boolean> = {},
  scoreColumns: Array<SchemaColumn> = [],
): WidgetMenuOptions[] => {
  const options = featureFlags.theme_groups
    ? ['Themes', 'Theme Groups', 'Top Concepts', 'Top Phrases']
    : ['Themes', 'Top Concepts', 'Top Phrases']

  const dataMenu: MenuEntry = {
    type: 'menu',
    title: 'Source',
    options: options.map((option) => {
      if (option === 'Themes' && !hasQueries) {
        return {
          label: 'Themes',
          value: 'Themes',
          disabled: true,
          tooltip: 'There are no Themes added to this Dashboard',
        }
      }

      if (option === 'Theme Groups' && !hasThemeGroups) {
        return {
          label: 'Theme Groups',
          value: 'Theme Groups',
          disabled: true,
          tooltip: 'There are no Theme Groups in this Analysis',
        }
      }

      return option
    }),
    showSelected: true,
    selected: selectedData,
  }

  let npsMenu: MenuEntry | false = false
  if (hasNps && npsField) {
    npsMenu = {
      type: 'menu',
      title: 'NPS',
      options: [
        { value: 'nps_', label: npsField },
        { value: 'npsi_', label: `Impact on ${npsField}` },
      ],
      showSelected: true,
      selected: selectedDisplay,
    }
  }

  let sentimentMenu: MenuEntry | false = false
  if (hasSentiment) {
    sentimentMenu = {
      type: 'menu',
      title: 'Sentiment',
      options: [
        'Positive Sentiment',
        'Negative Sentiment',
        'Mixed Sentiment',
        'Neutral Sentiment',
      ],
      showSelected: true,
      selected: selectedDisplay,
    }
  }

  const freqMenu: MenuEntry = {
    type: 'menu',
    title: 'Frequency',
    options: ['Frequency (%)', 'Frequency (#)'],
    showSelected: true,
    selected: selectedDisplay,
  }

  let numericsMenu: MenuEntry | false = false
  if (hasNumericFields) {
    numericsMenu = {
      type: 'menu',
      title:'Numerical Field',
      options: [
        ...numericalFields.flatMap((nf) => [{
          label: `Avg. ${nf}`,
          value: `__avg__${nf}`,
        }, {
          label: `Impact on Avg. ${nf}`,
          value: `__impact_on_avg__${nf}`
        }])
      ],
      showSelected: true,
      selected: selectedDisplay,
    }
  }

  let scoreMenu: MenuEntry | false = false
  if (scoreColumns.length > 0) {
    let options = []
    for (const col of scoreColumns) {
      let formatted = formatScoreColumns(col, true)
      options.push(...formatted)
    }
    scoreMenu = {
      type: 'menu',
      title: 'Score',
      options: options,
      showSelected: true,
      selected: selectedDisplay,
    }
  }

  return [
    {
      name: 'Data',
      selection: selectedData,
      options: [[dataMenu]],
    },
    {
      name: 'Display',
      selection: selectedDisplay,
      options: (isZoomed ? [
        // as one column
        [freqMenu, npsMenu, sentimentMenu, numericsMenu, scoreMenu].filter(Boolean),
      ] : [
        // as three columns
        [freqMenu, npsMenu].filter(Boolean),
        [sentimentMenu].filter(Boolean),
        [numericsMenu, scoreMenu].filter(Boolean),
      ])
      // remove empty sections
      .filter(o => o.length > 0)  as MenuEntry[][],
    },
  ]
}
