import Vue from 'vue'
import { $http, HttpOptions } from 'vue-resource/types/vue_resource'

const _retry = async (func: (...args: any[]) => any, count = 60, delay = 1000) => {
  const status_codes_to_retry = [0, 429, 500, 502, 503, 504]
  while (count > 0) {
      try {
        let response = await func()
        return await response.json()
      } catch (error: any) {
        count = count - 1
        if ('status' in error && error.status === 401) {
          throw error
        }
        if (count > 0 && 'status' in error && status_codes_to_retry.includes(error.status)) {
          console.warn(`Failed to contact the server. Retrying ${count}`)
          await new Promise(resolve => setTimeout(resolve, delay))
        } else {
          console.error(`Request failed with error: ${JSON.stringify(error)}`)
          throw error
        }
      }
    }
  }

interface Params {
  count?: number
  delay?: number
  params?: Record<string, any>
  body?: any
  config?: HttpOptions
  headers?: Record<string, any>
}

export default {
  /**
   * Perform an HTTP GET with retries. These retries will only be called if the user is logged in. If the user is
   * logged out due to not being able to contact the backend, the retries will terminate.
   * @param {string} url required.
   * @param {int} count Number of retries.
   * @param {int} delay Delay between retries (ms).
   * @param {object} params query parameters.
   * @returns {Promise}
   */
  async retry (url: string, { count = 10, delay = 1000, params, headers }: Params = {}) {
    const get_fn = Vue.http.get.bind(Vue.http, url, { params, headers })
    return await _retry(get_fn, count, delay)
  },

  /**
   * Perform an HTTP POST with retries. These retries will only be called if the user is logged in. If the user is
   * logged out due to not being able to contact the backend, the retries will terminate.
   * @param {string} url required.
   * @param {int} count Number of retries.
   * @param {int} delay Delay between retries (ms).
   * @param {object} config See https://github.com/pagekit/vue-resource/blob/develop/docs/http.md.
   * @param {object} body Data to be sent as the request body.
   * @returns {Promise}
   */
  async post (url: string, { body, config, count = 10, delay = 1000 }: Params = {}) {
    const post_func = (Vue.http.post as any).bind(Vue.http, url, body, config)
    return await _retry(post_func, count, delay)
  },
  async put (url: string, body: Params['body'], config: Params['config']) {
    const f = (Vue.http.put as any).bind(Vue.http, url, body, config)
    return await _retry(f)
  },
}
