<template>
  <div id="storyboard"></div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import numeric from 'numericjs'
  import $ from 'jquery'
  import * as d3 from 'd3'
  import {event as currentEvent} from 'd3'
  import Tooltip from 'tooltip.js'
  import { mapGetters } from 'vuex'

  import DataUtils from 'src/utils/data'
  import DrawUtils from 'src/utils/draw'
  import MathUtils from 'src/utils/math'
  import Utils from 'src/utils/general'
  import { UPDATE_ANALYSIS_CLUSTERS, UPDATE_ANALYSIS_CONCEPTS } from 'src/store/types'

  export default defineComponent({
    props: {
      conceptsInQueries: { type: Array, required: false, default: ()=>[] },
      dimConceptsInQueries: { type: Boolean, default: false },
      activeConcept: { type: Object, default: null },
      deferRender: { type: Boolean, default: false },
      hullOpacity: { type: Number, default: 0.25 },
      circleHiddenRadius: { type: Number, default: 8 },
      circleSizeRange: { type: Array, default: () => [16, 34] },
      textSizes: { type: Array, default: () => [18, 26, 34, 38] },
      onConceptClick: { type: Function, default: null }
    },
    data () {
      return {
        nodes: null,
        graphNodes: null,
        conceptNames: [],
        conceptPoints: [],
        conceptFreqs: [],
        radiusScale: null,
        termTextScale: null,
        topicClusters: [],
        graphLinks: [],
        svg: null,
        zoom: null,
        currentZoom: null,
        currentPan: null,
        canvas: null,
        nodesByIndex: null,
        conceptCircles: null,
        text: null,
        simulation: null,

      }
    },
    computed: {
      ...mapGetters([
        'currentModel'
      ])
    },
    watch: {
      // Active concept changes are triggered by the concept list
      activeConcept (concept, oldValue) {
        if (oldValue) {
          let i = this.conceptNames.indexOf(oldValue.name)
          this.conceptUnhighlight(d3.select(this.nodes.nodes()[i]))
        }
        if (concept) {
          let i = this.conceptNames.indexOf(concept.name)
          this.conceptHighlight(d3.select(this.nodes.nodes()[i]))
        }
      },
      dimConceptsInQueries ( newValue, oldValue) {
        if (newValue !== oldValue) this.updateConceptDisplay()
      }
    },
    mounted () {
      this.$nextTick(() => {
        if (!this.deferRender) {
          this.draw()
        }
        // Draw controls
        let controls = d3.select('#storyboard').append('div')
          .attr('class', 'controls')
        controls.append('i').attr('class', 'large zoom icon').on('click', () => { this.zoom.scaleBy(this.svg, 1.25) })
        controls.append('i').attr('class', 'large zoom out icon').on('click', () => { this.zoom.scaleBy(this.svg, 0.75) })
      })
    },
    methods: {
      updateConceptDisplay () {
        this.conceptCircles.filter(d=>d.isVisible).style('opacity', d => d.conceptInQuery && this.dimConceptsInQueries ? 0.3 : 1)
        this.text.filter(d=>d.isVisible).style('opacity', d => d.conceptInQuery && this.dimConceptsInQueries ? 0.3 : 1)
      },
      // Draw the storyboard using d3 force simulation. When calling multiple times performs a fresh redraw.
      //
      // Emits `layout-complete` event when the force simulation is complete (stabilised).
      draw () {
        let el = $('#storyboard')
        let width = el.width()
        let height = el.height()
        if (this.svg) {
          // Wipeout all traces of previous storyboard
          d3.select(el.get(0)).select('svg').remove()
        }

        // Data marshalling
        this.conceptNames = []
        this.conceptPoints = []
        this.conceptFreqs = []
        for (let conceptName in this.$store.getters.currentModel.concept_layout) {
          this.conceptNames.push(conceptName)
          let position = this.$store.getters.currentModel.concept_layout[conceptName]
          this.conceptPoints.push(position)
          this.conceptFreqs.push(this.$store.getters.currentModel.terms[conceptName].frequency)
        }
        let freqDomain = d3.extent(this.conceptFreqs)
        this.radiusScale = d3.scaleLinear().domain(freqDomain).range(this.circleSizeRange)
        this.termTextScale = d3.scaleQuantize().domain(freqDomain).range(this.textSizes)
        this.topicClusters = this.currentModel.clusters

        // Generate minimum spanning tree graph structure
        let xPos, yPos
        [xPos, yPos] = numeric.transpose(this.conceptPoints)
        let distanceLinks = this._generatePhysicalLinks(this.conceptPoints, this.conceptNames)
        this.graphLinks = MathUtils.kruskals(this.conceptNames, distanceLinks)
        this.graphNodes = this.conceptNames.map((conceptName) => {
          let node = Object.assign({}, this.currentModel.topics[conceptName])
          node.conceptInQuery = this.conceptsInQueries.includes(conceptName)
          node.isVisible = true
          return node
        })
        this.graphLinks = this.graphLinks.map((l) => {
          return {
            source: this.conceptNames.indexOf(l[0]),
            target: this.conceptNames.indexOf(l[1]),
            value: l[2]
          }
        })
        // Non-uniform scaling of coordinates to optimise space filling
        let xScale = d3.scaleLinear().domain(d3.extent(xPos)).range([0, width])
        let yScale = d3.scaleLinear().domain(d3.extent(yPos)).range([0, height])

        // Setup svg & zoom/pan
        this.svg = d3.select(el.get(0)).append('svg')
          .attr('width', '100%')
          .attr('height', '100%')
        this.zoom = d3.zoom()
          .scaleExtent([1 / 4, 4])
          .on('zoom', () => {
            this.currentZoom = currentEvent.transform.k
            this.currentPan = [currentEvent.transform.x, currentEvent.transform.y]
            this.canvas.attr('transform', currentEvent.transform)
          })
        this.svg.call(this.zoom)
        this.canvas = this.svg.append('g').attr('class', 'canvas')
        // Default zoom
        let scale = 3 / 4
        let zoomWidth = (width - scale * width) / 2
        let zoomHeight = (height - scale * height) / 2
        this.zoom.transform(this.svg, d3.zoomIdentity.translate(zoomWidth, zoomHeight).scale(scale))

        // Setup concept links
        let links = this.canvas.selectAll('line')
          .data(this.graphLinks)
          .enter()
          .append('line')
            .style('stroke', '#95a6ac')

        // Setup concept nodes
        this.nodesByIndex = {} // convient access to rendered concept nodes
        let self = this
        let tooltips: Tooltip[] = []
        let nodes = this.nodes = this.canvas.selectAll('.node').data(this.graphNodes).enter()
          .append('g')
            .attr('class', 'node')
            .attr('transform', function (d, i) {
              d.x = xScale(xPos[i])
              d.y = yScale(yPos[i])
              self.nodesByIndex[i] = this
              return `translate(${d.x},${d.y})`
            })
            .style('cursor', 'pointer')
            .on('mouseenter', function () {
              self.conceptHighlight(d3.select(this))
            })
            .on('mouseleave', function () {
              self.conceptUnhighlight(d3.select(this))
            })
            .on('click', (d, i, nodes) => {
              tooltips[i]?.hide()
              if (this.onConceptClick) {
                this.onConceptClick(d.name, nodes[i])
              } else {
                let queryLink = Utils.generateQueryLink([{
                  type: 'text',
                  values: [d.name],
                  operator: 'includes'
                }])
                this.$router.push(queryLink)
              }
              this.$emit('concept-clicked', d.name)
            })
            .each(function (d, i) {
              const tooltip = new Tooltip(this, {  // eslint-disable-line no-new
                title: Utils.generateConceptTooltip(
                  d, self.currentModel.attribute_info.sentiment,
                  self.currentModel.conceptColours[d.name]
                ),
                html: true,
                placement: 'right',
                container: self.$el,
              })
              tooltips[i] = tooltip
            })
        // concept circles
        this.conceptCircles = nodes.append('circle')
          .attr('r', (d) => {
            d.radius = this.radiusScale(d.frequency)
            d.isVisible = d.frequencyRank <= this.currentModel.numConceptsDisplayed
            return d.isVisible ? d.radius : this.circleHiddenRadius
          })
          .style('fill', (d) => this.currentModel.conceptColours[d.name])
          .style('opacity', d => d.conceptInQuery && this.dimConceptsInQueries ? 0.3 : 1)
        // concept text
        this.text = nodes.append('text')
          .attr('text-anchor', 'middle')
          .style('font-weight', 'normal')
          .style('color', '#383838')
          .style('opacity', (d) => d.isVisible ? ((d.conceptInQuery && this.dimConceptsInQueries ? 0.3 : 1)) : 0)
          .style('font-size', (d) => {
            d.fontSize = this.termTextScale(d.frequency)
            return `${d.fontSize}px`
          })
          .attr('y', (d) => d.fontSize / 4)
        this.text.append('tspan').text((d) => d.name)

        this._drawHulls(this.topicClusters)

        // Create the force layout simulation
        this.simulation = d3.forceSimulation()
          .force('charge', d3.forceManyBody().strength(-5))
          .nodes(this.graphNodes)
          .force('collide', d3.forceCollide().strength(1).radius((d) => d.radius * 1.3).iterations(5))
          .on('tick', () => {
            // update concept node positions
            nodes.attr('transform', (d) => {
              return `translate(${d.x},${d.y})`
            })
            // update links based on concept nodes
            links
              .attr('x1', (d) => this.graphNodes[d.source].x)
              .attr('y1', (d) => this.graphNodes[d.source].y)
              .attr('x2', (d) => this.graphNodes[d.target].x)
              .attr('y2', (d) => this.graphNodes[d.target].y)
            // generate new hull paths for updated node positions
            this.topicClusters.forEach((tc) => {
              let clusterNodes = tc.concepts.map((concept) => this.nodesByIndex[this.conceptNames.indexOf(concept)])
              tc.path = DrawUtils.generateHull(clusterNodes)
            })
            // update topic hulls
            this.canvas.selectAll('.hull').data(this.topicClusters, (d) => d.name)
              .attr('d', (d) => d.path)
              .attr('class', 'hull')
          })
          .on('end', () => {
            this.$emit('layout-complete')
          })
      },
      conceptHighlight (conceptNode) {
        conceptNode.select('circle')
          .transition(300)
            .style('opacity', 0.5)
        conceptNode.select('text')
          .transition(300)
            .style('font-weight', '900')
      },
      conceptUnhighlight (conceptNode) {
        conceptNode.select('circle')
          .transition(300)
            .style('opacity', d => d.conceptInQuery && this.dimConceptsInQueries ? 0.3 :1)
        conceptNode.select('text')
          .transition(300)
            .style('font-weight', '400')
      },
      // Regenerate topic clusters based on `numClusters`.
      updateClusters (numClusters) {
        this.topicClusters = DataUtils.findClusters(this.currentModel, numClusters, this.currentModel.baseClusterColours)
        this.$store.commit(UPDATE_ANALYSIS_CLUSTERS, this.topicClusters)
        if (!this.conceptCircles) return
        this.conceptCircles
          .style('fill', (d) => this.currentModel.conceptColours[d.name])
        this._drawHulls(this.topicClusters)
      },
      // Update the number of concepts shown on the storyboard
      updateNumConcepts (numConcepts) {
        this.$store.commit(UPDATE_ANALYSIS_CONCEPTS, numConcepts)
        // Find nodes that need to be hidden or shown
        // and mark them accordingly
        if (!this.nodes) return
        let nodesToUpdate = this.nodes
          .filter((d) => {
            let isVisible = d.frequencyRank <= numConcepts
            if (isVisible !== d.isVisible) {
              d.isVisible = isVisible
              return true
            }
            return false
          })
        // Update text and circles
        nodesToUpdate
          .selectAll('text')
            .transition().duration(500)
              .style('opacity', (d) => d.isVisible ? ((d.conceptInQuery && this.dimConceptsInQueries) ? 0.3 : 1) : '0')
        nodesToUpdate
          .selectAll('circle')
            .transition().duration(500)
              .attr('r', d => d.isVisible ? d.radius : this.circleHiddenRadius)
              .style('opacity', (d) => (d.conceptInQuery && this.dimConceptsInQueries) ? 0.3 : 1)
      },
      // Draw convex hulls for `topicClusters`.
      _drawHulls (topicClusters) {
        // Generate hull path from rendered concept nodes
        topicClusters.forEach((tc) => {
          let clusterNodes = tc.concepts.map((concept) => this.nodesByIndex[this.conceptNames.indexOf(concept)])
          tc.path = DrawUtils.generateHull(clusterNodes)
        })

        // Draw the hulls
        let self = this
        let hullSelection = this.canvas.selectAll('.hull')
          .data(topicClusters, (d) => d.name)
        this.hulls = hullSelection.enter()
          // Create hulls
          .append('path')
            .attr('class', 'hull')
            .style('fill', (d) => d.colour)
          // Update hulls
          .merge(hullSelection)
            .style('fill-opacity', this.hullOpacity)
            .transition().duration(500)
              .attr('d', function (d) { return d.path })
              .style('fill', (d) => d.colour)
              .each(function () { DrawUtils.moveToBack(this) })
        hullSelection.exit().remove()
      },
      // Generate physical links between all `conceptPoints`.
      //
      // Returns a list as per:
      // [concept1Name, concept2Name, distance]
      _generatePhysicalLinks (conceptPoints, conceptNames) {
        let links = []
        conceptPoints.forEach((c1, i) => {
          conceptPoints.forEach((c2, j) => {
            links.push([
              conceptNames[i],
              conceptNames[j],
              Math.sqrt(Math.pow(c1[0] - c2[0], 2) + Math.pow(c1[1] - c2[1], 2))
            ])
          })
        })
        return links
      },
      // Generate configuration for image/svg export
      getExportConfig (viewableOnly = false, padding = 50, exportScale = 3) {
        let width, height, centerX, centerY, translate, scale
        if (viewableOnly) {
          // Generate a 'screenshot' of the current viewable area
          scale = this.currentZoom
          width = this.svg.node().clientWidth
          height = this.svg.node().clientHeight
          centerX = width / 2 * scale
          centerY = height / 2 * scale
          translate = `${this.currentPan[0]}px, ${this.currentPan[1]}px`
        } else {
          // Generate a smart export that exhibits the full storyboard at a suitable resolution
          scale = exportScale
          let minX = Infinity
          let minY = Infinity
          let maxX = 0
          let maxY = 0
          this.hulls.each(function () {
            let bb = this.getBBox()
            minX = Math.min(minX, bb.x)
            minY = Math.min(minY, bb.y)
            maxX = Math.max(maxX, bb.x + bb.width)
            maxY = Math.max(maxY, bb.y + bb.height)
          })
          width = (maxX - minX) * scale + padding * 2
          height = (maxY - minY) * scale + padding * 2
          centerX = minX * scale + width / 2
          centerY = minY * scale + height / 2
          translate = `${width / 2 - centerX + padding}px, ${height / 2 - centerY + padding}px`
        }
        let dims = {
          width: width,
          height: height,
          centerX: centerX,
          centerY: centerY
        }
        let css = `
          .canvas {
            transform: translate(${translate}) scale(${scale})
          }
        `
        return {
          dims: dims,
          css: css
        }
      }
    }
  })
</script>
<style lang="sass">
  #storyboard
    height: 100%
    .node:focus
      outline: none !important
    .controls
      position: absolute
      display: grid
      bottom: 20px
      right: 20px
      background: none
      color: #ccc
      opacity: 0.9
      i
        cursor: pointer
        margin: 5px

  .tooltip, .popper
    span.sentiment.ribbon
      color: #ffffff
      &.positive
        background-color: #21BA45
      &.mixed
        background-color: #ffb628
      &.negative
        background-color: #DB2828
      &.neutral
        background-color: #767676
    table
      width: 100%
      tr > td:nth-of-type(2)
        text-align: right
        border-left: 5px solid transparent
      tr.sentiment
        &.positive
          color: #37c759
        &.negative
          color: #fa5948
        &.mixed
          color: #f89516
        &.neutral
          color: #8c8c8c
</style>
