import * as types from '../types'
import Subscription from '../../api/subscription'
import { Actions } from '..'
import { Nullable } from 'src/types/utils'

type Mutations = Record<string, (state: SubscriptionState, ...args: any[]) => any>
type Getters = Record<string, (state: SubscriptionState) => any>

const default_state = {
  domain: undefined,
  termEnd: undefined,
  userLimit: undefined as Nullable<number>,
  viewerUserLimit: undefined as Nullable<number>,
  membershipCount: undefined,
  viewerMembershipCount: undefined,
  dataUnitsBalance: undefined as Nullable<number>,
  trialing: undefined,
  allowsAccess: undefined,
  exceededLimits: undefined,
  errorMessage: undefined as Nullable<string>,
  projectColumnLimit: undefined,
  projectRowLimit: undefined,
  exportAPIEnabled: undefined,
  enforceRowLimit: undefined,
  planId: undefined as Nullable<number>,
  languageTranslation: undefined,
}

export type SubscriptionState = typeof default_state

const mutations: Mutations = {
  [types.SET_SUBSCRIPTION] (state: SubscriptionState, data: SubscriptionState) {
    state.domain = data.domain
    state.termEnd = data.termEnd
    state.userLimit = data.userLimit
    state.viewerUserLimit = data.viewerUserLimit
    state.membershipCount = data.membershipCount
    state.viewerMembershipCount = data.viewerMembershipCount
    state.dataUnitsBalance = data.dataUnitsBalance
    state.trialing = data.trialing
    state.allowsAccess = data.allowsAccess
    state.exceededLimits = data.exceededLimits
    state.projectColumnLimit = data.projectColumnLimit
    state.projectRowLimit = data.projectRowLimit
    state.enforceRowLimit = data.enforceRowLimit
    state.errorMessage = data.errorMessage || null
    state.exportAPIEnabled = data.exportAPIEnabled
    state.languageTranslation = data.languageTranslation
  },

  [types.CLEAR_SUBSCRIPTION] (state: SubscriptionState) {
    state = { ...default_state }
  },
}

const actions: Actions = {
  /**
   * Load the features that are allowed by the current site's subscription.
   */
  async [types.LOAD_SUBSCRIPTION] ({ commit }) {
    let data = { ...default_state }
    try {
      const res = await Subscription.getSubscription()
      Object.assign(data, res)
    } catch (error: any) {
      data.errorMessage = error.body
    } finally {
      commit(types.SET_SUBSCRIPTION, data)
    }
  }
}

const getters: Getters = {
  userLimit: (state) => state.userLimit,
  viewerUserLimit: (state) => state.viewerUserLimit,
  membershipCount: (state) => state.membershipCount,
  viewerMembershipCount: (state) => state.viewerMembershipCount,
  exceededLimits: (state) => state.exceededLimits,
  subscriptionErrorMessage: (state) => state.errorMessage?.toString(),
  subscription: (state) => ({
    domain: state.domain,
    termEnd: state.termEnd,
    userLimit: state.userLimit,
    membershipCount: state.membershipCount,
    viewerUserLimit: state.viewerUserLimit,
    viewerMembershipCount: state.viewerMembershipCount,
    dataUnitsBalance: state.dataUnitsBalance,
    trialing: state.trialing,
    allowsAccess: state.allowsAccess,
    exceededLimits: state.exceededLimits,
    errorMessage: state.errorMessage,
    projectColumnLimit: state.projectColumnLimit,
    projectRowLimit: state.projectRowLimit,
    enforceRowLimit: state.enforceRowLimit,
    exportAPIEnabled: state.exportAPIEnabled,
    languageTranslation: state.languageTranslation,
  })
}

export default {
  state: { ...default_state },
  mutations,
  actions,
  getters,
}
