import { marked } from 'marked'
import { sanitize } from 'dompurify'
import { gfmHeadingId } from 'marked-gfm-heading-id'

const options: marked.MarkedOptions = {
  mangle: false,
}

marked.use(gfmHeadingId({}))

export function markdown (text: string, trusted=false): string {
  return trusted ? marked(text, options) : sanitize(marked(text, options))
}

export function removemarkdown (text: string): string {
  return text
    // Remove headers (##, ###, etc.)
    .replace(/^#+\s/gm, '')
    // Remove bold/italic markers
    .replace(/(\*\*|__)(.*?)\1/g, '$2')
    .replace(/(\*|_)(.*?)\1/g, '$2')
    // Remove horizontal rules
    .replace(/^-{3,}$/gm, '')
    // Remove blockquotes
    .replace(/^>\s/gm, '')
    // Remove inline code
    .replace(/`(.+?)`/g, '$1')
    // Remove lists
    .replace(/^[\*\-+]\s/gm, '')
    .replace(/^\d+\.\s/gm, '')
    // Remove links
    .replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '$1')
    // Remove images
    .replace(/!\[([^\]]+)\]\(([^\)]+)\)/g, '')
    // Trim whitespace
    .trim()
}