<template>
  <div class="progress-indicator">
    <span v-for="i in numSteps" :key="i">
      <i class="icon circle" :class="{ 'active': i <= step }"></i>
      <div v-if="i < numSteps" class="divider" :class="{ 'active': i < step }"></div>
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      numSteps: { type: Number, default: 1 },
      step: { type: Number, default: 1 }
    }
  })
</script>

<style lang="sass" scoped>
  @import '../../assets/kapiche.sass'
  @import '../../assets/mixins.sass'

  i.icon
    color: $text-grey
    font-size: rem(12px)
    margin-right: 0
    opacity: 0.3
    &.active
      color: $blue
      opacity: 1
  .divider
    border-top: 1px solid $text-grey
    margin-left: rem(1px)
    margin-right: rem(1px)
    opacity: 0.3
    display: inline-block
    margin-bottom: rem(3px)
    width: rem(16px)
    &.active
      border-color: $blue !important
      opacity: 1
</style>
