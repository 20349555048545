import $ from 'jquery'

export type Vector = [number, number]

export default {
  // Given an event, measure the influence of potential driver.
  calculateInfluence (observedCooccurrence: number, eventFreq: number, driverFreq: number, numFrames: number) {
    let driverCoverage = driverFreq / numFrames
    let expectedCooccurrence = eventFreq * driverCoverage
    return observedCooccurrence - expectedCooccurrence
  },
  // Measure the (normalised) pointwise mutual information (PMI) between word 'w' and context 'c'.
  // Returns a value in the range [-1, 1], representing total orthogonality and congruence respectively.
  // 0 represents chance distribution.
  //
  // PMI is defined by "calculating the log of the ratio between their joint probability (the frequency in which they
  // occur together) and their marginal probabilities (the frequency in which they occur independently)." [1]
  //
  // Normalising PMI has been demonstrated to have moderate but positive affects in some empirical studies [2].
  //
  // The formula below uses the simplified PMI formula (10) provided in [1].
  //
  // [1] Levy, O. Neural Word Embedding as Implicit Matrix Factorization
  // [2] Bouma, G. Normalized Pointwise Mutual Information in Collocation Extraction
  calculateNormalisedPMI: function (fwc: number, fw: number, fc: number, numFrames: number, round = true) {
    if (fwc === 0) {
      return 0
    }
    let result = Math.log(
      (fwc * numFrames) / (fw * fc)
    ) / -Math.log(fwc / numFrames)
    if (round) {
      result = this.roundCorrelation(result)
    }
    return result
  },

  // Convenience method for consistent rounding of correlation values.
  roundCorrelation: (v: number) => Math.round(v * 1000) / 1000,

  // Kruskal's spanning tree algorithm, generated for a list of `nodes` and a list of `edges`.
  // This version solves for MAXIMUM edge weights rather than minimum.
  // See http://en.wikipedia.org/wiki/Kruskal's_algorithm
  kruskals: function (nodes: any[], edges: any[], findMaximum = false) {
    const mst = []
    let forest = $.map(nodes, function (node) {
      return [[node]]  // Have to double-nest as jquery flattens the returned array
    })

    // Sort copy of edges by strength, ascending
    edges = edges.concat()
    edges.sort(function (e1: number[], e2: number[]) {
      if (findMaximum) {
        return e1[2] - e2[2]
      }
      return e2[2] - e1[2]
    })

    while (forest.length > 1 && edges.length > 0) {
      // Pop strongest edge from last position
      let e = edges.pop()
      let n1 = e[0]
      let n2 = e[1]

      // Find tree that contains node [n1]
      let t1 = $.grep(forest, function (tree) {
        return tree.indexOf(n1) >= 0
      })
      // Find tree that contains node [n2]
      let t2 = $.grep(forest, function (tree) {
        return tree.indexOf(n2) >= 0
      })
      let diff = t1.filter(function (x) { return t2.indexOf(x) < 0 })
      // Compare trees of n1 & n2
      if (diff.length > 0) {
        // Trees are separate first, remove them from forest...
        forest = $.grep(forest, function (tree) {
          return tree.indexOf(n1) < 0 && tree.indexOf(n2) < 0
        })
        // ...then, re-insert union of two trees back into forest
        forest.push(t1[0].concat(t2[0]))
        mst.push(e)
      }
    }

    return mst
  },
  // Vector from p0 to p1
  vecFrom (p0: Vector, p1: Vector): Vector {
    return [p1[0] - p0[0], p1[1] - p0[1]]
  },
  // Vector v scaled by 'scale'
  vecScale (v: Vector, scale: number): Vector {
    return [scale * v[0], scale * v[1]]
  },
  // The sum of two points/vectors
  vecSum (pv1: Vector, pv2: Vector): Vector {
    return [pv1[0] + pv2[0], pv1[1] + pv2[1]]
  },
  // Vector with direction of v and length 1
  vecUnit (v: Vector) {
    let norm = Math.sqrt(v[0] * v[0] + v[1] * v[1])
    return this.vecScale(v, 1 / norm)
  },
  // Vector with direction of v with specified length
  vecScaleTo (v: Vector, length: number) {
    return this.vecScale(this.vecUnit(v), length)
  },
  // Unit normal to vector pv0, or line segment from p0 to p1
  unitNormal (pv0: Vector, p1?: Vector) {
    if (p1 != null) pv0 = this.vecFrom(pv0, p1)
    let normalVec = [-pv0[1], pv0[0]] as Vector
    return this.vecUnit(normalVec)
  },
  /**
   * This method is used when normalising sentiment data.
   * @param sentimentSeries {Object} - An object where the each property is
   *    the type of sentiment (positive, negative, mixed, neutral) and the
   *    value is the raw count of records as a number.
   * @param decimal {Boolean} - defaults to false. If this is false, then
   *      the object that is returned use the decimal representation of the
   *      normalised values. If this flag is true, then the returned object
   *      will have the percentages represented as string values out of 100.
   *      I.e. if decimal is true the return value might be:
   *        { 'positive': 0.5, 'negative': 0.3, 'mixed': 0.2 }
   *      However if decimal is false the return value would be:
   *        { 'positive': 50, 'negative': 30, 'mixed': 20 }
   * @return {Object} - An object which has the same properties as the input
   *    (sentimentSeries) but where the value of those properties are
   *    converted from the raw values to percentages (where 100 percent is the
   *    sum of all the properties).
   */
  // Where sentimentSeries is an array of objects that have a counts attribute
  sentimentCountsToPercentages (sentimentSeries: Record<string, number>, decimal = false) {
    ['positive', 'negative', 'mixed', 'neutral'].forEach(sentimentType => {
      // Zero missing/undefined sentiment values
      sentimentSeries[sentimentType] = sentimentSeries[sentimentType] || 0
    })
    const percents: Record<string, number> = {}
    let totalCounts = Object.values(sentimentSeries).reduce(
      (accumulator: number, val: number) => accumulator + val, 0
    )
    for (const key of Object.keys(sentimentSeries)) {
      if (totalCounts === 0) {
        percents[key] = 0
      } else {
        percents[key] = (sentimentSeries[key] / totalCounts)
      }

      if (!decimal) {
        percents[key] = +(percents[key] * 100).toFixed()
      }
    }
    return percents
  },
  /** Get the maximum absolute value of data in the dataset, for a particular
   *  field name.
   *
   * @param {Object[]} dataset The entire data set, which is made up
   *     multiple queries' data. Each query has a "data" property which
   *     contains the actual data for multiple fields. The "fieldName"
   *     parameter below will index into this "data" object, returning the
   *     object representing that field's data. One of the properties of
   *     that object, "counts", is the one that will be processed here.
   * @param {number} capValue Maximum allowed final value.
   * @param {number} multipleOf The max will be increased until it is
   *     a multiple of this value.
   * @returns {number} The max absolute value, capped by `capValue` and
   *     normalised to a multiple of `multipleOf`.
   *
   */
  getDataMax (dataset: any[], capValue = 100, multipleOf = 5) {
    // First find the maximum abs value in the data, disregarding NaN values.
    let maxval = -1
    for (const series of dataset) {
      let counts = series.counts
      for (const value of counts.filter((x: number) => !isNaN(x))) {
        maxval = Math.max(Math.abs(value), maxval)
      }
    }

    // Bump the max up to the nearest multiple of 5
    if (multipleOf) {
      let remainder = (maxval % multipleOf)
      if (remainder !== 0) {
        // If the value is not already a multiple of the number, make it so.
        // For example, if maxval at this point is "23", then 23 % 5 would
        // be 3. We want to bump that to 25, so subtract 3, then add 5
        maxval = maxval - remainder + multipleOf
      }
    }

    // Constrain the value to a final allowed maximum
    if (capValue) {
      maxval = Math.min(capValue, maxval)
    }
    return maxval
  },
  getPercentDiff (a: number, b: number) {
    const numer = Math.abs(a - b)
    const denom = a === 0 ? 1 : Math.abs(a)
    const percent = numer / denom * 100
    let sign = ''
    if (a < b) {
      sign = '+'
    } else if (a > b) {
      sign = '-'
    }
    return `${sign}${percent.toFixed(2)}%`
  }
}


export const calcWeightedAverage = (
  data: { value: number, weight: number }[]
): number => {
  const totalWeight = data.reduce((sum, item) => sum + item.weight, 0)

  // Avoid division by zero
  if (!totalWeight) {
      return 0
  }

  const weightedSum = data.reduce((sum, item) => sum + item.value * item.weight, 0)
  return weightedSum / totalWeight
}