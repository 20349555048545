import { formatNPS, decimalAsPercent, number } from 'src/utils/formatters'
import Utils from 'src/utils/general'
import {relativeDiff} from "components/DataWidgets/DataWidgetUtils"
import { DataToolTipInterface } from 'src/types/components/DataToolTip.types'
import { TableChartRowType } from 'src/types/components/Charts.types'
import { SavedQuery } from 'src/types/Query.types'
import { getScoreHeaders, topBoxRegex, botBoxRegex, aggRegex, formatDisplayForLabel, formatDisplayForConfig } from './ScoreUtils'

interface ItemBase {
  [propname: string]: Record<string, number> | number,
}

export interface Item extends ItemBase {
  frequency_percent__: number
  frequency_expected__: number
  frequency: number
  frequency_percent_expected__: number
  sentiment__: {
    'negative%__': number
    'positive%__': number
    'mixed%__': number
    'neutral%__': number
    'neutral%___expected__': number
    'negative%___expected__': number
    'positive%___expected__': number
    'mixed%___expected__': number
  }
  'NPS Category': {
    'npsi_rto__': number
    'nps__': number
    'npsi_rto___expected__': number
    'nps___expected__': number
  }
}

export interface CompareItem {
  sliceOne: Item
  sliceTwo: Item
}

interface ChartRow {
  columns: {
    value: number
  }[]
}

export const selectedDisplayToLabel = (value: string) => {
  if (value.startsWith('__avg__')) return `Avg. ${selectedDisplayToPayloadField(value)}`
  if (value.startsWith('__impact_on_avg__')) return `Impact on Avg. ${selectedDisplayToPayloadField(value)}`
  if (value === 'nps_') return 'NPS'
  if (value === 'npsi_') return 'Impact on NPS'
  if (value.startsWith("__score__")) return formatDisplayForLabel(value)
  return value
}

export const selectedDisplayToPayloadField = (value: string): keyof Item =>{
  const prefixes = ['__avg__', '__impact_on_avg__']
  for (const prefix of prefixes) {
    if (value.startsWith(prefix)) {
      return value.slice(prefix.length) as keyof Item
    }
  }
  return value as keyof Item
}

export const getHeader = (label: string, display: string, sortBy: string, asc: boolean, showingExpected=true, isFiltered=false) => {
  let impactTarget: string = isFiltered ? 'Filtered' : 'Overall'
  let prefix = `IMPACT ON ${impactTarget}`
  const expected_headers = {
    'Frequency (#)': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'FREQ. (#)'}],
    'Frequency (%)': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'FREQ. (%)'}],
    'nps_': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null as boolean | null, label:'NPS'}],
    'npsi_': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} NPS`}],
    'Positive Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'POSITIVE SENT.'}],
    'Negative Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'NEGATIVE SENT.'}],
    'Mixed Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'MIXED SENT.'}],
    'Neutral Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'NEUTRAL SENT.'}],
    'Impact on Positive Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} POSITIVE SENT.`}],
    'Impact on Negative Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} NEGATIVE SENT.`}],
    'Impact on Mixed Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} MIXED SENT.`}],
    'Impact on Neutral Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} NEUTRAL SENT.`}],

    '_': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label: selectedDisplayToLabel(display)?.toUpperCase()}],
  }
  const regular_headers = {
    'Frequency': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:'FREQ. (#)'}, {sortable: true, sortAsc: null, label:'FREQ. (%)'}],
    'nps_': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} NPS`}, {sortable: true, sortAsc: null, label:'NPS'}],
    'Positive Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} POSITIVE SENT.`}, {sortable: true, sortAsc: null, label:'POSITIVE SENT.'}],
    'Negative Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} NEGATIVE SENT.`}, {sortable: true, sortAsc: null, label:'NEGATIVE SENT.'}],
    'Mixed Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} MIXED SENT.`}, {sortable: true, sortAsc: null, label:'MIXED SENT.'}],
    'Neutral Sentiment': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label:`${prefix} NEUTRAL SENT.`}, {sortable: true, sortAsc: null, label:'NEUTRAL SENT.'}],

    '_': [{label, sortable: true, sortAsc: null, }, {sortable: true, sortAsc: null, label: `${prefix} ${selectedDisplayToLabel(display)?.toUpperCase()}`}, {sortable: true, sortAsc: null, label: selectedDisplayToLabel(display)?.toUpperCase()}],
  }
  const headers = showingExpected ? expected_headers : regular_headers
  let header = headers[display as keyof typeof headers]
    ? headers[display as keyof typeof headers]
    : headers['_']
  if (display.startsWith("__score")) {
    header = getScoreHeaders(label, display, showingExpected) ?? []
  }
  if (showingExpected) {
    header = header.concat([
      { sortable: true, sortAsc: null, label:'Expected' },
      { sortable: true, sortAsc: null, label:'Diff.' },
    ])
  }

  if (header[sortBy as any]) header[sortBy as any].sortAsc = asc
  return header
}

/**
 * Determine the minimum value for display for a set of rows based on
 * what is being displayed.
 */
export const minValue = (rowsForChart: TableChartRowType[], pad=5) => {
  const values = rowsForChart.flatMap((r) => [r.bars?.[0].percent ?? 0, r.indicator ?? 0])
  const lowest = Math.ceil(Math.floor(Math.min(...values) / pad) * pad)
  return lowest
}

/**
 * Determine the maximum value for display for a set of rows based on
 * what is being displayed.
 */
export const maxValue = (rowsForChart: TableChartRowType[], pad=5) => {
  const values = rowsForChart.flatMap((r) => [r.bars?.[0]?.percent ?? 0, r.bars?.[1]?.percent ?? 0, r.indicator ?? 0])
  const highest = Math.floor(Math.ceil(Math.max(...values) / pad) * pad)
  return highest
}

// In some cases there are specified limits.
export const minMaxValues = (rowsForChart: TableChartRowType[], selectedDisplay: string, sortBy: number, showingExpected=false, pad=5) => {
  const min = minValue(rowsForChart, pad)
  const max = maxValue(rowsForChart, pad)

  switch (selectedDisplay) {
    case 'nps_':
      return showingExpected ? { min, max } : [{min, max}, {min, max}, { min: -100, max: 100}][sortBy]
    case 'npsi_':
      return { min, max }
    case 'Frequency':
    case 'Frequency (#)':
      return { min, max }
    case 'Frequency (%)':
    case 'Positive Sentiment':
    case 'Negative Sentiment':
    case 'Neutral Sentiment':
    case 'Mixed Sentiment':
      return showingExpected ? { min: 0, max } : [{min: 0, max: 100}, {min, max}, {min: 0, max: 100}][sortBy]
    case 'Impact on Positive Sentiment':
    case 'Impact on Negative Sentiment':
    case 'Impact on Neutral Sentiment':
    case 'Impact on Mixed Sentiment':
      const minAbs = Math.abs(min)
      const maxAbs = Math.abs(max)
      const biggest = Math.max(minAbs, maxAbs)
      return { min: -biggest, max: biggest }
    default:
      // if we are here then it's numerics
      return { min, max }
  }
}

const formatNumber = (value: number, format?: string) => {
  switch (format) {
    case 'number':
      return `${value}`
    case 'percent':
      return decimalAsPercent(value/100)
    case 'nps':
      return formatNPS(value)
    default:
      return number(value, format)
  }
}

const makeRowExpected = (id: number, label: string, bar: any, [observed, format1]: any, [expected, format2]: any, format3?: string) => {
  return {
    id: id,
    label: label,
    bars: [{ percent: bar }],
    indicator: expected,
    columns: [
      {
        value: observed,
        label: formatNumber(observed, format1)
      },
      {
        value: expected,
        label: formatNumber(expected, format2)
      },
      {
        value: observed - expected,
        label: formatNumber(observed - expected, format3)
      }
    ]
  }
}

const makeRowRegular = (id: number, label: string, bar: any, [col1=0, format1]: any, [col2=0, format2]: any) => {
  return {
    id: id,
    label: label,
    bars: [{ percent: bar }],
    indicator: null,
    columns: [
      {
        value: col1,
        label: formatNumber(col1, format1)
      },
      {
        value: col2,
        label: formatNumber(col2, format2)
      },
    ]
  }
}

const makeRowCompare = (
  id: number,
  label: string,
  bars: [number, number],
  [col1=0, format1]: any,
  [col2=0, format2]: any,
) => {
  return {
    id: id,
    label: label,
    bars: [
      { percent: bars[0], color: '#11ACDF' },
      { percent: bars[1], color: '#8064AA' },
    ],
    indicator: null,
    columns: [
      {
        value: col1,
        label: formatNumber(col1, format1)
      },
      {
        value: col2,
        label: formatNumber(col2, format2)
      },
    ]
  }
}

const makeRow = (id: number, label: string, bar: any, [col1, format1]: any, [col2, format2]: any, showingExpected=false, format3?: string) => {
  if (showingExpected) {
    return makeRowExpected(id, label, bar, [col1, format1], [col2, format2], format3)
  } else {
    return makeRowRegular(id, label, bar, [col1, format1], [col2, format2])
  }
}

export const payloadDataToRows = (
  payloadData: any,
  queries: any,
  selectedDisplay: string,
  showingExpected: boolean,
  sortBy: number,
  nameFormatter: (name: string) => string = (name) => name,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return queries.map((query: any) => {
    const dataItem = payloadData[query.name]
    if (!dataItem) return undefined

    if (selectedDisplay === 'Frequency') {
      const items = [dataItem.frequency_percent__, dataItem.frequency, dataItem.frequency_percent__]
      return makeRow(
        query.id,
        nameFormatter(query.name),
        items[sortBy],
        [dataItem.frequency, undefined],
        [dataItem.frequency_percent__, 'percent']
      )
    } else if (selectedDisplay === 'Frequency (#)') {
      return makeRow(
        query.id,
        nameFormatter(query.name),
        dataItem.frequency,
        [dataItem.frequency, undefined],
        [dataItem.frequency_expected__, undefined],
        true
      )
    } else if (selectedDisplay === 'Frequency (%)') {
      return makeRow(
        query.id,
        nameFormatter(query.name),
        dataItem.frequency_percent__,
        [dataItem.frequency_percent__, 'percent'],
        [dataItem.frequency_percent_expected__, 'percent'],
        true
      )
    } else if (selectedDisplay === 'nps_') {
      const items = [dataItem['NPS Category'].nps__, dataItem['NPS Category'].npsi_rto__, dataItem['NPS Category'].nps__]
      return makeRow(
        query.id,
        nameFormatter(query.name),
        showingExpected ? dataItem['NPS Category'].nps__ : items[sortBy],
        showingExpected? [dataItem['NPS Category'].nps__, 'nps'] : [dataItem['NPS Category'].npsi_rto__, 'nps'],
        showingExpected? [dataItem['NPS Category'].nps___expected__, 'nps'] : [dataItem['NPS Category'].nps__, 'nps'],
        showingExpected,
        'nps'
      )
    } else if (selectedDisplay === 'npsi_') {
      return makeRow(
        query.id,
        nameFormatter(query.name),
        dataItem['NPS Category'].npsi_rto__,
        [dataItem['NPS Category'].npsi_rto__, 'nps'],
        [dataItem['NPS Category'].npsi_rto___expected__, 'nps'],
        true,
        'nps'
      )
    } else if (['Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment'].includes(selectedDisplay)) {
      const parts = selectedDisplay.toLowerCase().split(' ')
      const sentimentKey = `${parts[0].toLowerCase()}\%__`
      const impactKey = `${parts[0].toLowerCase()}\%i_rto__`
      const items = [dataItem.sentiment__[sentimentKey], dataItem.sentiment__[impactKey], dataItem.sentiment__[sentimentKey]]
      return makeRow(
        query.id,
        nameFormatter(query.name),
        showingExpected ? dataItem.sentiment__[sentimentKey] : items[sortBy],
        showingExpected ? [dataItem.sentiment__[sentimentKey], 'percent'] : [dataItem.sentiment__[impactKey], 'percent'],
        showingExpected ? [dataItem.sentiment__[`${sentimentKey}_expected__`], 'percent'] : [dataItem.sentiment__[sentimentKey], 'percent'],
        showingExpected,
        'percent'
      )
    } else if (
      [
        'Impact on Positive Sentiment',
        'Impact on Negative Sentiment',
        'Impact on Neutral Sentiment',
        'Impact on Mixed Sentiment'
      ].includes(selectedDisplay)) {
      const sentimentImpactParts = selectedDisplay.toLowerCase().split(' ')
      const impactKey = `${sentimentImpactParts[2].toLowerCase()}\%i_rto__`
      return makeRow(
        query.id,
        nameFormatter(query.name),
        dataItem.sentiment__[impactKey],
        [dataItem.sentiment__[impactKey], 'percent'],
        [dataItem.sentiment__[`${impactKey}_expected__`], 'percent'],
        true,
        'percent'
      )
    } else if (selectedDisplay.startsWith("__score")) {
        if (
          selectedDisplay.match(aggRegex)
        ) {
          const ops = selectedDisplay.match(aggRegex)
          if (!ops) return
          let fieldname = "aggVal"
          let fieldKey = "mean__"
          let impactKey = "mean__i_rto__"
          if (!dataItem[fieldname]) return undefined
          if (showingExpected) {
              if (ops[1] === "impact__") {
                return makeRow(
                  query.id,
                  nameFormatter(query.name),
                  dataItem[fieldname][impactKey],
                  [dataItem[fieldname][impactKey], "0.00"],
                  [dataItem[fieldname][`${impactKey}_expected__`], "0.00"],
                  true
                )
              } else {
                return makeRow(
                  query.id,
                  nameFormatter(query.name),
                  dataItem[fieldname][fieldKey],
                  [dataItem[fieldname][fieldKey], "0.00"],
                  [dataItem[fieldname][`${fieldKey}_expected__`], "0.00"],
                  true
                )
              }
          } else {
            return makeRow(
              query.id,
              nameFormatter(query.name),
              dataItem[fieldname][fieldKey],
              [dataItem[fieldname][impactKey], "0.00"],
              [dataItem[fieldname][fieldKey], "0.00"],
            )
          }
        } else if (
          selectedDisplay.match(topBoxRegex) || selectedDisplay.match(botBoxRegex)
        ) {
          let ops = selectedDisplay.match(topBoxRegex)
            ? selectedDisplay.match(topBoxRegex) ?? []
            : selectedDisplay.match(botBoxRegex) ?? []
          let fieldname  = ops[3]
          let boxKey = "box%__"
          let impactKey = "box%i_rto__"
          if (!dataItem[fieldname]) return undefined
          if (showingExpected) {
            if (ops[1] === "impact__") {
              return makeRow(
                query.id,
                nameFormatter(query.name),
                dataItem[fieldname][impactKey],
                [dataItem[fieldname][impactKey], "0.00"],
                [dataItem[fieldname][`${impactKey}_expected__`], "0.00"],
                true
              )

            } else {
              return makeRow(
                query.id,
                nameFormatter(query.name),
                dataItem[fieldname][boxKey],
                [dataItem[fieldname][boxKey], "percent"],
                [dataItem[fieldname][`${boxKey}_expected__`], "percent"],
                true
              )
            }
          } else {
            return makeRow(
              query.id,
              nameFormatter(query.name),
              dataItem[fieldname][boxKey],
              [dataItem[fieldname][impactKey], undefined],
              [dataItem[fieldname][boxKey], "percent"],
            )
          }
        }
      }
    // label, bar, [col1, format1], [col2, format2], showingExpected=false, format3=undefined
    else {
      // if we are here then it's numerics
      const numeric_field = selectedDisplayToPayloadField(selectedDisplay)
      let field_type = ''
      if (selectedDisplay.startsWith('__avg__'))field_type = 'mean__'
      if (selectedDisplay.startsWith('__impact_on_avg__'))field_type = 'mean__i_rto__'
      if (field_type === '') return undefined

      /*
      When a numeric field is first selected this computed prop will start updating before the new data
      starts loading.  As the current payload won't contain a matching numeric field it will error.
      As soon at the data fetch starts, `isLoading` is set to true and a spinner is shown instead
      of the rows & this will ditch out at the very beginning instead of erroring  until the new
      data is loaded.  So if we get to here and the field is missing then just return undefined to
      avoid that transitory error.
      */
      if (!dataItem[numeric_field]) return undefined

      if (showingExpected) {
        // expected:
        // avg, impact
        //
        // expected: observed, expected, diff
        return makeRow(
          query.id,
          nameFormatter(query.name),
          dataItem[numeric_field][field_type],
          [dataItem[numeric_field][field_type], undefined],
          [dataItem[numeric_field][`${field_type}_expected__`], undefined],
          true
        )
      } else {
        // regular:
        // avg
        // -> impact, value
        const items = [dataItem[numeric_field]['mean__'], dataItem[numeric_field]['mean__i_rto__'], dataItem[numeric_field]['mean__']]
        return makeRow(
          query.id,
          nameFormatter(query.name),
          items[sortBy],
          [dataItem[numeric_field]['mean__i_rto__'], undefined],
          [dataItem[numeric_field]['mean__'], undefined],
          false,
        )
      }
    }
  }).filter(Boolean)
}

export const payloadDataToRowsCompare = (
  payloadData: Record<string, CompareItem>,
  queries: any,
  selectedDisplay: string,
  nameFormatter: (name: string) => string = (name) => name,
) => {
  return queries.map((query: any) => {
    const dataItem = payloadData[query.name]
    if (!dataItem) return undefined

      if (selectedDisplay === 'Frequency (#)') {
        return makeRowCompare(
          query.id,
          nameFormatter(query.name),
          [dataItem.sliceOne.frequency, dataItem.sliceTwo.frequency],
          [dataItem.sliceOne.frequency, undefined],
          [dataItem.sliceTwo.frequency, undefined]
        )
      } else if ( selectedDisplay === 'Frequency (%)') {
        return makeRowCompare(
          query.id,
          nameFormatter(query.name),
          [dataItem.sliceOne.frequency_percent__, dataItem.sliceTwo.frequency_percent__],
          [dataItem.sliceOne.frequency_percent__, 'percent'],
          [dataItem.sliceTwo.frequency_percent__, 'percent']
        )
      } else if (selectedDisplay === 'nps_') {
        return makeRowCompare(
          query.id,
          nameFormatter(query.name),
          [dataItem.sliceOne['NPS Category'].nps__, dataItem.sliceTwo['NPS Category'].nps__],
          [dataItem.sliceOne['NPS Category'].nps__, 'nps'],
          [dataItem.sliceTwo['NPS Category'].nps__, 'nps'],
        )
      } else if (selectedDisplay === 'npsi_') {
        return makeRowCompare(
          query.id,
          nameFormatter(query.name),
          [dataItem.sliceOne['NPS Category'].npsi_rto__, dataItem.sliceTwo['NPS Category'].npsi_rto__],
          [dataItem.sliceOne['NPS Category'].npsi_rto__, 'nps'],
          [dataItem.sliceTwo['NPS Category'].npsi_rto__, 'nps'],
        )
      } else if (
        selectedDisplay === 'Positive Sentiment' ||
        selectedDisplay === 'Negative Sentiment' ||
        selectedDisplay === 'Neutral Sentiment' ||
        selectedDisplay === 'Mixed Sentiment'
       ) {
        const parts = selectedDisplay.toLowerCase().split(' ')
        const sentimentKey = `${parts[0].toLowerCase()}\%__` as keyof Item['sentiment__']
        return makeRowCompare(
          query.id,
          nameFormatter(query.name),
          [dataItem.sliceOne.sentiment__[sentimentKey], dataItem.sliceTwo.sentiment__[sentimentKey]],
          [dataItem.sliceOne.sentiment__[sentimentKey], 'percent'],
          [dataItem.sliceTwo.sentiment__[sentimentKey], 'percent'],
        )
      } else if (selectedDisplay.startsWith("__score")) {
        if (selectedDisplay.match(aggRegex)) {
          let fieldname = "aggVal"
            const ops = selectedDisplay.match(aggRegex)
            if (!ops) return
            let fieldKey = "mean__"
            if (ops[1] === "impact__") {
              fieldKey = "mean__i_rto__"
            }
          if (!dataItem.sliceOne[fieldname]) return undefined
          if (!dataItem.sliceTwo[fieldname]) return undefined
          const sl1 = dataItem.sliceOne[fieldname] as Record<string, number>
          const sl2 = dataItem.sliceTwo[fieldname] as Record<string, number>
          return makeRowCompare(
            query.id,
            nameFormatter(query.name),
            [sl1[fieldKey], sl2[fieldKey]],
            [sl1[fieldKey], "0.00"],
            [sl2[fieldKey], "0.00"],
          )
        } else if (selectedDisplay.match(topBoxRegex) || selectedDisplay.match(botBoxRegex)) {
          let ops = selectedDisplay.match(topBoxRegex)
            ? selectedDisplay.match(topBoxRegex) ?? []
            : selectedDisplay.match(botBoxRegex) ?? []
          if (!ops) return
          let fieldname = ops[3]
          let boxKey = "box%__"
          let format = "percent"
          if (ops[1] === "impact__") {
            boxKey = "box%i_rto__"
            format = "0.00"
          }
          if (!dataItem.sliceOne[fieldname]) return undefined
          if (!dataItem.sliceTwo[fieldname]) return undefined
          let sl1 = dataItem.sliceOne[fieldname] as Record<string, number>
          let sl2 = dataItem.sliceTwo[fieldname] as Record<string, number>
          return makeRowCompare(
            query.id,
            nameFormatter(query.name),
            [sl1[boxKey], sl2[boxKey]],
            [sl1[boxKey], format],
            [sl2[boxKey], format],
          )
        }
      } else {
        // if we are here then it's numerics
        const numeric_field = selectedDisplayToPayloadField(selectedDisplay)
        let field_type = ''
        if (selectedDisplay.startsWith('__avg__')) field_type = 'mean__'
        if (selectedDisplay.startsWith('__impact_on_avg__')) field_type = 'mean__i_rto__'
        if (field_type === '') return undefined

        const key = field_type as keyof typeof dataItem.sliceOne[typeof numeric_field]

        /*
        When a numeric field is first selected this computed prop will start updating before the new data
        starts loading.  As the current payload won't contain a matching numeric field it will error.
        As soon at the data fetch starts, `isLoading` is set to true and a spinner is shown instead
        of the rows & this will ditch out at the very beginning instead of erroring  until the new
        data is loaded.  So if we get to here and the field is missing then just return undefined to
        avoid that transitory error.
        */

        if (!dataItem.sliceOne[numeric_field]) return undefined
        if (!dataItem.sliceTwo[numeric_field]) return undefined
        let sl1 = dataItem.sliceOne[numeric_field] as Record<string, number>
        let sl2 = dataItem.sliceTwo[numeric_field] as Record<string, number>
        return makeRowCompare(
          query.id,
          nameFormatter(query.name),
          [sl1[key], sl2[key]],
          [sl1[key], undefined],
          [sl2[key], undefined],
        )
      }
    }
  ).filter(Boolean)
}

export const sortRows = (chartRows: any, sortBy: number, ascendingSort: boolean) => {
  let rows = [].concat(chartRows)

  let accessor
  switch (sortBy) {
    case 0:
      accessor = (obj: any) => obj?.label
      break
    case 1:
    case 2:
    case 3:
      accessor = (obj: any) => obj?.columns?.[sortBy-1]?.value
      break
    default:
      accessor = () => 1 // Force equal
  }
  const options = {
    accessor,
    direction: ascendingSort ? 'asc' : 'desc',
    nullsFirst: false
  }
  rows.sort(Utils.naturalSort(options))
  return rows
}

export const payloadDataToCSVExport = (
  payloadData: any,
  selectedDisplay: string,
  selectedDataLabel: string,
  queries=[] as any[],
  showingExpected=false,
  hasNps=false,
  hasSentiment=false,
  hasNumerics=false,
  numericalFields=[] as string[],
  sliceNames=null as [string, string] | null,
  nameMap: Record<number, string>,
  groupNameMap: Record<number, string>,
) => {
  const isThemesOrGroups = ['Theme', 'Theme Group'].includes(selectedDataLabel)

  return queries.map((query: any) => {
    const dataRow = payloadData[query.name]
    if (!dataRow) return undefined

    const suffixArr = sliceNames
      ? [['sliceOne', ` (${sliceNames[0]})`], ['sliceTwo', ` (${sliceNames[1]})`]]
      : [['', '']]

    let label = isThemesOrGroups
      ? nameMap[query.id]
      : query.name

    if (isThemesOrGroups && groupNameMap[query.id]) {
      label += ` [${groupNameMap[query.id]}]`
    }

    let row: Record<string, any> = {
      [selectedDataLabel]: label,
    }

    suffixArr.forEach(([name, suffix]) => {
      const dataItem = name ? dataRow[name] : dataRow
      if (!dataItem) return undefined

      row['frequency#' + suffix] = dataItem.frequency
      row['frequency%' + suffix] = number(dataItem.frequency_percent__, '0.00')

      if (showingExpected) {
        row['frequency#_expected' + suffix] = dataItem.frequency_expected__
        row['frequency%_expected' + suffix] = number(dataItem.frequency_percent_expected__, '0.00')
      }

      if (hasNps) {
        row['nps' + suffix] = number(dataItem['NPS Category'].nps__, '0.00')
        row['nps_impact' + suffix] = number(dataItem['NPS Category'].npsi_rto__, '0.00')
      }

      if (hasNps && showingExpected) {
        row['nps_expected' + suffix] = number(dataItem['NPS Category'].nps___expected__, '0.00')
        row['nps_expected_impact' + suffix] = number(dataItem['NPS Category'].npsi_rto___expected__, '0.00')
      }

      if (hasSentiment) {
        row['sentiment_positive' + suffix] = number(dataItem['sentiment__']['positive%__'], '0.00')
        row['sentiment_negative' + suffix] = number(dataItem['sentiment__']['negative%__'], '0.00')
        row['sentiment_neutral' + suffix] = number(dataItem['sentiment__']['neutral%__'], '0.00')
        row['sentiment_mixed' + suffix] = number(dataItem['sentiment__']['mixed%__'], '0.00')
        row['sentiment_positive_impact' + suffix] = number(dataItem['sentiment__']['positive%i_rto__'], '0.00')
        row['sentiment_negative_impact' + suffix] = number(dataItem['sentiment__']['negative%i_rto__'], '0.00')
        row['sentiment_neutral_impact' + suffix] = number(dataItem['sentiment__']['neutral%i_rto__'], '0.00')
        row['sentiment_mixed_impact' + suffix] = number(dataItem['sentiment__']['mixed%i_rto__'], '0.00')
      }

      if (hasSentiment && showingExpected) {
        row['sentiment_positive_expected' + suffix] = number(dataItem['sentiment__']['positive%___expected__'], '0.00')
        row['sentiment_negative_expected' + suffix] = number(dataItem['sentiment__']['negative%___expected__'], '0.00')
        row['sentiment_neutral_expected' + suffix] = number(dataItem['sentiment__']['neutral%___expected__'], '0.00')
        row['sentiment_mixed_expected' + suffix] = number(dataItem['sentiment__']['mixed%___expected__'], '0.00')
        row['sentiment_positive_impact_expected' + suffix] = number(dataItem['sentiment__']['positive%i_rto___expected__'], '0.00')
        row['sentiment_negative_impact_expected' + suffix] = number(dataItem['sentiment__']['negative%i_rto___expected__'], '0.00')
        row['sentiment_neutral_impact_expected' + suffix] = number(dataItem['sentiment__']['neutral%i_rto___expected__'], '0.00')
        row['sentiment_mixed_impact_expected' + suffix] = number(dataItem['sentiment__']['mixed%i_rto___expected__'], '0.00')
      }

      /* Numeric fields will only be exported if one is selected for display and only that
        field will be exported.  This is due to the fact that we currently only request
        that data if it is being displayed.  This is for performance reasons.
        Future work may allow these fields to be requested independenty for an export.
      */
      const numeric_field = selectedDisplayToPayloadField(selectedDisplay) as string
      const showNumericField = hasNumerics && numericalFields.includes(numeric_field)
      if (showNumericField) {
        row[`${numeric_field}_avg` + suffix] = number(dataItem[numeric_field]['mean__'], '0.00')
        row[`${numeric_field}_avg_impact` + suffix] = number(dataItem[numeric_field]['mean__i_rto__'], '0.00')
      }
      if (showNumericField && showingExpected) {
        row[`${numeric_field}_avg_expected` + suffix] = number(dataItem[numeric_field]['mean___expected__'], '0.00')
        row[`${numeric_field}_avg_impact_expected` + suffix] = number(dataItem[numeric_field]['mean__i_rto___expected__'], '0.00')
      }
    })

    return row
  }).filter(Boolean)
}



export const payloadToData = (
  payload: any,
  queries: any,
  showingExpected: boolean,
  dataDisplay: string,
) =>
  queries.reduce((data: any, query: any) => {
    const getName = (q: SavedQuery) => {
      if (dataDisplay === 'Themes') {
        return `q_${q.id}`
      }
      if (dataDisplay === 'Theme Groups') {
        return `group_${q.id}`
      }
      return q.name
    }
    const rowOverall = payload?.overall?.find((item: any) => item.group__ === getName(query))
    // If there are no filters then rowFilters should match rowOverall
    const rowFiltered = payload?.filtered?.find((item: any) => item.group__ === getName(query)) ?? null

    if (rowOverall === undefined) return data
    if (!rowFiltered) return data

    data[query.name] = {}

    // overall total (as opposed to a query's total)
    data.observedTotal = payload?.filtered?.find((p: any) => p.group__ === 'overall__')?.frequency
    if (showingExpected) {
      data.observedTotal = payload?.filtered?.find((p: any) => p.group__ === 'overall__')?.frequency
      data.expectedTotal = payload?.overall?.find((p: any) => p.group__ === 'overall__')?.frequency
    }

    const payloadKeys = Object.keys(showingExpected ? rowFiltered : rowFiltered).filter(k => k !== 'group__').map(k => k.split('|'))

    payloadKeys.forEach((keys) => {
      keys.forEach((key, index) => {
        if (index === 0) {
          if (keys.length === 1) {
            data[query.name][key] = rowFiltered[key]
            if (showingExpected) {
              data[query.name][`${key}_expected__`] = Math.round(data.observedTotal * ( rowOverall[key] / data.expectedTotal ))
            }
            if (key==='frequency') {
              data[query.name].frequency_percent__ = (data[query.name]['frequency']/data.observedTotal)*100
            }
            if (key==='frequency' && showingExpected) {
              data[query.name].frequency_percent_expected__ = rowOverall[key] / data.expectedTotal * 100
            }
          } else {
            if (!data[query.name][key]) data[query.name][key] = {}
          }
        }
        if (index > 0) {
          data[query.name][keys[0]][key] = rowFiltered[keys.join('|')]
          if (showingExpected) {
            data[query.name][keys[0]][`${key}_expected__`] = rowOverall[keys.join('|')]
          }
        }
      })
    })
    return data
  }, {})

export const payloadToDataCompare = (
  payload: any,
  queries: SavedQuery[],
  dataDisplay: string,
) => {
  return queries.reduce((data: Record<string, CompareItem>, query) => {
    const getName = (q: SavedQuery) => {
      if (dataDisplay === 'Themes') {
        return `q_${q.id}`
      }
      if (dataDisplay === 'Theme Groups') {
        return `group_${q.id}`
      }
      return q.name
    }

    const rowOverall = payload?.overall?.find((item: any) => item.group__ === getName(query))
    // If there are no filters then rowFilters should match rowOverall
    const rowFilteredOne: Record<string, number> | null = payload?.sliceOne?.find((item: any) => item.group__ === getName(query)) ?? null
    const rowFilteredTwo: Record<string, number> | null = payload?.sliceTwo?.find((item: any) => item.group__ === getName(query)) ?? null

    if (rowOverall === undefined) return data
    if (!rowFilteredOne && !rowFilteredTwo) return data

    data[query.name] = {
      sliceOne: {} as Item,
      sliceTwo: {} as Item,
    }

    // Determine the union of keys from both rows (excluding 'group__')
    const allKeys = new Set([
      ...Object.keys(rowFilteredOne || {}),
      ...Object.keys(rowFilteredTwo || {})
    ].filter(k => k !== 'group__'))

    const payloadKeys = Array.from(allKeys).map(k => k.split('|')) as (keyof Item)[][]

    // Initialise both slices with default values
    payloadKeys.forEach((keys) => {
      const initializeSliceWithKeys = (slice: Item) => {
        keys.forEach((key, index) => {
          // Assign first key of pair to row. If a second key exists,
          // it will be assigned to a nested object of the first key.
          if (index === 0) {
            slice[key] = keys.length === 1 ? 0 : {} as any
          } else {
            const k = keys[0] as 'sentiment__' | 'NPS Category'

            if (k === 'sentiment__') {
              slice[k][key as keyof Item['sentiment__']] = 0
            }

            if (k === 'NPS Category') {
              slice[k][key as keyof Item['NPS Category']] = 0
            }
          }
        })
      }
      initializeSliceWithKeys(data[query.name].sliceOne)
      initializeSliceWithKeys(data[query.name].sliceTwo)
    })

    // Assign each row if not null
    ;[rowFilteredOne, rowFilteredTwo].forEach((row, i) => {
      if (!row) return

      const sliceKey = i === 0 ? 'sliceOne' : 'sliceTwo'
      const observedTotal = payload?.[sliceKey]?.find((p: any) =>
        p.group__ === 'overall__'
      )?.frequency || 0

      payloadKeys.forEach(keys => {
        const dataRow = data[query.name][sliceKey]
        keys.forEach((key, index) => {
          if (index === 0) {
            if (keys.length === 1) {
              dataRow[key] = (row[key] || 0) as Item[keyof Item] as any
              if (key === 'frequency') {
                dataRow.frequency_percent__ = observedTotal ? (dataRow['frequency'] / observedTotal) * 100 : 0
              }
            }
          } else {
            const k = keys[0] as string

            if (k === 'sentiment__') {
              dataRow[k][key as keyof Item['sentiment__']] = row[keys.join('|')] || 0
            } else if (k === 'NPS Category') {
              dataRow[k][key as keyof Item['NPS Category']] = row[keys.join('|')] || 0
            } else {
              const dr = dataRow[k] as Record<string, number>
              dr[key] = row[keys.join("|") || 0]
            }
          }
        })
      })
    })

    return data
  }, {} as Record<string, CompareItem>)
}

export const toolTipData = (name: string, dataItem: Item, chartRow: ChartRow, selectedDisplay: string, selectedDataLabel: string, hasNps: boolean, hasSentiment: boolean, showingExpected: boolean, isFiltered=false) => {

  let impactTarget: string = isFiltered ? 'Filtered' : 'Overall'
  let prefix = `Impact on ${impactTarget}`

  let theme = {
    title: selectedDisplayToLabel(name),
    action: `Click for explore options`,
    data: [] as DataToolTipInterface[]
  }
  const valueStyle = {
    'font-weight': 'bold',
    'color': ''
  }
  if (showingExpected) {
    // if freq is selected
    if (['Frequency (%)', 'Frequency (#)'].includes(selectedDisplay)) {
      theme.data = [
        {
          label: 'Observed Frequency (#/%)',
          value: {
            text: `${number(dataItem.frequency)} / ${number(dataItem.frequency_percent__, '0,0.[00]')}%`,
            style: { ...valueStyle, color: '#11ACDF' }
          }
        },
        {
          label: 'Expected Frequency (#/%)',
          value: {
            text: `${number(dataItem.frequency_expected__)} / ${number(dataItem.frequency_percent_expected__, '0,0.[00]')}%`,
            style: { ...valueStyle, color: '#8064AA' }
          }
        },
        {
          label: 'Raw Difference (#/%)',
          value: {
            text: `${number(dataItem.frequency - dataItem.frequency_expected__)} / ${number(dataItem.frequency_percent__ - dataItem.frequency_percent_expected__, '0,0.[00]')}%`,
            style: valueStyle
          }
        },
        {
          label: 'Relative Difference',
          value: {
            text: `${decimalAsPercent(relativeDiff(dataItem.frequency_percent__, dataItem.frequency_percent_expected__)/100)}`,
            style: valueStyle
          }
        },
        {
          break: true
        }
      ]
    } else {
      // everything else
      theme.data = [
        {
          label: `Observed ${selectedDisplayToLabel(selectedDisplay)}`,
          value: {
            text: `${number(chartRow?.columns[0]?.value)}`,
            style: { ...valueStyle, color: '#11ACDF' }
          }
        },
        {
          label: `Expected ${selectedDisplayToLabel(selectedDisplay)}`,
          value: {
            text: `${number(chartRow?.columns[1]?.value)}`,
            style: { ...valueStyle, color: '#8064AA' }
          }
        },
        {
          label: 'Raw Difference',
          value: {
            text: `${number(chartRow?.columns[2]?.value)}`,
            style: valueStyle
          }
        },
        {
          label: 'Relative Difference',
          value: {
            text: `${decimalAsPercent(relativeDiff(chartRow?.columns[0]?.value, chartRow?.columns[1]?.value)/100)}`,
            style: valueStyle
          }
        },
        {
          break: true
        },
      ]
    }
    if (hasNps) {
      theme.data.push(
        {
          label: 'Observed NPS',
          value: {
            text: `${formatNPS(dataItem['NPS Category'].nps__)}`,
            style: valueStyle
          }
        },
        {
          label: 'Expected NPS',
          value: {
            text: `${formatNPS(dataItem['NPS Category'].nps___expected__)}`,
            style: valueStyle
          }
        },
        {
          label: `Observed ${prefix} NPS:`,
          value: {
            text: `${formatNPS(dataItem['NPS Category'].npsi_rto__)}`,
            style: {...valueStyle, color: '#11acdf'}
          }
        },
        {
          label: `Expected ${prefix} NPS:`,
          value: {
            text: `${formatNPS(dataItem['NPS Category'].npsi_rto___expected__)}`,
            style: {...valueStyle, color: '#11acdf'}
          }
        },
        {
          break: true
        },
      )
    }
    if (hasSentiment) {

      theme.data.push(
        {
          label: 'Observed Positive Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['positive%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#21ba45'}
          }
        },
        {
          label: 'Expected Positive Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['positive%___expected__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#21ba45'}
          }
        },
        {
          label: 'Observed Negative Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['negative%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#ee3824'}
          }
        },
        {
          label: 'Expected Negative Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['negative%___expected__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#ee3824'}
          }
        },
        {
          label: 'Observed Mixed Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['mixed%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#f89516'}
          }
        },
        {
          label: 'Expected Mixed Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['mixed%___expected__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#f89516'}
          }
        },
        {
          label: 'Observed Neutral Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['neutral%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#7f7f7f'}
          }
        },
        {
          label: 'Expected Neutral Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['neutral%___expected__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#7f7f7f'}
          }
        },
      )
    }
  } else {
    theme.data = [
      {
        label: 'Frequency (#/%):',
        value: {
          text: `${number(dataItem.frequency)} / ${number(dataItem.frequency_percent__ / 100, '0,0.[00]%')}`,
          style: valueStyle
        }
      },
      {
        break: true
      },
    ]
    if (hasNps) {
      theme.data.push(
        {
          label: 'NPS',
          value: {
            text: `${formatNPS(dataItem['NPS Category'].nps__)}`,
            style: valueStyle
          }
        },
        {
          label: `${prefix} NPS:`,
          value: {
            text: `${formatNPS(dataItem['NPS Category'].npsi_rto__)}`,
            style: {...valueStyle, color: '#11acdf'}
          }
        },
        {
          break: true
        },
      )
    }
    if (hasSentiment) {

      theme.data.push(
        {
          label: 'Positive Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['positive%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#21ba45'}
          }
        },
        {
          label: 'Negative Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['negative%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#ee3824'}
          }
        },
        {
          label: 'Mixed Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['mixed%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#f89516'}
          }
        },
        {
          label: 'Neutral Sentiment',
          value: {
            text: `${number(dataItem.sentiment__['neutral%__'] / 100, '0,0.[00]%')}`,
            style: {...valueStyle, color: '#7f7f7f'}
          }
        }
      )
    }
  }
  return theme
}

export const toolTipDataCompare = (
  name: string,
  dataItem: CompareItem,
  hasNps: boolean,
  sliceOneName: string,
  sliceTwoName: string,
) => {
  let theme = {
    title: selectedDisplayToLabel(name),
    action: `Click for explore options`,
    data: [] as DataToolTipInterface[]
  }

  const valueStyle = {
    'font-weight': 'bold',
    'color': ''
  }

  theme.data = [
    {
      label: `${sliceOneName} frequency (#/%):`,
      value: {
        text: `${number(dataItem.sliceOne.frequency)} / ${number(dataItem.sliceOne.frequency_percent__ / 100, '0,0.[00]%')}`,
        style: { ...valueStyle, color: '#11ACDF' }
      }
    },
    {
      label: `${sliceTwoName} frequency (#/%):`,
      value: {
        text: `${number(dataItem.sliceTwo.frequency)} / ${number(dataItem.sliceTwo.frequency_percent__ / 100, '0,0.[00]%')}`,
        style: { ...valueStyle, color: '#8064AA' }
      }
    },
    {
      break: true
    },
  ]

  if (hasNps) {
    theme.data.push(
      {
        label: `${sliceOneName} NPS (NPS/Impact):`,
        value: {
          text: `${formatNPS(dataItem.sliceOne['NPS Category'].nps__)} / ${formatNPS(dataItem.sliceOne['NPS Category'].npsi_rto__)}`,
          style: { ...valueStyle, color: '#11ACDF' }
        }
      },
      {
        label: `${sliceTwoName} NPS (NPS/Impact):`,
        value: {
          text: `${formatNPS(dataItem.sliceTwo['NPS Category'].nps__)} / ${formatNPS(dataItem.sliceTwo['NPS Category'].npsi_rto__)}`,
          style: { ...valueStyle, color: '#8064AA' }
        }
      },
      {
        break: true
      },
    )
  }

  return theme
}
