<template>
  <div class="page">
    <div class="header">
      <img src="../assets/logo.svg" alt="Kapiche logo">
      <a href="/">Go to Kapiche</a>
    </div>
    <div class="content">
      <h1>Successfully unsubscribed from digest</h1>
      <template v-if="resubLink">
        <p>If unsubscribing was a mistake, you can resubscribe here:</p>
        <bf-button
          size="big"
          color="white"
          @click="resubscribe"
        >
          Resubscribe
        </bf-button>
      </template>
      <p>
        Got a question or comment?
        <br />
        <a href="javascript:window.Intercom('show');">Contact us</a> anytime.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { BfButton } from 'components/Butterfly'

  const UnsubscribedPage = defineComponent({
    components: { BfButton },
    computed: {
      resubLink (): string {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        return params.resub_link
      }
    },
    mounted () {
      this.$analytics.track.dashboard.digest.userUnsubscribed()
    },
    methods: {
      resubscribe () {
        this.$analytics.track.dashboard.digest.userResubscribed()
        if (this.resubLink) {
          window.location.href = this.resubLink
        }
      }
    }
  })

  export default UnsubscribedPage
</script>

<style lang="sass" scoped>
  @import "assets/kapiche.sass"

  .page
    padding: 30px

  .header
    img
      width: 153px
    a
      float: right
      font-size: 18px
      line-height: 28px

  .content
    margin-top: 95px
    text-align: center

    h1
      font-size: 40px
      line-height: 44px
      margin-bottom: 30px

    p
      font-size: 20px
      line-height: 28px
      margin-bottom: 20px
      font-weight: 400

      &:last-child
        font-size: 18px
        margin-top: 40px

</style>
