<template>
  <div class="add-to-dashboards">
    <div class="dashboards-header">
      <label>Add to Dashboards:</label>
      <span class="select" :class="{ disabled: !canAddToDashboard }">
        <a @click.prevent="() => selectAllDashboards(true)">Select all</a>
        <a @click.prevent="() => selectAllDashboards(false)">Deselect all</a>
      </span>
    </div>
    <el-popover
      trigger="hover"
      effect="dark"
      :disabled="canAddToDashboard"
      :popper-options="[{
        placement: 'bottom',
        modifiers: {
          offset: { offset: '0,6px' },
          preventOverflow: { boundariesElement: 'window' },
        }
      }]"
    >
      <template #default>
        Queries with no Concepts/Terms/Phrases cannot be added to a
        <br />
        Dashboard (Dashboards use their own structured data filters).
      </template>
      <template #reference>
        <ul class="dashboards-list">
          <li v-for="dashboard in dashboardList" :key="dashboard.id">
            <toggle-checkbox
              :disabled="!canAddToDashboard"
              :value="selectedDashboards.includes(dashboard.id)"
              :label="dashboard.name"
              @input="() => toggleDashboard(dashboard.id)"
            >
              {{ dashboard.name }}
            </toggle-checkbox>
          </li>
        </ul>
      </template>
    </el-popover>

    <p v-if="!canAddToDashboard" class="no-structured-info">
      Themes with no Concepts/Terms/Phrases cannot be
      added to a Dashboard (Dashboards use their own structured
      data filters).
      <br />
      <a target="_blank" :href="CONST.intercom_links.DASHBOARDS">Learn more about Dashboards <img class="ui image logo" src="../../../../../assets/img/new-tab.png"></a>
    </p>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType, ref, watch } from 'vue'
  import { isEqual } from 'lodash'
  import ToggleCheckbox from 'components/widgets/ToggleCheckbox.vue'

  interface Dashboard {
    id: number
    name: string
  }

  const DashboardSelection = defineComponent({
    components: {
      ToggleCheckbox,
    },
    props: {
      dashboardList: { type: Array as PropType<Dashboard[]>, required: true },
      canAddToDashboard: { type: Boolean, required: true },
      dashboardIds: { type: Array as PropType<number[]>, required: true },
    },
    setup (props, { emit }) {
      const selectedDashboards = ref<number[]>(props.dashboardIds ?? [])

      watch(() => props.dashboardIds, (newVal, oldVal) => {
        if (isEqual(newVal, oldVal)) return
        selectedDashboards.value = newVal
      })

      watch(selectedDashboards, (newVal, oldVal) => {
        if (isEqual(newVal, oldVal)) return
        emit('update', selectedDashboards.value)
      })

      const selectAllDashboards = (selected: boolean) => {
        const value = selected ? props.dashboardList.map(dashboard => dashboard.id) : []
        selectedDashboards.value = value
      }

      const toggleDashboard = (id: number) => {
        const index = selectedDashboards.value.indexOf(id)
        if (index === -1) {
          selectedDashboards.value = [...selectedDashboards.value, id]
        } else {
          selectedDashboards.value = selectedDashboards.value.filter(dashboardId => dashboardId !== id)
        }
      }

      return {
        selectAllDashboards,
        selectedDashboards,
        toggleDashboard,
      }
    },
  })

  export default DashboardSelection
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .add-to-dashboards
    margin-top: 20px
    .select a
      margin-left: 10px
      cursor: pointer

    a
      white-space: nowrap
      img
        display: inline-block
    .disabled
      a
        opacity: 0.5
        pointer-events: none
    ul
      list-style: none
      padding: 0

  .dashboards-header
    label
      font-weight: bold

  .dashboards-list
    li:not(:last-child)
      margin-bottom: 10px
</style>
