<template>
  <div id="account-settings" class="ui grid">
    <div class="four wide column">
      <div class="ui fluid vertical menu">
        <div class="item header">
          Account Settings
        </div>
        <router-link :to="{ name: 'profile' }" class="item">
          <i class="kapiche-icon-people-single"></i> <span>Profile</span>
        </router-link>
        <template v-if="isAdmin">
          <div class="item header">
            Site Administration
          </div>
          <router-link :to="{ name: 'users' }" class="item">
            <i class="kapiche-icon-people-together"></i> <span>Users</span>
          </router-link>
          <router-link :to="{ name: 'integrations' }" class="item">
            <i class="kapiche-icon-puzzle"></i> <span>Integrations</span>
          </router-link>
          <router-link :to="{ name: 'subscription' }" class="item">
            <i class="kapiche-icon-card"></i> <span>Subscription</span>
          </router-link>
          <router-link :to="{ name: 'notifications' }" class="item">
            <i class="kapiche-icon-card"></i> <span>Notifications</span>
          </router-link>
          <router-link v-if="subscription.exportAPIEnabled" :to="{ name: 'exportapi' }" class="item">
            <i class="kapiche-icon-card"></i> <span>Export API</span>
          </router-link>
        </template>
      </div>
    </div>
    <div class="twelve wide column">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  export default defineComponent({
    metaInfo () {
      return {
        title: this.currentSite ? `${this.currentSite.site_name} Settings - Kapiche` : null
      }
    },
    computed: {
      ...mapGetters(['isAdmin', 'featureFlags', 'currentSite', 'currentUser', 'subscription']),
      showPasswordReset () {
        return !this.currentUser?.is_social
      }
    },
  })
</script>

<style lang="sass" scoped>
  .ui.grid .column
    height: 100%
    display: flex
    flex-direction: column

  #account-settings
    margin: 20px 20px 0 20px
    .item
      font-size: 16px
      line-height: 20px
      i
        color: #95a6ac
      span
        display: inline-block
        padding-left: 15px
    .header
      padding-bottom: 20px
      padding-top: 20px
      &:nth-child(2)
        padding-top: 40px
    a.item.active, a.item:hover
      background-color: #95a6ac
      color: white
      i
        color: white
    a.item.active
      font-weight: bold
</style>
