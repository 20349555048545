<template>
  <div v-if="currentProject" class="ui grid">
    <div class="sixteen wide column">
      <h1 class="ui header center aligned">
        {{ projectName }}
      </h1>
      <div class="project-description">
        {{ currentProject.description }}
      </div>
    </div>

    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
      <div v-if="projectMessage" class="centered row">
        <div class="column ten wide">
          <div class="ui message icon info">
            <i class="info circle icon"></i>
            <div class="content">
              <p>{{ projectMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="eight wide column data-files-list">
      <data-files v-if="!hasIntegration" />
      <integration-data-files v-if="hasIntegration && !hasBucketIntegration"></integration-data-files>
      <bucket-integration-data-files v-if="hasBucketIntegration"></bucket-integration-data-files>
    </div>
    <div class="eight wide column analyses-list">
      <analyses></analyses>
    </div>
    <div class="sixteen wide column project-users-list">
      <members
        :project-id="currentProject.id"
        :project-members="projectMembers"
        :project-created-by-id="currentProject.created_by.id"
        :active-user="currentUser"
        :is-loading="isLoadingProject"
        @users-updated="fetchProject"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import { mapGetters } from 'vuex'

  import Analyses from './AnalysisList.vue'
  import DataFiles from './DataFiles.vue'
  import Members from './Members.vue'
  import IntegrationDataFiles from './IntegrationDataFiles.vue'
  import BucketIntegrationDataFiles from './BucketIntegrationDataFiles.vue'
  import { CLEAR_MESSAGE } from 'src/store/types'

  export default defineComponent({
    components: {
      Analyses,
      DataFiles,
      IntegrationDataFiles,
      BucketIntegrationDataFiles,
      Members
    },
    props: {
      isLoadingProject: { type: Boolean, required: false, default: true }
    },
    computed: {
      ...mapGetters([
        'isSubscribed', 'isAdmin', 'currentUser', 'currentRoute', 'currentProject', 'projectMessage'
      ]),
      projectMembers () {
        return this.currentProject?.users?.map(({user})=>user) || []
      },
      projectId () {
        return this.$route.params.projectId
      },
      hasIntegration () {
        return !!this.currentProject.integration
      },
      hasBucketIntegration () {
        return this.hasIntegration && (this.currentProject.integration.type === 's3' || this.currentProject.integration.type === 'gcs')
      },
      projectName () {
        let parts: string[] = this.currentProject.name.split(' ')
        // If there is a continuous non-spaced string n = 50, truncate it.
        // Covers ridiculous edge case.
        return parts.map(x => x.slice(0, 50)).join(' ')
      }
    },
    metaInfo () {
      return {
        title: this.currentProject ? `${this.currentProject.name} - Kapiche` : null
      }
    },
    mounted () {

      // this might want to be implemented in the store itself, rather than here
      this.$store.watch(() => {
        if (this.projectMessage) {
          // Auto-hide
          setTimeout(() => {
            this.$store.commit(CLEAR_MESSAGE)
          }, 5000)
        }
      }, ()=>null)
    },
    methods: {
      fetchProject () {
        this.$emit('fetch-project')
      }
    }
  })
</script>

<style lang="sass" scoped>

  div.project-description
    width: 50%
    margin-left: auto
    margin-right: auto
    text-align: center

  // This handles the weird extra whitespace at the bottom of the page bug.
  // Something about margins here doesn't play well

  div.ui.grid
    padding: 1rem 0.5rem 0 0.5rem
    margin: 0 !important

  table.ui thead th
    background-color: #068CCC
    color: #ffffff

  div.segment.trial
    h5
      font-weight: bold
      margin-top: 0
      margin-bottom: 0
    a.button.blue
      border: 1px solid #068ccc
      background-color: transparent
      color: #068ccc
    a.button.blue:hover
      border: 1px solid #068ccc
      background-color: #068ccc
      color: #ffffff
</style>
