<template>
  <table
    ref="table"
    class="pivot-table-component"
  >
    <thead ref="thead">
      <tr>
        <th class="number-header">
          1
        </th>
        <th
          :colspan="rowMap.length"
        >
          {{ valueLabel }}
        </th>
        <th
          class="vector-label"
          :colspan="colSpans.reduce((acc, n) => acc + n[0], 0)"
        >
          {{ colLabel }}
        </th>
      </tr>
      <tr
        v-for="(colRow, i) in colMap"
        :key="str(colRow.label)"
      >
        <th class="number-header">
          {{ i + 2 }}
        </th>
        <th
          v-if="i === 0"
          class="vector-label"
          :rowspan="colMap.length"
          :colspan="rowMap.length"
        >
          {{ rowLabel }}
        </th>
        <th
          v-for="(col, j) in getAllCols"
          :key="str(col)"
          class="header"
          :class="{
            'summary': col[colRow.label] === undefined,
          }"
          :hidden="colSpans[j][i] === 0"
          :colspan="colSpans[j][i]"
          :data-coords="coordStr(col)"
          @click="sortClick('col', col)"
        >
          <slot
            v-if="$slots.header"
            name="header"
            :label="getColumnHeader(col, col[colRow.label], i)"
            :sorted="colSort && isSorted(col, colSort) ? colSort.direction : null"
            :visible="visibleCols[j]"
          />
          <span v-else>
            {{ getColumnHeader(col, col[colRow.label], i) }}
          </span>
        </th>
      </tr>
    </thead>
    <tbody ref="tbody">
      <tr
        v-for="(row, i) in getCleanedRows"
        :key="`${str(row)}-${i}}`"
      >
        <th class="number-header">
          {{ i + colMap.length + 2 }}
        </th>
        <th
          v-for="(rowName, j) in Object.values(row)"
          :key="`${str(row)}-${rowName}-${j}}`"
          class="header"
          :class="{
            'summary': rowName === undefined,
          }"
          :hidden="rowSpans[i][j] === 0"
          :rowspan="rowSpans[i][j]"
          :colspan="('GrandTotal' in row && j === Object.values(row).length - 1) ? rows.length : 1"
          :data-coords="coordStr(row)"
          @click="sortClick('row', row)"
        >
          <slot
            v-if="$slots.header"
            name="header"
            :label="getRowHeader(row, rowName, j)"
            :sorted="rowSort && isSorted(row, rowSort) ? rowSort.direction : null"
            :visible="visibleRows[i]"
          />
          <span v-else>
            {{ getRowHeader(row, rowName, j) }}
          </span>
        </th>
        <template v-if="initiated">
          <td
            v-for="col in renderVisible ? getVisibleCols(i) : getAllCols"
            :key="str(col)"
            :class="{
              'hidden-total': ('GrandTotal' in col && 'GrandTotal' in row),
            }"
            :colspan="col.skipped || 1"
            :data-coords="coordStr(col) + '|' + coordStr(row)"
          >
            <template
              v-if="!col.skipped"
            >
              <slot
                v-if="$slots.value"
                name="value"
                :cell="findCellValue(col, row)"
              />
              <span v-else>
                {{ findCellValue(col, row).value }}
              </span>
            </template>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script lang='ts'>
import { PropType, defineComponent } from 'vue'
import PptxGenJS from 'pptxgenjs'
import hash from 'object-hash'
import dayjs from 'dayjs'
import _, { isEqual } from 'lodash'

type Label = string | number | undefined
type Vector = Record<string, Label>
type Reducer = (data: Data) => { value: string | number }
type Data = Record<string, number> & {
  group__: string
}[]

export interface VectorGetter {
  getter: (datum: Data[number]) => Array<string | number>
  label: string
}

interface VectorMap {
  values: (string | number)[]
  children: VectorMap | null
  label: string
}

interface SortOptions {
  direction: 'asc' | 'desc'
  item: Vector
}

const allCombos = (arr: (number | string)[][]): (number | string)[][] => {
  const [ first, ...rest ] = arr
  if (!first) return []
  if (!rest.length) return first.map((n) => [n])
  return first.reduce((acc, n) => {
    const combos = allCombos(rest)
    return acc.concat(combos.map((combo) => [n, ...combo]))
  }, [] as (number | string)[][])
}

const chunkNumber = (num: number, limit: number) =>
  new Array(Math.floor(num / limit))
    .fill(limit)
    .concat(num % limit || [])

const checkInView = (
  container: HTMLElement,
  element: HTMLElement,
  vert = true
) => {
  // Container
  const cTop = container.scrollTop
  const cLeft = container.scrollLeft
  const cBottom = cTop + container.clientHeight
  const cRight = cLeft + container.clientWidth

  // Element
  const eTop = element.offsetTop
  const eLeft = element.offsetLeft
  const eBottom = eTop + element.clientHeight
  const eRight = eLeft + element.clientWidth

  return vert
    ? eTop < cBottom && eBottom > cTop
    : eLeft < cRight && eRight > cLeft
}

const Table = defineComponent({
  components: {},
  props: {
    rows: { type: Array as PropType<VectorGetter[]>, required: true },
    cols: { type: Array as PropType<VectorGetter[]>, required: true },
    reducer: { type: Function as PropType<Reducer>, required: true },
    data: { type: Array as PropType<Data>, required: true },
    renderVisible: { type: Boolean, default: false },
    summaryLabel: { type: String, default: 'Total' },
    wrapperClass: { type: String, default: '' },
    valueLabel: { type: String, default: '' },
    valueMethod: { type: String, default: '' },
    grandTotalLabel: { type: String, default: 'Grand Total' },
    dateFields: { type: Array as PropType<string[]>, default: () => [] },
    dateFormat: { type: String, required: false, default: 'YYYY-MM-DD'},
    rowLabel: { type: String, required: true },
    colLabel: { type: String, required: true },
    hideTotals: {type: Boolean, required: true},
    binColors: { type: Array, required: false },
  },
  data () {
    return {
      colSort: null as SortOptions | null,
      rowSort: null as SortOptions | null,
      working: false,
      initiated: false,
      visibleRows: [] as number[],
      visibleCols: [] as number[],
      headersStickied: false,
      valueHash: {} as Record<string, Data>,
      vectorArrays: {} as Record<string, Data>,
    }
  },
  computed: {
    colMap (): VectorMap[] {
      const map = this.createVectorMap(this.cols)

      // If no columns exist, add a blank one so the table
      // can render the row headers and Grand Total column
      if (!map.length) {
        map.push({
          values: [],
          children: null,
          label: undefined,
        })
      }

      return map
    },
    rowMap (): VectorMap[] {
      return this.createVectorMap(this.rows)
    },
    rowSizes (): Record<string, number> {
      return this.getFieldSizes(this.rowMap)
    },
    colSizes (): Record<string, number> {
      return this.getFieldSizes(this.colMap)
    },
    getAllRows (): Vector[] {
      let list = this.listFromVectorMap(this.rowMap)
      if (this.colSort) {
        list = this.sortVectors(list, this.rowMap, this.colSort)
      }
      if (this.cols.length && !this.hideTotals) {
        const rows = this.rows.map((r: VectorGetter) => r.label).join('|')
        list.push({ 'GrandTotal': rows })
      }
      return list
    },
    getCleanedRows (): Vector [] {
      return this.getAllRows.map((row) => {
        let filteredRow = Object.entries(row).filter(([k]) => k !== 'divider')
        return Object.fromEntries(filteredRow)
      })
    },
    getAllCols (): Vector[] {
      let list = this.listFromVectorMap(this.colMap)
      if (this.rowSort) {
        list = this.sortVectors(list, this.colMap, this.rowSort)
      }
      if (this.rows.length && !this.hideTotals) {
        const cols = this.cols.map((c: VectorGetter) => c.label).join('|')
        list.push({ 'GrandTotal': cols })
      }
      return list
    },
    getCleanedCols (): Vector [] {
      return this.getAllCols.map((col) => {
        let filteredCol = Object.entries(col).filter(([k]) => k !== 'divider')
        return Object.fromEntries(filteredCol)
      })
    },
    rowSpans (): number[][] {
      return this.getAllRows.map((row: Vector) =>
        Object.values(row).map((_, i) =>
          this.getVectorSpan(row, 'row', i)
        )
      )
    },
    colSpans (): number[][] {
      return this.getAllCols.map((row: Vector) =>
        Object.values(row).map((_, i) =>
          this.getVectorSpan(row, 'col', i)
        )
      )
    },
    getters (): Record<string, VectorGetter['getter']> {
      return (this.cols.concat(this.rows) as VectorGetter[])
        .reduce((acc, vector) => {
          acc[vector.label] = vector.getter
          return acc
        }, {} as Record<string, VectorGetter['getter']>)
    },
    values (): Record<string, VectorMap['values']> {
      return (this.colMap.concat(this.rowMap) as VectorMap[])
        .reduce((acc, vector) => {
          acc[vector.label] = vector.values
          return acc
        }, {} as Record<string, VectorMap['values']>)
    },
  },
  watch: {
    data: {
      handler (newVal, oldVal) {
        if (!_.isEqual(newVal, oldVal)) {
          this.headersStickied = false
          this.hashCellValues()
          if (this.renderVisible) {
            this.$nextTick(this.calculateVisible)
          }
        }
      },
      deep: true,
      immediate: true,
    },
    renderVisible (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.calculateVisible()
      }
    },
    hideTotal (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateVisible()
      }
    }
  },
  mounted () {
    this.renderVisible &&
      this.calculateVisible()
    this.initiated = true
  },
  updated () {
    if (!this.headersStickied) {
      this.stickyHeaders()
    }
  },
  methods: {
    coordStr (vector: Vector) {
      return Object.entries(vector)
        .map(([ key, value ]) =>
          value === undefined
            ? ''
            : `${key}=${value}`
        )
        .join('|')
    },
    str: JSON.stringify,
    // Convert vector list to a nested object containing all possible values
    createVectorMap (vectors: VectorGetter[]): VectorMap[] {
      const mapChildren = (cols: VectorGetter[]): VectorMap => {
        const [ col, ...rest ] = cols
        const values: (string | number)[] =
          this.data
            .flatMap(col.getter)
            .filter((v: any) => v !== undefined)
        return {
          children: rest.length ? mapChildren(rest) : null,
          values: Array.from(new Set(values)),
          label: col.label,
        }
      }

      return vectors.map((_, i) =>
        mapChildren(vectors.slice(i))
      )
    },
    // Convert VectorMap into a flat list of vectors to be
    // displayed as rows or columns in the table
    listFromVectorMap (vectorMap: VectorMap[]): Vector[] {
      const root = vectorMap[0]
      if (!root) return []

      const processLeaf = (map: VectorMap): Vector[] => {
        return map.values.map((v) => {
          if (map.children) {
            return processLeaf(map.children)
              .flatMap((c) => [{ [map.label]: v, ...c }])
          } else {
            return { [map.label]: v }
          }
        }).flat()
      }

      const vectorList = processLeaf(root)

      const fieldOrder = vectorMap.map((c) => c.label)
      // If summaries are visible, add summary vectors to vectorList
      if (!this.hideTotals) {
        const summaryVectors: [number, Vector][] = []
        for (const col of fieldOrder.slice(0, fieldOrder.length - 1)) {
          let val = null
          for (let i = 0; i < vectorList.length; i++) {
            const item = vectorList[i]
            // If we have entered a new section (value) for this field,
            // insert a "summary" vector that will be displayed as a header
            if (item[col] !== val) {
              val = item[col]
              // Create new vector with undefined values for fields below
              // the current field (undefined denotes a summary header)
              const obj = Object.fromEntries(
                fieldOrder.map((c, i) =>
                  [c, i < fieldOrder.indexOf(col) ? item[c] : undefined]
                )
              )
              obj[col] = val
              // New vector will be inserted at the current index
              summaryVectors.push([i, obj])
            }
          }
        }
        // Insert summary rows/cols in reverse order
        summaryVectors.sort(([ a ], [ b ]) => a - b)
        summaryVectors.reverse()
        summaryVectors.forEach(([ i, obj ]) => {
          vectorList.splice(i, 0, obj)
        })
      // Else add key values to vectors that indicate proper row/col spans for
      // multiIndex rows/cols.
      } else {
        for (const col of fieldOrder.slice(0, fieldOrder.length - 1)) {
          let val = null
          for (let i = 0; i < vectorList.length; i++) {
            const item = vectorList[i]
            // If we have entered a new section (value) for this field,
            // insert a "divider" key to the current item.
            // This will indicate that the new value for parent field starts here.
            if (item[col] !== val) {
              val = item[col]
              if (item.hasOwnProperty('divider')) {
                item['divider'].push(col)
              } else {
                item['divider'] = [col]
              }
              // item['divider'] = col
              vectorList[i] = item
            }
          }
        }
      }
      return vectorList
    },
    // Find data that matches both vectors, in the case of
    // a summary row or column this may be multiple data points.
    // Note: Always provide row fields as 'b', for percent of row
    // to work as intended.
    findCellValue (cols: Vector, rows: Vector, formatValue = true): {
      value: string | number
      isSummary: boolean
      data: Data
    } {
      const data = []
      let isSummary = Object.values(cols).some((v) => v === undefined) ||
                        Object.values(rows).some((v) => v === undefined)

      const fields = { ...cols, ...rows }
      // Get the row sum for the cell, by submitting just the row values
      // with the GrandTotal field again.
      let row_sum = 1
      if (this.valueMethod === 'PERCENT OF ROW' && !isSummary) {
        // Put foramatValue as false, since we need to get the int
        // sum for computation and not as a string
        const sum = this.findCellValue(rows, { 'GrandTotal': undefined }, false)
        row_sum = sum.value
      }

      const keys = Object.keys(fields)
        .map((k) => `${k}:${fields[k]}`)

      // This cell is in a Grand Total vector
      if ('GrandTotal' in fields) {
        isSummary = true

        let [ firstField, ...otherFields ] = Object.entries(fields)
          .filter(([ k, v ]) => k !== 'GrandTotal' && v !== undefined)

        if (!firstField) {
          return {
            value: '',
            isSummary,
            data: [],
          }
        }

        // Since the data entries donot match the standard keys for Sentiment
        // and themes, we need to modify otherFields.
        // For sentiment, remove the entry since each data object contains all
        // sentiment types data.
        // For themes, map the fields to be inline with how backend response
        // defines it.
        otherFields = otherFields.filter(([k]) => k!== 'Sentiment')
          .map( ([k, v]) => k === 'Themes'? ['group__', `theme_${v}`]: [k, v])
        const d = this.vectorArrays[firstField.join(':')]
          .filter((v: Data) =>
            _.isMatch(v, Object.fromEntries(otherFields))
          )

        data.push(...d)
      } else if (isSummary) {
        const undefKeys = keys.filter((k) => k.endsWith(':undefined'))
        const defKeys = keys.filter((k) => !k.endsWith(':undefined'))

        const undefValues = undefKeys.map((k) =>
          this.values[k.split(':')[0]]
        )

        const combos = allCombos(undefValues)
        for (const combo of combos) {
          const k = defKeys.concat(combo.map((v, i) =>
            `${undefKeys[i].split(':')[0]}:${v}`)
          )
          const h = hash(k, { unorderedArrays: true })
          const d = this.valueHash[h]
          d && data.push(...d)
        }
      } else {
        const h = hash(keys, { unorderedArrays: true })
        const d = this.valueHash[h]
        d && data.push(...d)
      }
      // Get the relative percentages if valueMethod specifies it.
      let reducer_val = this.reducer(data, keys, formatValue) ?? ''
      if (this.valueMethod === 'PERCENT OF ROW' && !isSummary) {
        if (row_sum === 0 || row_sum === undefined || isNaN(row_sum)) {
          row_sum = 1
        }
        reducer_val = ((reducer_val/row_sum)*100).toFixed(2)
      }
      return {
        value: reducer_val,
        isSummary,
        data,
      }
    },
    sortClick (type: 'col' | 'row', vector: Vector) {
      const sort: SortOptions = {
        direction: 'desc',
        item: vector,
      }

      // Rotate sort direction
      if (isEqual(vector, this[`${type}Sort`]?.item)) {
        if (this[`${type}Sort`].direction === 'asc') {
          this[`${type}Sort`] = null
          return
        }
        sort.direction = 'asc'
      }

      this[`${type}Sort`] = sort

      this.$emit('sort', type, sort)

      this.$nextTick(() => {
        this.stickyHeaders()
      })
    },
    // Sort rows/cols by cell value if table has been sorted
    sortVectors (vectors: Vector[], rowMap: VectorMap[], sort: SortOptions): Vector[] {
      if (rowMap.length === 0 || !sort) return vectors
      // Generate an nested array of fields ordered by the value of the cell in the row/col
      // being sorted by. e.g: For fields "1, 2, 3" nested within fields "a, b, c":
      // [
      //   ['b', [['1', null], ['2', null], ['3', null]]],
      //   ['a', [['1', null], ['3', null], ['2', null]]],
      //   ['c', [['3', null], ['2', null], ['1', null]]],
      // ]
      type OrderMap = [Vector[string], OrderMap | null][]
      const mapFieldOrder = (row: VectorMap, prev = {}): OrderMap => {
        const order = row.values.slice().sort((a, b) => {
          const val1 = this.findCellValue(sort.item, { [row.label]: a, ...prev }).value
          const val2 = this.findCellValue(sort.item, { [row.label]: b, ...prev }).value
          return sort.direction === 'asc' ? val1 - val2 : val2 - val1
        })
        return order.map((o) =>
          [o, row.children ? mapFieldOrder(row.children, { ...prev, [row.label]: o }) : null ]
        )
      }

      const orderMap = mapFieldOrder(rowMap[0])

      // Order of the fields as they appear in the table
      const fieldOrder = rowMap.map((r) => r.label)

      // Get the order of a field at a given level of the orderMap
      const getMapLevel = (level: number, vector: Vector) => {
        let mapLevel = orderMap
        for (let i = 0; i < level; i++) {
          mapLevel = mapLevel.find((l) =>
            l[0] === vector[fieldOrder[i]]
          )?.[1] ?? mapLevel
        }
        return mapLevel.map((l) => l[0])
      }

      // Sort the vectors by the order of the fields in the orderMap
      const sorted = vectors.slice().sort((a, b) => {
        const orderA = fieldOrder.map((r, i) => getMapLevel(i, a).indexOf(a[r]))
        const orderB = fieldOrder.map((r, i) => getMapLevel(i, b).indexOf(b[r]))

        // If fields match, sort by the next level of the orderMap
        for (let i = 0; i < orderA.length; i++) {
          if (orderA[i] !== orderB[i]) {
            return orderA[i] - orderB[i]
          }
        }

        return 0
      })

      return sorted
    },
    // Is the table being sorted by this vector?
    isSorted (vector: Vector, sort: SortOptions | null): boolean {
      return !!sort && this.str(sort.item) === this.str(vector)
    },
    // Calculate col/rowspans for headers based on child fields
    getFieldSizes (arr: VectorMap[]): Record<string, number> {
      // Recursively count children
      const countChildren = (row: VectorMap): number => {
        if (row.children) {
          // Add 1 only if we are showing the total counts.
          let extra = this.hideTotals? 0: 1
          return countChildren(row.children) * (row.children.values.length) + extra
        }
        return 1
      }
      return arr.reduce((obj, row) => ({
        ...obj,
        [row.label]: row.children
          ? countChildren(row)
          : 1,
      }), {})
    },
    // Calculate col/rowspan for a specific header cell
    getVectorSpan (vector: Vector, type: 'row' | 'col', index: number) {
      const vectorNames = type === 'row'
        ? this.rows.map((r: VectorGetter) => r.label)
        : this.cols.map((c: VectorGetter) => c.label)
      let span = 1
      if (!this.hideTotals) {
        let lastNotNull = null
        for (let i = 0; i < vectorNames.length; i++) {
          if (vector[vectorNames[i]] === undefined && i > 0) {
            lastNotNull = vectorNames[i - 1]
            break
          }
        }

        const currentVectorName = vectorNames[index]
        const sizes = type === 'row' ? this.rowSizes : this.colSizes
        if (vector[currentVectorName] !== undefined && sizes[currentVectorName] > 1) {
          // Hide header if it is not the last non-null header in order
          // i.e Parent headers should not be shown more than once
          span = lastNotNull === currentVectorName
            ? sizes[currentVectorName]
            : 0
        }
      } else {
        const currentVectorName = vectorNames[index]
        const sizes = type === 'row' ? this.rowSizes : this.colSizes
        if (sizes[currentVectorName] > 1) {
          // If vector specifies the divider list and currentVectorName is part of it,
          // assign the span value from `sizes`, else span = 0
          if (Object.keys(vector).includes('divider') && vector['divider'].includes(currentVectorName)) {
            span = sizes[currentVectorName]
          } else {
            span = 0
          }
        }
      }
      return span
    },
    // Calculate rows and columns that are currently visible
    calculateVisible () {
      this.visibleRows = []
      this.visibleCols = []
      const wrapper = this.$refs.table.closest(this.wrapperClass)
      const rowHeaders = this.$refs.tbody.querySelectorAll('tr .header:last-of-type')
      const colHeaders = this.$refs.thead.querySelectorAll('tr:last-of-type .header')

      // Keep track of whether we have passed the visible
      // section so we can break the loop early
      let hasSeenHeader = false
      for (const [i, _] of this.getAllRows.entries()) {
        const rowHeader = rowHeaders[i]
        const visible = checkInView(wrapper, rowHeader, true)
        this.visibleRows[i] = visible
        if (visible) {
          hasSeenHeader = true
        } else if (hasSeenHeader) {
          break
        }
      }

      hasSeenHeader = false
      for (const [i, _] of this.getAllCols.entries()) {
        // +1 offset to account for row number header
        const colHeader = colHeaders[i]
        const visible = checkInView(wrapper, colHeader, false)
        this.visibleCols[i] = visible
        if (visible) {
          hasSeenHeader = true
        } else if (hasSeenHeader) {
          break
        }
      }
    },
    // Get the visible columns for a given row
    getVisibleCols (row: number) {
      // Row is not visible
      if (!this.visibleRows[row]) {
        return []
      }

      const allCols = this.getAllCols
      const visibleCols = []

      // Keep track of whether we have passed the visible
      // section of columns so we can break the loop early
      let hasSeenHeader = false

      const firstVisible = this.visibleCols.indexOf(true)
      for (let col = firstVisible; col < allCols.length; col++) {
        const visible = this.visibleCols[col]
        if (visible) {
          if (!hasSeenHeader) {
            // colspan has a limit of 1000... so we have to split
            // the filler columns into chunks of 1000 colspans
            chunkNumber(col, 1000)
              .forEach((s, i) =>
                visibleCols.push({ skipped: s, i })
              )
          }
          visibleCols.push(allCols[col])
          hasSeenHeader = true
        } else if (hasSeenHeader) {
          // We've passed the visible section
          break
        }
      }
      return visibleCols
    },
    hashCellValues () {
      this.valueHash = {}
      this.vectorArrays = {}

      const rowKeys = this.rows.map((r: VectorGetter) => r.label)
      const colKeys = this.cols.map((c: VectorGetter) => c.label)
      const allKeys = Array.from(new Set([...rowKeys, ...colKeys]))

      for (const datum of this.data) {
        const sharedKeys: string[] = []
        const vals = allKeys.flatMap((k) => {
          type Segments = ReturnType<VectorGetter['getter']>
          const segments = this.getters[k](datum).flat() as Segments
          const keys = segments.map((s) => `${k}:${s}`)

          if (segments.length > 1) {
            sharedKeys.push(...keys)
          }

          return keys
        })

        for (const vector of vals) {
          this.vectorArrays[vector] = this.vectorArrays[vector] ?? []
          this.vectorArrays[vector].push(datum)
        }

        const hashVals = (vals: string[]) => {
          const h = hash(vals, { unorderedArrays: true })
          this.valueHash[h] = this.valueHash[h] || []
          this.valueHash[h].push(datum)
        }

        // In the case of shared keys (multiple cell values from a datum)
        // we need to pair up the keys individually so 2D vector lookup
        // works. e.g shared = ['a', 'b'] => ['a', 'c'], ['b', 'c']
        if (sharedKeys.length) {
          for (const val of sharedKeys) {
            // Filter shared keys with the same field label
            const newVals = vals.filter((v) =>
              !v.startsWith(val.split(':')[0])
            )
            hashVals([val, ...newVals])
          }
        } else {
          hashVals(vals)
        }
      }
    },
    stickyHeaders () {
      if (!this.wrapperClass) return
      const wrapper = this.$refs.table.closest(this.wrapperClass)
      wrapper.scrollLeft = 0

      const rows = this.$refs.tbody.querySelectorAll('tr')
      const numberHeader = this.$refs.tbody.querySelector('.number-header')
      const numberWidth = numberHeader ? numberHeader.clientWidth : 0
      rows.forEach((row: HTMLElement) => {
        const ths = row.querySelectorAll('.header') as NodeListOf<HTMLElement>
        ths.forEach((th, i) => {
          th.style.position = 'sticky'
          th.style.left = th.offsetLeft - numberWidth - 1 + i + 'px'
        })
      })

      this.headersStickied = true
    },
    toCSV (): Record<string, unknown>[] {
      const CSVRows: Record<string, unknown>[] = []
      for (const row of this.getAllRows as Vector[]) {
        for (const col of this.getAllCols as Vector[]) {
          let formattedRow = {...row}
          let formattedCol = {...col}
          if (this.valueMethod === "NPS" || this.valueMethod === "NPS Impact") {
            if (Object.keys(row).includes('GrandTotal')) {
              formattedRow[`Average ${this.valueMethod}`] = formattedRow['GrandTotal']
              delete formattedRow.GrandTotal
            }
            if (Object.keys(col).includes('GrandTotal')) {
              formattedCol[`Average ${this.valueMethod}`] = formattedCol['GrandTotal']
              delete formattedCol.GrandTotal
            }
          }
          CSVRows.push({
            ...formattedRow,
            ...formattedCol,
            [this.valueLabel]: this.findCellValue(col, row).value,
          })
        }
      }
      return CSVRows
    },
    getHeaderLabel (label: Label, formatDate = false) {
      const str = label === undefined
        ? this.summaryLabel
        : label
      return formatDate
        ? dayjs(str).format(this.dateFormat)
        : str
    },
    getColumnHeader (col: Vector, label: Label, index: number) {
      let formatDate = false

      if (
        label &&
        this.dateFormat &&
        this.dateFields.includes(this.colMap[index].label)
      ) {
        formatDate = true
      }

      return 'GrandTotal' in col
        // Display Grand Total label in last th of column
        ? index === this.colMap.length - 1 ? this.grandTotalLabel : ''
        : this.getHeaderLabel(label, formatDate)
    },
    getRowHeader (row: Vector, label: Label, index: number) {
      let formatDate = false

      if (
        label &&
        this.dateFormat &&
        this.dateFields.includes(this.rowMap[index].label)
      ) {
        formatDate = true
      }

      return 'GrandTotal' in row
        // Display Grand Total label in last th of row
        ? index === Object.values(row).length - 1 ? this.grandTotalLabel : ''
        : this.getHeaderLabel(label, formatDate)
    },
    generatePptTable (headerStyle: PptxGenJS.TableProps = {}, cellStyle: PptxGenJS.TableProps = {}) {
      const rows: PptxGenJS.TableRow[] = []

      rows.push([
        // Selected value display
        {
          text: this.valueLabel,
          options: {
            ...headerStyle,
            colspan: this.rowMap.length,
          }
        },
        // Column labels
        {
          text: this.colLabel,
          options: {
            ...headerStyle,
            colspan: this.colSpans.reduce((acc: number, n: number[]) => acc + n[0], 0),
          }
        }
      ])

      this.colMap.forEach((colRow: VectorMap, i: number) => {
        const rowArr: PptxGenJS.TableRow = []

        if (i === 0) {
          rowArr.push({
            text: this.rowLabel,
            options: {
              ...headerStyle,
              rowspan: this.colMap.length,
              colspan: this.rowMap.length,
            }
          })
        }

        this.getAllCols.forEach((col: Vector) => {
          rowArr.push({
            text: this.getColumnHeader(col, col[colRow.label], i).toString(),
            options: {
              ...headerStyle,
            }
          })
        })

        rows.push(rowArr)
      })

      this.getCleanedRows.forEach((row: Vector, i: number) => {
        const rowArr: PptxGenJS.TableRow = []

        Object.values(row).forEach((rowName, j) => {
          if (this.rowSpans[i][j]) {
            const grandTotal = 'GrandTotal' in row && j === Object.values(row).length - 1
            rowArr.push({
              text: this.getRowHeader(row, rowName, j).toString(),
              options: {
                ...headerStyle,
                colspan: grandTotal ? this.rows.length : 1,
                rowspan: this.rowSpans[i][j],
              }
            })
          }
        })

        function convertColor (rgba) {
          const parts = rgba.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
          const hex = parts.slice(0, 3)
            .map(string => parseFloat(string))
            // Converts numbers to hex
            .map(number => number.toString(16))
            // Adds 0 when length of one number is 1
            .map(string => string.length === 1 ? "0" + string : string)
            .join("")
          return {
            fill: {color: hex, transparency: 100 - (parts[3] * 100)}
          }
        }

        this.getAllCols.forEach((col: Vector) => {
          const cell = this.findCellValue(col, row)
          const binIndex = cell.data[0]?.value_binned
          const cellFill = !cell.isSummary && this.binColors && binIndex ? convertColor(this.binColors[binIndex]) : undefined
          rowArr.push({
            text: cell.value.toString(),
            options: {
              ...cellStyle,
              ...cellFill
            }
          })
        })

        rows.push(rowArr)
      })

      return rows
    }
  }
})

export default Table
</script>

<style lang="sass" scoped>
  @import '~assets/kapiche.sass'

  table
    $font-size: 16px
    font-size: $font-size
    height: 1px
    position: relative
    border-spacing: 0
    &.working
      &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba(255, 255, 255, 0.5)
        z-index: 1
        pointer-events: none
    th
      max-width: 200px
      background: #F7F9FA
      user-select: none
      > span
        display: flex
        align-items: center
        justify-content: center
        padding: 5px
    td
      background: #fff
      position: relative
      padding: 0

  table
    padding: 0
    height: 100%
    width: 100%
    thead
      position: sticky
      top: 0
      z-index: 4
    tbody tr th
        z-index: 3

  th
    text-align: left
    padding: 6px

  .vector-label
    font-style: italic
    text-align: left
    vertical-align: bottom
    font-weight: 400

  .number-header
    text-align: center
    font-weight: 400

  th, td
    border: 1px solid #E7E8E8
    border-bottom: 0
    border-right: 0
    &:first-child
      border-left: 1px solid #E7E8E8
    &:last-child
      border-right: 1px solid #E7E8E8
    &.hidden-total
      border: 0 !important
      border-left: 1px solid #E7E8E8 !important
      border-top: 1px solid #E7E8E8 !important
      background: #fff !important
  thead
    tr:first-child
      th
        border-top: 1px solid #E7E8E8
        &:nth-child(2)
          font-weight: 400
          font-style: italic
          min-width: 170px
          position: sticky
          left: 0
        &:nth-child(3)
          border-left: 0
    tr:nth-child(2)
      th:nth-child(2)
        border-top: 0
        border-bottom: 1px solid #E7E8E8
        position: sticky
        left: 0
    tr:last-of-type
      th
        border-bottom: 1px solid #E7E8E8
    .summary
      border-top: 0

  tbody
    .summary
      border-left: 0
    tr:first-child
      th, td
        border-top: 0
    tr th:nth-child(2)
      position: sticky
      left: 0
    tr th:last-of-type
      border-right: 1px solid #E7E8E8
    tr td:first-of-type
      border-left: 0
    tr td:last-of-type
      .showTotals
        background: #F7F9FA
        font-weight: 700
    tr:last-child
      td, th
        border-bottom: 1px solid #E7E8E8
      td
        .showTotals
          background: #F7F9FA
          font-weight: 700


  .header
    cursor: pointer
</style>
