<template>
  <modal
    :visible="visible"
    @close="$emit('close')"
  >
    <template #header>
      Name your Group
    </template>
    <template #content>
      <div class="errors">
        <ul>
          <li v-for="error in submitErrors" :key="error">
            {{ error }}
          </li>
        </ul>
      </div>
      <VeeForm
        ref="form"
        :initial-values="initialFormValues"
        :on-submit="saveOrRename"
      >
        <template #default="{ meta: { valid, pending }, isSubmitting }">
          <Field
            v-slot="{ field, errors }"
            rules="required|max:84"
            name="name"
            label="Group name"
            validate-on-input
          >
            <label>Group name:</label>
            <bf-text-input
              v-bind="field"
              focus
              type="text"
              placeholder="Group name..."
              maxlength="84"
              size="80"
              :errors="errors"
            />
          </Field>
          <div class="modal-actions">
            <bf-button
              color="grey"
              size="large"
              :disabled="pending || isSubmitting"
              @click="$emit('close')"
            >
              Cancel
            </bf-button>
            <bf-button
              type="submit"
              color="blue"
              size="large"
              :disabled="!valid || pending || isSubmitting"
            >
              Apply
            </bf-button>
          </div>
        </template>
      </VeeForm>
    </template>
  </modal>
</template>
<script lang="ts">
  import { computed, defineComponent, PropType, ref, watch, nextTick } from 'vue'
  import { Form as VeeForm, Field } from 'vee-validate'
  import { BfButton, BfTextInput } from 'components/Butterfly'
  import Modal from 'src/components/project/analysis/results/Modal.vue'

  interface FormValues {
    name: string
  }

  const ThemeNameModal = defineComponent({
    components: {
      VeeForm,
      Field,
      BfButton,
      BfTextInput,
      Modal,
    },
    props: {
      visible: { type: Boolean, required: true },
      values: { type: Object as PropType<FormValues>, required: true },
      submitErrors: { type: Array as PropType<string[]>, required: true },
    },
    setup (props, { emit }) {
      const form = ref<InstanceType<typeof VeeForm>>()

      // Set form to current query values when modal is opened
      watch(() => props.visible, (newVal, oldVal) => {
        if (!oldVal && newVal) {
          nextTick(() => {
            form.value && form.value.resetForm({ ...props.values })
          })
        }
      })

      const saveOrRename = (form: FormValues) => {
        const data: Record<string, unknown> = { ...form }
        emit('submit', data)
      }

      const initialFormValues = computed<FormValues>(() => {
        return {
          name: props.values.name ?? '',
        }
      })

      return {
        saveOrRename,
        initialFormValues,
        form,
      }
    },
  })

  export default ThemeNameModal
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .errors
    ul
      list-style: none
      padding: 0
      color: $red
      word-break: keep-all
      margin: 0
      margin-bottom: 20px

  .modal-actions
    display: flex
    justify-content: center
    margin-top: 30px
    > *:not(:last-child)
      margin-right: 20px

  label
    font-weight: bold
    margin-bottom: 4px
    display: block
</style>
