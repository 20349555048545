<template>
  <div>
    <p>API Access tokens can be created and deleted here.</p>

    <el-button type="primary" @click="$emit('prefix-action', 'Add')">
      Create new API token
    </el-button>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="Created" width="400">
        <template #default="scope">
          <span style="margin-left: 10px">
            {{ prettyCreated(scope.row.created) }}
          </span>
        </template>
      </el-table-column>

      <el-table-column label="Created by">
        <template #default="scope">
          <span style="margin-left: 10px">{{ scope.row.created_by }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Actions" width="200">
        <template #default="scope">
          <el-button
            size="small"
            type="danger"
            @click="$emit('prefix-action', 'Delete', scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import dayjs from 'dayjs'

  export default defineComponent({
    props: {
      tableData: { type: Array<Record<string, unknown>>, required: true },
    },
    methods: {
      prettyCreated (dateStr: string): string {
        return dayjs(dateStr).fromNow()
      }
    },
  })
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .items, .el-table
    font-size: 16px
</style>
