<template>
  <modal
    :visible="visible"
    @close="$emit('close')"
  >
    <template #header>
      Name your Theme
    </template>
    <template #content>
      <div class="content-wrap">
        <div class="errors">
          <ul>
            <li v-for="error in submitErrors" :key="error">
              {{ error }}
            </li>
          </ul>
        </div>
        <VeeForm
          v-if="visible"
          ref="form"
          :initial-values="initialFormValues"
          :on-submit="saveOrRename"
        >
          <template #default="{ meta: { valid, pending }, isSubmitting }">
            <Field
              v-slot="{ field, errors }"
              rules="required|max:84"
              name="name"
              label="Theme name"
              validate-on-input
            >
              <label>Theme name:</label>
              <bf-text-input
                v-bind="field"
                type="text"
                placeholder="Theme name..."
                maxlength="84"
                size="80"
                :focus="true"
                :errors="errors"
              />
            </Field>
            <Field
              v-slot="{ field, errors }"
              rules="max:150"
              name="description"
              validate-on-input
            >
              <label>
                Description <span class="subtext">(optional)</span>
              </label>
              <bf-text-input
                v-bind="field"
                type="text"
                placeholder="Theme description..."
                maxlength="150"
                size="40"
                :errors="errors"
              />
            </Field>
            <Field
              v-if="isNewTheme && themeGroupList.length > 0"
              v-slot="{ field }"
              rules="required"
              name="theme_group"
            >
              <label>
                Add to theme group. Themes can only belong to one group at a time.
              </label>
              <el-radio-group
                v-bind="field"
                :model-value="field.value"
                class="theme-group-radio"
                @update:model-value="field.value = $event"
              >
                <el-radio
                  v-for="group in themeGroupList"
                  :key="group.id"
                  :value="group.id"
                >
                  {{ group.name }}
                </el-radio>
              </el-radio-group>
            </Field>
            <dashboard-selection
              v-if="isNewTheme"
              :dashboard-list="dashboardList"
              :can-add-to-dashboard="canAddToDashboard"
              :dashboard-ids="selectedDashboards"
              @update="selectedDashboards = $event"
            />
            <div class="modal-actions">
              <bf-button
                color="grey"
                size="large"
                :disabled="pending || isSubmitting"
                @click="$emit('close')"
              >
                Cancel
              </bf-button>
              <bf-button
                type="submit"
                :color="isNewTheme ? 'green' : 'blue'"
                size="large"
                :disabled="!valid || pending || isSubmitting"
              >
                {{ isNewTheme ? 'Save' : 'Apply' }}
              </bf-button>
            </div>
          </template>
        </VeeForm>
      </div>
    </template>
  </modal>
</template>
<script lang="ts">
  import { computed, defineComponent, PropType, ref, watch, nextTick } from 'vue'
  import { Form as VeeForm, Field } from 'vee-validate'
  import { BfButton, BfTextInput } from 'components/Butterfly'
  import Modal from 'src/components/project/analysis/results/Modal.vue'
  import DashboardSelection from './DashboardSelection.vue'
  import { Dashboard } from 'src/types/DashboardTypes'

  interface FormValues {
    name: string
    description: string
  }

  const ThemeNameModal = defineComponent({
    components: {
      VeeForm,
      Field,
      BfButton,
      BfTextInput,
      Modal,
      DashboardSelection,
    },
    props: {
      visible: { type: Boolean, required: true },
      values: { type: Object as PropType<FormValues>, required: true },
      isNewTheme: { type: Boolean, required: false, default: false },
      submitErrors: { type: Array as PropType<string[]>, required: true },
      dashboardList: { type: Array as PropType<Dashboard[]>, required: false, default: () => []},
      themeGroupList: { type: Array as PropType<Dashboard[]>, required: false, default: () => []},
      canAddToDashboard: { type: Boolean, required: false, default: false },
    },
    setup (props, { emit }) {
      const form = ref<InstanceType<typeof VeeForm>>()
      const selectedDashboards = ref<number[]>([])

      // Set form to current query values when modal is opened
      watch(() => props.visible, (newVal, oldVal) => {
        if (!oldVal && newVal) {
          nextTick(() => {
            form.value && form.value.resetForm({ ...props.values })
          })

          selectedDashboards.value = []

          // For new themes, pre-select all dashboards
          if (props.isNewTheme && props.canAddToDashboard) {
            selectedDashboards.value = props.dashboardList.map((d) => d.id)
          }
        }
      })

      const saveOrRename = (form: FormValues) => {
        const data: Record<string, unknown> = { ...form }
        if (props.isNewTheme) {
          data.dashboard_ids = selectedDashboards.value
        }

        // If no theme group is selected, set it to null
        if (data.theme_group === -1) {
          data.theme_group = null
        }

        emit('update-theme', data, props.isNewTheme)
      }

      const initialFormValues = computed<FormValues>(() => {
        return {
          name: props.values.name ?? '',
          description: props.values.description ?? '',
          theme_group: -1,
        }
      })

      return {
        selectedDashboards,
        saveOrRename,
        initialFormValues,
        form,
      }
    },
  })

  export default ThemeNameModal
</script>
<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .errors
    ul
      list-style: none
      padding: 0
      color: $red
      word-break: keep-all
      margin: 0
      margin-bottom: 20px

  .add-to-dashboards
    .select a
      margin-left: 10px

    a
      white-space: nowrap
      img
        display: inline-block
    .disabled
      a
        opacity: 0.5
        pointer-events: none
    ul
      list-style: none
      padding: 0

  .modal-actions
    display: flex
    justify-content: center
    margin-top: 30px
    > *:not(:last-child)
      margin-right: 20px

  label
    font-weight: bold
    margin-bottom: 4px
    display: block

  .theme-group-radio
    display: flex
    flex-direction: column
    align-items: flex-start
    label
      font-weight: normal
      &:not(:last-child)
        margin-bottom: 10px

  .content-wrap
    padding-right: 10px
    max-height: 60vh
    overflow-y: auto
</style>
