<template>
  <div>
    <el-collapse v-model="activePanels">
      <schema
        :schema="schema"
        :enable-score-editing="enableScoreEditing"
        setting-index="1"
        @update-columns="(col) => $emit('update-score-columns', col)"
      ></schema>
      <unique-fields :unique-fields="uniqueFields" setting-index="2"></unique-fields>
    </el-collapse>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import Schema from './Schema.vue'
import UniqueFields from './UniqueFields.vue'
import { SchemaColumn } from 'types/SchemaTypes'

export default defineComponent({
  name: "CollapsableSettings",
  components: { Schema, UniqueFields },
  props: {
    uniqueFields: { type: Array as PropType<string[]>, default: () => []},
    schema: { type: Array as PropType<Array<SchemaColumn>>, default: () => [] },
    enableScoreEditing: { type: Boolean, default: false, required: false },
  },
  data () {
    return {
      activePanels: [],
    }
  }
})
</script>

<style scoped>

</style>
