<template v-slot:row-tool-tip>
  <tool-tip-panel class="themes-chart-tooltip">
    <chart-tool-tip-body>
      <template #body>
        <div v-show="action" class="action">
          {{ action }}
        </div>
        <h1 v-show="title">
          {{ title }}
        </h1>
        <slot name="title" />
        <table>
          <tr v-for="(datum, index) in data" :key="index">
            <template v-if="datum.break">
              <td colspan="2">
                <hr />
              </td>
            </template>
            <template v-else-if="datum.longText">
              <td colspan="2" style="text-align: left" :style="datum.longText.style">
                {{ datum.longText.text }}
              </td>
            </template>
            <template v-else>
              <th :style="datum.label.style">
                {{ datum.label.text || datum.label }}
              </th>
              <td :style="datum.value.style">
                {{ datum.value.text || datum.value }}
              </td>
            </template>
          </tr>
        </table>
      </template>
    </chart-tool-tip-body>
  </tool-tip-panel>
</template>

<script lang="ts">
  import { PropType, defineComponent } from 'vue'
  import ToolTipPanel from 'components/widgets/ToolTipPanel/ToolTipPanel.vue'
  import ChartToolTipBody from 'components/widgets/ChartToolTipBody/ChartToolTipBody.vue'
  import { DataToolTipInterface } from "types/components/DataToolTip.types"

  const DataToolTip = defineComponent({
    components: { ToolTipPanel, ChartToolTipBody },
    props: {
      title: { type: String, required: false, default: null},
      action: { type: String, required: false, default: null},
      data: { type: Array as PropType<DataToolTipInterface[]>, required: false, default: ()=>[]}
    }
  })

  export default DataToolTip
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'

  .themes-chart-tooltip
    min-width: max-content

    div.action
      color: #95A6AC
      padding: 0 0 10px 0
      font-size: 15px

    th
      text-align: left
      padding: 0 20px 7px 0
      font-weight: normal
      font-size: 16px
    td
      text-align: right
      padding: 0 0 7px 0
      font-size: 16px
    hr
      border: none

    h1
      font-weight: bold
      font-size: 16px
      margin-left: 2px
    hr
      margin: 7px 0
      border: none
      height: 1px
      background-color: rgba(0, 0, 0, 0.13)
</style>
