<template>
  <span></span>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import ClipboardJS from 'clipboard'
  import tinycolor from 'tinycolor2'
  import { mapGetters } from 'vuex'

  import Query from 'src/api/query'
  import QueryUtils from 'src/utils/query'
  import Util from 'src/utils/general'
  import escapeRegExp from 'escape-string-regexp'
  import { sanitize, addHook } from 'dompurify'

  const RESPONSE_LENGTH = 1000 // Character length to truncate a response at
  const NPS_STYLE = {
   Promoter: 'nps-promoter',
   Detractor: 'nps-detractor'
  }
  const SENTIMENT_STYLE = {
     positive: 'sentiment-positive',
     negative: 'sentiment-negative',
     mixed: 'sentiment-mixed'
  }

  export default defineComponent({
    props: {
      query: { type: Object, default: null }, // The query with which to populate excerpts
      savedQuery: { type: Object, default: null },
      excludeEmpty: { type: Boolean, default: false }, // Do we exclude empty excerpts?
      highlighted: { type: Boolean, default: true }
    },
    data () {
      return {
        count: 0, // The number of excerpts associated with this query (all possible hits)
        hits: [], // This is an array of our excerpt hits. Updated on "view more".
        isLoading: false,
        isHighlighted: this.highlighted,
        excerptsHelp: '<p>Displays non-empty verbatims matching the query. Empty verbatims are automatically not displayed</p>'
      }
    },
    computed: {
      ...mapGetters([
        'currentModel', 'currentAnalysis', 'featureFlags', 'savedQueries',
      ]),
      queryValue () {
        if (this.savedQuery) {
          return this.savedQuery.query_value
        }
        return this.query
      },
      // A list of all text values in the query
      textValues () {
        let nodes = QueryUtils.getQueryLeafNodesFromBotanicQuery(this.queryValue)
        return nodes.filter(n => n.type === 'text').map(n => n.value)
      }
    },
    watch: {
      'isHighlighted': function (val) {
        // Use the query to highlight the classes as injected by spans
        this._toggleColours()
      }
    },
    methods: {
      sentimentStyle (value: string): string {
        return SENTIMENT_STYLE[value]
      },
      // Properly expand an excerpt (highlight new content) -- take the VALUE of a js object (which is a reference to the object ¯\_(ツ)_/¯)
      expand (hit) {
        hit._expanded = !hit._expanded
        this.$nextTick(() => {
          this._toggleColours()
        })
      },
      /**
       * Get excerpts from the server based on a passed query, start, and finish values
       * Concats the returned verbatims to the already existing verbatims.
       * Override allows us to decide after query return whether to replace the existing array
       */
      async getExcerpts (query, start, limit, override = false, sort_order = 'most_relevant') {
        this.isLoading = true
        try {
          let q = this.excludeEmpty === true ? {
            type: 'match_all',
            includes: [query, { type: 'nonempty_data' }]
          } : query
          let data = await Query.runQuery(
            this.currentAnalysis.project, this.currentAnalysis.id, q, this.savedQueries,
            {
              start: start,
              limit: limit,
              sortOrder: sort_order            }
          )
          if (this._isDestroyed || this._isBeingDestroyed) return
          this.loadHits(data, override)
        } finally {
          this.isLoading = false
        }
      },
      loadHits (data, override = false) {
        if (override) {
          this.hits = []
        }
        // Reshape the data for display purposes
        data.hits.forEach(hit => {
          hit._show_info = false
          hit._expanded = false
          hit._full_text = this.processText(hit[hit._field], hit._topics, this.query)
          // Replace newlines with pagebreaks
          hit._full_text = Util.excerptNewlineReplace(hit._full_text)
          // Create a truncated display text field for very long verbatims
          if (hit[hit._field].length > RESPONSE_LENGTH) {
            hit._trunc_text = this.processText(hit[hit._field].substring(0, RESPONSE_LENGTH) + '...', hit._topics, this.query)
            hit._trunc_text = Util.excerptNewlineReplace(hit._trunc_text)
          } else {
            hit._trunc_text = null
          }
          hit._npsStyle = (hit['NPS Category'] && NPS_STYLE[hit['NPS Category']]) || ''

          // Check if the hit has AI topics and format them
          if (hit["ai topics"]) {
            // It comes in as an array of strings, but I want to render it
            // as a a simple string where each topic is separated by a comma.
            hit["ai topics"] = hit["ai topics"].join(', ')
          }
        })
        this.hits = this.hits.concat(data.hits) // concat hits to the existing hits (in case of view more)
        this.count = data.count
        // Re-instantiate clipboard elements to account for new excerpts
        new ClipboardJS('span.control.copy-text')
        // Colourize the excerpts based on value 'highlighted'
        this.$nextTick(() => {
          this._toggleColours()
        })
      },
      // Process the text into html -- injecting spans for highlighting
      processText (text, topics) {
        addHook('beforeSanitizeElements', function (node) {
          if (node.parentNode && node.nodeType === 1 && node.nodeName !== "BODY" && node.firstElementChild !== null) {
            // Text node
            node.outerHTML = node.outerHTML.replace('<', '[').replace('>', ']').replace(/(.*?)=""/, '$1')
          }
          return node
        })
        // clean html from text
        let cleanText = sanitize(text, {ALLOWED_TAGS: [], ALLOWED_ATTR: []})

        // Check all text search values to see if they are concepts (topics)
        for (let v of this.textValues) {
          let variants = []
          if (topics.indexOf(v) !== -1) {
            variants = variants.concat(this.currentModel.topics[v].concept_variants)
          } else if (this.currentModel.terms[v]) {
            variants = variants.concat(this.currentModel.terms[v].variants)
          } else {
            // Handle terms/phrases not in the language model
            const cleanedVariant = v.replace(/\"/g, '')  // strip quotes for explicit searching
            variants.push(cleanedVariant)
          }
          for (let variant of variants) {
            cleanText = Util.excerptTextTermReplace(
              cleanText,
              escapeRegExp(variant),
              `<span class="concept-${this._getCleanName(v)}">${variant}</span>`
            )
          }
        }
        return cleanText
      },
      // Return the topic name or term name with illegal characters replaced with a -
      _getCleanName (name) {
        return (typeof name === 'string' ? name.replace(/([^a-z0-9]+)/gi, '-') : name)
      },
      _toggleColours () {
        for (let v of this.textValues) {
          let sel = this.$el.querySelectorAll(`span.concept-${this._getCleanName(v)}`)
          if (sel) {
            Array.prototype.forEach.call(sel, el => {  // IE / Edge = FML!
              if (this.isHighlighted) {
                // Set topic colour if colour exists, else fallback to term colour
                let topicColour = this.currentModel.conceptColours[v]
                  ? tinycolor(this.currentModel.conceptColours[v]).setAlpha(0.5)
                  : tinycolor('#e5e5e5')
                // Assign topic colour, otherwise fallback to term colour
                el.style['background-color'] = topicColour.toRgbString()
              } else {
                el.style['background-color'] = ''
              }
            })
          }
        }
      },
    }
  })
</script>
<style lang="sass">
  @import 'assets/colours.sass'

  .loading-excerpts
    min-height: 7rem

  .segment.body.hit
    .hit-content
      padding-top: 0
      padding-bottom: 0
      font-size: 1.15rem
      line-height: 1.85rem
    .ui.grid.hit-footer-controls
      letter-spacing: 0.025rem
      font-size: 0.87rem !important
      padding-top: 1rem
      font-weight: bold !important
      .ui.horizontal.list
        .item
          font-weight: bold
          font-size: 0.87rem
      span.indicator
        color: $text-grey
      span.control
        cursor: pointer
        color: $text-grey
        &:hover
          opacity: 0.75
      span.control.active
        color: $blue
    .segment.metadata
      padding-left: 1.5rem
      padding-right: 1.5rem
      background-color: #fafafa
      letter-spacing: 0.025rem
      .sentiment
        text-transform: capitalize
      .label
        font-weight: bold
        text-transform: uppercase
        color: $text-grey
        font-size: 0.87rem !important
      .positive
        color: $green
      .negative
        color: $red
      .neutral
        color: $grey-dark
      .mixed
        color: $orange
      .ui.four.column.grid
        padding-top: 1rem
        padding-bottom: 1rem
        .column
          padding-top: 0.15rem
          padding-bottom: 0.15rem
          padding-left: 0.3rem
          padding-right: 0.3rem
  .show-more
    span
      cursor: pointer
      color: $blue
      &:hover
        opacity: 0.75

  .capitalize
    text-transform: capitalize
  .sentiment-positive
    color: $green
  .sentiment-negative
    color: $red
  .sentiment-mixed
    color: $orange
  .nps-promoter
    color: $green
  .nps-detractor
    color: $red
</style>
