// import defineRule from '../defineRule'

import { defineRule } from "vee-validate"

defineRule('max', (value: string, [ max ]: [string]): boolean | string => {
  return typeof value === 'string'
    ? value.length > parseInt(max, 10)
      ? `Your text is too long (${value.length}).   Maximum length is ${max}.`
      : true
    : false
})
