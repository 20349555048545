<template>
  <div class="toggle-checkbox">
    <div class="flex-container">
      <label v-if="showCheckbox" class="switch checkbox">
        <input
          :disabled="disabled"
          :checked="value"
          type="checkbox"
          @input.stop
          @change.stop="$emit('input', $event.target.checked)"
        />
        <span class="slider round"></span>
      </label>
      <label v-else class="switch">
        <div class="blank"></div>
      </label>
      <div
        v-if="hasLabel"
        class="stretch"
        :style="{ opacity: disabled ? '0.5' : '1' }"
        @click="disabled ? null : $emit('input', !value)"
      >
        <slot></slot>
      </div>
    </div>
    <div class="message" :class="{errored}">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  /**
    Renders a toggle slider.

    Example usage from a parent component:

       <div class="field">
           <toggle-slider v-model="sentimentEnabled">
               Enable Sentiment Identification
           </toggle-slider>
       </div>

   */
  export default defineComponent({
    props: {
      value: { type: Boolean, required: false, default: false},
      label: { type: String, required: false, default: '' },
      disabled: { type: Boolean, required: false, default: false},
      showCheckbox: { type: Boolean, required: false, default: true},
      errors: { type: Array, required: false, default: ()=>[] },
    },
    computed: {
      hasLabel () {
        return !!this.$slots
      },
      errored () {
        return this.errors && this.errors.length > 0
      }
    }
  })
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'


  // Width of the whole slider
  $width: 25px
  // Height of the slider. The round toggle itself is this value minus the
  // feather.
  $height: 25px
  // The tiny gap between the round toggle and the inlay of the slider
  // itself
  $feather: 2px
  // The distance to move the slider toggle to the right, which switching
  // it into the "on" position. The x-position is located at the bottom
  // left hand corner of the round toggle. So, to move right, we calculate
  // as following:
  //
  // - first move across by the with of the slider track itself;
  // - then correct for the width of the ball (subtract the ball's diameter
  //   which is the same as the slider's height minus the feather distance)
  // - then correct for the feather on the right hand side, which between the
  //   ball and the right edge of the slider track. This results makes it so
  //   that the right edge of the ball doesn't touch the right edge of the
  //   slider.
  $slider-translate: $width - ($height - $feather) - ($feather)

  $color-hover-off: #afbfc5
  $color-hover-on: #37d75d
  $space-between-checkbox-and-label: 5px
  $border-color: $grey-dark

  .toggle-checkbox
    display: inline-flex
    text-align: initial

  .flex-container
    display: flex
    align-items: center

  .stretch
    flex-grow: 1
    display: flex
    align-items: center
    cursor: pointer
    padding-left: $space-between-checkbox-and-label
  .errored
    margin-left: $width + $space-between-checkbox-and-label * 2
    color: red

  /* Toggle specific styles */
  .switch
    display: block
    cursor: pointer

  .switch input
    display: none

  .slider:before
    content: "\f00c"
    border: 1px solid $border-color
    border-radius: 3px
    display: inline-block
    width: $width
    height: $height
    line-height: 17px
    font-size: 17px
    padding-top: 3px
    padding-left: 3.5px
    margin-right: 0
    color: white
    transition: .2s
    font-family: "Icons"

  .checkbox-label
    font-size: 16px
    color: #373737

  .blank
    width: $width + $space-between-checkbox-and-label

  .blank:before
    display: inline-block
    width: $width
    height: $height
    padding-left: 0.3em
    padding-bottom: 0.5em
    margin-right: $space-between-checkbox-and-label
    vertical-align: bottom
    color: transparent
    margin-bottom: 3px
    font-family: Icons

  input[type=checkbox] + .slider:active:before
    transform: scale(0)

  input[type=checkbox]:checked + .slider:before
    background-color: #068ccc
    border-color: #068ccc
    color: #fff

  input[type=checkbox]:disabled + .slider:before
    transform: scale(1)
    border-color: $border-color
    cursor: default

  input[type=checkbox]:checked:disabled + .slider:before
    transform: scale(1)
    background-color: $border-color
    border-color: $border-color
</style>
