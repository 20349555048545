/* Custom functions/extensions for chart.js */
import Chart from 'chart.js'
import dayjs from 'dayjs'
import { sanitize } from 'dompurify'

const AXIS_COLOUR = '#ebebeb'

/**
 * A helper method for returning a function which creates a custom HTML tooltip using chartJS
 * Heavily inspired by https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
 * NOTE: This returns a function, which accepts a param TooltipModel. This is intended to be passed directly
 * to chartJS
 */
const createTooltipSegmentationChart = (options: {
  selectedOption: string
  frequencies: number[]
  chart: { canvas: HTMLCanvasElement }
}) => {
  return (tooltipModel: any) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip')

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      tooltipEl.innerHTML = '<table></table>'
      document.body.appendChild(tooltipEl)
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform')
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign)
    } else {
      tooltipEl.classList.add('no-transform')
    }

    // Set Text
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || []

      let innerHtml = '<thead>'
      // Add our greyed out text label
      innerHtml += `<tr><td style="color: #95a6ac; font-weight: bold; padding-bottom: 7.5px">Click to add this segment as a filter</td></tr>`
      titleLines.forEach((title: string) => {
        innerHtml += `<tr><th>${options.selectedOption}: ${title}</th></tr>`
      })
      innerHtml += '</thead><tbody>'
      let percentageRow = `<tr><td>- Makes up <span style="color: #068ccc; font-weight: bold;">${percentLabel(tooltipModel.dataPoints[0])}</span> of this cohort.</td></tr>`
      innerHtml += percentageRow
      // For overall, we want frequency. For individual, correlations
      let datasetIdx = tooltipModel.dataPoints[0].index
      let frequency = options.frequencies[datasetIdx]
      let frequencyRow = `<tr><td>- This represents <span style="color: #068ccc; font-weight: bold;">${frequency}</span> total respondents.</td></tr>`
      innerHtml += frequencyRow
      innerHtml += '</tbody>'

      let tableRoot = tooltipEl.querySelector('table')!
      tableRoot.innerHTML = sanitize(innerHtml)
    }

    // `this` will be the overall tooltip
    let position = options.chart.canvas.getBoundingClientRect()

    // Display and font styles
    tooltipEl.style.opacity = '1'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.padding = 15 + 'px ' + 15 + 'px'
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
    tooltipEl.style.pointerEvents = 'none'
    // Place the tooltip
    const minWidth = 280 // Pixel value of our expected minimum width.
    const tooltipElBoundingRect = tooltipEl.getBoundingClientRect()
    const tooltipElHeightOffset = tooltipElBoundingRect.height / 2

    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px'
    // We want to make sure we're not rendering a squashed tooltip
    const currentTooltipWidth = tooltipEl.getBoundingClientRect().width
    if (currentTooltipWidth < minWidth) {
      const offset = minWidth - currentTooltipWidth
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 10 - offset + 'px'
    }
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipElHeightOffset + 'px'
    // Set a minwidth
    tooltipEl.style.minWidth = minWidth.toString()
    // Custom style for the tooltips
    tooltipEl.style.backgroundColor = '#f8f8f8'
    tooltipEl.style.border = 'solid 1px #e6e6e6'
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.boxShadow = '0px 1px 5px 0 rgba(0, 0, 0, 0.15)'
    tooltipEl.style.textAlign = 'left'
  }
}

// Return scale config for percentage.
const percentTicks = function (scaled: boolean) {
  if (!scaled) {
    return {
      min: 0,
      max: 100,
      callback: (value: number) => {
        return value + '%'
      }
    }
  } else if (scaled) {
    return {
      min: undefined,
      max: undefined,
      beginAtZero: true,
      callback: (value: number) => {
        return value + '%'
      }
    }
  }
}
// Convert tooltip label to percentage.
const percentLabel = function (tooltipItem: any) {
  return tooltipItem.xLabel.toFixed(2) + '%'
}
// Chart.js plugin that renders a tiny bit of horizontal bar for zero values.
const horizontalZeroCompensationPlugin = {
  renderZeroCompensation: function (chartInstance: any, d: any, width = 2) {
    // get postion info from _view
    const view = d._view
    const context = chartInstance.chart.ctx
    const startX = view.x
    const startY = view.y - view.height / 2
    context.beginPath()
    context.moveTo(startX, startY)
    context.lineTo(startX + width, startY)
    context.lineTo(startX + width, startY + view.height)
    context.lineTo(startX, startY + view.height)
    context.fillStyle = view.backgroundColor
    context.fill()
  },
  afterDatasetsDraw: function (chart: any) {
    chart.config.data.datasets.forEach((dataset: any, i: number) => {
      let meta = chart.getDatasetMeta(i)
      meta.data.forEach((d: any, index: number) => {
        // for the item which value is 0, reander a line.
        if (dataset.data[index] === 0) {
          this.renderZeroCompensation(chart, d)
        }
      })
    })
  }
}

// Register custom correlation scale for chart.js
let CorrelationScale = (Chart as any).Scale.extend({
  determineDataLimits () {
    this.min = -1
    this.max = 1
  },
  buildTicks () {
    this.tickValues = [-1, -0.25, 0, 0.25, 1]
    this.ticks = ['Never\n(-1.0)', 'Unlikely\n(-0.25)', 'No correlation\n(0)', 'Likely\n(0.25)', 'Always\n(1.0)']
  },
  getLabelForIndex (index: number, datasetIndex: number) {
    return this.chart.data.datasets[datasetIndex].data[index]
  },
  // Get the pixel (x coordinate for horizontal axis, y coordinate for vertical axis) for a given value
  // @param index: index into the ticks array
  // @param includeOffset: if true, get the pixel halway between the given tick and the next
  getPixelForTick (index: number) {
    return this.getPixelForValue(this.tickValues[index])
  },

  // Get the pixel (x coordinate for horizontal axis, y coordinate for vertical axis) for a given value
  // @param value : the value to get the pixel for
  // @param index : index into the data array of the value
  // @param datasetIndex : index of the dataset the value comes from
  // @param includeOffset : if true, get the pixel halway between the given tick and the next
  getPixelForValue (value: number) {
    let widthPerSegment = (this.width - this.paddingRight) / 4
    let p
    if (value < -0.25) {
      p = widthPerSegment / 0.75 * (1 - Math.abs(value))
    } else if (value < 0) {
      p = widthPerSegment + widthPerSegment / 0.25 * (0.25 - Math.abs(value))
    } else if (value > 0.25) {
      p = 4 * widthPerSegment - widthPerSegment / 0.75 * (1 - value)
    } else if (value > 0) {
      p = 3 * widthPerSegment - widthPerSegment / 0.25 * (0.25 - value)
      if (value < 0.001) {
        p += 2
      }
    } else if (value === 0) {
      p = 2 * widthPerSegment
    }
    return this.left + p
  },
  convertTicksToLabels () {
    (Chart as any).Scale.prototype.convertTicksToLabels.call(this)
    this.zeroLineIndex = this.tickValues.indexOf(0)
  }
})

const scaleService = (Chart.scaleService as any).registerScaleType('correlation', CorrelationScale, { scaleBeginAtZero: true })

/**
 * Formats a percentage value for CSV output
 */
const formatPercent = (num: number | string) => {
  return `${parseFloat(num.toString()).toFixed(2)}%`
}

/**
 * Formats a date based on the resolution
 */
const formatDate = (date: string, resolution: string) => {
  // This is called from NPSTimeline.util and SentimentTimeline.util.
  // We pass in milliseconds since epoc here as date.
  // We convert the localtime to UTC time and format.
  switch (resolution) {
    default:
    case 'daily':
    case 'weekly':
      return dayjs(+date).utc().format('DD/MM/YYYY')
    case 'monthly':
      return dayjs(+date).utc().format('MMMM YYYY')
    case 'yearly':
      return dayjs(+date).utc().format('YYYY')
  }
}

export default {
  AXIS_COLOUR,
  createTooltipSegmentationChart,
  percentTicks,
  percentLabel,
  horizontalZeroCompensationPlugin,
  CorrelationScale,
  scaleService,
  formatPercent,
  formatDate,
}
