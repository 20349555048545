<template>
  <div id="grid-wrapper" class="ui segment wrapper">
    <!-- Header -->
    <div class="header row">
      <h3>
        Project Data
      </h3>
      <!-- Qualtrics and Survey Monkey do not support manual updates. -->
      <bf-button
        v-if="!loadingData && !['qualtrics', 'survey_monkey'].includes(currentProject.integration.type)"
        color="blue"
        size="large"
        :class="{ disabled: numResponsesToUpdate === 0, 'loading disabled': isLoading }"
        @click="popModal"
      >
        <i class="kapiche-icon-refresh"></i><span class="button-label">Update Data</span>
      </bf-button>
    </div>

    <!-- Integration logo -->
    <div class="logo row">
      <div class="subtext">
        Integrated With
      </div>
      <img
        v-if="currentProject.integration.type === 'qualtrics'" class="ui centered image" src="../../../assets/img/qualtrics.png"
        srcset="../../../assets/img/qualtrics@2x.png 2x, ../../../assets/img/qualtrics@3x.png 3x"
      >
      <img
        v-if="currentProject.integration.type === 'delighted'" class="ui centered image delighted" src="../../../assets/img/integrations/delighted-full.png"
        srcset="../../../assets/img/integrations/delighted-full@2x.png 2x, ../../../assets/img/integrations/delighted-full@3x.png 3x"
      >
      <img
        v-if="currentProject.integration.type === 'survey_monkey'" class="ui centered image surveymonkey" src="../../../assets/img/integrations/surveymonkey-full.png"
        srcset="../../../assets/img/integrations/surveymonkey-full@2x.png 2x, ../../../assets/img/integrations/surveymonkey-full@3x.png 3x"
      >
    </div>

    <!-- Integration info -->
    <div class="ui grid info row">
      <div class="name">
        <label :class="{ exceeded: rowLimitExceeded }">
          {{ number(currentProject.data_units) }}<!-- wrapping comment to eliminate space
                --><span v-if="subscription.projectRowLimit > 0">/{{ number(subscription.projectRowLimit) }} max</span>
          records
        </label>
      </div>
      <div class="status">
        <template v-if="loadingData">
          <div class="ui active inline loader tiny"></div>
          <label class="orange"> Updating</label>
        </template>
        <span v-else-if="currentProject.processing">
          <span class="ui active tiny inline loader"></span>
          <label class="orange"> Processing</label>
        </span>
        <!-- todo: we should check if the survey is active -->
        <span v-else-if="currentProject.integration.type === 'qualtrics'" class="green">Active</span>
      </div>
    </div>

    <!-- Updated date -->
    <div class="updated row">
      <div class="subtext">
        Last updated: {{ dateModified }}
      </div>
    </div>

    <!-- Update modal -->
    <div class="ui tiny modal update-modal">
      <div class="header">
        Update from Integration
      </div>
      <div class="content">
        <div class="ui basic segment center aligned">
          <p v-if="sufficientDataUnits">
            The new data has <span class="green">{{ number(numResponsesToUpdate) }}</span> more responses than the old data.
            Should you choose to update, you will have {{ number(dataUnits - numResponsesToUpdate) }} Data units remaining in your balance
          </p>

          <p v-if="!sufficientDataUnits">
            The new data has <span class="red">{{ numResponsesToUpdate }}</span> more
            responses than the old data, but you only have {{ number(dataUnits) }} data units remaining. Please <router-link :to="{ name: 'select-plan' }">
              Upgrade your plan.
            </router-link>
          </p>

          <div class="ui basic segment center aligned">
            <button class="ui big button grey floated left" :class="{ 'disabled': isLoading }" @click="removeModal">
              Cancel
            </button>
            <button v-if="sufficientDataUnits" class="ui big button blue floated right" :class="{ 'loading disabled': isLoading }" @click="updateIntegration()">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue, { defineComponent } from 'vue'
  import Project from 'src/api/project'

  import $ from 'jquery'
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  import { BfButton } from 'components/Butterfly'
  import FormatUtils from 'src/utils/formatters'

  export default defineComponent({
    components: { BfButton },
    data () {
      return {
        loadingData: false,
        numResponsesToUpdate: 0,
        isLoading: false
      }
    },
    computed: {
      ...mapGetters([ 'dataUnits', 'subscription', 'currentProject']),
      rowLimitExceeded () {
        return this.subscription?.projectRowLimit && this.currentProject?.data_units > this.subscription?.projectRowLimit
      },
      dateModified () {
        return dayjs(this.currentProject.modified).format('DD-MMM-YYYY')
      },
      sufficientDataUnits () {
        if (this.numResponsesToUpdate < this.dataUnits) {
          return true
        } else if (this.numResponsesToUpdate > this.dataUnits) {
          return false
        }
        // Catchall, to be safe
        return false
      }
    },
    mounted () {
      Project.getNewResponsesFromIntegration(this.currentProject.id)
        .then(response => {
          this.numResponsesToUpdate = response
        })
    },
    methods: {
      number: FormatUtils.number,
      updateIntegration () {
        this.isLoading = true
        Project.updateProjectFromIntegration(this.currentProject.id, {})
          .then((response) => {
            // Update our update button state
            Project.getNewResponsesFromIntegration(this.currentProject.id)
              .then(response => {
                this.numResponsesToUpdate = response
              })
          })
          .finally(() => {
            this.isLoading = false
            this.removeModal()
          })
      },
      popModal () {
        $('.ui.tiny.modal.update-modal'
        ).modal('show')
      },
      removeModal () {
        $('.ui.tiny.modal.update-modal'
        ).modal('hide')
      }
    }
  })
</script>
<style lang="sass" scoped>
  @import '../../../assets/kapiche.sass'
  @import '../../../assets/mixins.sass'

  .ui.tiny.modal
    p
      font-size: 16px
      color: rgb(56, 56, 56)

  /* Reusable classes */
  .red
    color: $red
  .green
    color: $green
  .grey
    color: $text-grey
  .orange
    color: $orange
  .subtext
    color: $text-grey
    font-size: rem(12px)
    font-weight: bold
    letter-spacing: rem(0.6px)
    margin-bottom: rem(10px)
    text-transform: uppercase

  .ui.segment#grid-wrapper
    border: 0
    box-shadow: $box-shadow
    height: rem(550px)
    padding: 0

    /* Integrations logo */
    .ui.basic.segment
      img.ui.centered.image
        padding-bottom: 30px
        &.delighted
          padding-top: 15px
          padding-bottom: 15px

    .row
      height: auto
      padding: rem(10px)

      /* Header row */
      &.header
        display: flex
        justify-content: space-between
        height: rem(75px)
        align-items: center
        padding: 0
        h3, a, button
          margin: rem(15px) rem(20px)

      /* Logo row */
      &.logo
        display: block
        background-color: $grey-extra-light
        text-align: center

      /* Info row */
      &.info
        margin: 0 !important
        padding: rem(10px)
      label
        margin-left: rem(5px)

      /* Updated row */
      &.updated
        justify-content: center

      /* Row parts */
      .name,
      .status
        font-size: rem(12px)
        margin-top: rem(12px)
        margin-bottom: rem(12px)

      /* Status parts */
      .status
        .inline.loader
          vertical-align: text-bottom

  .button-label
    margin-left: rem(10px)

  label
    font-size: 16px
    color: $subdued
    &.exceeded
      color: $orange

</style>
