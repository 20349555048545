<template>
  <modal
    :visible="visible"
    @close="close"
  >
    <template #header>
      Share this Dashboard
    </template>
    <template #content>
      <div ref="content" class="sharePanel">
        <div class="tab-container">
          <el-tabs
            :model-value="activeTab"
            @update:model-value="activeTab = $event"
            @tab-change="tabChanged"
          >
            <el-tab-pane label="SHARE / INVITE" name="share">
              <template #default>
                <div class="content" :class="{reverse: !approvedDomains.length}">
                  <div v-if="approvedDomains.length" class="share-url">
                    <label>Copy this private URL to share:</label>
                    <div ref="copyArea" class="inviter">
                      <bf-text-input :value="shareableLink" readonly class="copy-text" />
                      <bf-button class="copy-button" sharp size="large" color="green">
                        {{ copyText }}
                      </bf-button>
                    </div>
                    <div class="under-text">
                      <label class="restricted">
                        <span class="label">
                          Restricted to:
                        </span>
                        <span
                          @mouseover="tooltip = true"
                          @mouseleave="tooltip = false"
                        >
                          <span v-if="approvedDomains.length>1" class="domain">APPROVED DOMAINS</span>
                          <span v-else class="domain">{{ approvedDomains[0].domain }}</span>
                          &nbsp;
                          <div v-show="tooltip" class="tooltip">
                            <p>
                              Anyone with an email from the approved domains below can use the private URL to view this Dashboard.
                            </p>
                            <label v-for="domain in approvedDomains" :key="domain.id" class="domain">
                              {{ domain.domain }}
                            </label>
                          </div>
                        </span>
                      </label>
                      <div v-if="approvedDomains.length" class="limit">
                        {{ viewerLimitMessage }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="info">
                    <h2>Set up an Approved domain to share in one-click</h2>
                    <p>Approved domains lets you share Dashboards with anyone that has an approved email. </p>
                    <p>Have your administrator set up <a :href="CONST.widget_help_links.approved_domains" target="_blank">Approved Domains</a>.</p>
                  </div>
                  <bf-divider>or</bf-divider>
                  <div class="email-invite">
                    <label>Invite by email address:</label>
                    <div class="inviter">
                      <div v-if="userStatusMessage" class="status-text green center">
                        <i class="icon check" />{{ userStatusMessage }}
                      </div>
                      <multiselect
                        v-show="!userStatusMessage"
                        ref="multiselect"
                        v-model="stagedUser"
                        placeholder="Invite by email address"
                        track-by="id"
                        :options="users"
                        :custom-label="userLabel"
                        :show-labels="false"
                        :multiple="false"
                        :searchable="true"
                        :disabled="inviting"
                        :loading="isSearching || inviting"
                        :internal-search="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :options-limit="300"
                        :limit-text="limitText"
                        :option-height="20"
                        :preserve-search="true"
                        no-options="No users found."
                        @select="stageUser"
                        @search-change="fetchUsers"
                        @close="onSelectClose"
                      >
                        <template v-if="users.length > 0 && users[0].id !== -1" #beforeList>
                          <span class="option__heading">
                            Add an existing user to this project:
                          </span>
                        </template>

                        <template #noResult>
                          <span>No users found.</span>
                        </template>

                        <template #option="props">
                          <span class="option__title">
                            {{ props.option.email }}
                          </span>
                        </template>
                      </multiselect>
                      <bf-button :disabled="inviting || isSearching || !validStagedUser" color="blue" class="invite" size="large" sharp @click="addUser">
                        Invite
                      </bf-button>
                    </div>
                    <div class="under-text">
                      <p class="hint">
                        Inviting an existing Creator user will add them to this Project as well as the Dashboard.
                      </p>
                      <p v-if="approvedDomains.length <= 0" class="limit">
                        {{ viewerLimitMessage }}
                      </p>
                    </div>
                    <template v-if="serverErrors.length > 0">
                      <p v-for="error in serverErrors" :key="error" class="error-text">
                        {{ error }}
                      </p>
                    </template>
                  </div>
                </div>
              </template>
            </el-tab-pane>
            <el-tab-pane label="WHO HAS ACCESS" name="access">
              <template #default>
                <div class="content">
                  <ul class="segments">
                    <li
                      class="members"
                      @mouseover="tooltip = true"
                      @mouseleave="tooltip = false"
                    >
                      All members of this project
                      <el-popover
                        v-show="tooltip"
                        :hide-after="0"
                        effect="dark"
                      >
                        <template #default>
                          <div class="memberslist">
                            <label>Project Members</label>
                            <p v-for="user in projectMembers" :key="user.email">
                              {{ user.full_name }} ({{ user.email }})
                            </p>
                          </div>
                        </template>
                        <template #reference>
                          <label class="memberslist">
                            (View {{ projectMembers.length }} member{{ projectMembers.length>1?'s':'' }})
                          </label>
                        </template>
                      </el-popover>
                    </li>
                    <li v-for="user in dashboardMembers" :key="user.email">
                      <template v-if="user.id !== -1">
                        <!--
                          I don't really like how this is done but I'm going to leave it for now.
                          some semantic-ui stuff that could go away, and possibly make removingUser
                          an array so you could click through multiples to remove them instead of
                          having to wait.
                         -->
                        <span v-if="removingUser === user.id" class="ui active inline small right floated loader "></span>
                        <a v-else class="right floated remove" :class="{'disabled':removingUser}" @click="removingUser?'':removeMember(user.id)">REMOVE</a>
                        {{ user.full_name }} ({{ user.email }})
                      </template>
                      <template v-else>
                        {{ user.email }} (<em>invitation sent</em>)
                      </template>
                    </li>
                  </ul>
                </div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="actions">
          <bf-button color="blue" size="large" @click="close">
            Done
          </bf-button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import { BfDivider, BfButton, BfTextInput } from 'src/components/Butterfly'
import Multiselect from 'vue-multiselect'
import Project from 'src/api/project'
import _ from 'lodash'
import ClipboardJS from 'clipboard'

import { CLEAR_ERRORS } from 'src/store/types'
import isEmail from 'validator/lib/isEmail'
import Modal from 'src/components/project/analysis/results/Modal.vue'

export default defineComponent({
  components: {
    Multiselect,
    BfDivider,
    BfButton,
    BfTextInput,
    Modal,
  },
  props: {
    visible: { type: Boolean, default: false, required: false },
    approvedDomains: {type: Array, required: false, default:()=>[]},
    dashboard: { type: Object, required: true },
    dashboardMembers: { type: Array, required: true },
    projectMembers: { type: Array, required: true },
    shareableLink: { type: String, required: false, default: null }
  },
  data () {
    return {
      activeTab: 'share',
      search: '',
      inviting: false,
      tooltip: false,
      users: [],
      copyText: 'Copy',
      clipboard: null,
      isSearching: false,
      serverErrors: [],
      userStatusMessage: '', // displays success message for added/invited
      userStatusTimer: null,
      stagedUser: null, // holds user object after selection but before sending invite
      removingUser: null,
      fetchUsers: _.debounce(search => {
        this.search = search

        if (!search) return

        const isQueryEmail = isEmail(search)
        this.isSearching = true
        Project.searchDashboardUsers(this.dashboard.id, search)
          .then((response) => {
            if (isQueryEmail === true) {
              // Is there a perfect match for the email in the results?
              let match = response
                .concat(this.projectMembers)
                .concat(this.dashboardMembers)
                .find(user => user.email.toLowerCase() === search.toLowerCase())
              if (!match) {
                // Offer the option to invite by email
                this.users = [{ email: search, id: -1 }].concat(response)
              } else {
                this.users = response
              }
            } else {
              this.users = response
            }
            this.isSearching = false
          })
      }, 300),
    }
  },
  computed:{
    ...mapGetters([
      'currentSite', 'currentUser', 'viewerUserLimit', 'viewerMembershipCount',
    ]),
    validStagedUser () {
      return this.stagedUser && isEmail(this.stagedUser.email)
    },
    viewerLimitMessage (): string {
      const remaining = this.viewerUserLimit - this.viewerMembershipCount
      if (!Number.isInteger(remaining)) {
        return ''
      }
      return `${this.viewerUserLimit - this.viewerMembershipCount} explorer seats remain`
    },
  },
  watch: {
    visible (value) {
      this.setUpClipboard(value)
    }
  },
  methods:{
    ...mapActions({ CLEAR_ERRORS }),
    beforeUnmount () {
      // See ch22034 and https://github.com/shentao/vue-multiselect/issues/725
      this.$refs.multiselect?.deactivate()
    },
    onSelectClose () {
      const isQueryEmail = isEmail(this.search || '')

      // An email has been entered, select the invitation option
      if (this.users[0]?.id === -1) {
        this.stageUser(this.users[0])
      // Input is no longer a valid email, clear the selection
      } else if (this.stagedUser?.id === -1 && !isQueryEmail) {
        this.stageUser(null)
      }
    },
    setUpClipboard (visible) {
      if (this.clipboard !== null) this.clipboard.destroy()
      if (!visible) {
        // Dont rebind clipboard triggers if the modal is not visible
        return
      }
      this.$nextTick(()=>{
        const triggers = this.$refs.content.querySelectorAll('button.copy-button,div.copy-text')
        this.clipboard = new ClipboardJS(triggers, {
          target: (trigger)=>trigger,
          text: ()=>this.shareableLink
        })
        this.clipboard.on('success', () => {
          this.copyText = 'Copied!'
          window.setTimeout(() => {
            this.copyText = 'Copy'
          }, 1500)
          this.$emit('linkCopied')
        })
        this.clipboard.on('error', () => {
          this.copyText = 'Copy Failed'
          window.setTimeout(() => {
            this.copyText = 'Copy'
          }, 1500)
        })
        }
      )
    },
    tabChanged (tab: string) {
      // if the modal is visible & it's the first tab then we
      // want clipboard.js setup, otherwise just tear down
      this.setUpClipboard(this.visible && tab === 'share')
    },
    close () {
      this.stagedUser = null
      this.serverErrors = []
      this.$emit('close')
    },
    stageUser (user) {
      this.stagedUser = user
    },
    addUser () {
      const user = this.stagedUser
        this.inviting = true
        this.serverErrors = []
        // Is this an invite or an add?
        if (user.id === -1) {
          Project.inviteToDashboard(this.dashboard.id, user.email, this.shareableLink)
            .then(() => {
              this.$emit('updated', this.dashboardMembers.concat(user))
              this.setUserStatus(`${user.email} has been invited`)
              this.inviting = false
              this.stagedUser = null
              this.$analytics.track.dashboard.inviteUser(user.email, this.dashboard.id, this.currentUser.email)
            })
            .catch(errors => {
              this.CLEAR_ERRORS()
              this.serverErrors = typeof errors.body === 'object'? Object.values(errors.body).flat() : [errors.body]
              this.inviting = false
            })
        } else {
          Project.addUserToDashboard(this.dashboard.id, user, this.shareableLink)
            .then(response => {
              this.$emit('updated', this.dashboardMembers.concat(user))
              this.setUserStatus(`${response.user.full_name} has been added`)
              this.users = []
              this.inviting = false
              this.stagedUser = null
              this.$analytics.track.dashboard.addUser(user.email, this.dashboard.id, this.currentUser.email)
            })
            .catch(errors => {
              this.CLEAR_ERRORS()
              this.serverErrors = typeof errors.body === 'object'? Object.values(errors.body).flat() : [errors.body]
              this.inviting = false
            })
        }
      },
      removeMember (id) {
        this.removingUser = id
        Project.deleteUserFromDashboard(this.dashboard.id, id)
          .then(() => {
            this.removingUser = null
            this.$emit('updated', this.dashboardMembers.filter(item => item.id !== id))
          })
          .catch(errors => {
            this.removingUser = null
          })
      },
      userLabel (user) {
        if (user.id === -1) {
          // This must be the invite option.
          return `Send invite to ${user.email}`
        } else {
          return `${user.full_name} (${user.email})`
        }
      },
      limitText (count) {
        return `and ${count} other users`
      },
      setUserStatus (text) {
        this.userStatusMessage = text
        if (this.userStatusTimer) {
          window.clearTimeout(this.userStatusTimer)
        }
        this.userStatusTimer = window.setTimeout(() => {
          this.userStatusMessage = ''
        }, 2000)
      },
  }
})
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.sharePanel
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  height: 550px

  ::v-deep .tab-list
    border-bottom: 1px solid #e5e5e5

  h2
    margin-top: 13px
    margin-bottom: 30px

  .tab-container
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%
    flex: 1

  .content
    padding: 40px 50px
    background-color: $grey-background
    display: flex
    justify-content: space-between
    flex-direction: column
    width: 100%
    height: 100%
    flex-grow: 2

  .reverse
    flex-direction: column-reverse

  .info
    display: flex
    flex-direction: column
    align-items: center
    font-size: 16px
    line-height: 24px
    h2
      font-size: 20px
      margin: 0 0 20px
    p
      margin: 0

  .under-text
    display: flex
    justify-content: space-between
    margin-top: 10px
    font-size: 14px
    line-height: 24px
    margin-bottom: 1em

    .limit, .label
      color: $text-grey
      text-transform: uppercase
      font-weight: bold
      font-size: 12px
      letter-spacing: 0.05em
      .domain
        color: $blue

    .limit
      margin-left: auto

  .domain
    font-weight: bold
    color: $blue
    font-size: 12px
    line-height: 16px
    letter-spacing: 0.6px
    text-transform: uppercase

  .tooltip
    position: absolute
    max-width: 432px
    z-index: 30
    padding: 20px 25px 10px 25px !important
    color: $text-black !important
    background-color: $white
    font-size: 16px

  .actions
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    background-color: $grey-background
    flex-shrink: 1

  .status-text
    background-color: $white
    width: 100%
    border-radius: 4px
    border: 1px solid #e8e8e8
    padding: 14px 10px
    font-size: 16px
    font-weight: bold
    height: 50px
    &.green
      color: $green
    &.right
      text-align: right
    &.center
        text-align: center
    &.hidden
      visibility: hidden

  .memberslist
    display: inline-block
    color: $blue

  .memberslist.tooltip
    color: $white
    padding: 15px 15px !important
    font-size: 16px
    line-height: 20px
    letter-spacing: 0.6px
    p
      margin-bottom: 2px
    label
      font-weight: bold
      display: block
      text-transform: uppercase
      margin-bottom: 5px

  .inviter
    display: flex
    justify-content: center
    align-items: flex-start

    button.invite
      height: 50px
      margin: 0

    &::v-deep .message
      display: none

    &::v-deep .copy-button
      height: 50px

    &::v-deep .multiselect

      height: 50px

      .multiselect__input, .multiselect__placeholder
        padding: 0 !important
        margin: 0 5px !important

      .multiselect__content-wrapper
        margin-top: 5px
        border-radius: 0
        color: text-black !important
        background-color: #fff !important
        box-shadow: 0 1px 15px #0000001A
        border: 1px solid #E5E5E5

      .multiselect__spinner
        width: 48px
        height: 48px
        z-index: 5

      .multiselect__option
        padding: 7px !important
        height: 29px !important
        min-height: 29px !important

      .multiselect__tags
        border-radius: 0
        height: 50px
        padding: 14px 10px

      .multiselect__select::before
        content: none

      .multiselect__option--highlight
        background-color: $grey-background

  .option__title
    background-color: none
    display: inline-block
    height: 14px !important
    color: $text-black

  .option__heading
    padding: 4px 7px 2px
    text-transform: uppercase
    font-weight: bold
    font-size: 12px
    display: block

  ul.segments
    padding: 0
    margin: 0
    list-style: none
    height: 100%
    background: white
    border: 1px solid #E5E5E5

    li
      padding: 15px 20px
      border-bottom: 1px solid #E5E5E5

  .copy-button
    min-width: 100px
    margin: 0 0 0 0

  a.remove
    cursor: pointer

  a.disabled
    cursor: not-allowed
    color: $text-grey !important
    text-decoration: none

  .error-text
    color: $red


</style>
