<template>
  <div class="ui container">
    <template v-if="projects.length > 0 || filtered || isSearching">
      <!-- Header -->
      <div class="ui grid">
        <div class="eight wide column">
          <h1><span v-if="featureFlags.demo_site">Demo </span>Projects</h1>
        </div>
        <div class="eight wide column right">
          <router-link
            v-if="(currentUser && currentUser.is_staff) || !featureFlags.demo_site"
            :to="{ name: 'project-create' }" class="ui button primary"
            :class="{'staff-only': featureFlags.demo_site && (currentUser && currentUser.is_staff)}"
          >
            <i class="kapiche-icon-briefcase"></i>
            <span>Create Project</span>
          </router-link>
        </div>
      </div>

      <!-- Search/filter -->
      <div class="filters">
        <div class="search-container">
          <div class="ui tiny inline loader" :class="{active: isSearching}"></div>
          <i v-show="!isSearching" class="icon search"></i>
          <input v-model="filterName" type="search" placeholder="Search" @input="search">
          <i v-if="filterName.length > 0" class="kapiche-icon-delete-thin" @click="clearNameFilter"></i>
        </div>
        <div>
          Sort by:
          <dropdown position="is-bottom-right" class="sort" :value="sortBy" @change="setSortBy">
            <template #trigger>
              <div class="sortByButton">
                {{ sortOptions[sortBy] }} <i class="icon chevron down"></i>
              </div>
            </template>
            <dropdown-item v-for="key in Object.keys(sortOptions)" :key="key" :value="key">
              {{ sortOptions[key] }}
            </dropdown-item>
          </dropdown>
        </div>
      </div>
      <div class="label-filters ui grid">
        <div class="twelve wide column">
          <project-label-filter :filter-labels="filterLabels" @toggle-label-filter="handleLabelToggle" @reset-label-filters="handleLabelReset" />
        </div>
        <div class="four wide column">
          <project-label
            name="Manage labels"
            icon="el-icon-edit"
            color="#068ccc"
            :invert="true"
            @click="$router.push({ name: 'project-labels' })"
          />
        </div>
      </div>

      <div v-if="projects.length > 0" class="project-rows">
        <div id="projectDimmer" class="ui dimmer inverted" :class="{ 'active' : loading }">
          <div class="ui text loader">
            Loading
          </div>
        </div>

        <div
          v-for="project in projects" :key="project.id"
          class="ui grid project-row" :class="{ hovered: hoveredProject===project.id }"
          @click="goToProject(project.id)"
          @mouseover="hoverRow(project.id)"
          @mouseleave="unhoverRow"
        >
          <div class="twelve wide column">
            <div class="name">
              {{ truncate(project.name, 50) }}
            </div>
            <div class="subtitle">
              <div class="date">
                <span v-if="sortBy == '-modified'">
                  MODIFIED {{ fmtDate(project.modified) }}
                </span>
                <span v-else>
                  CREATED {{ fmtDate(project.created) }}
                </span>
              </div>
            </div>
          </div>
          <div class="four wide column right">
            <div class="project-info">
              <div class="subtext">
                {{ number(project.data_units) }} records
              </div>
              <div>
                Created by {{ createdBy(project) }}
              </div>
            </div>
            <!-- prevents click event triggering in parent -->
            <div @click.stop>
              <dropdown ref="projectActionMenu" position="is-bottom-right" @input="doProjectAction" @cancelled="projectMenuCancelled">
                <template #trigger>
                  <div class="elipsis" @click="selectProject(project.id, project.name)">
                    ...
                  </div>
                </template>
                <dropdown-item :value="{ action: 'rename', options: { id: project.id, name: project.name } }">
                  Rename Project
                </dropdown-item>
                <dropdown-item :value="{ action: 'delete', options: { id: project.id, name: project.name } }">
                  Delete Project
                </dropdown-item>
              </dropdown>
            </div>
            <div class="num-members">
              {{ pluraliseDesc(project.users, 'member') }}
            </div>
          </div>
          <div class="sixteen wide column labels">
            <project-label
              v-for="label in project.labels"
              :key="label.id"
              :color="label.color"
              :name="label.name"
              removable
              @remove="deleteLabel(project.id, label.id, project.labels)"
              @click.stop
            >
            </project-label>
            <project-label-add
              :ref="`plf-${project.id}`"
              :project-id="project.id"
              :show-icon="hoveredProject===project.id"
              :current-labels="project.labels"
              @label-selected="addLabel(project.id, $event, project.labels)"
              @click.stop
            >
            </project-label-add>
          </div>
        </div>
      </div>

      <!-- No results state -->
      <div v-if="projects.length === 0">
        <div class="no-results">
          <img src="../../assets/logo-faded.png">
          <h2>No results found</h2>
          <div class="subtext">
            Try adjusting your search.
          </div>
        </div>
      </div>

      <div v-if="pageCount > 1" class="pagination">
        <i class="kapiche-icon-chevron-left" :class="{ 'disabled' : firstResult === 1 }" @click="pageBack"></i>
        <div class="info">
          {{ firstResult }} - {{ firstResult + projects.length - 1 }} <span class="of">of</span> {{ data.count }}
        </div>
        <i class="kapiche-icon-chevron-right" :class="{ 'disabled' : firstResult + projects.length > data.count }" @click="pageForward"></i>
      </div>
      <div v-else class="pagination" />
    </template>

    <!-- show help info & create project button if there are no projects in the list  -->
    <div v-else-if="!isSearching" class="empty-state">
      <i class="kapiche-icon-briefcase"></i>
      <h1>Create your first Project</h1>
      <div>Welcome to Kapiche! Let’s start by creating a project.</div>
      <router-link :to="{ name: 'project-create' }" class="ui button primary">
        Create Project
      </router-link>
      <div class="help-text">
        Need help? <a href="javascript:window.Intercom('show');">Chat with us</a> or visit our <a :href="CONST.intercom_links.HELP" target="_blank">Help &amp; Knowledge Base</a>.
      </div>
    </div>

    <!-- === Delete Project Confirmation Modal === -->
    <bf-modal :visible="showingConfirmationModal" :click-to-close="false" @close="cancelProjectAction">
      <bf-dialog @close="cancelProjectAction">
        <div class="dialog-content">
          <h2>Do you want to delete the project '{{ selectedProject && selectedProject.name }}'?</h2>
          <div class="text">
            <p>Deleting a project cannot be undone..</p>
            <p><strong>All data and analyses for the project will be lost!</strong></p>
          </div>

          <div class="buttons">
            <bf-button size="big" @click="cancelProjectAction">
              No
            </bf-button>
            <bf-button size="big" color="red" @click="deleteProject(selectedProject.id)">
              Yes, Delete the project
            </bf-button>
          </div>
        </div>
      </bf-dialog>
    </bf-modal>

    <!-- === delete project modal === -->
    <bf-modal :visible="showingDeleteModal" :click-to-close="false">
      <bf-dialog>
        <div class="dialog-content">
          <bf-spinner size="small" text-pos="right" class="deletingIndicator">
            Deleting project...
          </bf-spinner>
        </div>
      </bf-dialog>
    </bf-modal>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { debounce, indexOf, find } from 'lodash'

  import { isNavigationFailure, NavigationFailureType } from 'vue-router'

  import Dropdown from 'components/Butterfly/Dropdown/Dropdown.vue'
  import DropdownItem from 'components/Butterfly/Dropdown/DropdownItem.vue'
  import { BfModal, BfDialog, BfButton, BfSpinner  } from 'components/Butterfly'
  import Project from 'src/api/project'
  import DataUtils from 'src/utils/data'
  import store from 'src/store'
  import { DELETE_PROJECT, FETCH_USER, UPDATE_PROJECT } from 'src/store/types'
  import { mapGetters, mapActions } from 'vuex'
  import FormatUtils from 'src/utils/formatters'
  import { Label } from 'src/pages/ProjectLabels/ProjectLabels.util'
  import ProjectLabelAdd from './ProjectLabels/ProjectLabelAdd.vue'
  import ProjectLabelFilter from './ProjectLabels/ProjectLabelFilter.vue'
  import ProjectLabel from './ProjectLabels/ProjectLabel.vue'


  const PAGE_SIZE = 10
  const DEFAULT_SORT_BY = '-modified'

  const SiteHome = defineComponent({
    components: {
      Dropdown,
      DropdownItem,
      BfModal,
      BfDialog,
      BfButton,
      BfSpinner,
      ProjectLabelAdd,
      ProjectLabel,
      ProjectLabelFilter,
    },
    metaInfo () {  // Declaring metaInfo as a function allows it to be reactive
      return {
        title: this.currentSite ? `${this.currentSite.site_name} - Kapiche` : 'Kapiche'
      }
    },
    async beforeRouteEnter (to, from, next) {
      await store.dispatch({ type: FETCH_USER })
      if (store.getters.currentUser?.viewer) {
        next({ name: 'viewer-home', params: { site: to.params.site } })
      }

      let pageNumber = to.query.page || 1
      pageNumber = parseInt(pageNumber, 10)
      try {
        const response = await Project.getProjects(pageNumber, '', DEFAULT_SORT_BY)
        next(vm => {
          vm.data = response
          vm.pageNumber = pageNumber
        })
      } catch (error) {
        next()
      }
    },
    /**
     * This is used when changing between sites in the sidebar. beforeRouteEnter
     * doesn't work if only the "params" portion of the $router.push args
     * changes.
     */
    async beforeRouteUpdate (to, from, next) {
      // Only fetch new data if the site has changed
      if (to.params.site === from.params.site) {
        return next()
      }

      let pageNumber = to.query.page || 1
      pageNumber = parseInt(pageNumber, 10)
      this.data = await Project.getProjects(pageNumber, '', this.sortBy)
      this.pageNumber = pageNumber
      try {
        next()
      } catch (e) {
        if (!isNavigationFailure(e, NavigationFailureType.cancelled)) {
          throw e
        }
      }
    },
    data () {
      return {
        data: {
          count: 0,
          results: null
        },
        filterName: '',
        filterLabels: [] as number[],
        isSearching: false,
        loading: false,
        labelsLoading: false,
        labelsOptions: [] as Label[],
        selectedLabels: [],
        pageNumber: 1,
        sortBy: DEFAULT_SORT_BY,
        sortOptions: {
          "name": "Alphanumeric",
          "-created": "Date Created",
          "-modified": "Date Modified",
        },
        updatingProject: false,
        errorMessage: null,
        showingConfirmationModal: false,
        showingDeleteModal: false,
        selectedProject: null,
        hoveredProject: null
      }
    },
    computed: {
      ...mapGetters(['currentUser', 'currentSite', 'featureFlags']),
      firstResult (): number {
        return (this.pageNumber - 1) * PAGE_SIZE + 1
      },
      pageCount (): number {
        return Math.ceil(this.data.count / PAGE_SIZE)
      },
      projects (): Array<object> {
        return this.data.results || []
      },
      filtered (): boolean {
        return this.filterName.length > 0 || this.filterLabels.length > 0
      },
    },
    methods: {
      ...mapActions({ UPDATE_PROJECT, DELETE_PROJECT }),
      number: FormatUtils.number,
      truncate: FormatUtils.truncate,
      pluraliseDesc: FormatUtils.pluraliseDesc,
      createdBy (project: object): string {
        let user = project?.created_by
        if (!user) {
          return '(missing)'
        }

        let name = user.first_name + ' ' + user.last_name
        if (name.trim()) {
          return name
        } else {
          return user.email
        }
      },
      setSortBy (selected: string): void {
        this.sortBy = selected
        this.getProjects(1)
      },
      projectMenuCancelled () {
        this.selectedProject = null
      },
      hoverRow (projectId: number) {
        if (this.selectedProject) return
        this.hoveredProject = projectId
      },
      unhoverRow () {
        if (this.selectedProject) return
        this.hoveredProject = null
        this.$refs['projectActionMenu'].forEach(menu=>menu.isActive = false)
      },
      toggleModal (modal: string) {
        switch (modal) {
          case 'delete':
            this.showingDeleteModal = show
            break
        }
      },
      goToProject (projectId: number): void {
        if (!this.hoveredProject) return
        if (projectId !== this.hoveredProject) return
        this.$router.push({
          name: 'project-details', params: { projectId: projectId, }})
      },
      clearNameFilter () {
        this.filterName = ''
        this.search()
      },
      async deleteProject ( id ) {
        this.showingConfirmationModal = false
        this.showingDeleteModal = true
        this.updatingProject = true
        this.errorMessage = null
        try {
          await this.DELETE_PROJECT({ projectId: id })
          this.$analytics.track.project.delete(id)
          // wait for a moment so we don't get UI flicker if it happens too fast
          await setTimeout(async ()=>{
            // now update project list page being shown
            let refreshPageNumber = this.pageNumber
            // if the list that we were showing only had one in it, go to the previous page
            if (this.projects.length === 1) refreshPageNumber = Math.max(this.pageNumber - 1, 1)
            await this.getProjects(refreshPageNumber)
            this.showingDeleteModal = false
            this.updatingProject = false
            this.selectedProject = null
            this.hoveredProject = null
          }, 2500)
        } catch (e) {
          this.errorMessage = e.userMsg
        } finally {
          this.updatingProject = false
        }
      },
      fmtDate: DataUtils.formatDate,
      async getProjects (pageNumber) {
        this.data = await Project.getProjects(pageNumber, this.filterName, this.sortBy, this.filterLabels)
        // Avoid pushing the same route
        if (this.pageNumber !== pageNumber) {
          this.pageNumber = pageNumber
          this.$router.push({ name: 'home', query: { page: pageNumber }})
        }
        this.isSearching = false
      },

      pageBack () {
        this.getProjects(this.pageNumber - 1)
      },
      pageForward () {
        this.getProjects(this.pageNumber + 1)
      },

      selectProject (id: number, name: string): void {
        this.selectedProject = { id, name }
      },
      cancelProjectAction () {
        this.selectedProject = null
        this.showingConfirmationModal = false
        this.showingDeleteModal = false
      },
      doProjectAction ({action, options}) {
        switch (action) {
          case 'delete':
            this.selectedProject = options
            this.showingConfirmationModal = true
            break
          case 'rename':
            this.$router.push({
              name: 'project-edit',
              params: { projectId: options.id }
            })
            break
          default:
            this.selectedProject = null
        }
      },
      async deleteLabel (
        projectId: number,
        labelToDelete: number,
        currentLabels: Label[],
        ) {
        this.$analytics.track.labels.removeLabelFromProject()
        currentLabels.splice(
          indexOf(currentLabels, find(currentLabels, { id : labelToDelete})),
          1,
        )
        Project.updateProject(projectId, { labels: currentLabels })
      },
      async addLabel (
        projectId: number,
        labelToAdd: Label,
        currentLabels: Label[],
        ) {
          this.$analytics.track.labels.addLabelToProject()
          currentLabels.push(labelToAdd)
          Project.updateProject(projectId, { labels: currentLabels })
      },
      handleLabelToggle (label: number) {
        const idx = this.filterLabels.indexOf(label)
        if (idx >= 0) {
          this.filterLabels.splice(idx, 1)
        } else {
          this.filterLabels.push(label)
        }
        this.$analytics.track.labels.filteredProjectList(this.filterLabels.length)
        this.search()
      },
      handleLabelReset () {
        this.filterLabels = []
        this.search()
      },
      // Wrap the debounced search in an outer function
      // so that we can set isSearching flag without delay
      search: function () {
        this.isSearching = true
        this.doSearch()
      },
      // Search, simply adds a debounce to projects fetching to handle continuous typing
      doSearch: debounce(function () {
        this.getProjects(1)
      }, 350),
    }
  })
  export default SiteHome
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche'

  div.elipsis
    display: inline-block
    color: #95a6ac
    font-size: 2.85714rem
    &:hover
      color: #068ccc

  div.dialog-content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    padding: 20px 50px
    max-width: 90%
    text-align: center

    h2
      padding: 0
      margin: 0

    div.text
      height: 108px
      display: flex
      flex-direction: column
      justify-content: center
      font-size: 18px
      padding: 20px 0

  div.container
    margin-top: rem(30px)
    max-width: calc(100% - 2rem) !important
    /* Header */
    > .grid
      border-bottom: 1px solid $grey
      margin-bottom: rem(20px)
      h1
        font-size: rem(28px)
      .column.right
        text-align: right

    .button.primary
      font-size: rem(16px)
      i.kapiche-icon-briefcase
        margin-right: rem(10px)
      span
        vertical-align: top

    /* Filters */
    .filters
      position: relative
      display: flex
      height: 35px
      justify-content: space-between

      .search-container
        display: inline-block
        &:focus-within
          .icon.search
            color: $blue
          input[type=search]
            border-bottom: 1px solid $blue
      .icon.search
        color: $text-grey
        margin-right: rem(5px)
      .loader
        margin-right: rem(5px)
      input[type=search]
        background: transparent
        border: 0
        border-bottom: 1px solid transparent
        box-shadow: 0
        margin-bottom: rem(40px)
        outline: none
        padding-bottom: rem(5px)
        padding-right: rem(10px)
        +placeholder-color($text-grey)
      .kapiche-icon-delete-thin
        color: $text-grey
        cursor: pointer
        font-size: rem(10px)
        margin-left: rem(-10px)
        &:hover
          opacity: $text-hover-opacity
    .label-filters
      .column:last-child
        text-align: right

    /* Sorting */
    .sort
      background: transparent
      border: 0
      box-shadow: none !important
      color: $text-grey
      margin-right: 20px
      &::v-deep.dropdown

        .dropdown-content
          border: 1px solid rgba(0, 0, 0, 0.13)
          border-radius: 0
          padding: 0
          .dropdown-item
            font-size: 14px
          .dropdown-item.active
            font-weight: bold
            background-color: $grey-light
            color: $text-black
        .dropdown-menu
          top: 20px
          padding: 0
      .sortByButton
        color: $blue
        display: inline-flex
        align-self: center
        cursor: pointer
        font-size: 1rem
        font-weight: bold
        .icon
          margin-left: 3px
          font-size: 0.8rem
    /* Projects */
    div.project-rows
      box-shadow: $box-shadow
      div.project-row
        background: white
        border: 1px solid white
        cursor: pointer
        margin: 0
        padding: rem(10px)
        &:not(:first-child)
          border-top: 1px solid $grey
        &:not(.hovered:not(.no-results))
          .add-label
            display: none
        &.hovered:not(.no-results)
          background-color: #f4f6f7
          border: 1px solid $blue
          .project-info
            display: none
          .dropdown
            display: inline-block !important
          .num-members
            display: block !important
        .column.right
          height: rem(54px)
          padding-top: rem(5px)
          text-align: right
          .dropdown, .num-members
            display: none
          .num-members
            margin-top: rem(7px)
          .project-info
            padding-top: rem(10px)
          .project-info, .num-members
            color: $text-grey
            font-size: rem(14px)
            .subtext
              text-transform: uppercase
              font-weight: bold
              font-size: 0.85714rem

            div.right
              margin-top: rem(5px)
        .name
          display: inline-block
          font-size: rem(18px)

        .subtitle
          display: flex
          margin-top: rem(5px)

        .date
          color: $text-grey
          font-size: rem(12px)
          font-weight: bold
          letter-spacing: rem(0.6px)
          text-transform: uppercase

        .labels
          padding: 0px 10px

        .dropdown
          .menu
            left: unset
            right: 0
          .text
            color: $text-grey
            font-size: rem(40px)
            &:hover
              color: $blue
    .no-results
      color: $text-grey
      margin-top: rem(30px)
      text-align: center
      h2
        font-size: rem(30px)
      .subtext
        font-size: rem(18px)

    div.pagination
      margin-bottom: rem(90px)
      margin-top: rem(40px)
      text-align: center
      .info
        display: inline-block
        font-size: rem(16px)
        font-weight: bold
        margin-left: rem(40px)
        margin-right: rem(40px)
        span.of
          font-weight: normal
      i
        color: $blue
        cursor: pointer
        &.disabled
          color: $grey !important
          cursor: default
          pointer-events: none
        &:hover
          color: $blue-light

    div.empty-state
      font-size: rem(16px)
      margin-top: rem(140px)
      text-align: center
      i.kapiche-icon-briefcase
        color: $text-grey
        font-size: rem(70px)
      h1
        font-size: rem(30px)
      .button.primary
        font-size: rem(18px)
        margin: rem(30px) 0
      div.help-text
        font-size: rem(14px)

  #projectDimmer
    position: fixed
    z-index: 20
</style>
