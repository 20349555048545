<template>
  <div class="tab" :class="{active}">
    <slot name="default" />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      title: { type: String, required: false, default: 'untitled' },
      active: { type: Boolean, required: false, default: true }
    },
  })
</script>

<style lang="sass" scoped>
  @import 'assets/kapiche.sass'
  div.tab
    flex-grow: 1
    display: none
  div.tab.active
    display: block
</style>
