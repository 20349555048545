<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="false"
    :dev-mode="devMode"
    :has-errored="false"
    class="summary"
    @resize="setChartDimensions"
  >
    <!--======================== ACTIONS -->
    <template #actions>
      <div>
        <div class="default-actions">
          <a
            :href="CONST.widget_help_links.ai-summary"
            class="widget-action help"
            target="_blank"
          >
            <i class="kapiche-icon-info"></i>
          </a>
        </div>
      </div>
    </template>

    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon">
    </template>

    <template #header>
      {{ widgetTitle }}
    </template>

    <template #menu>
      <span class="label">AI GENERATED </span>
    </template>

    <template #devPanel>
      <div>
        <!-- Start: {{ new Date(sliceOneData.startTime || 0) }}<br />
        Done: {{ new Date(sliceOneData.doneTime || 0) }}<br />
        Elapsed: {{ ((sliceOneData.doneTime || 0) - (sliceOneData.startTime || 0)) / 1000 }} seconds<br />
        Status: {{ sliceOneData.status }}<br />
        Error: {{ sliceOneData.error }} -->
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel>
    </template>

    <template #content>
      <div v-if="isLoading" class="loading">
        <br />
        <bf-spinner></bf-spinner>
        <br />
        <br />
        One moment, reading verbatims...
        <br />
        <br />
      </div>
      <div v-else-if="summary" class="summary-wrapper">
        <div class="summary-text">
          {{ summary }}
        </div>
        <el-collapse class="collapse">
          <el-collapse-item>
            <template #title>
              <span class="subheader-text">Sample Verbatims</span>
            </template>
            <ul>
              <li
                v-for="v in sampleVerbatims"
                :key="v"
                class="verbatim-text"
              >
                <q>{{ v }}</q>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </div>
      <bf-button v-else color="blue" @click="getSummary">
        Generate
      </bf-button>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from 'vue'
import WidgetFrame from 'components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-summary.svg'
import { BfButton, BfSpinner } from 'components/Butterfly'
import { Analytics } from 'src/analytics'
import Query from 'src/api/query'
import { mergeDashboardFiltersWithBotanicQuery } from "src/utils/query"
import { removemarkdown } from 'src/utils/formatters'
import { isEqual } from 'lodash'


export default defineComponent({
  components: {
    WidgetFrame,
    BfSpinner,
    BfButton
  },
  props: {
    devMode: {type: Boolean, required: false, default: false},
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    dashboardId: { type: Number, required: false, default: null },
    projectId: { type: Number, required: false, default: null },
    currentSite: { type: Object, default: ()=>null, required: false },
    currentProject: { type: Object, default: ()=>null, required: false },
    currentAnalysis: { type: Object, default: ()=>null, required: false },
    query: { type: Object, required: false, default: null },
    mergedFilters: { type: Array, default: ()=>null, required: false },
    savedQueries: { type: Array, default: ()=>null, required: false },
    themeName: { type: String, required: false, default: null },
    widgetTitle: { type: String, required: false, default: 'At a Glance' },
    isStaff: { type: Boolean, required: false, default: false},
  },
  setup (props, {}) {
    const analytics = inject<Analytics>('analytics')
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const isLoading = ref(false)
    const summary = ref('')
    const sampleVerbatims = ref([])
    const width = ref(300)

    const refresh = () => {
      window.location.reload()
    }

    const setChartDimensions = (w: number): void => {
      width.value = w
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      getSummary()
    }

    const getSummary = async () => {
      try {
        isLoading.value = true
        analytics?.track.dashboard.tellAStory()
        const themeName = props.themeName
        const summary_type = 'paragraph'
        const summaryResult = await Query.generateSummary(
          props.projectId,
          props.currentSite.chrysalis_url,
          props.currentProject.chrysalis_ref,
          props.currentAnalysis.topic_framework_id,
          mergeDashboardFiltersWithBotanicQuery(props.query, props.mergedFilters),
          props.savedQueries,
          summary_type,
          themeName,
          3,
          "theme_summary",
          props.isStaff,
        )
        if (summaryResult && summaryResult.payload) {
          summary.value = removemarkdown(summaryResult.payload[0].summary)
          sampleVerbatims.value = summaryResult.payload[0].sample_verbatims
          if (props.isStaff) {
            console.log("Prepared Prompt for Summary:", summaryResult.payload[0].llm_prompt)
          }
        } else {
          throw new Error(`Failed to get summary data`)
        }
      } catch (e) {
        console.warn(`Error ${e}`)
      } finally {
        isLoading.value = false
      }
    }

    watch(
      [
        () => props.mergedFilters,
        () => props.query,
        () => props.themeName
      ],
      ([newFilters, newQuery, newTheme], [oldFilters, oldQuery, oldTheme]) => {
        if (
          !isEqual(oldFilters, newFilters) ||
          !isEqual(oldQuery, newQuery) ||
          newTheme !== oldTheme
        ) {
          summary.value = ''
        }
      },
      { deep: true }
    )

    return {
      icon,
      root,
      refresh,
      contact,
      reload,
      isLoading,
      getSummary,
      summary,
      sampleVerbatims,
      setChartDimensions,
      width,
    }
  },
})
</script>

<style lang="sass" scoped>
@import "assets/colours.sass"
@import '~assets/kapiche.sass'

$dark-orange: #DC7070

.header-icon
  height: 32px
  width: 100%

.row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

::v-deep
  .legend-buttons
    display: none
  .timeline-legend-container
    column-count: 2
    margin-top: -6px
    li
      text-align: center
      .clickable-legend
        display: inline-flex

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px

  &:hover
    background-color: $grey-light

  &:focus
    border: 2px solid $blue-light
    outline: none

// These settings are required to allow the chart to resize correctly. If you comment these out,
// weird things happen with the sizing of the timeline.
.timeline-container
  width: inherit
  align-items: unset

.summary-wrapper
  width: 100%
  padding: 20px
  align-self: center

.summary-text
  color: $text-black
  font-size: 16px
  line-height: 1.7rem
  align-self: center
  margin-bottom: 20px

.loading
  text-align: center

.label
  font-size: 14px
  font-weight: bold
  letter-spacing: 0.6px
  color: rgb(149, 166, 172)
  margin-bottom: 3px
  text-transform: uppercase
  align-self: center

.verbatim-text
  font-size: 14px
  font-style: italic
  letter-spacing: 0.6px

.subheader-text
  font-size: 14px
  font-weight: bold
  letter-spacing: 0.6px

.el-collapse
  margin-top: 20px

</style>
