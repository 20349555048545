<template>
  <div>
    <bf-modal :visible="visible" @close="close">
      <bf-dialog @close="close">
        <div class="emailDigestManagementModal">
          <h2>
            Manage email digests
          </h2>
          <div class="content">
            <bf-spinner v-if="loading" />
            <div v-if="!loading && digests.length" class="digest-container">
              <div v-for="digest in digests" :key="digest.id" class="digest-item">
                <div>
                  <div>{{ digest.subject }}</div>
                  <div>{{ getCronString(digest.cron_string) }}</div>
                </div>
                <button @click="editDigest(digest)">
                  Edit
                </button>
                <button @click="digestToDelete = digest">
                  Delete
                </button>
              </div>
            </div>
            <div v-if="!loading && !digests.length" class="no-digests">
              <h3>There are no email digests for this dashboard</h3>
              <p>
                Email digests are snapshots of the dashboard, sent automatically.
                <br />
                They keep people in your organisation up-to-date with this data.
              </p>
            </div>

            <bf-button
              color="green"
              size="large"
              class="create-button"
              @click="editModalVisible = true"
            >
              Create email digest
            </bf-button>
          </div>
          <div class="actions">
            <bf-button
              color="blue"
              size="large"
              @click="close"
            >
              Done
            </bf-button>
          </div>
        </div>
      </bf-dialog>
    </bf-modal>
    <bf-modal :visible="!!digestToDelete" @close="digestToDelete = null">
      <bf-dialog @close="digestToDelete = null">
        <div v-if="digestToDelete" class="delete-modal">
          <h2>
            Do you want to delete the "{{ digestToDelete.subject }}" digest?
          </h2>
          <p>
            This action cannot be reversed. All current recipients will no longer
            receive emails associated with this digest.
          </p>
          <div class="buttons">
            <bf-button size="big" @click="digestToDelete = null">
              No
            </bf-button>
            <bf-button color="red" size="big" @click="deleteDigest(digestToDelete.id)">
              Yes, delete this digest
            </bf-button>
          </div>
        </div>
      </bf-dialog>
    </bf-modal>
    <email-digest-modal
      :sorted-segments-for-fields-limited="sortedSegmentsForFieldsLimited"
      :current-analysis="currentAnalysis"
      :saved-queries="savedQueries"
      :dashboard="dashboard"
      :visible="editModalVisible"
      :saved-data="digestToEdit"
      :has-sentiment="hasSentiment"
      :has-nps="hasNps"
      @refresh-digests="fetchDigests"
      @close="closeEditModal"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import EmailDigestModal from 'components/widgets/EmailDigest/EmailDigestModal.vue'
import { BfModal, BfDialog, BfButton, BfSpinner } from 'src/components/Butterfly'
import { EmailDigest } from 'src/types/components/EmailDigest.types'
import ProjectAPI from 'src/api/project'
import * as cronstrue from 'cronstrue'

const EmailDigestManagementModal = defineComponent({
  components: {
    BfModal,
    BfDialog,
    BfButton,
    BfSpinner,
    EmailDigestModal,
  },
  props: {
    visible: { type: Boolean, default: false, required: false },
    dashboard: { type: Object, required: true },
    sortedSegmentsForFieldsLimited: { type: Object, required: true },
    currentAnalysis: { type: Object, required: true },
    savedQueries: { type: Array, required: true },
    hasNps: { type: Boolean, default: false, required: false },
    hasSentiment: { type: Boolean, default: false, required: false },
  },
  data () {
    return {
      loading: true,
      digests: [] as EmailDigest[],
      digestToDelete: null as EmailDigest | null,
      digestToEdit: null as EmailDigest | null,
      editModalVisible: false,
    }
  },
  watch: {
    visible (newValue) {
      if (newValue) {
        this.fetchDigests()
      }
    },
  },
  methods:{
    close () {
      this.$emit('close')
    },
    async fetchDigests () {
      this.loading = true
      const { data } = await ProjectAPI.listEmailDigests(this.dashboard.id)
      this.digests = data
      this.loading = false
    },
    async deleteDigest (id: number) {
      this.$analytics.track.dashboard.digest.deleted(
        id,
        this.dashboard.id
      )
      await ProjectAPI.deleteEmailDigest(this.dashboard.id, id)
      this.digestToDelete = null
      this.fetchDigests()
    },
    getCronString (cron: string) {
      try {
        return cronstrue.toString(cron)
      } catch (e) {
        console.error(e)
        return 'err'
      }
    },
    editDigest (digest: EmailDigest) {
      this.digestToEdit = digest
      this.editModalVisible = true
    },
    closeEditModal () {
      this.digestToEdit = null
      this.editModalVisible = false
    },
  }
})

export default EmailDigestManagementModal
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.emailDigestManagementModal
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between

  h2
    margin: 20px 0 20px

  .no-digests
    display: flex
    margin: auto 0
    flex-direction: column
    align-items: center
    font-size: 18px
    color: $subdued
    text-align: center


  .create-button
    width: 240px
    margin-top: 20px

  .bf-spinner-container
    margin: 100px auto 0

  .content
    padding: 40px 50px
    background-color: $grey-background
    display: flex
    justify-content: space-between
    flex-direction: column
    width: 100%
    min-height: 400px
    flex-grow: 2

  .actions
    display: flex
    justify-content: center
    width: 100%
    padding: 20px 0 55px

  .digest-container
    display: flex
    flex-direction: column
    max-height: 50vh
    overflow-y: auto

  .digest-item
    background: #fff
    display: flex
    border: 1px solid $grey
    padding: 20px
    border-radius: 5px

    &:not(:last-of-type)
      margin-bottom: 10px

    > div:first-child
      flex: 1

      > div:nth-child(1)
        font-weight: 700
        font-size: 18px
        color: $text-black
        line-height: 28px

      > div:nth-child(2)
        font-weight: 400
        font-size: 16px
        color: $text-grey
        line-height: 24px

    > button
      border: none
      cursor: pointer
      font-weight: 400
      font-size: 14px
      align-self: end
      margin-bottom: 3px

      &:nth-of-type(1)
        color: $blue
        margin-right: 10px

      &:nth-of-type(2)
        color: $red

.delete-modal
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding: 25px
  h2
    font-size: 30px
  p
    text-align: center
  .buttons
    padding: 10px 0 10px
    button
      &:first-child
        margin-right: 20px

::v-deep div.dialog
  width: 70%
  max-width: 1200px

</style>
